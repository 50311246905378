import React, { useState, useContext, useEffect } from "react";
import {
  Collapse,
  Card,
  CardHeader,
  ListGroupItem,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { MainContext } from "context/mainContext";

const CourseSessionListGroup = ({
  session,
  i,
  lectures,
  handlePlay,
  handleCheckChange,
  ...props
}) => {
  const { textColor, bgColor } = useContext(MainContext);
  const [toggleState, setToggleState] = useState({ collapse: false });

  const toggle = () => {
    setToggleState({ collapse: !toggleState.collapse });
  };
  // console.log("CourseSessionListGroup", session);
  return (
    <>
      <Card
        style={{
          cursor: "pointer",
          marginBottom: "5px",
        }}
        key={i}
      >
        <CardHeader
          style={{
            backgroundColor: `${bgColor}`,
            // borderBottom: '1px solid #d1d7dc'
            color: `${textColor}`,
            borderRadius: 0,
          }}
          id={`toggler${i}`}
          onClick={() => toggle()}
          className="clearfix"
        >
          <strong>{`Session ${i + 1} - ${session.Title}`}</strong>
          <i
            className="ni ni-bold-down float-right"
            style={{
              transform: toggleState.collapse ? "rotate(-180deg)" : "",
              transition: ".3s ease-in-out",
            }}
          />
        </CardHeader>
        <Collapse
          // toggler={`#toggler${i}`}
          // defaultOpen={i === 0 ? true : false}
          isOpen={toggleState.collapse}
        >
          {lectures?.length > 0
            ? lectures?.map((lecture, i) =>
                lecture.Session === session.id ? (
                  <ListGroupItem
                    key={i}
                    onClick={() =>
                      handlePlay("", lecture.URL, i, lecture.id, lecture)
                    }
                    style={{
                      cursor: "pointer",
                      // background: `${focusedIndex === index ? '#d1d7dc' : lecture.IsDone ? '#2dce89' : ''}`, color: `${ focusedIndex === index ? '#525f7f' : lecture.IsDone ? 'white' : ''}`
                      background: `${
                        props.videoDetails.id === lecture.id ? "#d1d7dc" : ""
                      }`,
                      borderLeft: 0,
                      borderRight: 0,
                    }}
                    className="videoLists"
                  >
                    <Form>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          id={i}
                          checked={lecture.IsDone}
                          onChange={(e) => handleCheckChange(i, lecture.id, e)}
                        />
                        <Label check>
                          <span className="btn-inner--icon pr-1">
                            <i className="ni ni-button-play"></i>
                          </span>
                        </Label>
                        <span style={{ fontSize: "0.8125rem" }}>
                          <strong>{`${lecture.Name}`}</strong>
                        </span>
                      </FormGroup>
                    </Form>
                  </ListGroupItem>
                ) : null
              )
            : null}
          {props.children}
        </Collapse>
      </Card>
    </>
  );
};

export default CourseSessionListGroup;
