import React, { useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import { useHistory } from "react-router";
import "./styles.css";

const GoogleAuthenticatorLoginPage = () => {
  const routerHistory = useHistory();
  const [userDetails, setUserDetails] = useState("");
  const [mfaTextbox, setMfaTextbox] = useState("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");

  const getUserDetails = async () => {
    const details = await fetch("/api/user/userDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await details.json();
    setUserDetails(data);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const mfaOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMfaTextbox(value);
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  const onVerifyHandler = () => {
    const validateGA = async () => {
      const auth = await fetch("/api/auth/verifyGoogleAuthenticator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userToken: mfaTextbox,
        }),
      });
      const data = await auth.json();
      if (!data.success) {
        setModal(true);
      } else {
        setMessage("You account has been authenticated");
        setTimeout(() => {
          routerHistory.push("/client/accountant/details");
        }, 1000);
      }
    };
    validateGA();
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid MFA Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            The multi-factor authentication code could not be verified. Enter
            the correct pin found on your Google Authenticator app and try
            again.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div className="center-blk-container mfa-container-blk">
        <div></div>
        <h1>MFA - Google Authenticator</h1>
        <p>
          Your account is protected with Multi-factor authentication.
          <br />
          Please confirm your account by entering the code found on Google
          Authenticator app.
        </p>
        <div className="form-blk-mfa">
          <input
            type="text"
            placeholder="Enter your code here..."
            maxLength={6}
            value={mfaTextbox}
            onChange={mfaOnChangeHandler}
          />
          <button onClick={onVerifyHandler}>VERIFY</button>
          {!message ? (
            ""
          ) : (
            <span
              style={{
                display: "block",
                marginTop: "20px",
                background: "#00ffa9",
                padding: "10px",
                textAlign: "center",
                fontSize: "15px",
                color: "#000",
              }}
            >
              {message}
            </span>
          )}
        </div>
      </div>
      <div className="footer-blk">
        <p>
          &copy; 2023 - <span>Etani</span>
        </p>
      </div>
    </>
  );
};

export default GoogleAuthenticatorLoginPage;
