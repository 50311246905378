import React, { useEffect, useState, useContext } from 'react'
import CustomHeader from 'customComponents/customHeader';
import { Card, Container, Col, Row, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import BlockUI from 'components/Misc/BlockUI';
import CustomSpinner from 'components/Misc/CustomSpinner';
import CustomTooltip from 'customComponents/customComponents';

const MainDashboard = () => {
  const {
    textColor,
    bgColor,
    selectedClientID,
    setSelectedClientID,
    userDetails,
    firmDetails
  } = useContext(MainContext);
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const [showBlockUI, setShowBlockUI] = useState(false);


  const fetchAPIs = async () => {
    let firstClient = [];
    let url = window.location.pathname.split("/");
    const getAllRecords = await fetch(`/api/client/getAllRecords`)
    const allClientRecords = await getAllRecords.json()
    firstClient = allClientRecords[0]
    if (allClientRecords && firstClient) {
      setSelectedClientID(url.length < 4 ? firstClient.ClientID : url[url.length - 1]);
      const getClientDetails = await fetch(`/api/client/showDetails/${url.length < 4 ? firstClient.ClientID : url[url.length - 1]}`)
      const clientDetails = await getClientDetails.json()
      // console.log(clientDetails)
      if (clientDetails && !clientDetails.error) {
        if (clientDetails.message === "Client not found") {
          // setClientID(firstClient.ClientID);
          setSelectedClientID(firstClient.ClientID);
          return history.push(`/auth/page-not-found`);
        }
      }
    }
  }

  useEffect(async () => {
    setLoading(true)

    // fetchAPIs()

    // if (selectedClientID) {
    //   history.push(`/client/maindashboard/${selectedClientID}`);
    // }
    const getFavorites = await fetch('/api/favorites/getAll')
    const favorites = await getFavorites.json()
    // console.log(favorites)
    if (favorites) {
      setLoading(false)
      if (favorites.message === 'No data found.') {
        setLoading(false)
        setFavorites([])
      } else {
        setFavorites(favorites.detailsFavorites)
      }
    }
  }, [])

  const limitedBusinessNameCharacters = (text, maxLength) => {
    const shortenedText = text.substring(0, maxLength);
    return `${shortenedText}${text.length > maxLength ? '...' : ''}`
  }


  const handleViewFavorite = async (data) => {
    setShowBlockUI(true)
    // console.log(data)
    // console.log(selectedClientID)
    const getClientDetails = await fetch(`/api/client/getDetailsByID/${data.Client}`)
    const clientDetails = await getClientDetails.json()
    if (clientDetails) {
      setShowBlockUI(false)
      localStorage.setItem('clientOfSelectedReport', clientDetails.ClientID);
      localStorage.setItem('selectedReportID', data.ReportID);
      // history.push(`/client/reports/${clientDetails.ClientID}`);
      window.open(`/client/reports/${clientDetails.ClientID}`, "_blank");
    }
    // console.log(clientDetails)
  }

  const removeToFavorite = async (data) => {
    // console.log(data)
    setShowBlockUI(true)
    try {
      const removeFaveRequest = await fetch(`/api/favorites/removeToFavorite`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ ReportID: data.ReportID })
      })
      const result = await removeFaveRequest.json()
      // console.log(result)
      setShowBlockUI(false)
      if (!result.error) {
        const updatedFav = favorites.filter(fav => fav.ReportID !== result.removedID)
        setFavorites(updatedFav)
      }
    } catch (error) {
      setShowBlockUI(false)
      // console.log(error)
    }
  }

  return (
    <>
      {showBlockUI ? <BlockUI /> : null}
      {/* <CustomHeader />
      <Container className="mt-6 fadein" fluid>
        <div className="nav-wrapper" style={{ minHeight: '80vh', paddingLeft: '30px', paddingRight: '30px' }}>
          <h1>Favorites</h1> */}
      <Row>
        {
          loading ?
            <CustomSpinner />
            :
            favorites?.length >= 1 ?
              favorites.map((fav, i) => (
                <Col lg="4" xl="3" md="6" sm="6">
                  <Card>
                    <CardHeader>
                      <div style={{ position: "absolute", right: "15px", top: "8px" }}>
                        <Row>
                          <Col>
                            <CustomTooltip
                              i={i}
                              id="removeToFavorites"
                              message="Remove to favourites"
                              className="fas fa-star fa-sm"
                              onClick={() => removeToFavorite(fav)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center" style={{ cursor: "pointer" }} onClick={() => handleViewFavorite(fav)}>
                      <Row>
                        <Col style={{ fontSize: "50px" }}>
                          {
                            fav.Type === 1 ?
                              <span className="text-info mr-2">
                                <i className="fas fa-chart-bar"></i>
                              </span>
                              :
                              <span className="text-warning mr-2">
                                <i className="fas fa-tachometer-alt"></i>
                              </span>
                          }
                        </Col>
                      </Row>
                      {/* <span className="text-nowrap">{limitedBusinessNameCharacters(fav.Name, 20)}</span> */}
                    </CardBody>
                    <CardFooter className="p-3" style={{ cursor: "pointer" }}>
                      <strong className="text-nowrap">{limitedBusinessNameCharacters(fav.Name, 25)}</strong>
                    </CardFooter>
                  </Card>
                </Col>
              ))
              :
              <Col className="text-center" >
                <h5> No data found.</h5>
              </Col>
        }
      </Row>
      {/* </div>
      </Container> */}
    </>
  )
}

export default MainDashboard;