import React, { useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import CustomTooltip from "customComponents/customComponents";
import { useHistory } from "react-router";
import EvaToggleSwitch from "components/EvaToggleSwitch/EvaToggle";

const ClientCard = ({ data, ...props }) => {
  const { bgColor, textColor, setSelectedClientID, userDetails } = useContext(MainContext);
  const [defaultModal, setdefaultModal] = React.useState(false);
  const history = useHistory();
  const [canSeeEva, setCanSeeEva] = React.useState(false);
  const [isAdvisor, setIsAdvisor] = React.useState(false);
  const [isVisibleToClient, setIsVisibleToClient] = React.useState(false);


  const userFullName = (user) => {
    let clientUserAdmin = [];

    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].FirstName} ${clientUserAdmin[0].LastName}`;
    }
    // console.log(clientUserAdmin);
    return "N/A";
  };

  const userEmail = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].Email}`;
    }
    return "N/A";
  };

  const changeUrl = (page, clientID) => {
    if (page === "users") {
      setSelectedClientID(clientID);
      return history.push(`/client/users/${clientID}`);
    }
    if (page === "dashboard") {
      setSelectedClientID(clientID);
      return history.push(`/client/dashboard/${clientID}`);
    }
    if (page === "reports") {
      setSelectedClientID(clientID);
      return history.push(`/client/reports/${clientID}`);
    }
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
    if (page === "signclient") {
      setSelectedClientID(clientID);
      return history.push(`/client/signclient/${clientID}`);
    }
    if (page === "eva") {
      setSelectedClientID(clientID);
      return history.push(`/client/eva?clientId=${clientID}`);
    }    
  };

  useEffect(() => {
    let isMounted = true;

    const { ClientID } = data;

    if (isMounted) {
      if (userDetails?.User) {
        const firmId = userDetails?.User?.AccountingFirm;
       
        const { User: { UserAccess } } = userDetails;        

        if ([1,2,3,4].includes(UserAccess)) setIsAdvisor(true);

        fetch(`/api/eva/access?businessId=${firmId}&clientId=${ClientID}`)
          .then((res) => res.json())
          .then((data) => { 
            if (data.success && !data.data.isAdvisorOnly) {
              setCanSeeEva(true);
              setIsVisibleToClient(true);
            } else if (data.success && [1,2,3,4].includes(UserAccess) && data.data.isAdvisorOnly ) {
              setCanSeeEva(true);
              setIsVisibleToClient(false);
            }
          }).catch((err) => { console.log(err) });
       }
    }
    
    return () => {  isMounted = false }

  },[]);

  return (
    <>
      <Card className="zoom">
        <CardBody className="d-flex flex-column px-3">
          <div
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            <Row>
              <Col className="p-0">
                {props.userDetails?.User?.Details?.Authority?.Admin ? (
                  <CustomTooltip
                    i={data.id}
                    id={`editAccountant${data.id}`}
                    message="Update"
                    className="fas fa-pen fa-xs"
                    onClick={() => props.handleEditClient(data)}
                  />
                ) : null}
                <Modal
                  className="modal-dialog-centered"
                  isOpen={defaultModal}
                  toggle={() => setdefaultModal(false)}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      Edit Client
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setdefaultModal(false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="FirstName"
                        >
                          Name
                        </label>
                        <Input
                          placeholder="Name"
                          type="text"
                          name="Name"
                          required={true}
                          id="Name"
                          onChange={props.handleInputChange}
                          defaultValue={data && data.Name ? data.Name : ""}
                          className="getData"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="Sample">
                          Description
                        </label>
                        <Input
                          placeholder="Description"
                          type="textarea"
                          name="Description"
                          required={true}
                          id="Description"
                          rows={2}
                          onChange={props.handleInputChange}
                          defaultValue={
                            data && data.Description ? data.Description : ""
                          }
                          className="getData"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="FirstName"
                        >
                          Website
                        </label>
                        <Input
                          placeholder="Website"
                          type="text"
                          name="WebSite"
                          required={true}
                          id="WebSite"
                          onChange={props.handleInputChange}
                          defaultValue={
                            data && data.WebSite ? data.WebSite : ""
                          }
                          className="getData"
                        />
                      </FormGroup>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="PhoneNumber"
                            >
                              Logo
                            </label>
                            <div className="custom-file">
                              <input
                                className="custom-file-input getData"
                                id="Logo"
                                lang="en"
                                type="file"
                                name="Logo"
                                accept="image/*"
                                onChange={props.handleInputChange}
                              />
                              <label className="custom-file-label">
                                Select file
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <img
                            src={
                              props.avatar
                                ? props.avatar
                                : data && data.Logo
                                ? data.Logo
                                : require("assets/img/blank-avatar.png").default
                            }
                            style={{
                              maxWidth: "100px",
                              display: "block",
                              margin: "0 auto",
                            }}
                            alt="Avatar"
                          />
                        </Col>
                      </Row>
                      <FormGroup>
                        <Button
                          style={{
                            backgroundColor: `${
                              bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                            color: `${
                              textColor === "#000" ? "#fff" : textColor
                            }`,
                            borderColor: `${bgColor}`,
                          }}
                          type="button"
                          size="sm"
                          onClick={() => props.setAccountantListModal(true)}
                        >
                          Select Accountants
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                  <div className="modal-footer">
                    <Button color="primary" type="button">
                      Save changes
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setdefaultModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
              </Col>
              <Col className="pl-1">
                {props.userDetails?.User?.Details?.Authority?.Admin ? (
                  <CustomTooltip
                    i={data.id}
                    id={`deleteAccountant${data.id}`}
                    message="Delete"
                    className="fas fa-times fa-sm"
                    onClick={() =>
                      props.warningAlert(
                        "deleteClient",
                        `Are youre you want to delete ${data.Name} Client?`,
                        data
                      )
                    }
                  />
                ) : null}
              </Col>
            </Row>
          </div>
          <div className="my-3"></div>
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img-center img-fluid shadow shadow-lg--hover"
              src={
                data.Logo
                  ? data.Logo
                  : require(`assets/img/brand/logo-placeholder.png`).default
              }
              style={{
                width: "100px",
                //height: "100px", // Commented by Joef: to retain dimension
                objectFit: "cover",
                borderRadius: "0.475rem",
              }}
            />
          </a>
          <div className="pt-3 text-center">
            <h4
              className="mb-0"
              style={{
                height: "40px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                {data.Name}
              </a>
            </h4>
            <Row>
              <Col sm="12">
                <hr className="my-3" />
              </Col>
              <Col xs="12" className="text-center">
                <span className="btn-inner--icon ml-1">
                  <i className="ni ni-single-02" />
                </span>
                <span className="text-sm text-muted ml-3">
                  {userFullName(data.User)}
                </span>
              </Col>
              <Col xs="12" className="text-center">
                <span className="btn-inner--icon ml-1">
                  <i className="ni ni-email-83" />
                </span>
                <span className="text-sm text-muted ml-3">
                  {userEmail(data.User) === "N/A" ? (
                    <span>{userEmail(data.User)}</span>
                  ) : (
                    <a href={`mailto:${userEmail(data.User)}`}>
                      {userEmail(data.User)}
                    </a>
                  )}
                </span>
              </Col>
              <Col sm="12">
                <hr className="my-3" />
              </Col>
            </Row>
            <Row sm="12">
              <Col sm='12'>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: `${
                        bgColor === "#fff" ? "#5e72e4" : bgColor
                      }`,
                      color: `${textColor === "#000" ? "#fff" : textColor}`,
                      borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                    }}
                  >
                    View
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => changeUrl("users", data.ClientID)}
                    >
                      Users
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => changeUrl("dashboard", data.ClientID)}
                    >
                      Dashboard
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => changeUrl("reports", data.ClientID)}
                    >
                      Reports
                    </DropdownItem>

                    <DropdownItem
                      onClick={() =>
                        changeUrl("thirdpartyapplication", data.ClientID)
                      }
                    >
                      3rd Party Applications
                    </DropdownItem>
                    {  canSeeEva ?
                    <DropdownItem
                      onClick={() =>
                        changeUrl("eva", data.ClientID)
                      }
                    >
                      EVA Beta
                    </DropdownItem>
                    : null}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ClientCard;
