import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Input, Table } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CsvHeaderCheck } from '../../../utils/utilityFunctions';
import Papa from 'papaparse';
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import BlockUI from "components/Misc/BlockUI";


const CsvStaffHistoricRatesModal = ({ selectedData, integrationType, ...props }) => {
  const [alert, setAlert] = useState(null);
  const [file, setFile] = useState(null);
  const [csvIsEmptyfile, setcsvIsEmptyfile] = useState(true);
  const [csvErrors, setCsvErrors] = useState('');
  const [csvIsValid, setcsvIsValid] = useState(true);
  const [csvHeader, setCSVHeader] = useState([])
  const [staffRateHistory, setStaffRateHistory] = useState([])
  const [showBlockUI, setShowBlockUI] = useState(false)

  useEffect(() => {
    setcsvIsValid(true)
    setcsvIsEmptyfile(false)
    loadHistory();
  }, []);



  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setcsvIsEmptyfile(false)
  };

  const fileRef = useRef(null);

  const handleSubmit = async () => {
    if (file) {
      const isValid = await fileValidateHeader(file, setCsvErrors);
      setcsvIsValid(isValid);

      if (isValid) {
        handleWIPUpload(file);
      }
    } else {
      setcsvIsEmptyfile(true)
      setcsvIsValid(true);
    }

    fileRef.current.value = '';
    setFile(null);
  }

  const handleWIPUpload = async (file) => {
    props.setShowBlockUI(true)
    setcsvIsEmptyfile(false)
    const uniqueFileId = new Date().getTime();
    const blobFileName = `${integrationType === 'wfm2' ? `QUEUE/CSV/${uniqueFileId}-wfm2-staffrate-csv-upload.csv` : `QUEUE/CSV/${uniqueFileId}-staffrate-csv-upload.csv`}`;
    const { dbName, tbName, orgId, dw, clientId, firmId } = props.dbDetails;

    // console.log(dbName, tbName, orgId)

    await fetch(`/api/clientService/uploadCsv`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ fileName: blobFileName })
    })
      .then((response) => response.json())
      .then(async data => {

        // Upload to Azure Blob
        await uploadBlob(file, blobFileName, data);

        // // Push it to queue
        // clientId: `${props.firmDetails?.Code.toUpperCase()}_CLIENT${props.selectedClient?.Details?.Legacy
        //   ? props.selectedClient?.Details?.Legacy?.id
        //   : props.selectedClient?.id}`,
        // firmId: props.firmDetails?.id,
        const QUEUENAME = integrationType === 'wfm2' ? 'wfm2-staffrate-upload-csv-queue' : 'xpm-staffrate-upload-csv-queue';
        const messageMetadata = {
          organizationId: orgId,
          clientId: clientId,
          dbName: dbName,
          firmId: firmId,
          tableName: tbName,
          originalFileName: file.name,
          blobFileName,
          uploader: {
            id: props.userDetails.User.id,
            email: props.userDetails.User.Email,
            userAccess: props.userDetails.User.UserAccess,
          },
          dw: dw
        }
        await pushToQueue(QUEUENAME, blobFileName, data, messageMetadata);

        // console.log(selectedData)

        const updateClientService = await fetch(`/api/clientService/updateClientServiceAfterCsvUpload`, {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify({
            clientId: selectedData.Client,
            firmId: selectedData.AccountingFirm,
            serviceId: selectedData.id
          })
        })

        const updatedResult = await updateClientService.json()
        // console.log(updatedResult)

        props.setShowBlockUI(false)

        setAlert(
          <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
              props.setShowStaffRateUpload(false)
              setAlert(null)
              props.closeStaffListModal()
              props.handleUpdateRecord(updatedResult.updatedClientService)
            }}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            {"Your file has been successfully uploaded. Please allow approximately 3 minutes for it to be updated in your list."}
          </ReactBSAlert>
        );
      })
      .catch((err) => {
        props.setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            err
          </ReactBSAlert>
        );
      });

  };

  const validStaffRateHeader = [
    '[Ledger] Staff',
    '[Ledger] Date',
    '[Ledger] Standard Rate'
  ]

  const fileValidateHeader = (file, setCsvErrors) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (result) {
          const firstObject = result.data[0];
          const keys = Object.keys(firstObject);

          setCSVHeader(keys);

          const { validCsv, errorsFound } = CsvHeaderCheck(keys, validStaffRateHeader);
          if (validCsv && !errorsFound.length) {
            resolve(true);
          } else {
            setCsvErrors(errorsFound);
            resolve(false);
          }
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
          resolve(false);
        },
      });
    });
  }

  const loadHistory = async () => {
    // console.log(integrationType)
    await fetch(`/api/clientService/getCsvUploadHistory?csv=${integrationType === 'wfm2' ? `wfm2_staffrate_csv` : `xpm_staffrate_csv`}&orgId=${props.dbDetails.orgId}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((HistoryRecord) => {
        if (HistoryRecord.error) {
          HistoryRecord = {};
          // return warningAlert(wipHistoryRecord);
        }

        setStaffRateHistory(HistoryRecord);
      });
  }

  const dateFormatter = (myData) => {
    const originalDate = myData;
    const formattedDate = new Date(originalDate).toLocaleString();
    return formattedDate;
  }



  return (
    <>
      {alert}
      <div className="HistoricRatesLayout">
        <div className="HistoricRatesContainer" style={{ width: '800px' }}>
          <h2>Staff Rate CSV Upload</h2>
          <div className="file-upload-container">
            <input type="file" accept=".csv" onChange={handleFileChange} ref={fileRef} />
            <div className="button-container">
              <Button onClick={handleSubmit}>Upload</Button>
              <Button onClick={() => {
                props.showStaffRateModal()
                setFile(null)
              }
              }>Cancel</Button>
            </div>
          </div>
          <div className="historic-table-container">
            <div className={`csvErr ${csvIsValid ? "hidden" : ""}`}>
              <em>Invalid CSV file:</em>
              {csvErrors.length ?
                <ul>{csvErrors.map((error, k) => <li key={k}>{error}</li>)}</ul>
                : null}
              <span className={`csvErr ${csvIsEmptyfile ? "" : "hidden"}`}>File is empty; please choose a valid file.</span>
            </div>
            <div className='CSVList-Container'>
              <Table className='CSVHistoryList'>
                <thead>
                  <tr>
                    <td>Uploader</td>
                    <td>File Name</td>
                    <td>Date</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {staffRateHistory.length > 0 && staffRateHistory.map((data) => (
                    <tr key={data.id}>
                      <td>{data.uploader.email}</td>
                      <td>{data.originalFileName}</td>
                      <td>{dateFormatter(data.updatedAt)}</td>
                      <td>{data.Status}</td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CsvStaffHistoricRatesModal;
