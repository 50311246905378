import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { Button, CustomInput, FormGroup } from 'reactstrap';

function ComparativeDatePicker({selectedDashboardTemplate, selectedTemplate, item, index}) 
{
    const { bgColor, textColor } = useContext(MainContext);
    const [dateType, setDateType] = useState('month');
    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);

    useEffect(() => {

    }, [item, dateType])
    const handleSelectDateType = event => {
        setDateType(event.target.value);
    };

    const handleFromDateChange = date => {
        setDate1(date);
    };

    const handleToDateChange = date => {
        setDate2(date);
    };

    const handleFilter = () => {
        // Perform filtering logic here
        console.log('Filtering...', { date1, date2 });
    };
    return (
        item.isPinToDashboard ? null :
        <div className='comparativeContainer'>
            <FormGroup tag="fieldset">
                <CustomInput
                    label="By Month"
                    type="radio"
                    id="byMonth"
                    name="dateType"
                    value="month"
                    checked={dateType === 'month'}
                    onChange={handleSelectDateType}
                />
                <CustomInput
                    label="By Year"
                    type="radio"
                    id="byYear"
                    name="dateType"
                    value="year"
                    checked={dateType === 'year'}
                    onChange={handleSelectDateType}
                />
            </FormGroup>
            <div className='comparativeDatePicker'>
                {dateType === 'month' && (
                    <>
                        <label htmlFor="date1">Date 1 :</label>
                        <ReactDatePicker
                            selected={date1}
                            onChange={handleFromDateChange}
                            selectsStart
                            startDate={date1}
                            endDate={date2}
                            placeholderText="From Date"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                        <label htmlFor="date2">Date 2:</label>
                        <ReactDatePicker
                            selected={date2}
                            onChange={handleToDateChange}
                            selectsEnd
                            startDate={date1}
                            endDate={date2}
                            minDate={date1}
                            placeholderText="To Date"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </>
                )}
                {dateType === 'year' && (
                    <>
                        <label htmlFor="date1">Date 1 :</label>
                        <ReactDatePicker
                            selected={date1}
                            onChange={handleFromDateChange}
                            selectsStart
                            startDate={date1}
                            endDate={date2}
                            placeholderText="From Date"
                            dateFormat="yyyy"
                            showYearPicker
                        />
                        <label htmlFor="date2">Date 2:</label>
                        <ReactDatePicker
                            selected={date2}
                            onChange={handleToDateChange}
                            selectsEnd
                            startDate={date1}
                            endDate={date2}
                            minDate={date1}
                            placeholderText="To Date"
                            dateFormat="yyyy"
                            showYearPicker
                        />
                    </>
                )}
                <div style={{ backgroundColor: bgColor, color: textColor }} className='dateComparativeBtn' onClick={handleFilter}>Filter</div>
            </div>
        </div>
    )
}

export default ComparativeDatePicker