import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  CardImg,
  CardTitle,
  CardDeck,
  Button,
  Progress,
} from "reactstrap";
import { MainContext } from "context/mainContext";

const Courses = ({ id, courseData, userDetails, ...props }) => {
  const {
    textColor,
    bgColor,
    getProgressBarPercentage,
    progressbarPercentage,
  } = useContext(MainContext);
  const [progPercentage, setProgPercentage] = useState(0);
  // console.log("COURSE", courseData)

  useEffect(() => {
    // console.log(courseData)
    let userID = null;
    let isMounted = true;
    // fetch("/api/user/userDetails", { credentials: "include" })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // console.log(data)
    //     if (data) {
    //       userID = data.id;
    //     }
    //   })
    //   .then(() => {
    const fetchData = async() => {
      await fetch(`/api/video/getRecordPerCourse/${courseData.id}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log("TOTAL LECTURES", data.length)
        let lectureLength = data.length;
        fetch(
          `/api/courseProgress/getProgressPerUserAndCourse/${courseData.id}/${userDetails?.User?.UserID}`
        )
          .then((res) => res.json())
          .then((data) => {
            if(isMounted){ 
            // console.log("PROGRESS-data", data);
            data.length > 0 && lectureLength > 0
              ? setProgPercentage(
                  Math.round((data.length / lectureLength) * 100)
                )
              : setProgPercentage(0);
              }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    if(isMounted) fetchData();
    return () =>{
      isMounted = false;
    }
  }, [userDetails]);
  return (
    <>
      <Col key={props.id} sm="12" xl="3" lg="4" md="6">
        <Card className="bg-transparent shadow--hover">
          <CardImg
            alt="..."
            src={
              courseData.Image
                ? courseData.Image
                : require("assets/img/image-placeholder.jpg").default
            }
            top
          />
          <CardBody className="text-center">
            <CardTitle style={{ height: "50px" }}>
              <h4>{courseData.Name ? courseData.Name : ""}</h4>
            </CardTitle>
            <CardDeck style={{ height: "80px" }}>
              {/* <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <small>{courseData.Description ? courseData.Description : ""}</small>
                            </div> */}
              
              <div className="progress-wrapper">
                <div className="progress-info">
                  <div className="progress-label">
                    <span style={{ color: `#32325d` }}>Course completed</span>
                  </div>
                  <div className="progress-percentage">
                    <span>{`${progPercentage}%`}</span>
                  </div>
                </div>
                <Progress max="100" value={progPercentage} color="default" />
              </div>
            </CardDeck>
            <Button
              className="mt-3"
              color="primary"
              href={`/client/course/${courseData.CourseID}`}
              // onClick={e => e.preventDefault()}
            >
              View Course
            </Button>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Courses;
