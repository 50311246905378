import React, { useEffect, useState } from 'react'
import { TableHeader } from 'services/EVAEditGridService'

const EditableCell = ({
    parentId,
    sqlId,
    colIndex,
    cellValue,
    handleCellDoubleClick,
    handleCellClick,
    selectedCellId,
    cellFormulaList,
    processFormula,
    childListCellValue,
    onEditCell,
    handleClickedTD,
    clickedTD,
    selectedCellFormula
}) => {
    const tdId = `${TableHeader(colIndex)}${parentId.split("-")[0]}`
    const existingFormula = cellFormulaList.find(c => c.cellId === tdId && c.sqlId === sqlId)
    
    let cellFormulaValue = 0
    
    if(existingFormula)
    {
        cellFormulaValue = processFormula(existingFormula.formula, cellFormulaList, childListCellValue, tdId, sqlId)
    }

    const handleCellClickInternal = (tdId, parentId, sqlId) => {
        if(!onEditCell) return

        if(sqlId === "grand-total") handleCellClick(colIndex, parentId, sqlId);
        else handleCellClick(tdId, parentId, sqlId);
        handleClickedTD(tdId, sqlId);
    };
    const getRowData = (child,childIndex, tdId, parentId, sqlId) => {
        return (
          <td 
          onClick={() => {
            handleCellClickInternal(tdId, parentId, sqlId)
            }}
          className={`tdClass 
            ${parentId.includes('-TotalRow') ? `total-row-td` : `grand-total-row-td`} 
            ${getIsAmount(child)}
            ${onEditCell && selectedCellFormula.includes(tdId) ? 'cellClicked' : ''}`} 
          key={childIndex} 
          style={styleAlignRight(child)}>
            {isNaN(child) ? child : Number(child).toLocaleString()}
          </td>
        )
    }
    function getIsAmount(cl) {
        const newcl = JSON.stringify(cl).replace(/,/g, "").replace(/\$/g, "");
    
        if (cl === "Total Amount")
          return "isTotalAmount"
        const newNumber = parseFloat(newcl);
        if (!Number.isNaN(newNumber)) {
          return "isAmount";
        }
        return ""
      }
    const styleAlignRight = (value) => {
        const alignment = typeof value === 'string' ? !isNaN(Number(value.replace(/,/g, ''))) ? 'right' : value.includes('%') ? 'right' : 'left' : 'right';
        return { textAlign: alignment }
    }

    const renderTDValue =  //onEditCell && selectedCellId === tdId ? selectedCellFormula : 
            existingFormula ? cellFormulaValue : cellValue
    
    return (
        parentId.includes('-TotalRow') || parentId.includes('grand-total') ?
            // <td className='total-row-td'>{cellValue}</td>
            getRowData(cellValue, colIndex, tdId, parentId, sqlId)
        :
        <td 
            key={tdId}
            onClick={() => {
                handleCellClickInternal(tdId, parentId, sqlId)
            }}
            onDoubleClick={() => {
                handleCellDoubleClick(tdId, parentId, sqlId)
            }}
            style={styleAlignRight(renderTDValue)}
            className={`editTD
                    ${selectedCellId === tdId ? 'cellOnEdit' : ''} 
                    ${existingFormula ? 'withFormula' : ''} 
                    ${onEditCell && selectedCellFormula.includes(tdId) ? 'cellClicked' : ''}
                    ${`tdClass ${getIsAmount(renderTDValue)}`} 
                    $`}
        >
             {isNaN(renderTDValue) ? renderTDValue : Number(renderTDValue).toLocaleString()}
        </td>
    )
};

export default EditableCell