import React, { useContext, useState, useEffect } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { MainContext } from "context/mainContext";
import AccountantDetailsForm from "../details/AccountantDetailsForm";
import Clients from "../details/Clients";
import Authority from "../details/Authority";

const AccountantUpdateModal = ({firmDetails, ...props }) => {
  // eslint-disable-next-line
  const { bgColor, textColor, userDetails } = useContext(MainContext);
  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {}, [props, props.authority, props.data]);

  return (
    <>
      <div className="nav-wrapper p-0">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, tabs, 1)}
              href="#"
              role="tab"
              style={{
                backgroundColor: `${
                  tabs === 1 && bgColor === "#fff"
                    ? "#5e72e4"
                    : tabs === 1
                    ? bgColor
                    : "#fff"
                }`,
                color: `${
                  tabs === 1 && textColor === "#000"
                    ? "#fff"
                    : tabs === 1
                    ? textColor
                    : "#525f7f"
                }`,
              }}
            >
              <i className="ni ni-briefcase-24 mr-2" />
              Admin Details
            </NavLink>
          </NavItem>
          {/* {
            props.selectedAccountant.User.Status === 1 ? */}
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, tabs, 2)}
              href="#"
              role="tab"
              style={{
                backgroundColor: `${
                  tabs === 2 && bgColor === "#fff"
                    ? "#5e72e4"
                    : tabs === 2
                    ? bgColor
                    : "#fff"
                }`,
                color: `${
                  tabs === 2 && textColor === "#000"
                    ? "#fff"
                    : tabs === 2
                    ? textColor
                    : "#525f7f"
                }`,
              }}
            >
              <i className="ni ni-money-coins mr-2" />
              Clients
            </NavLink>
          </NavItem>
          {/* : null
          } */}
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, tabs, 3)}
              href="#"
              role="tab"
              style={{
                backgroundColor: `${
                  tabs === 3 && bgColor === "#fff"
                    ? "#5e72e4"
                    : tabs === 3
                    ? bgColor
                    : "#fff"
                }`,
                color: `${
                  tabs === 3 && textColor === "#000"
                    ? "#fff"
                    : tabs === 3
                    ? textColor
                    : "#525f7f"
                }`,
              }}
            >
              <i className="ni ni-lock-circle-open mr-2" />
              Authority
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="mt-5">
        <TabContent activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1">
            <AccountantDetailsForm
              data={props.data}
              handleInputChange={props.handleInputChange}
              selectedAccountant={props.selectedAccountant}
              avatar={props.avatar}
              isEmailValid={props.isEmailValid}
              isFirstNameValid={props.isFirstNameValid}
              isLastNameValid={props.isLastNameValid}
              isPhoneValid={props.isPhoneValid}
              isPositionValid={props.isPositionValid}
              handleImgChange={props.handleImgChange}
              isUpdate={true}
              firmDetails={firmDetails}
            />
            <Button
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              }}
              type="button"
              onClick={() => props.confirmUpdate("info")}
            >
              Save
            </Button>
          </TabPane>
          <TabPane tabId="tabs2">
            <Clients
              bgColor={bgColor}
              textColor={textColor}
              clients={props.clients}
              selectedClients={props.selectedClients}
              handleClientSelection={props.handleClientSelection}
              selectedAccountant={props.selectedAccountant}
              setRemovedClients={props.setRemovedClients}
              removedClients={props.removedClients}
            />
            <Button
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              }}
              type="button"
              onClick={() => props.confirmUpdate("clients")}
            >
              Save
            </Button>
          </TabPane>
          <TabPane tabId="tabs3">
            <Authority
              authority={props.authority}
              handleInputChange={props.handleInputChange}
              payload={props.data}
              userDetails={props.userDetails}
              selectedAccountant={props.selectedAccountant}
            />
            <div className="mt-3">
              <Button
                style={{
                  backgroundColor: `${
                    bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`,
                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                  borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                }}
                type="button"
                onClick={() => props.confirmUpdate("authority")}
              >
                Save
              </Button>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default AccountantUpdateModal;
