import React from "react";
import { Form, Modal } from "reactstrap";
import DynamicInputs from "components/Forms/DynamicInputs";
import { CustomButton } from "components/Button/CustomButton";

const UpdateModal = ({ user, i, ...props }) => {
  return (
    <Modal
      className="modal-dialog-top"
      isOpen={props.updateModal}
      toggle={() => props.handleUpdateUser}
      size="lg"
      backdrop="static"
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Update User
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.openUpdate()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <DynamicInputs
            data={[
              {
                id: "email",
                name: "Email",
                label: "Email",
                placeholder: "Email",
                type: "email",
                isRequired: true,
                onChange: props.handleInputChange,
                value: user.email,
              },
              {
                id: "firstName",
                name: "First Name",
                label: "First Name",
                placeholder: "First Name",
                type: "text",
                isRequired: true,
                onChange: props.handleInputChange,
                value: user.firstName,
              },
              {
                id: "lastName",
                name: "Last Name",
                label: "Last Name",
                placeholder: "Last Name",
                type: "text",
                isRequired: true,
                onChange: props.handleInputChange,
                value: user.lastName,
              },
              {
                id: "Avatar",
                name: "Avatar",
                label: "Avatar",
                placeholder: "",
                type: "file",
                isRequired: false,
                onChange: props.handleInputChange,
                value: user.avatar,
              },
            ]}
          />
        </Form>
      </div>
      <div className="modal-footer">
        <CustomButton
          type="button"
          onClick={() => props.handleSave()}
          outlineStyle={false}
          title=" Save changes"
        />
        <CustomButton
          onClick={() => props.handleUpdateUser()}
          className="ml-auto"
          data-dismiss="modal"
          type="button"
          color="link"
          outlineStyle={true}
          title="Close"
        />
      </div>
    </Modal>
  );
};

export default UpdateModal;
