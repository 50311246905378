import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "reactstrap";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";

const MFAGoogleAuthenticatorPage = () => {
  const location = useLocation();
  const routerHistory = useHistory();
  const [userDetails, setUserDetails] = useState("");
  const [mfaTextbox, setMfaTextbox] = useState("");
  const [confirmCodeEmail, setConfirmCodeEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [mfaCodeModal, setMfaCodeModal] = useState(false);
  const [message, setMessage] = useState("");
  const [mailData, setMailData] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountingLogo, setAccountingLogo] = useState("");
  const [saveMessageData, setSaveMessageData] = useState("");
  const [authToken, setAuthToken] = useState("");

  // Backup Email State
  const [backupEmailModal, setBackupEmailModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [backUpEmailMessage, setBackUpMessage] = useState("");
  const [backUpEmail, setBackEmail] = useState("");

  const [defaultScreen, setDefaultScreen] = useState(true);
  const [stepTwoNominateEmail, setStepTwoNominateEmail] = useState(false);
  const [stepThreeValidateEmail, setStepThreeValidateEmail] = useState(false);
  const [stepFourConfirmedEmail, setStepFourConfirmedEmail] = useState(false);

  // Security Question Screen

  const [defaultSecurityQuestionScreen, setSecurityQuestionScreen] =
    useState(false);
  const [questions, setQuestions] = useState([]);
  const [modalSecurityQuestions, setModalSecurityQuestion] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false)

  const defaultQuestions = [
    "As a child, what did you want to be when you grew up?",
    "What is your dream car?",
    "What is your dream job?",
    "What street did you live on when you were 10 years old?",
    "What was the best holiday gift you ever received?",
    "What was the name of your first pet?",
    "Who is your favourite fictional character?",
    "Who is your favourite person from history?",
    "Who is your favourite poet?",
    "What was your favourite place to visit as a child?",
    "What is the first name of your closest childhood friend?",
    "What road did you live on when you first started school?",
    "What road did your best friend in secondary/high school live on?",
    "What is the phone number you remember best from your childhood?",
    "What road did you live on when you attended secondary/high school?",
    "What is your most disliked movie?",
    "What is your most disliked holiday?",
    "Which movie scared you most as a child?",
    "What is the name of your favourite cartoon?",
    "what was the name of your favourite stuffed animal?",
    "What was the first concert you attended?",
  ];

  // TO DO : Add redirecting route if MFA setting is enabled

  useEffect(() => {
    const checkIfLogin = async () => {
      const details = await fetch("/api/auth/checkLogin", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await details.json();
      if (!data.authenticated) {
        return routerHistory.push("/auth/login");
      }
    };
    checkIfLogin();
  }, []);

  useEffect(() => {
    getToken();
    fetchData();
    fetchFirmLogo();
  }, [mailData]);

  const getToken = async () => {
    try {
      const generateSecretToken = await fetch("/api/auth/gAuthSecretToken", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await generateSecretToken.json();
      // console.log(data)
      setAuthToken(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const details = await fetch("/api/user/userDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await details.json();
      // console.log(data)
      setUserDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFirmLogo = () => {
    const firmLogo = async () => {
      try {
        const data = await fetch("/api/accountingFirm/getLogo", {
          method: "GET",
        });
        const logo = await data.json();
        setAccountingLogo(logo);
      } catch (error) {
        console.log(error);
      }
    };
    firmLogo();
  };

  const sendMFAEmail = () => {
    // setLoading(true);
    setBtnLoading(true)
    const sendMfaCode = async () => {
      try {
        const mail = await fetch("/api/auth/sendBackUpEmailMFACode", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromEmail: backUpEmail.toLowerCase(),
            accountingFirmLogo: accountingLogo.logo,
          }),
        });
        const data = await mail.json();
        // setLoading(false);
        setMailData(data);
        setBtnLoading(false)
        return setTimeout(() => {
          proceedStepThreeBE();
        }, 3000);
      } catch (error) {
        setBtnLoading(false)
        console.log(error);
      }
    };
    sendMfaCode();
  };

  // Step One
  const validateOnClick = () => {
    setBtnLoading(true)
    const validateGA = async () => {
      const auth = await fetch("/api/auth/verifyGoogleAuthenticator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          secretToken: authToken.secretToken,
          userToken: mfaTextbox,
        }),
      });
      const data = await auth.json();
      if (mfaTextbox === "") {
        setMessage("Google authenticator code required. Try again.");
        return setTimeout(() => {
          setMessage("");
        }, 1500);
      }
      if (!data.success) {
        // setModal(true);
        setBtnLoading(false)
        setMessage("Google authentication failed. Try again.");
        return setTimeout(() => {
          setMessage("");
        }, 1500);
      } else {
        setBtnLoading(false)
        setMessage("Google authenticator key has been validated");
        setTimeout(() => {
          setMessage("");
          proceedStepTwoBE();
        }, 1000);
      }
    };
    validateGA();
  };

  // Step Two
  const confirmBackUpEmailMFA = () => {
    if (backUpEmail === "") {
      setBackUpMessage("Email is required. Try again.");
      return setTimeout(() => {
        setBackUpMessage("");
      }, 1000);
    }

    if (!isValid) {
      setBackUpMessage("Email address is invalid. Try again");
      return setTimeout(() => {
        setBackUpMessage("");
      }, 1000);
    }

    const confirmBackUpEmail = async () => {
      try {
        const auth = await fetch("/api/auth/confirmMFABackupEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            backupEmail: backUpEmail.toLowerCase(),
          }),
        });
        const data = await auth.json();

        if (!data.success) {
          return setBackupEmailModal(true);
        }
        sendMFAEmail();
      } catch (error) {
        console.log(error);
      }
    };
    confirmBackUpEmail();
  };

  // Step Three

  const saveMFASettingsData = async () => {
    // setLoading(true);
    setBtnLoading(true)

    const mfaData = async () => {
      try {
        const mfaSettings = await fetch("/api/auth/saveBackUpEmailMFA", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            backupEmail: backUpEmail,
          }),
        });
        const data = await mfaSettings.json();
        // setLoading(false);
        setBtnLoading(false)
        setSaveMessageData(data);
        return setTimeout(() => {
          proceedStepFourBE();
        }, 2000);
      } catch (error) {
        setBtnLoading(false)
        console.log(error);
      }
    };

    try {
      const response = await fetch('/api/auth/verifyEmailCode', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          code: confirmCodeEmail,
          email: location.state.emailAddress,
          ubID: userDetails.ubID
        }),
      });

      const emailValidation = await response.json();

      if (emailValidation.error) {
        setLoading(false);
        return setMfaCodeModal(true);
      }

      return mfaData();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }

  };
  // Back up Email Steps - State Change
  const proceedStepTwoBE = () => {
    setStepTwoNominateEmail(true);
    setDefaultScreen(false);
    setStepThreeValidateEmail(false);
    setStepFourConfirmedEmail(false);
    setSecurityQuestionScreen(false);
  };

  const proceedStepThreeBE = () => {
    setStepThreeValidateEmail(true);
    setDefaultScreen(false);
    setStepTwoNominateEmail(false);
    setStepFourConfirmedEmail(false);
    setSecurityQuestionScreen(false);
  };

  const proceedStepFourBE = () => {
    setStepFourConfirmedEmail(true);
    setSecurityQuestionScreen(false);
    setStepThreeValidateEmail(false);
    setDefaultScreen(false);
    setStepTwoNominateEmail(false);
  };

  // Security Question Screen
  const securityQuestionDefaultPage = () => {
    setSecurityQuestionScreen(true);
    setDefaultScreen(false);
    setStepTwoNominateEmail(false);
    setStepThreeValidateEmail(false);
    setStepFourConfirmedEmail(false);
  };

  // Handlers
  const mfaOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMfaTextbox(value);
  };

  const confirmEmailCodeInputHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setConfirmCodeEmail(value);
  };

  const onConfirmCodeEmail = () => {
    // proceedStepFourBE();
    saveMFASettingsData();
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  const closeModalHandlerBackupEmail = () => {
    setBackupEmailModal(false);
  };

  const closeModalMfaCodeVerification = () => {
    setMfaCodeModal(false);
  };

  const closeModalHandlerSecurityQuestions = () => {
    setModalSecurityQuestion(false);
  };

  const backUpEmailOnChangeHandler = (e) => {
    const value = e.target.value;
    setBackEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(value));
  };

  // Security Questions
  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", answer: "" }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    const isDuplicate = newQuestions.some(
      (question, i) => i !== index && question.question === value
    );
    if (isDuplicate) {
      setModalSecurityQuestion(true);
      newQuestions[index].question = "";
      return;
    }
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = value;
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const canSaveQuestions = () => {
    if (questions.length < 3) {
      return false;
    }
    return questions.every(
      (q) => q.question.trim() !== "" && q.answer.trim() !== ""
    );
  };

  const submitSecurityQuestions = (event) => {
    event.preventDefault();
    setLoading(true);
    const saveSecQuestionData = async () => {
      try {
        const answers = await fetch("/api/auth/saveSecurityQuestions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            questions: questions,
          }),
        });
        const data = await answers.json();
        if (data.success) {
          setLoading(false);
          setMessage("Your answers are now saved.");
          return setTimeout(() => {
            proceedStepFourBE();
          }, 1500);
        } else {
          setLoading(false);
          return setMessage("Something went wrong saving the date. Try again.");
        }
      } catch (error) {
        console.log(error);
      }
    };
    saveSecQuestionData();
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modalSecurityQuestions}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Duplicate question
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Please choose a different question to proceed.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerSecurityQuestions}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={modal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid MFA Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            The multi-factor authentication code could not be verified. Enter
            the correct pin found on your Google Authenticator app and try
            again.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={backupEmailModal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Email Address
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Please provide an alternate email address that is distinct from the
            one used for your login.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerBackupEmail}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={mfaCodeModal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Authentication Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Check your secondry email inbox and copy the correct code.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalMfaCodeVerification}
          >
            Close
          </Button>
        </div>
      </Modal>
      {defaultScreen && (
        <div className="mfa-push-notification-container-blk">
          <div></div>
          <>
            <h1>Setup Google Authenticator</h1>
            <div className="form-mfa-container-blk">
              <hr />
              <div>
                <p>
                  <b>Step 1:</b> Scan the QR Code below using Google
                  authenticator app.
                </p>
                {!authToken ? (
                  <div
                    style={{
                      width: "40%",
                      height: "240px",
                      margin: "0 auto",
                      display: "block",
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      Generating QR Code
                    </p>
                  </div>
                ) : (
                  <img
                    alt="QrCode"
                    style={{ width: "40%", margin: "0 auto", display: "block" }}
                    src={authToken.qrCode}
                  />
                )}
                <p>
                  <b>Step 2:</b> Enter code found on the google authenticator
                  app and click "Verify".
                </p>
                <div className="form-blk-mfa">
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                    value={mfaTextbox}
                    placeholder="Enter your code here..."
                    onChange={mfaOnChangeHandler}
                    maxLength={6}
                  />
                </div>
                {!message ? (
                  ""
                ) : (
                  <div
                    className={
                      message ===
                        "Google authenticator code required. Try again." ||
                        message === "Google authentication failed. Try again."
                        ? "message-blk-validation-error"
                        : "message-blk-validation-success"
                    }
                  >
                    {message}
                  </div>
                )}
                <div
                  className="card-flex-blk button-blk-mfa-container"
                  style={{ marginTop: "15px", marginBottom: "10px" }}
                >
                  <div>
                    <button
                      className="mfa-btn-blk-class"
                      onClick={() => {
                        routerHistory.push("/client/accountant/details");
                      }}
                      disabled={btnLoading ? true : false}
                    >
                      Skip for now
                    </button>
                  </div>
                  <div>
                    <button
                      className="mfa-btn-blk-class verify-blk-btn"
                      onClick={validateOnClick}
                      disabled={btnLoading ? true : false}
                    >
                      {
                        btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                          :
                          <div>
                            Verify
                            <span className="btn-inner--icon"></span>
                          </div>
                      }
                    </button>
                  </div>
                </div>
              </div>

              <hr style={{ marginTop: "15px" }} />
              <Link
                to={{
                  pathname: "/auth/multi-factor-push-notification-setup",
                  state: routerHistory.location.state.emailAddress,
                }}
              >
                Do you want to setup up MFA using App verify instead?
              </Link>
            </div>
          </>
        </div>
      )}
      {stepTwoNominateEmail && (
        <div
          className="mfa-push-notification-container-blk"
          style={{ height: "350px" }}
        >
          <div></div>
          <>
            <h1>Setup Backup Email Address</h1>
            <div className="form-mfa-container-blk">
              <hr />
              <div>
                <p>
                  <b>Step 3:</b> This email is only used to log in to your
                  account if you're locked out or don't have your phone.
                  <br />
                </p>
                <div className="form-blk-mfa">
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      marginTop: "0px",
                    }}
                    value={backUpEmail}
                    placeholder="Enter email address..."
                    onChange={backUpEmailOnChangeHandler}
                  />
                  {!backUpEmailMessage ? (
                    ""
                  ) : (
                    <div
                      className={
                        backUpEmailMessage ===
                          "Email is required. Try again." ||
                          backUpEmailMessage ===
                          "Email address is invalid. Try again"
                          ? "message-blk-validation-error"
                          : "message-blk-validation-success"
                      }
                    >
                      {backUpEmailMessage}
                    </div>
                  )}

                  <i
                    style={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "block",
                    }}
                  >
                    <b>Note:</b> It must be different to the email you are using
                    when logging in to your account
                  </i>
                </div>

                <div
                  className="card-flex-blk button-blk-mfa-container"
                  style={{ marginBottom: "10px" }}
                >
                  <div>
                    <button
                      className="mfa-btn-blk-class"
                      onClick={() => {
                        routerHistory.goBack();
                      }}
                      disabled={btnLoading ? true : false}
                    >
                      Back to Prev Page
                    </button>
                  </div>
                  
                  <div>
                    <button
                      className="mfa-btn-blk-class verify-blk-btn"
                      onClick={confirmBackUpEmailMFA}
                      disabled={btnLoading ? true : false}
                    >
                      {
                        btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                          :
                          <div>
                            CONTINUE
                            <span className="btn-inner--icon"></span>
                          </div>
                      }
                    </button>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <Spinner style={{ display: "block", margin: "0 auto" }} />
                  ) : (
                    <div
                      className={
                        mailData.length === 0
                          ? ""
                          : mailData.message ===
                            "Verify your secondary email to retrieve the authentication code."
                            ? "message-blk-validation-success"
                            : "message-blk-validation-error"
                      }
                    >
                      {mailData.message}
                    </div>
                  )}
                </div>
              </div>
              <hr style={{ marginTop: "15px" }} />
              <Link onClick={btnLoading ? null : securityQuestionDefaultPage}>
                Use security questions as your back up method instead
              </Link>
            </div>
          </>
        </div>
      )}
      {stepThreeValidateEmail && (
        <div
          className="mfa-push-notification-container-blk"
          style={{ height: "350px" }}
        >
          <div></div>
          <>
            <h1>Setup Backup Email Address</h1>
            <p>Check your back up email and enter the code below</p>
            <div className="form-mfa-container-blk">
              <hr />
              <div>
                <p>
                  <b>Step 4:</b> Enter the code sent to <b>{backUpEmail}</b> to
                  confirm.
                  <br />
                </p>
                <div className="form-blk-mfa">
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      marginTop: "0px",
                      marginBottom: "8px",
                    }}
                    value={confirmCodeEmail}
                    placeholder="Enter your code here..."
                    onChange={confirmEmailCodeInputHandler}
                    maxLength={6}
                  />
                </div>

                <div
                  className="card-flex-blk button-blk-mfa-container"
                  style={{ marginBottom: "10px" }}
                >
                  <div>
                    <button
                      className="mfa-btn-blk-class"
                      onClick={() => {
                        routerHistory.goBack();
                      }}
                      disabled={btnLoading ? true : false}
                    >
                      Back to Prev Page
                    </button>
                  </div>

                  <div>
                    <button
                      className="mfa-btn-blk-class verify-blk-btn"
                      onClick={onConfirmCodeEmail}
                      disabled={btnLoading ? true : false}
                    >
                      {
                        btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                          :
                          <div>
                            CONTINUE
                            <span className="btn-inner--icon"></span>
                          </div>
                      }
                    </button>
                  </div>
                </div>
              </div>
              {loading ? (
                <Spinner
                  style={{ display: "block", margin: "25px auto 0px auto" }}
                />
              ) : (
                <div
                  className={
                    saveMessageData.length === 0
                      ? ""
                      : saveMessageData.message ===
                        "Your account's Multi-Factor Authentication has been set up successfully."
                        ? "message-blk-validation-success"
                        : "message-blk-validation-error"
                  }
                >
                  {saveMessageData.message}
                </div>
              )}
              <hr style={{ marginTop: "15px" }} />
            </div>
          </>
        </div>
      )}
      {stepFourConfirmedEmail && (
        <div
          className="mfa-push-notification-container-blk"
          style={{ height: "250px" }}
        >
          <div></div>
          <>
            <h1>SETUP COMPLETE</h1>
            <p>
              Next time you log in, you'll need to open your authenticator app
              and use the 6-digit code to authenticate your login
            </p>
            <button
              style={{
                width: "50%",
                margin: "0 auto",
                display: "block",
              }}
              className="mfa-btn-blk-class verify-blk-btn"
              onClick={() => {
                return routerHistory.push('/api/auth/logout')
              }}
            >
              <div>
                CONTINUE
                <span className="btn-inner--icon"></span>
              </div>
            </button>
          </>
        </div>
      )}
      {defaultSecurityQuestionScreen && (
        <div className="mfa-push-notification-container-blk">
          <div></div>
          <h1>Security Questions</h1>
          <p>
            Add three security questions below. These questions will help us
            verify your identity should you not have access to Google
            Authenticator app
          </p>
          <div>
            <hr />
            <form onSubmit={submitSecurityQuestions}>
              {questions.map((question, index) => (
                <div
                  key={index}
                  className="question"
                  style={{
                    background: "#f6f6f6",
                    margin: "10px 0px 0px 0px",
                    padding: "20px 20px 0px 20px",
                  }}
                >
                  <div>
                    <label htmlFor={`question-${index}`}>
                      Question {index + 1} :
                    </label>
                    <select
                      style={{
                        width: "82%",
                        marginLeft: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      id={`question-${index}`}
                      value={question.question}
                      onChange={(event) =>
                        handleQuestionChange(index, event.target.value)
                      }
                    >
                      <option value="">Select a question...</option>
                      {defaultQuestions.map((q, i) => (
                        <option key={i} value={q}>
                          {q}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      margin: "10px 0px 0px 0px",
                      padding: "10px 0px 10px 10px",
                    }}
                  >
                    <label htmlFor={`answer-${index}`}>Answer : </label>
                    <input
                      id={`answer-${index}`}
                      style={{
                        width: "86%",
                        marginLeft: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      type="text"
                      value={question.answer}
                      onChange={(event) =>
                        handleAnswerChange(index, event.target.value)
                      }
                    />
                    <button
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginTop: "20px",
                        marginRight: "10px",
                        borderRadius: "5px",
                      }}
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              {questions.length >= 3 ? (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  disabled
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    borderColor: "#fff",
                    background: "#dadae1",
                    color: "#979797;",
                    width: "50%",
                    paddingRight: "10px",
                  }}
                >
                  Add Security Question
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "20px",
                    width: "50%",
                    paddingRight: "10px",
                  }}
                >
                  Add Security Question
                </button>
              )}
              {!canSaveQuestions() ? (
                <button
                  type="submit"
                  disabled
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    borderColor: "#dadae1",
                    background: "#dadae1",
                    color: "#979797;",
                    width: "49%",
                    marginLeft: "5px",
                  }}
                >
                  Save Security Questions
                </button>
              ) : (
                <button
                  type="submit"
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    width: "49%",
                    marginLeft: "5px",
                  }}
                >
                  Save Security Questions
                </button>
              )}
            </form>
            {loading ? (
              <Spinner
                style={{ display: "block", margin: " 30px auto 0px auto" }}
              />
            ) : !message ? (
              ""
            ) : (
              <div
                className={
                  message === "Your answers are now saved."
                    ? "message-blk-validation-success"
                    : "message-blk-validation-error"
                }
              >
                {message}
              </div>
            )}
            <hr />
            <Link
              to="/auth/multifactor"
              style={{
                display: "block",
                textAlign: "center",
                fontSize: "14px",
                color: "#32325d",
                paddingTop: "20px",
                paddingBottom: "50px",
              }}
            >
              Go Back to Setup Page
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default MFAGoogleAuthenticatorPage;
