export const seatPricingDetailsList = [
    {
        id: 1,
        header: "EVA - Chat GPT - Data Enabled (Beta)",
        details: "With EVA, a simple chat command is all it takes to:",
        list: [
            {
                listHeader: "Customise Report Build:",
                listDetails: "Tell EVA what you need, and she’ll craft your report with precision."
            },
            {
                listHeader: "Advice on Demand:",
                listDetails: "Ask for guidance, and EVA delivers immediate advice."
            },
            {
                listHeader: "Surface Specific Information:",
                listDetails: "Request a deep dive on specific data points, and EVA brings them to the forefront."
            },
            {
                listHeader: "Provide Comparatives:",
                listDetails: "Seek benchmarking, and EVA offers comparatives for insightful analysis."
            },
        ]
    },
    {
        id: 2,
        header: "Share Reports in Charts",
        details: "Easily share reports with your teams or group chats using the readily available share functionality, all with a simple click."
    },
    {
        id: 3,
        header: "Pin to Dashboard",
        details: "Pin important reports to your dashboard for quick access to key data and insights right when you need them."
    },
    {
        id: 4,
        header: "Collaborate with Team and EVA in Chat",
        details: "Tag EVA in group chats to ask questions and keep your team aligned with real-time insights."
    },
    {
        id: 5,
        header: "Mobile App",
        details: "Access your data anywhere, anytime with the Etani mobile app, with a branded mobile app option available for select plans to align with your business identity."
    },
    {
        id: 6,
        header: "Data 'Refresh Now' Functionality",
        details: "Instantly update your data with the 'Refresh Now' functionality, ensuring you always have the most current insights."
    },
    {
        id: 7,
        header: "2FA",
        details: "Enhanced account security with 2FA, featuring options like Google Authentication, Mobile Verify, and more."
    },
    {
        id: 8,
        header: "Support",
        details: "Get help from a dedicated support team, ready to provide prompt assistance with any questions or issues you might encounter."
    },
    {
        id: 9,
        header: "*Power BI Embedded",
        details: "Power BI is seamlessly integrated for instant access to dynamic visualisations and analytics."
    },
    {
        id: 10,
        header: "*Power BI Reports & Templates",
        details: "Access a range of Power BI reports and templates out of the box and ready to use."
    },
    {
        id: 11,
        header: "*PDF Email Reports",
        details: "Easily generate and send PDF reports via email, providing a convenient way to share insights and updates with stakeholders."
    },
    {
        id: 12,
        header: "*Shared Azure Data Warehouse",
        details: "Secure cloud-based Azure Data Warehouse, providing a shared, scalable, and reliable data storage solution."
    },
]