// import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements, PaymentElement, CardElement, LinkAuthenticationElement, AddressElement, IbanElement } from "@stripe/react-stripe-js";
import { CustomButton } from "components/Button/CustomButton";
import {
  Row,
  Col,
  Button
} from 'reactstrap'
import { CustomModalButton } from "components/Button/CustomButton";

const CheckoutForm = ({ stripeSuccess, ...props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [mounted, setMounted] = useState(false);
  const [error, setError] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [email, setEmail] = useState(props.userDetails?.User?.Email)
  const [name, setName] = useState(`${props.userDetails?.User?.FirstName} ${props.userDetails?.User?.LastName}`);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmitPaymentElement = async (e) => {
    setMessage(null)
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const result = await stripe.confirmSetup({
      elements,
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: `${window.location.origin}/payment/status`,
      // },
      redirect: "if_required",
    });

    if (result?.setupIntent?.status !== 'succeeded') {
      // console.log(result)
      setMessage(result?.setupIntent?.status);
    }

    if (result.error) {
      setMessage(result?.error?.message);
    }

    if (result?.setupIntent?.status === 'succeeded') {
      if (props.firstTimeSetup) {
        if (props.sharedSetup) {
          let token = props.token
          let authToken = `Bearer ${token}`;
          let headers = new Headers({
            'Authorization': authToken
          });
          fetch("/api/trialUser/subscribeToPlanShared", {
            headers: headers,
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              success: result.setupIntent.status,
              bcode: props.firmDetails.Code,
              email: props.firmDetails.Email,
              name: props.firmDetails.Name
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log(data)
              if (data && !data.error) {
                fetch("/api/stripe/setupDefaultPaymentShared", {
                  headers: headers,
                  method: "POST",
                  credentials: "include",
                  body: JSON.stringify({
                    email: props.firmDetails.Email,
                    description: props.description,
                    bcode: props.firmDetails.Code,
                  }),
                })
                  .then(res => res.json())
                  .then(data => {
                    // console.log(data)
                  })
              }
            })
        } else {
          fetch("/api/trialUser/subscribeToPlan", {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              success: result.setupIntent.status
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log(data)
              if (data && !data.error) {
                fetch("/api/stripe/setupDefaultPayment", {
                  method: "POST",
                  credentials: "include",
                  body: JSON.stringify({
                    email: props.firmDetails.Email,
                    description: props.description,
                    bcode: props.firmDetails.Code,
                  }),
                })
                  .then(res => res.json())
                  .then(data => {
                    // console.log(data)
                  })
              }
            })
        }
      } else {
        fetch("/api/stripe/updatePaymentDetails")
          .then((res) => res.json())
          .then((data) => {
            console.log(data)
          })
      }
    }
    // console.log(result)

    setIsProcessing(false);
    stripeSuccess(result)

  };

  const availablePaymentMethods = ["card", "sepa_debit"];

  const paymentElementOptions = {
    layout: "tabs",
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };


  return (
    <>
      <form id="payment-form" onSubmit={handleSubmitPaymentElement}>
        <PaymentElement id="payment-element"
          options={paymentElementOptions}
        />
        <div className="mt-3">
          {
            props.sharedSetup ?
              <Button
                style={{ backgroundColor: "#272A65", borderColor: "#272A65", color: "#ffffff", width: "100%" }}
                className="mt-2"
                color="primary"
                type="submit"
              >
                {isProcessing ? "Processing ... " : props.saveType === "updatePayment" ? 'Update' : props.saveType === "setupPayment" ? "Setup" : "Proceed Now"}
              </Button>
              :
              <CustomButton
                type="submit"
                outlineStyle={false}
                title={isProcessing ? "Processing ... " : props.saveType === "updatePayment" ? 'Update' : props.saveType === "setupPayment" ? "Setup" : "Start Trial"}
                disabled={isProcessing || !stripe || !elements}
                buttonstyle={props.buttonstyle}
              />
          }
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}

export default CheckoutForm
