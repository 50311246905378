import React, { useContext } from "react";
import { NavItem } from "reactstrap";
import { MainContext } from "context/mainContext";
// import classnames from "classnames";
import { NavLink } from "react-router-dom";

const CustomTab = ({ title, link, className, tabs, ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  return (
    <NavItem className="navColor">
      <NavLink
        activeStyle={{
          // border: `1px solid ${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
          color: `${textColor === "#525f7f" ? "#525f7f" : "#fff"}`,
          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
        }}
        style={{
          color: `${textColor === "#000" ? "#000" : "#525f7f"}`,
          backgroundColor: `${bgColor === "#fff" ? bgColor : "#fff"}`,
          fontSize: ".8rem",
        }}
        to={link}
        role="tab"
        className={className}
        {...props}
      >
        {title}
      </NavLink>
    </NavItem>
  );
};

export default CustomTab;
