import React, { useEffect, useState } from 'react';

function EVATyping({ text, delay, infinite, isShow }) {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (text && currentIndex <= text.length - 1) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      setCurrentIndex(0);
      setCurrentText('');
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentIndex, delay, infinite, text]);

  return (
    <div className="chatLoading">
      <i className='mb-2 last:mb-0'>EVA is typing {currentText}</i>
    </div>
  );
}

export default EVATyping;
