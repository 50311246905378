import { IconEdit } from 'chat-components/ui/icon';
import { IconTrash } from 'chat-components/ui/icon';
import { IconExpand } from 'chat-components/ui/icon';
import { IconMinimize } from 'chat-components/ui/icon';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import GraphView from 'eva-component/response/GraphView';
import GridView from 'eva-component/response/GridView';
import TreeView from 'eva-component/response/TreeView';
import React, { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-date-picker';
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { evaThemeLighter } from 'services/EVAResponseService';
import { isWithChildHandler } from 'services/EVAResponseService';
import { convertToTreeViewData } from 'services/EVAResponseService';
import { registerDashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from 'react-datepicker';
import ComparativeDatePicker from 'components/ComparativeDatePicker/ComparativeDatePicker';
import DashboardItemHeader from './DashboardItemHeader';
import DashboardVisual from 'components/DashboardVisual/DashboardVisual';
import DashboardTemplateItemNewVisual from './DashboardTemplateItemNewVisual';
import { reFormatEVADateRange } from 'services/EVAResponseService';
import { PopulateNewEVAResponseFormat } from 'services/EVAResponseService';
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService';
import { PopulateNewRows } from 'services/EVAEditGridService';
import { TransformSQLResult } from 'services/EVAEditGridService';
import { ProcessRenamingSQLData } from 'services/EVAEditGridService';
import { ProcessGrandTotal } from 'services/EVAEditGridService';
import { GetCellIdValue } from 'services/EVAEditGridService';
import { ProcessGrandParentRow } from 'services/EVAEditGridService';
import { ProcessRemoveRow } from 'services/EVAEditGridService';
import { UpdateCellWithFormula } from 'services/EVAEditGridService';
import { RecalculateWithTotal } from 'services/EVAEditGridService';
import { ProcessGroupWithTotal } from 'services/EVAEditGridService';
import { ProcessSQLGroupWithTotal } from 'services/EVAEditGridService';

function DashboardTemplateItem({ handleSetItemIndexToEdit, isEditIndex, editMode = false , selectedDashboardTemplate, selectedTemplate, item, index, handleNewUpdate, handleDeleteDashboardTemplateItem}) 
{
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [toggleAll, setToggleAll] = useState(false)
    const [sqlresult, setSqlResult] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [onEditName, setOnEditName] = useState(false)
    const [queryResult, setQueryResult] = useState(null)
    const [alert, setAlert] = useState(false);
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [selectedViewOption, setSelectedViewOption] = useState(null)
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const [dataValue, setDataValue] = useState(null)
    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    const [groupLevel, setGroupLevel] = useState(null)

    useEffect(() => {
        const processData = async () => {
            try{
                
                if(item.visual)
                {
                    setSelectedViewOption(item.visual.selectedViewOption)
                    setTemplateItemVisual(item.visual)
                }

                const res = await submitEvaQueryHandler(item.query, selectedClient, firmDetails, userDetails);
                setQueryResult(res)
                if(!res) {
                    setError(true)
                    setLoading(false);
                    return
                }
                
                const sqlresult = res.map(obj => Object.values(obj));
                const header = res.length > 0 ? Object.keys(res[0]) : [];
                const newRes = await reFormatEVADateRange(header, sqlresult)
                setDataValue(newRes.dataValue)
                const treeViewData = convertToTreeViewData(sqlresult)
                const isWithChild = isWithChildHandler(treeViewData)
                const { result, groupLevel } = PopulateNewEVAResponseFormat(sqlresult, isWithChild, header);
                const newResult = ProcessSQLGroupWithTotal(result, header, groupLevel)
                setGroupLevel(groupLevel)

                const newTreeData = processEditedGridCellData(item, sqlresult, header)

                if(newTreeData) setTreeViewData(newTreeData)
                else setTreeViewData(result)

                setEVAData(res)
                setSqlResult(sqlresult)
                setGridHeader(header)
                // setTreeViewData(treeViewData)
                setIsWithChild(isWithChild)
                setLoading(false)
                setError(false)
            }catch (error)
            {
                console.log(error)
                setError(true)
                setLoading(false);
            }
        }
        processData();
    }, [selectedClientID, newUpdateCount])

    useEffect(() => {
        setOnEditName(isEditIndex === index)
    },[isEditIndex])
    const processEditedGridCellData = (item, sqlresult, gridHeader) => {
        if(item && item.editedGridCellData)
        {
            const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow, renamedHeaderList, addedColumnList } = item.editedGridCellData
            let header = gridHeader
            let sqlData = sqlresult
            let updatedNewRowList = newRowList

            const processNewHeader = (columnHeader, sqlData, newRowList) => {
                let header = [...columnHeader];
                let updatedData = [...sqlData];
                let updateRowList = JSON.parse(JSON.stringify(newRowList));
            
                if (addedColumnList.length > 0) {
                    addedColumnList.map(item => {
                        header.push(item.name);
                        addZeroToRow(updateRowList);
                    });
                    updatedData = sqlData.map(item => [...item, 0]);
                }
            
                function addZeroToRow(updateRowList) {
                    for (let i = 0; i < updateRowList.length; i++) {
                        const row = updateRowList[i];
                        if (row.value) row.value = [...row.value, 0];
                        if (row.children) addZeroToRow(row.children);
                    }
                }
                return { header, updatedData, updateRowList };
            };
            
            if(addedColumnList) {
                const headerData = processNewHeader(gridHeader, sqlData, newRowList);
                header = headerData.header
                sqlData = headerData.updatedData
                updatedNewRowList = headerData.updateRowList
            }

            if(renamedHeaderList) header = ProcessColumnRename(renamedHeaderList, header)
            
            let treeData = []

            treeData = TransformSQLResult(sqlData, header, gridGroupLevel)
           

            treeData = PopulateNewRows(updatedNewRowList, sqlData, header, gridGroupLevel)

            if(dragAndDropData.length > 0) 
            {
                treeData = ProcessDnDTreeviewData(dragAndDropData, treeData)
                const isWithGroup = dragAndDropData.filter(g => g.parentId && g.parentId.includes("-group-"))
                if(isWithGroup.length > 0)
                {
                    treeData = RecalculateWithTotal(treeData)
                }
            }

            let cellValue = GetCellIdValue(treeData)


            treeData = ProcessGroupWithTotal(treeData, cellValue, header, gridGroupLevel, cellFormulaList)
        
            const processRenaming = (treeData) => {
                if (renamedSQLData && renamedSQLData.length > 0) {
                    return ProcessRenamingSQLData(renamedSQLData, treeData);
                }
                return treeData;
            };
     
            treeData = ProcessGrandTotal(treeData, cellValue, updatedNewRowList, header, gridGroupLevel, cellFormulaList)
            cellValue = GetCellIdValue(treeData)
            treeData = ProcessGrandParentRow(updatedNewRowList, treeData)
            treeData = processRenaming(treeData);
            treeData = ProcessRemoveRow(treeData, hiddenRow)
            if(cellFormulaList) {
                cellValue = GetCellIdValue(treeData)
                treeData = UpdateCellWithFormula(treeData, cellValue, cellFormulaList)
            }

            return treeData
        }
        return null
    }
    const ProcessColumnRename = (renamedHeaderList, gridHeader) => {
        if (renamedHeaderList?.length === 0 || !renamedHeaderList)
        { 
            setGridHeader(gridHeader);
            return gridHeader
        }

        const newColumnHeader = [...gridHeader]

        renamedHeaderList && renamedHeaderList.map((item, index) => {
            newColumnHeader[item.colIndex] =  item.newHeader
        })

        setGridHeader(newColumnHeader);
        return newColumnHeader
    }
    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    }
    const ItemFullScreenView = () => {
        return (
            <div className='ItemFullScreenViewContainer'>
                <div  className='ItemFullScreenViewContent'>
                    <ItemContentView />
                </div>
            </div>
        )
    }

    const ItemContentView = () => {
        return (
            <>
            {alert}


            <div className={`dashboardItems ${templateItemVisual ? onEditName ? 'col-xs-6' : 'col-xs-12 col-md-6' : ''}`} style={{ border: `1px solid ${bgColor}` }} key={index}>
                {loading ? <CustomSpinner /> :
                    isError && !loading ? 
                    <>
                        <div className="dashboardItems" style={{
                            height: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center horizontally
                            justifyContent: 'center', // Center vertically
                            flexWrap: 'wrap'
                        }}>
                            <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                            <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                        </div>
                    </>
                    :
                    queryResult && queryResult.length > 0 ?
                    <div className="dashboardItemBody" style={{ padding: 20}}>
                        {onEditName ?
                            <>
                                {/* <ComparativeDatePicker
                                    selectedDashboardTemplate={selectedDashboardTemplate} 
                                    selectedTemplate={selectedTemplate} 
                                    item={item} 
                                    index={index}  
                                /> */}

                                <DashboardVisual 
                                    item={item}
                                    index={index} 
                                    selectedDashboardTemplate={selectedDashboardTemplate}
                                    gridHeader={gridHeader}
                                    sqlresult={sqlresult}
                                    dataValue={dataValue}
                                    theme={theme} 
                                    treeViewData={treeViewData}
                                    isWithChild={isWithChild}
                                    isShowGrid={isShowGrid}
                                    toggleAll={toggleAll}
                                    isShowGrap={isShowGrap}
                                    toggleFullScreen={toggleFullScreen}
                                    handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                    handleNewUpdate={handleNewUpdate}
                                    templateItemVisual={templateItemVisual}
                                    groupLevel={groupLevel}
                                />
                            </>
                        :
                            <>
                                {templateItemVisual && selectedViewOption !== 'Grid' ?
                                <>
                                    
                                    <DashboardTemplateItemNewVisual 
                                        item={item}
                                        index={index}
                                        gridHeader={gridHeader}
                                        sqlresult={sqlresult}
                                        dataValue={dataValue}
                                        templateItemVisual={templateItemVisual}
                                        theme={theme}
                                        treeViewData={treeViewData}
                                        isWithChild={isWithChild}
                                        isShowGrid={isShowGrid}
                                        toggleAll={toggleAll}
                                        isShowGrap={isShowGrap}
                                        groupLevel={groupLevel}
                                    />
                                </>
                                :
                                <>
                                    <DashboardItemHeader 
                                        onEditName={onEditName}
                                        item={item}
                                        editMode={editMode}
                                        index={index}
                                        handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                        handleDeleteDashboardTemplateItem={handleDeleteDashboardTemplateItem}
                                        selectedDashboardTemplate={selectedDashboardTemplate}
                                        toggleFullScreen={toggleFullScreen}
                                        isFullScreen={isFullScreen}
                                    />
                                    <div className='EVAResponseContainer' style={{ display: 'unset', padding: 0 }}>
                                        <div className='EVADataContent'>
                                            <TreeView
                                                theme={theme} 
                                                sqlresult={sqlresult} 
                                                gridHeader={gridHeader}
                                                treeViewData={treeViewData}
                                                toggleAll={toggleAll}
                                                groupLevel={groupLevel}
                                            />
                                        </div>
                                    </div>
                                </>
                                }
                                
                            </>
                        }
                    </div>
                :
                    <>
                        <div className="dashboardItems" style={{
                            height: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center horizontally
                            justifyContent: 'center', // Center vertically
                            flexWrap: 'wrap'
                        }}>
                            <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                            <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                        </div>
                    </>
                }
            </div>
            </>
        )
    }

    return (
        isFullScreen ? 
        <ItemFullScreenView /> :
        <ItemContentView />
        // </Col>
    )
}

export default DashboardTemplateItem