import React, { useState, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardImg,
  CardBody,
  FormGroup
} from "reactstrap";

const CancellationSuccess = () => {
  const [transitionStage, setTransistionStage] = useState("fadeFrom");
  const routerLocation = useLocation();
  const [displayLocation, setDisplayLocation] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (routerLocation === displayLocation) setTransistionStage("fadeTo");
    // let url = window.location.pathname.split("/");
    // console.log(url);
    // let urlOrigin = window.location.origin;
    // console.log(window.location.origin);
    // let jwt = url[url.length - 1];
    // setTimeout(() => {
    //   window.location.replace(
    //     `${urlOrigin}/api/auth/acceptInvitation?access_token=${jwt}`
    //   );
    // }, 2500);

    // fetch(`/api/auth/acceptInvitation?access_token=${jwt}`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }, [routerLocation, displayLocation]);

  const backToLogin = () => {
    return history.push(
      `/auth/login`
    );
  }

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="8"
              md="8"
              sm="12"
              xs="12"
              className={`${transitionStage} mt-2 px-0 px-lg-3`}
            >
              <Card
                className="bg-transparent p-sm-2 py-3 p-lg-4 mx-3 shadow-none"
                style={{ overflow: "hidden" }}
              >
                <CardImg
                  alt="Login"
                  src={require(`assets/img/success-36.svg`).default}
                  style={{ width: "80px", margin: "0 auto" }}
                  className="spin justify-content-center text-center rounded my-3"
                  top
                />
                <CardHeader className="bg-transparent text-center border-0 py-2">
                  <h1 className="h1" style={{ color: '#32325d' }}>Success!</h1>
                </CardHeader>
                <CardHeader className="bg-transparent text-center border-0 px-xl-4 px-2  mt--2 text-sm-sm">
                  <p>Subscription Cancellation Confirmed.</p>
                  <p>You've successfully initiated the cancellation process. Please note that you have now lost access to your account and all associated services. All your data will be permanently deleted within the next 48 hours. If you have any concerns or wish to reverse this decision, please contact us immediately.</p>

                  {/* click
                  <span
                    className="text-primary ml-2 text-underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => (window.location.href = '/auth/login')}
                  >
                    here
                  </span>{' '}
                  to login on your account in the CMS. */}
                </CardHeader>
                <CardBody className="text-center">

                  {/* <Row className='mb-3'>
                    <Col className="text-center">
                      <FormGroup>
                        <label
                          className="form-control-label btn-link"
                          htmlFor="exampleFormControlInput1"
                          style={{ cursor: 'pointer' }}
                          onClick={() => backToLogin()}
                        >
                          <span className="btn-inner--icon ml-1">
                            <i className="ni ni-bold-left mr-2" />
                          </span> Back to Login
                        </label>
                      </FormGroup>
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CancellationSuccess;
