import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import 'assets/css/login-popup.css'; // import your CSS file here
import BlockUI from "components/Misc/BlockUI";

const LoginPopup = () => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [idleTime, setIdleTime] = useState(0);
  const [error, setError] = useState("");
  const [value, setValue] = useState({
    email: "",
    password: "",
    // trust: "",
  });
  const [, setUser] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setIdleTime(idleTime => idleTime + 1);
      console.log(`idle for ${idleTime}`);
    }, 60000);

    // check idle status on component mount
    if (localStorage.getItem('timed-out') === 'true') {
      setIsVisible(true);
      // localStorage.setItem('idle', 'false');
    }

    if(idleTime > 30) {
      setIsVisible(true);
      localStorage.setItem('timed-out', 'true');
    }

    window.onmousemove = () => setIdleTime(0);

    window.onbeforeunload = () => {
      if (idleTime > 30) {
        localStorage.setItem('timed-out', 'true');
      }
    };

    return () => {
      clearInterval(interval);
      window.onbeforeunload = null;
    };
  }, [idleTime]);

  const redirectToLogin = () => {
    history.push("/auth/login"); // Redirect to login page
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!value.email)
      return setError("Please enter your Email to login");
    else if (value.password === "") {
      return setError("Please enter a password!");
    }

    setShowBlockUI(true);



    setTimeout(() => {
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(value),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          // console.log(result);
          setShowBlockUI(false);

          if (result.error) return setError(result.error);
          if (result.success) {
            setUser(result);
            setIsVisible(false);
            localStorage.setItem('timed-out', 'false');
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  const handleChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const name = target.name;

    const newValue = Object.assign({}, value);

    newValue[name] = target.value;

    setValue(newValue);
    setError("");
  };

  const handleLogout = () => {
    // Show confirmation dialog
    if (window.confirm('Are you sure you want to logout?')) {
      localStorage.setItem('timed-out', 'false');
      // Make a request to the logout endpoint
      fetch('/api/auth/logout', {
        method: 'GET', // or 'GET', depends on how your logout endpoint is set up
        headers: {
          'Content-Type': 'application/json',
          // Include your authentication headers if needed
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setIsVisible(false);
        redirectToLogin();
      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    }
  };
  

  return isVisible ? (
    <div className="login-popup">
      <div className="login-content">
        <div className="logo-container">
          <img src="/api/accountingFirm/getThemeLogo" alt="Company Logo" />
        </div>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Email:</label><br />
          <input type="text" id="email" name="email" onChange={handleChange} /><br />
          <label htmlFor="password">Password:</label><br />
          <input type="password" id="password" name="password" onChange={handleChange}/><br />
          <label className='error-label'>{error}</label>
          <input type="submit" value="Login" />
          <div className="logout-button-container">
          <button type="button" className="logout-button" onClick={handleLogout}>Log me out</button>
          </div>
        </form>
      </div>
      {showBlockUI ? <BlockUI /> : null}
    </div>
  ) : null;
};

export default LoginPopup;