import React, { useEffect, useState } from "react";
import { Modal, Row, Button, FormGroup, Input, Form, Col } from "reactstrap";

import { CustomFieldGroup } from "customComponents/Form";
import CustomImageFieldGroup from "customComponents/Form";
import BootstrapTable from "react-bootstrap-table-next";
import { CustomToggleField } from "customComponents/Form";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CustomModalButton } from "components/Button/CustomButton";
import ThirdPartyAppStaffTable from "components/Table/ThirdPartyAppStaffTable";

const AddServiceInfo = ({ modal, ...props }) => {
  const [openStaffListModal, setOpenStaffListModal] = useState(props.staffDetailsModal?.record)
  let record = props.staffDetailsModal.record;

  useEffect(() => {
    setOpenStaffListModal(props.staffDetailsModal?.record)

  }, [record, props?.staffDetailsModal])

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b - a;
        }
        return a - b; // desc
      },
    },
    {
      dataField: "StaffID",
      text: "StaffID",
    },
    {
      dataField: "Staff Name",
      text: "Staff Name",
    },
    {
      dataField: "Mon",
      text: "Mon",
    },
    {
      dataField: "Tue",
      text: "Tue",
    },
    {
      dataField: "Wed",
      text: "Wed",
    },
    {
      dataField: "Thu",
      text: "Thu",
    },
    {
      dataField: "Fri",
      text: "Fri",
    },
    {
      dataField: "Total",
      text: "Total",
    },
    {
      dataField: "%",
      text: "Productivity %",
    },
    {
      dataField: "",
      text: "Productivity %",
      // dataFormat:{TableButtonAction},
      dataAlign: "center",
      formatExtraData: {
        title: "Edit",
        action: (row) => props.openStaffDetailsModal(row, "edit"),
        field: "StaffID",
      },
    },
    {
      dataField: "",
      text: "Productivity %",
      // dataFormat:{TableButtonAction},
      dataAlign: "center",
      formatExtraData: {
        title: "Open",
        action: (row) => props.openStaffDetailsModal(row, "open"),
        field: "StaffID",
      },
    },
  ];

  // const TableButtonAction = (cell, row, details) => {
  //   return (
  //     <Button
  //       color={details.style || "info"}
  //       className="btn-rounded"
  //       onClick={() => details.action(row)}
  //     >
  //       {details.title}
  //     </Button>
  //   );
  // };

  return (
    <>
      <Modal
        size="lg"
        className="modal-dialog"
        isOpen={modal.show}
      // toggle={() => props.closeModal()}
      >
        <div className="modal-body p-2">
          <h6 className="modal-body modal-title" id="modal-title-default">
            {modal.title}
          </h6>
          <Form onSubmit={props.handleSubmit}>
            {modal.format === "default" ? (
              <div className="modal-body">
                {modal.logo ? (
                  <img
                    alt="..."
                    className="rounded img-center img-fluid "
                    src={modal.logo}
                    style={{ height: "140px", objectFit: "cover" }}
                  />
                ) : null}
                {modal.details.additionalPrice ? (
                  <p className="text-muted">
                    This integration costs $
                    {modal.details.additionalPrice.defaultValue || 10}
                    {modal.details.additionalPrice.currency || ""} per{" "}
                    {modal.details.additionalPrice.frequency || "month"}.
                  </p>
                ) : null}
                {modal.details.pubKeyCert ? (
                  <div>
                    <FormGroup controlId="PublicKeyCertificate">
                      <label>Public Key Certificate</label>
                      <Input
                        name="PublicKeyCertificate"
                        type="textarea"
                        style={{ height: 200, lineHeight: "1.7em" }}
                        defaultValue={`-----BEGIN CERTIFICATE-----
MIICsDCCAhmgAwIBAgIJAI1vGdrEeCENMA0GCSqGSIb3DQEBBQUAMEUxCzAJBgNV
BAYTAkFVMRMwEQYDVQQIEwpTb21lLVN0YXRlMSEwHwYDVQQKExhJbnRlcm5ldCBX
aWRnaXRzIFB0eSBMdGQwHhcNMTcwMTMxMDEwMjIxWhcNMjIwMTMwMDEwMjIxWjBF
MQswCQYDVQQGEwJBVTETMBEGA1UECBMKU29tZS1TdGF0ZTEhMB8GA1UEChMYSW50
ZXJuZXQgV2lkZ2l0cyBQdHkgTHRkMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB
gQCazMBRzvPcH6bfZ+emkQtYFsxP8ZE/N1mmGgRaIH2w7hbcPhM0TnxvrgsAIGI2
lQ4vSC2Rj/dAW17fVsbDGfa0E2g1Lz7VWbaAsFzogJEAzdeF3fhn2lZ67WJoPTI8
z3fhfSF0mcMsAVm8m7MUeqb8fjzTq0iXNFjksGxzlaEv5QIDAQABo4GnMIGkMB0G
A1UdDgQWBBQLzqksSur0bw9ZVgRz0Oxo/vnyoTB1BgNVHSMEbjBsgBQLzqksSur0
bw9ZVgRz0Oxo/vnyoaFJpEcwRTELMAkGA1UEBhMCQVUxEzARBgNVBAgTClNvbWUt
U3RhdGUxITAfBgNVBAoTGEludGVybmV0IFdpZGdpdHMgUHR5IEx0ZIIJAI1vGdrE
eCENMAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEFBQADgYEAOBbKgTbalA6Bf004
rfWN0F7BFM64/LRiFYRyFLHWc06NPbewJk7KZUq0+X1FWrUuEj2oEcM2TUTaYyWS
rzkPVWoc1alfKkL9h1haBG9By1MGKSVeBlgbTRHysE5igGo97D8uyDwz1aRwmqE3
48yR5Hwv6qW8Vx6MB290VAZ0ksI=
-----END CERTIFICATE-----`}
                        wrap="off"
                        //ref={(textArea) => props.textArea = textArea}
                        // inputRef={(ref) => {
                        //   publicKey = ref;
                        // }}
                        spellCheck="false"
                        onChange={props.handleInputChange}
                        //disabled={true}
                        readOnly
                      />
                    </FormGroup>
                    {/* {document.queryCommandSupported("copy") && (
                  <Button onClick={copyToClipboard} block>
                    Copy to clipboard
                  </Button>
                )} */}
                    <hr />
                  </div>
                ) : null}
                {modal.details.fields && modal.details.fields.length
                  ? modal.details.fields.map((obj, index) => {
                    return (
                      <CustomFieldGroup
                        autoComplete="true"
                        id={obj.name}
                        key={index}
                        name={obj.name}
                        type={obj.type || "text"}
                        label={obj.label || obj.name}
                        placeholder={
                          obj.placeholder
                            ? obj.placeholder
                            : "Enter " + (obj.label || obj.name)
                        }
                        onChange={props.handleInputChange}
                        defaultValue={modal[obj.name] || ""}
                        required={true}
                      />
                    );
                  })
                  : null}
                {/* {modal.details.trainingLink
                  ? ExternalLink(
                      "Watch Training Here",
                      modal.details.trainingLink,
                      "_blank"
                    )
                  : null} */}
              </div>
            ) : null}

            {modal.format === "name" ? (
              <div className="modal-body">
                {modal.logo ? (
                  <div style={{ marginBottom: "10px" }}>
                    <img
                      alt="logo"
                      src={modal.logo}
                      style={{
                        height: "100px",
                        margin: "0 auto",
                        display: "block",
                      }}
                    />
                  </div>
                ) : null}
                <CustomFieldGroup
                  id="Name"
                  name="Name"
                  type="text"
                  label="Power BI Workspace Name"
                  placeholder="Enter Power BI Workspace Name."
                  onChange={props.handleInputChange}
                  defaultValue={modal.Name || ""}
                  style={props.errorStyle}
                  error={props.errorMessage}
                //required={true}
                />
              </div>
            ) : null}
            {modal.format === "dear" ? (
              <div className="modal-body">
                <CustomFieldGroup
                  id="AccountID"
                  name="AccountID"
                  type="text"
                  label="Account ID"
                  placeholder="Enter Account ID."
                  onChange={props.handleInputChange}
                  defaultValue={modal.AccountID || ""}
                  style={props.errorStyle}
                  error={props.errorMessage}
                />
                <CustomFieldGroup
                  id="Key"
                  name="Key"
                  type="text"
                  label="Key"
                  placeholder="Enter Key."
                  onChange={props.handleInputChange}
                  defaultValue={modal.Key || ""}
                  rstyle={props.errorStyle}
                  error={props.errorMessage}
                />
                {/* {modal.details.trainingLink
                  ? ExternalLink(
                      "Watch Training Here",
                      modal.details.trainingLink,
                      "_blank"
                    )
                  : null} */}
              </div>
            ) : null}
            {modal.format === "workflowmax" ? (
              <div className="modal-body">
                <CustomImageFieldGroup
                  id="APIKey"
                  name="APIKey"
                  type="text"
                  label="API Key"
                  placeholder="Enter API Key."
                  onChange={props.handleInputChange}
                  defaultValue={modal.APIKey || ""}
                  required={true}
                />
                <CustomFieldGroup
                  id="AccountKey"
                  name="AccountKey"
                  type="text"
                  label="Account Key"
                  placeholder="Enter Account Key."
                  onChange={props.handleInputChange}
                  defaultValue={modal.AccountKey || ""}
                  required={true}
                />
                {/* {modal.details.trainingLink
                  ? ExternalLink(
                      "Watch Training Here",
                      modal.details.trainingLink,
                      "_blank"
                    )
                  : null} */}
              </div>
            ) : null}

            <div className="modal-footer p-0 pb-2">
              <CustomModalButton
                submitButton={null}
                closeButton={() => props.closeModal()}
                submitText="Save"
                cancelText="Close"
                submitType="submit"
              />
            </div>
          </Form>
        </div>
      </Modal>

      {/* staff list modal */}

      {/* <Modal
        size="xl"
        className="modal-dialog-centered"
        isOpen={props.isStaffListModalOpen}
        toggle={() => props.closeStaffListModal()}
      >
        <div className="modal-header">
          <h6 className=" modal-title" id="modal-title-default">
            Edit Staff Details
          </h6>
        </div>
        <div className="modal-body p-2">
          <ThirdPartyAppStaffTable
            data={props.staffListModal.data}
            openStaffDetailsModal={props.openStaffDetailsModal}
          />
        </div>
        <div className="modal-footer pt-0">
          <Button color="dark" onClick={() => props.closeStaffListModal()}>
            Close
          </Button>
        </div>
      </Modal> */}

      {/* Staff Details Modal */}

      {/* <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={props.staffDetailsModal.show}
        toggle={() => props.closeStaffDetailsModal()}
      >
        <div className="modal-header">
          <h6 className=" modal-title" id="modal-title-default">
            Edit Staff Details
          </h6>
        </div>
        <Form onSubmit={props.handleStaffDetailsUpdate}>
          <div className="modal-body p-2">
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`StaffID${record.id}`}
                  name="StaffID"
                  type="text"
                  label="Staff ID"
                  placeholder="Enter Staff ID"
                  //onChange={props.handleStaffDetailsInputChange}
                  ////required={true}
                  readOnly
                  defaultValue={record["StaffID"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`StaffName${record.id}`}
                  name="StaffName"
                  type="text"
                  label="Staff Name"
                  placeholder="Enter Staff Name"
                  //onChange={props.handleStaffDetailsInputChange}
                  ////required={true}
                  readOnly
                  defaultValue={record["StaffName"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Mon${record.id}`}
                  name="Mon"
                  type="text"
                  label="Monday"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Mon"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Tue${record.id}`}
                  name="Tue"
                  type="text"
                  label="Tuesday"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Tue"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Wed${record.id}`}
                  name="Wed"
                  type="text"
                  label="Wednesday"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Wed"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Thu${record.id}`}
                  name="Thu"
                  type="text"
                  label="Thursday"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Thu"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Fri${record.id}`}
                  name="Fri"
                  type="text"
                  label="Friday"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Fri"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Total${record.id}`}
                  name="Total"
                  type="text"
                  label="Total"
                  placeholder="0:00"
                  onChange={props.staffDetailsFormatTime}
                  onBlur={props.staffDetailsFormatTimeOnBlur}
                  //required={true}
                  readOnly
                  defaultValue={record["Total"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Percent${record.id}`}
                  name="Percent"
                  type="text"
                  label="Productivity %"
                  placeholder="Enter Productivity %"
                  onChange={props.staffDetailsFormatWholeNumber}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Percent"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Team${record.id}`}
                  name="Team"
                  type="text"
                  label="Team"
                  placeholder="Enter Team"
                  onChange={props.handleStaffDetailsInputChange}
                  ////required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Team"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`BillRate${record.id}`}
                  name="BillRate"
                  type="text"
                  label="Bill Rate"
                  placeholder="Enter Bill Rate"
                  onChange={props.staffDetailsFormatWholeNumber}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["BillRate"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`FulltimeEquivPercent${record.id}`}
                  name="FulltimeEquivPercent"
                  type="text"
                  label="Fulltime Equiv %"
                  placeholder="Enter Fulltime Equiv %"
                  onChange={props.staffDetailsFormatWholeNumber}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["FulltimeEquivPercent"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id={`Notes${record.id}`}
                  name="Notes"
                  type="text"
                  label="Annual Budget"
                  placeholder="Enter Annual Budget"
                  onChange={props.handleStaffDetailsInputChange}
                  //required={true}
                  readOnly={props.staffDetailsModal.type === "open"}
                  defaultValue={record["Notes"] || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomToggleField
                  onChange={(defaultValue) => {
                    //console.log(defaultValue);
                    record["Working"] = !defaultValue;
                    props.setStaffDetailsModal(props.staffDetailsModal);
                  }}
                  label="Working?"
                  type="checkbox"
                  id={`Working${record.id}`}
                  name="Working"
                  defaultValue={record["Working"] || false}
                  defaultChecked={record["Working"] || false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomToggleField
                  onChange={(defaultValue) => {
                    //console.log(defaultValue);
                    record["DirectorPartner"] = !defaultValue;

                    props.setStaffDetailsModal(props.staffDetailsModal);
                  }}
                  label="Director/Partner?"
                  type="checkbox"
                  id={`DirectorPartner${record.id}`}
                  name="DirectorPartner"
                  defaultValue={record["DirectorPartner"] || false}
                  defaultChecked={record["DirectorPartner"] || false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomToggleField
                  onChange={(defaultValue) => {
                    //console.log(defaultValue);
                    record["FeeEarner"] = !defaultValue;

                    props.setStaffDetailsModal(props.staffDetailsModal);
                  }}
                  label="Fee Earner?"
                  type="checkbox"
                  id={`FeeEarner${record.id}`}
                  name="Fee Earner?"
                  defaultValue={record["FeeEarner"] || false}
                  defaultChecked={record["FeeEarner"] || false}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer pt-0">
            <Button type="submit">Save</Button>
            <Button color="dark" onClick={props.closeStaffDetailsModal}>
              Close
            </Button>
          </div>
        </Form>
      </Modal> */}
    </>
  );
};

export default AddServiceInfo;
