import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, CardImg } from "reactstrap";

const PageNotFound = () => {
  const [transitionStage] = useState("fadeFrom");
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        backgroundColor: "#fff",
        display: "grid",
      }}
    >
      <Container className="align-items-center " style={{ display: "grid" }}>
        <Row className="justify-content-center">
          <Col
            lg="6"
            md="8"
            sm="12"
            xs="12"
            className={`${transitionStage} mt-2 px-0 px-lg-2`}
          >
            <Card
              className="bg-transparent p-sm-2 py-3 p-lg-4 mx-3 shadow-none"
              style={{ overflow: "hidden" }}
            >
              <CardImg
                alt="Login"
                src={
                  require("../../../../assets/img/error-page-svgrepo-com.svg")
                    .default
                }
                style={{ width: "80px", margin: "0 auto" }}
                className="spin justify-content-center text-center rounded my-3"
                top
              />
              <CardHeader className="bg-transparent text-center border-0 py-2">
                <h1 className="h1" style={{ color: "#272A65" }}>Page not found</h1>
              </CardHeader>
              <CardHeader className="bg-transparent text-center border-0 px-xl-4 px-2  mt--2 text-sm-sm">
                The page you are looking for may have been moved, deleted or
                possibly never existed.
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageNotFound;
