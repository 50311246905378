import React, { useContext } from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from "reactstrap";
// eslint-disable-next-line
import { SyncLoader } from "react-spinners";
import { MainContext } from "context/mainContext";
import { ClipLoader } from "react-spinners";
import Modal from 'react-modal';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { modalCustomStyles } from "../Modal/Modal";
import { useHistory } from "react-router";
import BlockUI from "components/Misc/BlockUI";
import { dataFilter, dataFilterNameOnly } from "utils/utilityFunctions";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

function Sidebar({
  toggleSidenav,
  sidenavOpen,
  routes,
  logo,
  rtlActive,
  firmCode,
  userClients,
}) {
  const { bgColor, userDetails, contextFunctionsLoading, firmDetails, getDetails, selectedClientID } =
    useContext(MainContext);
  const [state, setState] = React.useState({});
  const [subDomain, setSubDomain] = React.useState({});
  const [businessList, setBusinessList] = React.useState({});
  const [filteredBusinessList, setFilteredBusinessList] = React.useState({});
  const [selectedBusiness, setSelectedBusiness] = React.useState({});
  const location = useLocation();
  const routerHistory = useHistory();
  const [showBlockUI, setShowBlockUI] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState('');
  const [alert, setAlert] = React.useState(false);

  const fetchAPIs = () => {
    setState(getCollapseStates(routes));
    let url = window.location.hostname.split(".");
    setSubDomain(url[0])

    if (url[0] === 'platform') {
      fetch("/api/trialUser/getAllBusinessesPerMBU")
        .then((res) => res.json())
        .then(data => {
          // console.log('trialUser/getAllBusinessesPerMBU', data)
          if (data.record && !data.error) {
            let businesses = data.record
            let initialSelectedBusiness = businesses.filter(rec => rec.Code === firmDetails?.Code)
            let indexOfBusiness = businesses.indexOf(initialSelectedBusiness[0])

            businesses.splice(indexOfBusiness, 1)
            businesses.unshift(initialSelectedBusiness[0])
            // console.log(initialSelectedBusiness)
            // console.log(indexOfBusiness)
            setBusinessList(businesses)
            setFilteredBusinessList(businesses)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

  }

  const getCurrentClient = (userClients) => {
    const currentURL = window.location.href;
    const uuidRegex = /\/[a-f0-9-]{36}/;
    const matches = currentURL.match(uuidRegex);
    let currentClientId = matches ? matches[0].substring(1) : null;
    let clientID;
    let clientNAME;

    if (userClients && userClients?.length > 0) {
      const { ClientID, Name } = userClients?.[0];

      if (currentClientId) {
        clientID = currentClientId;
        const clientData = userClients?.find((client) => client?.ClientID === currentClientId);
        clientNAME = clientData?.Name;
      } else {
        clientID = ClientID;
        clientNAME = Name;
      }
    }
    return {
      clientID,
      clientNAME,
    }
  }
  const getCurrentClientMemoized = React.useMemo(() => getCurrentClient(), []);

  const handleCloseModal = () => setModalOpen(false);

  React.useEffect(() => {
    setState(getCollapseStates(routes));

    fetchAPIs()
    // eslint-disable-next-line
  }, [
    userDetails?.AccountingFirm,
    firmDetails
  ]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.path === "/business/details" &&
        userDetails?.User?.UserAccess <= 4 &&
        userDetails?.User?.Details?.Authority?.PracticeManagerAccess === false
      ) {
        return null;
      }
      if (
        prop.path === "/business/details" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (
        prop.path === "/adviser-lists" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (prop.path === "/lists" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (prop.path === "/users" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (prop.path === "/advisers" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (
        prop.path === "/thirdpartyapplication" &&
        userDetails?.User?.UserAccess >= 6
      ) {
        return null;
      }
      if (
        prop.path === "/traningvideos" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }
      if (prop.path === "/eva") {
        if (firmDetails?.Database === null || !firmDetails?.Database?.length) {
          return null;
        }
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {contextFunctionsLoading ? (
                  <ClipLoader color={bgColor} size={15} margin={2} />
                ) : (
                  createLinks(prop.views)
                )}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.openModal ? '#' : prop.layout + prop.path}
            activeClassName=""
            onClick={prop.openModal ? (e) => e.preventDefault() : closeSidenav}
            tag={NavLinkRRD}
          >
            {(prop.icon !== undefined) ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  // if (logo && logo.innerLink) {
  //   navbarBrandProps = {
  //     to: logo.innerLink,
  //     tag: Link,
  //   };
  // } else if (logo && logo.outterLink) {
  //   navbarBrandProps = {
  //     href: logo.outterLink,
  //     target: "_blank",
  //   };
  // }

  const errorAlert = (message) => {
    setAlert(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Warning"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </ReactBSAlert>
    );
  };

  const handleChangeBusiness = (bus) => {
    // console.log(bus)
    // let details = { AccountingFirm: bus.id }
    // getDetails(details)
    setShowBlockUI(true)
    setSelectedBusiness(bus)
    fetch(`/api/trialUser/changeBusiness/${bus.id}`)
      .then((res) => res.json())
      .then(data => {
        // console.log('trialUser/changeBusiness', data)
        if (data.error) {
          setShowBlockUI(false)
          errorAlert(data.error)
        }
        setTimeout(() => {
          if (data && !data.error) {
            fetchAPIs()
            getDetails()
            setShowBlockUI(false)
            return routerHistory.push("/");
          }
        }, 5000)
      })
      .catch(e => {
        setShowBlockUI(false)
        console.log(e)
      })
  }

  const limitedBusinessNameCharacters = (text, maxLength) => {
    const shortenedText = text.substring(0, maxLength);
    return `${shortenedText}${text.length > maxLength ? '...' : ''}`
  }

  const handleSearch = (e) => {
    let filterString = e.target.value
    setFilteredBusinessList(dataFilterNameOnly(filterString, businessList))
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            {firmCode ? (
              subDomain === 'platform' ?
                <>
                  <UncontrolledDropdown>
                    <DropdownToggle nav caret>
                      <img
                        alt={logo.imgAlt}
                        style={{ borderRadius: "5px", }}
                        className={`navbar-brand-img ${subDomain === 'platform' ? `default-logo-size` : ``} `}
                        src={logo.imgSrc}
                      />
                      {/* Options */}
                    </DropdownToggle>
                    <DropdownMenu end='true' style={{ width: '220px' }} className={businessList?.length > 15 ? "business-list-dropdown" : ""}>
                      <DropdownItem header><i className="fas fa-exchange-alt"></i> Change Business</DropdownItem>
                      {
                        businessList?.length > 15 ?
                          <DropdownItem header>
                            <Input
                              placeholder="Business Name"
                              type="text"
                              bsSize="sm"
                              onChange={handleSearch}
                            />
                          </DropdownItem>
                          : null
                      }
                      {
                        filteredBusinessList && filteredBusinessList?.length >= 1 ?
                          filteredBusinessList?.map((bl, i) => (
                            <DropdownItem key={i} onClick={() => handleChangeBusiness(bl)}>
                              <img
                                alt={bl?.Name}
                                style={{ borderRadius: "5px" }}
                                className={`navbar-brand-img ${subDomain === 'platform' ? `default-logo-size` : ``} `}
                                src={bl?.Icon ? bl?.Icon : `https://ui-avatars.com/api/?background=random&&name=${bl?.Name[0]}`}
                              />
                              {firmDetails?.Code === bl?.Code ? <strong>{limitedBusinessNameCharacters(`${bl?.Name}`, 15)}</strong> : limitedBusinessNameCharacters(`${bl?.Name}`, 15)}
                            </DropdownItem>
                          ))
                          : null
                      }
                      {/* <DropdownItem>Option 2</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
                : <img
                  alt={logo.imgAlt}
                  style={{ borderRadius: "5px" }}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
            ) : (
              <SyncLoader color={bgColor} size={3} />
            )}
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner text-center">
        {
          firmCode && subDomain === 'platform' ?
            <h5 className="nav-link-text">{firmDetails?.Name}</h5>
            : ""
        }
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>
            {contextFunctionsLoading ? (
              <NavItem>
                <NavLink href="#">
                  <>
                    <span className="sidenav-normal">
                      <ClipLoader color={bgColor} size={15} margin={2} />
                    </span>
                  </>
                </NavLink>
              </NavItem>
            ) : (
              createLinks(routes)
            )}
          </Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <>
      {showBlockUI ? <BlockUI /> : null}
      {alert}
      <Navbar
        className={
          "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
          (rtlActive ? "" : "fixed-left")
        }
        onMouseEnter={onMouseEnterSidenav}
        onMouseLeave={onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Chat Modal"
        style={modalCustomStyles}
      >
        <Row>
          <Col>
            <iframe src={iframeUrl} width="800" height="600" style={{ border: 'none' }}></iframe>
          </Col>
          <Col>
            <button onClick={handleCloseModal}>Close</button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
