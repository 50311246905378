import React, { useContext, useEffect, useState } from 'react';
import PanelCard from 'components/Cards/PanelCard';
import CustomHeader from 'customComponents/customHeader';
import { Button, ButtonGroup, Container, Row, Col, Input, CardBody, Spinner } from 'reactstrap';
import { MainContext } from 'context/mainContext';
import { useHistory } from "react-router";
import CustomSpinner from 'components/Misc/CustomSpinner';
import PowerBITable from 'components/Table/PowerBITable';
import BlockUI from 'components/Misc/BlockUI';
import CustomModal from 'customComponents/customModal';
import avatar from "../../../assets/img/clients/blank-avatar.png";
import ReactBSAlert from "react-bootstrap-sweetalert";
import DashboardSettingsModal from './DashboardSettingsModal';

const PowerBIPage = ({ ...props }) => {
  const {
    selectedClientID,
    setSelectedClientID,
    bgColor,
    textColor,
    userDetails,
    firmDetails
  } = useContext(MainContext)
  const history = useHistory();
  const [selectedClient, setSelectedClient] = useState({});
  const [loading, setLoading] = useState(false)
  const [dashboardsReportsData, setDashboardsReportsData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filteredBy, setFilteredBy] = useState('all')
  const [clientUsers, setClientUsers] = useState([])
  const [filteredClientUsers, setfilteredClientUsers] = useState([])
  const [dashboardSetting, setDashboardSetting] = useState(false)
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [openDashboardSelectUserModal, setOpenDashboardSelectUserModal] = useState(false)
  const [selectedDashBoardUsers, setSelectedDashBoardUsers] = useState([])
  const [selectedDashboard, setSelectedDashboard] = useState({})
  const [color, setColor] = useState(false);
  const [alert, setAlert] = useState(null)

  useEffect(async () => {
    // console.log(selectedClientID)
    setLoading(true)
    if (selectedClientID) {
      history.push(`/client/powerbi/${selectedClientID}`);
    }

    let firstClient = [];
    let allClients = [];
    let url = window.location.pathname.split("/");

    let allReports = [];
    let allDashboards = [];

    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {

    } else {
      try {
        const getAllRecords = await fetch(`/api/client/getAllRecords`)
        const allClientRecords = await getAllRecords.json()
        // console.log(allClientRecords)
        firstClient = allClientRecords[0]

        if (allClientRecords && firstClient) {
          setSelectedClientID(url.length < 4 ? firstClient.ClientID : url[url.length - 1]);

          const getClientDetails = await fetch(`/api/client/showDetails/${url.length < 4 ? firstClient.ClientID : url[url.length - 1]}`)
          const clientDetails = await getClientDetails.json()
          // console.log(clientDetails)
          if (clientDetails && !clientDetails.error) {
            if (clientDetails.message === "Client not found") {
              // setClientID(firstClient.ClientID);
              setSelectedClientID(firstClient.ClientID);
              return history.push(`/auth/page-not-found`);
            }
            setSelectedClient(clientDetails);

            const getPowerBIReports = await fetch(`/api/customReport/getPowerBIReports/${clientDetails.id}`)
            const powerBIReports = await getPowerBIReports.json()
            // console.log(powerBIReports)

            const getPowerBIDashboards = await fetch(`/api/customReport/getPowerBIDashboards/${clientDetails.id}`)
            const powerBIDashboards = await getPowerBIDashboards.json()
            // console.log(powerBIDashboards)
            if (!powerBIReports.error && !powerBIDashboards.error) {
              console.log([...powerBIDashboards.data, ...powerBIReports.data])
              setDashboardsReportsData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setFilteredData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setLoading(false)
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [userDetails, selectedClientID, selectedDashBoardUsers])

  const handleFilterContent = () => {
    setFilteredBy('all')
    console.log(filteredData)
    const sorted = dashboardsReportsData.sort((a, b) => a.Name.localeCompare(b.Name));
    setFilteredData(sorted)
  }

  const handleFilteredBy = (type) => {
    const filterType = type === 'report' ? 1 : 2
    const filtered = dashboardsReportsData.filter(f => f.Type === filterType)
    console.log(filtered)
    const sorted = filtered.sort((a, b) => a.Name.localeCompare(b.Name));
    setFilteredBy(type)
    setFilteredData(sorted)
  }

  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = dashboardsReportsData.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilteredData(filtered);
  };

  const handleGoLiveReport = (data) => {
    console.log(data)
  }

  const handleGoLiveDashboard = (data) => {
    // console.log(data)
    if (data.Status === 5 || data.Status === 1) {
      console.log('Golive')
      openDashboardUserSelectModal(data, false)
    } else if (data.Status === 4) {
      console.log('GoHide')
      handleUpdateDashboard(
        `/api/customReport/updateSubmit?id=${data.ReportID}`,
        1,
        data
      )
    }
  }

  const openDashboardUserSelectModal = async (data, settings) => {
    try {
      setShowBlockUI(true)
      setSelectedDashboard(data)
      setDashboardSetting(settings)
      const getUsersByClientID = await fetch("/api/user/getUsersByClientID")
      const usersByClientID = await getUsersByClientID.json()
      if (usersByClientID.error) return console.log(usersByClientID.error)
      if (usersByClientID) {
        setShowBlockUI(false)
        setClientUsers(usersByClientID.data)
        setfilteredClientUsers(usersByClientID.data)
        setOpenDashboardSelectUserModal(true)
        // console.log(usersByClientID.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleShowDashboardSettings = (data) => {
    console.log(data)
  }

  const closeDashboardUserSelectModal = () => {
    setOpenDashboardSelectUserModal(false)
  }

  const dashboardUsersListModal = () => {
    if (filteredClientUsers?.length === 0) {
      if (loading) {
        return (
          <Container className="text-center">
            <Spinner />
          </Container>
        )
      }
      return (
        <div className="col-sm-12 text-center mt-4">
          <h5>No users found.</h5>
        </div>
      )
    } else {
      return filteredClientUsers?.map((obj, i) => {
        const index = selectedDashBoardUsers.indexOf(obj.id);
        return (
          <Col
            className="d-flex p-2 text-center "
            lg="6"
            style={{ flexDirection: "column", cursor: "pointer" }}
            key={i}
          >
            <Row className="justify-content-space-between p-3  ">
              <Col
                className="text-left border d-flex p-3 shadow--hover rounded"
                onClick={() => selectUser(obj)}
              >
                <img
                  alt="..."
                  className="rounded-circle img-fluid shadow shadow-lg--hover"
                  src={obj.Avatar || avatar}
                  style={{ width: "40px", height: "40px", objectFit: "cover" }}
                />
                <span className="m-auto" style={{ fontSize: "14px" }}>
                  {obj.FirstName + " " + obj.LastName}
                </span>
                <i
                  className="fas fa-check-circle mt-2"
                  style={{
                    color: index > -1 ? "green" : "gray",
                    fontSize: "20px",
                    marginTop: "5px",
                  }}
                />
              </Col>
            </Row>
          </Col>
        )
      })
    }
  }

  const selectUser = (record) => {
    const selectedUsers = selectedDashBoardUsers
    const index = selectedUsers.indexOf(record.id);

    if (index > -1) {
      selectedUsers.splice(index, 1);
      setColor(!color);
    } else {
      selectedUsers.push(record.id);
      setColor(!color);
    }

    setSelectedDashBoardUsers(selectedUsers)
  }

  const selectAll = () => {
    const selectedUsers = selectedDashBoardUsers
    for (let i = 0; i < clientUsers.length; i++) {
      selectedUsers.push(clientUsers[i].id);
    }
    setSelectedDashBoardUsers(selectedUsers)
    setColor(!color);
  }

  const unselectAll = () => {
    const selectedUsers = selectedDashBoardUsers
    for (let i = 0; i < clientUsers.length; i++) {
      selectedUsers.pop(clientUsers[i].id);
    }
    setSelectedDashBoardUsers(selectedUsers)
    setColor(!color);

  }

  const handleSearchDashboardUsers = (e) => {
    let data = clientUsers;
    e.preventDefault();

    let filterString = e.target.value.toLowerCase();
    const filtered = data.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });

    setfilteredClientUsers(filtered)
  }

  const handleUpdateDashboard = async (url, toStatus, dashboard) => {
    console.log("handleUpdateDashboard", url, toStatus, dashboard)
    setShowBlockUI(true)
    try {
      const updateDashboard = await fetch(url, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(dashboard),
      })
      const updatedDashboard = await updateDashboard.json()
      let id = dashboard.id;
      if (updatedDashboard.error) return warningAlert(updatedDashboard)
      successAlert(updatedDashboard);
      setShowBlockUI(false)

      if (openDashboardSelectUserModal === true && toStatus === 4) {
        dashboard = dashboard.report;
        setOpenDashboardSelectUserModal(false);
      }

      for (let i = 0; i < dashboardsReportsData.length; i++) {
        if (dashboardsReportsData[i].ReportID === dashboard.ReportID) {
          dashboardsReportsData[i].Status = toStatus;
          if (id === 0 && updatedDashboard.record.id > 0)
            dashboardsReportsData[i].id = updatedDashboard.record.id;
          break;
        }
      }

      for (let i = 0; i < filteredData.length; i++) {
        if (filteredData[i].ReportID === dashboard.ReportID) {
          filteredData[i].Status = toStatus;
          if (id === 0 && updatedDashboard.record.id > 0)
            dashboardsReportsData[i].id = updatedDashboard.record.id;
          break;
        }
      }

      setOpenDashboardSelectUserModal(false);
      setDashboardsReportsData(dashboardsReportsData)
      setFilteredData(filteredData)

    } catch (error) {
      console.log(error);
    }
  }

  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error || response}
      </ReactBSAlert>
    )
  }
  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message}
      </ReactBSAlert>
    );
  };

  const dashboardUsersSelectModal = () => {
    return (
      <CustomModal
        title="Select User"
        subtitle={null}
        fieldGroup={null}
        list={dashboardUsersListModal()}
        isOpen={openDashboardSelectUserModal}
        searchInput={handleSearchDashboardUsers}
        closeButton={closeDashboardUserSelectModal}
        toggle={closeDashboardUserSelectModal}
        submitButton={(e) => {
          e.preventDefault();
          handleUpdateDashboard(
            `/api/customReport/updateLive?id=${selectedDashboard.ReportID}`,
            4,
            {
              report: selectedDashboard,
              recipient: selectedDashBoardUsers
            }
          )
        }}
        headerButtons={
          <ButtonGroup>
            <Button
              style={{
                backgroundColor: `${bgColor}`,
                color: `${textColor}`,
                borderColor: `${bgColor}`,
              }}
              type="button"
              size="sm"
              onClick={() => selectAll()}
            >
              <i className="ni ni-fat-add "></i>
              Select All
            </Button>
            <Button
              style={{
                color: `${bgColor}`,
                borderColor: `${bgColor}`,
              }}
              data-dismiss="modal"
              type="button"
              size="sm"
              outline
              onClick={() => unselectAll()}
            >
              <i className="ni ni-fat-delete "></i>
              Unselect All
            </Button>
          </ButtonGroup>
        }
      />
    )
  }

  const addToFavorite = async (data) => {
    console.log(data)
    console.log(userDetails.User.Details.Favorites)
    let fav = userDetails.User.Details.Favorites
    if (fav) {
      fav.map(async f => {
        if (f.Details.ReportID === data.ReportID) {
          console.log("SAME")
        } else {
          const setAddToFavorite = await fetch(`/api/favorites/addToFavorite`, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              data
            }),
          })
          const favorite = await setAddToFavorite.json()
          console.log(favorite)
        }
      })
    }
  }

  return (
    <>
      {alert}
      {showBlockUI ? <BlockUI /> : null}
      <CustomHeader />
      <Container className="mt-6 fadeIn" fluid>
        <div className="nav-wrapper">
          <PanelCard headerName={`Dashboards/Reports`}>
            {dashboardUsersSelectModal()}
            {/* <DashboardSettingsModal
              data={selectedDashboard}
              bgColor={bgColor}
              textColor={textColor}
              selectAll={selectAll}
              unselectAll={unselectAll}
              userSelectList={dashboardUsersListModal}
              openSettingsModal={handleShowDashboardSettings}
            /> */}
            <Row>
              <Col className="mt-2">
                <Row>
                  <Col>
                    <Button
                      style={{
                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      // onClick={() => handleShowPbixFileUpload()}
                      id="importPBIX"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="fas fa-file-upload"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      Import
                    </Button>
                  </Col>
                  <Col lg="4" sm="4" className="mt-0 ml-auto">
                    <div className="ml-auto">
                      <Row>
                        <Col className="text-right pr-2" style={{ paddingRight: 0 }}>
                          <ButtonGroup>
                            <Button
                              size="sm"
                              type="button"
                              style={{
                                backgroundColor: `${filteredBy === 'all'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                borderColor: `${filteredBy === 'all'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                color: `${textColor === "#000" ? "#fff" : textColor
                                  }`,
                                paddingTop: "0.3rem",
                                paddingBottom: "0.3rem"
                              }}
                              onClick={() => handleFilterContent()}
                            >
                              All
                            </Button>
                            <Button
                              size="sm"
                              type="button"
                              style={{
                                backgroundColor: `${filteredBy === 'dashboard'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                borderColor: `${filteredBy === 'dashboard'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                color: `${textColor === "#000" ? "#fff" : textColor
                                  }`,
                                paddingTop: "0.3rem",
                                paddingBottom: "0.3rem"
                              }}
                              onClick={() => handleFilteredBy('dashboard')}
                            >
                              Dashboards
                            </Button>
                            <Button
                              size="sm"
                              type="button"
                              style={{
                                backgroundColor: `${filteredBy === 'report'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                borderColor: `${filteredBy === 'report'
                                  ? "#2dce89"
                                  : bgColor === "#fff"
                                    ? "#5e72e4"
                                    : bgColor
                                  }`,
                                color: `${textColor === "#000" ? "#fff" : textColor
                                  }`,
                                paddingTop: "0.3rem",
                                paddingBottom: "0.3rem"
                              }}
                              onClick={() => handleFilteredBy('report')}
                            >
                              Reports
                            </Button>
                          </ButtonGroup>
                        </Col>
                        <Col style={{ paddingLeft: 0 }}>
                          <Input
                            placeholder="Search"
                            type="text"
                            bsSize="sm"
                            onChange={handleSearch}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <CardBody>
              <Row>
                {
                  loading ? <CustomSpinner />
                    :
                    <Col>
                      <PowerBITable
                        data={filteredData}
                        handleGoLiveDashboard={handleGoLiveDashboard}
                        handleShowDashboardSettings={handleShowDashboardSettings}
                        handleGoLiveReport={handleGoLiveReport}
                        addToFavorite={addToFavorite}
                      />
                    </Col>
                }
              </Row>
            </CardBody>
          </PanelCard>
        </div>
      </Container >
    </>
  )
}

export default PowerBIPage;