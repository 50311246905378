import { CustomFeatures } from "customComponents/customPricing";
import CustomPricingDetailsCollapse from "customComponents/customPricingDetailsCollapse";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Collapse,
  ListGroupItem,
} from "reactstrap";
import { seatPricingDetailsList } from "utils/seatPricingData";
const SeatPricingCard = ({ record, handleShowCurrentSeatCount, ...props }) => {
  return (
    <>
      <Row>
        <Col xl={{ offset: 3, size: 6 }} lg={{ offset: 3, size: 6 }} md={12} sm={12} sx={12}>
          <Card className="card-pricing border-0 mt-3 ribbon-overflow p-2">
            {record.Code === props.firmDetails?.PricingPlan?.Code ? (
              <div className="ribbon">{props.firmDetails?.Details?.Trial ? `Trial` : `Subscribed`}</div>
            ) : null}
            <CardHeader className="h3 rounded-top font-weight-700">
              {record.Name}
            </CardHeader>
            <CardBody className="px-lg-4 p-sm-3">
              <p
                className="display-4 font-weight-700 d-flex align-items-center"
                style={{
                  // color: props.bgColor === '#fff' ? 'rgb(94, 114, 228)' : '',
                  color: `${props.bgColor === "#fff" ? "rgb(94, 114, 228)" : props.bgColor
                    }`,
                }}
              >
                <small style={{ fontSize: "14px" }}>min.   </small>
                &nbsp;{`$60`}&nbsp;
                <small style={{ fontSize: "14px" }}> / month</small>
              </p>
              <div className="mt-3 mb-3">
                <p className="h5 my-2">
                  Client Fee — $20/Client
                </p>
                <p className="h5 my-2">
                  Admin — $30
                </p>
                <p className="h5 my-2">
                  Data Integration Sources — $10-$50/Integration
                </p>
              </div>
              <div className="mt-4" onClick={() => handleShowCurrentSeatCount()}>
                <p className="h5 my-2 seat-count-link" style={{ fontWeight: "500", cursor: "pointer" }}>
                  See current seat counts
                </p>
              </div>
              <div className="border-top">
                <Row className="mb-4 pt-3 d-flex align-items-start flex-column justify-content-center">
                  <Col><p className="h3">Add-ons</p></Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column text-left">
                    <p className="text-sm text-left" style={{ fontWeight: 'bold', color: "#32325d " }}>
                      Extended User Licenses:
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Additional Admin seats
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$30/Admin seat</span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>EVA-enabled users:</span>
                      <span style={{ fontSize: "0.9000rem" }}>&nbsp;Allows unlimited interactions with EVA, including report creation, advice on-demand, data surfacing, and more.</span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$15/User</span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                        View-only:
                      </span>
                      <span style={{ fontSize: "0.9000rem" }}>&nbsp;Can view already built Dashboards, interact in chat, through Desktop and Mobile. Cannot Ask EVA, Generate reports with EVA, Pin or Share EVA Visuals.</span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$3/User</span>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column text-left">
                    <p className="text-sm text-left" style={{ fontWeight: 'bold', color: "#32325d " }}>
                      Microsoft Power BI:
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>Microsoft Power BI Etani Platform: </span>
                      <span style={{ fontSize: "0.9000rem" }}>&nbsp;This runs with Azure Data Warehouse and works other resources to simplify data handling. You need to run this platform to use Power BI and it’s advanced visualisation tools.</span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$200/month</span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Microsoft Power BI Access
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$10/month</span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-2 text-left">
                      <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Microsoft Power BI Pro Account
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-2 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>$15/User/month</span>
                    </p>
                  </Col>
                </Row>
              </div>
              <Row className="mb-2 mt-2 pt-3 d-flex align-items-start flex-column justify-content-center border-top">
                <Col><p className="h3 mb-3">Features</p></Col>
                {
                  seatPricingDetailsList.map((pdl, i) => (
                    <Col xs="12" xl="12" sm="12" md="12" key={i}>
                      <CustomPricingDetailsCollapse
                        data={pdl}
                      />
                    </Col>
                  ))
                }
              </Row>
              <div className="">
                <p className="h5 mt-2 mb-5" style={{ fontWeight: "500" }}>
                  *Microsoft Power BI Features
                </p>
              </div>
              <Col xs="12" className="text-center">
                <div className="mt-3">
                  <Button
                    className="mt-auto"
                    style={{
                      borderRadius: "50px",
                      backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                        }`,
                      color: `${props.textColor === "#000" ? "#fff" : props.textColor}`,
                      borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                        }`,
                    }}>
                    Cancel Subscription
                  </Button>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default SeatPricingCard;