import React, { useEffect } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import TreeviewGraph from './Graph/TreeviewGraph';

function GraphView({ sqlResult, gridHeader, theme, treeViewData, isWithChild, isShowGrid}) {
    const data = sqlResult
    const columnCount = gridHeader.length - 1

    const accountNames = sqlResult.map(item => item[columnCount - 1]);
    const totalSales = sqlResult.map(item => parseFloat(item[columnCount]));

    const getRandomColor = () => {
        return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
    };
    useEffect(()=> {
    })

    // const categories = Array.from(new Set(data.map(item => item[0])));
    // const subcategories = Array.from(new Set(data.map(item => item[1])));
    // const datasets = categories.map(category => ({
    //     label: category,
    //     data: data.filter(item => item[0] === category).map(item => item[2]),
    //     backgroundColor: getRandomColor(),
    //     borderWidth: 1,
    // }));

    // console.log(datasets)
    // const chartData = {
    //     labels: subcategories,
    //     datasets: datasets,
    // };

    // const chartOptions = {
    //     responsive: true,
    //     scales: {
    //     x: { stacked: true },
    //     y: { stacked: true },
    //     },
    // };
    
    const chartData = {
        labels: accountNames,
        datasets: [
            {
                label: 'Amount',
                data: totalSales,
                backgroundColor: theme.PrimaryColor,
                borderColor: theme.PrimaryColor,
                borderWidth: 1
            }
        ]
    };
    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };
    return (
        isShowGrid ? 
        <>
            <div>Gridview Graph</div>
            <Line data={chartData} options={chartOptions} />
        </>
        
        : 
        <TreeviewGraph
            sqlResult={sqlResult}
            gridHeader={gridHeader}
            theme={theme} 
            treeViewData={treeViewData}
            isWithChild={isWithChild}
            isShowGrid={isShowGrid}
            />
    )
}

export default GraphView