import { MainContext } from "context/mainContext";
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const Authority = ({ authority, ...props }) => {
  const [payloadProp, setPayloadProp] = useState();
  const { userDetails, firmDetails, isMultiBusinessUser, existingMySQLAccess, multiBusinessList, setExistingMySQLAccess } = useContext(MainContext);
  const [isMySQLAccessExist, setIsMySQLAccessExist] = useState(true)
  const [isManuallyAddedUser, setIsManuallyAddedUser] = useState(true)
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')
  const [isMyProfilePage, setIsMyProfilePage] = useState(false)
  const [otherAdminToEdit, setOtherAdminToEdit] = useState([])

  const isAnalyticsDashboardReportsChecked =
    props.payload?.AnalyticsDashboardReports;

  useEffect(() => {
    setPayloadProp(props.payload);
    if (isAnalyticsDashboardReportsChecked) {
      props.payload.AnalyticsDashboardReportGreen = true;
      props.payload.AnalyticsDashboardReportOrange = true;
    }
  }, [props, authority, props.payload, payloadProp, props.handleInputChange]);

  const existingUserWithAccess = [
    { Username: "Deb@etani.au", Password: "xB6tqyd64R39", Firm: "advise" },
    { Username: "diane@etani.au", Password: "gjaf8OI3STTg", Firm: "advise" },
    { Username: "erin@etani.au", Password: "bVQMG2zlsWWw", Firm: "advise" },
    { Username: "jacinta@etani.au", Password: "U4YLMfrxOutJ", Firm: "advise" },
    { Username: "Kristel@etani.au", Password: "psAEtJ92c4cA", Firm: "advise" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "advise" },
    { Username: "matt@etani.au", Password: "46bXMdRgroQI", Firm: "advise" },
    { Username: "jeremyheng@etani.au", Password: "jvdyWrzDXdqa", Firm: "austasiagroup" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "austasiagroup" },
    { Username: "simon@etani.au", Password: "CRBUEThMK5pr", Firm: "austasiagroup" },
    { Username: "Kristel@etani.au", Password: "psAEtJ92c4cA", Firm: "beautifuldata" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "beautifuldata" },
    { Username: "Craigs@etani.au", Password: "x1GJqSpMxjSc", Firm: "enspira" },
    { Username: "Kristel@etani.au", Password: "psAEtJ92c4cA", Firm: "enspira" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "enspira" },
    { Username: "branniell.galang@etani.au", Password: "X3coMCVbyio4", Firm: "mksgroup" },
    { Username: "kristian.said@etani.au", Password: "z34a03p1DtKQ", Firm: "mksgroup" },
    { Username: "mark.said@etani.au", Password: "VYnZpU9d8OpT", Firm: "mksgroup" },
    { Username: "patrick.carrier@etani.au", Password: "9Kuv2cFpC9xE", Firm: "mksgroup" },
    { Username: "samuel.lucas@etani.au", Password: "vLgYcIRlt5Wd", Firm: "momentumdoctors" },
    { Username: "brendan@etani.au", Password: "D036GZQ8cFVP", Firm: "nextda" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "nextda" },
    { Username: "John@etani.au", Password: "kvPib2617Rko", Firm: "rjsanderson" },
    { Username: "prashant@etani.au", Password: "yAaLzfAnl5DS", Firm: "rjsanderson" },
    { Username: "ravixkumar90@etani.au", Password: "bXbznXeLpQUl", Firm: "rjsanderson" },
    { Username: "bmarchione@etani.au", Password: "f6c98751d93a", Firm: "sw-au" },
    { Username: "jling@etani.au", Password: "b26a5ca5d921", Firm: "sw-au" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "sw-au" },
    { Username: "rjamal@etani.au", Password: "e03f0e15d93a", Firm: "sw-au" },
    { Username: "rhemphill@etani.au", Password: "906fa34ad921", Firm: "sw-au" },
    { Username: "ssmith@etani.au", Password: "055a90aed93b", Firm: "sw-au" },
    { Username: "smuthukumaran@etani.au", Password: "eb4e6d8e9a71", Firm: "sw-au" },
    { Username: "Yuktha@etani.au", Password: "610efc03d921", Firm: "sw-au" },
    { Username: "imke.hoepfner@etani.au", Password: "r6VGAcF3yXIS", Firm: "ubtaccountants" },
    { Username: "kristina.silby@etani.au", Password: "1yLBfQxDpNwh", Firm: "ubtaccountants" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "ubtaccountants" },
    { Username: "Nathan.Prestidge@etani.au", Password: "rj8dieoIuP8E", Firm: "ubtaccountants" },
    { Username: "Neville.Kennerley@etani.au", Password: "zL4sKBOhhqPb", Firm: "ubtaccountants" },
    { Username: "justin@etani.au", Password: "Ys6TegWqL4ow", Firm: "sudburys" },
    { Username: "nikita@etani.au", Password: "u8VgZpYYrnTb", Firm: "sudburys" },
    { Username: "stephen@etani.au", Password: "K52QSRAbynbV", Firm: "sudburys" },
    { Username: "victoria@etani.au", Password: "U8jZRxR7TVCO", Firm: "sudburys" },
    { Username: "Accounts@etani.au", Password: "RkF5ybWIfeDf", Firm: "sudburys" },
    { Username: "luism@etani.au", Password: "vu7JUMIf0bQ1", Firm: "enspira" },
    { Username: "ak@etani.au", Password: "LFwX3p5geZEq", Firm: "powertynan" },
    { Username: "bdt@etani.au", Password: "jPZIBbVAwTsF", Firm: "powertynan" },
    { Username: "Brad@etani.au", Password: "9PS1vQBe4ybW", Firm: "powertynan" },
    { Username: "chr@etani.au", Password: "Y3CHMu6Dz5hu", Firm: "powertynan" },
    { Username: "db@etani.au", Password: "61nD93Vd9WeZ", Firm: "powertynan" },
    { Username: "et@etani.au", Password: "QHzLVaBg0CTG", Firm: "powertynan" },
    { Username: "Gilda@etani.au", Password: "r2KaQl74nwVU", Firm: "powertynan" },
    { Username: "jk@etani.au", Password: "LNZ71wbbtpNg", Firm: "powertynan" },
    { Username: "ng@etani.au", Password: "OJzh3CiSdrQ2", Firm: "powertynan" },
    { Username: "ns@etani.au", Password: "fLBYLUyeH7uK", Firm: "powertynan" },
    { Username: "pbh@etani.au", Password: "3cf2cvjvfELh", Firm: "powertynan" },
    { Username: "pdr@etani.au", Password: "qQBSsIvE7I3K", Firm: "powertynan" },
    { Username: "sac@etani.au", Password: "Oh8p6pmTfRJB", Firm: "powertynan" },
    { Username: "stacey@etani.au", Password: "AHDZ8LEtaNIE", Firm: "powertynan" },
    { Username: "jamie@etani.au", Password: "9JBFCDGrH9sb", Firm: "blumetric" },
    { Username: "claire.gibbons@etani.au", Password: "2Vq6IjIFimFX", Firm: "williambuck-wa" },
    { Username: "yuxuan.gao@etani.au", Password: "LzczRCsUEMo7", Firm: "williambuck-wa" },
    { Username: "wa.xero@etani.au", Password: "vLtO2j8BN1do", Firm: "williambuck-wa" },
    { Username: "accounts.ahp@etani.au", Password: "PEJLBfHOqlIo", Firm: "ahp" },
    { Username: "lachlan.bonser@etani.au", Password: "9a47d6ed3833", Firm: "lbadvisory" },
    { Username: "leslieq@etani.au", Password: "eb4e6d8e9a71", Firm: "lbadvisory" },
    { Username: "maricel.garcia@etani.au", Password: "f9652957786e", Firm: "lbadvisory" },
    { Username: "BIAdmin@etani.au", Password: "E6QaqJ1eA9Gr", Firm: "visionbi" },
    { Username: "Matthew.Dodds@etani.au", Password: "f5zLNRwrlDZI", Firm: "visionbi" },
    { Username: "swathi.chatlaparthy@etani.au", Password: "ElozTIzjZ0Hd", Firm: "visionbi" },
    { Username: "victor@etani.au", Password: "q3ybhYrwwJal", Firm: "visionbi" },
  ];

  useEffect(() => {
    if (payloadProp === undefined || Object.keys(payloadProp).length === 0) return 

    if(userDetails.User.UserAccess < 5)
      checkMySQLGrantAccess()
  },[payloadProp, firmDetails])
  
  const hasRun = useRef(false);

  useEffect(() => {
    if (!isMySQLAccessExist && !hasRun.current) {
      processMySQLGrantAccess();
      hasRun.current = true;
    }
  }, [isMySQLAccessExist]);

  const checkMySQLGrantAccess = useCallback(() => {
    try {

      if(isMultiBusinessUser && payloadProp?.Email.toLowerCase() === userDetails?.User.Email.toLowerCase() ) {
        processMySQLAccessMultiBusinessUser()
        return
      }

      if(!payloadProp.MySQLAccess)
      {
        const matchedUser = validateManuallyAddedUser()
        if (matchedUser) {
          console.log("User access existed, manually added.");
          // handleUpdatePayload(matchedUser.Username, matchedUser.Password, matchedUser.Firm)
        } else {
          console.log("User does not have MySQL access for this firm.");
        }
        setIsMySQLAccessExist(false)
      } else {
        const existingIndex = payloadProp.MySQLAccess.findIndex(i =>
          i.accountcode === firmDetails.Code
        );

        if (existingIndex === -1) 
        {
          setIsMySQLAccessExist(false)
        } else {
          setIsMySQLAccessExist(true)
          setusername(payloadProp.MySQLAccess[0]._username)
          setpassword(payloadProp.MySQLAccess[0]._password)
        }
      }
    }catch(e) {
      console.log(e)
    }
  },[payloadProp, firmDetails])
  const processMySQLGrantAccess = useCallback(async () => {
    try{
      console.log('processMySQLGrantAccess() is running!')

      const matchedUser = validateManuallyAddedUser()

      const uuid = matchedUser ? matchedUser.Password : generateUUID()
      const username = payloadProp.UserName.split('@')[0] + '@etani.au'
      const accountcode = firmDetails.Code
      const MySQLAccess = [{ 
        'accountcode' : accountcode,
        '_username' : username,
        '_password' : uuid
      }]

      setusername(username)
      setpassword(uuid)

      let newPayload = payloadProp
      newPayload.MySQLAccess = MySQLAccess

      if (typeof props.handleSetPayload === "function")
        props.handleSetPayload(payloadProp)

      handleSaveMySQLAccess(payloadProp.MySQLAccess, false, accountcode, username, uuid)
      
    }catch(e) {
      console.log(e)
    }
  },[isMySQLAccessExist])

  const validateManuallyAddedUser = () => {
    const username = payloadProp.UserName.split('@')[0] + '@etani.au'
    const matchedUser = existingUserWithAccess.find(
      (user) =>
        user.Username.toLowerCase() === username.toLowerCase()
    );
    return matchedUser
  }
  const processMySQLAccessMultiBusinessUser = async () => {

    const matchedUser = validateManuallyAddedUser()
    if(existingMySQLAccess) 
    {
      const accountcode = firmDetails.Code
      const MySQLAccess = [{ 
        'accountcode' : accountcode,
        '_username' : existingMySQLAccess.username,
        '_password' : existingMySQLAccess.password
      }]

      let newPayload = payloadProp
      newPayload.MySQLAccess = MySQLAccess
      if (typeof props.handleSetPayload === "function")
        props.handleSetPayload(payloadProp)

      setusername(existingMySQLAccess.username)
      setpassword(existingMySQLAccess.password)
    } else {
      const uuid =  matchedUser ? matchedUser.Password : generateUUID()
      const username = payloadProp.UserName.split('@')[0] + '@etani.au'

      setusername(username)
      setpassword(uuid)
  
      for (const business of multiBusinessList) {
        const MySQLAccess = [{ 
          'accountcode' : business,
          '_username' : username,
          '_password' : uuid
        }]

        setExistingMySQLAccess({ username : username, password: uuid});
        let newPayload = payloadProp
        newPayload.MySQLAccess = MySQLAccess
        if(firmDetails.Code === business) {
          processMultiBusinessSavingCredentials(MySQLAccess)
        }
        executeMySQLAccess(business, username, uuid)
      }
    }
  }
  const processMultiBusinessSavingCredentials = (MySQLAccess) => {
    try {
      fetch(`/api/accountant/updateMultipleAuthority`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({
          Admin: payloadProp.Admin,
          AnalyticsDashboardReportGreen: payloadProp.AnalyticsDashboardReportGreen,
          AnalyticsDashboardReportOrange: payloadProp.AnalyticsDashboardReportOrange,
          AnalyticsDashboardReports: payloadProp.AnalyticsDashboardReports,
          Chat: payloadProp.Chat,
          PermanentDocs: payloadProp.PermanentDocs,
          PracticeManagerAccess: payloadProp.PracticeManagerAccess,
          PushNotifications: payloadProp.PushNotifications,
          ThirdPartyConnectors: payloadProp.ThirdPartyConnectors,
          MySQLAccess: MySQLAccess ? MySQLAccess: payloadProp.authority.MySQLAccess
        }),
      })
    }catch(e) {
      console.log(e)
    }
  }
  const handleResetPassword = async (e) => {
    const uuid = generateUUID();
    const userEmail = payloadProp?.UserName
    const username = userEmail.split('@')[0] + '@etani.au'
    setpassword(uuid)

    e.preventDefault();
    if(isMultiBusinessUser) {
      for (const business of multiBusinessList) {
        const MySQLAccess = [{ 
          'accountcode' : business,
          '_username' : username,
          '_password' : uuid
        }]

        setExistingMySQLAccess({ username : username, password: uuid});
        let newPayload = payloadProp
        newPayload.MySQLAccess = MySQLAccess

        if(firmDetails.Code === business) {
          processMultiBusinessSavingCredentials(MySQLAccess)
        }
      }

    } else {
      payloadProp.MySQLAccess[0]._password = uuid
      processMultiBusinessSavingCredentials(payloadProp.MySQLAccess)
    }

    const query = `ALTER USER '${username}'@'%' IDENTIFIED BY '${uuid}';`
    const body = { 
      'query' : query
    }
    await fetch("/api/user/executeMySQLAccess", {
      method: "POST",
      headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  };
  const handleSaveMySQLAccess = async (MySQLAccess, isEditMySQLAccess = false, business, username, uuid) => {
    props.handleUpdateAuthority(MySQLAccess, isEditMySQLAccess);
    executeMySQLAccess(business, username, uuid)
  };

  const executeMySQLAccess = async (business, username, uuid) => {
    const formattedUserName = username.split('@')[0] + `@${business}.au`
    const query = `call Grant_Select_Access_XeroBlue('${business}', '${formattedUserName}', '${uuid}');`
    try {
      const body = { 
        'query' : query
      }
      const details = await fetch("/api/user/executeMySQLAccess", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await details.json();
    }catch(e) {
      console.log(e)
    }
  }

  const generateUUID = () => {
    let uuid = '';
    const characters = 'abcdef0123456789';
    for (let i = 0; i < 12; i++) {
        // if (i === 8 || i === 12 || i === 16 || i === 20) {
        //     uuid += '';
        // } else {
            uuid += characters[Math.floor(Math.random() * characters.length)];
        // }
    }
    return uuid;
  }

  const isSameEmail = () => {
    if (payloadProp === undefined || Object.keys(payloadProp).length === 0) return true
    return payloadProp?.Email.toLowerCase() === userDetails?.User.Email.toLowerCase() || userDetails?.User.Email.toLowerCase() === 'admin@etani-bp.com.au'
  }

  return (
    <>
    {isSameEmail() &&
      <>
        <h3>Enable PowerBI MySQL Access</h3>
        <Form>
          <FormGroup>
              <Row>
                <Col sm='12' md='3'>
                  <Label htmlFor={'_username'}>User Name</Label>
                  <Input
                    type="text"
                    id="_username"
                    disabled
                    value={username}
                  />
                </Col>
                <Col sm='12' md='3'>
                  <Label htmlFor={'_password'}>Password</Label>
                  <Input
                    type="text"
                    id="_password"
                    disabled
                    value={password}
                  />
                  <div className="mt-3" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button color="primary" type="button" size="sm" onClick={e => handleResetPassword(e)}>
                      Reset Password
                    </Button>
                  </div>
                </Col>
              </Row>
          </FormGroup>
        </Form>
      </>
    }

      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Is this User an Admin?
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.Admin}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="Admin"
                  name="Admin"
                  disabled={
                    props.selectedAccountant?.User?.id ===
                    props.userDetails?.User?.id
                      ? true
                      : false
                  }
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Can invite/delete admins, invite/delete clients, viewing
                rights to the App Analytics / Power BI
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Practice Manager Access
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PracticeManagerAccess}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PracticeManagerAccess"
                  name="PracticeManagerAccess"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Access/Create/Delete Content Management System (CMS) About Tab.
                (Details, Tax Tables, Publications, Meeting The Team, Our
                Offices, Our Services).
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {
        firmDetails?.Details?.PbiEnabled ? 
          <div>
            <label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Power BI
            </label>
            <div>
              <Row>
                <Col className="col-lg-1" xs="2">
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input
                      defaultChecked={props.payload?.AnalyticsDashboardReports}
                      type="checkbox"
                      onClick={props.handleInputChange}
                      id="AnalyticsDashboardReports"
                      name="AnalyticsDashboardReports"
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <input
                    defaultChecked={props.payload?.AnalyticsDashboardReportOrange}
                    type="checkbox"
                    onChange={props.handleInputChange}
                    id="AnalyticsDashboardReportOrange"
                    name="AnalyticsDashboardReports"
                    style={{ display: "none" }}
                  />
                  <input
                    defaultChecked={props.payload?.AnalyticsDashboardReportGreen}
                    type="checkbox"
                    onChange={props.handleInputChange}
                    id="AnalyticsDashboardReportGreen"
                    name="AnalyticsDashboardReportGreen"
                    style={{ display: "none" }}
                  />
                </Col>
                <Col className="col-lg-11" xs="10">
                  <p>
                    Are you allowed to go live on dashboards and reports to clients
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          : null
      }
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Push Notifications
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PushNotifications}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PushNotifications"
                  name="PushNotifications"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Share business articles, send instant messages and create
                scheduled & repeatable push notifications with clients
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Chat - Public and Individual Level
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.Chat}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="Chat"
                  name="Chat"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Cloud Document Portal - Access
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PermanentDocs}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PermanentDocs"
                  name="PermanentDocs"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div> */}
      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Create 3rd Party Connectors for clients
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.ThirdPartyConnectors}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="ThirdPartyConnectors"
                  name="ThirdPartyConnectors"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Authority;
