import React from 'react'
import GroupList from './GroupList'

function Sidebar({ groupList, selectedGroupId, onSelectUUID, selectedUUID, theme, isOpenTab, onIsLoading, onSetAlert, onSelectGroup }) {
  return (
    <div 
      className={`sidebar ${isOpenTab ? 'open' : 'closed'}`} 
      style={{ border: `2px solid ${ theme.PrimaryColor }`}}
      > 
        <GroupList 
          groupList={groupList}
          selectedGroupId={selectedGroupId}
          onSelectGroup={onSelectGroup}
          onSelectUUID={onSelectUUID}
          selectedUUID={selectedUUID}
          theme={theme}
          onIsLoading={onIsLoading}
          onSetAlert={onSetAlert}
        />
    </div>
  )
}

export default Sidebar