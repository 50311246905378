import React from "react";
// eslint-disable-next-line
import { ClipLoader } from "react-spinners";
import { Card, CardBody, Row, Col } from "reactstrap";

const ProfileInfo = ({ data, ...props }) => {
  return (
    <>
      <Card className="card-profile">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <img
                style={{
                  background: "rgb(238, 238, 238)",
                  width: "140px",
                  height: "140px",
                  objectFit: "cover",
                  borderRadius: "10px",
                  border: " 2px solid rgb(226, 226, 226)",
                }}
                alt="..."
                src={
                  data && data.User && data.User.Avatar
                    ? data.User.Avatar
                    : require("assets/img/blank-avatar.png").default
                }
              />
            </div>
          </Col>
        </Row>
        <CardBody className="pt-0">
          {!props.isLoading || !props.contextFunctionsLoading ? (
            <div className="text-center" style={{ marginTop: "100px" }}>
              <h5 className="h3">
                {data && data.User && data.User.FirstName && data.User.LastName
                  ? `${data.User.FirstName} ${data.User.LastName}`
                  : ""}
              </h5>
              <div className="h4 font-weight-400">
                {data && data.Name ? data.Name : ""}
              </div>
              <Row>
                <Col sm="4" className="offset-sm-4">
                  <hr className="m-3" />
                </Col>
              </Row>
              <Row className="">
                <Col xs="12">
                  <h4>
                    <span className="btn-inner--icon ml-1">
                      <i className="ni ni-email-83" />
                    </span>
                    <a
                      href={`mailto:${
                        data && data.User && data.User.Email
                          ? data.User.Email
                          : ""
                      }`}
                      className="ml-2"
                    >
                      {data && data.User && data.User.Email
                        ? data.User.Email
                        : ""}
                    </a>
                  </h4>
                </Col>
                <Col xs="12">
                  <h4>
                    <span className="btn-inner--icon ml-1">
                      {data && data.User && data.User.Phone ? (
                        <i className="ni ni-send" />
                      ) : null}
                    </span>
                    <span className="ml-2">
                      {data && data.User && data.User.Phone
                        ? data.User.Phone
                        : ""}
                    </span>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col sm="4" className="offset-sm-4">
                  <hr className="m-3" />
                </Col>
                <Col xs="12">
                  <div className="h4 font-weight-400">
                    {data && data.Description ? data.Description : ""}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="text-center" style={{ marginTop: "100px" }}>
              <ClipLoader color={props.bgColor} size={20} margin={2} />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileInfo;
