import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Input
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import DatePicker from "react-date-picker";
import { dataFilterStaffNameOnly } from "utils/utilityFunctions";

const ThirdPartyAppTimeSheetTable = ({
  data,
  warningAlert,
  handleEditClient,
  userDetails,
  ...props
}) => {
  const { setSelectedClientID } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);
  const [sort, setSort] = useState({ field: 'age', direction: 'asc' });
  const [editedRows, setEditedRows] = useState({});
  const [dataToFilter, setDataToFilter] = useState([]);

  useEffect(() => {
    // console.log(data)
    setPaginatedData(data);
    setDataToFilter(data);
    // Get clientListNumberPerPage
    localStorage.getItem("clientListNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("clientListNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage,]);

  // const userFullName = (user) => {
  //   let clientUserAdmin = [];
  //   user.map((usr) => {
  //     if (usr.UserAccess === 5) {
  //       clientUserAdmin.push(usr);
  //     }
  //     return null;
  //   });
  //   if (clientUserAdmin.length) {
  //     return `${clientUserAdmin[0].FirstName} ${clientUserAdmin[0].LastName}`;
  //   }
  //   return "N/A";
  // };

  // const userEmail = (user) => {
  //   let clientUserAdmin = [];
  //   user.map((usr) => {
  //     if (usr.UserAccess === 5) {
  //       clientUserAdmin.push(usr);
  //     }
  //     return null;
  //   });
  //   if (clientUserAdmin.length) {
  //     return `${clientUserAdmin[0].Email}`;
  //   }
  //   return "N/A";
  // };

  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  const currentPageData = () => {
    return sortedData
      .slice(offset, Number(offset) + Number(numberPerPage))
      .map((data, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }}>
            <td className="name">
              {data.ID ? data.ID : data.id}
            </td>
            <td className="admin">
              {data.JobID ? data.JobID : ""}
            </td>
            <td className="admin-email">
              {data.JobName}
            </td>
            <td className="admin-email">
              {data.TaskID}
            </td>
            <td className="admin-email">
              {data.TaskName}
            </td>
            <td className="admin-email">
              {data.StaffID}
            </td>
            <td className="admin-email">
              {data.StaffName}
            </td>
            <td className="admin-email">
              {data.Date}
            </td>
            <td className="admin-email">
              {data.Minutes}
            </td>
            <td className="admin-email">
              {data.Note}
            </td>
            <td className="admin-email">
              {data.Billable}
            </td>
            <td className="admin-email">
              {data.InvoiceTaskID}
            </td>
            <td className="admin-email">
              {data.Start}
            </td>
            <td className="admin-email">
              {data.End}
            </td>
            <td className="admin-email">
              {data.ClientID}
            </td>
            <td className="admin-email">
              {data.OrganisationID}
            </td>
            <td className="admin-email">
              {data.ChargeOutRate}
            </td>
            {/* <td className="admin-email">
              {data.WrittenOffDate}
            </td> */}
            <EditableColumn
              value={data.WrittenOffDate}
              onChange={(newWrittenOffDate) => {
                const updatedRow = { ...data, WrittenOffDate: newWrittenOffDate };
                // setPaginatedData((prev) =>
                //   prev.map((item) => (item.StaffID === data.StaffID ? updatedRow : item))
                // );
                setEditedRows((prev) => ({ ...prev, [data.WrittenOffDate]: updatedRow }));
              }}
            />
          </tr>
        );
      });
  };

  const pageCount = () => {
    return Math.ceil(data.length / numberPerPage);
  };

  const changeUrl = (page, clientID) => {
    if (page === "users") {
      setSelectedClientID(clientID);
      return history.push(`/client/users/${clientID}`);
    }
    if (page === "advisers") {
      setSelectedClientID(clientID);
      return history.push(`/client/advisers/${clientID}`);
    }
    if (page === "dashboard") {
      setSelectedClientID(clientID);
      return history.push(`/client/dashboard/${clientID}`);
    }
    if (page === "reports") {
      setSelectedClientID(clientID);
      return history.push(`/client/reports/${clientID}`);
    }
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
    if (page === "signclient") {
      setSelectedClientID(clientID);
      return history.push(`/client/signclient/${clientID}`);
    }
  };

  const sortedData = [...paginatedData].sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.field] > b[sort.field] ? 1 : -1;
    } else {
      return a[sort.field] < b[sort.field] ? 1 : -1;
    }
  });


  function EditableColumn({ value, onChange }) {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const handleSave = () => {
      setIsEditing(false);
      onChange(currentValue);
      setPaginatedData((prevData) => {
        return prevData.map(d => editedRows[d.id] || d);
      });
    };

    return (
      <td onClick={() => !isEditing && setIsEditing(true)}>
        {isEditing ? (
          // <input
          //   value={currentValue}
          //   onChange={(e) => setCurrentValue(e.target.value)}
          //   onBlur={handleSave}
          // />
          <DatePicker selected={currentValue} onChange={(date) => setCurrentValue(date)} onBlur={handleSave} />
        ) : (
          value
        )}
      </td>
    );
  }

  const saveEdits = () => {
    console.log("Saving edited rows:", editedRows);
    // Here, make an API call or any other action to save the edited data.
  };


  const handleSearch = (e) => {
    let filterString = e.target.value;
    setPaginatedData(dataFilterStaffNameOnly(filterString, dataToFilter));
  }


  return (
    <>
      <Card>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
          <button onClick={saveEdits}>Save All Edits</button>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Input
                  placeholder="Search"
                  type="text"
                  bsSize="sm"
                  onChange={handleSearch}
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th className="" scope="col"
                  onClick={() => {
                    if (sort.field === 'ID' && sort.direction === 'asc') {
                      setSort({ field: 'ID', direction: 'desc' });
                    } else {
                      setSort({ field: 'ID', direction: 'asc' });
                    }
                  }}
                >
                  ID  {sort.field === 'ID' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="" scope="col" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sort.field === 'JobID' && sort.direction === 'asc') {
                      setSort({ field: 'JobID', direction: 'desc' });
                    } else {
                      setSort({ field: 'JobID', direction: 'asc' });
                    }
                  }}
                >
                  JobID  {sort.field === 'JobID' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="" scope="col">
                  JobName
                </th>
                <th className="" scope="col">
                  TaskID
                </th>
                <th className="" scope="col">
                  TaskName
                </th>
                <th className="" scope="col">
                  StaffID
                </th>
                <th className="" scope="col">
                  StaffName
                </th>
                <th className="" scope="col">
                  Date
                </th>
                <th className="" scope="col">
                  Minutes
                </th>
                <th className="" scope="col">
                  Note
                </th>
                <th className="" scope="col">
                  Billable
                </th>
                <th className="" scope="col">
                  InvoiceTaskID
                </th>
                <th className="" scope="col">
                  Start
                </th>
                <th className="" scope="col">
                  End
                </th>
                <th className="" scope="col">
                  ClientID
                </th>
                <th className="" scope="col">
                  OrganisationID
                </th>
                <th className="" scope="col">
                  ChargeOutRate
                </th>
                <th className="" scope="col">
                  WrittenOffDate
                </th>
                {/* <th scope="col">Actions</th> */}
                {/* <th className="" data-sort="admin-email" scope="col">
                  TEAM
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  BILL RATE
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  BILLABLE RATE
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  Fulltime Equiv %
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  Notes
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  Working?
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  Director/Partner?
                </th>
                <th className="" data-sort="admin-email" scope="col">
                  Fee Earner?
                </th> */}
                {/* <th className="sort" data-sort="engagement" scope="col">Signed Engagement</th> */}
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        <CardFooter className="py-4">
          {data && data.length >= 1 ? null : (
            <p className="text-center mt-3">No data found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              {/* <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={`${currentPage <= 0 ? 'disabled' : ''}`}>
                    <PaginationLink
                      onClick={e => handleChangePage(e, currentPage - 1)}
                      previous
                      href="#"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {showPages()}
                  <PaginationItem className={`${currentPage === (pageCount - 1) ? 'disabled' : ''}`}>
                    <PaginationLink
                      onClick={e => handleChangePage(e, currentPage + 1)}
                      previous
                      href="#"
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav> */}
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card >
    </>
  );
};

export default ThirdPartyAppTimeSheetTable;
