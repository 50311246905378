import { sendSQLQuery } from './mongoDB';

const zlib = require('zlib');
const crypto = require('crypto');

const enc_key_literal = "\xe2\xbaJ\x94\x02\xb9Y\xdbJ+\xce\xd8\xa1\x94i\xbb(\x29\xfcO\x45\x90=g\x83&z\xd0\xec\x0e\xda=";
const enc_iv_literal = "\x83\xf18^9\x1c\x9d\x0e\xd1S\x9aFe\xe8y\xd5"

const key = new Uint8Array([...enc_key_literal].map(char => char.charCodeAt(0)));
const iv = new Uint8Array([...enc_iv_literal].map(char => char.charCodeAt(0)));

async function decryptAndDecompress(encryptedBase64Text) {
    // Convert the IV string to a Buffer
    const ivBuffer = Buffer.from(iv);
   
    // Convert the base64-encoded string to a Buffer
    const encryptedBuffer = Buffer.from(encryptedBase64Text, 'base64');
   
    // Create a decipher instance
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), ivBuffer);
   
    // Decrypt the data
    let decryptedData = decipher.update(encryptedBuffer, 'binary', 'binary');
    decryptedData += decipher.final('binary');
   
    // Decompress the decrypted data using zlib
    const decompressedData = zlib.inflateSync(Buffer.from(decryptedData, 'binary'));
   
    // Convert the Buffer to a string
    const resultText = decompressedData.toString('utf-8');
   
    return resultText;
}
const submitEvaQueryHandler = async (sText, selectedClient, firmDetails, userDetails) => 
{
    if(!selectedClient) return 
    const clientId = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient.id
    const clientName = transformCompanyName(firmDetails.Code)
    const tableName = `${clientName}_client${clientId}`
    const newTableName = tableName.replace(/-/g, "")
    let formatedSText = sText.replace(/\[id\]_balancesheetaccruals/g, "[id]_xeroblueaccrualtransactions")
                                .replace(/\[id\]_profitandlossaccruals/g, "[id]_xeroblueaccrualtransactions")
                                .replace(/\[id\]_budgets/g, "[id]_xerobluebudgets")
                                .replace(/\[id\]_cashtransactions/g, "[id]_xerobluecashtransactions")

    formatedSText = (formatedSText ?? "").replace(/\[id\]/g, newTableName)
          
    const email = userDetails.User.Email.toLowerCase()

    const specialEmails = [
        // "jp@etani.com.au", 
    "fuhad@etani.com.au", "admin@etani-bp.com.au"];

    if (specialEmails.includes(email))
        console.log(formatedSText);

    const queries = convertQueryStringToArray(formatedSText)

    const body = { sText: formatedSText }
    try {
        if(queries.length > 1) 
        { 
            const res = await sendSQLQuery(queries, clientId, userDetails.User.UserID)
            return res
        } else {
            const details = await fetch("/api/eva/query", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
           
            const data = await details.json();
            
            if(!sText.includes("_eva_sql_templates"))
            {
                const processedData = data.result.rows.map(item => {
                    return Object.keys(item).reduce((acc, key) => {
                        let value = item[key];
                        // Check if value is a percentage string or a number string
                        if (typeof value === 'string') {
                            if (value.includes('%')) 
                            {
                                if(!isNaN(parseFloat(value.replace('%', '')).toFixed(2)))
                                {
                                    value = parseFloat(value.replace('%', '')).toFixed(2) + '%'
                                }
                            }else
                                value = value
                        } else if (typeof value === 'number') {
                            value = Number(value.toFixed(2));
                        }
        
                        acc[key] = value;
                        return acc;
                    }, {});
                });
                // console.log(processedData)
                return processedData;
            }else {
                // console.log([...data.result.rows])
                return [...data.result.rows]
            }
        }
    } catch (error) {
        console.log(error);
    }
};
function convertQueryStringToArray(queryString) {
    return queryString
      .split(";") // Split by newline
      .map(query => query.trim()) // Trim whitespace from each query
      .filter(query => query.length > 0); // Remove any empty strings
  }
const getLastRefreshDate = async (selectedClient, firmDetails, integration) => {
    if(!selectedClient) return 

    const clientId = selectedClient.id
    const clientName = transformCompanyName(firmDetails.Code)
    const tableName = `${clientName}_client${clientId}`

    // let query = `SELECT lastrefreshutcdate FROM ${tableName}_xerobluelastrefreshdate
    //     ORDER BY lastrefreshUTCDate DESC
    //     LIMIT 1`

    // if(integration === "MYOB AR Live")
    //     query = `SELECT lastrefreshutcdate FROM ${tableName}_myob_lastrefreshdate
    //     ORDER BY lastrefreshUTCDate DESC
    //     LIMIT 1`

    // if(integration === "WFM")
    //     query = `SELECT lastrefreshutcdate FROM ${tableName}_wfm2lastrefreshdate
    //     ORDER BY lastrefreshUTCDate DESC
    //     LIMIT 1`

    const body = { tableName, integration: integration ? integration : 'default' }
    try {
        const details = await fetch("/api/eva/getEVAIntegrationLastRefreshDate", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        const data = await details.json();

        if(data.success)
        {
            // console.log(data)
            return { lastRefresh : integration ? data.result.rows[0].lastrefreshutcdate : null }
        }

    } catch (error) {
        console.log(error);
    }

    // const body = { sText: query }
    // try {
    //     const details = await fetch("/api/eva/query", {
    //         method: "POST",
    //         headers: {
    //         "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(body),
    //     });
    //     const data = await details.json();
    //     // console.log("data: ", data)
    //     if(data.success)
    //         return data.result.rows[0].lastrefreshutcdate
    // } catch (error) {
    //     // console.log(error);
    // }
    
}
const transformCompanyName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '');
};
const validateAndReplaceNulls = (data) => {
    return data.map(item => {
        Object.keys(item).forEach(key => {
            if (item[key] === null) {
                item[key] = '';
            }
        });
        return item;
    });
}
export { decryptAndDecompress, submitEvaQueryHandler, getLastRefreshDate, validateAndReplaceNulls }