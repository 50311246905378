import { createElement } from "react";

function IconUser({ className, ...props }) {
    return (
      createElement('svg', {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 256 256',
        fill: 'currentColor',
        ...props
      },
        createElement('path', {
          d: 'M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z',
        })
      )
    );
  }
function IconEtani({ className, ...props }) {
    return (
        createElement('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 256 256',
            ...props,
            fill: '#fff',
            style: { backgroundColor: '#fff', borderRadius: '4px', width: '40px', height: '40px' }
        },
                createElement('g', { transform: 'translate(15.000000,240.000000) scale(0.100000,-0.100000)', stroke: 'none' },
                createElement('path', { d: 'M180 1095 l0 -965 950 0 950 0 0 115 0 115 -832 2 -833 3 -3 727 -2 728 832 2 833 3 3 118 3 117 -951 0 -950 0 0 -965z', fill: '#292a63' }),
                createElement('path', { d: 'M600 1095 l0 -545 740 0 740 0 0 115 0 115 -625 0 -625 0 0 310 0 310 623 2 622 3 3 118 3 117 -741 0 -740 0 0 -545z', fill: '#292a63' }),
                createElement('path', { d: 'M1030 1095 l0 -115 525 0 525 0 0 115 0 115 -525 0 -525 0 0 -115z', fill: '#292a63' }),
            )
        )
    );
}
function CloseTab({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      ...props,
      fill: 'none', // Set fill to none to make the background transparent
      style: {
        backgroundColor: 'transparent', // Set background color to transparent
        borderRadius: '4px',
        width: '35px',
        height: '35px',
      }
    },
      createElement('g', { stroke: '#fff' },
        createElement('path', { d: 'M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h40v144H40Zm176 144H96V56h120v144Z', fill: '#fff' }),
      )
    )
  );
}

function IconTrash({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      fill: '#ff3e3e',
      style: { width: '45px', height: '20px' },
      ...props
    },
      createElement('path', { d: 'M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16ZM96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Zm48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Z' }),
    )
  );
}
function IconLike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M2 9h3v12H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1zm5.293-1.293l6.4-6.4a.5.5 0 0 1 .654-.047l.853.64a1.5 1.5 0 0 1 .553 1.57L14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H8a1 1 0 0 1-1-1V8.414a1 1 0 0 1 .293-.707z' }),
      )
    )
  );
}

function IconDislike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M22 15h-3V3h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-5.293 1.293l-6.4 6.4a.5.5 0 0 1-.654.047L8.8 22.1a1.5 1.5 0 0 1-.553-1.57L9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H16a1 1 0 0 1 1 1v11.586a1 1 0 0 1-.293.707z' }),
      )
    )
  );
}
function Shield({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#fff',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px', stroke: '#333' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M19,14.7368421 C19,17.122807 16.6666667,19.2105263 12,21 C7.33333333,19.2105263 5,17.122807 5,14.7368421 C5,12.3508772 5,9.36842105 5,5.78947368 C8.13611482,4.59649123 10.4694481,4 12,4 C13.5305519,4 15.8638852,4.59649123 19,5.78947368 C19,9.36842105 19,12.3508772 19,14.7368421 Z' }),
      )
    )
  );
}

function IconSort({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" style={{ width: '20px', height: '20px' }} {...props}>
      <path d="M4 8H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 13H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 18H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 20V4L20 8" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function IconGrid({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#525f7f" width="20px" height="20px" viewBox="0 0 1920 1920">
        <path d="M1800 1320v420c0 33-27 60-60 60h-420v-480h480Zm-600 0v480H720v-480h480Zm-600 0v480H180c-33 0-60-27-60-60v-420h480Zm1200-600v480h-480V720h480Zm-600 0v480H720V720h480Zm-600 0v480H120V720h480Zm1140-600c33 0 60 27 60 60v420h-480V120h420Zm-540 0v480H720V120h480Zm-600 0v480H120V180c0-33 27-60 60-60h420ZM1740 0H180C80.76 0 0 80.76 0 180v1560c0 99.24 80.76 180 180 180h1560c99.24 0 180-80.76 180-180V180c0-99.24-80.76-180-180-180Z" fillRule="evenodd"/>
    </svg>
  );
}

function IconPie({ className, ...props }) {
  return (
  <svg width="25px" height="25px" viewBox="3 2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.75 3.75H12V12H20.25V11.25C20.25 7.10786 16.8921 3.75 12.75 3.75ZM13.5 10.5V5.29642C16.214 5.63486 18.3651 7.78596 18.7036 10.5H13.5ZM4.5 13.5C4.5 10.4403 6.79027 7.91549 9.75 7.54642V14.25H16.4536C16.0845 17.2097 13.5597 19.5 10.5 19.5C7.18629 19.5 4.5 16.8137 4.5 13.5ZM10.5 6C6.35786 6 3 9.35786 3 13.5C3 17.6421 6.35786 21 10.5 21C14.6421 21 18 17.6421 18 13.5V12.75H11.25V6H10.5Z" fill="#525f7f"/>
  </svg>
  );
}

function IconBar({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#525f7f" width="20px" height="20px" viewBox="-0.5 0 32 32" preserveAspectRatio="xMidYMid">
        <path d="M30.000,32.000 L23.000,32.000 C22.447,32.000 22.000,31.552 22.000,31.000 L22.000,1.000 C22.000,0.448 22.447,-0.000 23.000,-0.000 L30.000,-0.000 C30.553,-0.000 31.000,0.448 31.000,1.000 L31.000,31.000 C31.000,31.552 30.553,32.000 30.000,32.000 ZM29.000,2.000 L24.000,2.000 L24.000,30.000 L29.000,30.000 L29.000,2.000 ZM19.000,32.000 L12.000,32.000 C11.448,32.000 11.000,31.552 11.000,31.000 L11.000,17.000 C11.000,16.448 11.448,16.000 12.000,16.000 L19.000,16.000 C19.553,16.000 20.000,16.448 20.000,17.000 L20.000,31.000 C20.000,31.552 19.553,32.000 19.000,32.000 ZM18.000,18.000 L13.000,18.000 L13.000,30.000 L18.000,30.000 L18.000,18.000 ZM8.000,32.000 L1.000,32.000 C0.448,32.000 0.000,31.552 0.000,31.000 L0.000,11.000 C0.000,10.448 0.448,10.000 1.000,10.000 L8.000,10.000 C8.552,10.000 9.000,10.448 9.000,11.000 L9.000,31.000 C9.000,31.552 8.552,32.000 8.000,32.000 ZM7.000,12.000 L2.000,12.000 L2.000,30.000 L7.000,30.000 L7.000,12.000 Z"/>
    </svg>
  );
}


function IconLine({ className, ...props }) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
    <path xmlns="http://www.w3.org/2000/svg" d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M20 8L16.0811 12.1827C15.9326 12.3412 15.8584 12.4204 15.7688 12.4614C15.6897 12.4976 15.6026 12.5125 15.516 12.5047C15.4179 12.4958 15.3215 12.4458 15.1287 12.3457L11.8713 10.6543C11.6785 10.5542 11.5821 10.5042 11.484 10.4953C11.3974 10.4875 11.3103 10.5024 11.2312 10.5386C11.1416 10.5796 11.0674 10.6588 10.9189 10.8173L7 15" stroke="#525f7f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
}

function IconCard({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="2 2 17 17">
      <g fill="none" fillRule="evenodd" stroke="#525f7f" strokeLinecap="round" strokeLinejoin="round" transform="translate(2 3)">
        <path d="m5.5.5h6c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-6c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2zm8 2.5h1c1.1045695 0 2 .8954305 2 2v5c0 1.1045695-.8954305 2-2 2h-1z"/>
        <path d="m.5 3h1c1.1045695 0 2 .8954305 2 2v5c0 1.1045695-.8954305 2-2 2h-1z" transform="matrix(-1 0 0 1 4 0)"/>
      </g>
    </svg>
  );
}

function IconClose({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
      <path d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 
      7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 
      8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 
      14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 
      13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 
      14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z" fill="#f55b5b"/>
      <path fillRule="evenodd" clipRule="evenodd" 
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 
        23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 
        16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="#f55b5b"/>
    </svg>
  );
}




export {
    IconUser,
    IconEtani,
    CloseTab,
    IconTrash,
    IconLike,
    IconDislike,
    IconSort,
    Shield,
    IconGrid,
    IconPie,
    IconBar,
    IconLine,
    IconCard,
    IconClose
}