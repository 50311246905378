import React, { useContext, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MainContext } from "context/mainContext";
import { IconExpand } from "chat-components/ui/icon";
import { IconMinimize } from "chat-components/ui/icon";

const CustomDashboardPanelCard = ({showEVAButton=true , ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={isFullScreen ? 'DashboardToggleFullScreen': ''}>
      <Card className="fadeIn" style={props.style || {}}>
        <CardHeader
          style={{
            backgroundColor: `${bgColor}`,
            transition: ".3s ease-in-out",
          }}
        >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <h5 className="h3 mb-0" style={{ color: `${textColor}` }}>
                    {props.headerName}
                </h5>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              {showEVAButton && !props.editMode && 
                <div className="h3 mb-0" style={{ color: `${textColor}`, cursor: 'pointer' }}>
                    <Button 
                      className="btn btn-3" 
                      color={!props.showEVA ? 'secondary' : 'danger'} type="button" 
                      style={{ padding: '0.325rem 1rem', fontSize: '10px', marginRight: props.showEVA ? '5px' : '20px',  background: props.showEVA ? '#ff0000' : '',  color: props.showEVA ? '' : bgColor }}
                      onClick={props.toggleShowEVA}
                      > 
                        <span className="">{!props.showEVA ? 'Show' : 'Hide'} EVA</span>
                    </Button>
                  {props.showEVA && 
                    <Button 
                      className="btn btn-3" 
                      color='secondary' 
                      type="button" 
                      style={{ padding: '0.325rem 1rem', fontSize: '10px', marginRight: '20px',  color: bgColor }}
                      onClick={props.toggleShowChat}
                      > 
                        <span className="">{!props.showChat ? 'Show Chat' : 'Show EVA'}</span>
                    </Button>}
                </div>
              }
              <div className="h3 mb-0" style={{ color: `${textColor}`, cursor: 'pointer' }} onClick={toggleFullScreen}>
                  {isFullScreen ? <IconMinimize /> : <IconExpand />}
              </div>
            </div>
        </div>
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    </div>
  );
};

export default CustomDashboardPanelCard;
