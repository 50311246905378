import React, { useState } from "react";
import {
  Modal,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import getVideoId from "get-video-id";

import CustomSpinner from "components/Misc/CustomSpinner";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import { MainContext } from "context/mainContext";
import ReactPlayer from "react-player";
import parse from "html-react-parser";

import "./style.css";
import { CustomButton } from "components/Button/CustomButton";
import CustomTooltip from "customComponents/customComponents";

const AddThirdParty = ({ serviceData, filteredServiceData, firmDetails, ...props }) => {
  // eslint-disable-next-line
  // const { textColor, bgColor } = useContext(MainContext);
  const [viewVideo, setViewVideo] = useState({});
  const [alert, setAlert] = useState(null);
  const [defaultModal, setdefaultModal] = useState(false);
  // const [modalTitle, setModalTitle] = useState("");
  // const [videoUrl, setVideoUrl] = useState("");
  const [selectedService, setSelectedService] = useState();

  const openVideo = (value) => {
    let key_to_update = {};
    key_to_update[value] = true;
    setViewVideo(Object.assign({}, viewVideo, key_to_update));
    // setOpen(true)
  };

  const closeModal = (value) => {
    let key_to_update = {};
    key_to_update[value] = false;
    setViewVideo(Object.assign({}, viewVideo, key_to_update));
    // setOpen(false)
  };
  const warningAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setAlert(null)}
        // onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="ok"
        btnSize=""
      // showCancel={true}
      >
        This service is not yet available
      </ReactBSAlert>
    );
  };

  const playTutorialVideo = (data) => {
    // console.log("played", data)
    setSelectedService(data);
    setdefaultModal(true);
  };

  const closeTutorialModal = () => {
    setdefaultModal(false);
  };

  const servicesList = () => {
    let services = filteredServiceData;
    // console.log(services)
    if (props.workspace) {
      services = services.filter(
        (serv) => serv.Name !== props.workspace?.Service?.Name
      );
    }
    if (services.length === 0) {
      if (props.loading) return <CustomSpinner />;
    } else {
      return services.map((data, i) => {
        // console.log(data)
        let Details = data.Details;
        // console.log("DETAILS",Details)
        let videoId = "";
        if (
          Details != null &&
          Details.training != null &&
          Details.training.link != null
        ) {
          const { id } = getVideoId(`${Details.training.link}`);
          // eslint-disable-next-line
          videoId = id;
        }

        return (
          // firmDetails?.PricingPlan.Code === 'TIER6' && !data.Details?.EvaEnabled ? null 
          // :
          <Col className=" mt-3 text-center " xl="3" lg="4" sm="6" key={i}>
            <Card className="shadow-none shadow-lg--hover border p-2">
              {/* <div style={{ position: 'absolute', top: 10, left: 20, cursor: 'pointer' }}>
                {props.showServiceStatus(data.Status, i)}
              </div> */}
              <CardBody className=" p-2">
                <Row className="">
                  <Col className="float-right">
                    <CustomTooltip
                      leftdiv={`float-right ${data.TrainingVideoUrl ? `text-danger` : `text-default`
                        }`}
                      i={i}
                      // id={`editAccountant${props.i}`}
                      message={
                        data.TrainingVideoUrl
                          ? `Play Tutorial`
                          : `No Available Tutorial`
                      }
                      className="fas fa-play-circle"
                      // className="fas fa-pen-alt"
                      onClick={() =>
                        data.TrainingVideoUrl ? playTutorialVideo(data) : null
                      }
                    />
                  </Col>
                </Row>
                {/* <Row
                  onClick={() => {
                    (firmDetails?.PricingPlan.Code === 'TIER6' && !firmDetails?.Details?.PbiEnabled) && !data.Details?.EvaEnabled ?
                      null :
                      data.Status === 1
                        ? props.openCreateModal(data)
                        : warningAlert();
                  }}
                  id={`serviceBody${i}`}
                > */}
                <Row
                    onClick={() => {
                      if ((firmDetails?.PricingPlan.Code === 'TIER6' && !firmDetails?.Details?.PbiEnabled) && !data.Details?.EvaEnabled) {
                        // Do nothing (or you can explicitly state it)
                        return;
                      } else {
                        if (data.Status === 1) {
                          props.openCreateModal(data);
                        } else {
                          warningAlert();
                        }
                      }
                    }}
                    id={`serviceBody${i}`}
                  >
                  <Col>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded img-center img-fluid "
                        src={data.Logo}
                        style={{ height: "140px", objectFit: "contain", filter: `${(firmDetails?.PricingPlan.Code === 'TIER6' && !firmDetails?.Details?.PbiEnabled) && !data.Details?.EvaEnabled ? `grayscale(100%)` : `grayscale(0%)`}`  }}
                      />
                    </a>
                    <div
                      className="pt-4 text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <h5 className="h3 title">
                        <span className="d-block mb-1">{data.Name}</span>
                      </h5>
                      {
                        firmDetails?.PricingPlan.Code === 'TIER6' ?
                          <div>
                            <span className="d-block mb-1">${data.Details?.Price ?? 10}</span>
                          </div>
                          : null
                      }
                    </div>
                  </Col>
                </Row>
                {
                  (firmDetails?.PricingPlan.Code === 'TIER6' && !firmDetails?.Details?.PbiEnabled) && !data.Details?.EvaEnabled ?
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={`serviceBody${i}`}
                    >
                      {`Only available with Power BI - Coming soon to EVA`}
                    </UncontrolledTooltip>
                    : null
                }
              </CardBody>
              {Details != null &&
                Details.training != null &&
                Details.training.link != null ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 20,
                      display: "block",
                    }}
                    onClick={() => openVideo(data.id)}
                  >
                    <i
                      className="fas fa-video text-dark text-lg"
                      id={`tp${i}`}
                    />
                    <UncontrolledTooltip target={`tp${i}`}>
                      Click here to watch the video tutorial for {data.Name}
                    </UncontrolledTooltip>
                  </div>
                  <Modal
                    size="lg"
                    className="modal-dialog custom"
                    isOpen={viewVideo[data.id]}
                  // toggle={() => closeModal(data.id)}
                  >
                    <div className="modal-body bg-dark py-5 px-3 px-lg-5">
                      <ReactPlayer
                        className="react-player-custom"
                        url={Details.training.link}
                        width="100%"
                        controls
                        light={true}
                        // height="calc(100vh - 100px)"
                        playsinline={true}
                      />
                      <h5 className="h1 title mt-3 mb--3 text-center text-light">
                        <span className="d-block mb-1">{data.Name}</span>

                        <span className="h3 d-block mb-1 text-light">
                          {data.Description}
                        </span>
                      </h5>
                    </div>
                    <i
                      className="fas fa-times text-light"
                      style={{
                        position: "absolute",
                        fontSize: "1.2em",
                        right: 20,
                        top: 15,
                      }}
                      onClick={() => closeModal(data.id)}
                    ></i>
                  </Modal>
                </>
              ) : null}
            </Card>
          </Col>
        );
      });
    }
  };

  // const handleSelectService = (Name) => {
  //   console.log(`You've Selected ${Name} Application`)
  // }

  return (
    <>
      {alert}
      <Modal
        className="modal-dialog"
        isOpen={props.modal}
        size="xl"
      // toggle={() => props.closeServiceModal()}
      >
        <div className="modal-body">
          <i
            className="ni ni-fat-remove"
            onClick={() => props.closeServiceModal()}
            style={{
              position: "absolute",
              fontSize: "1.5em",
              top: 14,
              right: 20,
            }}
          />
          <h5 className="modal-title mb-0">Select Services</h5>
          <Row>
            <Col lg={{ size: "4", offset: "8" }} sm={{ size: "4", offset: "8" }} className="my-2">
              <Input
                placeholder="Search"
                type="text"
                bsSize="sm"
                onChange={props.handleSearchServices}
              />
            </Col>
          </Row>

          <Row style={{ maxHeight: "80vh", overflow: "auto" }}>{servicesList()}</Row>

          <div className="modal-footer p-0">
            <CustomButton
              type="button"
              title="Close"
              outlineStyle={false}
              onClick={() => props.closeServiceModal()}
            />
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={defaultModal}
      // toggle={() => closeModal()}
      >
        <div className="modal-body ">
          <div className="modal-header px-0">
            <h6 className="modal-title">
              {selectedService ? selectedService.Name : ""}
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => closeTutorialModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              className="react-player-custom"
              style={{ position: "absolute", top: 0, right: 0 }}
              url={
                selectedService && selectedService.TrainingVideoUrl
                  ? selectedService.TrainingVideoUrl
                  : ""
              }
              controls
              width="100%"
              height="100%"
              light={true}
              stopOnUnmount={false}
              playsinline={true}
              pip={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                vimeo: {
                  playerOptions: {
                    autopause: true,
                    responsive: true,
                    loop: true,
                    keyboard: true,
                    pip: true,
                  },
                },
              }}
            />
          </div>
          <Row
            className="mt-3"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            {/* {videoList()} */}
            {/* <Courses /> */}
            <Col>
              <h3>Description</h3>
              {selectedService?.Details?.TrainingVideoDescriptions
                ? parse(selectedService?.Details?.TrainingVideoDescriptions)
                : ""}
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddThirdParty;
