import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import "./styles.css";

export const SecurityWrapperContext = React.createContext();

const SecurityWrapper = ({ children }) => {
  const history = useHistory();
  const [auth, setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const LoadingScreen = () => {
    return (
      <div className="loading-container-blk">
        <div className="loading-container-blk-content">
          <img src={require("assets/img/etani-logo-cms.png").default} />
          <p>LOADING</p>
        </div>
      </div>
    );
  };

  async function checkLogin() {
    const response = await fetch("/api/auth/checkLogin", {
      credentials: "include",
    });
    const parsedResult = await response.json();
    return parsedResult.authenticated;
  }
  const redirectToLogin = () => {
    history.push("/auth/login"); // Redirect to login page
  };

  useEffect(() => {
    // Fetch the JWT token from your server
    let isMounted = true;
    checkLogin()
      .then((result) => {
        if (!result) {
          redirectToLogin();
        }
        if (isMounted) {
          setIsLoading(false);
          setIsTokenValidated(true);
          setAuth(result);
        }
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setAuth(false);
          setIsTokenValidated(false);
          redirectToLogin();
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const secureFetch = (url, options = {}) => {
    if (isLoading) {
      throw new Error("Security wrapper is still loading the JWT token.");
    }

    const headers = {
      ...options.headers,
    };

    return axios({
      ...options,
      headers,
    });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <SecurityWrapperContext.Provider value={{ secureFetch }}>
      {children}
    </SecurityWrapperContext.Provider>
  );
};

export default SecurityWrapper;
