import { createElement } from "react";

function IconUser({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      fill: 'currentColor',
      ...props
    },
      createElement('path', {
        d: 'M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z',
      })
    )
  );
}
function IconEtani({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      ...props,
      fill: '#fff',
      style: { backgroundColor: '#fff', borderRadius: '4px', width: '40px', height: '40px' }
    },
      createElement('g', { transform: 'translate(15.000000,240.000000) scale(0.100000,-0.100000)', stroke: 'none' },
        createElement('path', { d: 'M180 1095 l0 -965 950 0 950 0 0 115 0 115 -832 2 -833 3 -3 727 -2 728 832 2 833 3 3 118 3 117 -951 0 -950 0 0 -965z', fill: '#292a63' }),
        createElement('path', { d: 'M600 1095 l0 -545 740 0 740 0 0 115 0 115 -625 0 -625 0 0 310 0 310 623 2 622 3 3 118 3 117 -741 0 -740 0 0 -545z', fill: '#292a63' }),
        createElement('path', { d: 'M1030 1095 l0 -115 525 0 525 0 0 115 0 115 -525 0 -525 0 0 -115z', fill: '#292a63' }),
      )
    )
  );
}
function CloseTab({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      ...props,
      fill: 'none', // Set fill to none to make the background transparent
      style: {
        backgroundColor: 'transparent', // Set background color to transparent
        borderRadius: '4px',
        width: '35px',
        height: '35px',
      }
    },
      createElement('g', { stroke: '#fff' },
        createElement('path', { d: 'M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h40v144H40Zm176 144H96V56h120v144Z', fill: '#fff' }),
      )
    )
  );
}

function IconTrash({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      fill: '#ff3e3e',
      style: { width: '25px', height: '20px' },
      ...props
    },
      createElement('path', { d: 'M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16ZM96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Zm48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Z' }),
    )
  );
}
function IconLike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M2 9h3v12H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1zm5.293-1.293l6.4-6.4a.5.5 0 0 1 .654-.047l.853.64a1.5 1.5 0 0 1 .553 1.57L14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H8a1 1 0 0 1-1-1V8.414a1 1 0 0 1 .293-.707z' }),
      )
    )
  );
}

function IconDislike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M22 15h-3V3h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-5.293 1.293l-6.4 6.4a.5.5 0 0 1-.654.047L8.8 22.1a1.5 1.5 0 0 1-.553-1.57L9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H16a1 1 0 0 1 1 1v11.586a1 1 0 0 1-.293.707z' }),
      )
    )
  );
}

function IconExpand({ className, ...props }) {
  return (
    createElement('svg', {
      width: '20',
      height: '20',
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      ...props
    },
      createElement('path', { d: 'M16 8L21 3M21 3H16M21 3V8M8 8L3 3M3 3L3 8M3 3L8 3M8 16L3 21M3 21H8M3 21L3 16M16 16L21 21M21 21V16M21 21H16', stroke: props.color, strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    )
  );
}

function IconMinimize({ className, ...props }) {
  return (
    createElement('svg', {
      width: '20',
      height: '20',
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      ...props
    },
      createElement('path', { d: 'M20.8571 9.75C21.2714 9.75 21.6071 9.41421 21.6071 9C21.6071 8.58579 21.2714 8.25 20.8571 8.25H16.8107L22.5303 2.53033C22.8232 2.23744 22.8232 1.76256 22.5303 1.46967C22.2374 1.17678 21.7626 1.17678 21.4697 1.46967L15.75 7.18934V3.14286C15.75 2.72864 15.4142 2.39286 15 2.39286C14.5858 2.39286 14.25 2.72864 14.25 3.14286V9C14.25 9.41421 14.5858 9.75 15 9.75H20.8571Z', fill: '#272A65' }),
      createElement('path', { d: 'M3.14286 14.25C2.72864 14.25 2.39286 14.5858 2.39286 15C2.39286 15.4142 2.72864 15.75 3.14286 15.75H7.18934L1.46967 21.4697C1.17678 21.7626 1.17678 22.2374 1.46967 22.5303C1.76256 22.8232 2.23744 22.8232 2.53033 22.5303L8.25 16.8107V20.8571C8.25 21.2714 8.58579 21.6071 9 21.6071C9.41421 21.6071 9.75 21.2714 9.75 20.8571V15C9.75 14.5858 9.41421 14.25 9 14.25H3.14286Z', fill: '#272A65' }),
    )
  );
}
const IconEdit = ({ className, ...props }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-edit" fill="none" height="20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" viewBox="0 0 24 24" width="24">
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
      </svg>
    </>
  );
};
const IconSave = ({ className, ...props }) => {
  return (
    createElement('svg', {
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      ...props
    },
      createElement('path', {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2',
        d: 'M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z'
      }),
      createElement('path', {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2',
        d: 'M13.67,22a1,1,0,0,1-.73-.32l-4.67-5a1,1,0,0,1,1.46-1.36l3.94,4.21,8.6-9.21a1,1,0,1,1,1.46,1.36l-9.33,10A1,1,0,0,1,13.67,22Z'
      })
    )
  );
};


export {
  IconUser,
  IconEtani,
  CloseTab,
  IconTrash,
  IconLike,
  IconDislike,
  IconExpand,
  IconMinimize,
  IconEdit,
  IconSave
}