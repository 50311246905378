import { CustomButton } from 'components/Button/CustomButton'
import React, { useState, useContext, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { Modal } from 'reactstrap'


const EnablePCE = ({ handleClosePCEModal, showPCEModal, type, handleEnablePCE, handleSavePCE, data, value, businessOnDW, ...props }) => {
  return (
    <Modal
      size="lg"
      className="modal-dialog-centered"
      isOpen={showPCEModal}
      toggle={handleClosePCEModal}
    >
      <div className="modal-header">
        <h6 className="mb-0 modal-title">
          {businessOnDW ?
            (type === 'pbi' && !data?.Details?.ShowPowerBIPage ||
              type === 'chat' && !data?.Details?.ShowChatPage ||
              type === 'eva' && !data?.Details?.ShowEvaPage ? 'Enable' : 'Disable')
            :
            (!businessOnDW && (type === 'chat' || type === 'eva') ?
              'Access to EVA Requires Transition to Data Warehouse'
              :
              (type === 'pbi' && !data?.Details?.ShowPowerBIPage ||
                type === 'chat' && !data?.Details?.ShowChatPage ||
                type === 'eva' && !data?.Details?.ShowEvaPage ? 'Enable' : 'Disable')
            )
          } {type?.toUpperCase()} Page
        </h6>
      </div>
      <div className="modal-body">
        {businessOnDW ? (
          <p style={{ textAlign: "left", margin: "0px", marginBottom: "20px", fontSize: "18px" }}>
            {`This will ${type === 'pbi' && !data?.Details?.ShowPowerBIPage ||
              type === 'chat' && !data?.Details?.ShowChatPage ||
              type === 'eva' && !data?.Details?.ShowEvaPage ? 'show' : 'hide'} ${type?.toUpperCase()} page to all users. Click confirm to proceed.`}
          </p>
        ) : (
          !businessOnDW && (type === 'chat' || type === 'eva') ? (
            <>
              <p className="text-left">
                Please note that EVA, our AI Data Assistant, is accessible only to users who have transitioned to our Azure SQL Data Warehouse. This move is crucial for enhanced performance, EVA integration, and improved Power BI Templates.
              </p>
              <p className="text-left">
                Ready to transition? When you're ready, just email <a href="mailto:support@etani.ai">support@etani.ai</a> to start the transition process.
              </p>
              <p className="text-left">
                Discover more about the transition in our Explainer Video – <a href="https://vimeo.com/874700012/c31847d11b?share=copy" target="_blank" rel="noreferrer">Transition Xero Blue to Data Warehouse</a>.
              </p>
            </>
          )
            :
            <p style={{ textAlign: "left", margin: "0px", marginBottom: "20px", fontSize: "18px" }}>
              {`This will ${type === 'pbi' && !data?.Details?.ShowPowerBIPage ||
                type === 'chat' && !data?.Details?.ShowChatPage ||
                type === 'eva' && !data?.Details?.ShowEvaPage ? 'show' : 'hide'} ${type?.toUpperCase()} page to all users. Click confirm to proceed.`}
            </p>
        )}
      </div>
      <div className="modal-footer">
        {
          businessOnDW ?
            <CustomButton
              type="button"
              onClick={() => handleSavePCE(type)}
              outlineStyle={false}
              title={"Confirm"}
            />
            : !businessOnDW && (type === 'chat' || type === 'eva') ? null :
              <CustomButton
                type="button"
                onClick={() => handleSavePCE(type)}
                outlineStyle={false}
                title={"Confirm"}
              />
        }
        <CustomButton
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => handleClosePCEModal()}
          outlineStyle={true}
          title="Close"
        />
      </div>
    </Modal>
  )
}

export default EnablePCE