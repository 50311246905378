import CustomSpinner from "components/Misc/CustomSpinner";
import AdminHistoryTable from "components/Table/AdminHistoryTable";
import ClientHistoryTable from "components/Table/ClientHistoryTable";
import IntegrationHistoryTable from "components/Table/IntegrationHistoryTable";
import UserHistoryTable from "components/Table/UserHistoryTable";
import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import MonthlyCostCard from "./MonthlyCostCard";
import CustomTooltip from "customComponents/customComponents";
import { MainContext } from "context/mainContext";
import { dataFilterNameOnly } from "utils/utilityFunctions";
import CurrentAndHistoricTabs from "components/SubscriptionList/CurrentAndHistoricTabs";

const CurrentSeactCountCard = ({ handleShowCurrentSeatCount, firmDetails, ...props }) => {
  const { contextClientLists, bgColor, textColor } = useContext(MainContext);
  const [detailsType, setDetailsType] = useState("Client")
  const [clientHistory, seClientHistory] = useState([])
  const [adminHistory, seAdminHistory] = useState([])
  const [userHistory, setUserHistory] = useState([])
  const [integrationHistory, seIntegrationHistory] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [integrationsLength, setIntegrationsLength] = useState(0)
  const [filteredBy, setFilteredBy] = useState('All')
  const [clientList, setClientList] = useState([])
  const [filteredClientList, setFilteredClientList] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [totaledSubs, setTotaledSubs] = useState({
    users: 0,
    integrations: 0
  })
  const [estimatedCost, setEstimatedCost] = useState({
    Client: { count: 0, cost: 0 },
    Admin: { count: 0, cost: 0 },
    MultiClientUsers: { count: 0, cost: 0 },
    EvaEnableUsers: { count: 0, cost: 0 },
    ViewOnlyUsers: { count: 0, cost: 0 },
    Integrations: { count: 0, cost: 0 },
    PowerBIFeatures: {
      isEnabled: false,
      cost: 0,
      PowerBIAccess: { count: 0, cost: 0 },
      PowerBIProAccount: { count: 0, cost: 0 }
    }
  })
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0)
  const [currentTab, setCurrentTab] = useState(true)
  const [tabKey, setTabKey] = useState('current');
  const [currentClients, setCurrentClients] = useState([])
  const [currentUsers, setCurrentUsers] = useState([])
  const [currentIntegrations, setCurrentIntegrations] = useState([])
  const [currentAdmins, setCurrentAdmins] = useState([])

  useEffect(async () => {
    // console.log(firmDetails)
    fetchInitialDatas()
    handleRefresh()
  }, [contextClientLists])

  const fetchInitialDatas = async (clienData) => {
    try {
      setIsLoading(true)
      let clientServices = 0
      if(firmDetails?.IntegrationsCount){
        clientServices = firmDetails?.IntegrationsCount
      } else if (firmDetails?.ClientService?.length > 0) {
        let integrations = firmDetails?.ClientService.filter(d => d.Service !== 3)
        clientServices = integrations.length
      }
      // console.log(clientServices)
      setIntegrationsLength(clientServices)

      // const getClientsHistory = await fetch(`/api/transactionHistory/getClientsHistory${clienData ? `?clientId=${clienData.id}` : ``}`)
      // const data = await getClientsHistory.json();
      // // console.log('CurrentSeactCountCard', data);
      // seClientHistory(data)
      handleGetClientHistory(clienData)
      setClientList(contextClientLists)
      setFilteredClientList(contextClientLists)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeDetailsType = (val) => {
    setDetailsType(val)
    if (val === "Client") handleGetClientHistory(selectedClient)
    if (val === "Admin") handleGetAdminsHistory()
    if (val === "User") handleGetUsersHistory(selectedClient)
    if (val === "Integration") handleGetIntegrationsHistory(selectedClient)
  }
  const handleGetClientHistory = async (clienData) => {
    try {
      setIsLoading(true)
      const getClientsHistory = await fetch(`/api/transactionHistory/getClientsHistory${clienData ? `?clientId=${clienData.id}` : ``}`)
      const data = await getClientsHistory.json();
      // console.log('CurrentSeactCountCard', data);
      seClientHistory(data)

      const getClientsCurrent = await fetch(`/api/transactionHistory/getClientsCurrent${clienData ? `?clientId=${clienData.id}` : ``}`)
      const dataCurrent = await getClientsCurrent.json();
      // console.log(dataCurrent)
      setCurrentClients(dataCurrent)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }
  const handleGetAdminsHistory = async () => {
    try {
      setIsLoading(true)
      const getAdminsHistory = await fetch("/api/transactionHistory/getAdminsHistory")
      const data = await getAdminsHistory.json();
      // console.log('CurrentSeactCountCard', data);
      seAdminHistory(data)
      const getAdminsCurrent = await fetch("/api/transactionHistory/getAdminsCurrent")
      const dataCurrent = await getAdminsCurrent.json();
      // console.log(dataCurrent)
      setCurrentAdmins(dataCurrent.users)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }
  const handleGetUsersHistory = async (clienData) => {
    try {
      setIsLoading(true)
      const getUsersHistory = await fetch(`/api/transactionHistory/getUsersHistory${clienData ? `?clientId=${clienData.id}` : ``}`)
      const data = await getUsersHistory.json();
      // console.log('CurrentSeactCountCard', data);
      setUserHistory(data)
      const getUsersCurrent = await fetch(`/api/transactionHistory/getUsersCurrent${clienData ? `?clientId=${clienData.id}` : ``}`)
      const dataCurrent = await getUsersCurrent.json();
      // console.log(dataCurrent)
      setCurrentUsers(dataCurrent)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }
  const handleGetIntegrationsHistory = async (clienData) => {
    try {
      setIsLoading(true)
      const getIntegrationsHistory = await fetch(`/api/transactionHistory/getIntegrationsHistory${clienData ? `?clientId=${clienData.id}` : ``}`)
      const data = await getIntegrationsHistory.json();
      let integrationHistories = data.integrations.filter(d => d.Details.Integration.Service.Name !== 'Power BI')
      // console.log('CurrentSeactCountCard', integrationHistories);
      seIntegrationHistory(integrationHistories)
      const getIntegrationsCurrent = await fetch(`/api/transactionHistory/getIntegrationsCurrent${clienData ? `?clientId=${clienData.id}` : ``}`)
      const dataCurrent = await getIntegrationsCurrent.json();
      // console.log(dataCurrent)
      setCurrentIntegrations(dataCurrent)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  const handleFilterBy = (value) => {
    if (value === "All") {
      setFilteredBy("All")
      setSelectedClient(null)
      handleRefresh()
      handleGetIntegrationsHistory()
      handleGetUsersHistory()
      handleGetClientHistory()
    } else {
      setFilteredBy(value.Name)
      setSelectedClient(value)
      fetchTotalPerClient(value)
      handleRefresh(value)
      handleGetIntegrationsHistory(value)
      handleGetUsersHistory(value)
      handleGetClientHistory(value)
    }
  }

  const handleSearchClient = (e) => {
    let filterString = e.target.value
    setFilteredClientList(dataFilterNameOnly(filterString, clientList))
  }

  const fetchTotalPerClient = async (data) => {
    try {
      const getTotalPerClient = await fetch(`/api/transactionHistory/getTotalPerClient/${data.id}`)
      const resultTotalPerClient = await getTotalPerClient.json()
      // console.log(resultTotalPerClient)
      setTotaledSubs(resultTotalPerClient.totals)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMonthlyCost = async (clienData) => {
    try {
      const getClientsHistory = await fetch(`/api/transactionHistory/getEstimatedMonthlyCost${clienData ? `?clientId=${clienData.id}` : ``}`)
      const data = await getClientsHistory.json();
      // console.log('CurrentSeactCountCard', data);
      setEstimatedCost(data.emcData)
      getTotalEstimatedCosts(data.emcData)
    } catch (error) {
      console.log(error);
    }
  }
  const getTotalEstimatedCosts = (data) => {
    let totalCost = 0;

    function addCost(obj) {
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          if ('cost' in obj[key] && typeof obj[key].cost === 'number') {
            totalCost += obj[key].cost;
          }
          // Recursively add costs for nested objects
          addCost(obj[key]);
        }
      }
    }

    addCost(data);
    setTotalEstimatedCost(totalCost)
  }

  const handleRefresh = (clienData) => {
    const detaultDate = {
      Client: { count: 0, cost: 0 },
      Admin: { count: 0, cost: 0 },
      EvaEnableUsers: { count: 0, cost: 0 },
      ViewOnlyUsers: { count: 0, cost: 0 },
      Integrations: { count: 0, cost: 0 },
      PowerBIFeatures: {
        isEnabled: false,
        cost: 0,
        PowerBIAccess: { count: 0, cost: 0 },
        PowerBIProAccount: { count: 0, cost: 0 }
      }
    }
    setEstimatedCost(detaultDate)
    getTotalEstimatedCosts(detaultDate)
    fetchMonthlyCost(clienData)
  }

  const handleTabSelect = (value) => {
    setTabKey(value)
  }

  return (
    <>
      <Row>
        <Col>
          <Card className="card-pricing border-0 mt-3 p-2">
            <CardHeader className="h3 rounded-top font-weight-700">
              <Row>
                <Col>
                  Subscription Details
                </Col>
                <Col className="col-auto">
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-4 p-sm-3">
              <Row>
                <Col lg="4" sm="6" xs="12">
                  <MonthlyCostCard
                    totalClient={firmDetails.Client.length}
                    totalAdmin={firmDetails.Accountant.length}
                    pbiEnabled={firmDetails.Details.PbiEnabled}
                    fetchMonthlyCost={fetchMonthlyCost}
                    handleRefresh={handleRefresh}
                    estimatedCost={estimatedCost}
                    totalEstimatedCost={totalEstimatedCost}
                    selectedClient={selectedClient}
                    hasGST={firmDetails?.CountryCode === 'au' ? true : false}
                  />
                </Col>
                <Col lg="8" sm="6" xs="12">
                  <Row className="mb-3">
                    <Col lg={{ size: "6", offset: "6" }} sm={{ size: "6", offset: "6" }}>
                      <Row>
                        <Col>
                          <div className="float-right">
                            <UncontrolledButtonDropdown>
                              <DropdownToggle
                                caret
                                size='sm'
                                style={{
                                  backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`,
                                  paddingTop: '6px', paddingBottom: '6px'
                                }}
                              >
                                Filtered by: {filteredBy} {`${filteredBy === "All" ? "Clients" : "Client"}`}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>
                                  <Input
                                    placeholder="Client Name"
                                    type="text"
                                    bsSize="sm"
                                    onChange={handleSearchClient}
                                  />
                                </DropdownItem>
                                <DropdownItem onClick={() => handleFilterBy('All')}>All</DropdownItem>
                                {
                                  filteredClientList && filteredClientList.length >= 1 ?
                                    filteredClientList.map((cl, i) => (
                                      <DropdownItem key={i} onClick={() => handleFilterBy(cl)}>{cl.Name}</DropdownItem>
                                    ))
                                    : null
                                }
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Total Clients
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {filteredBy === "All" ? firmDetails.Client.length : 1}
                              </span>
                            </Col>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-briefcase-24" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className="text-nowrap seat-count-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleChangeDetailsType("Client") }}
                            >
                              See details
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Total Admins
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {selectedClient ? 0 : firmDetails.Accountant.length}
                              </span>
                            </Col>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                                <i className="ni ni-circle-08" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className="text-nowrap seat-count-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleChangeDetailsType("Admin") }}
                            >
                              See details
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Total Users
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {filteredBy === "All" ? `${ estimatedCost && estimatedCost.MultiClientUsers?.count && estimatedCost.EvaEnableUsers?.count && estimatedCost.ViewOnlyUsers?.count  ? estimatedCost?.MultiClientUsers?.count + estimatedCost?.EvaEnableUsers?.count + estimatedCost?.ViewOnlyUsers?.count : 0 }` : totaledSubs.users}
                              </span>
                            </Col>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i className="ni ni-single-02" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className="text-nowrap seat-count-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleChangeDetailsType("User") }}
                            >
                              See details
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col>
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Total Integrations
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {filteredBy === "All" ? integrationsLength : totaledSubs.integrations}
                              </span>
                            </Col>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="fas fa-cogs" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className="text-nowrap seat-count-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleChangeDetailsType("Integration") }}
                            >
                              See details
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">{detailsType}s</h3>
                        </div>
                      </Row>
                    </CardHeader>
                    {
                      isLoading ? <CustomSpinner />
                        :
                        detailsType === "Client" ?
                          <CurrentAndHistoricTabs tabKey={tabKey} handleTabSelect={handleTabSelect}>
                            {
                              tabKey === "current" ?
                                <ClientHistoryTable
                                  detailsType={detailsType}
                                  data={currentClients}
                                  isCurrent={tabKey === "current" ? true : false}
                                  estimatedCost={estimatedCost}
                                />
                                :
                                <ClientHistoryTable
                                  detailsType={detailsType}
                                  data={clientHistory.clients}
                                  isCurrent={tabKey === "current" ? true : false}
                                  estimatedCost={estimatedCost}
                                />
                            }
                          </CurrentAndHistoricTabs>
                          :
                          detailsType === "Admin" ?
                            <CurrentAndHistoricTabs tabKey={tabKey} handleTabSelect={handleTabSelect}>
                              {
                                tabKey === "current" ?
                                  <AdminHistoryTable
                                    detailsType={detailsType}
                                    data={currentAdmins}
                                    isCurrent={tabKey === "current" ? true : false}
                                  />
                                  :
                                  <AdminHistoryTable
                                    detailsType={detailsType}
                                    data={adminHistory.admins}
                                    isCurrent={tabKey === "current" ? true : false}
                                  />
                              }
                            </CurrentAndHistoricTabs>
                            :
                            detailsType === "User" ?
                              <CurrentAndHistoricTabs tabKey={tabKey} handleTabSelect={handleTabSelect}>
                                {
                                  tabKey === "current" ?
                                    <UserHistoryTable
                                      detailsType={detailsType}
                                      data={currentUsers}
                                      isCurrent={tabKey === "current" ? true : false}
                                    />
                                    :
                                    <UserHistoryTable
                                      detailsType={detailsType}
                                      data={userHistory.users}
                                      isCurrent={tabKey === "current" ? true : false}
                                    />
                                }
                              </CurrentAndHistoricTabs>
                              :
                              <CurrentAndHistoricTabs tabKey={tabKey} handleTabSelect={handleTabSelect}>
                                {
                                  tabKey === "current" ?
                                    <IntegrationHistoryTable
                                      detailsType={detailsType}
                                      data={currentIntegrations}
                                      isCurrent={tabKey === "current" ? true : false}
                                    />
                                    :
                                    <IntegrationHistoryTable
                                      detailsType={detailsType}
                                      data={integrationHistory}
                                      isCurrent={tabKey === "current" ? true : false}
                                    />
                                }
                              </CurrentAndHistoricTabs>
                    }
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <Button
                    // color="primary"
                    className="btn-link"
                    // outline
                    onClick={() => props.handleUpgradePlan(props.record, "cancelSubs")}
                    style={{
                      backgroundColor: `${"#fff"}`,
                      color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                      borderColor: `${"#fff"}`,
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default CurrentSeactCountCard;