import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Collapse,
  ListGroupItem,
} from "reactstrap";
const CustomPricingDetailsCollapse = ({ data, ...props }) => {
  const [toggleState, setToggleState] = useState({ collapse: false });
  const toggle = () => {
    setToggleState({ collapse: !toggleState.collapse });
  };
  // console.log(data)
  return (
    <>
      <div onClick={() => toggle()} style={{ cursor: 'pointer' }}>
        <Row
          className=" pb-2 "
          style={{ opacity: 1 }}
        >
          {/* <Col xl="1" sm="1" xs="1" className="text-left" style={{ padding: `${props.hideCheck ? '23px 0' : null}` }}>
            {
              props.hideCheck ?
                null
                :
                <i className="fas fa-check-circle mr-3 text-sm text-success" />
            }
          </Col> */}
          <Col xl="12" sm="12" xs="12" className="d-flex flex-column text-left">
            <p className="text-sm text-left" style={{ fontWeight: 'bold' }}>
              <i className="fas fa-check-circle mr-3 text-sm text-success" /> {data.header} <span className="btn-inner--icon pr-1"><i
                className="ni ni-bold-down float-right"
                style={{
                  transform: toggleState.collapse ? "rotate(-180deg)" : "",
                  transition: ".3s ease-in-out",
                }}
              /></span>
            </p>
            {/* <h3 className="h5">
            </h3> */}
          </Col>
        </Row>
      </div>
      <Collapse
        // toggler={`#toggler${i}`}
        // defaultOpen={i === 0 ? true : false}
        isOpen={toggleState.collapse}
      >
        <Card className=" card-body">
          <span className="text-sm">{data.details}</span>
          {
            data.list ?
              <ul>
                {
                  data.list.map((d, i) => (
                    <li className="text-sm" key={i}><strong>{d.listHeader}</strong> {d.listDetails}</li>
                  ))
                }
              </ul>
              : null
          }
        </Card>
      </Collapse>
    </>
  )
}
export default CustomPricingDetailsCollapse;