import React, { useCallback, useContext, useEffect, useState } from "react";
import DashboardTabs from "./DashboardTabs";
import PersonalDashboardList from "./PersonalDashboardList";
import PanelCard from "components/Cards/PanelCard";
import CustomDashboardPanelCard from "components/Cards/CustomDashboardPanelCard";
import CustomDashboardDetails from "./CustomDashboardDetails";
import MainDashboard from "views/pages/mainDashboard/MainDashboard";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import ReportView from "./ReportView";
import { MainContext } from "context/mainContext";

const TabbedView = ({ ...props }) => {
    const [showReport, setShowReport] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const [dashboardList, DashboardList] = useState(null)

    useEffect(() => {
        const combinedArray = props.personalDashboardList.map((item) => {
            item.isBI = false;
            return item;
        }).concat(props.personalDashboardBIList.map((item) => {
            item.isBI = true;
            return item;
        }));
        
        combinedArray.sort((a, b) => {
            const dateA = a.createdAt ? new Date(a.createdAt) : null;
            const dateB = b.createdAt ? new Date(b.createdAt) : null;
            if (!dateA && dateB) return 1;
            if (dateA && !dateB) return -1;
            return dateB - dateA;
        });

        DashboardList(combinedArray)

        console.log(combinedArray)
    },[props.personalDashboardList, props.personalDashboardBIList])

    useCallback(() => {

    })

    const handleViewFavorite = async (data) => {
        console.log(data)
        setSelectedData(data)
        setShowReport(!showReport)
    }

    return (
        <>
            <DashboardTabs tabbedKey={props.tabbedKey} handleTabSelect={props.handleTabSelect}>
                
                {props.tabbedKey === "personal" &&
                    <>
                    <Row>
                    {dashboardList && dashboardList.length > 0 ?
                        <PersonalDashboardList
                            loading={props.loading}
                            personalDashboardList={dashboardList}
                            handleActiveDasboard={props.handleActiveDasboard}
                            showDeleteConfirmation={props.showDeleteConfirmation}
                            handleSelectDashboard={props.handleSelectDashboard}
                        />
                        :
                        <><p>No Personal Dashboard found.</p></>}
                  
                    {/* <BIReports 
                        personalDashboardBIList={props.personalDashboardBIList}
                        /> */}
                    </Row>
                    </>
                }
                {props.tabbedKey === "group" &&
                    <><p>Group Dashboard List</p></>
                }
            </DashboardTabs>
            
            {props.tabbedKey === "group" && props.dashboardItems && props.dashboardItems.length === 0 &&
                <PanelCard headerName="Group Dashboard">
                </PanelCard>
            }

            {props.showReport && props.selectedReportData && props.tabbedKey === "personal" && 
                    <ReportView 
                        data={props.selectedReportData} />
            } 
            
            {props.selectedClientID && props.selectedDashboardName && props.dashboardItems && props.dashboardItems.length > 0 && props.tabbedKey === "personal" &&
                <CustomDashboardPanelCard headerName={props.selectedDashboardName}>
                    <div className="dashboardItemContainer" key="1">
                        {props.dashboardItems.map((dashboard, index) => (
                            <CustomDashboardDetails
                                detail={dashboard}
                                headerName={props.selectedDashboardName}
                                index={index}
                                onHandleDeleteDashboardItem={props.onHandleDeleteDashboardItem}
                                onHandleSaveName={props.onHandleSaveName}
                            />
                        ))}
                    </div>
                    
                </CustomDashboardPanelCard>
            }
        </>
    );
}
export default TabbedView
