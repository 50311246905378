// import { useCallback, useState } from "react";
// import { useSocketEvent } from "./SocketContext";

// export const useSocketListener = (dependencies) => {
//   const {
//     getReadAnnouncent,
//     getAllAnnouncements,    
//   } = dependencies

//   useSocketEvent("getReadAnnouncent", useCallback((message) => {
//     const { setReadAnnouncements } = getReadAnnouncent;
//     setReadAnnouncements(message.record);
//   },[ getReadAnnouncent ]));

//   // Get all announcements and filter
//   useSocketEvent("getAllAnnouncements", useCallback((message) => {
//     const {
//       setUnReadAnnouncements,
//       setFilteredAnnouncements, 
//       userDetails,
//       readAnnouncements,
//       firmDetails
//     } = getAllAnnouncements;

//     const SEND_MODES = {
//       NEW_BUSINESS: 'newFirms',
//       NEW_CLIENTS: 'newClients', 
//       NEW_ADVISERS: 'newAccountants',
//       NEW_CLIENT_USERS: 'newClientUsers',
//       SELECTED_ADVISERS: 'specificAccountants',
//       SELECTED_CLIENTS: 'specificClients',
//     }

//     const clientIds = firmDetails?.Client?.map(client => client.id);

//     const generalAnnouncements = message.record.filter(announcement => 
//       (announcement.target_audience?.users?.all &&
//       announcement.target_audience?.business?.all &&
//       announcement.target_audience?.advisors?.all &&
//       announcement.target_audience?.clients?.all) &&
//       announcement.status ==='PUBLISHED'
//     );
    
//     let businessdAnnouncements = [];
//     let newBusinessdAnnouncements = [];
//     let newAdvisorAnnouncements = [];
//     let advisorAnnouncements = [];
//     let clientUserAnnouncements = [];
//     let allAdviserAnnouncements = [];
//     let allClientUserAnnouncements = [];
//     let selectedUserAnnouncements = [];
//     let selectClientAnnouncements = [];
//     let newClientUserAnnouncements = [];
//     let unreadAnnouncements = [];
//     let mergedAnnouncements = [
//       ...generalAnnouncements,
//     ];

//     const isAdvisorUser = (userAccess) => [1,2,3,4].includes(userAccess);

//     const isClientUser = (userAccess) => [5,6].includes(userAccess);


//     if (userDetails) {
//       const { AccountingFirm, UserAccess, id } = userDetails.User;

//       // Filter Announcements by Accounting Firm
//       businessdAnnouncements = message.record.filter(announcement => 
//         announcement.target_audience?.business?.specific_ids.includes(AccountingFirm) &&
//         announcement.status ==='PUBLISHED'
//       );

//       // Filter Announcement with target Business and is flag as NewBusiness
//       newBusinessdAnnouncements = message.record.filter(announcement => 
//         announcement.send_mode === SEND_MODES.NEW_BUSINESS &&
//         !announcement.target_audience?.business?.all &&
//         announcement.target_audience?.business?.new_business &&
//         announcement.target_audience?.business?.specific_ids.includes(AccountingFirm) &&
//         announcement.status ==='PUBLISHED'
//       );

//       // Filter all Adviser only announcements
//       allAdviserAnnouncements = message.record.filter(announcement =>
//         announcement.target_audience?.advisors?.all &&
//         !announcement.target_audience?.users?.all &&
//         isAdvisorUser(UserAccess) &&
//         announcement.status ==='PUBLISHED'        
//       );

//       // Filter Announcements by Selected Advisor Users
//       advisorAnnouncements = message.record.filter(announcement =>
//         !announcement.target_audience?.advisors?.all &&
//         announcement.send_mode === SEND_MODES.SELECTED_ADVISERS &&        
//         announcement.target_audience?.advisors?.specific_ids.includes(id) &&
//         isAdvisorUser(UserAccess) &&
//         announcement.status ==='PUBLISHED'
//       );
      
//       // Filter Announcments by New Advisor Users
//       newAdvisorAnnouncements = message.record.filter(announcement =>
//         announcement.send_mode === SEND_MODES.NEW_ADVISERS &&
//         !announcement.target_audience?.advisors?.all &&
//         announcement.target_audience?.advisors?.new_advisers &&
//         announcement.target_audience?.advisors?.specific_ids.includes(id) &&
//         isAdvisorUser(UserAccess) &&
//         announcement.status ==='PUBLISHED'        
//       );


//       // Filter All Client User Announcements
//       allClientUserAnnouncements = message.record.filter(announcement =>
//         announcement.target_audience?.clients?.all &&
//         !announcement.target_audience?.users?.all &&
//         isClientUser(UserAccess) &&
//         announcement.status ==='PUBLISHED'
//       );      

//       // Filter Announcements by Selected Client
//       selectClientAnnouncements = message.record.filter(announcement =>
//         !announcement.target_audience?.clients?.all &&
//         announcement.send_mode === SEND_MODES.SELECTED_CLIENTS &&
//         announcement.target_audience?.clients?.specific_ids.some(client => clientIds?.includes(client)) && 
//         announcement.status ==='PUBLISHED'
//       );

//       newClientUserAnnouncements = message.record.filter(announcement =>
//         announcement.send_mode === SEND_MODES.NEW_CLIENT_USERS &&
//         !announcement.target_audience?.clients?.all &&
//         announcement.target_audience?.clients?.new_client_users &&
//         announcement.target_audience?.clients?.specific_ids.includes(id) &&
//         isClientUser(UserAccess) &&
//         announcement.status ==='PUBLISHED'
//       );
    
//       // Filter Announcements by Selected Users
//       selectedUserAnnouncements = message.record.filter(announcement =>
//         announcement.target_audience?.users?.specific_ids.includes(id) && 
//         announcement.status ==='PUBLISHED'
//       );

//       // All Announcements
//       mergedAnnouncements = [
//         ...mergedAnnouncements,
//         ...businessdAnnouncements,
//         ...allAdviserAnnouncements,
//         ...allClientUserAnnouncements, 
//         ...advisorAnnouncements,
//         ...clientUserAnnouncements,
//         ...selectedUserAnnouncements,
//         ...newBusinessdAnnouncements,
//         ...selectClientAnnouncements,
//         ...newAdvisorAnnouncements,
//         ...newClientUserAnnouncements
//       ];

//       // Set Unread Announcements
//       unreadAnnouncements = mergedAnnouncements.map(announcement => {
//           const isRead = readAnnouncements.some(readAnnouncement =>
//               readAnnouncement.announcement_id.toString() === announcement._id.toString() &&
//               readAnnouncement.user_id.toString() === id.toString()
//           );

//           return {
//               ...announcement,
//               isRead: isRead
//           };
//       }).filter(announcement => !announcement.isRead);

//       // Add isRead to Announcements
//       mergedAnnouncements = mergedAnnouncements.map(announcement => {
//           const isRead = readAnnouncements.some(readAnnouncement =>
//               readAnnouncement.announcement_id.toString() === announcement._id.toString() &&
//               readAnnouncement.user_id.toString() === id.toString()
//           );

//           return {
//               ...announcement,
//               isRead: isRead
//           };
//       });

//       // Sort by Unread first
//       // mergedAnnouncements.sort((a,b) => a.isRead - b.isRead)
//     }

//     // Remove duplicates after merged
//     const uniqueAnnouncements = new Set();
//     mergedAnnouncements = mergedAnnouncements.filter(announcement => {
//       if (uniqueAnnouncements.has(announcement._id)) {
//         return false;
//       } else {
//         uniqueAnnouncements.add(announcement._id);
//         return true;
//       }
//     });
//     const uniqueUnReadAnnouncements = new Set();
//     unreadAnnouncements = unreadAnnouncements.filter(announcement => {
//       if (uniqueUnReadAnnouncements.has(announcement._id)) {
//         return false;
//       } else {
//         uniqueUnReadAnnouncements.add(announcement._id);
//         return true;
//       }
//     });

//     setUnReadAnnouncements(unreadAnnouncements);

//     setFilteredAnnouncements(mergedAnnouncements);


//     // Sort by UpdatedAt
//     mergedAnnouncements.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

//     unreadAnnouncements.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
//   }, [ getAllAnnouncements ]));


// };

