import React, { useContext, useEffect, useState } from 'react';
import { MainContext } from 'context/mainContext';

const DashboardTabs = ({
  tabKey,
  editMode,
  selectedTemplateName,
  showEVA,
  showChat,
  toggleShowDashboardOption,
  handleTabSelect,
  toggleTemplateModal,
  handleShowEVA,
  handleShowChat,
  toggleEditMode,
  children,
  stillLoading
}) => {
  const { bgColor, textColor } = useContext(MainContext);
  
  const tabList = [
    // { id: "dashboard-tabs-tab-personal", name: "personal", display: "Personal Dashboard" },
    // { id: "dashboard-tabs-tab-group", name: "group", display: "Group Dashboard" }
  ];

  const renderTab = (item) => (
    <a
      key={item.id}
      id={item.id}
      href="#"
      role="tab"
      style={
        tabKey === item.name
          ? { backgroundColor: bgColor, borderColor: bgColor, color: textColor }
          : { color: bgColor, backgroundColor: textColor, borderColor: textColor }
      }
      className={`nav-item nav-link ${tabKey === item.name ? "active" : ""}`}
      onClick={() => handleTabSelect(item.name)}
    >
      {item.display}
    </a>
  );

  const renderLeftNav = () => (
    <div className="leftNav">
      {tabKey === "personal" && !editMode && (
        <>
          <a
            id={4}
            href="#"
            role="tab"
            style={{ color: bgColor, backgroundColor: textColor, borderColor: textColor, border: '1px solid #333 !important' }}
            className="nav-item nav-link templateName"
            onClick={toggleShowDashboardOption}
          >
            {selectedTemplateName || "Select Dashboard"}
            <i className="dropdown-toggle nav-link" style={{ color: bgColor, fontSize: 17, width: 20, border: 'none!important' }}></i>
          </a>
          {/* <a
            id={3}
            href="#"
            role="tab"
            style={{ color: bgColor, backgroundColor: textColor, fontWeight: 600, border: '1px solid #333 !important' }}
            className="nav-item nav-link"
            onClick={toggleTemplateModal}
          >
            Create Dashboard Templates
          </a> */}
        </>
      )}

      {tabList.map(renderTab)}
    </div>
  );

  const renderRightNav = () => (
    <div className="rightNav">

      {/* {!editMode && ( */}
        <>
          <a
            id={5}
            href="#"
            role="tab"
            style={showEVA || showChat
              ? { color: '#fff', backgroundColor: "#ff6262", border: 0, fontWeight: 600 }
              : { color: bgColor, backgroundColor: textColor, border: 0, fontWeight: 600 }}
            className="nav-item nav-link"
            onClick={handleShowEVA}
          >
            {(showEVA || showChat) && <i className="fas fa-times fa-md" style={{ marginRight: 10 }}></i>}
            {!showEVA ? "Show" : "Hide"} EVA / Chat
          </a>
          {showEVA && (
            <a
              id={6}
              href="#"
              role="tab"
              style={{ color: bgColor, backgroundColor: textColor, fontWeight: 600, borderLeft: '1px solid #bbb', borderRight: '1px solid #bbb', borderRight: '1px solid #bbb' }}
              className="nav-item nav-link"
              onClick={handleShowChat}
            >
              {showChat ? "Show EVA" : "Show Group Chat"}
            </a>
          )}
        </>
      {/* )} */}
      {/* {!showEVA && ( */}
        <a
          id={2}
          href="#"
          role="tab"
          style={editMode
            ? { color: '#fff', backgroundColor: "#ff6262", border: 0, fontWeight: 600 }
            : { color: bgColor, backgroundColor: textColor, border: 0, fontWeight: 600 }}
          className="nav-item nav-link"
          onClick={toggleEditMode}
        >
          {editMode ? (
            <>
              <i className="fas fa-times fa-md" style={{ marginRight: 10 }}></i> Close Edit Mode
            </>
          ) : (
            <>
              <i className="fas fa-pen fa-md" style={{ marginRight: 10 }}></i> Edit Dashboard
            </>
          )}
        </a>
      {/* )} */}
    </div>
  );

  const renderGroupRightNav = () => (
    <div className="rightNav">
        {!editMode && (
            <>
                <a
                    id={5}
                    href="#"
                    role="tab"
                    style={showEVA || showChat
                    ? { color: '#fff', backgroundColor: "#ff6262", border: 0, fontWeight: 600 }
                    : { color: bgColor, backgroundColor: textColor, border: 0, fontWeight: 600 }}
                    className="nav-item nav-link"
                    onClick={handleShowEVA}
                >
                    {(showEVA || showChat) && <i className="fas fa-times fa-md" style={{ marginRight: 10 }}></i>}
                    {!showEVA ? "Show" : "Hide"} EVA/Chat
                </a>
                {showEVA && (
                    <a
                    id={6}
                    href="#"
                    role="tab"
                    style={{ color: bgColor, backgroundColor: textColor, fontWeight: 600, borderLeft: '1px solid #bbb', borderRight: '1px solid #bbb' }}
                    className="nav-item nav-link"
                    onClick={handleShowChat}
                    >
                    {showChat ? "Show EVA" : "Show Group Chat"}
                    </a>
                )}
            </>
        )}
        {!showEVA && (
            <a
            id={2}
            href="#"
            role="tab"
            style={editMode
                ? { color: '#fff', backgroundColor: "#ff6262", border: 0, fontWeight: 600 }
                : { color: bgColor, backgroundColor: textColor, border: 0, fontWeight: 600 }}
            className="nav-item nav-link"
            onClick={toggleEditMode}
            >
            {editMode ? (
                <>
                <i className="fas fa-times fa-md" style={{ marginRight: 10 }}></i> Close Edit Mode
                </>
            ) : (
                <>
                <i className="fas fa-pen fa-md" style={{ marginRight: 10 }}></i> Edit
                </>
            )}
            </a>
        )}
    </div>
  );

  return (
    <>
      <div className="nav nav-tabs" role="tablist" style={{ border: 'unset' }}>
        {renderLeftNav()}
        {tabKey === "personal" && !stillLoading &&  renderRightNav()} 
        {tabKey === "group" && renderGroupRightNav()}
      </div>
      <div className="tabContainer" style={{ borderTop: `3px solid ${bgColor}` }}>
        {children}
      </div>
    </>
  );
};

export default DashboardTabs;
