import { IconEtani } from 'eva-component/ui/icon'
import React, { useEffect } from 'react'

function EVAStreamLoading({ streamMessage }) {
    useEffect(() => {
        // console.log(streamMessage)
    }, [streamMessage]);

    const loadingSteps = [
        { message: 'Analysing the question and deciding the formula to extract data', minLength: 0 , className: 'item-1'},
        { message: 'Data received. Verifying the data and checking accuracy', minLength: 1, className: 'item-2' },
        { message: 'Sharing data with EVA for insights', minLength: 2, className: 'item-3' },
        { message: 'Creating visual from the data and final preparations', minLength: 3, className: 'item-4' },
    ];

    return (
        <div className='EVAResponseContainer'>
          <div className='EVAAvatar' style={{ padding: 0 }}>
            <IconEtani />
          </div>
          {/* <div className='EVAMessage'>
              {loadingSteps.map((step, index) => (
                <div className={`EVAStreamLoading ${streamMessage.length >= step.minLength ? 'active' : ''}`}>
                    <div
                        className={`loading-spinner-container ${step.className}`}
                        key={index}
                        style={{ justifyContent: 'left', marginBottom: 5 }}
                    >
                        <div className="loading-spinner" style={{ width: 20, height: 20, marginRight: 10 }}></div>
                        {step.message}
                    </div>
                </div>
              ))}
            </div> */}

            <div className='EVAMessage'>
                <div className='EVAStreamLoading'>
                    {loadingSteps.map((step, index) => (
                        <div className={`loading-spinner-container  ${streamMessage.length >= step.minLength ? step.className + '-active' : step.className}`} key={index} style={{ justifyContent: 'left', marginBottom: 5 }}>
                            {streamMessage.length > step.minLength ?
                                <i className="fas fa-check-circle text-success" style={{ fontSize: 20, marginRight: 10 }} />
                            :
                                step.minLength === 3 ? 
                                <i className="fas fa-check-circle text-success" style={{ fontSize: 20, marginRight: 10 }} /> :
                                <div className="loading-spinner" style={{ width: 20, height: 20, marginRight: 10 }}></div>
                            }
                            {step.message}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        
      );
}

export default EVAStreamLoading;
