import { IconTrash } from 'chat-components/ui/icon';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';

const GroupDashboards = ({showDashboardList, groupList, handleActiveGroupDashboard, handleSelectGroup, groupDashboardList, handleIsActiveGroupDashboard, handleSelectGroupDashboard}) => {
    const { bgColor, textColor } = useContext(MainContext);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState('');
    const handleSelectChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedGroupId = e.target.value;
        const selectedGroup = groupList && groupList.find(group => group._id === selectedGroupId);
    
        handleSelectGroup(selectedGroup, selectedIndex)
        setSelectedGroup(e.target.value);
    };
    const handleSelectDashboard = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedGroupId = e.target.value;
        const selectedGroup = groupDashboardList.find(group => group._id === selectedGroupId);
    
        handleSelectGroupDashboard(selectedGroup, selectedIndex)
        setSelectedDashboard(e.target.value);
    };
    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    useEffect(() => {
        console.log(showDashboardList)
    },[showDashboardList])
    return (
        <>
            <Col xs="12" sm="6" lg="3">
                <>
                    <label className='selectionLable' htmlFor="groupSelect" >Select Group:</label>
                </>
                <FormGroup>
                    <Input type="select" id="groupSelect" value={selectedGroup} onChange={handleSelectChange}>
                            <option key="All" value="All">All</option>
                        {groupList && groupList.map((group) => (
                            <option key={group._id} value={group._id}>{capitalize(group.GroupName)}</option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
            {!showDashboardList &&
                <Col xs="12" sm="6" lg="3">
                    <>
                        <label className='selectionLable' htmlFor="groupSelect" >Select Dashboard:</label>
                    </>
                    <FormGroup>
                        <Input type="select" id="groupSelect" value={selectedDashboard} onChange={handleSelectDashboard}>
                            <option key="All" value="All">Select Dashboard</option>
                            {groupDashboardList && groupDashboardList.map((data) => (
                                <option key={data._id} value={data._id}>
                                    {capitalize(data.name)}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
            }
        </>
    )
}
export default GroupDashboards