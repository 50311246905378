import { MainContext } from "context/mainContext";
import React, { useContext, useState } from "react";
import { Col, Card, CardBody, Modal } from "reactstrap";
import avatar from "../../../../assets/img/clients/blank-avatar.png";

const List = ({ i, data, ...props }) => {
  const [openDetails, setOpenDetails] = useState(false);
  // eslint-disable-next-line
  const { bgColor, textColor } = useContext(MainContext);

  const onOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <Col
      sm="12"
      lg="4"
      xl="3"
      md="6"
      key={i}
      style={{ paddingRight: 8, paddingLeft: 8 }}
    >
      <Card className="mt-2 zoom">
        <CardBody className="p-2">
          {props.showTrafficLight(data.MeetingTheTeam, i)}

          <div onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img-center img-fluid shadow shadow-lg--hover"
              src={data.User.Avatar || avatar}
              style={{
                width: "70px",
                height: "70px",
                objectFit: "cover",
                borderRadius: "0.475rem",
              }}
            />
          </div>
          <div className="py-2 text-center">
            <h5
              className="h3 py-3 title border-bottom"
              style={{ height: "100px" }}
            >
              <span className="d-block mb-1">
                {data.User.FirstName + " " + data.User.LastName}
              </span>
              <small className="h4 font-weight-light text-muted">
                {data.Name +
                  " / " +
                  props.displaySubTitle(data.User.UserAccess)}
              </small>
            </h5>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="text-center ">
                <i className="fa fa-home   my-2 pull-left mr-2" />
                <span style={{ fontSize: "14px" }}>{data.User.Email}</span>
              </div>
              <div className="text-center ">
                <i className="fa fa-phone  my-2  pull-left mr-2" />
                <span style={{ fontSize: "14px" }}>
                  {data.User.Phone ? data.User.Phone : "000-000-0000"}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "grid",
                height: "150px",
                alignItems: "center",
                textOverflow: "hidden",
              }}
              // className=''
            >
              {data.Description ? (
                <>
                  <p
                    className="h5 font-weight-400 text-muted"
                    style={{ fontSize: "13px", position: "relative" }}
                  >
                    {data.Description.slice(0, 150)}
                    {data.Description.length > 50 ? (
                      <span
                        onClick={() => onOpenDetails()}
                        style={{ cursor: "pointer", fontSize: ".7rem" }}
                        className="text-muted mt--3 ml-2 text-underline font-weight-500"
                      >
                        ...read more
                      </span>
                    ) : null}
                  </p>
                </>
              ) : (
                "No dexcription available"
              )}
            </div>
            <div className="mt-3">
              {props.showStatus(data.AccountantID, data.MeetingTheTeam)}
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={openDetails}
        key={i}
        toggle={() => onOpenDetails()}
      >
        <i
          onClick={() => onOpenDetails()}
          className="fas fa-times fa-sm"
          style={{ position: "absolute", top: 20, right: 20 }}
        />
        <img
          alt={data.User.FirstName}
          className="rounded img-center mt-5 img-fluid shadow shadow-lg--hover"
          src={data.User.Avatar || avatar}
          style={{
            width: "140px",
            height: "140px",
            objectFit: "cover",
          }}
        />
        <h5 className="h3 py-3 title text-center border-bottom">
          <span className="d-block mb-1">
            {data.User.FirstName + " " + data.User.LastName}
          </span>
          <small className="h4 font-weight-light text-muted">
            {data.Name + "/" + props.displaySubTitle(data.User.UserAccess)}
          </small>
        </h5>
        <p className="text-justify p-3 my-3 mx-auto">{data.Description}</p>
        {/* <div className="modal-footer">
          <Button
            style={{
              backgroundColor: `${bgColor === '#fff' ? '#5e72e4' : bgColor}`,
              color: `${textColor === '#000' ? '#fff' : textColor}`,
              borderColor: `${bgColor === '#fff' ? '#5e72e4' : bgColor}`,
            }}
            onClick={() => onOpenDetails()}
          >
            Close
          </Button>
        </div> */}
      </Modal>
    </Col>
  );
};

export default List;
