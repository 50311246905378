import { sendEVAPrivateMessage, sendEVAQuery, sendMessage } from "./mongoDB";
// import { async } from "@firebase/util";
// import socket from "../utils/socket";
// import Constants from 'expo-constants';

const EVAServiceStream = async (msg, dbPath, ClientID, userid, uuid, name, BusinessId, isfromGroup = false) => {
  const raw = {
    "chatId": uuid,
    "query": msg,
    "UserInfo": {
      "chatId": uuid,
      "UserId": userid,
      "Name": name,
      "ClientId": ClientID,
      "BusinessId": BusinessId
    }
  };

  try {
    const response = await fetch('https://evabetaprod.azurewebsites.net/eva_stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(raw)
    });

    const reader = response.body.getReader();
    let chunks = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks += new TextDecoder().decode(value);
      const messagesArray = chunks.split('\n');
      // console.log(messagesArray)
      // messagesArray.forEach((message, index) => {
      //   if (message.trim()) {
      //     setStreamMessages(prevMessages => [...prevMessages, message]);
      //   }
      // });
    }
  } catch (error) {
    if (!isfromGroup) {
      return await EVAPrivate("We are currently encountering an issue while processing your request. We kindly ask that you attempt your request again at a later time. If you continue to receive this message repeatedly, we urge you to contact our dedicated support team for prompt assistance. Thank you!", dbPath, ClientID, userid, uuid, msg);
    } else {
      await EVAResponse("We are currently encountering an issue while processing your request. We kindly ask that you attempt your request again at a later time. If you continue to receive this message repeatedly, we urge you to contact our dedicated support team for prompt assistance. Thank you!", dbPath, ClientID, msg);
    }
  }
}
const EVAService = async (msg, dbPath, ClientID, userid, uuid, name, BusinessId, isfromGroup = false) => {
  const raw = {
    "chatId": uuid,
    "query": msg,
    "UserInfo": {
      "chatId": uuid,
      "UserId": userid,
      "Name": name,
      "ClientId": ClientID,
      "BusinessId": BusinessId
    }
  };

  // const eva_api = "https://evabetaprod.azurewebsites.net/eva";

  try {
    // console.log(raw)
    // const response = await fetch(eva_api, { ...requestOptions, timeout: 5000 });
    // const response = await fetch(eva_api, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: raw,
    //   });
    // console.log(response)

    const response = await sendEVAQuery(raw)
    // console.log("API Response: ", response)
    // const parsedResult = JSON.parse(response);

    // const lastIndex = response.length - 1;

    // console.log(response)
    // console.log("isfromGroup: ",isfromGroup)
    if (!isfromGroup) {
      return await EVAPrivate(response[1], dbPath, ClientID, userid, uuid, msg);
    } else {
      await EVAResponse(response[1], dbPath, ClientID, msg);
    }
  } catch (error) {
    if (!isfromGroup) {
      return await EVAPrivate("We are currently encountering an issue while processing your request. We kindly ask that you attempt your request again at a later time. If you continue to receive this message repeatedly, we urge you to contact our dedicated support team for prompt assistance. Thank you!", dbPath, ClientID, userid, uuid, msg);
    } else {
      await EVAResponse("We are currently encountering an issue while processing your request. We kindly ask that you attempt your request again at a later time. If you continue to receive this message repeatedly, we urge you to contact our dedicated support team for prompt assistance. Thank you!", dbPath, ClientID, msg);
    }
  }
};
const EVAPrivate = async (msg, dbPath, ClientID, userid, uuid, query) => {
  const data = {
    uuid: uuid,
    clientid: ClientID,
    userid: userid,
    // _id: Math.floor(Math.random() * 10000000000000),
    query: query,
    text: msg,
    sender: 1,
    user: mapEVA(),
    createdAt: new Date(),
  }
  await sendEVAPrivateMessage(data)
  return data
}
const EVAResponse = async (msg, dbPath, ClientID, query) => {
  const evaResponse = {
    clientid: ClientID,
    _id: Math.floor(Math.random() * 10000000000000),
    query: query,
    text: msg,
    sender: 1,
    user: mapEVA(),
    createdAt: new Date(),
  }
  // console.log("evaResponse: ", evaResponse)
  await sendMessage(evaResponse)
  // socket.emit("newMessage", evaResponse);
  // update(ref(database,dbPath), currentMessage);
}
const EVAGroup = async (msg, dbPath, ClientID, query, selectedGroupId) => {
  const evaResponse = {
    clientid: ClientID,
    _id: Math.floor(Math.random() * 10000000000000),
    query: query,
    groupId: selectedGroupId,
    text: msg,
    sender: 1,
    user: mapEVA(),
    createdAt: new Date(),
  }
  // console.log("evaGroup: ", evaResponse)
  await sendMessage(evaResponse)
  // socket.emit("newMessage", evaResponse);
  // update(ref(database,dbPath), currentMessage);
}
function mapEVA() {
  return {
    _id: Math.floor(Math.random() * 10000000000000),
    sender: Math.floor(Math.random() * 10000000000000),
    name: "EVA",
    // avatar: '../../etani.png'
  };
}
const convertToTreeViewData = (tableData, columnCount) => {
  const treeViewData = [];
  if (columnCount === 4) {
    tableData.forEach((rowData) => {

      const yearNode = treeViewData.find((node) => node.label === rowData.YearNo);

      if (yearNode) {
        const AccountNode = yearNode.children?.find((node) => node.label === rowData.Month);
        if (AccountNode) {
          AccountNode.children?.push({ clabel: [rowData.AccountName, rowData.Amount] });
        } else {
          yearNode.children?.push({
            label: rowData.Month,
            children: [{ clabel: [rowData.AccountName, rowData.Amount] }],
          });
        }
      } else {
        treeViewData.push({
          label: rowData.YearNo,
          children: [
            {
              label: rowData.Month,
              children: [{ clabel: [rowData.AccountName, rowData.Amount] }],
            },
          ],
        });
      }
    });

  }
  else {
    tableData.forEach((rowData) => {
      const yearNode = treeViewData.find((node) => node.label === rowData.YearNo);

      if (yearNode) {

        // const AccountNode = yearNode.children?.find((node) => node.label === rowData.Month);
        // if (AccountNode) {
        //   AccountNode.children?.push({ clabel: [rowData.AccountName , rowData.Amount] });
        // } else {
        yearNode.children?.push({
          clabel: [rowData.AccountName, rowData.Amount],
        });
        // }
      } else {
        treeViewData.push({
          label: rowData.YearNo,
          children: [{ clabel: [rowData.AccountName, rowData.Amount] }],
        });
      }
    });
  }

  return treeViewData;
};
const SetTotalAmount = (treeViewData, columnCount) => {
  if (columnCount === 4) {
    const totals = {};
    treeViewData.forEach((parent) => {

      parent.children?.forEach((subParent) => {
        // console.log(parent)
        let total = 0
        subParent.children?.forEach((entry) => {
          const child = entry.clabel
          let strItem = JSON.stringify(child[1])
          strItem = child[1] === undefined ? "0" : child[1]
          strItem = strItem.toString().includes(",") ? strItem.replace(",", "") : strItem
          strItem = strItem.toString().includes("$") ? strItem.replace("$", "") : strItem
          total = total + parseFloat(strItem)
        })
        const totalCLabel = ["Total Amount", total.toLocaleString("en-US", { maximumFractionDigits: 2 })];
        subParent.children?.push({ clabel: totalCLabel });
      })
    });
  } else {
    const totals = {};
    treeViewData.forEach((parent) => {
      let total = 0
      parent.children?.forEach((entry) => {
        const child = entry.clabel
        let strItem = JSON.stringify(child[1])
        strItem = child[1] === undefined ? "0" : child[1]
        strItem = strItem.toString().includes(",") ? strItem.replace(",", "") : strItem
        strItem = strItem.toString().includes("$") ? strItem.replace("$", "") : strItem
        total = total + parseFloat(strItem)
      })
      const totalCLabel = ["Total Amount", total.toLocaleString("en-US", { maximumFractionDigits: 2 })];
      parent.children?.push({ clabel: totalCLabel });
    });
  }
  return treeViewData
}
const generateUUID = async () => {
  let uuid = '';
  const characters = 'abcdef0123456789';

  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    } else {
      uuid += characters[Math.floor(Math.random() * characters.length)];
    }
  }
  return uuid;
}
const groupMessagesByDate = (history) => {
  const groupedMessages = {};

  history.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const today = new Date();

    let label;
    if (createdAt.toDateString() === today.toDateString()) {
      label = 'Today';
    } else if (
      createdAt > new Date(today - 1 * 24 * 60 * 60 * 1000) &&
      createdAt <= today
    ) {
      label = 'Yesterday';
    } else if (createdAt > new Date(today - 7 * 24 * 60 * 60 * 1000)) {
      label = 'Last 7 days';
    } else if (createdAt > new Date(today - 30 * 24 * 60 * 60 * 1000)) {
      label = 'Last 30 days';
    } else {
      label = createdAt.toLocaleString('default', { month: 'short', year: 'numeric' });
    }

    if (!groupedMessages[label]) {
      groupedMessages[label] = [];
    }

    const now = new Date()
    const isToday = createdAt.toDateString() === now.toDateString();
    let formattedTime;
    if (isToday) {
      const hours = createdAt.getHours();
      const minutes = createdAt.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
      formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      // Format the date as "MM-DD" for dates that are not today
      const month = createdAt.getMonth() + 1; // Months are 0-indexed
      const day = createdAt.getDate();
      formattedTime = `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
    }
    item.createdAt = formattedTime
    groupedMessages[label].push(item);
  });

  return groupedMessages;
};
const getChatTimeLabel = (createdAt) => {
  const newCreatedAt = new Date(createdAt);
  const now = new Date()
  const isToday = newCreatedAt.toDateString() === now.toDateString();
  let formattedTime;
  if (isToday) {
    const hours = newCreatedAt.getHours();
    const minutes = newCreatedAt.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
    formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  } else {
    // Format the date as "MM-DD" for dates that are not today
    const month = newCreatedAt.getMonth() + 1; // Months are 0-indexed
    const day = newCreatedAt.getDate();
    formattedTime = `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
  }
  return formattedTime
}
const checkEvaAccess = async (selectedClient, userDetails, setEvaIframeUrl, setNoEvaAccess, checkIsEvaAdvisorOnly) => {
  const firmId = userDetails?.User?.AccountingFirm;
  const { User: { UserAccess } } = userDetails;

  fetch(`/api/eva/access?businessId=${firmId}&clientId=${selectedClient}`)
    .then((res) => res.json())
    .then(async (data) => {
      // console.log(data)
      if ([1, 2, 3, 4].includes(UserAccess)) {
        // setCanSeeEvaToggle(true);
      } else {
        // setCanSeeEvaToggle(false);
        setEvaIframeUrl(null);
      }

      if (data.success && !data.data.isAdvisorOnly) {
        // setIsVisibleToClient(true); 
      } else {
        // setIsVisibleToClient(false); 
        setEvaIframeUrl(null);
      }

      if (
        data.success === true &&
        ([1, 2, 3, 4].includes(UserAccess) ||
          data.data.isAdvisorOnly === false)
      ) {
        await generateEVAIframeUrl({
          businessId: firmId.toString(),
          clientId: selectedClient,
          clientName: data.data.clientName,
          userFullName: `${userDetails.User.FirstName} ${userDetails.User.LastName}`,
          userId: userDetails.User.UserID.toString(),
          user: userDetails.User.id.toString(),
        }, setEvaIframeUrl);
        setNoEvaAccess(false);
      } else {
        setEvaIframeUrl(null);
        setNoEvaAccess(true);
      }
      if (data.success && checkIsEvaAdvisorOnly) {
        checkIsEvaAdvisorOnly(data.data.isAdvisorOnly)
      }

    }).catch((err) => { console.log(err); });
}
const generateEVAIframeUrl = async (params, setEvaIframeUrl) => {
  const details = await fetch("/api/eva/evaParams", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
  const data = await details.json();

  if (data.success) {
    const token = data.data;
    const url = `${process.env.REACT_APP_EVA_URL}?token=${token}`;
    setEvaIframeUrl(token);
  } else {
    setEvaIframeUrl(null);
  }
}
function getRandomLightColor() {
  // Generate random values for red, green, and blue components
  const r = Math.floor(Math.random() * 128) + 128; // Ranges from 128 to 255 (brighter colors)
  const g = Math.floor(Math.random() * 128) + 128;
  const b = Math.floor(Math.random() * 128) + 128;

  // Format the RGB values as a CSS color string
  const color = `rgb(${r}, ${g}, ${b})`;

  return color;
}

export { EVAService, convertToTreeViewData, SetTotalAmount, generateUUID, groupMessagesByDate, getChatTimeLabel, checkEvaAccess, getRandomLightColor, EVAServiceStream, EVAPrivate, EVAResponse, EVAGroup };
