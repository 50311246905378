import { mongoFetchToken } from "services/mongoDB"

const setBorderRadius = (messages, index) => {
    const currentMessage = messages[index]
    const prevMessage = messages[index - 1]
    const nextMessage = messages[index + 1]
    let resClassName = ""

    if(prevMessage === undefined || prevMessage && prevMessage.sender !== currentMessage.sender)
    {
      resClassName = resClassName + " btlr"
    }
    
    if(nextMessage === undefined || nextMessage && nextMessage.sender !== currentMessage.sender)
    {
      resClassName = resClassName + " bblr"
    }
      
    return resClassName
}
const isShowName = (messages, index) => {
    const currentMessage = messages[index]
    const prevMessage = messages[index - 1]
    const nextMessage = messages[index + 1]
    let res = false

    if(prevMessage === undefined || prevMessage && prevMessage.sender !== currentMessage.sender)
    {
        res = true
    }
    return res
}
const sendNotificationHandler = async (name,groupChat,msg) => {
    // const isFile = msg.includes("/documents%2F")
    // const isImage = msg.includes("/images%2F")

    // const messageType = isImage
    // ? 'image'
    // : isFile
    // ? 'document'
    // : 'a message';
    
    // const notification = {
    //   title: `${name} sent ${messageType} to the ${groupChat} group chat.`,
    //   body:  isFile || isImage ? '' : msg,
    //   sound: 'default',
    //   badge: '1',
    // };

    // const clientToken = await mongoFetchToken({ clientid : ClientID})
    // const filteredTokens = (clientToken.tokens || []).map(tokenObj => {
    //   socket.emit("sendNotification", tokenObj.token)
    //   return tokenObj.token
    // });
    // if (filteredTokens.length === 0) return

    // const message = {
    //   tokens: filteredTokens,
    //   data: route.route.params.data,
    //   notification: notification,
    //   apns: {
    //     payload: {
    //       aps: {
    //         'mutable-content': 1,
    //       },
    //     },
    //     fcm_options: {
    //        image: isAttachImage && msg,
    //     },
    //   },
    //   android: {
    //     notification: {
    //       image: isAttachImage && msg,
    //     },
    //   },
    // };
    // const payload = {
    //   notification: {
    //     title: 'FCM Notification',
    //     body: 'This is an FCM notification that displays an image!',
    //     sound: 'default',
    //     badge: '1',
    //   },
    // };
    // await sendMultiDeviceNotification(message)
    // await sendExpoNotifications(message)
};
export {
    setBorderRadius,
    isShowName
}
  