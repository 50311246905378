import { MainContext } from 'context/mainContext'
import GraphView from 'eva-component/response/GraphView'
import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useContext, useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

function VisualCardView({ gridHeader, sqlresult, theme, treeViewData, isWithChild, isShowGrid, toggleAll , isShowGrap, 
    templateItemVisual, headerToMultiSelect, handleSaveVisual, selectedViewOption, visualType, onEditVisual, handleCloseEditVisual, 
    index, layouts, item, onEditName, groupLevel }) 
{
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [cardItem, setCardItem] = useState([
            { id: '', 
            column: '', 
            columnIndex: '', 
            columnItem: {}, 
            columnItemList: [], 
            columnToSum: '', 
            columnIndexToSum: '', 
            sum: 0, 
            title: '', 
            description: '' }])
    const [cardWidth, setCardWidth] = useState(null)
    const [fontSizeFactor, setFontSizeFactor] = useState(null)
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    const [defaultCard, setDefaultCard] = useState(false)
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    useEffect(() => {
        // console.log("trigger")
        if(!templateItemVisual) {
            setDefaultCard(true)
            return
        }
        
        // console.log(templateItemVisual)
        // console.log(sqlresult)
        // console.log(treeViewData)
        setDefaultCard(false)
        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(templateItemVisual.selectedViewOption === 'Card') setCardItem(cardItem)

        else setDefaultCard(true)
    }, [templateItemVisual])

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
        else  setGridGroupLevel(groupLevel)
    }, [item])

    useEffect(() => {
        if(!gridGroupLevel) return
        if(defaultCard)
            processDefaultCard()
    }, [defaultCard])

    useEffect(() => {

    },[cardItem])

    useEffect(() => {
        if(onEditVisual) return 
        if(!templateItemVisual) return

        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(layouts !== undefined) setFontSizeFactor(layouts.w * 7)
        else setFontSizeFactor(60)

        if(cardItem && cardItem.length > 0)
        {
            loadCards(cardItem, headerToMultiSelect)
            const cardWidth = 100 / cardItem.length
            setCardWidth(cardWidth)
        }
    })

    const processDefaultCard = () => {
        // console.log(gridHeader)
        // handleCardItemChange(0, 'column', gridHeader[0])
        // add Card
        const Cards = []
        for(let i = 0; i < gridHeader.length - gridGroupLevel; i++)
        {
            // console.log(gridHeader[i + gridGroupLevel])
            Cards.push({ id: i, 
            column: gridHeader[0], 
            columnIndex: 0, 
            columnItem: 'grand-total', 
            columnItemList: getColumnItemList(), 
            columnToSum: gridHeader[i + gridGroupLevel], 
            columnIndexToSum: i, 
            sum: getGrandTotal(i), 
            title: '', 
            description: '' })
        }

        function getColumnItemList()
        {
            const columnItemList = []
            treeViewData.map(item => {
                columnItemList.push({
                    id: item.sqlId,
                    row: item.name
                })
            })

            return columnItemList
        }

        function getGrandTotal(index)
        {
            const item = treeViewData.find(i => i.sqlId === 'grand-total')
            if(item)
            {
                return item.value[index]
            }
            return 0
        }

        setCardItem(Cards)
    }

    const loadCards = (cardItem, headerToMultiSelect) => {
     
        cardItem.forEach((card, index) => {
     
            let totalAmount = 0;
            let isPercentage = false;
            let percentageSum = 0;
            let percentageCount = 0;
            
            if(item.editedGridCellData) {
                let currentIndentLevel = 0;
                const rowsToSum = loopThruItems(card.columnIndex, treeViewData);
                const totalAmount = calculateAllwithValue(rowsToSum);
                const isPercentage = card.columnToSum.includes("%")

                if(isPercentage) {
                    const sum = loopThruPercentageItems(rowsToSum)
                    // console.log(sum)
                    card.sum = sum
                }else {
                    card.sum = totalAmount;
                }

                function loopThruPercentageItems (rowsToSum)
                {
                    let sum = 0;
                    for (let i = 0; i < rowsToSum.length; i++) {
                        const row = rowsToSum[i];
                        if (row.value && (!row.id.includes("-TotalRow")) && (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) 
                        {
                            // console.log(row)
                            if (isNaN(row.value[card.columnIndexToSum]) || 
                            (typeof row.value[card.columnIndexToSum] === 'string' && row.value[card.columnIndexToSum].includes("%"))) 
                            {
                                const item = row.value[card.columnIndexToSum];
                                // Check if the item is defined and is a string
                                if (item && typeof item === 'string') {
                                    const cleanedItem = item.replace("%", "");
                                    sum += parseFloat(cleanedItem)
                                }
                            }
                            else    
                                sum += parseFloat(row.value[card.columnIndexToSum]);
                        }
                    }
                    
                    const result = sum / rowsToSum.length
                    
                    if(result > 1 || result < -1)
                        return `${((result) / 100).toFixed(2)} %`

                    return `${(result).toFixed(2)} %`
                }

                function loopThruItems(indentLevel, treeViewData) {
                    const rowsToSum = [];
                    
                    for (let i = 0; i < treeViewData.length; i++) {
                        const row = treeViewData[i];
                        
                        if (currentIndentLevel === card.columnIndex) 
                        {
                       
                            if (card.columnItem !== "") {
                                if (card.columnItem === row.sqlId) { 
                                    if(row.value ) rowsToSum.push(row);
                                    if(row.children) loopThruChildren(row.children)
                                }
                            } else {
                                if(row.value) {
                                    if (!row.id.includes("-TotalRow")) {
                                        rowsToSum.push(row);
                                    }
                                }
                            }
                            
                            if (row.children) {
                                const childRowsToSum = loopThruItems(indentLevel, row.children);
                                rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                            }
                        }

                        if (row.children) {
                            currentIndentLevel++;
                            const childRowsToSum = loopThruItems(indentLevel, row.children);
                            rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                            currentIndentLevel--;
                        }
                    }
                    return rowsToSum;

                    function loopThruChildren(parent)
                    {
                        for (let i = 0; i < parent.length; i++) { 
                            const row = parent[i];
                            if(row.value) {
                                if(!row.id.includes("-TotalRow"))
                                    rowsToSum.push(row);
                            }
                            if(row.children) loopThruChildren(row.children)
                        }
                    }
                }

                

                function calculateAllwithValue(rowsToSum) {
                    // console.log(rowsToSum);
                    let sum = 0;
                    
                    function loopThruItems(rowsToSum) {
                        for (let i = 0; i < rowsToSum.length; i++) {
                            const row = rowsToSum[i];
                            if (row.value && (!row.id.includes("-TotalRow")) && 
                                (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) {
                                    if(isNaN(row.value[card.columnIndexToSum]))
                                        sum += 0
                                    else    
                                        sum += parseFloat(row.value[card.columnIndexToSum]);
                            }
    
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }
                    
                    loopThruItems(rowsToSum); // Run the calculation on the collected rows
                    // console.log(sum);
                    return sum;
                }
                
            }else {
                const processItem = (item) => {
                    const res = headerToMultiSelect.filter((e) => e.name === card.columnToSum);
                    if (res.length > 0) {
                        const value = item[res[0].id];
                        if (typeof value === 'string' && value.includes('%')) {
                            isPercentage = true;
                            const numericValue = Number(parseFloat(value.replace('%', '')));
                            if (!isNaN(numericValue)) {
                                percentageSum += numericValue;
                                percentageCount++;
                            }
                        } else if (typeof value === 'number') {
                            totalAmount += value;
                        }
                    }
                };
        
                if (card.columnItem !== "") {
                    sqlresult.forEach((item) => {
                        if (item[card.id] === card.columnItem) {
                            processItem(item);
                        }
                    });
                } else {
                    sqlresult.forEach((item) => {
                        processItem(item);
                    });
                }
        
                if (isPercentage && percentageCount > 0) {
                    card.sum = (percentageSum / percentageCount).toFixed(1) + '%';
                } else {
                    card.sum = totalAmount.toFixed(2);
                }
            }
            
        });

        setCardItem(cardItem);
    }
    useEffect(() => {
        if(visualType) processVisualType(gridHeader, sqlresult)
    }, [visualType])
    const processVisualType = (gridHeader, sqlresult) => {
        if(gridHeader.length === 1)
        {
            handleCardItemChange(0, 'column', gridHeader[0])
            handleCardItemChange(0, 'columnToSum', gridHeader[0])
            const param = {
                selectedViewOption: selectedViewOption,
                cardItem: cardItem,
                headerToMultiSelect: headerToMultiSelect
            }

            if (item !== undefined) {
                if (item.tempVisual === undefined && item.visual === undefined) {
                    item.tempVisual = param;
                    // item.visual = param;
                }
            }
        }
    }
    const onHandleSaveVisual = () => {
        const param = {
            selectedViewOption: selectedViewOption,
            cardItem: cardItem,
            headerToMultiSelect: headerToMultiSelect
        }
        
        handleSaveVisual(param)
    }
    const handleCardItemChange = (index, type, value) => {
        if(value !== "")
        {
            const res = headerToMultiSelect.filter((e) => e.name === value);

            const columnIndexToSum = gridHeader.indexOf(value)

            setCardItem(prevGroups => {
                const updatedGroups = [...prevGroups];
                updatedGroups[index][type] = value;
                // updatedGroups[index][type] = value;

                if(type === 'column')
                {
                    updatedGroups[index]['id'] = res[0].id;
                    updatedGroups[index]['columnIndex'] = gridHeader.indexOf(value)
                    updatedGroups[index]['columnItemList'] = getColumnItemList(res[0].id)
                    updatedGroups[index]['columnItem'] = ""
                }

                if(type === 'columnToSum') {
                    const columnIndexToSum = gridHeader.indexOf(value)
                    updatedGroups[index]['columnIndexToSum'] = columnIndexToSum - gridGroupLevel
                }

                if((type === 'columnToSum') || type === 'columnItem' || 
                    (type === 'column' && updatedGroups[index]['columnIndexToSum'] !== ""))
                {
                    if(type === 'columnItem')
                    {   
                        if(updatedGroups[index]['columnToSum'] !== '')
                        {
                            const res = headerToMultiSelect.filter((e) => e.name === updatedGroups[index]['columnToSum']);
                            updatedGroups[index]['sum'] = getColumnItemListSum(updatedGroups[index], res[0].id)
                        }
                    }else updatedGroups[index]['sum'] = getColumnItemListSum(updatedGroups[index], res[0].id)
                }
                // console.log(updatedGroups)
                return updatedGroups;
            });
        }else{
            setCardItem(prevGroups => {
                const updatedGroups = [...prevGroups];
                updatedGroups[index][type] = '';
                updatedGroups[index]['id'] = '';
                updatedGroups[index]['columnItemList'] = [];
                updatedGroups[index]['columnIndexToSum'] = -1
                return updatedGroups;
            });
        }
    };
    const getColumnItemList = (id) => {
        const itemList = []

        if(gridGroupLevel) 
            return treeDataGetColumnItemList(id)
      
        sqlresult.forEach(element => {
            if(!itemList.includes(element[id]))
            itemList.push(element[id])
        });

       
        return itemList

        
    }
    const treeDataGetColumnItemList = (indentLevel) => {
        let currentIndentLevel = 0;
        const itemList = [];
    
        loopThruItems(indentLevel, treeViewData);
    
        function loopThruItems(indentLevel, treeViewData) {
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];
    
                if (currentIndentLevel === indentLevel) {
                    if (!itemList.includes(row.name)) {
                        if(!row.id.includes("-TotalRow"))
                        itemList.push({id: row.sqlId, row: row.name});
                    }
                }
    
                if (row.children) {
                    currentIndentLevel++;
                    loopThruItems(indentLevel, row.children);
                    currentIndentLevel--;
                }
            }
        }
    
        return itemList;
    };
    const getColumnItemListSum = (cardItem,id) => {
        let totalAmount = 0;
        let isPercentage = false;
        let percentageSum = 0;
        let percentageCount = 0;

        if(item && item.editedGridCellData)
        {
            let currentIndentLevel = 0;

            const rowsToSum = loopThruItems(cardItem.columnIndex, treeViewData);
            const totalAmount = calculateAllwithValue(rowsToSum);

            return totalAmount;

            function loopThruItems(indentLevel, treeViewData) {
                const rowsToSum = [];
                
                for (let i = 0; i < treeViewData.length; i++) {
                    const row = treeViewData[i];

                    if (currentIndentLevel === cardItem.columnIndex) {
                        if (cardItem.columnItem !== "") {
                            if (cardItem.columnItem === row.sqlId) { 
                                if (row.id && !row.id.includes("-TotalRow") && (row.id !== "grand-total")) 
                                {
                                    // console.log("Pushing row:", row); // Debugging line
                                    rowsToSum.push(row);
                                } else {
                                    if(cardItem.columnItem === "grand-total") rowsToSum.push(row);
                                    else {
                                        console.log("cardItem excluded:", cardItem);
                                        console.log("Row excluded:", row); // Debugging line
                                    }
                                }
                            }
                        } else {
                            if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                rowsToSum.push(row);
                            }
                        }
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        const childRowsToSum = loopThruItems(indentLevel, row.children);
                        rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                        currentIndentLevel--;
                    }
                }
                return rowsToSum;
            }

            function calculateAllwithValue(rowsToSum) {
                let sum = 0;
                
                function loopThruItems(rowsToSum) {
                    for (let i = 0; i < rowsToSum.length; i++) {
                        const row = rowsToSum[i];
                        if (row.value && (!row.id.includes("-TotalRow")) && 
                            (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) {
                                if(isNaN(row.value[cardItem.columnIndexToSum]))
                                    sum += 0
                                else    
                                    sum += parseFloat(row.value[cardItem.columnIndexToSum]);
                        }

                        if (row.children) {
                            loopThruItems(row.children);
                        }
                    }
                }
                
                loopThruItems(rowsToSum); // Run the calculation on the collected rows
                console.log(sum);
                return sum;
            }

        } else {
            if (cardItem.columnItem !== '') {
                sqlresult.forEach((data) => {
                    if (data[cardItem.id] === cardItem.columnItem) {
                        totalAmount += data[id];
                    }
                });
            } else {
                sqlresult.forEach((data) => {
                    if (typeof data[id] === 'string' && data[id].includes('%')) {
                        isPercentage = true;
                        const numericValue = parseFloat(data[id].replace('%', ''));
                        if (!isNaN(numericValue)) {
                            percentageSum += numericValue;
                            percentageCount++;
                        }
                    } else if (typeof data[id] === 'number') {
                        totalAmount += data[id];
                    }
                });
        
                if (isPercentage && percentageCount > 0) {
                    totalAmount = (percentageSum / percentageCount).toFixed(1) + '%';
                }
            }
        }
        
        if (cardItem.columnItem !== '') {
            sqlresult.forEach((data) => {
                if (data[cardItem.id] === cardItem.columnItem) {
                    totalAmount += data[id];
                }
            });
        } else {
            sqlresult.forEach((data) => {
                if (typeof data[id] === 'string' && data[id].includes('%')) {
                    isPercentage = true;
                    const numericValue = parseFloat(data[id].replace('%', ''));
                    if (!isNaN(numericValue)) {
                        percentageSum += numericValue;
                        percentageCount++;
                    }
                } else if (typeof data[id] === 'number') {
                    totalAmount += data[id];
                }
            });
    
            if (isPercentage && percentageCount > 0) {
                totalAmount = (percentageSum / percentageCount).toFixed(1) + '%';
            }
        }
    
        return totalAmount;
    }
    const formatAmount = (number) => {
        const isNegative = number < 0;
        const absNumber = Math.abs(number);
        
        if (isNaN(number) && !String(number).includes("%")) return 0;
        if (typeof number === 'string' && number.includes("%")) return number;
    
        if (absNumber >= 1e9) {
            return (isNegative ? "-" : "") + (absNumber / 1e9).toFixed(1) + "B";
        } else if (absNumber >= 1e6) {
            return (isNegative ? "-" : "") + (absNumber / 1e6).toFixed(1) + "M";
        } else if (absNumber >= 1e3) {
            return (isNegative ? "-" : "") + (absNumber / 1e3).toFixed(1) + "K";
        } else {
            return (isNegative ? "-" : "") + Math.round(absNumber);
        }
    };
    const addCardItem = () => {
        setCardItem(prevCard => [...prevCard, { id: '', 
        column: '', 
        columnIndex: '', 
        columnItem: {}, 
        columnItemList: [], 
        columnToSum: '', 
        columnIndexToSum: '', 
        sum: 0, 
        title: '', 
        description: '' }])
    }
    const removeCardItem = (index) => {
        templateItemVisual.cardItem = cardItem.filter(i => i.id !== index)
        setCardItem(prevCard => prevCard.filter((card, i) => i !== index))
    }
    const titleSize = (fontSizeFactor) => {
        return fontSizeFactor / 3  >= 20 ? 20 : fontSizeFactor / 3 <= 15 ? 15 : fontSizeFactor / 3
    }
    const splitTextIntoLines = (card, maxLength) => {
        if(card.columnToSum === card.column) return ''
        // console.log(card)
        let text = ""
        if(gridGroupLevel) {
            const item = card.columnItemList.find(i => i.id === card.columnItem)
            text = item ? item.row : card.column
        }else {
            text = typeof card.columnItem === 'string' && card.columnItem !== ''
            ? card.columnItem
            : card.column
        }   

        if (text.length <= maxLength) return text;
    
        const lines = [];
        for (let i = 0; i < text.length; i += maxLength) {
            lines.push(text.substring(i, i + maxLength));
        }
    
        return lines.join('\n');
    }
    return (
        <>
            {(onEditVisual || onEditName) && (
                <>
                    {cardItem && cardItem.length > 0 && cardItem.map((card, index) => (
                        <FormGroup key={index}>
                            <Col sm='12'>
                                <Row>
                                    <Col sm='12' md='3'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.column} onChange={e => handleCardItemChange(index, 'column', e.target.value)}>
                                            <option value="">Select Column</option>
                                            {gridGroupLevel ? 
                                                <>
                                                    {gridHeader.map((group, index) => (
                                                        index < gridGroupLevel && 
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {gridHeader.map((group, index) => (
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </>
                                            }
                                        </Input>
                                    </Col>
                                    <Col sm='12' md='3'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column Item</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.columnItem} onChange={e => handleCardItemChange(index, 'columnItem', e.target.value)}>
                                            <option value="">Select Column Item</option>
                                            {gridGroupLevel ? 
                                            <>
                                                {card.columnItemList.length > 0 && card.columnItemList.map(itemListItem => (
                                                    <option key={itemListItem.id} value={itemListItem.id}>
                                                        {itemListItem.row}
                                                    </option>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {card.columnItemList.length > 0 && card.columnItemList.map(itemListItem => (
                                                    <option key={itemListItem} value={itemListItem}>
                                                        {itemListItem}
                                                    </option>
                                                ))}
                                            </>
                                            }
                                        </Input>
                                    </Col>
                                    <Col sm='12' md='3'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column to Summarize</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.columnToSum} onChange={e => handleCardItemChange(index, 'columnToSum', e.target.value)}>
                                            <option value="">Select Column to Summarize</option>
                                            {gridGroupLevel ? 
                                                <>
                                                    {gridHeader.map((group, index) => (
                                                        index >= gridGroupLevel && 
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {gridHeader.map((group, index) => (
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </>
                                            }
                                        </Input>
                                        <div className='cardControllerContainer'>
                                            {index === cardItem.length - 1 && (
                                                <div 
                                                    className='addNewCardlData'
                                                    onClick={addCardItem}
                                                ><i className="fas fa-solid fa-plus"></i></div>
                                            )}
                                            {cardItem.length > 1 && (
                                                <div className='removeCardlData' onClick={() => removeCardItem(index)}><i className="fas fa-solid fa-trash"></i></div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    ))}
                    <FormGroup>
                        <Col sm='12'>
                            <Row>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                    onClick={onHandleSaveVisual}
                                >Save View</div>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                                    onClick={handleCloseEditVisual}
                                >Cancel</div>
                            </Row>
                        </Col>
                    </FormGroup>
                </>
            )}
            <div className='cardContent'>
                {cardItem && cardItem.length > 0 && cardItem.map((card, index) => (
                    <div key={index} style={{ width : `calc(${cardWidth}% - 0px)`, padding: '0px 5px', minWidth: 170, minHeight: 200, marginBottom: 10, maxWidth: 400 }}>
                        <div className='cardContainer'>
                            <div className='cardAmount' style={{ fontSize: fontSizeFactor > 100 ? 100 : fontSizeFactor < 30 ? 30 : fontSizeFactor}}>
                                {formatAmount(card.sum)}
                            </div>
                            <div className='cardTitle' style={{ fontSize: titleSize(fontSizeFactor) }}>
                                {card.columnToSum}
                            </div>
                            <div className='cardTitleDescription'>
                                {splitTextIntoLines( card, 25 )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
    
}

export default VisualCardView