import React, { useEffect, useState } from 'react'
import { evaThemeLighter, reformatEVAColumnValue, styleAlignRight } from '../../services/EVAResponseService';

export default function GridView({ theme, sqlresult, EVAData, gridHeader }) {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'defaultKey', direction: 'asc' });
  const [totalRow, setTotalRow] = useState([])



  useEffect(() => {
    const fetchData = async () => {
      if (!sqlresult) return null
      await addTotalRow(sqlresult);
      setData(sqlresult);
    };

    fetchData();
  }, [sqlresult]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
  };
  const sortData = async (key, direction) => {
    const sortedData = [...sqlresult].sort((a, b) => {
      const keyA = typeof a[key] === 'string' ? parseFloat(a[key].replace(/,/g, '')) : a[key]
      const keyB = typeof b[key] === 'string' ? parseFloat(b[key].replace(/,/g, '')) : b[key]

      if (direction === 'asc') {
        if (!isNaN(keyA))
          return keyA > keyB ? 1 : -1;


        return a[key].replace(/,/g, '') > b[key].replace(/,/g, '') ? 1 : -1;
      } else {
        if (!isNaN(keyA))
          return keyA < keyB ? 1 : -1;

        return a[key].replace(/,/g, '') < b[key].replace(/,/g, '') ? 1 : -1;
      }
    });
    setData(sortedData);
  };
  const addTotalRow = async (sqlresult) => {

    const newRow = ['Total']
    for (const column in sqlresult[0]) {
      if (column != 0) {
        const columnValue = isNaN(sqlresult[0][column]) ? sqlresult[0][column].replace(/,/g, '') : sqlresult[0][column];
        // const columnValue = sqlresult[0][column].replace(/,/g, ''); // Remove commas
        if (!isNaN(columnValue)) {
          const total = sqlresult.reduce((acc, row) => {
            const value = typeof row[column] === 'string' ? parseFloat(row[column].replace(/,/g, '')) : row[column];
            return acc + (value || 0);
          }, 0);
          newRow.push(total.toFixed(2)); // Include decimal in the total
        } else {
          newRow.push("-");
        }
      }
    }

    const newData = [...sqlresult, newRow]
    setTotalRow(newRow)
    return newData
  }
  const backgroundColorStyleLighttd = evaThemeLighter(10, theme, '#333')
  return (
    <div className='gridView'>

      {EVAData && gridHeader && (
        <table>
          <thead
            style={evaThemeLighter(100, theme)}
          >
            <tr>
              {gridHeader.map((header, index) => (
                <th
                  key={index}
                  onClick={() => requestSort(index)}
                  style={evaThemeLighter(100, theme)}
                >
                  {header}
                  {sortConfig.key === index && (
                    <span>{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.map((row, rowIndex) => (
              <tr key={rowIndex} style={rowIndex % 2 === 1 ? backgroundColorStyleLighttd : {}}>
                {row.map((col, colIndex) => (
                  <td key={colIndex} style={styleAlignRight(col)}>
                    {reformatEVAColumnValue(col)}
                  </td>
                ))}
              </tr>
            ))}
            {sqlresult.length > 1 && totalRow && (
              <tr className='highlighted'>
                {Object.values(totalRow).map((col, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      ...evaThemeLighter(100, theme),
                      ...styleAlignRight(col)
                    }}
                  >
                    {reformatEVAColumnValue(col)}
                  </td>

                ))}
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
