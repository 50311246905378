import React, { useEffect, useState } from "react";

const Input = ({ selectedUUID, onSendMessage, theme , onInputText, token }) => {
  const [text, setText] = useState("");

  // useEffect(()=>{
  //   setText(onInputText)
  // }, [selectedUUID ,onInputText])

  const handleSend = async () => {
    if(text)
    {
      onSendMessage(text)
      setText("")
    }
  };
  const mentionEVA = () => {
    const lowercasedMessage = text.toLowerCase();
    if(!lowercasedMessage.includes("@eva"))
      setText("@Eva " + text)
  }

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
      />
      <div className="send">
        {token && <button  style={{ backgroundColor: theme.PrimaryColor }} onClick={mentionEVA}>@Eva</button>}
        <button  style={{ backgroundColor: theme.PrimaryColor }} onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;