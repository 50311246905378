import PricingCard from 'components/Cards/PricingCard';
import React, { useEffect, useState, useContext } from 'react'
import { MainContext } from "context/mainContext";
import { CustomFeatures } from "customComponents/customPricing";
import {
  Modal,
  FormGroup,
  Form,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import moment from "moment";
import PlanCard from './PlanCard';
import BlockUI from 'components/Misc/BlockUI';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";
import PaymentDetails from 'components/Payment/PaymentDetails';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "utils/CheckoutForm";
import CustomSpinner from "components/Misc/CustomSpinner";
import SeatPricingCard from 'components/Cards/SeatPricingCard';
import CurrentSeactCountCard from 'components/Cards/CurrentSeatCountCard';

const PlanDetails = ({ data, planList, ...props }) => {
  const { firmDetails, textColor, bgColor, userDetails } = useContext(MainContext)
  const [changePlan, setChangePlan] = useState(false)
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [alert, setAlert] = useState(null)
  const routerHistory = useHistory();
  const [userPaymentDetails, setUserPaymentDetails] = useState(null)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isStripeSuccess, setIsStripeSuccess] = useState(false)
  const [openStripSuccessModal, setOpenStripeSuccessModal] = useState(false)
  const [showCurrentSeatCount, setShowCurrentSeatCount] = useState(true)
  const [feedbackMessage, setFeedbackMessage] = useState(null)
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [cancelParams, setCancelParams] = useState({})
  const [isFeedbackValid, setIsFeedbackValid] = useState(true)

  useEffect(() => {
    // console.log(firmDetails)
    fetch(`/api/stripe/getCustomerPaymentDetails?email=${firmDetails?.Email}&description=${firmDetails?.Name}`, { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        // console.log('getCustomerPaymentDetails', data)
        if (data && !data.error) {
          if (data.message !== "No details found.") {
            setUserPaymentDetails(data.record)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userDetails])

  const handleChangePlan = () => {
    setChangePlan(!changePlan)
  }

  const selectPlan = (data) => {
    // console.log(data)
  }

  const handleUpgradeOrDowngradeChangePlan = (params, type) => {
    // console.log(firmDetails)
    let clientUserCount = firmDetails?.ClientUserCount || 0;
    let accountantCount = firmDetails?.Accountant?.length || 0;
    let totalUserCount = params.Code === 'TIER1' ? clientUserCount : clientUserCount + accountantCount;

    let meetsClientRequirements = firmDetails.Client.length <= params.Details.Clients;
    let meetsUserRequirements = totalUserCount <= params.Details.ClientUsers;
    let meetsAdvisorRequirements = firmDetails.Accountant.length <= params.Details.Advisors;

    // console.log(meetsClientRequirements)
    // console.log(meetsUserRequirements)
    // console.log(meetsUserRequirements)

    let readyForUpgradeOrDowngrade;
    if (params.Code === 'TIER1') {
      readyForUpgradeOrDowngrade = meetsClientRequirements && meetsUserRequirements && meetsAdvisorRequirements;
    } else {
      readyForUpgradeOrDowngrade = meetsClientRequirements && meetsUserRequirements;
    }
    // console.log(readyForUpgradeOrDowngrade)
    if (readyForUpgradeOrDowngrade) {
      setShowBlockUI(true)
      fetch("/api/pricing/upgradeOrDowngradePlan", {
        method: "PUT",
        body: JSON.stringify({
          planCode: params.Code,
          countryCode: firmDetails.CountryCode,
          forUpgrade: type === 'downgrade' ? false : true,
        }),
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          if (data && !data.error) {
            successAlert(data.record.Details, type)
            setShowBlockUI(false)
          }
        })
        .catch(e => {
          console.log(e)
          setShowBlockUI(false)
          errorAlert(e)
        })
    } else {
      errorAlert(
        // <p>Current numbers of <strong style={{ fontWeight: 700 }}>Client, Client Users or Advisor</strong> are to many to be able to downgrade. Please ensure to remove some of the Client, Client Users, and Advisors before proceeding.</p>
        <p>To proceed with your plan downgrade, it is necessary to reduce the number of <strong style={{ fontWeight: 700 }}>Clients, Users, and Admins</strong> currently linked to your account, as they exceed the limit of the lower-tier plan. Please adjust these quantities to align with the new plan's specifications and then continue with the downgrade process. Thank you.</p>
      )
    }
  }

  const successAlert = (response, type, selectedBusinessToGoBack) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          setAlert(null)
          if (type === 'cancelSubs') {
            if (selectedBusinessToGoBack) {
              handleChangeBusiness(selectedBusinessToGoBack)
            } else {
              routerHistory.push(
                `/subscription/success-cancellation`
              );
            }
          } else {
            window.location.reload()
            routerHistory.push(
              `/client`
            );
          }
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {
          type === 'cancelSubs' ? <p>{response}</p> :
            type === 'upgrade' && response.Plan.Name === 'Etani Business' ?
              <>
                <p>
                  You are now subscribed to <strong style={{ fontWeight: 700 }}>{`${response.Plan.Name}`}</strong>.
                </p>
                <p>
                  You can continue to use the platform.etani URL but we have now setup a white-labelled platform for your business at <strong><a href={`https://${response.azureDomain}`} target="_blank" rel="noreferrer">{response.azureDomain}</a></strong>
                </p>
                {/* <p>
                  We are also launching your mobile app available for download from the Apple App Store and Google Play Store. We will send you an email with the links when live.
                </p> */}
                <p>
                  Welcome to Etani!
                </p>
              </>
              :
              type === 'upgrade' && response.Plan.Name === 'Etani Premium' ?
                <>
                  <p>
                    You are now subscribed to <strong style={{ fontWeight: 700 }}>{`${response.Plan.Name}`}</strong>.
                  </p>
                  <p>
                    We are also launching your mobile app available for download from the Apple App Store and Google Play Store. We will send you an email with the links when live.
                  </p>
                </>
                :
                <p>
                  You are now subscribed to <strong style={{ fontWeight: 700 }}>{`${response.Plan.Name}`}</strong>.
                </p>
        }
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setAlert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const handleFeedbackChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setFeedbackMessage(value)
  }

  const cancelPopup = () => {
    return (
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => handleCancelSubscription(cancelParams)}
        onCancel={() => {
          setFeedbackMessage(null)
          setShowCancelPopup(false)
          setAlert(null)
          setCancelParams({})
          setIsFeedbackValid(true)
        }}
        confirmBtnBsStyle="warning"
        confirmBtnText={'Proceed'}
        btnSize=""
        showCancel={true}
      >
        <>
          <p><strong style={{ fontWeight: 700 }}>Cancel Subscription</strong></p>
          <p><strong style={{ fontWeight: 700 }}>Please note:</strong> If you choose to continue with the cancellation, all your details, integration databases, and datasets will be permanently deleted.</p>
          <p className='mb-0'>We would appreciate if you shared some feedback to help us improve.</p>
          <Input
            placeholder="Type in your feedback"
            type="textarea"
            onChange={(e) => handleFeedbackChange(e)}
            rows={3}
            style={{
              border: isFeedbackValid ? "1px solid #dee2e6" : "1px solid red",
            }}
          />
          {isFeedbackValid ? (
            ""
          ) : (
            <small className="text-warning">
              This field is required.
            </small>
          )}{" "}
        </>
      </ReactBSAlert>
    )
  }

  const warningAlert = (params, type, pricingCode) => {
    // console.log(params, type)
    // console.log(firmDetails)
    // console.log(pricingCode)
    if (type === 'cancelSubs') {
      setShowCancelPopup(true)
      setCancelParams(params)
    }
    if (type === 'downgrade') {
      setAlert(
        <ReactBSAlert
          warning
          title="Warning"
          onConfirm={() => handleUpgradeOrDowngradeChangePlan(params, type)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText={'Downgrade'}
          btnSize=""
          showCancel={true}
        >
          <p>You are about to <strong style={{ fontWeight: 700 }}>{type}</strong> to <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>. Click downgrade to continue</p>
        </ReactBSAlert>
      )

    } else {
      setAlert(
        <ReactBSAlert
          info
          title=""
          onConfirm={() => type === 'cancelSubs' ? handleCancelSubscription(params) : handleUpgradeOrDowngradeChangePlan(params, type)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          confirmBtnText={'Upgrade'}
          btnSize=""
          showCancel={true}
        >
          <>
            <p>You are about to <strong style={{ fontWeight: 700 }}>{type}</strong> to <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>.</p>
            <p>
              We cannot wait to open up all the features and functionality of the <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>.
            </p>
            <p>Click {type} to continue.</p>
          </>
        </ReactBSAlert>
      );
    }
  };

  const handleCancelSubscription = (params) => {
    //console.log('Canceled')
    if (!feedbackMessage) {
      setIsFeedbackValid(false)
      return
    }
    setShowBlockUI(true)
    fetch("/api/pricing/cancelSubscription", {
      method: "POST",
      body: JSON.stringify({
        planCode: params.Code,
        feedbackMessage: feedbackMessage
      }),
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data)
        setFeedbackMessage(null)
        setShowCancelPopup(false)
        setCancelParams({})
        setIsFeedbackValid(true)
        let cancelData = data
        if (!data.error) {
          let url = window.location.hostname.split(".");
          if (url[0] === 'platform') {
            fetch("/api/trialUser/getAllBusinessesPerMBU")
              .then((res) => res.json())
              .then(data => {
                // console.log('trialUser/getAllBusinessesPerMBU', data)
                if (data.record && !data.error) {
                  let businesses = data.record
                  if (businesses.length > 1) {
                    let initialSelectedBusiness = businesses.filter((rec, i) => rec.Details?.NeedSetPayment != true)
                    // console.log(initialSelectedBusiness)
                    // setSelectedBusinessToGoBack(initialSelectedBusiness)
                    setShowBlockUI(false)
                    return successAlert(cancelData.message, 'cancelSubs', initialSelectedBusiness[0])
                  } else {
                    setShowBlockUI(false)
                    successAlert(cancelData.message, 'cancelSubs')
                  }
                }
              })
              .catch(e => {
                setShowBlockUI(false)
                console.log(e)
              })
          } else {
            successAlert(cancelData.message, 'cancelSubs')
            setShowBlockUI(false)
          }
        } else {
          setShowBlockUI(false)
          errorAlert(data.error)
        }
      })
      .catch(e => {
        setShowBlockUI(false)
        errorAlert(e)
        console.log(e)
      })
  }

  const handleChangeBusiness = (bus) => {
    // console.log(bus)
    // let details = { AccountingFirm: bus.id }
    // getDetails(details)
    setShowBlockUI(true)
    // setSelectedBusiness(bus)
    fetch(`/api/trialUser/changeBusiness/${bus.id}`)
      .then((res) => res.json())
      .then(data => {
        // console.log('trialUser/changeBusiness', data)
        if (data.error) {
          setShowBlockUI(false)
          // errorAlert(data.error)
        }
        setTimeout(() => {
          if (data && !data.error) {
            // fetchAPIs()
            // getDetails()
            setShowBlockUI(false)
            return routerHistory.push("/");
          }
        }, 5000)
      })
      .catch(e => {
        setShowBlockUI(false)
        console.log(e)
      })
  }

  const handleOpenPaymentModal = () => {
    // console.log(openPaymentModal);
    setOpenPaymentModal(!openPaymentModal);
    if (!openPaymentModal) {
      fetch("/api/stripe/getConfig", {
        method: "POST",
        body: JSON.stringify({
          email: `${userDetails?.User?.Email}`,
        }),
      }).then(async (r) => {
        const { publishKey } = await r.json();
        setStripePromise(loadStripe(publishKey));
        // console.log(publishKey);
        if (publishKey) {
          fetch("/api/stripe/setUpIntent", {
            method: "POST",
            body: JSON.stringify({
              name: `${firmDetails?.Name}`,
              email: `${firmDetails?.Email}`,
              description: `${firmDetails?.Name}`,
              country: `${firmDetails?.CountryCode}`,
            }),
          }).then(async (result) => {
            let { clientSecret } = await result.json();
            // console.log(clientSecret);
            setClientSecret(clientSecret);
          });
        }
      });
    }
  };

  const paymentModal = () => {
    const options = {
      appearance: {
        theme: "stripe",
      },
      clientSecret,
    };
    return (
      <Modal
        className="modal-dialog-top"
        isOpen={openPaymentModal}
        toggle={() => handleOpenPaymentModal()}
        size="lg"
        backdrop="static"
      >
        <div className="modal-header">
          <h6 className="modal-title">Subscribe to Etani Essentials Plan</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => handleOpenPaymentModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-2">
            <p className="font-weight-400 text-left">
              Before diving deeper, we need your banking details. But here's the assurance: <strong>*You won't be charged until your trial is over!*</strong> And yes, you can cancel at any point before the trial concludes, even after entering your payment details.
            </p>
          </div>
          <div className="mb-5">
            {/* <p className='text-left font-weight-600'>
              <span className="btn-link" style={{ cursor: 'pointer' }} onClick={handleShowPlanVideo}>Click to watch video</span>
            </p> */}
            <p className="font-weight-400 text-left">
              Once you're on board with Essentials, you'll find it easy to explore our other plans. We're thrilled to be your partner in meeting your data needs.
            </p>
          </div>
          {clientSecret && stripePromise ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                secret={clientSecret}
                userDetails={userDetails}
                firmDetails={firmDetails}
                stripeSuccess={stripeSuccess}
                userEmail={userDetails?.User?.Email}
                description={firmDetails?.Name}
              />
            </Elements>
          ) : (
            <CustomSpinner />
          )}
        </div>
      </Modal>
    );
  };

  const stripeSuccess = (result) => {
    if (result?.setupIntent?.status === 'succeeded') {
      setIsStripeSuccess(true)
      setOpenPaymentModal(false);
      // setOpenStripeSuccessModal(true)
      stripeSuccessAlert()
    } else {
      setIsStripeSuccess(false)
    }
  }

  const stripeSuccessAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          setAlert(null)
          window.location.reload();
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        <p>
          You are now subscribed to <strong>Etani Essentials Plan</strong>.
        </p>
      </ReactBSAlert>
    );
  }

  const handleOpenStripeSuccessModal = () => {
    setOpenStripeSuccessModal(!openStripSuccessModal)
  }

  const stripeSuccessModal = () => {
    return (
      <Modal
        className="modal-dialog-top"
        isOpen={openStripSuccessModal}
        toggle={() => handleOpenStripeSuccessModal()}
        size="lg"
        backdrop="static"
      >
        <div className="modal-header">
          <h6 className="modal-title">Success subscribing to Etani Business Plan</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => handleOpenStripeSuccessModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
        </div>
      </Modal>
    );
  }

  const handleShowCurrentSeatCount = () => {
    setShowCurrentSeatCount(!showCurrentSeatCount)
  }

  return (
    <>
      {showBlockUI ? <BlockUI /> : null}
      {alert}
      {paymentModal()}
      {stripeSuccessModal()}
      {showCancelPopup ? cancelPopup() : null}
      {
        <>
          {
            firmDetails.PricingPlan?.Code === "TIER5" ? null :
              <>
                <Row>
                  {
                    planList.length >= 1 ?
                      planList.map((pl, i) => (
                        <Col xl={planList.length === 1 ? "12" : planList.length > 2 ? "4" : "6"} lg={planList.length === 1 ? "12" : planList.length > 2 ? "4" : "6"} md={planList.length === 1 ? "12" : planList.length > 2 ? "4" : "6"} sm={planList.length === 1 ? "12" : planList.length > 2 ? "4" : "6"} key={i}>
                          {
                            firmDetails?.PricingPlan?.Code === "TIER6" ?
                              <CurrentSeactCountCard
                                handleShowCurrentSeatCount={handleShowCurrentSeatCount}
                                firmDetails={firmDetails}
                                handleUpgradePlan={warningAlert}
                                record={pl}
                              />
                              :
                              <PricingCard
                                textColor={textColor}
                                bgColor={bgColor}
                                i={i}
                                firmDetails={firmDetails}
                                record={pl}
                                handleUpgradePlan={warningAlert}
                                subscribe={handleOpenPaymentModal}
                                userDetails={userDetails}
                              />
                          }
                        </Col>
                      ))
                      : null
                  }
                </Row>
              </>
          }
        </>
      }
    </>
  )
}


export default PlanDetails;