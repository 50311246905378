import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { MainContext } from "context/mainContext";

const PanelCardEva = ({ showSettingButton, handleShowEvaSettings, isEvaAdvisorOnly, ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  return (
    <>
      {
        //Added by Joef: Added style in case style is passed in panel
      }
      <Card className="fadeIn" style={props.style || {}}>
        <CardHeader
          style={{
            backgroundColor: `${bgColor}`,
            transition: ".3s ease-in-out",
          }}
        >
          <Row>
            <Col sm="10" xs="10">
              <h5 className="h3 mb-0" style={{ color: `${textColor}` }}>
                {props.headerName}
              </h5>
              {props.subHeader ? (
                <span style={{ color: `${textColor}`, fontSize: "0.7rem" }}>
                  {props.subHeader}
                </span>
              ) : null}
            </Col>
            <Col sm="2" xs="2">
              {
                showSettingButton ?
                  <div className="text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        color=""
                        size="sm"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() => handleShowEvaSettings()}
                        >
                          {`${isEvaAdvisorOnly ? 'Enable' : 'Disable'} to all client users`}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  : null
              }
            </Col>
          </Row>
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    </>
  );
};

export default PanelCardEva;
