import React from 'react'

 function Loading({ style }) {
    return (
        <div style={style} className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
}

export default Loading