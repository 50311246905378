import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

const LoadingPage = ({ ...props }) => {
  // const [success, setSuccess] = useState(false)


  // useEffect(() => {
  //   fetch(
  //     `/api/trialUser/processingDetails`,
  //     {
  //       method: "GET",
  //       credentials: "include",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       console.log(data)
  //       if (data && data.success) {
  //         setSuccess(true)
  //       }
  //     })
  // }, [])

  return (
    <>
      {props.success ? "Success" :
        <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
      }
    </>
  )
}

export default LoadingPage;