import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { sendEVAPrivateMessage } from "../services/mongoDB";
import { EVAService } from "../services/EVAService";
import { generateUUID } from "services/EVAResponseService";
import { evaThemeLighter } from "services/EVAResponseService";
import { MainContext } from "context/mainContext";

const Input = ({ selectedUUID, onSendEVAMessage,onSelectUUID, ClientID, userid, theme , onInputText }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [includesEmail, setIncludesEmail] = useState(false)

  const {
    userDetails,
  } = useContext(MainContext);

  useEffect(() => {
    const loadData = async (stext) => {
      const email = userDetails.User.Email.toLowerCase()
      const specialEmails = ["jp@etani.com.au", "fuhad@etani.com.au", "admin@etani-bp.com.au"];
      setIncludesEmail(specialEmails.includes(email))
    }

    loadData()
  }, [selectedUUID, userDetails])
  useEffect(()=>{
  
    setText(onInputText)
  }, [onInputText])

  const handleSend = async () => {
    if(text)
    {
      onSendEVAMessage(text, false)
      setText("")
    }
  };

  const handleClick = async () => {
    const newUUID = await generateUUID();
    onSelectUUID(newUUID);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = function (event) {
        const fileText = event.target.result;
        const lines = fileText.split('\n');
        lines.forEach((line) => {
          if (line.trim()) {
            onSendEVAMessage(line.trim(), true);
          }
        });
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid .txt file");
    }
  };

  return (
    <div className="input">
      <div className="new">
        <button style={evaThemeLighter(100, theme)} onClick={handleClick}>New Chat</button>
      </div>
      {includesEmail &&
      <div className="uploadEvafile" style={{ cursor: 'pointer', ...evaThemeLighter(100, theme), width: 100, height: 40, borderRadius: 5, padding: '10px 11px' }}>
        <input
          type="file"
          accept=".txt"
          style={{ display: 'none' }}
          id="file-upload"
          onChange={handleUploadFile}
        />
        <label htmlFor="file-upload">Upload file</label>
      </div>
      }
      
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
      />
      <div className="send">
        <button  style={{ backgroundColor: theme.PrimaryColor }} onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;