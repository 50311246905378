import React from "react";
import { Modal, CardHeader, Row, Col, Input } from "reactstrap";
import { CustomModalButton } from "components/Button/CustomButton";

const CustomModal = ({ ...props }) => {
  return (
    <Modal
      size="lg"
      className="modal-dialog"
      isOpen={props.isOpen}
      // toggle={props.toggle}
    >
      <div className="modal-body">
        <h5 className="modal-title mb-0">{props.title}</h5>
        <CardHeader className="px-0">
          {props.fieldGroup}
          <Row>
            <Col>{props.headerButtons}</Col>

            <Col>
              <Input
                bsSize="sm"
                type="text"
                placeholder="Search"
                onChange={props.searchInput}
              />
            </Col>
          </Row>
        </CardHeader>
        {props.subtitle}
        <Row className="">{props.list}</Row>
        <CustomModalButton
          submitButton={props.submitButton}
          closeButton={props.closeButton}
          submitText="Submit"
          cancelText="Cancel"
          submitType="button"
        />
      </div>
    </Modal>
  );
};

export default CustomModal;
