import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Card, CardBody, Row, Col } from "reactstrap";

const AccountingFirmInfo = ({ data, loading, bgColor }) => {
  useEffect(() => {}, [data]);
  return (
    <>
      <Card className="card-profile">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <div onClick={(e) => e.preventDefault()}>
                <img
                  style={{
                    background: "#eee",
                    width: "140px",
                    height: "140px",
                    objectFit: "contain",
                    borderRadius: "10px",
                    border: "2px solid #e2e2e2",
                  }}
                  alt="..."
                  // className="rounded-circle"
                  src={
                    data && data.Logo
                      ? data.Logo
                      : require("assets/img/brand/logo-placeholder.png").default
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <CardBody className="pt-0">
          {!loading && data ? (
            <div className="text-center" style={{ marginTop: "100px" }}>
              <h5 className="h3">{data && data.Name ? data.Name : ""}</h5>
              <div className="h4 font-weight-400">
                {data && data.Code ? data.Code : ""}
              </div>
              {/* <Row>
                <Col sm="4" className="offset-sm-4">
                  <hr className="m-3" />
                </Col>
              </Row>
              <Row className="">
                <Col xs="12">
                  <h4>
                    <span className="btn-inner--icon ml-1">
                      <i className="fa fa-home pull-left mx-2" />
                    </span>
                    <a
                      style={{ fontSize: ".9rem" }}
                      target="_blank"
                      rel="noreferrer"
                      href={data && data.WebSite ? data.WebSite : ""}
                      className="ml-2"
                    >
                      {data && data.WebSite ? data.WebSite : ""}
                    </a>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col sm="4" className="offset-sm-4">
                  <hr className="m-3" />
                </Col>
                <Col xs="12">
                  <div className="h4 font-weight-400 mb-3">
                    {data && data.Description ? data.Description : ""}
                  </div>
                  <div className="text-center col-xs-6">
                    <a
                      className="fab fa-facebook-f text-dark mx-4"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.FacebookPage}`}
                    >
                      {""}
                    </a>
                    <a
                      className="fab fa-twitter  text-dark mx-4"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.TwitterPage}`}
                    >
                      {""}
                    </a>
                    <a
                      className="fab fa-linkedin text-dark  mx-4"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.LinkedInPage}`}
                    >
                      {""}
                    </a>
                    <a
                      className="fab fa-youtube  text-dark mx-4"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.YouTubePage}`}
                    >
                      {""}
                    </a>
                  </div>
                </Col>
              </Row> */}
            </div>
          ) : (
            <div className="text-center" style={{ marginTop: "100px" }}>
              <ClipLoader
                color={bgColor === "#fff" ? "#5e72e4" : bgColor}
                size={20}
                margin={2}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AccountingFirmInfo;
