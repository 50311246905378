import React, { useEffect, useState } from 'react'
import { TableHeader } from 'services/EVAEditGridService'
import EditableCell from './EditableCell'
import { NewParseFormula } from 'services/EVAEditGridService'

const GridContent = ({
        tableHeader,
        treeData,
        isWithChild,
        selectedCellId,
        onEditCell,
        cellFormulaList,
        childListCellValue,
        gridGroupLevel,
        handleCellDoubleClick,
        handleCellClick,
        processFormula,
        selectedCellFormula,
        handleSelectHeader
    }) => {

    const [clickedTD, setClickedTD] = useState('')

    useEffect(() => {
    },[selectedCellId])

    useEffect(() => {
    },[cellFormulaList])


    
    const handleClickedTD = (tdId) => {
        setClickedTD(tdId)
    }
    const RenderGridView = ({ parent, childListCellValue, handleCellDoubleClick, 
        handleCellClick, selectedCellId, gridGroupLevel, onEditCell, handleClickedTD,
        clickedTD, selectedCellFormula }) => {
        
        const [parsedFormula, setParsedFormula] = useState([])
        useEffect(() => {
            if(onEditCell) {
                const parsedFormula = NewParseFormula(selectedCellFormula)
                setParsedFormula(parsedFormula)
            }
        },[selectedCellFormula])

        if(!parent) return (<></>)

        // console.log(parent)
        // try {
            if (parent.id.includes("grand-total")) {
                // console.log(parent)
                return (
                    <>
                        <tr>
                            <td className='tableDataIndex'></td>
                            {parent.value.map((item, index) => (
                                <EditableCell
                                    parentId={parent.id}
                                    sqlId={parent.sqlId}
                                    colIndex={index}
                                    cellValue={item}
                                    childListCellValue={childListCellValue}
                                    handleCellDoubleClick={handleCellDoubleClick}
                                    handleCellClick={handleCellClick}
                                    selectedCellId={selectedCellId}
                                    cellFormulaList={cellFormulaList}
                                    processFormula={processFormula}
                                    onEditCell={onEditCell}
                                    handleClickedTD={handleClickedTD}
                                    clickedTD={clickedTD}
                                    selectedCellFormula={parsedFormula}
                                />
                            ))}
                        </tr>
                    </>
                );
            }
            if (parent.id.includes("-group-")) {
                // console.log(parent)
                return (
                    <>
                        <tr>
                            <td className='tableDataIndex'>{!parent.id.includes('-TotalRow') && parent.id.split("-")[0]}</td>
                            <td colSpan={tableHeader.length}></td>
                        </tr>
                        {parent.children && Array.isArray(parent.children) &&
                            <>
                                {parent.children.map((child, index) => ( 
                                    <tr>
                                        <td className='tableDataIndex'>{!child.id.includes('-TotalRow') && child.id.split("-")[0]}</td>
                                        {child.value.map((item, index) => (
                                            <EditableCell
                                                parentId={child.id}
                                                sqlId={child.sqlId}
                                                colIndex={index}
                                                cellValue={item}
                                                childListCellValue={childListCellValue}
                                                handleCellDoubleClick={handleCellDoubleClick}
                                                handleCellClick={handleCellClick}
                                                selectedCellId={selectedCellId}
                                                cellFormulaList={cellFormulaList}
                                                processFormula={processFormula}
                                                onEditCell={onEditCell}
                                                handleClickedTD={handleClickedTD}
                                                clickedTD={clickedTD}
                                                selectedCellFormula={parsedFormula}
                                            />
                                        ))}
                                    </tr>
                                ))}
                            </>
                        }
                    </>
                );
            }
            if (parent.id.includes("-child-")) {
                return (
                    <tr>
                        <td className='tableDataIndex'>{!parent.id.includes('-TotalRow') && parent.id.split("-")[0]}</td>
                        {parent.value && Array.isArray(parent.value) ? (
                            parent.value.map((item, index) => (
                                // <td key={index}>{item}</td>
                                <EditableCell 
                                    parentId={parent.id}
                                    sqlId={parent.sqlId}
                                    colIndex={index}
                                    cellValue={item}
                                    childListCellValue={childListCellValue}
                                    handleCellDoubleClick={handleCellDoubleClick}
                                    handleCellClick={handleCellClick}
                                    selectedCellId={selectedCellId}
                                    cellFormulaList={cellFormulaList}
                                    processFormula={processFormula}
                                    onEditCell={onEditCell}
                                    handleClickedTD={handleClickedTD}
                                    clickedTD={clickedTD}
                                    selectedCellFormula={parsedFormula}
                                />
                            ))
                        ) : (
                            <td colSpan={tableHeader.length}></td>
                        )}
                    </tr>
                );
            }
            if (parent.id.includes("-sub-")) {
                return (
                    <>
                        <tr>
                            <td className='tableDataIndex'>{!parent.id.includes('-TotalRow') && parent.id.split("-")[0]}</td>
                            {parent.value && Array.isArray(parent.value) ?
                            <>
                                {parent.value.map((item, childIndex) => (
                                    // <td key={childIndex}>{item}</td>
                                    <EditableCell
                                        parentId={parent.id}
                                        sqlId={parent.sqlId}
                                        colIndex={childIndex}
                                        cellValue={item}
                                        childListCellValue={childListCellValue}
                                        handleCellDoubleClick={handleCellDoubleClick}
                                        handleCellClick={handleCellClick}
                                        selectedCellId={selectedCellId}
                                        cellFormulaList={cellFormulaList}
                                        processFormula={processFormula}
                                        onEditCell={onEditCell}
                                        handleClickedTD={handleClickedTD}
                                        clickedTD={clickedTD}
                                        selectedCellFormula={parsedFormula}
                                    />
                                ))}
                            </>
                            :
                            <td colSpan={tableHeader.length}></td>}
                        </tr>
                        {parent.children && parent.children.length > 0 && parent.children.map((child, index) => (
                            <>
                            {child.value && Array.isArray(child.value) &&
                            <tr key={index}>
                                <td className='tableDataIndex'>{!child.id.includes('-TotalRow') && child.id.split("-")[0]}</td>
                                    <>
                                    {child.value.map((item, childIndex) => (
                                        // <td key={childIndex}>{item}</td>
                                        <EditableCell
                                            parentId={child.id}
                                            sqlId={child.sqlId}
                                            colIndex={childIndex}
                                            cellValue={item}
                                            childListCellValue={childListCellValue}
                                            handleCellDoubleClick={handleCellDoubleClick}
                                            handleCellClick={handleCellClick}
                                            selectedCellId={selectedCellId}
                                            cellFormulaList={cellFormulaList}
                                            processFormula={processFormula}
                                            onEditCell={onEditCell}
                                            handleClickedTD={handleClickedTD}
                                            clickedTD={clickedTD}
                                            selectedCellFormula={parsedFormula}
                                        />
                                    ))}
                                    </>
                            </tr>}

                            {child.children && 
                                <>
                                <tr>
                                    <td className='tableDataIndex'>{!child.id.includes('-TotalRow') && child.id.split("-")[0]}</td>
                                    <td colSpan={tableHeader.length}></td>
                                </tr>
                              
                                    {child.children.map((c, index) => (
                                          <tr>
                                        <td className='tableDataIndex'>{!c.id.includes('-TotalRow') && c.id.split("-")[0]}</td>
                                        {c.value.map((item, cIndex) => (
                                            <EditableCell 
                                                parentId={c.id}
                                                sqlId={c.sqlId}
                                                colIndex={cIndex}
                                                cellValue={item}
                                                childListCellValue={childListCellValue}
                                                handleCellDoubleClick={handleCellDoubleClick}
                                                handleCellClick={handleCellClick}
                                                selectedCellId={selectedCellId}
                                                cellFormulaList={cellFormulaList}
                                                processFormula={processFormula}
                                                onEditCell={onEditCell}
                                                handleClickedTD={handleClickedTD}
                                                clickedTD={clickedTD}
                                                selectedCellFormula={parsedFormula}
                                            />
                                        ))}
                                        </tr>
                                    ))}
                                </>
                            }
                            </>
                        ))}
                    </>
                );
            }
            if (parent.id.includes("-parent-")) {
                const parentId = parent.id.split("-")[0]
                // console.log(parent)
                return (
                    <>
                        <tr className={`${parent.id.includes('New Row') && 'addedRow'}`}>
                            <td className='tableDataIndex'>{parentId}</td>
                            {parent.value && Array.isArray(parent.value) ?
                            <>
                                {parent.value.map((item, childIndex) => (
                                    <EditableCell
                                        parentId={parent.id}
                                        sqlId={parent.sqlId}
                                        colIndex={childIndex}
                                        cellValue={item}
                                        childListCellValue={childListCellValue}
                                        handleCellDoubleClick={handleCellDoubleClick}
                                        handleCellClick={handleCellClick}
                                        selectedCellId={selectedCellId}
                                        cellFormulaList={cellFormulaList}
                                        processFormula={processFormula}
                                        onEditCell={onEditCell}
                                        handleClickedTD={handleClickedTD}
                                        clickedTD={clickedTD}
                                        selectedCellFormula={parsedFormula}
                                    />
                                ))}
                            </>
                            :
                            <td colSpan={tableHeader.length}></td>
                            }
                        </tr>
                        {parent.children && parent.children.length > 0 && parent.children.map((sub, subIndex) => (
                            <React.Fragment key={subIndex}>
                                
                                {sub.children && sub.children.length > 0 && (
                                    <>
                                        <tr className={`${sub.id.includes('New Row') ? 'addedRow' : ''}`}>
                                            <td className="tableDataIndex">{!sub.id.includes('-TotalRow') && sub.id.split("-")[0]}</td>
                                            <td colSpan={tableHeader.length}></td>
                                        </tr>
                                        {sub.children.map((child, childIndex) => (
                                            <React.Fragment key={childIndex}>
                                                <tr className={`${child.id.includes('New Row') ? 'addedRow' : ''}`}>
                                                    <td className="tableDataIndex">{!child.id.includes('-TotalRow') && child.id.split("-")[0]}</td>
                                                    {child.value && Array.isArray(child.value) && (
                                                        child.value.map((item, itemIndex) => (
                                                            <EditableCell
                                                                key={itemIndex}
                                                                parentId={child.id}
                                                                sqlId={child.sqlId}
                                                                colIndex={itemIndex}
                                                                cellValue={item}
                                                                childListCellValue={childListCellValue}
                                                                handleCellDoubleClick={handleCellDoubleClick}
                                                                handleCellClick={handleCellClick}
                                                                selectedCellId={selectedCellId}
                                                                cellFormulaList={cellFormulaList}
                                                                processFormula={processFormula}
                                                                handleClickedTD={handleClickedTD}
                                                                clickedTD={clickedTD}
                                                                onEditCell={onEditCell}
                                                                selectedCellFormula={parsedFormula}
                                                            />
                                                        ))
                                                    )}
                                                    {child.children && Array.isArray(child.children) && (
                                                        <td colSpan={tableHeader.length}></td>
                                                    )}
                                                </tr>

                                                {child.children && Array.isArray(child.children) && (
                                                    <>
                                                        {child.children.map((item, itemIndex) => (
                                                            <tr key={`child-${child.id}-${itemIndex}`}>
                                                            <React.Fragment key={itemIndex}>
                                                                <td className="tableDataIndex">{!item.id.includes('-TotalRow') && item.id.split("-")[0]}</td>
                                                                {item.value && Array.isArray(item.value) && (
                                                                    item.value.map((valueItem, valueIndex) => (
                                                                        <EditableCell
                                                                            key={valueIndex}
                                                                            parentId={item.id}
                                                                            sqlId={item.sqlId}
                                                                            colIndex={valueIndex}
                                                                            cellValue={valueItem}
                                                                            childListCellValue={childListCellValue}
                                                                            handleCellDoubleClick={handleCellDoubleClick}
                                                                            handleCellClick={handleCellClick}
                                                                            selectedCellId={selectedCellId}
                                                                            cellFormulaList={cellFormulaList}
                                                                            processFormula={processFormula}
                                                                            handleClickedTD={handleClickedTD}
                                                                            clickedTD={clickedTD}
                                                                            onEditCell={onEditCell}
                                                                            selectedCellFormula={parsedFormula}
                                                                        />
                                                                    ))
                                                                )}
                                                            </React.Fragment>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </>
                                )}

    
                                {sub.children && sub.children.length === 0 && 
                                    <>
                                        <tr>
                                            <td className='tableDataIndex'>{!sub.id.includes('-TotalRow') && sub.id.split("-")[0]}</td>
                                            <td colSpan={tableHeader.length}></td>
                                        </tr>
                                    </>
                                }
    
                                {sub.value && sub.value.length > 0 && 
                                    <tr key={subIndex} className={`${sub.id.includes('New Row') && 'addedRow'}`}>
                                        <td className='tableDataIndex'>{!sub.id.includes('-TotalRow') && sub.id.split("-")[0]}</td>
                                        {sub.value.map((child, childIndex) => (
                                            <EditableCell
                                                key={childIndex}
                                                parentId={sub.id}
                                                sqlId={sub.sqlId}
                                                colIndex={childIndex}
                                                cellValue={child}
                                                childListCellValue={childListCellValue}
                                                handleCellDoubleClick={handleCellDoubleClick}
                                                handleCellClick={handleCellClick}
                                                selectedCellId={selectedCellId}
                                                cellFormulaList={cellFormulaList}
                                                processFormula={processFormula}
                                                handleClickedTD={handleClickedTD}
                                                clickedTD={clickedTD}
                                                onEditCell={onEditCell}
                                                selectedCellFormula={parsedFormula}
                                            />
                                        ))}
                                    </tr>
                                }
                            </React.Fragment>
                        ))}
                    </>
                );
            }
            if(parent.id.includes("-grandParent-")) {
                const parentId = parent.id.split("-")[0]

                return (
                <tr className={`${parent.id.includes('New Row') && 'addedRow'}`}>
                            <td className='tableDataIndex'>{parentId}</td>
                            {parent.value && Array.isArray(parent.value) ?
                            <>
                                {parent.value.map((item, childIndex) => (
                                    <EditableCell
                                        parentId={parent.id}
                                        sqlId={parent.sqlId}
                                        colIndex={childIndex}
                                        cellValue={item}
                                        childListCellValue={childListCellValue}
                                        handleCellDoubleClick={handleCellDoubleClick}
                                        handleCellClick={handleCellClick}
                                        selectedCellId={selectedCellId}
                                        cellFormulaList={cellFormulaList}
                                        processFormula={processFormula}
                                        onEditCell={onEditCell}
                                        handleClickedTD={handleClickedTD}
                                        clickedTD={clickedTD}
                                        selectedCellFormula={parsedFormula}
                                    />
                                ))}
                            </>
                            :
                            <td colSpan={tableHeader.length}></td>
                            }
                        </tr>
                )
            }
            return null
        // }catch(error){
        //     console.error(error)
        //     return  (<></>)
        // }
    };
    
    return (
        <table>
            <thead>
                <tr class='stickyHeader0'>
                    <td></td>
                    {tableHeader.map((header, headerIndex) => (
                        <td key={headerIndex}  className='tableDataIndex'>{TableHeader(headerIndex)}</td>
                    ))}
                </tr>
                <tr class='stickyHeader1'>
                    <td></td>
                    {tableHeader.map((header, headerIndex) => (
                        <td key={headerIndex} className='colHeader' onClick={() => handleSelectHeader(gridGroupLevel + headerIndex, header)}>{header}</td>
                    ))}
                </tr>
            </thead>
            <tbody>
                {treeData && treeData.length > 0 && treeData.map((parent, index) => (
                    <RenderGridView
                        key={index}
                        parent={parent}
                        childListCellValue={childListCellValue}
                        handleCellDoubleClick={handleCellDoubleClick}
                        handleCellClick={handleCellClick}
                        selectedCellId={selectedCellId}
                        onEditCell={onEditCell}
                        gridGroupLevel={gridGroupLevel}
                        handleClickedTD={handleClickedTD}
                        clickedTD={clickedTD}
                        selectedCellFormula={selectedCellFormula}
                    />
                ))}
            </tbody>
        </table>
    )
}

export default GridContent