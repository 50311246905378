import React, { useCallback, useContext, useEffect, useState } from 'react'
import { deleteAllEVAHistoryItem, deleteEVAHistoryItem, fetchEVAMessagesPerUser, getCustomGroups } from '../services/mongoDB'
import { evaThemeLighter, generateUUID } from '../services/EVAResponseService'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { IconTrash } from './ui/icon';
import { MainContext } from 'context/mainContext';
import moment from "moment";

const Group = ({ selectedGroupId, theme, group, notificationCount, selectGroup, groupId , grpMessages}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [history, getHistory] = useState([])
    const [formattedHistory, getFormattedHistory] = useState(null)
    const [deleteItemCount, setDeleteItemCount] = useState(0)
    const [newuuid, setNewuuid] = useState(null)
    const [selecteduuid, setSelecteduuid] = useState(null)
    const [alert, setAlert] = useState(false);
    const [activeGroup, setActiveGroup] = useState(null)
    const [grpMessage, setGrpMessage] = useState(null)
    const { bgColor, textColor, selectedClientID, selectedClient, userDetails, userChatNotification, setSelectedGroupID, selectedGroupID } = useContext(MainContext);

    useEffect(() => {

        setGrpMessage(getGroupMesageDetails(groupId))

    },[userChatNotification, grpMessages])

    const getGroupMesageDetails = (groupId) => {
        if (grpMessages) {
            const notification = grpMessages.filter((item) => item.groupId === groupId);
            if (notification) return notification[0];
            else return null;
        }
        return null;
    }

    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    function limitText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.substring(0, maxLength) + "...";
        }
    }
    function formatText(text) 
    {
        if(text.includes('```json')) return 'shared EVA report'
        return text
    }
    return (
        <div
            key={group._id}
            className='client-group-list-item'
            style={{
            ...(selectedGroupId === group._id && evaThemeLighter(80, theme)),
            }}
            onClick={() => { selectGroup(group._id) }}>
                {notificationCount > 0 &&
                    <div className='notificationCount' style={{ background: bgColor, color: textColor}}>
                        <span>{notificationCount}</span>
                    </div>
                }
                <div style={{ width: '100%'}}>
                    {grpMessage && grpMessage !== undefined ? 
                    <>
                        <div className='messageDetails'>
                            <span className='grpName'>{capitalize(group.GroupName)}</span>
                            <span className='grpTime'>{moment(grpMessage.createdAt).fromNow()}</span>
                        </div>
                        <span className='grpHistory'>
                            <strong>{capitalize(grpMessage.user.name)}</strong>: {' '}
                            {limitText(formatText(grpMessage.text), 35)}
                        </span>
                    </>
                    :
                        <>
                            {capitalize(group.GroupName)}
                        </>
                    }
                </div>
        </div>
    );
    
}



export default Group