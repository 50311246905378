import { IconEdit } from 'chat-components/ui/icon'
import { IconTrash } from 'chat-components/ui/icon'
import { MainContext } from 'context/mainContext'
import CustomTooltip from 'customComponents/customComponents'
import React, { useContext } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const Group = ({data, ...props}) => {
    const { bgColor, textColor } = useContext(MainContext);
    // console.log(data)
  return (
    <>
        <Col lg="2" xl="2" md="2" sm="6" key={data._id}>
            <Card 
                className="groupCardList" 
                // style={handleActiveDasboard(data._id)}
                >
                <CardBody className="d-flex flex-column p-2">
                    <Row>
                        <Col>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <h3>{data.GroupName}</h3>
                                <div className='itemControlsEdit' onClick={() => props.addEditCustomGroup(data, true)}>
                                    <IconEdit />
                                </div>
                                <div className='deleteDashboardBtn'>
                                    <IconTrash  onClick={() => props.showDeleteConfirmation(data)} />
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div 
                                className="btn-showDetails"
                                style={{ background: bgColor, color: textColor }}
                                onClick={() => props.addEditCustomGroup(data, true)}
                                >View Group Members</div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    </>
  )
}
export default Group