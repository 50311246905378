import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import CustomTooltip from "customComponents/customComponents";
import "./style.css";
import { MainContext } from "context/mainContext";

const ClientReportsCard = ({ data, i, ...props }) => {
  const { userDetails } = useContext(MainContext);
  const [isAdvisor, setIsAdvisor] = useState(false);

  useEffect(() => {
    if (userDetails?.User?.UserAccess) {
      if ([1, 2, 3, 4].includes(userDetails?.User?.UserAccess)) {
        setIsAdvisor(true);
      }
    }
  }, [userDetails])

  return (
    <Col lg="4" md="6" sm="12">
      <Card className="zoom">
        <CardBody className="d-flex flex-column">
          {/*Uncommented by Joef: Need to show if report is live or not*/}
          <div
            className="traffic-light-blk"
            style={{
              position: "absolute",
              left: "10px",
              top: "20px",
            }}
          >
            {props.showTrafficLight(data.Status, i)}
          </div>
          <div style={{ position: "absolute", right: "30px", top: "20px" }}>
            <Row>
              {props.userDetails.User.UserAccess <= 4 ? (
                data.Status === 4 || data.Status === 5 ? (
                  <Col className="px-1">
                    <CustomTooltip
                      i={i}
                      id="settings"
                      message="Settings"
                      className="fas fa-cog fa-sm"
                      onClick={() => props.openSettingsModal(data)}
                    />
                  </Col>
                ) : null
              ) : null}
              {props.userDetails.User.UserAccess <= 4 ?
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="download"
                    message="Download Report"
                    className="fas fa-download fa-xs"
                    onClick={() => props.downloadPbixReport(data, null, true)}
                  />
                </Col>
                : null
              }
              {props.userDetails.User.UserAccess <= 4 ?
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="edit"
                    message="Edit Report Contents"
                    className="fas fa-pen fa-xs"
                    onClick={() => props.openViewReport(data, null, true)}
                  />
                </Col>
                : null
              }
              {props.userDetails.User.UserAccess <= 4 ?
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="duplicate"
                    message="Duplicate Report"
                    className="fas fa-clone fa-xs"
                    onClick={() => props.clonePBIReport(data)}
                  />
                </Col>
                : null
              }
              {props.userDetails.User.UserAccess <= 4 ?
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="deletePBI"
                    message="Delete Report"
                    className="fas fa-times fa-sm"
                    onClick={() => props.deletePBIReport(data)}
                  />
                </Col>
                : null
              }
              {/* <Col className="ml--3">
                <CustomTooltip
                  i={i}
                  id="viewReports"
                  message="View Reports"
                  className="ni ni-folder-17 text-sm"
                  onClick=""
                />
              </Col> */}
              {/*
              Trello Card - Edited by Paul: https://trello.com/c/8PMrDtza/286-reports-hide-duplicate-edit
              {props.userDetails.User.UserAccess <= 4 ? (
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="duplicate"
                    message="Duplicate Report"
                    className="fas fa-clone fa-xs"
                    onClick={() => props.openReportPageSelection(data)}
                  />
                </Col>
              ) : null}
              {props.userDetails.User.UserAccess <= 4 ? (
                <Col className="px-1">
                  <CustomTooltip
                    i={i}
                    id="edit"
                    message="Edit Report"
                    className="fas fa-pen fa-xs"
                    onClick={() => props.openReportUpdate(data)}
                  />
                </Col>
              ) : null} */}
              {/* Commented by Joef: There's no point for this since we're skipping the Red traffic light
              {props.userDetails.User.UserAccess <= 4 ? (
                <Col className="pl-1">
                  <CustomTooltip
                    i={i}
                    id="delete"
                    message="Delete Report"
                    className="fas fa-times fa-sm"
                    onClick={() => props.handleDelete(data)}
                  />
                </Col>
              ) : null} */}
            </Row>
          </div>

          <div className="pt-3 text-center"></div>

          <Row className="">
            <Col sm="12">
              <hr className="my-3" />
            </Col>

            <Col sm="12" className="text-center " style={{ height: "90px" }}>
              <h4>{data.Name}</h4>
              {/*  Commented by Joef: I removed the created by as well. I don't think there's a way for us to know who created this on power bi
              <p className="text-sm mb-0 h5">
                Created By:
                {data.LastAddedBy && data.LastAddedBy.FirstName
                  ? data.LastAddedBy.FirstName
                  : "N/A"}
              </p> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center">
              <span className="btn-inner--icon ml-1">
                <i className="ni ni-single-02" />
              </span>
              <span className="text-sm text-muted ml-3">
                Last Submitted By:{" "}
                {(data.LastSubmittedBy
                  ? data.LastSubmittedBy.FirstName
                  : "N/A") || "N/A"}
              </span>
            </Col>
            {/* Commented by Joef: Hide this as requested
            <Col xs="12" className="text-center">
              <span className="btn-inner--icon ml-1">
                <i className="ni ni-single-02" />
              </span>
              <span className="text-sm text-muted ml-3">
                Last Published By:{" "}
                {(data.LastPublishedBy
                  ? data.LastPublishedBy.FirstName
                  : "N/A") || "N/A"}
              </span>
            </Col> */}
          </Row>
        </CardBody>
        <div
          className="card-footer border-0 px-3 mt-3"
          style={{ position: "relative" }}
        >
          <div className="mb-2" style={{ position: "absolute", top: -15 }}>
            {
              isAdvisor &&
                data.Details &&
                data.Details.Settings &&
                data.Details.Settings.RLS &&
                data.Details.Settings.Role ? (
                <Button
                  style={{
                    color: `${props.bgColor}`,
                    borderColor: `${props.bgColor}`,
                  }}
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    props.openSingleUserSelectionModal(data); //Uncommented by Joef: RLS Fix
                  }}
                >
                  Preview RLS
                </Button>
              ) : null}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                  }`,
                color: `${props.textColor === "#000" ? "#fff" : props.textColor
                  }`,
                borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                  }`,
                borderRadius: "50px",
              }}
              size="sm"
              outline
              type="button"
              onClick={() => props.openViewReport(data)}
            >
              View Report
            </Button>
            {props.userDetails.User.UserAccess <= 4
              ? props.submitButton(data.Status, data.id, data)
              : null}
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ClientReportsCard;
