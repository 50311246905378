import { saveNotification } from "./mongoDB";

const SendMultiDeviceNotification = async (data) => {
    // const senderToken = (await AsyncStorage.getItem("deviceExpoTokenId")).trim();
    const Tokens = data.tokens
    const nonExpoTokens = Tokens.filter(token => !token.startsWith("ExponentPushToken["));
    // const filteredTokens = Tokens.filter(token => token.trim() !== senderToken.replace(/"/g,''));
    if (nonExpoTokens && nonExpoTokens.length > 0) {
      const { ClientID, Name, type, Id, groupId } =  data.data
           
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append(
        'Authorization',
        'key=AAAAi0mguKY:APA91bH80RHFXltDy3Gpy3n-mCSK7TPr7h69D9vBaJvlfWJwems1FCGp5oApQnglQxRlJjyHrByjr4VTkobiPFqSxdwQizAgzoddlz4JaLybKPY_8crEjWsWnRgdyJn9PH8xqWf6WK3s',
      );

      var raw = JSON.stringify({
        data: { ClientID, Name , Id, groupId, type: type, createdAt: new Date()},
        notification: {
          body: data.notification.body,
          title: data.notification.title
        },
        apns: data.apns,
        android: data.android,
        registration_ids: nonExpoTokens,
        createdAt: new Date(),
        type: type
      });
      // console.log(data)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      try {
        const response = await fetch('https://fcm.googleapis.com/fcm/send', requestOptions);
        const result = await response.text();
        // saveNotification(JSON.parse(raw))
        // console.log(result);
      } catch (error) {
          console.log('error', error);
      }
    }
  };
  
  export default SendMultiDeviceNotification;