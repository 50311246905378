import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";

import "./styles.css";

const ToggleSwitch = ({ mfaSettings, ...props }) => {
  const [isToggle, setIsToggle] = useState(mfaSettings);
  const [modal, setModal] = useState(false);
  const [, setMessage] = useState("");

  useEffect(() => {
    // console.log('mfaSettings', mfaSettings)
    // console.log('isToggle', isToggle)
    if (mfaSettings?.mfaSettings === true) {
      setModal(false);
      setIsToggle(true);
    } else {
      setIsToggle(false);
    }
  }, [mfaSettings, isToggle]);

  const onChangeSettings = () => {
    // console.log(mfaSettings)
    if (mfaSettings?.mfaSettings === true) {
      setModal(true);
      setIsToggle(false);
    }
  };

  const closeModalHandler = () => {
    setModal(false);
    setIsToggle(!isToggle);
  };

  const submitModalHandler = () => {
    const mfaUpdateSettings = async () => {
      try {
        const details = await fetch("/api/auth/mfaUpdateSetup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ MFASettings: isToggle }),
        });
        const data = await details.json();
        if (data.success) {
          setMessage(data.message);
          setModal(false);
          setIsToggle(false);
          window.location.reload(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    mfaUpdateSettings();
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Turn Off Multi Factor Authentication?
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            This action is only recommended if you've lost your phone.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            onClick={submitModalHandler}
          >
            Confirm
          </Button>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <label className="switch-container">
        {/* <input type="checkbox" checked={isToggle} onChange={onChangeSettings} />
        <span className="slider" />
        {!isToggle ? (
          <span className="toggle-off">OFF</span>
        ) : (
          <span className="toggle-on">ON</span>
        )} */}
        <label className="custom-toggle custom-toggle-success mr-1">
          <input
            checked={isToggle}
            type="checkbox"
            onClick={onChangeSettings}
            id="mfa"
            name="MFA"
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="Off"
            data-label-on="On"
          />
        </label>
      </label>
    </>
  );
};

export default ToggleSwitch;
