import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useHistory } from "react-router";

function NoDashboardFound() {
    const history = useHistory()
    const reDirectToPage = (val) => {
        history.push(`/client/templates/`);
    };

    const handleRedirect = (page) => {
        if(page === 'integrations') return history.push(`/client/thirdpartyapplication`);
        if(page === 'traningvideos') return history.push(`/client/traningvideos`);
    }
    return (
        <>
            {/* <div style={{ padding: '20px 0px', textAlign: 'center' }}>
                No dashboard was found.
            </div> */}
            <div className='noDashboardFound'>
            <Container className="mt-5">
                <Row className="mb-4">
                    <div className='col'>
                        <h1 className="text-center">No Dashboards?</h1>
                        <p className="text-center">You have not yet created any Dashboards with EVA. Let's get you started!</p>
                    </div>
                </Row>
                <Row className="mb-3 text-center">
                    <div className='col'>
                        <h2>Step 1</h2>
                        <p>Add Data Integrations that connect with EVA (Xero, XPM, Workflow Max) <strong style={{marginLeft:'5px', color:'#5e72e4', cursor: 'pointer', borderBottom: '2px solid', fontWeight: '600'}} onClick={() => handleRedirect('integrations')}>Go to Integrations Page</strong></p>
                    </div>
                </Row>
                 <Row className="mb-3 text-center">
                    <div className='col'>
                        <h2>Step 2</h2>
                        <p>Create a Dashboard Template <a color="primary" href="#" onClick={reDirectToPage}>Create Dashboard Template</a></p>
                    </div>
                </Row>
                 <Row className="mb-3 text-center">
                    <div className='col'>
                        <h2>Step 3</h2>
                        <p>Edit Dashboard, Ask EVA to improve, add custom reports, and make it yours</p>
                    </div>
                </Row>
                 <Row className="mb-3 text-center">
                    <div className='col'>
                        <h2>Step 4</h2>
                        <p>Invite Colleagues to view reporting</p>
                    </div>
                </Row>
                <Row className="mb-3 text-center">
                    <div className='col'>
                        <h2>Step 5</h2>
                        <p>Collaborate - continue to build out more dashboards, interact with reporting with team inside chat.</p>
                    </div>
                </Row>
                <Row className="mb-3 text-center">
                    <div className='col'>
                        <p style={{ fontWeight: 600 }}>Learn more in depth with this training video from our CEO:</p>
                        <p><strong style={{marginLeft:'5px', color:'#5e72e4', cursor: 'pointer', borderBottom: '2px solid', fontWeight: '600'}} onClick={() => handleRedirect('traningvideos')}>Watch Training Video</strong></p>
                    </div>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default NoDashboardFound