import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Dropdown, Input, Table, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import CustomSpinner from "components/Misc/CustomSpinner";
import { SyncLoader } from "react-spinners";
import CustomTooltip from "customComponents/customComponents";

const CustomTaskRateModal = ({ handlesetShowCustomTaskRate, dbDetails, CustomTaskRates, bgColor, textColor, selectedStaff, fetchStaffList, ...props }) => {
  const [taskList, setTaskList] = useState([])
  const [filteredTaskList, setFilteredTaskList] = useState([])
  const [loading, setLoading] = useState(false)
  const [numericInput, setNumericInput] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [taskRateValues, setTaskRateValues] = useState([]);
  const [currentTaskToAdd, setCurrentTaskToAdd] = useState({
    ID: '',
    Task: '',
    BaseRate: 0,
    BillableRate: 0
  })
  const [showAddCustomTaskRateRow, setShowAddCustomTaskRate] = useState(false)
  const [baseRateInput, setBaseRateInput] = useState(0)
  const [billableRateInput, setBillableRateInput] = useState(0)
  const [taskInput, setTaskInput] = useState("")
  const [alert, setAlert] = useState(null);
  const [editTaskRateRowValues, setEditTaskRateRowValues] = useState(false)
  const [taskToBeEdited, setTaskToBeEdited] = useState()
  const [taskRowToBeEdited, setTaskRowToBeEdited] = useState(null)
  const [submitting, setSubmitting] = useState(false)


  useEffect(() => {
    setLoading(true)
    const { dbName, tbName, orgId, dw, sTbName, taskRateTbName } = dbDetails;
    let fetchUrl = `/api/clientService/getTaskList?databaseName=${dbName}&dw=${dw}&tableName=${taskRateTbName}&sTbName=${sTbName}&orgID=${orgId}`;
    fetch(fetchUrl, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response.error) {
          setLoading(false)
          return console.log(response.error)
        }

        setLoading(false)
        let parsedRates;
        if (CustomTaskRates) {
          parsedRates = JSON.parse(CustomTaskRates);
        } else {
          parsedRates = []
        }
        setTaskRateValues(parsedRates)
        // this will remove the customRates values from the task list selections
        let taskList = response.data.sort((a, b) => a.Name.localeCompare(b.Name)).filter(res => {
          return !parsedRates.some(filterObj => filterObj.Task === res.Name)
        })
        setTaskList(response.data.sort((a, b) => a.Name.localeCompare(b.Name)))
        setFilteredTaskList(taskList)
        // console.log(parsedRates)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  }, [dbDetails])

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const showTaskList = () => {
    return (
      filteredTaskList.length && filteredTaskList.map((tl, i) => (
        <DropdownItem key={i} onClick={() => handleSelectTaskFromDropdown(tl, i)}>
          {tl.Name}
        </DropdownItem>
      ))
    )
  }

  const handleTaskInput = (e) => {
    let value = e.target.value
    // console.log(value)
    let updatedCurrentValue = currentTaskToAdd

    if (e.target.id === 'baseRate') {
      value = value.replace(/^0+/, '');
      setBaseRateInput(Number(value))
      updatedCurrentValue.BaseRate = Number(value)
    }
    if (e.target.id === 'billableRate') {
      value = value.replace(/^0+/, '');
      setBillableRateInput(Number(value))
      updatedCurrentValue.BillableRate = Number(value)
    }
    setCurrentTaskToAdd(updatedCurrentValue)
  }

  const handleSelectTaskFromDropdown = (value) => {
    // console.log(value)
    setTaskInput(value.Name)
    let newCurrentTaskToAdd = currentTaskToAdd
    newCurrentTaskToAdd.Task = value.Name
    newCurrentTaskToAdd.ID = value.ID
    setCurrentTaskToAdd(newCurrentTaskToAdd)
  }

  const handleDeleteTask = (task, i) => {
    // console.log(taskRateValues[i])
    let newTaskRateValues = taskRateValues.filter(trv => trv.Task !== taskRateValues[i].Task)
    setTaskRateValues(newTaskRateValues)
    let mainTaskListSelection = taskList.filter(tl => tl.Name === taskRateValues[i].Task)
    // console.log(mainTaskListSelection)
    let newFilteredTaskList = filteredTaskList
    newFilteredTaskList.push(mainTaskListSelection[0])
    setFilteredTaskList(newFilteredTaskList.sort((a, b) => a.Name.localeCompare(b.Name)))
  }

  const insertToCurrentTaksValue = () => {
    // console.log(taskRateValues)
    if (currentTaskToAdd.Task === "") return;
    let newTaskRateValues = taskRateValues
    if (editTaskRateRowValues) {
      newTaskRateValues.splice(taskRowToBeEdited, 1, currentTaskToAdd)
    } else {
      newTaskRateValues.push(currentTaskToAdd)
    }
    // console.log(newTaskRateValues)
    setTaskRateValues(newTaskRateValues)
    setCurrentTaskToAdd({
      ID: '',
      Task: '',
      BaseRate: 0,
      BillableRate: 0
    })
    setTaskInput('')
    setBaseRateInput(0)
    setBillableRateInput(0)
    setShowAddCustomTaskRate(false)
    setEditTaskRateRowValues(false)
    setTaskRowToBeEdited(null)
    // console.log(currentTaskToAdd)
    let newTaskList = filteredTaskList.filter(tl => tl.Name !== currentTaskToAdd.Task)
    setFilteredTaskList(newTaskList)
  }

  const showAddCustomTaskRate = () => {
    if (editTaskRateRowValues || submitting) return;
    setShowAddCustomTaskRate(!showAddCustomTaskRateRow)
  }

  const handleEditTask = (value, i) => {
    if (editTaskRateRowValues || showAddCustomTaskRateRow || submitting) return;
    // console.log(value)
    let mainTaskListSelection = taskList.filter(tl => tl.Name === value.Task)
    // console.log(mainTaskListSelection)
    setCurrentTaskToAdd({
      ID: value.ID ?? mainTaskListSelection[0].ID,
      Task: value.Task,
      BaseRate: value.BaseRate,
      BillableRate: value.BillableRate
    })
    setTaskToBeEdited(value)
    setTaskRowToBeEdited(i)
    setTaskInput(value.Task)
    setBaseRateInput(Number(value.BaseRate))
    setBillableRateInput(Number(value.BillableRate))
    let newFilteredTaskList = filteredTaskList
    newFilteredTaskList.push(mainTaskListSelection[0])
    const uniqueMap = {};
    const uniqueArray = [];
    newFilteredTaskList.forEach(obj => {
      const propValue = obj['Name'];
      if (!uniqueMap[propValue]) {
        uniqueMap[propValue] = true;
        uniqueArray.push(obj);
      }
    })
    setFilteredTaskList(uniqueArray.sort((a, b) => a.Name.localeCompare(b.Name)))
    setEditTaskRateRowValues(true)
  }

  const handleSubmit = () => {
    setSubmitting(true)
    const payloadData =
    {
      "id": selectedStaff.id,
      "customTaskRate": JSON.stringify(taskRateValues),
      "db": dbDetails
    }
    // console.log(payloadData)
    fetch(`/api/clientService/updateStaffCustomTaskRate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payloadData)
    })
      .then((response) => response.json())
      .then((response) => {
        setSubmitting(false)
        if (response.error) {
          return setAlert(
            <ReactBSAlert
              error
              title="Error"
              onConfirm={() => setAlert(null)}
              confirmBtnBsStyle="danger"
              confirmBtnText="Ok"
              btnSize=""
            >
              Something went wrong. Please try again later.
            </ReactBSAlert>
          )
        }
        // handlesetShowCustomTaskRate(false)
        fetchStaffList()
        // console.log(response)
      })
      .catch((err) => {
        setSubmitting(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            Something went wrong. Please try again later.
          </ReactBSAlert>
        )
        console.log(err)
      })
  }

  return (
    <>
      <div className="CustomTaskRatesLayout" style={{ overflow: 'hidden' }}>
        <div className="CustomTaskRatesContainer">
          <h2>Custom Task Rates</h2>
          <div className="custom-task-rate-table-container">
            {
              loading ?
                <div className="d-flex justify-content-center align-items-center">
                  <CustomSpinner />
                </div>
                :
                filteredTaskList &&
                <>
                  <table>
                    <thead>
                      <tr>
                        <td>Task</td>
                        <td>Base Rate</td>
                        <td>Billable Rate</td>
                        <td></td> {/* Add a new column for delete */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        taskRateValues.length >= 1 ? taskRateValues?.map((tlv, i) => (
                          <>
                            <tr key={i} id={`row${i}`}>
                              <td onClick={() => handleEditTask(tlv, i)} id={`columnTask${i}`}>
                                {
                                  editTaskRateRowValues && taskRowToBeEdited === i ?
                                    <UncontrolledDropdown
                                      className="me-2"
                                      direction="down"
                                    >
                                      <DropdownToggle tag="span" caret>{taskInput ? taskInput : `Select Task`}</DropdownToggle>
                                      <DropdownMenu className="task-list-dropdown">
                                        {showTaskList()}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                    :
                                    tlv.Task
                                }
                                {/* <UncontrolledTooltip
                                  target={`columnTask${i}`}
                                  placement="top"
                                  style={{ fontSize: "smaller", padding: "13px" }}
                                >
                                  {`Click to edit.`}
                                </UncontrolledTooltip> */}
                              </td>
                              <td onClick={() => handleEditTask(tlv, i)} id={`columnBill${i}`}>
                                {
                                  editTaskRateRowValues && taskRowToBeEdited === i ?
                                    <input
                                      type="number"
                                      step="0.01"
                                      value={baseRateInput}
                                      onChange={(e) => handleTaskInput(e)}
                                      placeholder="Enter a number"
                                      id={`baseRate`}
                                    />
                                    :
                                    tlv.BaseRate
                                }
                                {/* <UncontrolledTooltip
                                  target={`columnBill${i}`}
                                  placement="top"
                                  style={{ fontSize: "smaller", padding: "13px" }}
                                >
                                  {`Click to edit.`}
                                </UncontrolledTooltip> */}
                              </td>
                              <td onClick={() => handleEditTask(tlv, i)} id={`columnBillable${i}`}>
                                {
                                  editTaskRateRowValues && taskRowToBeEdited === i ?
                                    <input
                                      type="number"
                                      step="0.01"
                                      value={billableRateInput}
                                      onChange={(e) => handleTaskInput(e)}
                                      placeholder="Enter a number"
                                      id={`billableRate`}
                                    />
                                    :
                                    tlv.BillableRate
                                }
                                {/* <UncontrolledTooltip
                                  target={`columnBillable${i}`}
                                  placement="top"
                                  style={{ fontSize: "smaller", padding: "13px" }}
                                >
                                  {`Click to edit.`}
                                </UncontrolledTooltip> */}
                              </td>
                              <td>
                                {
                                  editTaskRateRowValues && taskRowToBeEdited === i ?
                                    <i
                                      onClick={() => insertToCurrentTaksValue()}
                                      className="far fa-save"
                                      style={{
                                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                          }`,
                                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                          }`
                                      }}
                                    />
                                    :
                                    <i
                                      onClick={() => handleDeleteTask(tlv, i)}
                                      className="far fa-trash-alt"
                                    />
                                }
                              </td>
                            </tr>
                          </>
                        ))
                          : null
                      }
                      {
                        showAddCustomTaskRateRow ?
                          <tr>
                            <td>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle tag="span" caret>{taskInput ? taskInput : `Select Task`}</DropdownToggle>
                                <DropdownMenu className="task-list-dropdown">
                                  {showTaskList()}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              <input
                                type="number"
                                step="0.01"
                                value={baseRateInput}
                                onChange={(e) => handleTaskInput(e)}
                                placeholder="Enter a number"
                                id={`baseRate`}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                step="0.01"
                                value={billableRateInput}
                                onChange={(e) => handleTaskInput(e)}
                                placeholder="Enter a number"
                                id={`billableRate`}
                              />
                            </td>
                            <td>
                              <i
                                onClick={() => insertToCurrentTaksValue()}
                                className="far fa-save"
                                style={{
                                  backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`
                                }}
                              />
                            </td>
                          </tr>
                          : null
                      }
                    </tbody>
                  </table>
                  <div className="p-3">
                    <div style={{ width: '205px' }}>
                      <span onClick={() => showAddCustomTaskRate()}><h4 style={{
                        color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`, cursor: "pointer"
                      }}>{showAddCustomTaskRateRow ? `Cancel add custom task rate` : `Add custom task rate`}</h4></span>
                    </div>
                  </div>
                </>
            }
          </div>
          <div className="custom-task-rate-button-container">
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`
              }}
              disabled={(loading || submitting || showAddCustomTaskRateRow || editTaskRateRowValues) ? true : false}
            >
              {submitting ? `Saving...` : `Save Custom Task Rates`}
            </Button>
            <Button onClick={() => { handlesetShowCustomTaskRate(false) }}>Close</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomTaskRateModal