import { useEffect } from 'react';
import { useSocket } from './SocketContext';

export const useSocketRooms = (rooms) => {
const socket = useSocket();

  useEffect(() => {
    if (!socket || !rooms || rooms.length === 0) return;

    // Join all the rooms
    rooms.forEach(({ roomName, label }) => {
      if (roomName) {
        socket.emit('joinRoom', { roomName, label });
      }
    });

    return () => {
      rooms.forEach(({ roomName, label }) => {
        if (roomName) {
          socket.emit('leaveRoom', { roomName, label });
        }
      });
    };
  }, [rooms, socket]);
};
