import CustomTooltip from 'customComponents/customComponents';
import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button } from "reactstrap";

const MonthlyCostCard = ({ pbiEnabled, handleRefresh, estimatedCost, totalEstimatedCost, fetchData, selectedClient, hasGST, ...props }) => {
  return (
    <>
      <Card className="card-stats">
        <CardBody>
          <CardTitle
            tag="h5"
            className="text-uppercase mb-2"
          >
            <Row>
              <Col>
                Estimated Monthly Cost
              </Col>
              <Col className="col-auto">
                <CustomTooltip 
                  i={1}
                  id={`refresh${1}`}
                  message="Refresh"
                  className="fas fa-redo-alt fa-sm"
                  onClick={() => handleRefresh(selectedClient)}
                />
              </Col>
            </Row>
          </CardTitle>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  Client x {estimatedCost?.Client?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${estimatedCost?.Client?.cost ?? 0 * 20}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  Admin x {selectedClient ? 0 : estimatedCost?.Admin?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${selectedClient ? 0 : estimatedCost?.Admin?.cost ?? 0 * 20}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                  Add-ons:
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  EVA-enabled users x {estimatedCost?.EvaEnableUsers?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${estimatedCost?.EvaEnableUsers?.cost ?? 0 * 15}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  View-only users x {estimatedCost?.ViewOnlyUsers?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${estimatedCost?.ViewOnlyUsers?.cost ?? 0 * 3}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  Multi-client users x {estimatedCost?.MultiClientUsers?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${estimatedCost?.MultiClientUsers?.cost ?? 0 * 30}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                  Integrations x {estimatedCost?.Integrations?.count ?? 0}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${estimatedCost?.Integrations?.cost ?? 0 * 3}
                </span>
              </p>
            </Col>
          </Row>
          {
            pbiEnabled && !selectedClient ?
              <>
                <Row className="mb-1">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-0 text-left">
                      <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Microsoft Power BI Etani Platform
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-0 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                        A${estimatedCost?.PowerBIFeatures?.isEnabled ? estimatedCost?.PowerBIFeatures?.cost : 0}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-0 text-left">
                      <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Microsoft Power BI Access
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-0 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                        A${estimatedCost?.PowerBIFeatures?.isEnabled ? estimatedCost?.PowerBIFeatures?.PowerBIAccess?.cost : 0}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col xl={8} lg={8} md={8} sm={8}>
                    <p className="my-0 text-left">
                      <span style={{ fontWeight: "500", fontSize: "0.9000rem", color: "#525f7f" }}>
                        Power BI Pro Account
                      </span>
                    </p>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="my-0 text-right">
                      <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                        A${estimatedCost?.PowerBIFeatures?.PowerBIProAccount?.cost ?? 0 * 15}
                      </span>
                    </p>
                  </Col>
                </Row>
              </>
              : null
          }
          <hr />
          {
            hasGST ?
            <>
              <Row className="mb-0">
                <Col xl={8} lg={8} md={8} sm={8}>
                  <p className="my-0 text-left">
                    <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                      Subtotal
                    </span>
                  </p>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  <p className="my-0 text-right">
                    <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                      A${parseFloat(totalEstimatedCost).toFixed(2)}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="mb-0">
                <Col xl={8} lg={8} md={8} sm={8}>
                  <p className="my-0 text-left">
                    <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                      GST 10%
                    </span>
                  </p>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  <p className="my-0 text-right">
                    <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                      A${parseFloat(totalEstimatedCost * 0.10).toFixed(2)}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
            : null
          }
          <Row className="mb-0">
            <Col xl={8} lg={8} md={8} sm={8}>
              <p className="my-0 text-left">
                <span style={{ fontWeight: "600", fontSize: "0.9000rem", color: "#525f7f" }}>
                Total
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <p className="my-0 text-right">
                <span style={{ fontSize: "0.9000rem", textAlign: 'left !important' }}>
                  A${hasGST ? (totalEstimatedCost + parseFloat((totalEstimatedCost * 0.10))).toFixed(2) : totalEstimatedCost}
                </span>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default MonthlyCostCard;