import { CustomButton } from 'components/Button/CustomButton';
import React from 'react';
import {
  Modal,
  Input,
  FormGroup
} from "reactstrap";

const DuplicateReportModal = ({ ...props }) => {
  return (
    <>
      <Modal
        size="sm"
        className="modal-dialog-centered"
        isOpen={props.showDuplicateModal}
        toggle={() => props.closeDuplicateModal()}
      >
        <div className="modal-body">
          <h5 className="modal-title mb-0">
            Duplicate Report
          </h5>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="FirstName"
            >
              Report Name
            </label>
            <Input
              placeholder="Report Name"
              type="text"
              name="ReportName"
              required={true}
              id="ReportName"
              onChange={props.handleReportNameChange}
              defaultValue={
                props.selectedReport?.Name ?? ""
              }
              className="getData"
            />
            <small>
              If no report name, it will use the current report name.
            </small>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <CustomButton
            type="button"
            onClick={() => props.clonePBIReport(props.selectedReport)}
            outlineStyle={false}
            title={"Save"}
          />
          <CustomButton
            onClick={() => props.closeDuplicateModal()}
            outlineStyle={true}
            data-dismiss="modal"
            className="ml-auto"
            type="button"
            color="link"
            title="Close"
          />
        </div>
      </Modal>
    </>
  )
}

export default DuplicateReportModal;