import React from "react";
import {
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  CardText,
  CardTitle,
} from "reactstrap";

const AddressList = ({ data, openModal, i, displaySubtitle }) => {
  return (
    <Col sm="12" lg="6" key={i}>
      <Card className="p-3">
        <CardBody className="text-center">
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 20,
              fontSize: 25,
              display: "flex",
            }}
          >
            <div>
              <i
                className="ni ni-fat-delete"
                id={`delete${i}`}
                onClick={() => alert("Cooming soon")}
              />
              <UncontrolledTooltip
                placement="left"
                style={{ fontSize: "smaller", padding: "13px" }}
                target={`delete${i}`}
              >
                Delete
              </UncontrolledTooltip>
            </div>

            <div>
              <i
                className="ni ni-fat-add"
                id={`add${i}`}
                onClick={() => openModal()}
              />
              <UncontrolledTooltip
                placement="top"
                style={{ fontSize: "smaller", padding: "13px" }}
                target={`add${i}`}
              >
                Update
              </UncontrolledTooltip>
            </div>
          </div>
          <div style={{ height: "200px" }}>
            <CardTitle className="border-bottom p-2">{data.name}</CardTitle>
            <CardText>Office Address</CardText>
            <div style={{ margin: "0 auto" }}>
              <CardText style={{ fontSize: "13px" }}>
                <i className="fa fa-address-card pull-left mx-3" />
                {displaySubtitle(data.Type)}
              </CardText>
              <CardText style={{ fontSize: "13px" }}>
                <i className="fa fa-map-marker pull-left mx-3" />
                {data.PostalAddress || "Postal Address As Above"}
              </CardText>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AddressList;
