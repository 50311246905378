import { saveNotification } from "./mongoDB";

const SendExpoNotifications = async (data) => {

    console.log(data)
    const Tokens = data.tokens
   
    const expoTokens = Tokens.filter(token => token.startsWith("ExponentPushToken["));
    if (expoTokens.length === 0) return

    const { ClientID, Name, type, Id } =  data.data

    var raw = JSON.stringify({
        to: expoTokens,
        body: data.notification.body,
        title: data.notification.title,
        data: { 
          ClientID, 
          Name ,  
          Id,
          type: type, 
          createdAt: new Date(),
          groupId: data.data.groupId && data.data.groupId !== undefined ? data.data.groupId : ""
        },
        sound: "default"
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        host: "exp.host",
        accept: "application/json",
        "accept-encoding": "gzip, deflate",
        "content-type": "application/json",
      },
      body: raw,
    };

    var raw2 = JSON.stringify({
      data: { ClientID, Name ,  type: type},
      notification: {
        body: data.notification.body,
        title: data.notification.title
      },
      apns: data.apns,
      android: data.android,
      registration_ids: expoTokens,
      createdAt: new Date(),
      type: type
    });

    try {
        // const response = await fetch('https://exp.host/--/api/v2/push/send', requestOptions);
        saveNotification(JSON.parse(raw2))
        await fetch('/api/chatGroup/expoPushNotification', requestOptions);
        // const result = await response.text();
   

        // console.log(response)
    } catch (error) {
        // console.log('error', error);
    }
  };
  
  export default SendExpoNotifications