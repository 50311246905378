import React, { useContext } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { MainContext } from "context/mainContext";
//eslint-disable-next-line
import { ClipLoader } from "react-spinners";
import DropdownSelectClient from "utils/DropdownSelectClient";

const ClientInfo = ({ data, ...props }) => {
  const { bgColor } = useContext(MainContext);
  return (
    <>
      <Card className="card-profile ">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <img
                style={{
                  background: "#eee",
                  //height: "140px", //Commented by Joef: To retain dimension
                  width: "140px",
                  objectFit: "cover",
                  borderRadius: "0.675rem",
                }}
                alt="..."
                src={
                  data && data.Logo
                    ? data.Logo
                    : require("assets/img/brand/logo-placeholder.png").default
                }
              />
            </div>
          </Col>
        </Row>
        <CardBody className="pt-0">
          <div className="text-center" style={{ marginTop: "100px" }}>
            <h5 className="h3">
              {props.isLoading ? (
                <ClipLoader color={bgColor} size={20} margin={2} />
              ) : data.Name ? (
                data.Name
              ) : (
                "No Client Found."
              )}
            </h5>
            {data.Name ? (
              <Row>
                <Col sm="6" className="offset-sm-3">
                  <hr className="m-3" />
                  <DropdownSelectClient
                    page={props.page}
                    options={props.clientsList}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ClientInfo;
