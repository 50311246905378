import React, { useCallback, useContext, useEffect, useState } from 'react'
import { deleteAllEVAHistoryItem, deleteEVAHistoryItem, fetchEVAMessagesPerUser, updateEVAChatHistoryList } from '../services/mongoDB'
import { generateUUID } from '../services/EVAResponseService'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { IconTrash } from './ui/icon';
import { groupMessagesByDate } from 'services/EVAService';
import Loading from "./ui/loading";
import { MainContext } from 'context/mainContext';
import { IconEdit } from 'chat-components/ui/icon';
import { FormGroup, Input, Row } from 'reactstrap';

const History = ({ onSelectUUID, userData, selectedUUID, theme, onIsLoading ,  onSetAlert, handleOpenTab}) => {
    const { bgColor, textColor, selectedClientID } = useContext(MainContext);
    const [userDetails, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [history, getHistory] = useState([])
    const [formattedHistory, getFormattedHistory] = useState(null)
    const [deleteItemCount, setDeleteItemCount] = useState(0)
    const [newuuid, setNewuuid] = useState(null)
    const [selecteduuid, setSelecteduuid] = useState(null)
    const { business, client, user } = userData
    const [alert, setAlert] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [isEditIndex, setIsEditIndex] = useState(null)
    const [newName, setNewName] = useState('')
    const [newUpdate, setNewUpdate] = useState(0)

    const handleItemClick = (uuid) => {
      handleLoading(true)
      setSelecteduuid(uuid)
      onSelectUUID(uuid);
      handleOpenTab()
    };

    const handleLoading = (value) => {
      setIsLoading(value)
      onIsLoading(value);
    }    

    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

        const getLocalStorage = async () => {
            try {
              const myChatroom = await fetchMessages({ signal });
                if (myChatroom && myChatroom.messages && myChatroom.messages !== undefined) {

                    getHistory(myChatroom.messages);
                    handleLoading(false)
                    // Add a check for null before calling groupMessagesByDate
                    const groupedMessages = myChatroom.messages !== null
                        ? groupMessagesByDate(myChatroom.messages)
                        : null;

    
                    // Add a check for null before calling getFormattedHistory
                    if (groupedMessages !== null) {
                        try {
                            getFormattedHistory(groupedMessages);
                            handleLoading(false)
                        } catch (err) {
                            console.error("Error in getFormattedHistory:", err);
                            handleLoading(false)
                        }
                    } else {
                        console.error("Error: Messages are null");
                    }
                } else {
                    handleLoading(false)
                    getHistory([]);
                }
            } catch (error) {
                console.error("Error in the main try-catch block:", error);
                handleLoading(false)
            }
        };

        getLocalStorage();
        return () => {
          abortController.abort();
        };
    },[selectedUUID, newUpdate])
    
  
    const fetchMessages = useCallback(async () => {
        try {
            const EVAMessage = await fetchEVAMessagesPerUser({ clientid: client.uid, userid: user.uid })
            return EVAMessage
        } catch (error) {
            console.error('Error fetching messages:', error);
            return [];
        }
    }, []);
    
    const handleClick = async () => {
        const newUUID = await generateUUID();
        handleItemClick(newUUID);
    };
    const truncateText = (text) => {
        if (text.length > 35) {
          return text.substring(0, 40) + '...';
        }
        return text;
    };

    const deleteAllHistory = async(item) => {
        await deleteAllEVAHistoryItem(item)
        const newUUID = await generateUUID();
        handleItemClick(newUUID);
        onSetAlert(null)
    }
    const showDeleteAllConfirmation = (data) => {
      onSetAlert(
        <ReactBSAlert
          warning
          title="Warning"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => deleteAllHistory(data)}
          onCancel={() => onSetAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          showCancel={true}
          btnSize=""
        >
          <p>
            Are you sure you want to clear the history item?
          </p>
          {/* {`Are you sure you want to delete record of ${data.FirstName} ${data.LastName}?`} */}
        </ReactBSAlert>
      );
    };
    const showDeleteConfirmation = (data) => {
      onSetAlert(
        <ReactBSAlert
          warning
          title="Warning"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => deleteHistory(data)}
          onCancel={() => onSetAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          showCancel={true}
          btnSize=""
        >
          <p>
            Are you sure you want to delete this history item?'
          </p>
          {/* {`Are you sure you want to delete record of ${data.FirstName} ${data.LastName}?`} */}
        </ReactBSAlert>
      );
    };
    const deleteHistory = async(item) => {
      await deleteEVAHistoryItem(item)
      const newUUID = await generateUUID();
      handleItemClick(newUUID);
      onSetAlert(null)
    }

    const handleIsEditCancel = (item,isEdit) => {
      setNewName('')
      setIsEditIndex(null)
      setIsEdit(isEdit)
    }
    const handleIsEditSave = async(item, isEdit) => {

     
      // console.log(item)
      try{
        item.newText = newName
        await updateEVAChatHistoryList(item)
        handleSuccessUpdate()
      }catch(error)
      {
        setIsEditIndex(item.uuid)
        setIsEdit(isEdit)
      }

    }
    const handleShowIsEdit = (item, isEdit) => {

      setNewName(item.text);
      setIsEdit(isEdit)
      setIsEditIndex(item.uuid)
    }
    const handleNewName = (event) => {
      setNewName(event.target.value);
    }
    const handleSuccessUpdate = () => {
      setAlert(
          <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
              setAlert(null)
              setIsEdit(false)
              setNewUpdate(prev => prev + 1)
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            <>
              <p>
                You have successfully update the chat history name.
              </p>
            </>
          </ReactBSAlert>
      );
  }


  return (
    <>
    {alert}
    <div>
      {/* <div className=''>
        <div className='newChat' 
          // style={{ backgroundColor: theme.PrimaryColor }} 
          onClick={handleClick}>
          New Chat
        </div>
      </div> */}
   
      <div className='userChat'>
      {isLoading ? <Loading /> :
      <>
      
        {history && history.length > 0 && formattedHistory ? (
          <>
            <div className='clearContainer'>
              <div 
                className='clearBtn' 
                onClick={() => {
                  showDeleteAllConfirmation({ 'clientid': client.uid, 'userid': user.uid })
                  }}>Clear History</div>
            </div>
            {Object.entries(formattedHistory).map(([label, messages]) => (
              <div key={label}>
                <div className="dateLabel">{label}</div>
                {messages.map((item, index) => (
                  <div
                    className={`menuItem ${item.uuid === selecteduuid ? 'active' : ''}`}
                    key={item._id}
                    onClick={() => handleItemClick(item.uuid)}
                  >
                    {isEdit && item.uuid === isEditIndex ? 
                    <>
                      <div>
                        <FormGroup>
                          <Input
                                type="text"
                                placeholder={item.text}
                                name={item.uuid}
                                value={newName}
                                onChange={handleNewName}
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleIsEditSave(item, true);
                                  }
                              }}
                              />
                        </FormGroup>
                      </div>
                      <Row style={{ marginRight: '0px', height:'45px'}}>
                        <div
                          className='menuItemSave'
                          style={{ background: bgColor, color: textColor}}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handleIsEditSave(item, true);
                          }}
                        >
                          Save
                        </div>
                        <div
                          className='menuItemCancel'
                          onClick={(e) => {
                            e.stopPropagation()
                            handleIsEditCancel(item, false);
                          }}
                        >
                          Cancel
                        </div>
                      </Row>
                    </>
                    :
                    <>
                      <div>
                        <div className="menuItemText">{truncateText(item.text)}</div>
                        <div className="menuItemTime">{item.createdAt}</div>
                      </div>
                    
                    <Row style={{paddingTop: '7px'}}>
                      <div
                        className='menuItemEdit'
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event propagation
                          handleShowIsEdit(item, true);
                        }}
                      >
                        <IconEdit />
                      </div>
                      <div
                        className='menuItemDelete'
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event propagation
                          showDeleteConfirmation(item);
                        }}
                      >
                        <IconTrash />
                      </div>

                    </Row>
                    </>
                    }
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <p>No chat history available.</p>
        )}
        </>}
      </div>
    </div>
    </>
  );
    
}



export default History