import React from 'react'
import History from './History'

function Sidebar({ onSelectUUID, userData, selectedUUID, theme, isOpenTab, onIsLoading, onSetAlert, handleOpenTab }) {
  return (
    <div 
      className={`sidebar ${isOpenTab ? 'open' : 'closed'}`} 
      style={{ border: `2px solid ${ theme.PrimaryColor }`}}
      > 
        <History 
          onSelectUUID={onSelectUUID}
          selectedUUID={selectedUUID}
          userData={userData} 
          theme={theme}
          onIsLoading={onIsLoading}
          onSetAlert={onSetAlert}
          handleOpenTab={handleOpenTab}
        />
    </div>
  )
}

export default Sidebar