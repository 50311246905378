import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import CustomHeader from "customComponents/customHeader";
import { MainContext } from "context/mainContext";
import EvaClientList from "components/EvaClientList/EvaClientList";
import PanelCard from "components/Cards/PanelCard";
import { useHistory } from "react-router";
import Chat from "chat-components/Chat";
import { checkEvaAccess } from "services/EVAService";
import CustomSpinner from "components/Misc/CustomSpinner";

const ChatForum = () => {
  const { userDetails, firmDetails, selectedClientID, selectedClient, setSelectedGroupID } = useContext(MainContext);
  const [evaIframeUrl, setEvaIframeUrl] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [noEvaAccess, setNoEvaAccess] = useState(false);
  const [clientName, setClientName] = useState(false)
  const [isBOS, setIsBOS] = useState(firmDetails.Code === 'BIGOLESHOECOMPANY')
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [generateTokenCount, setGenerateTokenCount] = useState(0)
  const handleSetEvaIframeUrl = (value) => {
    setEvaIframeUrl(value)
  }
  const handleSetNoEvaAccess = (value) => {
    setNoEvaAccess(value)
  }
  const handleSetClientName = (value) => {
    setClientName(value)
  }


  useEffect(() => {
    const fetchData = async () => {
      // Redirect if trial is active and extended
      if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
        return history.push(`/`);
      }

      // Return early if userDetails is empty or selectedClientID is not set, or generateTokenCount > 1
      if (!Object.keys(userDetails).length || !selectedClientID || generateTokenCount > 1) {
        setIsLoading(false);
        return;
      }

      // Fetch data if userDetails, userDetails.User, and selectedClientID are set
      if (userDetails?.User) {
        setGenerateTokenCount(prev => prev + 1);
        await checkEvaAccess(selectedClientID, userDetails, handleSetEvaIframeUrl, handleSetNoEvaAccess);
      }

      setIsLoading(false);
    };

    setIsLoading(true);
    fetchData();

    return () => {
      // Cleanup function if needed
      setSelectedGroupID(null)
    };
  }, [selectedClientID, userDetails]);


  const handleRedirect = () => {
    return history.push(`/client/lists`)
  }

  return (

    <Container className="mt-6 fadeIn" fluid>
      <div className="nav-wrapper">
        <PanelCard headerName={clientName ? clientName : "Chat Forum"}>
          {isLoading ? <CustomSpinner /> :
            selectedClient?.Details?.ShowChatPage ?
              <>
                {!selectedClientID &&
                  <Row className="text-center d-flex align-items-center justify-content-center">
                    Please choose a client first.
                  </Row>}

                {/* {!evaIframeUrl &&
                  <Row className="text-center d-flex align-items-center justify-content-center">
                    You dont have access to this page.
                  </Row>} */}

                {/* {selectedClientID && evaIframeUrl &&
                  <Chat token={evaIframeUrl} onSetClientName={handleSetClientName} userDetails={userDetails} />
                } */}

                {selectedClientID &&
                  <Chat token={evaIframeUrl} onSetClientName={handleSetClientName} />
                }
              </>
              :
              <Row>
                <Col className="text-center">
                  {
                    userDetails?.User?.UserAccess >= 5 ?
                      <span>Chat page is not yet enabled for this Client. Please speak to your admin about turning this feature on.</span>
                      :
                      <span>Chat page is not yet enabled for this Client. Please click <strong style={{ color: "#5e72e4", cursor: "pointer" }} onClick={() => handleRedirect()}>here</strong> go to clients to enable Chat page.</span>
                  }
                </Col>
              </Row>
          }
        </PanelCard>
      </div>
    </Container>
  );
}

export default ChatForum