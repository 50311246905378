import React from 'react'
import ReactPlayer from 'react-player';
import {
  Container, Row, Col, Button, Input, Modal,
} from "reactstrap";

const UpgradeModal = ({
  bgColor, textColor, showUpgradeModal, toggleUpgradeModal, ...props
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={showUpgradeModal}
        toggle={toggleUpgradeModal}
        size="xl"
        backdrop="static"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {props.subscribe ? `Subscribe Video` : `Plan Video`}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleUpgradeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0 text-left">
          {/* <p><strong>You are about to upgrade to Etani Business Plan</strong></p> */}
          <Row
            style={{
              position: "relative",
              paddingTop: "56.25%",
              background: "#FFFFFF",
              margin: "auto",
            }}
          >
            <ReactPlayer
              style={{ position: "absolute", top: 0, right: 0 }}
              url={'https://player.vimeo.com/video/579225240?h=1751f8e7a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
              playing={false}
              controls
              width="100%"
              height="100%"
              light={true}
              stopOnUnmount={false}
              playsinline={true}
              pip={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                vimeo: {
                  playerOptions: {
                    autoplay: true,
                    autopause: true,
                    responsive: true,
                    loop: false,
                    keyboard: true,
                    pip: true,
                  },
                },
              }}
            />
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default UpgradeModal