import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  Button
} from "reactstrap";

const PlanCard = ({ data, currentPlan, selectPlan, bgColor, ...props }) => {
  return (
    <>
      <Card className='hover-plan-card' onClick={() => selectPlan(data)} style={{ cursor: 'pointer', border: `${currentPlan.Plan.Code === data.Code ? `5px solid ${bgColor}` : ''}`, '--hover-border': `5px solid ${bgColor}` }}>
        <CardBody style={{}}>
          {/* {currentPlan.Plan.Code === data.Code ?
            <div style={{ display: 'flex', position: 'absolute', left: '-7px', top: '50%' }}>
              <i className="fas fa-check-circle mr-3 text-sm text-success" />
            </div>
            : null
          } */}
          <Row className='align-items-centers' style={{ height: '110px', alignItems: 'center', }}>
            <Col sm='3' lg='3' className=''>
              <strong>{data.Name}</strong> <br />
              {currentPlan.Plan.Code === data.Code ? `(Current Plan)` : ''}
            </Col>
            <Col sm='7' lg='7'>
              {data.Details.Descriptions}
            </Col>
            <Col sm='2' lg='2' className='text-right'>
              <strong>{`$ ${data.Details.Price} / month`}</strong>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default PlanCard;