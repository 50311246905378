export const detailsData = [
  {
    id: 0,
    title: "Power BI Basic Client Data Integrations",
    businessPlan: "1 Client | 10 Xero | MYOB | Quickbooks Integrations",
    accountingPlan: "25 Clients | 100 Xero | MYOB | Quickbooks Integrations",
    extendedPlan: "75 Clients | 300 Xero | MYOB | Quickbooks Integrations",
  },
  {
    id: 1,
    title: "Power BI Extended Client Data Integrations",
    businessPlan: "$10 per integration per month",
    accountingPlan: "$10 per integration per month",
    extendedPlan: "$10 per integration per month",
    link: "https://www.etani.com.au/integrations",
  },
  {
    id: 2,
    title: "Client Users Needing Access to Embedded Reports",
    businessPlan: "10",
    accountingPlan: "100",
    extendedPlan: "300",
    link: "https://www.etani.com.au/frequently-asked-questions",
  },
  {
    id: 3,
    title: "Accountant Users Included",
    businessPlan: "2",
    accountingPlan: "5",
    extendedPlan: "5",
    link: "https://www.etani.com.au/frequently-asked-questions",
  },
  {
    id: 4,
    title: "Power BI Pro Licences Included",
    businessPlan: "1",
    accountingPlan: "1",
    extendedPlan: "3",
  },
  {
    id: 4,
    title: "Scheduled PDF Email Reports",
    businessPlan: "2 Daily",
    accountingPlan: "20 Daily",
    extendedPlan: "60 Daily",
    link: "https://www.etani.com.au/blog/dynamic-custom-automated-power-bi-pdf-emails",
  },
  {
    id: 4,
    title: "Branded Mobile App (Apple/Android)",
    businessPlan: "No",
    accountingPlan: "Yes",
    extendedPlan: "Yes",
  },
  {
    id: 4,
    title: "Data ‘Refresh Now’ Functionality",
    businessPlan: "Yes",
    accountingPlan: "Yes",
    extendedPlan: "Yes",
    link: "https://www.etani.com.au/frequently-asked-questions",
  },
];

export const planDetails = [
  {
    id: 0,
    basicIntegration: "1 Client | 10 Xero | MYOB | Quickbooks Integrations",
    extendedIntegration: "$10 per integration per month",
    reportsAccess: "10",
    accountantUser: "2",
    powerBIProLiscense: "1",
    scheduledPDF: "2 Daily",
    mobile: "No",
    refresh: "Yes",
    name: "Business",
    price: "$300",
    ribbon: true,
  },
  {
    id: 1,
    basicIntegration: "25 Clients | 100 Xero | MYOB | Quickbooks Integrations",
    extendedIntegration: "$10 per integration per month",
    reportsAccess: "10",
    accountantUser: "5",
    powerBIProLiscense: "1",
    scheduledPDF: "20 Daily",
    mobile: "Yes",
    refresh: "Yes",
    name: "Accounting Firm",
    price: "$500",
    ribbon: true,
  },
  {
    id: 2,
    basicIntegration: "75 Clients | 300 Xero | MYOB | Quickbooks Integrations",
    extendedIntegration: "$20 Integrations Included",
    reportsAccess: "300",
    accountantUser: "5",
    powerBIProLiscense: "3",
    scheduledPDF: "60 Daily",
    mobile: "Yes",
    refresh: "Yes",
    name: "Extended Firm",
    price: "$1,000",
    ribbon: false,
  },
];
