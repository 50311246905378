import React, { useContext } from "react";
import { Modal, Row, Col, Button, Form, ButtonGroup } from "reactstrap";
import { CustomFieldGroup } from "customComponents/Form";
import { CustomToggleField } from "customComponents/Form";
import CustomModal from "customComponents/customModal";
import { MainContext } from "context/mainContext";

const SelectModal = ({ modal, data, ...props }) => {
  const { textColor, bgColor } = useContext(MainContext);

  return (
    <>
      {/* change settings modal */}

      <Modal
        size="lg"
        // key={props.i}
        className="modal-dialog-centered"
        isOpen={props.settingsModal.show}
        toggle={() => props.closeSettingsModal()}
      >
        <div className="modal-body">
          <h6 className="mb-0 modal-title">
            {props.settingsModal.record.Name} Settings
          </h6>
          <Form onSubmit={props.handleChangeSettings}>
            <Row>
              <CustomToggleField
                label="Show on mobile?"
                onChange={props.onToggle}
                defaultChecked={
                  !props.settingsModal.record.hasOwnProperty("Details") ||
                  !props.settingsModal.record.Details.hasOwnProperty(
                    "Settings"
                  ) ||
                  !props.settingsModal.record.Details.Settings.hasOwnProperty(
                    "Mobile"
                  ) ||
                  props.settingsModal.record.Details.Settings.Mobile !== true
                    ? false
                    : true
                }
                type="checkbox"
                id={`mobile${props.i}`}
                name="Mobile"
                defaultValue={
                  !props.settingsModal.record.hasOwnProperty("Details") ||
                  !props.settingsModal.record.Details.hasOwnProperty(
                    "Settings"
                  ) ||
                  !props.settingsModal.record.Details.Settings.hasOwnProperty(
                    "Mobile"
                  ) ||
                  props.settingsModal.record.Details.Settings.Mobile
                }
              />
            </Row>
            <Row>
              <CustomToggleField
                label="Show in desktop?"
                onChange={props.onToggle}
                defaultChecked={
                  !props.settingsModal.record.hasOwnProperty("Details") ||
                  !props.settingsModal.record.Details.hasOwnProperty(
                    "Settings"
                  ) ||
                  !props.settingsModal.record.Details.Settings.hasOwnProperty(
                    "Desktop"
                  ) ||
                  props.settingsModal.record.Details.Settings.Desktop === true
                    ? true
                    : false
                }
                type="checkbox"
                name="Desktop"
                id={`desktop${props.i}`}
                value={
                  !props.settingsModal.record.hasOwnProperty("Details") ||
                  !props.settingsModal.record.Details.hasOwnProperty(
                    "Settings"
                  ) ||
                  !props.settingsModal.record.Details.Settings.hasOwnProperty(
                    "Desktop"
                  ) ||
                  props.settingsModal.record.Details.Settings.Desktop
                }
              />
            </Row>
            <Row>
              <Col className="mt-3">
                <CustomFieldGroup
                  id={`description${props.i}`}
                  type="textarea"
                  name="Description"
                  label="Description"
                  placeholder="Enter Description"
                  defaultValue={props.settingsModal.record.Description || ""}
                  onChange={props.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Button
                  className="btn-icon btn-3 mr-2"
                  size="sm"
                  style={{
                    backgroundColor: `${bgColor}`,
                    color: `${textColor}`,
                    borderColor: `${bgColor}`,
                  }}
                  type="button"
                  onClick={() =>
                    props.openUserSelectionModal(
                      props.settingsModal.record,
                      true
                    )
                  }
                >
                  <i className="ni ni-bag-17" />
                  <span className="btn-inner--text">Select Users</span>
                </Button>
                {(props.settingsModal.record.Details &&
                props.settingsModal.record.Details.recipient &&
                Object.keys(props.settingsModal.record.Details.recipient).length
                  ? Object.keys(props.settingsModal.record.Details.recipient)
                      .length
                  : "No") + " recipient groups selected"}
              </Col>
            </Row>
            <Row className="modal-footer py-0">
              <Button
                style={{
                  backgroundColor: `${bgColor}`,
                  color: `${textColor}`,
                  borderColor: `${bgColor}`,
                }}
                type="submit"
              >
                Save
              </Button>
              <Button
                color="secondary"
                outline
                data-dismiss="modal"
                type="button"
                style={{
                  color: `${props.bgColor}`,
                  borderColor: `${props.bgColor}`,
                }}
                onClick={() => props.closeSettingsModal()}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>

      {/* user selection modal */}

      <CustomModal
        title="Select User"
        subtitle={null}
        fieldGroup={null}
        list={props.userSelectList()}
        isOpen={props.userSelectionModal.show}
        searchInput={props.handleUserSearch}
        toggle={() => props.closeUserSelectionModal()}
        closeButton={() => props.closeUserSelectionModal()}
        submitButton={(e) => {
          e.preventDefault();
          if (props.userSelectionModal.settings) props.handleUserSelect();
          else {
            props.handleUpdate(
              "/api/customReport/updateLive?id=" +
                props.userSelectionModal.report.ReportID,
              4,
              {
                report: props.userSelectionModal.report,
                recipient: props.userSelectionModal.selected,
              }
            );
          }
        }}
        headerButtons={
          <ButtonGroup>
            <Button
              style={{
                backgroundColor: `${props.bgColor}`,
                color: `${props.textColor}`,
                borderColor: `${props.bgColor}`,
              }}
              type="button"
              size="sm"
              onClick={() => props.selectAll()}
            >
              <i className="ni ni-fat-add "></i>
              Select All
            </Button>
            <Button
              style={{
                color: `${props.bgColor}`,
                borderColor: `${props.bgColor}`,
              }}
              data-dismiss="modal"
              type="button"
              size="sm"
              outline
              onClick={() => props.unselectAll()}
            >
              <i className="ni ni-fat-delete "></i>
              Unselect All
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default SelectModal;
