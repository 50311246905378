import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Input,
  Button
} from "reactstrap";
import BlockUI from "components/Misc/BlockUI";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import DatePicker from "react-date-picker";
import { dataFilterStaffNameOnly } from "utils/utilityFunctions";
import { ClipLoader } from "react-spinners";
import ReactBSAlert from "react-bootstrap-sweetalert";


const ThirdPartyAppTimeSheetTableV2 = ({
  data,
  warningAlert,
  handleEditClient,
  userDetails,
  ...props
}) => {
  const { setSelectedClientID } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);
  const [sort, setSort] = useState({ field: 'age', direction: 'asc' });
  const [editedRows, setEditedRows] = useState({});
  const [dataToFilter, setDataToFilter] = useState([]);
  const [isUploadingData, setisUploadingData] = useState(false);
  const { bgColor } = useContext(MainContext);
  const [alert, setAlert] = useState(null);
  const [jobCostList, setjobCostList] = useState([])
  const [jobCostListCount, setjobCostListCount] = useState(null)
  const [showBlockUI, setShowBlockUI] = useState(true);

  useEffect(() => {
    setShowBlockUI(true);
    openJobCost(currentPage, numberPerPage)

    localStorage.getItem("clientListNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("clientListNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage]);

  const openJobCost = (currentPage, numberPerPage) => {
    setShowBlockUI(true);
    console.log(currentPage, numberPerPage)
    fetch(`/api/clientService/getWipCleared?page=${currentPage+1}&itemsPerPage=${numberPerPage}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return setShowBlockUI(false);;
        console.log(response);
        // response.data;
        setPaginatedData(response.data)
        setjobCostList(response.data)
        setjobCostListCount(response.totalRows)
        setShowBlockUI(false);
      })
      .catch((err) => {
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            Something went wrong. Please try again later.
          </ReactBSAlert>
        );
        setShowBlockUI(false);
        console.log(err);
      });
  }

  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    openJobCost(0, num)
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  const currentPageData = () => {

    // console.log("jobCostList")
    // console.log(jobCostList)
    // console.log("sortedData")
    // console.log(sortedData)
    // console.log("offset")
    // console.log(offset)

    return sortedData
      // .slice(offset, Number(offset) + Number(numberPerPage))
      .map((data, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }}>
            <td className="name">
              {data.ClientID ? data.ClientID : data.id}
            </td>
            <td className="admin-email">
              {data.Ledger_Staff}
            </td>
            <td className="admin-email">
              {data.Ledger_Date}
            </td>
            <td className="admin-email">
              {data.Ledger_Invoice_Number}
            </td>
            <td className="admin-email">
              {data.Ledger_Ledger_Type}
            </td>
            <td className="admin-email">
              {data.Ledger_Time}
            </td>
            <td className="admin">
              {data.Job_Job_No ? data.Job_Job_No : ""}
            </td>
            <td className="admin-email">
              {data.Job_Name}
            </td>
            <td className="admin-email">
              {data.Ledger_Description}
            </td>
            <td className="admin-email">
              {data.Ledger_Billable}
            </td>
            <td className="admin-email">
              {data.Ledger_Note}
            </td>
            <EditableColumn
              value={data.WrittenOffDate}
              onChange={(newWrittenOffDate) => {
                const updatedRow = { ...data, WrittenOffDate: newWrittenOffDate };
                setEditedRows((prev) => ({ ...prev, [data.WrittenOffDate]: updatedRow }));
              }}
            />
          </tr>
        );
      });
  };

  const pageCount = () => {
    return Math.ceil(jobCostListCount / numberPerPage);
  };

  const changeUrl = (page, clientID) => {
    if (page === "users") {
      setSelectedClientID(clientID);
      return history.push(`/client/users/${clientID}`);
    }
    if (page === "advisers") {
      setSelectedClientID(clientID);
      return history.push(`/client/advisers/${clientID}`);
    }
    if (page === "dashboard") {
      setSelectedClientID(clientID);
      return history.push(`/client/dashboard/${clientID}`);
    }
    if (page === "reports") {
      setSelectedClientID(clientID);
      return history.push(`/client/reports/${clientID}`);
    }
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
    if (page === "signclient") {
      setSelectedClientID(clientID);
      return history.push(`/client/signclient/${clientID}`);
    }
  };

  const sortedData = [...paginatedData].sort((a, b) => {
    console.log(a)
    console.log(b)
    if (sort.direction === 'asc') {
      return a[sort.field] > b[sort.field] ? 1 : -1;
    } else {
      return a[sort.field] < b[sort.field] ? 1 : -1;
    }
  });


  function EditableColumn({ value, onChange }) {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const handleSave = () => {
      setIsEditing(false);
      onChange(currentValue);
      setPaginatedData((prevData) => {
        return prevData.map(d => editedRows[d.id] || d);
      });
    };

    return (
      <td onClick={() => !isEditing && setIsEditing(true)}>
        {isEditing ? (
          // <input
          //   value={currentValue}
          //   onChange={(e) => setCurrentValue(e.target.value)}
          //   onBlur={handleSave}
          // />
          <DatePicker selected={currentValue} onChange={(date) => setCurrentValue(date)} onBlur={handleSave} />
        ) : (
          value
        )}
      </td>
    );
  }

  const saveEdits = () => {
    console.log("Saving edited rows:", editedRows);
    // Here, make an API call or any other action to save the edited data.
  };


  const handleSearch = (e) => {
    let filterString = e.target.value;
    setPaginatedData(dataFilterStaffNameOnly(filterString, dataToFilter));
  }


  return (
    <>
      {/* {showBlockUI ? <BlockUI /> : null} */}
      <Card>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
          {isUploadingData ? <div className="dataloading">
            <ClipLoader color={bgColor} size={20} margin={2} />
            <span>uploading data</span>
          </div> : null}
          
         
          
          <Button onClick={saveEdits}>Save All Edits</Button>
          
          <Row>
            <Col lg="6">
            <br />
              <div className="mb-3">
                <Input
                  placeholder="Search"
                  type="text"
                  bsSize="sm"
                  onChange={handleSearch}
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          <>
          
         
          
          <Table className="align-items-center table-flush" responsive>
          {showBlockUI ? <div className="tableLoading">
           <ClipLoader color={bgColor} size={50} margin={2} />
          </div> : null}
            <thead className="thead-light">
                <tr>
                    <th className="" scope="col"
                    onClick={() => {
                        if (sort.field === 'ClientID' && sort.direction === 'asc') {
                        setSort({ field: 'ClientID', direction: 'desc' });
                        } else {
                        setSort({ field: 'ClientID', direction: 'asc' });
                        }
                    }}>ClientID  {sort.field === 'ClientID' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    <th className="" scope="col">StaffName</th>
                    <th className="" scope="col">Date</th>
                    <th className="" scope="col">Invoice Number</th>
                    <th className="" scope="col">Ledger Type</th>
                    <th className="" scope="col">Time</th>
                    <th className="" scope="col" style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (sort.field === 'Job No.' && sort.direction === 'asc') {
                        setSort({ field: 'Job No.', direction: 'desc' });
                        } else {
                        setSort({ field: 'Job No.', direction: 'asc' });
                        }
                    }}>Job No.  {sort.field === 'Job No.' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    <th className="" scope="col">JobName</th>
                    <th className="" scope="col">Description</th>
                    <th className="" scope="col">Billable</th>
                    <th className="" scope="col">Note</th>
                </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/* <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td> */}
              </tr>
            </tbody>
          </Table>
          </>
        ) : null}
        <CardFooter className="py-4">
          {paginatedData && paginatedData.length >= 1 ? null : (
            <p className="text-center mt-3">No data found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card >
    </>
  );
};

export default ThirdPartyAppTimeSheetTableV2;
