import React from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import CustomTooltip from "customComponents/customComponents";

const ClientDashboardCard = ({ i, data, ...props }) => {
  return (
    <Col lg="4" md="4" sm="6" key={i}>
      <Card className="zoom">
        <CardBody className="d-flex flex-column px-0">
          <div style={{ position: "absolute", left: "10px", top: "10px" }}>
            {props.showTrafficLight(data.Status, i)}
          </div>
          <div style={{ position: "absolute", right: 29, top: 10 }}>
            <Row>
              {data.Status === 4 || data.Status === 5 ? (
                <CustomTooltip
                  i={i}
                  id="settings"
                  message="Settings"
                  className="fas fa-cog sm"
                  onClick={() => props.openSettingsModal(data)}
                />
              ) : null}
              {/*Commented by Joef: Placed at the bottom for consistency*/}
              {/* <CustomTooltip
                i={i}
                id="dashboard"
                message="View Dashboard"
                className="fas fa-signal fa-xs ml-1"
                onClick={() => props.openViewDashboard(data)}
              /> */}
              {/* <CustomTooltip
                i={i}
                id="update"
                message="Update"
                className="fas fa-pen-alt"
                // onClick={() => openReportUpdate(data)}
              /> */}
              {/*Commented by Joef: Placed at the bottom for consistency*/}
              {/* <CustomTooltip
                i={i}
                id="delete"
                message="Delete"
                className="fas fa-times fa-sm ml-1"
                onClick={() => props.handleDelete(data)}
              /> */}
            </Row>
          </div>
          <div className="pt-3 text-center"></div>

          <Row className="">
            <Col sm="12">
              <hr
                className="my-3"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              />
            </Col>
            <Col sm="12" className="text-center " style={{ height: "90px" }}>
              <h4>{data.Name}</h4>
              {/*Commented by Joef: For consistency with reports*/}
              {/* <p className="text-sm mb-0 h5">
                {"Created By: " +
                  (data.LastAddedBy && data.LastAddedBy.FirstName
                    ? data.LastAddedBy.FirstName
                    : "N/A")}
              </p> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center">
              <span className="btn-inner--icon ml-1 text-xs">
                <i className="ni ni-single-02" />
              </span>
              <span className="text-xs text-muted ml-3">
                Last Submitted By:{" "}
                {(data.LastSubmittedBy
                  ? data.LastSubmittedBy.FirstName
                  : "N/A") || "N/A"}
              </span>
            </Col>
            {/*Commented by Joef: For consistency with reports*/}
            {/* <Col xs="12" className="text-center">
              <span className="btn-inner--icon ml-1 text-xs">
                <i className="ni ni-single-02" />
              </span>
              <span className="text-xs text-muted ml-3">
                Last Published By:{" "}
                {(data.LastPublishedBy
                  ? data.LastPublishedBy.FirstName
                  : "N/A") || "N/A"}
              </span>
            </Col> */}
          </Row>
        </CardBody>
        <div
          className="card-footer border-0 px-3 mt-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Button
            style={{
              backgroundColor: `${
                props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
              }`,
              color: `${props.textColor === "#000" ? "#fff" : props.textColor}`,
              borderColor: `${
                props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
              }`,
              borderRadius: "50px",
            }}
            size="sm"
            outline
            type="button"
            onClick={() => props.openViewDashboard(data)}
          >
            View Dashboard
          </Button>
          {props.userDetails.User.UserAccess <= 4
            ? props.submitButton(data.Status, data.id, data)
            : null}
        </div>
      </Card>

      {/* <ViewDashBoard data={data} {...props} /> */}
    </Col>
  );
};

export default ClientDashboardCard;
