import CustomTooltip from "customComponents/customComponents";
import React from "react";
import {
  Card,
  CardBody,
  // CardHeader,
  // Container,
  Row,
  Col,
  Button,
} from "reactstrap";

const UserCard = ({ data, ...props }) => {
  return (
    <>
      <Card className="zoom">
        <CardBody className="d-flex flex-column px-3">
          {props.isEditable ? (
            <div
              style={{
                position: "absolute",
                right: "16px",
                top: "10px",
              }}
            >
              <Row>
                {data.Status === 3 ? (
                  <Col className="pl-1" style={{ paddingRight: "5px" }}>
                    <CustomTooltip
                      i={data.id}
                      id={`resendEmailInvitation${data.id}`}
                      message="Resend Email Invitation"
                      className="fas fa-paper-plane fa-xs"
                      onClick={() => props.handleResendEmail(data)}
                    />
                    {/* <a
                      href="#pablo"
                      id={`resendEmailInvitation${data.id}`}
                      onClick={() => {
                        // props.confirmDelete();
                      }}
                    >
                      <span
                        className="btn-inner--icon ml-1"
                        style={{ fontSize: "1rem" }}
                      >
                        <i className="ni ni-send" />
                      </span>
                    </a>
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={`resendEmailInvitation${data.id}`}
                    >
                      Resend Email Invitation
                    </UncontrolledTooltip> */}
                  </Col>
                ) : null}
                <Col className="p-0">
                  <CustomTooltip
                    i={data.id}
                    id={`editAccountant${data.id}`}
                    message="Update"
                    className="fas fa-pen fa-xs"
                    // className="fas fa-pen-alt"
                    onClick={() => props.userUpdate(data)}
                  />
                  {/* <a
                    href="#pablo"
                    id={`editAccountant${data.id}`}
                    onClick={() => props.userUpdate(data)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                  </a>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`editAccountant${data.id}`}
                  >
                    Update
                  </UncontrolledTooltip> */}
                </Col>
                <Col className="pl-1">
                  <CustomTooltip
                    i={data.id}
                    id={`deleteAccountant${data.id}`}
                    message="Delete"
                    className="fas fa-times fa-sm"
                    onClick={() => props.confirmDelete(data)}
                  />
                  {/* <a
                    href="#pablo"
                    id={`deleteAccountant${data.id}`}
                    onClick={() => {
                      props.confirmDelete(data);
                    }}
                  >
                    <span
                      className="btn-inner--icon ml-1"
                      style={{ fontSize: "1.3rem" }}
                    >
                      <i className="ni ni-fat-remove" />
                    </span>
                  </a>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`deleteAccountant${data.id}`}
                  >
                    Delete
                  </UncontrolledTooltip> */}
                </Col>
              </Row>
            </div>
          ) : null}
          <div className="pt-4 text-center" style={{ height: "70px" }}>
            <h5 className="h3 title">
              <span className="d-block mb-1">{`${data.FirstName} ${data.LastName}`}</span>
              <small className="h5 font-weight-light text-muted">
                {data.UserAccess === 1
                  ? "System Super Admin"
                  : data.UserAccess === 2
                    ? "System Admin"
                    : data.UserAccess === 3
                      ? "Super Admin"
                      : data.UserAccess === 4
                        ? "Admin"
                        : data.UserAccess === 5
                          ? "User Admin"
                          : data.UserAccess === 6
                            ? "User"
                            : null}
                {data?.Details?.EvaEnabled ? " / EVA Enabled" : ""}
              </small>
            </h5>
          </div>
          <Row>
            <Col sm="12">
              <hr />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs="12" className="text-center">
              <span className="btn-inner--icon ml-1">
                <i className="ni ni-email-83" />
              </span>
              <small className="h5 text-muted ml-3">{data.Email}</small>
            </Col>
          </Row>
          <div className="mt-3 text-center">
            <Button
              size="sm"
              className="mt-auto"
              // color={
              //   data.status === "active"
              //     ? "success"
              //     : data.status === "waiting"
              //     ? "warning"
              //     : ""
              // }
              color={
                data.Status === 1
                  ? "success"
                  : data.Status === 3
                    ? "warning"
                    : ""
              }
              // href="#"
              onClick={(e) => e.preventDefault()}
              style={{ borderRadius: "50px" }}
            >
              {data.Status === 1
                ? "Active"
                : data.Status === 3
                  ? "Waiting for confirmation"
                  : ""}
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UserCard;
