import React, { useEffect, useState } from "react";
import { Card, CardImg, CardBody, CardHeader, Container, Row, Col, Button } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "utils/CheckoutForm";
import CustomSpinner from "components/Misc/CustomSpinner";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const SharedPaymentSetup = ({ ...props }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [firmDetails, setFirmDetails] = useState();
  const routerHistory = useHistory();
  const [isError, setIsError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [shared, setShared] = useState(true);
  const [sharedToken, setSharedToken] = useState();

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("access_token");
    setSharedToken(token)
    try {
      const result = await axios.get(`/api/stripe/processSharedLink?token=${token}`)
      // console.log(result.data)
      if (result.data.message === "Unauthorized") {
        return routerHistory.push(
          "/trialUser/shared-page-error"
        );
      }
      let firmDetails = result.data
      let authToken = `Bearer ${token}`;
      let headers = new Headers({
        'Authorization': authToken
      });
      if (result.data && !result.data.error) {
        setFirmDetails(result.data)
        fetch("/api/stripe/getConfigShared", {
          headers: headers,
          method: "POST",
          body: JSON.stringify({
            email: `{userDetails?.User?.Email}`,
          }),
        }).then(async (r) => {
          const { publishKey } = await r.json();
          // console.log(publishKey);

          setStripePromise(loadStripe(publishKey));
          if (publishKey) {
            fetch("/api/stripe/setUpIntentShared", {
              headers: headers,
              method: "POST",
              body: JSON.stringify({
                name: `${firmDetails?.Name}`,
                email: `${firmDetails?.Email}`,
                description: `${firmDetails?.Name}`,
                country: `${firmDetails?.CountryCode}`,
              }),
            }).then(async (result) => {
              let { clientSecret } = await result.json();
              // console.log(clientSecret);
              setClientSecret(clientSecret);
            })
              .catch((err) => {
                console.log(err)
              })
          }
        });
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const stripeSuccess = (result) => {
    if (result?.setupIntent?.status === 'succeeded') {
      successAlert()
    } else {
      warningAlert(result?.error?.message)
    }
  }

  const successAlert = (res) => {
    setSuccess(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          // setTimeout(() => {
          routerHistory.push(
            "/"
          )
          setSuccess(null)
        }}
        // }, 1500)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        <p className="font-weight-400">
          Thank you for completing the payment details for <strong>{`${firmDetails?.Name}`}</strong> account. We greatly appreciate your assistance.
        </p>
        <p>We will inform the person who signed up that they can now continue with the 14 day trial with Etani.</p>
        <p className="font-weight-400">
          If you have any questions or require further assistance, please do not hesitate to reach out to our support team at <a href="mailto:support@etani.ai">support@etani.ai</a>.
        </p>
        <p className="font-weight-400">
          Thank you for your prompt and efficient support.
        </p>
      </ReactBSAlert >
    );
  };

  const warningAlert = (res) => {
    setIsError(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setIsError(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {res}
      </ReactBSAlert>
    );
  };

  const handleLogout = async () => {
    try {
      const isLogout = await axios.get("/api/auth/forceLogout")
      // console.log(isLogout)
      if (isLogout.data.success) {
        return routerHistory.push(
          `/auth/login`
        );
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {isError}
      {success}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="8"
              sm="12"
              xs="12"
              className={`mt-2 px-0 px-lg-3`}
            >
              <Card className=" bg-transparent shadow-none p-3 mb-0 py-3 px-lg-5 mx-2 ">
                <CardImg
                  alt="Login"
                  src={"/api/accountingFirm/getThemeLogo"}
                  style={{ width: "150px" }}
                  className=" justify-content-center text-center rounded mx-auto"
                  top
                />
                <CardHeader className="px-0 pb-0 bg-transparent">
                  <h1 className="h2 text-center text-normal">
                    Payment Setup
                  </h1>
                </CardHeader>
                <CardBody className="pt-2 p-0">
                  {clientSecret && stripePromise ?
                    <>
                      <p className="h5 font-weight-400 text-justify text-small">
                        You have been selected to assist with the payment setup for <strong>{`${firmDetails?.Name}`}</strong> account. The <strong>{`${firmDetails?.Name}`}</strong> user, does not have access to a credit card or bank account and has entrusted this task to you.
                      </p>
                      <p className="h5 font-weight-400 text-justify text-small">
                        To complete the payment, please fill in the necessary details below.
                      </p>
                      <p className="h5 font-weight-400 text-justify text-small">
                        If you have any questions or require further assistance, please do not hesitate to reach out to our support team at <a href="mailto:support@etani.ai">support@etani.ai</a>.
                      </p>
                    </>
                    : null
                  }
                  <div className="pt-2 text-center">
                    {clientSecret && stripePromise ? (
                      <Elements stripe={stripePromise} options={{
                        appearance: {
                          theme: "stripe",
                        },
                        clientSecret,
                      }}>
                        <CheckoutForm
                          secret={clientSecret}
                          buttonstyle={{ width: "100%" }}
                          // userDetails={userDetails}
                          firmDetails={firmDetails}
                          stripeSuccess={stripeSuccess}
                          // userEmail={userDetails?.User?.Email}
                          description={firmDetails?.Name}
                          sharedSetup={true}
                          token={sharedToken}
                          firstTimeSetup={true}
                        />
                      </Elements>
                    )
                      : <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                    }
                    {
                      shared ? null
                        :
                        <div className="mt-2">
                          <Button
                            style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#272A65", width: "100%" }}
                            className="mt-2"
                            color="primary"
                            type="button"
                            onClick={handleLogout}
                          >
                            Logout
                          </Button>
                        </div>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SharedPaymentSetup;