import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Col, Row, Form, Modal } from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import ServiceList from "./ServiceList";
import { MainContext } from "context/mainContext";
import { CustomFieldGroup } from "customComponents/Form";
import BlockUI from "components/Misc/BlockUI";
import CustomSpinner from "components/Misc/CustomSpinner";
import AboutUsCard from "components/Cards/AboutUsCard";
import { CustomModalButton } from "components/Button/CustomButton";

const Services = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const { bgColor, textColor, userDetails } = useContext(MainContext);

  const [error, setError] = useState({
    name: "",
    description: "",
  });

  const [modal, setModal] = useState({
    title: "Add Services",
    show: false,
    record: {},
    action: "create",
  });

  let modalRecord = modal.record;

  useEffect(() => {
    fetch("/api/serviceOffer/getAllRecords", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          let res = [];

          setData(res);
          props.warningAlert(response);
        }

        // console.log(data);
        setData(response);
        setFilteredData(response);
        setLoading(!loading);
      })
      .catch((e) => {
        let res = [];

        setData(res);
        setFilteredData(res);
        props.warningAlert(e);
      });

    return () => {
      setData(data);
      setLoading(loading);
      setFilteredData(filteredData);
    };
    //eslint-disable-next-line
  }, [alert, modal]);

  const confirmDelete = (record) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() => handleDelete(record)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        showCancel
        confirmBtnText="Ok"
        btnSize=""
      >
        Are you sure you want to remove this service?
      </ReactBSAlert>
    );
  };

  const openModal = (record) => {
    setError({
      name: "",
      description: "",
    });
    if (record) {
      setModal({
        title: "Update Service",
        show: true,
        record: record,
        actionUrl:
          "/api/serviceOffer/updateRecord?ServiceOfferID=" +
          record.ServiceOfferID,
        actionUrlMethod: "PUT",
      });

      // console.log(record);
    } else {
      setModal({
        title: "Add New  Service",
        show: true,
        record: {},
        actionUrl: "/api/serviceOffer/createRecord",
        actionUrlMethod: "POST",
      });
    }

    // setModal(modal)
  };

  const closeModal = () => {
    setModal({
      title: "Add Services",
      show: false,
      record: modalRecord,
      action: "create",
    });
    setError({
      name: "",
      description: "",
    });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    modal.record[name] = value;
    setError({
      name: "",
      description: "",
    });
    setModal(modal);
  };

  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = data.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilteredData(filtered);
  };

  const handleDelete = (record) => {
    fetch(
      "/api/serviceOffer/deleteRecord?ServiceOfferID=" + record.ServiceOfferID,
      {
        method: "DELETE",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return alert(response.error);
        else {
          // alert(response.message);
          // confimDelete(response)
          props.successAlert(response);

          for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i].ServiceOfferID === record.ServiceOfferID) {
              filteredData.splice(i, 1);
              break;
            }
          }
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      (modalRecord.Name === undefined || modalRecord.Name === "") &&
      (modalRecord.Description === undefined || modalRecord.Description === "")
    ) {
      return setError({
        name: "Name is required!",
        description: "Description is required!",
      });
    }
    if (modalRecord.Name === undefined || modalRecord.Name === "") {
      return setError({
        name: "Name is required!",
        description: error.description,
      });
    }

    if (
      modalRecord.Description === undefined ||
      modalRecord.Description === ""
    ) {
      return setError({
        name: error.name,
        description: "Description is required!",
      });
    }

    setShowBlockUI(true);

    fetch(modal.actionUrl, {
      method: modal.actionUrlMethod,
      body: JSON.stringify(modal.record),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          if (response.error) {
            setShowBlockUI(false);
            return props.warningAlert(response);
          }

          modal.show = false;

          if (modal.actionUrlMethod === "POST") {
            response.record.id = data.length + 1;
            filteredData.push(response.record);
          }
          // console.log(data);
          setData(data);
          setError(error);
          setModal(modal);
          setShowBlockUI(false);
          setFilteredData(data);
          props.successAlert(response);
        }, 2000);
      });
  };

  const serviceList = () => {
    if (filteredData.length === 0) {
      if (loading) return <CustomSpinner />;
      return (
        <div className="col-sm-12 text-center">
          <h5>No services found.</h5>
        </div>
      );
    } else {
      return filteredData.map((data, i) => {
        return (
          <ServiceList
            i={i}
            data={data}
            key={data.id}
            openModal={openModal}
            confirmDelete={confirmDelete}
          />
        );
      });
    }
  };

  return (
    <AboutUsCard>
      {alert}
      {showBlockUI ? <BlockUI /> : null}
      <Row>
        <Col>
          <div>
            {userDetails?.User?.Details?.Authority?.Admin ? (
              <Button
                style={{
                  backgroundColor: `${
                    bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`,
                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                  border: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                }}
                type="button"
                onClick={() => openModal()}
                size="sm"
              >
                <i className="ni ni-fat-add" />
                Add New Service
              </Button>
            ) : null}
          </div>
        </Col>
        <Col sm="4" className="mt-2">
          <Input
            placeholder="Search"
            type="text"
            bsSize="sm"
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="mt-4 ">{serviceList()}</Row>

      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={modal.show}
        // toggle={() => closeModal()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {modal.title}
          </h6>
        </div>
        <div className="modal-body ">
          <Form role="form" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <CustomFieldGroup
                  id="Name"
                  name="Name"
                  type="text"
                  label="Service Name"
                  placeholder="Enter Service Name"
                  onChange={handleInputChange}
                  defaultValue={modalRecord.Name || ""}
                  // required={true}
                  style={
                    error.name !== ""
                      ? { border: "1px solid #fb6340 " }
                      : { border: "" }
                  }
                  error={
                    error.name !== "" ? (
                      <span className="text-warning text-sm">
                        <i className="fas fa-exclamation-circle mr-2 text-warning" />
                        {error.name}
                      </span>
                    ) : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id="Description"
                  name="Description"
                  type="textarea"
                  label="Description"
                  placeholder="Enter Description"
                  rows={6}
                  onChange={handleInputChange}
                  defaultValue={modalRecord.Description || ""}
                  // required={true}
                  style={
                    error.description !== ""
                      ? { border: "1px solid #fb6340 " }
                      : { border: "" }
                  }
                  error={
                    error.description !== "" ? (
                      <span className="text-warning text-sm">
                        <i className="fas fa-exclamation-circle mr-2 text-warning" />
                        {error.description}
                      </span>
                    ) : null
                  }
                />
              </Col>
            </Row>

            <CustomModalButton
              submitButton={null}
              closeButton={() => closeModal()}
              submitText="Save"
              cancelText="Cancel"
              submitType="submit"
            />
          </Form>
        </div>
      </Modal>
    </AboutUsCard>
  );
};

export default Services;
