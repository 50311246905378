/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";

const DynamicInputs = ({ data, ...props }) => {
  const [logo, setLogo] = useState();
  const [fields, setFields] = useState();

  useEffect(() => {
    setLogo(props.logo);
    setFields(renderFields());
    //eslint-disable-next-line
  }, []);

  const renderFields = () => {
    const fields = [];
    if (data) {
      data.map((val, i) => {
        return fields.push(
          <FormGroup key={i}>
            <label className="form-control-label" htmlFor={val.id}>
              {val.label}
            </label>
            {val.type === "email" ? (
              <Input
                placeholder={val.placeholder}
                type="email"
                name={val.name}
                required={val.isRequired}
                onChange={val.onChange}
                defaultValue={val.value ? val.value : ""}
                className="getData"
                id={val.id}
              />
            ) : val.type === "textarea" ? (
              <Input
                rows={val.rows}
                type="textarea"
                placeholder={val.placeholder}
                name={val.name}
                onChange={val.onChange}
                defaultValue={val.value ? val.value : ""}
                className="getData"
                id={val.id}
              />
            ) : val.type === "file" ? (
              <Row>
                <Col>
                  <div className="custom-file">
                    <input
                      className="custom-file-input getData"
                      id={val.id}
                      lang="en"
                      type="file"
                      name={val.name}
                      accept="image/*"
                      onChange={val.onChange}
                    />
                    <label className="custom-file-label" htmlFor={val.id}>
                      Select file
                    </label>
                  </div>
                </Col>
                <Col>
                  <img
                    src={logo || val.value}
                    style={{
                      maxWidth: "100px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    // alt="Logo"
                  />
                </Col>
              </Row>
            ) : val.type === "checkbox" ? (
              <div>
                <label className="custom-toggle custom-toggle-success mr-1">
                  <input
                    defaultChecked
                    type="checkbox"
                    className="getData"
                    id={val.id}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            ) : (
              <Input
                placeholder={val.placeholder}
                type="text"
                name={val.name}
                required={val.isRequired}
                id={val.id}
                onChange={val.onChange}
                defaultValue={val.value ? val.value : ""}
                className="getData"
              />
            )}
          </FormGroup>
        );
      });
    }
    return fields;
  };

  return (
    <>
      {/* <Form onSubmit={props.handleSubmit}> */}
      {fields}
      {/* </Form> */}
    </>
  );
};

export default DynamicInputs;
