import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  FormGroup,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
// core components
import ClientsInfo from "../../../components/ClientInfo/ClientInfo";
import CustomImageFieldGroup from "customComponents/Form";
import UserCard from "../../../components/Cards/UserCard";
import { dataFilter } from "utils/utilityFunctions";
import PanelCard from "components/Cards/PanelCard";
import { MainContext } from "context/mainContext";
import UpdateModal from "./updateModal";
import BlockUI from "components/Misc/BlockUI";
import CustomHeader from "customComponents/customHeader";
import CustomSpinner from "components/Misc/CustomSpinner";
import { CustomButton } from "components/Button/CustomButton";
import { useHistory } from "react-router";
import { CustomImageFieldGroupSquare } from "customComponents/Form";

const Users = () => {
  const {
    bgColor,
    textColor,
    setSelectedClientID,
    selectedClientID,
    userDetails,
    firmDetails,
    getDetails,
  } = useContext(MainContext);
  const history = useHistory();
  const [defaultModal, setdefaultModal] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [payload, setPayload] = useState({});
  // eslint-disable-next-line
  const [accountants, setAccountants] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [clientUsers, setClientUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [avatar, setAvatar] = useState("");
  // eslint-disable-next-line
  const [isNameValid, setIsNameValid] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [clientID, setClientID] = useState("");
  // eslint-disable-next-line
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [emailFormatValid, setEmailFormatValid] = useState(true);
  const [fNameValid, setFNameValid] = useState(true);
  const [lNameValid, setLNameValid] = useState(true);
  const [evaEnabled, setEvaEnabled] = useState(false);
  const [azureAvatar, setAzureAvatar] = useState(null);

  const fetchAPIs = () => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    // eslint-disable-next-line
    let url = window.location.pathname.split("/");
    let clientID = url[url.length - 1];
    let firstClient = [];

    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')

    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {
      fetch(`/api/client/getAll`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log(data)
            setClients(data);
            let _client = data.find(client => client.ClientID == clientID);
            firstClient = _client ? _client : data[0];
          } else {
            return;
          }
        })
        .then(() => {
          if (firstClient) {
            //console.log(url.length);
            //console.log(url);
            setClientID(sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]);
            // setSelectedClientID(selectedClientID ? selectedClient :
            //   url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            // );
            fetch(
              `/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                if (data && !data.error) {

                  // console.log(data)
                  setSelectedClient(data);
                  fetch(`/api/user/getAllRecords/${data.id}`)
                    .then((res) => res.json())
                    .then((data) => {
                      if (data && !data.error) {
                        setClientUsers(data);
                        setFilteredData(data);
                        setIsLoading(false);
                      } else {
                        return;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  setIsLoading(false);
                  return;
                }


              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
              });
          }

        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      fetch(`/api/client/getAllRecords`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log("/api/client/getAllRecords", data);
            setClients(data);
            firstClient = data[0];

          } else {
            return;
          }
        })
        .then(() => {
          if (firstClient) {
            //console.log(url.length);
            //console.log(url);
            setClientID(sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.id : url[url.length - 1]);
            // setSelectedClientID(selectedClientID ? selectedClientID :
            //   url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            // );
            fetch(
              `/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                if (data && !data.error) {
                  // console.log(data);
                  if (data.message === "Client not found") {
                    setClientID(firstClient.ClientID);
                    setSelectedClientID(firstClient.ClientID);
                    return history.push(`/auth/page-not-found`);
                  }
                  setSelectedClient(data);
                  fetch(`/api/user/getAllRecords/${data.id}`)
                    .then((res) => res.json())
                    .then((data) => {
                      if (data && !data.error) {
                        // console.log(data)
                        setClientUsers(data);
                        setFilteredData(data);
                        setIsLoading(false);
                      } else {
                        setIsLoading(false);
                        return;
                      }
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      console.log(err);
                    });
                } else {
                  setIsLoading(false);
                  return;
                }

              })
              .catch((err) => {
                console.log(err);
              });
          }

        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    // console.log('selectedClientID', selectedClientID)    
    setFilteredData([])
    setIsLoading(true)
    let url = window.location.pathname.split("/");
    if (!selectedClientID && url.length >= 4) {
      let clientID = url[url.length - 1];
      history.push(`/client/users/${clientID}`);
    }
    if (selectedClientID) {
      history.push(`/client/users/${selectedClientID}`);
    }
    setTimeout(() => {
      fetchAPIs();
    }, 50);
    return () => {
      //added to cleanup asynchronous task
      console.log(clientUsers)
      setClientUsers(clientUsers);
      setFilteredData(filteredData);
      setIsLoading(isLoading);
    };
    //eslint-disable-next-line
  }, [userDetails, firmDetails, selectedClientID]);

  const handleSearch = (e) => {
    let filterString = e.target.value;
    setFilteredData(dataFilter(filterString, clientUsers));
  };

  const handleImgChange = (img) => {
    // console.log("click")
    setAvatar(img);
    // setIsAvatarUpdate(true);
  };

  const userUpdate = (data) => {
    // console.log(data);
    setIsUpdate(true);
    setSelectedUser(data);
    setPayload(data);
    setAvatar(data.Avatar);
    setdefaultModal(true);
    setEvaEnabled(data.Details.hasOwnProperty('EvaEnabled') ? data.Details.EvaEnabled : false)

    // setupdateModal(!updateModal);
  };
  const redirectCustomGroup = () => {
    history.push(`/client/custom-group`);
  }
  const addClientUser = () => {
    // console.log(firmDetails?.PricingPlan?.Details?.ClientUsers)
    // if (firmDetails?.Details?.Trial) {
    //   warningAlert(
    //     "planSubscribe",
    //     `You are not yet subscribe to a plan. Please click continue to redirect to Plan subscribe page.`,
    //     null
    //   )
    // } else 
    setShowBlockUI(true);
    fetch("/api/accountingFirm/getRecord")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          // console.log(data)
          setShowBlockUI(false);

          if (data?.PricingPlan?.Code === 'TIER1') {
            let totalUserCount = data?.ClientUserCount ? data?.ClientUserCount : 0;
            // console.log(totalUserCount)
            if (totalUserCount >= firmDetails?.PricingPlan?.Details?.ClientUsers) {
              warningAlert(
                "planUpgrade",
                `The maximum number of Users has been reached. Do you want to upgrade your plan?`,
                null
              )
            } else {
              setdefaultModal(true)
            }
          } else if (data?.PricingPlan?.Code === 'TIER6') {
            setdefaultModal(true)
          } else {
            // totalUserCount is the total count of client users and advisers if Plan is not TIER1
            let totalUserCount = data?.ClientUserCount ? data?.ClientUserCount + data?.Accountant.length : 0 + data?.Accountant.length;
            // console.log(totalUserCount)
            if (totalUserCount >= firmDetails?.PricingPlan?.Details?.ClientUsers) {
              warningAlert(
                "planUpgrade",
                `The maximum number of Users has been reached. Do you want to upgrade your plan?`,
                null
              )
            } else {
              setdefaultModal(true)
            }
          }
        } else {
          setShowBlockUI(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowBlockUI(false);
      });
  }

  const confirmAddSeat = () => {
    setAlert(
      <ReactBSAlert
        info
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Info"
        onConfirm={() => {
          setdefaultModal(true)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        You are about to add seat for a new User. Click continue to proceed.
      </ReactBSAlert>
    );
  }

  const handleInputChange = (e) => {
    const newPayload = { ...payload };
    let target = e.target;
    let val = target.type === "checkbox" ? target.checked : target.value;
    let key = e.target.id;

    if (target.type === "checkbox") {
      val = target.checked;
      // console.log(key, val)
    } else if (target.type === "file") {
      const reader = new FileReader();
      const file = target.files[0];

      reader.onload = function (upload) {
        setAvatar(upload.target.result);

        let inputElement = target.nextSibling;
        inputElement.value = upload.target.result;
        inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      };
      reader.readAsDataURL(file);
    } else {
      val = target.value;
    }
    if (key === "Email") {
      const emailRegExp = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      let result = emailRegExp.test(target.value);
      if (!val || !result || !val.match(/\S/g) || !result) {
        setEmailValid(false);
        setEmailFormatValid(false);
      } else {
        setEmailValid(true);
        setEmailFormatValid(true);
      }
    }
    if (key === "FirstName") {
      if (!val || !val.match(/\S/g)) {
        setFNameValid(false);
      } else {
        setFNameValid(true);
      }
    }
    if (key === "LastName") {
      if (!val || !val.match(/\S/g)) {
        setLNameValid(false);
      } else {
        setLNameValid(true);
      }
    }

    newPayload[key] = val;
    if (key === 'ClientAdmin') {
      newPayload['UserAccess'] = val === true ? 5 : 6
      delete newPayload['ClientAdmin']
    }

    if (key === 'EvaEnabled') {
      setEvaEnabled(val)
    }
    // console.log(newPayload)
    setPayload(newPayload);
  };

  const confirmDelete = (data) => {
    // console.log(data)
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => handleDelete(data)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        showCancel={true}
        btnSize=""
      >
        <p>
          Are you sure you want to delete{" "}
          <strong className="text-warning">{`${data.FirstName} ${data.LastName}`}</strong>
          's record?
        </p>
        {/* {`Are you sure you want to delete record of ${data.FirstName} ${data.LastName}?`} */}
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="error"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const confirmUpdate = () => {
    updateModal
      ? setUpdateAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => setUpdateAlert(!updateAlert)}
          onCancel={() => setUpdateAlert(null)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Successfully Updated
        </ReactBSAlert>
      )
      : setUpdateAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => setUpdateAlert(!updateAlert)}
          onCancel={() => setUpdateAlert(null)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Sent an email invitation
        </ReactBSAlert>
      );
  };

  const successAlert = (message) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const warningAlert = (type, message, obj) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() =>
          type === 'planSubscribe' ? redirectToAboutUsPlanTab() : redirectToPricing()
        }
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        {message}
      </ReactBSAlert>
    );
  };

  const redirectToAboutUsPlanTab = () => {
    setAlert(null);
    setdefaultModal(false);
    sessionStorage.setItem("aboutUsTabView", 2);
    return history.replace("/client/accounting-firm/details");
  }

  const redirectToPricing = () => {
    setAlert(null);
    setdefaultModal(false);
    return history.replace("/client/plans");
  };

  // const errorAlert = (message) => {
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: 'block', marginTop: '-100px' }}
  //       title="Warning"
  //       onConfirm={() => setAlert(null)}
  //       onCancel={() => setAlert(null)}
  //       confirmBtnBsStyle="warning"
  //       confirmBtnText="Ok"
  //       btnSize=""
  //     >
  //       {message}
  //     </ReactBSAlert>,
  //   )
  // }

  const handleDelete = (user) => {
    // let newAccountants = filteredData.filter(
    //   (usersInfo) => usersInfo.id !== data.id
    // );
    // setFilteredData(newAccountants);
    // setAlert(!alert);
    // console.log('selectedClient', selectedClient)
    // console.log('selectedUser', user)
    setShowBlockUI(true);
    let newUsersList = [...filteredData];
    if (user.Client.length > 1) {
      // console.log('multi')
      fetch(`/api/client/deleteClientUser/${selectedClient.id}/${user.id}/${user.Business[0].UserAccess}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.message === "Revoke client access successful") {
            newUsersList.filter((nusr) => nusr.id !== user.id);
            // console.log(newUsersList.filter(nusr => nusr.id !== user.id))
            successAlert(data.message);
            setFilteredData(newUsersList.filter((nusr) => nusr.id !== user.id));
            setClientUsers(newUsersList.filter((nusr) => nusr.id !== user.id));
            // fetchAPIs()
            setShowBlockUI(false);
            getDetails();
          }
          if (data.error) {
            setShowBlockUI(false);
            errorAlert(data.message);
          }
        });
    } else {
      // console.log('single')
      fetch(`/api/user/deleteRecord/${user.id}/${user.Business[0].UserAccess}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log("Delete RECORD", data);
          if (data.message === "Record has been deleted") {
            setShowBlockUI(false);
            successAlert(data.message);
            fetchAPIs();
          }
          if (data.error) {
            //Edited by Joef. data.message is empty
            setShowBlockUI(false);
            errorAlert(data.error);
          }
        });
    }
  };

  const handleUpdateUser = (usersInfo) => {
    setAccountants(usersInfo);
    setupdateModal(!updateModal);
    setPayload(payload);
    confirmUpdate();
  };

  const handleCloseModal = () => {
    setIsUpdate(false);
    setdefaultModal(false);
    setPayload({});
    setSelectedUser({});
    setAvatar("");
    setEmailFormatValid(true);
    setEmailValid(true);
    setFNameValid(true);
    setLNameValid(true);
  };

  const handleAzureAvatar = (file) => {
    setAzureAvatar(file)
  }

  const handleSave = () => {
    setShowBlockUI(true);
    let newPayload = payload;
    newPayload.Avatar = avatar;
    newPayload.SendEmail = true;
    newPayload.EvaEnabled = evaEnabled
    // console.log(newPayload)
    // console.log(evaEnabled)
    const emailRegExp = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    let result = emailRegExp.test(newPayload.Email);
    if (
      !newPayload.Email ||
      !result ||
      !newPayload.Email.match(/\S/g) ||
      !result
    ) {
      setEmailValid(false);
      setEmailFormatValid(false);
      setShowBlockUI(false);
      return;
    }
    // if(!newPayload.Email){
    //   setEmailValid(false)
    //   setShowBlockUI(false)
    //   return
    // }
    if (!newPayload.FirstName) {
      setFNameValid(false);
      setShowBlockUI(false);
      return;
    }
    if (!newPayload.LastName) {
      setLNameValid(false);
      setShowBlockUI(false);
      return;
    }
    if (emailValid && emailFormatValid && fNameValid && lNameValid) {
      if (isUpdate) {
        fetch(`/api/user/updateRecord/${payload.id}/${clientID}?clientId=${selectedClient.id}`, {
          method: "PUT",
          body: JSON.stringify(newPayload),
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data)
            if (data.error) {
              setShowBlockUI(false);
              errorAlert(data.error);
            }
            if (data.record) {
              setShowBlockUI(false);
              successAlert(data.message);
              handleCloseModal();
              fetchAPIs();
            }
          });
      } else {
        fetch(`/api/user/createRecord?clientId=${selectedClient.id}`, {
          method: "POST",
          body: JSON.stringify(newPayload),
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setShowBlockUI(false);
              errorAlert(data.error);
            }
            if (data.record) {
              setShowBlockUI(false);
              successAlert(data.message);
              handleCloseModal();
              getDetails();
              fetchAPIs();
            }
            // console.log(data)
          });
      }
    }
  };

  const handleUpdateSave = () => {
    let oldIndex;
    filteredData.forEach((data, i) => {
      if (data.id === payload.id) {
        oldIndex = i;
      }
    });
    let newFilteredData = [...filteredData];
    newFilteredData.splice(oldIndex, 1, payload);
    setFilteredData(newFilteredData);
    setupdateModal(!updateModal);
    confirmUpdate();
  };

  const showBlockUIModal = () => {
    return <BlockUI />;
  };

  const handleResendEmail = (data) => {
    // console.log(data)
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() => {
          setShowBlockUI(true);
          fetch(`/api/accountant/resendEmailInvitation/${data.UserID}`, {
            method: "GET",
            credentials: "include",
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log(data)
              if (data.error) {
                setShowBlockUI(false);
                return errorAlert(data.error);
              } else {
                setShowBlockUI(false);
                successAlert(data.message);
              }
            });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Confirm"
        btnSize=""
        showCancel={true}
      >
        <p>
          Are you sure you want to re-send email invitation for user:{" "}
          <strong className="text-warning">{`${data.FirstName} ${data.LastName}`}</strong>
        </p>
      </ReactBSAlert>
    );
  };

  return (
    <>
      {updateAlert}
      {alert}
      <CustomHeader />
      <Container className="mt-5 fadeIn" fluid>
        {/* <div className="mt-3">
          <ClientsInfo
            data={selectedClient}
            clientsList={clients}
            isLoading={isLoading}
            page={"users"}
          />
        </div> */}
        <div className="mt-6 fadeIn">
          <div className="nav-wrapper">
            <PanelCard headerName="Users">
              <Row>
                <Col lg="8" sm="4">
                  {clients?.length <= 0 ? null : isLoading ? null : (
                    <div className="mb-3">
                      <Button
                        style={{
                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          color: `${textColor === "#000" ? "#fff" : textColor}`,
                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                        }}
                        size="sm"
                        type="button"
                        onClick={() => addClientUser()}
                        id="addAccountant"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i
                            className="ni ni-fat-add"
                            style={{ fontSize: "1rem" }}
                          />
                        </span>
                        Invite User
                      </Button>
                      <Button
                        style={{
                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          color: `${textColor === "#000" ? "#fff" : textColor}`,
                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                        }}
                        size="sm"
                        type="button"
                        onClick={() => redirectCustomGroup()}
                        id="addAccountant"
                      >
                        Custom Group
                      </Button>

                      {showBlockUI ? showBlockUIModal() : null}

                      <Modal
                        className="modal-dialog-top"
                        isOpen={defaultModal}
                        toggle={() => setdefaultModal(!defaultModal)}
                        size="lg"
                        backdrop="static"
                      >
                        <div className="modal-header">
                          <h6 className="modal-title" id="modal-title-default">
                            {isUpdate ? "Update User" : `Invite User${selectedClient?.Name ? ` to ${selectedClient?.Name}` : ""}`}
                          </h6>
                          <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => handleCloseModal()}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Form>
                            <div>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                EVA enabled? <span className="font-weight-300"><em>{`($15 for EVA and $3 for normal)`}</em></span>
                              </label>
                              <div>
                                <Row>
                                  <Col className="col-lg-1" xs="2">
                                    <label className="custom-toggle custom-toggle-success mr-1">
                                      <input
                                        defaultChecked={selectedUser?.Details?.EvaEnabled ? true : false}
                                        type="checkbox"
                                        onClick={handleInputChange}
                                        id="EvaEnabled"
                                        name="EvaEnabled"
                                      // disabled={
                                      //   props.selectedAccountant?.User?.id ===
                                      //     props.userDetails?.User?.id
                                      //     ? true
                                      //     : false
                                      // }
                                      />
                                      <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                      />
                                    </label>
                                  </Col>
                                  <Col className="col-lg-11" xs="10">
                                    <p>
                                      Can ask EVA/Dashboard Templates.
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {
                              evaEnabled ?
                                <div>
                                  <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Is this user a User Admin?
                                  </label>
                                  <div>
                                    <Row>
                                      <Col className="col-lg-1" xs="2">
                                        <label className="custom-toggle custom-toggle-success mr-1">
                                          <input
                                            defaultChecked={selectedUser?.UserAccess === 5 ? true : false}
                                            type="checkbox"
                                            onClick={handleInputChange}
                                            id="ClientAdmin"
                                            name="ClientAdmin"
                                          // disabled={
                                          //   props.selectedAccountant?.User?.id ===
                                          //     props.userDetails?.User?.id
                                          //     ? true
                                          //     : false
                                          // }
                                          />
                                          <span
                                            className="custom-toggle-slider rounded-circle"
                                            data-label-off="No"
                                            data-label-on="Yes"
                                          />
                                        </label>
                                      </Col>
                                      <Col className="col-lg-11" xs="10">
                                        <p>
                                          Can add/update/delete thirt-party applications.
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                : null
                            }
                            <FormGroup>
                              <label className="form-control-label" htmlFor="Email">
                                Email
                              </label>
                              <Input
                                placeholder="Email"
                                type="email"
                                name="Email"
                                required={true}
                                id="Email"
                                onChange={handleInputChange}
                                defaultValue={
                                  selectedUser && selectedUser.Email
                                    ? selectedUser.Email
                                    : ""
                                }
                                className="getData"
                                style={{
                                  border:
                                    emailValid || emailFormatValid
                                      ? "1px solid #dee2e6"
                                      : "1px solid red",
                                }}
                                disabled={isUpdate ? true : false}
                              />
                              {emailValid ? (
                                ""
                              ) : (
                                <small className="text-warning">
                                  This field is required.
                                </small>
                              )}{" "}
                              <br />
                              {emailFormatValid ? (
                                ""
                              ) : (
                                <small className="text-warning">
                                  Please input correct email format.
                                </small>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="FirstName"
                              >
                                First Name
                              </label>
                              <Input
                                placeholder="FirstName"
                                type="text"
                                name="FirstName"
                                required={true}
                                id="FirstName"
                                onChange={handleInputChange}
                                defaultValue={
                                  selectedUser && selectedUser.FirstName
                                    ? selectedUser.FirstName
                                    : ""
                                }
                                className="getData"
                                style={{
                                  border: fNameValid
                                    ? "1px solid #dee2e6"
                                    : "1px solid red",
                                }}
                              />
                              {fNameValid ? (
                                ""
                              ) : (
                                <small className="text-warning">
                                  This field is required.
                                </small>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="LastName"
                              >
                                Last Name
                              </label>
                              <Input
                                placeholder="LastName"
                                type="text"
                                name="LastName"
                                required={true}
                                id="LastName"
                                onChange={handleInputChange}
                                defaultValue={
                                  selectedUser && selectedUser.LastName
                                    ? selectedUser.LastName
                                    : ""
                                }
                                className="getData"
                                style={{
                                  border: lNameValid
                                    ? "1px solid #dee2e6"
                                    : "1px solid red",
                                }}
                              />
                              {lNameValid ? (
                                ""
                              ) : (
                                <small className="text-warning">
                                  This field is required.
                                </small>
                              )}
                            </FormGroup>
                            <Row>
                              <Col>
                                {/* <CustomImageFieldGroup
                              id="Avatar"
                              name="Logo"
                              help="Upload Avatar"
                              onChange={handleInputChange}
                              value={selectedUser.Avatar}
                              label="Avatar"
                              alt="Avatar"
                              record={clientUsers}
                              handleImgChange={handleImgChange}
                              withResetImage={true}
                            /> */}
                                <CustomImageFieldGroupSquare
                                  id="Avatar"
                                  name="Logo"
                                  help="Upload Avatar"
                                  onChange={handleInputChange}
                                  value={selectedUser.Avatar}
                                  label="Avatar"
                                  alt="Avatar"
                                  record={clientUsers}
                                  handleImgChange={handleImgChange}
                                  handleImgChangeIcon={handleImgChange}
                                  handleAzureImgChangeLogo={handleAzureAvatar}
                                  oldLogo={selectedClient.Avatar}
                                  withResetImage={true}
                                />
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div className="modal-footer">
                          <CustomButton
                            type="button"
                            onClick={() => handleSave()}
                            outlineStyle={false}
                            title={isUpdate ? "Save" : "Invite"}
                          />
                          <CustomButton
                            onClick={() => handleCloseModal()}
                            outlineStyle={true}
                            data-dismiss="modal"
                            className="ml-auto"
                            type="button"
                            color="link"
                            title="Close"
                          />
                        </div>
                      </Modal>
                    </div>
                  )}
                </Col>
                <Col lg="4" sm="8">
                  <div className="mb-3">
                    <Input
                      placeholder="Search"
                      type="text"
                      bsSize="sm"
                      onChange={handleSearch}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {isLoading ? (
                  <CustomSpinner />
                ) : filteredData.length <= 0 ? (
                  <div className="col-sm-12 mt-3 text-center">
                    <h5> No Users found.</h5>
                  </div>
                ) : (
                  filteredData.map((user, i) => (
                    <Col lg="4" md="4" sm="12" key={i}>
                      <UserCard
                        data={user}
                        isEditable={true}
                        userUpdate={userUpdate}
                        confirmDelete={confirmDelete}
                        confirmUpdate={confirmUpdate}
                        handleResendEmail={handleResendEmail}
                      />
                      <UpdateModal
                        i={i}
                        updateModal={updateModal}
                        bgColor={bgColor}
                        textColor={textColor}
                        handleUpdateUser={handleUpdateUser}
                        handleInputChange={handleInputChange}
                        handleUpdateSave={handleUpdateSave}
                        handleCloseModal={handleCloseModal}
                        user={user}
                      // openUpdate={openUpdate}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </PanelCard>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Users;
