import React, { useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { evaThemeLighter } from 'services/EVAResponseService'

function TreeviewGraph({ sqlResult, gridHeader, theme, treeViewData, isWithChild, isShowGrap }) {
    const [itemIndex, setItemIndex] = useState(0)
    // const [itemToGrid, setitemToGrid] = ([])
    const itemToGrid = treeViewData
    const GraphData = []
    const headers = []

    useEffect(()=> {
        // setitemToGrid(treeViewData)
    }, [itemIndex])

    itemToGrid && itemToGrid.map((dataItem) => {
        
        const arrayData = dataItem.children

        const header = dataItem.label
        headers.push(header)
        const resultObject = []

        arrayData.reduce((acc, item) => {
            console.log(item)
            const [label, value] = item.clabel ? item.clabel : item.label;
            const itemLabel = item.clabel ? item.clabel : item.label
            if (label !== "Total Amount")
            {
                resultObject.push({ x: itemLabel[0], y: typeof itemLabel[1] === "string" ? Number(itemLabel[1].replace(/,/g, '')) : itemLabel[1]})
            }
                
            }, {});

        const uniqueLabelsArray = [...new Set(Object.keys(resultObject))];

        GraphData.push({"header" : dataItem.label,"labels" : uniqueLabelsArray,"chartData": resultObject})
    });

    const labels = GraphData[itemIndex].labels
    const data = GraphData[itemIndex].chartData

    const chartData = {
        labels: data.map(item => item.x),
        datasets: [
          {
            label: headers[itemIndex],
            data: data.map(item => item.y),
            backgroundColor: theme.PrimaryColor,
            borderColor: theme.PrimaryColor,
            borderWidth: 1
          }
        ]
      };
    
      const chartOptions = {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      };

    const handleIndexChange = (index) => {
        setItemIndex(index)
    }
    return (
        <div>
            <div className='graphHeader'>
            {headers.map((header, index) => (
                <div 
                    className={index === itemIndex && 'isActive'}
                    key={index}
                    onClick={() => handleIndexChange(index)}
                    style={index === itemIndex ? evaThemeLighter(100, theme) :
                    { border: `1px solid ${theme.PrimaryColor}`, color: theme.PrimaryColor }}
                    >
                    {header}
                </div>
            ))}
            </div>
            <Line data={chartData} options={chartOptions} />
        </div>
    )
}
export default TreeviewGraph