import socket from 'chat-components/utils/socket';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, Input, Table } from 'reactstrap'
import { evaThemeLighter } from 'services/EVAResponseService'
import SendExpoNotifications from 'services/ExpoNotificationService';
import SendMultiDeviceNotification from 'services/NotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { sendMessage } from 'services/mongoDB';
import { getCustomGroups } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { generateUUID } from 'services/EVAResponseService';
import { registerGroupChatNotification } from 'services/mongoDB';

const SharetoOther = ({messages, ...props}) => {
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [groupList, setGroupList] = useState([])
    const [groupUserList, setGroupUserList] = useState([])
    const { userDetails, selectedClientID, selectedClient, firmDetails } = useContext(MainContext);
    const [selectAll, setSelectAll] = useState(false);
    const [alert, setAlert] = useState(false);
    useEffect(() => {
        const loadData = async () => {
            console.log(messages)
            await fetchClientGroups()
        }

        loadData()
    },[selectedClientID])

    const fetchClientGroups = async () => {
        const groups = [{ _id: selectedClient.ClientID, GroupName:selectedClient.Name }]

        const res = await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email || '' })
        if (Array.isArray(res)) {
            res.forEach(group => {
                groups.push({
                    _id: group._id,
                    GroupName: group.GroupName
                });
            });
        }
        setGroupUserList(res)
        setGroupList(groups)
    }
    const handleShareEVAResponse = async () => {
        if(selectedGroup.length === 0) return
        await shareEVAResponse(selectedGroup)
        handleSuccessUpdate()
    }
    const shareEVAResponse = async (selectedGroup) => {
        selectedGroup.map((group) => {
            send(group._id, userDetails.User)
        })
    }
    const send = async (groupId, user) => {
        const fullName = user.FirstName + ' ' + user.LastName
        const newMessage = {
            clientid: selectedClientID,
            groupId: groupId,
            userid: user.UserID,
            _id: Math.floor(Math.random() * 10000000000000),
            text: messages.text,
            sender: user.UserID,
            visual: messages.visual,
            user: {   
                _id: user.UserID,
                sender: user.UserID,
                name: fullName
            },
            createdAt: new Date(),
        }
    
        let newNotification = newMessage
        newNotification.type = 'chat'
        newNotification.senderEmail = userDetails.User.Email

        await sendCMSNotification(newNotification, groupId)
        await sendMessage(newMessage)
        await sendNotificationHandler(fullName,selectedClient.Name,messages.text)
        await socket.emit("newMessage", newMessage);
        // await socket.emit("newNotification", newNotification);
        console.log(newNotification)
        await socket.emit("newGroupChatNotification", newNotification);
        
    }
    const sendCMSNotification = async (newNotification, groupId) => {
        const param = {
            uid : await generateUUID(),
            id: newNotification._id,
            clientId: newNotification.clientid,
            message: newNotification.text,
            sender: userDetails.User.Email,
            url: `/client/chat/${groupId}`,
            // users: clientUsers,
            groupId: newNotification.groupId,
            // senderEmail: newNotification.senderEmail,
        }
        if(newNotification.clientid === newNotification.groupId)
        {
            param.users = await loadClientUser(selectedClient)
        }else{
            param.users = await loadGroupUsers(groupId)
        }
        await registerGroupChatNotification(param)
    }
    const loadGroupUsers = async (groupId) => {
        if(groupUserList)
        {
            const group = groupUserList.filter((group) => group._id === groupId)
            return group ? group[0].Users : null
        } return null
    }
    const loadClientUser = async (selectedClient) => {
        if(selectedClient === undefined || !selectedClient) return
        const userList = []
        await fetch(`/api/user/getAllRecords/${selectedClient.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            userList.push(data.map((user) => {
                return user.Email
            }));
          }
        })
        .catch((err) => {
            console.log(err);
        });
        const adviser = []
        await fetch("/api/accountant/getAllRecords", { credentials: "include" })
        .then((res) => res.json())
        .then((data) => {
            if (!data.error && data) 
            {
              adviser.push(data.map((user) => {
                  return user.User.Email
              }))
            }
        })
        .catch((err) => {
            console.log(err);
        });
    
        const combinedArray = userList.concat(adviser);
        return combinedArray.flat()
        // setClientUsers(combinedArray.flat())
    }
    const sendNotificationHandler = async (name,groupChat,msg) => {
        // const admin = require('firebase-admin');
        const isFile = msg.includes("/documents%2F")
        const isImage = msg.includes("/images%2F")
    
        const messageType = isImage
        ? 'image'
        : isFile
        ? 'document'
        : 'a message';
        
        const notification = {
          title: `${name} sent ${messageType} to the ${groupChat} group chat.`,
          body:  isFile || isImage ? '' : msg,
          sound: 'default',
          badge: '1',
        };
    
        const clientToken = await mongoFetchToken({ clientid : selectedClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = (clientToken.tokens || []).map(tokenObj => {
          socket.emit("sendNotification", tokenObj.token)
          return tokenObj.token
        });
        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: selectedClientID, Name: firmDetails.Name ,  type: 'chat'},
          notification: notification,
        };
    
        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };
    const handleSelectAll = () => {
        if (selectAll) {
          setSelectedGroup([]);
        } else {
          setSelectedGroup(groupList.map((user) =>  user));
        }
        setSelectAll(!selectAll);
    };
    const toggleSelected = (group) => {
        setSelectedGroup((prevSelectedGroup) => {
            if (prevSelectedGroup.map(g => g._id).includes(group._id)) {
                return prevSelectedGroup.filter((g) => g._id !== group._id);
            } else {
                return [...prevSelectedGroup, group];
            }
        });
    };
    const handleSuccessUpdate = () => {
        setAlert(
            <ReactBSAlert
              success
              title="Success"
              onConfirm={() => {
                setAlert(null)
                props.handleShowEvaModal()
              }}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              <>
                <p>
                  You have successfully shared EVA's response.
                </p>
              </>
            </ReactBSAlert>
        );
    }
    return (
        <>
        {alert}
        <div className="pin-response-container">
            <div className="pin-response-content">
                <h1>Share EVA Response</h1>
                <h5>Select an existing dashboard or create a new one</h5>
            
                {/* radio button here */}
                <div className='input-container'>
                <div className='userListModalDetails'>
                <Table>
                <thead>
                    <tr>
                        <th>
                            <Button color="primary" onClick={handleSelectAll} style={{ padding: '5px', fontSize: '10px', background: props.bgColor }}>
                                {selectAll ? 'Deselect All' : 'Select All'}
                            </Button>
                        </th>
                        <th>Client / Group Name</th>
                    </tr>
                </thead>
                <tbody>
                    {groupList.map((group, index) => (
                        <tr key={group._id} 
                            >
                            <td>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        bsSize="xl"
                                        checked={selectedGroup.map(g => g._id).includes(group._id)}
                                        onChange={() => toggleSelected(group)}
                                    />
                                </FormGroup>
                            </td>
                            <td onClick={() => toggleSelected(group)}>{group.GroupName}</td>
                        </tr>
                    ))}
                </tbody>
                </Table>

                    </div>
                </div>
                <div className="button-container">
                    <div style={evaThemeLighter(100,props.theme)} onClick={handleShareEVAResponse}>Share</div>
                    <div style={{ border: '1px solid ' + props.theme.PrimaryColor, color: props.theme.PrimaryColor }} onClick={props.handleShowEvaModal}>Cancel</div>
                </div>
            </div>
        </div>
        </>
    )
}
export default SharetoOther