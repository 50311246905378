import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button } from "reactstrap";
import moment from "moment";
const UserHistoryTable = ({ detailsType, data, isCurrent, ...props }) => {
  // console.log("USerHistory",data)
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/* <th scope="col">
              UserID
            </th> */}
            <th scope="col">
              Name
            </th>
            <th scope="col">Email</th>
            {
              isCurrent ? null
                :
                <th scope="col">Client</th>
            }
            <th scope="col">Is Useradmin?</th>
            <th scope="col">Eva Enabled?</th>
            {
              isCurrent ?
                <th scope="col">Multi Client?</th>
                : null
            }
            <th scope="col">{isCurrent ? "Price" : "Action"}</th>
            <th scope="col">Initiator</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.length >= 1 ? data?.map((d, i) => (
              <tr key={i}>
                {/* <th scope="row">{d.Details.User.UserID}</th> */}
                <td>{isCurrent ? `${d.User.FirstName} ${d.User.LastName}` : `${d.Details.User.User.FirstName} ${d.Details.User.User.LastName}`}</td>
                <td>{isCurrent ? d.User.Email : d.Details.User.User.Email}</td>
                {
                  isCurrent ? null
                    :
                    <td>{d.Details.Client.Name}</td>
                }
                {
                  isCurrent ?
                    <td>{d.UserAccess === 5 ? "Yes" : "No"}</td>
                    :
                    <td>{d.Details.IsAdmin ? "Yes" : "No"}</td>
                }
                {
                  isCurrent ?
                  <td>{d.Details?.EvaEnabled ? "Yes" : "No"}</td>
                  :
                  <td>{d.Details?.User?.Details?.EvaEnabled ? "Yes" : "No"}</td>
                }
                {
                  isCurrent ?
                    <td>{d.IsMultiClient ? "Yes" : "No"}</td>
                    : null
                }
                <td>{isCurrent ? `${d.IsMultiClient ? "A$30" : d.Details?.EvaEnabled ? "A$15" : "A$3"}` : d.Action}</td>
                <td>{isCurrent ? d.LastAddedBy.Email : `${d.Details?.Initiator.id <= 4 ? 'System' : `${d.Details?.Initiator.Email}`}`}</td>
                <td>{moment(isCurrent ? d.createdAt : d.createdAt).format("DD-MM-YYYY LT")}</td>
              </tr>
            ))
              : null
          }
        </tbody>
      </Table>
      {
        data?.length < 1 ?
          <Row>
            <Col>
              <p className="text-center text-sm mt-3">No data found.</p>
            </Col>
          </Row>
          : null
      }
    </>
  )
}
export default UserHistoryTable;