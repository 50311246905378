import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { registerDashboardTemplate } from 'services/mongoDB'
import ReactBSAlert from "react-bootstrap-sweetalert";

function DashboardTemplateAccessSetting({ selectedTemplate, showAccessModal, showAccessSettingModal, firmDetails, selectedClient, contextClientLists }) {
    const [selectedTemplateAccessType, setSelectedTemplateAccessType] = useState('private')
    const [selectedClientOption, setSelectedClient] = useState('All Client')
    const [clientList, setClientList] = useState(null)
    const [selectedClientList, setSelectedClientList] = useState([])
    const [selectedUserType, setSelectedUserType] = useState("All Users")
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        console.log(selectedTemplate)
        setClientList(firmDetails.Client)
        if(selectedTemplate.userAccessSetting === undefined) return

        setSelectedTemplateAccessType(selectedTemplate.userAccessSetting?.accessType)
        setSelectedClient(selectedTemplate.userAccessSetting?.clientOption ? selectedTemplate.userAccessSetting?.clientOption : 'All Client')
        setSelectedClientList(selectedTemplate.userAccessSetting?.selectClient ? selectedTemplate.userAccessSetting?.selectClient : [])


    },[selectedTemplate])

    useEffect(() => {
    },[clientList])
    
    const handleSelectAccessType = (event) => {
        setSelectedTemplateAccessType(event.target.value);
    }
    const handleSelectClientsType = (event) => {
        setSelectedClient(event.target.value);
    }
    const handleSelectUserType = (event) => {
        setSelectedUserType(event.target.value)
    }
    const handleSaveChanges = () => {
        const param = { 
            accessType: selectedTemplateAccessType,
            clientOption: selectedClientOption,
            selectedClient: selectedClientList
        }
        selectedTemplate.userAccessSetting  = param
       
        try{
            registerDashboardTemplate(selectedTemplate);
            handleSuccessUpdate(`You have successfully updated ${selectedTemplate.name} access setting!`)
        }catch(error)
        {
            handleErrorUpdate(`Something went wrong while updating ${selectedTemplate.name} access setting!`)
        }
       
    }
    const handleCloseModal = () => {
        showAccessSettingModal()
    }
    const handleSelectClient = (item) => {
        setSelectedClientList((prevItems) => {
            const index = prevItems.findIndex((prevItem) => prevItem.ClientID === item.ClientID);
            if (index > -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1);
                return updatedItems;
            } else {
                return [...prevItems, item];
            }
        })
    }
    const handleSuccessUpdate = (msg) => {
        setAlert(
            <ReactBSAlert
              success
              title="Success"
              onConfirm={() => {
                setAlert(null)
                showAccessSettingModal()
              }}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
                <p>
                  {msg}
                </p>
            </ReactBSAlert>
        );
    }
    const handleErrorUpdate = (msg) => {
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                onConfirm={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                <p>
                  {msg}
                </p>
            </ReactBSAlert>
        );
    };
    return (
        <>
          {alert}
          <Modal isOpen={showAccessModal}>
            <ModalBody>
                <FormGroup tag="fieldset">
                    <legend>User Access Setting</legend>
                    <CustomInput
                        type="radio"
                        id="private"
                        name="templateAccess"
                        value="private"
                        label="Private"
                        checked={selectedTemplateAccessType === 'private'}
                        onChange={handleSelectAccessType}
                    />
                    <CustomInput
                        type="radio"
                        id="public"
                        name="templateAccess"
                        value="public"
                        label="Public"
                        checked={selectedTemplateAccessType === 'public'}
                        onChange={handleSelectAccessType}
                    />
                </FormGroup>
                {selectedTemplateAccessType === 'public' &&
                    <>
                        <FormGroup tag="fieldset">
                            <legend>Client Option</legend>
                            <CustomInput
                                type="radio"
                                id="All Client"
                                name="selectClient"
                                value="All Client"
                                label="All Client"
                                checked={selectedClientOption === 'All Client'}
                                onChange={handleSelectClientsType}
                            />
                            <CustomInput
                                type="radio"
                                id="Specific Client"
                                name="selectClient"
                                value="Specific Client"
                                label="Specific Client"
                                checked={selectedClientOption === 'Specific Client'}
                                onChange={handleSelectClientsType}
                            />
                        </FormGroup>
                       {selectedClientOption === 'Specific Client' &&
                            <div className='clientListAccessSetting'>
                                 <legend>Client List</legend>
                                {clientList && clientList.map((item, index) => 
                                (
                                    <div
                                        className='templateItem'
                                        key={index}
                                    >
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    bsSize="xl"
                                                    checked={selectedClientList && selectedClientList.length > 0 && selectedClientList.some(selectedItem => selectedItem.ClientID === item.ClientID)}
                                                    onChange={() => handleSelectClient(
                                                    { 
                                                        ClientID: item.ClientID, 
                                                        name: item.Name, 
                                                    })}
                                                />
                                                {item.Name}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>
                        }
                         {/* <FormGroup tag="fieldset">
                            <legend>User Option</legend>
                            <CustomInput
                                type="radio"
                                id="All Users"
                                name="selectUsers"
                                value="All Users"
                                label="All Users"
                                checked={selectedUserType === 'All Users'}
                                onChange={handleSelectUserType}
                            />
                            <CustomInput
                                type="radio"
                                id="Specific Users"
                                name="selectUsers"
                                value="Specific Users"
                                label="Specific Users"
                                checked={selectedUserType === 'Specific Users'}
                                onChange={handleSelectUserType}
                            />
                        </FormGroup> */}
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSaveChanges}>Save</Button>
                <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
       
    )
}

export default DashboardTemplateAccessSetting