import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import CustomHeader from "customComponents/customHeader";
import ClientsInfo from "../../../components/ClientInfo/ClientInfo";
import PanelCard from "components/Cards/PanelCard";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BlockUI from "components/Misc/BlockUI";

const SignClient = () => {
  const {
    bgColor,
    textColor,
    setSelectedClientID,
    selectedClientID,
    userDetails,
  } = useContext(MainContext);
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [updateAlert] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientID, setClientID] = useState("");
  const [showBlockUI, setShowBlockUI] = useState(false);

  const fetchAPIs = () => {
    // eslint-disable-next-line
    let url = window.location.pathname.split("/");
    let firstClient = [];

    // if(userDetails && userDetails.User && userDetails.User.UserAccess === 1){
    fetch(`/api/client/getAllRecords`)
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          // console.log(data)
          let unsignedClient = data.filter((clnt) => clnt.Status === 1);
          setClients(unsignedClient);
          firstClient = unsignedClient[0];
        } else {
          return;
        }
      })
      .then(() => {
        if (firstClient) {
          //console.log(url.length);
          //console.log(url);
          setClientID(url.length < 4 ? firstClient.id : url[url.length - 1]);
          setSelectedClientID(
            url.length < 4 ? firstClient.ClientID : url[url.length - 1]
          );
          fetch(
            `/api/client/showDetails/${url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            }`
          )
            .then((res) => res.json())
            .then((data) => {
              if (data && !data.error) {
                // console.log(data)
                setSelectedClient(data);
                // fetch(`/api/user/getAllRecords/${data.id}`)
                //   .then((res) => res.json())
                //   .then((data) => {
                //     if (data && !data.error) {
                //       setClientUsers(data)
                //       setFilteredData(data)
                //     } else {
                //       return
                //     }
                //   })
              } else {
                return;
              }
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // console.log('selectedClientID', selectedClientID)
    if (selectedClientID) {
      history.push(`/client/signclient/${selectedClientID}`);
    }
    setTimeout(() => {
      fetchAPIs();
    }, 1500);
    return () => {
      //added to cleanup asynchronous task
      // setClientUsers(clientUsers)
      // setFilteredData(filteredData)
      setIsLoading(isLoading);
    };
    //eslint-disable-next-line
  }, [userDetails]);

  const errorAlert = (message) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="error"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const successAlert = (message) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => redirectToUserLists()}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const handleShowBlockUI = () => {
    return <BlockUI />;
  };

  const signClientEngagement = () => {
    setShowBlockUI(true);
    fetch(`/api/client/updateSignEngagementForClient/${clientID}`, {
      method: "PUT",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(access);

        //console.log(accountant);

        if (data.error) {
          setShowBlockUI(false);
          return errorAlert(data.error);
        }
        setShowBlockUI(false);
        successAlert(data.message);
      });
  };

  const redirectToUserLists = () => {
    setAlert(null);
    history.push(`/client/users/${clientID}`);
  };

  return (
    <>
      {updateAlert}
      {alert}
      {showBlockUI ? handleShowBlockUI() : null}
      <CustomHeader />
      <Container className="mt-5 fadeIn" fluid>
        {/* <div className="mt-3">
          <ClientsInfo
            data={selectedClient}
            clientsList={clients}
            isLoading={isLoading}
            page={"signclient"}
          />
        </div> */}
        <PanelCard headerName="Sign Client">
          <Row>
            <Col>
              <div className="col-sm-12 mt-3 text-center">
                <h4>
                  Please note we recommend you gain the necessary permissions
                  from this client (email, letter, phone call confirmation or
                  downloading of the app and accepting the Terms and Conditions)
                  before bringing in 3rd Party Data Sources.
                </h4>
                <div className="mt-4">
                  <Button
                    style={{
                      backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                        }`,
                      color: `${textColor === "#000" ? "#fff" : textColor}`,
                      borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                        }`,
                    }}
                    size="md"
                    type="button"
                    onClick={() => signClientEngagement()}
                    id="signClient"
                  >
                    Sign Engagement
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </PanelCard>
      </Container>
    </>
  );
};

export default SignClient;
