import React, { useContext } from "react";
import { MainContext } from "context/mainContext";
import { CustomButton } from "components/Button/CustomButton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap";

const PricingDetailsModal = ({ record, ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);

  return (
    <Modal isOpen={props.isOpen} className="modal-dialog ribbon-overflow">
      <CardHeader className="h3 rounded-top">{record.name} Plan</CardHeader>
      <div className="modal-body pt-1">
        <h2
          className="display-4 w-100 font-weight-700"
          style={{
            color: bgColor === "#fff" ? "rgb(94, 114, 228)" : bgColor,
          }}
        >
          {record.price} <small style={{ fontSize: "14px" }}> / month</small>
        </h2>
        <div className="my-3 text-justify ">
          <p className="h4">Key features:</p>
          <p className="mb-0 h4 text-muted font-weight-500">
            Power BI Templates, Microsoft Azure Server (2 Factor Authentication
            Microsoft Azure Setup Included), SQL Database, Power BI integration,
            API Data Integrations, Instant Messaging, Push Notifications, Tax
            Tools, Content Management System, Online Training Platform, Support
            (Business Hours), 2x 90 Minute onboarding sessions
          </p>
        </div>
        <Row>
          <Col xs="12" xl="12" sm="12" md="12">
            <CustomFeatures
              name="Power BI Basic Client Data Integrations"
              info={record.basicIntegration}
            />
            <CustomFeatures
              name="Power BI Extended Client Data Integrations"
              info={record.extendedIntegration}
              infoName={
                <span className="mt-3">
                  <a
                    href="https://www.etani.com.au/integrations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Integration Library (XPM, Deputy, SimPro etc)
                  </a>
                </span>
              }
            />
            <CustomFeatures
              name="Client Users Needing Access to Embedded Reports"
              info={record.basicIntegration}
              url={
                <a
                  href="https://www.etani.com.au/frequently-asked-questions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              }
            />
            <CustomFeatures
              name="Accountant Users Included"
              info={record.accountantUser}
              url={
                <a
                  href="https://www.etani.com.au/frequently-asked-questions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              }
            />
            <CustomFeatures
              name="Power BI Pro Licences Included"
              info={record.powerBIProLiscense}
            />
            <CustomFeatures
              name="Scheduled PDF Email Reports"
              info={record.scheduledPDF}
              url={
                <a
                  href="https://www.etani.com.au/blog/dynamic-custom-automated-power-bi-pdf-emails"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              }
            />
            <CustomFeatures
              name="Branded Mobile App (Apple/Android)"
              info={record.mobile}
            />
            <CustomFeatures
              name="  Data ‘Refresh Now’ Functionality"
              info={record.refresh}
              url={
                <a
                  href="https://www.etani.com.au/frequently-asked-questions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              }
            />
          </Col>
        </Row>
        <div className="modal-footer p-0 d-flex justify-content-between">
          <Button
            block
            style={{
              backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              color: `${textColor === "#000" ? "#fff" : textColor}`,
              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
            }}
          >
            <a
              style={{
                color: `${textColor === "#000" ? "#fff" : textColor}`,
              }}
              target="_blank"
              rel="noreferrer"
              href="https://www.etani.com.au/free-trial-new"
              type="button"
            >
              Sign Up (30 days Trial)
            </a>
          </Button>
        </div>
      </div>
      <i
        className="fas fa-times text-muted text-sm"
        style={{
          position: "absolute",
          right: 16,
          top: 15,
          zIndex: 100,
          cursor: "pointer",
        }}
        onClick={() => props.closeFullDetails(record.id)}
      ></i>
    </Modal>
  );
};

export default PricingDetailsModal;

export const CustomPricingCard = ({ record, ...props }) => {
  // const checkIcon = <i className="fas fa-check-circle text-success mr-3" />

  const { bgColor } = useContext(MainContext);
  return (
    <Card className="card-pricing border-0 mt-3 ribbon-overflow p-2">
      {record.ribbon === true ? (
        <div className="ribbon">Most Popular</div>
      ) : null}
      <CardHeader
        className="px-lg-4 p-sm-3"
      // style={{
      //   background: `${props.bgColor === '#fff' ? '' : props.bgColor}`,
      // }}
      >
        <h1 className="h2 mb-0 font-weight-700">{record.name} Plan</h1>
      </CardHeader>
      <CardBody className="px-lg-4 p-sm-3">
        <p
          className="display-4 font-weight-700 d-flex align-items-center"
          style={{
            // color: props.bgColor === '#fff' ? 'rgb(94, 114, 228)' : '',
            color: `${props.bgColor === "#fff" ? "rgb(94, 114, 228)" : props.bgColor
              }`,
          }}
        >
          {record.price} <small style={{ fontSize: "14px" }}> / month</small>
        </p>
        <div className="mt-3 ">
          <p className="h5 mt-3">Etani Business Platform</p>
          <p className="h5 mb-3">GST EXCLUSIVE</p>
        </div>

        <Row className="mb-4 mt-2 pt-3 d-flex align-items-start flex-column justify-content-center border-top">
          <Col xs="12" xl="12" sm="12" md="12">
            <CustomFeatures
              name="Power BI Basic Client Data Integrations"
              info={record.basicIntegration}
            />
            <CustomFeatures
              name="Power BI Extended Client Data Integrations"
              info={record.extendedIntegration}
              infoName={
                <span className="mt-2">
                  <a
                    href="https://www.etani.com.au/integrations"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: bgColor === "#fff" ? "#525f7f" : bgColor,
                    }}
                  >
                    Integration Library (XPM, Deputy, SimPro etc)
                  </a>
                </span>
              }
            />
            <CustomFeatures
              name="Client Users Needing Access to Embedded Reports"
              info={record.basicIntegration}
              opacity={false}
              url={
                <p
                  className="text-xs mb--1"
                  style={{
                    color: bgColor === "#fff" ? "#525f7f" : bgColor,
                  }}
                >
                  Learn More
                </p>
              }
            />
          </Col>
        </Row>

        <div>
          <CustomButton
            block
            className="btn mb-1"
            title="View full details"
            onClick={() => props.showFullDetails(record.id)}
            outlineStyle={false}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export const CustomPricingTable = ({ detailsData }) => {
  return (
    <Row className="row-grid justify-content-center p-4">
      <Col className="px-0">
        <Table className=" my-5" striped responsive>
          <thead>
            <tr>
              <th className="px-0 bg-transparent" scope="col">
                <p className="m-0 font-weight-700">
                  <strong>Features</strong>
                </p>
              </th>
              <th className=" bg-transparent" scope="col">
                <p className="m-0 font-weight-700">
                  <strong>Business Plan</strong>
                </p>
              </th>
              <th className=" bg-transparent" scope="col">
                <p className="m-0 font-weight-700">
                  <strong>Accounting Firm Plan</strong>
                </p>
              </th>
              <th className=" bg-transparent" scope="col">
                <p className="m-0 font-weight-700">
                  <strong>Extended Firm Plan</strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {detailsData.map((record) => {
              return (
                <tr key={record.id}>
                  <td className="px-0 font-weight-600">{record.title}</td>
                  <td className="">
                    <i className="fas fa-check-circle text-success mr-3" />
                    <span>{record.businessPlan} </span>
                    {record.link ? (
                      <a href={record.link} target="_blank" rel="noreferrer">
                        - Learn More
                      </a>
                    ) : null}
                  </td>
                  <td className="">
                    <i className="fas fa-check-circle text-success mr-3" />
                    <span>
                      {record.accountingPlan}{" "}
                      {record.link ? (
                        <a href={record.link} target="_blank" rel="noreferrer">
                          - Learn More
                        </a>
                      ) : null}
                    </span>
                  </td>
                  <td className="">
                    <i className="fas fa-check-circle text-success mr-3" />
                    <span>
                      {record.extendedPlan}{" "}
                      {record.link ? (
                        <a href={record.link} target="_blank" rel="noreferrer">
                          - Learn More
                        </a>
                      ) : null}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export const CustomFeatures = ({ name, info, infoName, url, opacity, additionalInfo, ...props }) => {
  return (
    <Row
      className=" pb-2 "
      style={{ opacity: opacity === false ? 0.3 : 1 }}
    >
      <Col xl="1" sm="1" xs="1" className="text-left" style={{ padding: `${props.hideCheck ? '23px 0' : null}` }}>
        {
          props.hideCheck ?
            null
            :
            <i className="fas fa-check-circle mr-3 text-sm text-success" />
        }
        {/* <i className="fas fa-check-circle mr-3 text-sm text-success" /> */}
      </Col>
      <Col xl="10" sm="10" xs="10" className="d-flex flex-column text-left">
        <h3 className="h5">
          {name} {url}
        </h3>
        <div className="d-flex flex-column " style={{ fontSize: "12px" }}>
          {info}
          {infoName}
          {additionalInfo}
        </div>
      </Col>
    </Row>
  );
};
