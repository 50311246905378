/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ClientThirdPartyApplications from "views/pages/clientThirdPartyApplications/ThirdPartyApplications";
import AccountantDetails from "views/pages/accountant/details/AccountantDetails";
import Announcements from "views/pages/pushNotification/Announcements";
import TrainingVideo from "views/pages/trainingVideo/TrainingVideo";
import ClientDashboard from "views/pages/clientDashboard/Dashboard";
import Accountant from "views/pages/accountant/index/Accountant";
import ClientReports from "views/pages/clientReports/Reports";
// import ClientReports from "views/pages/clientReports/Reports";
// import RedesignedReports from "views/pages/clientReports/RedesignedReports";
import PowerBIPage from "views/pages/clientPowerBI/PowerBIPage";
import ClientLists from "views/pages/clientLists/Lists";
import ClientUsers from "views/pages/clientUsers/Users";
import AboutUs from "views/pages/aboutUS/AboutUs";
import EvaAssistant from "views/pages/eva/Eva";
import MainDashboard from "views/pages/mainDashboard/MainDashboard";
import ChatForum from "views/pages/chat/ChatForum";
import CustomDashboard from "views/pages/dashboards/CustomDashboard";
import CustomGroups from "views/pages/customGroups/CustomGroups";
import DashboardTemplate from "views/pages/dashboards/DashboardTemplate";

const routes = [
  // {
  //   path: "/accountingfirm",
  //   name: "Accounting Firm",
  //   icon: "fa fa-home text-danger",
  //   component: AccountingFirm,
  //   layout: "/client",
  // },

  {
    path: "/maindashboard",
    name: "Main Dashboard",
    icon: "ni ni-circle-08 text-info",
    component: MainDashboard,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/accountant/details",
    name: "My Profile",
    icon: "ni ni-circle-08 text-info",
    component: AccountantDetails,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/business/details",
    name: "About Us",
    icon: "ni ni-bulb-61 text-primary",
    component: AboutUs,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/adviser-lists",
    name: "Advisers",
    icon: "ni ni-briefcase-24 text-orange",
    component: Accountant,
    layout: "/client",
    isActive: true,
  },
  {
    collapse: true,
    name: "Client",
    icon: "ni ni-money-coins text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/lists",
        name: "Client List",
        miniName: "C",
        component: ClientLists,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/users",
        name: "Users",
        miniName: "U",
        component: ClientUsers,
        layout: "/client",
        isActive: true,
      },
      // {
      //   path: "/advisers",
      //   name: "Advisers",
      //   miniName: "A",
      //   component: ClientAccountants,
      //   layout: "/client",
      //   isActive: false
      // },
      {
        path: "/dashboard",
        name: "Dashboards",
        miniName: "D",
        component: ClientDashboard,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/reports",
        name: "Reports",
        miniName: "R",
        component: ClientReports,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/powerbi",
        name: "Power BI",
        miniName: "P",
        component: PowerBIPage,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/thirdpartyapplication",
        name: "3rd Party Applications",
        miniName: "3",
        component: ClientThirdPartyApplications,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/chat",
        name: "EVA BETA",
        miniName: "4",
        component: ChatForum,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/eva",
        name: "EVA BETA",
        miniName: "4",
        component: EvaAssistant,
        layout: "/client",
        isActive: true,
      },
      {
        path: "/customDashboard",
        name: "Custom Dashboard",
        miniName: "CD",
        component: CustomDashboard,
        layout: "/client",
        isActive: true,
      },
    ],
  },
  // {
  //   path: "/pricing",
  //   name: "Pricing",
  //   icon: "ni ni-cart text-success",
  //   component: Pricing,
  //   layout: "/client",
  // },
  // {
  //   path: "/notification",
  //   name: "Notification",
  //   icon: "ni ni-bell-55 text-warning",
  //   component: Notification,
  //   layout: "/client",
  //   isActive: true
  // },
  {
    path: "/announcements",
    name: "Announcements",
    icon: "fas fa-solid fa-bullhorn text-success",
    component: Announcements,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/traningvideos",
    name: "Tutorial Videos",
    icon: "ni ni-tv-2 text-dark",
    component: TrainingVideo,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/custom-group",
    name: "Custom Groups",
    icon: "ni ni-tv-2 text-dark",
    component: CustomGroups,
    layout: "/client",
    isActive: true,
  },
  {
    path: "/templates",
    name: "Dashboard Templates",
    icon: "ni ni-tv-2 text-dark",
    component: DashboardTemplate,
    layout: "/client",
    isActive: true,
  },
];

export default routes;
