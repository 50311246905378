import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Input,
  Row,
  Col,
} from "reactstrap";
import { dataFilter } from "utils/utilityFunctions";

const Clients = ({ clients, selectedClients, ...props }) => {
  const [selected, setSelected] = useState({});
  const [, setRemoved] = useState({});
  const [filteredData, setFilteredData] = useState(clients);
  const [filterAccess, setFilterAccess] = useState(false);

  useEffect(() => {
    let selectedIds = {};
    let removedIds = {};

    selectedClients &&
      // eslint-disable-next-line
      selectedClients.map((selected) => {
        selectedIds[selected] = true;
      });

    setSelected(selectedIds);

    props.removedClients &&
      props.removedClients.map((removed) => {
        return (removedIds[removed] = true);
      });

    setRemoved(removedIds);
  }, [selectedClients, filteredData]);

  const removeAccess = (id) => {
    let selectedIds = selected;
    let removedIds = props.removedClients;
    removedIds.push(id);
    delete selectedIds[id];
    setSelected(selectedIds);
    props.handleClientSelection(selectedIds);
    props.setRemovedClients(removedIds);
  };

  const grantAccess = (id) => {
    let selectedIds = selected;
    selectedIds[id] = true;
    setSelected(selectedIds);
    props.handleClientSelection(selectedIds);

    let removedClients = props.removedClients.filter((e) => e !== id);
    props.setRemovedClients(removedClients);
  };

  const removeAllAccess = () => {
    let selectedIds = {};
    setSelected(selectedIds);
    props.handleClientSelection(selectedIds);

    props.setRemovedClients(selectedClients);
  };

  const grantAllAccess = () => {
    let selectedIds = selected;
    // eslint-disable-next-line
    filteredData.map((client) => {
      selectedIds[client.id] = true;
    });

    setSelected(selectedIds);
    props.handleClientSelection(selectedIds);
    clientList();
    props.setRemovedClients([]);
  };

  const handleSearch = (e) => {
    let filterString = e.target.value;
    setFilteredData(dataFilter(filterString, clients));
  };

  const selectFilter = (filterAccess) => {
    setFilterAccess(filterAccess);
  };

  const clientList = () => {
    return (
      filteredData &&
      // eslint-disable-next-line
      filteredData.map((data, i) => {
        if (
          !filterAccess ||
          selected.hasOwnProperty(data.id) === filterAccess
        ) {
          return (
            <Col lg="4" md="6" sm="6" xs="12" key={i}>
              <Card className="text-center zoom">
                <CardHeader>
                  <div style={{ height: "50px" }}>
                    <h5 className="h3 mb-0">{data.Name}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  {selected.hasOwnProperty(data.id) ? (
                    <Button
                      key={i}
                      style={{
                        backgroundColor: `#fb6340`,
                        color: `${props.textColor === "#000" ? "#fff" : props.textColor
                          }`,
                        borderColor: `#fb6340`,
                        borderRadius: "50px",
                      }}
                      color="warning"
                      onClick={() => removeAccess(data.id)}
                      size="sm"
                    >
                      Remove Access
                    </Button>
                  ) : (
                    <Button
                      key={i}
                      style={{
                        backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                          }`,
                        color: `${props.textColor === "#000" ? "#fff" : props.textColor
                          }`,
                        borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                          }`,
                        borderRadius: "50px",
                      }}
                      onClick={() => grantAccess(data.id)}
                      size="sm"
                    >
                      Grant Access
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Col>
          );
        }
      })
    );
  };

  return (
    <>
      <Row className="card-wrapper">
        <Col lg="8">
          <div className="mb-5">
            <ButtonGroup>
              <Button
                style={{
                  backgroundColor: `${!filterAccess
                      ? "#2dce89"
                      : props.bgColor === "#fff"
                        ? "#5e72e4"
                        : props.bgColor
                    }`,
                  borderColor: `${!filterAccess
                      ? "#2dce89"
                      : props.bgColor === "#fff"
                        ? "#5e72e4"
                        : props.bgColor
                    }`,
                  color: `${props.textColor === "#000" ? "#fff" : props.textColor
                    }`,
                }}
                size="sm"
                type="button"
                onClick={() => selectFilter(false)}
              >
                All
              </Button>
              <Button
                style={{
                  backgroundColor: `${filterAccess
                      ? "#2dce89"
                      : props.bgColor === "#fff"
                        ? "#5e72e4"
                        : props.bgColor
                    }`,
                  color: `${props.textColor === "#000" ? "#fff" : props.textColor
                    }`,
                  borderColor: `${filterAccess
                      ? "#2dce89"
                      : props.bgColor === "#fff"
                        ? "#5e72e4"
                        : props.bgColor
                    }`,
                }}
                size="sm"
                type="button"
                onClick={() => selectFilter(true)}
              >
                Access Granted
              </Button>
              <Button
                style={{
                  backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                    }`,
                  color: `${props.textColor === "#000" ? "#fff" : props.textColor
                    }`,
                  borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                    }`,
                }}
                size="sm"
                type="button"
                onClick={() => grantAllAccess()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" style={{ fontSize: "1rem" }} />
                </span>
                Grant All
              </Button>
              <Button
                style={{
                  backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                    }`,
                  color: `${props.textColor === "#000" ? "#fff" : props.textColor
                    }`,
                  borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                    }`,
                }}
                size="sm"
                type="button"
                onClick={() => removeAllAccess()}
              >
                <span className="btn-inner--icon mr-1">
                  <i
                    className="ni ni-fat-delete"
                    style={{ fontSize: "1rem" }}
                  />
                </span>
                Remove All
              </Button>
            </ButtonGroup>
          </div>
        </Col>
        <Col lg="4">
          <div className="mb-5">
            <Input
              placeholder="Search"
              type="text"
              bsSize="sm"
              onChange={handleSearch}
            />
          </div>
        </Col>
      </Row>
      <Row className="card-wrapper">{clientList()}</Row>
    </>
  );
};

export default Clients;
