import React, { useContext, useEffect, useState } from "react";
import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  CardBody,
} from "reactstrap";
import AccountingFirmInfo from "components/ProfileInfo/AccountingFirmInfo";
import CustomHeader from "customComponents/customHeader";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { MainContext } from "context/mainContext";
import MeetingTheTeam from "./MeetingTheTeam";
import Address from "./Address/Address";
import Services from "./Services/Services";
import Details from "./Details/Details";

import "./index.css";
import PaymentDetails from "components/Payment/PaymentDetails";
import PlanDetails from "./PlandDetails/PlanDetails";

import { useHistory } from "react-router";


const AboutUs = () => {
  const { setSelectedClientID, selectedClientID, bgColor, textColor, firmDetails, userDetails } = useContext(MainContext);
  const [tabs, setTabs] = useState(1)
  const [, setRecord] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [planList, setPlanList] = useState([])
  const history = useHistory();
  const [userPaymentDetails, setUserPaymentDetails] = useState(null)

  useEffect(() => {
    // console.log(firmDetails)
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    if(userDetails?.User?.UserAccess >= 5) {
      return history.push(`/`);
    }
    sessionStorage.getItem("aboutUsTabView") !== null ? setTabs(Number(sessionStorage.getItem("aboutUsTabView"))) : setTabs(1)
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    setSelectedClientID(sessionSelectedClientID ? sessionSelectedClientID : selectedClientID);
    fetch("/api/accountingFirm/getRecord", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setRecord(data);
        setFilteredData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });

    fetch("/api/pricing/getAllRecords")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          // setPricing(data);
          // console.log(data)
          // console.log(firmDetails)
          let filteredPricing = []
          // let filteredPricing = data.filter(d => firmDetails.Details.Plan.Code === d.Code || firmDetails.Details.Plan.Code === 'TIER1' && d.Code === 'TIER2' || firmDetails.Details.Plan.Code === 'TIER2' && d.Code === 'TIER3' || firmDetails.Details.Plan.Code === 'TIER4' && d.Code === 'TIER4')
          data.filter(d => d.CountryCode === firmDetails.CountryCode).map(d => {
            if (firmDetails.PricingPlan.Code === d.Code) {
              filteredPricing.push(d)
            }
            // if (!firmDetails.Details.Trial) {
            if (firmDetails.PricingPlan.Code === 'TIER1' && d.Code === 'TIER2') {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER2' && (d.Code === 'TIER1' || d.Code === 'TIER3')) {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER3' && (d.Code === 'TIER2' || d.Code === 'TIER4')) {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER4' && d.Code === 'TIER3') {
              filteredPricing.push(d)
            }
            // }
            // if (firmDetails.PricingPlan.Code === 'TIER4' && (d.Code === 'TIER3' || d.Code === 'TIER5')) {
            //   filteredPricing.push(d)
            // }
            // if (firmDetails.PricingPlan.Code === 'TIER5' && d.Code === 'TIER4') {
            //   filteredPricing.push(d)
            // }
          })
          // console.log(filteredPricing)
          setPlanList(filteredPricing)
        }
      })
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
      });
    getPaymentDetails()
    // fetch("/api/pricing/getAllRecords")
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data)
    //     if (data && !data.error) {
    //       setPlanList(data)
    //     }
    //   })
    //   .catch((e) => {
    //     setLoading(false);
    //   });

  }, [firmDetails]);

  const getPaymentDetails = () => {
    fetch(`/api/stripe/getCustomerPaymentDetails?email=${firmDetails?.Email}&description=${firmDetails?.Name}`, { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        // console.log('getCustomerPaymentDetails', data)
        if (data && !data.error) {
          if (data.message !== "No details found.") {
            setUserPaymentDetails(data.record)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response}
      </ReactBSAlert>
    );
  };

  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.error || response.message || response}
      </ReactBSAlert>
    );
  };

  const toggleNavs = (e, index) => {
    e.preventDefault()
    setTabs(index)
    sessionStorage.setItem("aboutUsTabView", Number(index));
  }

  return (
    <>
      {alert}
      <CustomHeader style={{ height: "100px" }} />
      <Container className="mt-5 fadeIn" fluid>
        <Row>
          <div className="col mt-3">
            <Card className="">
              <CardHeader>
                <div>
                  <AccountingFirmInfo
                    data={filteredData ? filteredData : null}
                    loading={loading}
                    bgColor={bgColor}
                  />
                </div>
                {/* <div className="nav-wrapper" style={{ paddingBottom: "0px" }}>
                  <h1 style={{ paddingBottom: "0px", fontSize: "23px" }}>
                    Details
                  </h1>
                  <hr
                    style={{
                      padding: "0px",
                      margin: "0px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "2px solid #fff",
                    }}
                  />
                </div> */}
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                    style={{ cursor: 'pointer' }}
                  >
                    <NavItem>
                      <NavLink
                        style={{
                          backgroundColor: `${tabs === 1 && bgColor === '#fff'
                            ? '#5e72e4'
                            : tabs === 1
                              ? bgColor
                              : '#fff'
                            }`,
                          color: `${tabs === 1 && textColor === '#000'
                            ? '#fff'
                            : tabs === 1
                              ? textColor
                              : '#525f7f'
                            }`,
                        }}
                        aria-selected={tabs && tabs === 1}
                        className={classnames('mb-sm-3 mb-md-0 ', {
                          active: tabs && tabs === 1,
                        })}
                        onClick={(e) => toggleNavs(e, 1)}
                        role="tab"
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    {
                      // !firmDetails.Details?.Trial && 
                      firmDetails.PricingPlan?.Code ?
                        <NavItem className="navColor">
                          <NavLink
                            style={{
                              backgroundColor: `${tabs === 2 && bgColor === '#fff'
                                ? '#5e72e4'
                                : tabs === 2
                                  ? bgColor
                                  : '#fff'
                                }`,
                              color: `${tabs === 2 && textColor === '#000'
                                ? '#fff'
                                : tabs === 2
                                  ? textColor
                                  : '#525f7f'
                                }`,
                            }}
                            aria-selected={tabs && tabs === 2}
                            className={classnames('mb-sm-3 mb-md-0', {
                              active: tabs && tabs === 2,
                            })}
                            onClick={(e) => toggleNavs(e, 2)}
                            role="tab"
                          >
                            Subscription
                          </NavLink>
                        </NavItem>
                        : null
                    }
                    {
                      // !firmDetails.Details?.Trial && 
                      firmDetails.PricingPlan?.Code ?
                        <NavItem className="navColor">
                          <NavLink
                            style={{
                              backgroundColor: `${tabs === 3 && bgColor === '#fff'
                                ? '#5e72e4'
                                : tabs === 3
                                  ? bgColor
                                  : '#fff'
                                }`,
                              color: `${tabs === 3 && textColor === '#000'
                                ? '#fff'
                                : tabs === 3
                                  ? textColor
                                  : '#525f7f'
                                }`,
                            }}
                            aria-selected={tabs && tabs === 3}
                            className={classnames('mb-sm-3 mb-md-0', {
                              active: tabs && tabs === 3,
                            })}
                            onClick={(e) => toggleNavs(e, 3)}
                            role="tab"
                          >
                            Billing
                          </NavLink>
                        </NavItem>
                        : null
                    }
                    {/* <NavItem className="navColor">
                      <NavLink
                        style={{
                          backgroundColor: `${tabs === 3 && bgColor === '#fff'
                            ? '#5e72e4'
                            : tabs === 3
                              ? bgColor
                              : '#fff'
                            }`,
                          color: `${tabs === 3 && textColor === '#000'
                            ? '#fff'
                            : tabs === 3
                              ? textColor
                              : '#525f7f'
                            }`,
                        }}
                        aria-selected={tabs === 3}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: tabs === 3,
                        })}
                        onClick={(e) => toggleNavs(e, 3)}
                        role="tab"
                      >
                        Our Offices
                      </NavLink>
                    </NavItem>
                    <NavItem className="navColor">
                      <NavLink
                        style={{
                          backgroundColor: `${tabs === 4 && bgColor === '#fff'
                            ? '#5e72e4'
                            : tabs === 4
                              ? bgColor
                              : '#fff'
                            }`,
                          color: `${tabs === 4 && textColor === '#000'
                            ? '#fff'
                            : tabs === 4
                              ? textColor
                              : '#525f7f'
                            }`,
                        }}
                        aria-selected={tabs === 4}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: tabs === 4,
                        })}
                        onClick={(e) => toggleNavs(e, 4)}
                        role="tab"
                      >
                        Our Services
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>

                <Card className="shadow-none">
                  <CardBody className="px-0">
                    <TabContent activeTab={"tabs" + tabs}>
                      <TabPane tabId="tabs1">
                        {/* {
                          !firmDetails.Details?.Trial ?
                            <>
                              <Row>
                                <Col>
                                  <PaymentDetails
                                    userPaymentDetails={userPaymentDetails}
                                    userDetails={userDetails}
                                  />
                                </Col>
                              </Row>
                            </>
                            : null
                        }
                        <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} /> */}
                        <Details
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                        />
                      </TabPane>
                      <TabPane tabId="tabs2">
                        {
                          firmDetails?.Details?.Plan ?
                            <PlanDetails
                              firmDetails={firmDetails}
                              planList={planList}
                            />
                            : null
                        }
                        {/* <PaymentDetails

                        /> */}
                      </TabPane>
                      <TabPane tabId="tabs3">
                        {
                          !firmDetails.Details?.Trial ?
                            <>
                              <Row>
                                <Col>
                                  <PaymentDetails
                                    userPaymentDetails={userPaymentDetails}
                                    userDetails={userDetails}
                                  />
                                </Col>
                              </Row>
                            </>
                            : null
                        }
                      </TabPane>
                      {/* <TabPane tabId="tabs2">
                        <MeetingTheTeam
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                        />
                      </TabPane>
                      <TabPane tabId="tabs3">
                        <Address />
                      </TabPane>
                      <TabPane tabId="tabs4">
                        <Services
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                        />
                      </TabPane> */}
                    </TabContent>
                  </CardBody>
                </Card>
              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
