import { IconTrash } from 'chat-components/ui/icon';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';

const PersonalDashboardList = ({ editMode,loading, personalDashboardList, handleActiveDasboard, showDeleteConfirmation, handleSelectDashboard, handleAddtoFavorite, dashboardUpdate}) => {
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID } = useContext(MainContext);

    const onHandleSetFavorite = (index) => {
        const param = { index, personalDashboardList, from: 'personal' }
        handleAddtoFavorite(param)
    }
    useEffect(() => {
        console.log(personalDashboardList)
    },[])
    useEffect(() => {
        console.log(personalDashboardList)
    },[dashboardUpdate])
    return (
        !selectedClientID ? 
            <Row className="text-center d-flex align-items-center justify-content-center">
            Please choose a client first.
            </Row>
            :
                loading ? <CustomSpinner />
                :
                personalDashboardList.map((data, index) => (
                    <Col lg="4" xl="3" md="4" sm="6" key={index}>
                        <Card className="dashboardListCard" style={handleActiveDasboard(index)}>
                            <CardBody className="d-flex flex-column p-2">
                                <Row>
                                    <Col>
                                        {data.groupId === undefined &&
                                            <div className='dashboardFavorite'>
                                                <i 
                                                    style={{ color: !data.isFavorite && bgColor }}
                                                    className={`${!data.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}
                                                    onClick={e => onHandleSetFavorite(index)}></i>
                                            </div>
                                        }
                                        <div className='groupItemContainer'>
                                            <div className='groupItemContainerIcon'>
                                                {data.isBI ? 
                                                    <img alt="..." className='mr-2' style={{ height: '21px', width: '25px'}} src={require("assets/img/bi.png").default} />
                                                    :
                                                    <img alt="..." className='mr-2' src={require("assets/img/etanilogo.png").default} />
                                                }
                                            </div>
                                            <div className='groupItemContainerTitle'>
                                                <div className='groupItemName' style={{color: bgColor}}>{data.name}</div>
                                                <div className='groupName'>{data.groupName}</div>
                                                {/* <div className='groupName'>{data.createdBy && `Created By: ${data.createdBy}`}</div> */}
                                            </div>
                                           
                                            {editMode &&
                                                <div className='deleteDashboardBtn'>
                                                    <IconTrash  onClick={(e) => {
                                                        e.stopPropagation(); // Stop event propagation
                                                        showDeleteConfirmation(data, index);
                                                    }} />
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div 
                                            className="btn-showDetails"
                                            style={{ background: bgColor, color: textColor }}
                                            onClick={() => handleSelectDashboard(data, index)}
                                            >View {!data.isBI ? 'Dashboard  Items' : 'Reports'}</div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                ))
        
    )
}
export default PersonalDashboardList