import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const WYSIWYGEditor = ({ value, onChange, placeholder, defaultValue, readOnly }) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content) => {
    onChange(content);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && !event.shiftKey) {
      event.preventDefault();
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection();
      if (range) {
        editor.format("indent", "+1");
      }
    }
  };

  return (
    <ReactQuill
      ref={editorRef}
      value={value}
      defaultValue={defaultValue}
      onChange={handleEditorChange}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      readOnly={readOnly}
    />
  );
};

export default WYSIWYGEditor;
