import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

const Authority = ({ authority, firmDetails, ...props }) => {
  const [payloadProp, setPayloadProp] = useState();

  const isAnalyticsDashboardReportsChecked =
    props.payload?.AnalyticsDashboardReports;

  useEffect(() => {
    setPayloadProp(props.payload);
    if (isAnalyticsDashboardReportsChecked) {
      props.payload.AnalyticsDashboardReportGreen = true;
      props.payload.AnalyticsDashboardReportOrange = true;
    }
  }, [props, authority, props.payload, payloadProp, props.handleInputChange]);

  return (
    <>
      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Is this User an Admin?
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.Admin}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="Admin"
                  name="Admin"
                  disabled={
                    props.selectedAccountant?.User?.id ===
                    props.userDetails?.User?.id
                      ? true
                      : false
                  }
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Can invite/delete admins, invite/delete clients, viewing
                rights to the App Analytics / Power BI
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Practice Manager Access
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PracticeManagerAccess}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PracticeManagerAccess"
                  name="PracticeManagerAccess"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Access/Create/Delete Content Management System (CMS) About Tab.
                (Details, Tax Tables, Publications, Meeting The Team, Our
                Offices, Our Services).
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {
        firmDetails?.Details?.PbiEnabled ? 
          <div>
            <label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Power BI
            </label>
            <div>
              <Row>
                <Col className="col-lg-1" xs="2">
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input
                      defaultChecked={props.payload?.AnalyticsDashboardReports}
                      type="checkbox"
                      onClick={props.handleInputChange}
                      id="AnalyticsDashboardReports"
                      name="AnalyticsDashboardReports"
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <input
                    defaultChecked={props.payload?.AnalyticsDashboardReportOrange}
                    type="checkbox"
                    onChange={props.handleInputChange}
                    id="AnalyticsDashboardReportOrange"
                    name="AnalyticsDashboardReports"
                    style={{ display: "none" }}
                  />
                  <input
                    defaultChecked={props.payload?.AnalyticsDashboardReportGreen}
                    type="checkbox"
                    onChange={props.handleInputChange}
                    id="AnalyticsDashboardReportGreen"
                    name="AnalyticsDashboardReportGreen"
                    style={{ display: "none" }}
                  />
                </Col>
                <Col className="col-lg-11" xs="10">
                  <p>
                    Are you allowed to go live on dashboards and reports to clients
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          : null
      }
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Push Notifications
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PushNotifications}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PushNotifications"
                  name="PushNotifications"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col className="col-lg-11" xs="10">
              <p>
                Share business articles, send instant messages and create
                scheduled & repeatable push notifications with clients
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Chat - Public and Individual Level
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.Chat}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="Chat"
                  name="Chat"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Cloud Document Portal - Access
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.PermanentDocs}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="PermanentDocs"
                  name="PermanentDocs"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div> */}
      <div>
        <label
          className="form-control-label"
          htmlFor="exampleFormControlInput1"
        >
          Create 3rd Party Connectors for clients
        </label>
        <div>
          <Row>
            <Col className="col-lg-1" xs="2">
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  defaultChecked={props.payload?.ThirdPartyConnectors}
                  type="checkbox"
                  onClick={props.handleInputChange}
                  id="ThirdPartyConnectors"
                  name="ThirdPartyConnectors"
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Authority;
