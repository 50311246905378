import React, { useCallback, useContext, useEffect, useState } from 'react'
import { deleteAllEVAHistoryItem, deleteEVAHistoryItem, fetchEVAMessagesPerUser, fetchLastMessagePerGroup, getCustomGroups } from '../services/mongoDB'
import { evaThemeLighter, generateUUID } from '../services/EVAResponseService'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { IconTrash } from './ui/icon';
import { MainContext } from 'context/mainContext';
import Group from './Group';
import moment from "moment";


const GroupList = ({ selectedGroupId, groupList, onSelectUUID, theme, onIsLoading ,  onSetAlert, onSelectGroup}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [history, getHistory] = useState([])
    const [formattedHistory, getFormattedHistory] = useState(null)
    const [deleteItemCount, setDeleteItemCount] = useState(0)
    const [newuuid, setNewuuid] = useState(null)
    const [selecteduuid, setSelecteduuid] = useState(null)
    const [alert, setAlert] = useState(false);
    const [activeGroup, setActiveGroup] = useState(null)
    const [grpMessage, setGrpMessage] = useState(null)
    const [clientMessage, setClientMessage] = useState(null)
    const { bgColor, textColor, selectedClientID, selectedClient, userDetails, userChatNotification, setSelectedGroupID, selectedGroupID } = useContext(MainContext);

    useEffect(() => 
    {
        const loadData = async () => {
            if(!selectedClientID) return
           const grpMessages = await fetchLastMessagePerGroup({ clientid : selectedClientID })
           setGrpMessage(grpMessages)
           setClientMessage(handleSetGrpMessage(grpMessages, selectedClientID))
           getNotificationCount(selectedClientID)
        }
        loadData()
    },[userChatNotification, selectedClientID])

    const handleItemClick = (uuid) => {
      handleLoading(true)
      setSelecteduuid(uuid)
      onSelectUUID(uuid);
    };

    const getNotificationCount = (groupId) => {
        if (userChatNotification) {
            const notification = userChatNotification.filter((item) => item.groupId === groupId);
            if (notification.length > 0) return notification[0].notificationCount;
            else return 0;
        }
        return 0;
    }
    
    const handleLoading = (value) => {
      console.log("Setting isLoading to:", value);
      onIsLoading(value);
    }    
    
    const groupMessagesByDate = (history) => {
        const groupedMessages = {};
      
        history.forEach((item) => {
          const createdAt = new Date(item.createdAt);
          const today = new Date();
      
          let label;
          if (createdAt.toDateString() === today.toDateString()) {
            label = 'Today';
          } else if (
            createdAt > new Date(today - 1 * 24 * 60 * 60 * 1000) &&
            createdAt <= today
          ) {
            label = 'Yesterday';
          } else if (createdAt > new Date(today - 7 * 24 * 60 * 60 * 1000)) {
            label = 'Last 7 days';
          } else if (createdAt > new Date(today - 30 * 24 * 60 * 60 * 1000)) {
            label = 'Last 30 days';
          } else {
            label = createdAt.toLocaleString('default', { month: 'short', year: 'numeric' });
          }
      
          if (!groupedMessages[label]) {
            groupedMessages[label] = [];
          }

          const now = new Date()
          const isToday = createdAt.toDateString() === now.toDateString();
          let formattedTime;
          if (isToday) {
            const hours = createdAt.getHours();
            const minutes = createdAt.getMinutes();
            const ampm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
            formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
          } else {
            // Format the date as "MM-DD" for dates that are not today
            const month = createdAt.getMonth() + 1; // Months are 0-indexed
            const day = createdAt.getDate();
            formattedTime = `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
          }
          item.createdAt = formattedTime
          groupedMessages[label].push(item);
        });
      
        return groupedMessages;
    };
    
  const handleClick = async () => {
      const newUUID = await generateUUID();
      handleItemClick(newUUID);
  };
  const truncateText = (text) => {
      if (text.length > 35) {
        return text.substring(0, 40) + '...';
      }
      return text;
  };
  const capitalize = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const selectGroup = (val) => {
    setSelectedGroupID(val)
    setActiveGroup(val)
    onSelectGroup(val)
  };

  const handleSetGrpMessage = (grpMessages, groupId) => {
        if (grpMessages) {
            const notification = grpMessages.filter((item) => item.groupId === groupId);
            if (notification) return notification[0];
            else return null;
        }
  }
    function limitText(text, maxLength) 
    {
        if (text.length <= maxLength) {
            return text;
        } else {
            return text.substring(0, maxLength) + "...";
        }
    }
    function formatText(text) 
    {
        if(text.includes('```json')) return 'shared EVA report'
        return text
    }

    return (
        <div className='client-group-list'>
        <div
            key={1}
            className='client-group-list-item'
            style={{
            ...(selectedGroupId === selectedClientID && evaThemeLighter(80, theme)),
            }}
            onClick={() => {selectGroup(selectedClientID)}}>
               
                {getNotificationCount(selectedClientID) > 0 &&
                    <div className='notificationCount' style={{ background: bgColor, color: textColor}}>
                        <span>{getNotificationCount(selectedClientID)}</span>
                    </div>
                }
                <div style={{ width: '100%'}}>
                    {clientMessage && clientMessage !== undefined ? 
                        <>
                            <div className='messageDetails'>
                                <span className='grpName'>{selectedClient.Name}</span>
                                {getNotificationCount(selectedClientID) === 0 &&
                                <span className='grpTime'>{moment(clientMessage.createdAt).fromNow()}</span>}
                            </div>
                            <span className='grpHistory'>
                              <strong>{capitalize(clientMessage.user.name)}</strong>: {' '}
                              {limitText(formatText(clientMessage.text), 35)}</span>
                        </>
                        :
                        <>
                            {selectedClient.Name}
                        </>
                    }
                </div>
        </div>

        {selectedClientID && groupList && groupList.map((group, index) => (
            <Group
                key={index}
                selectedGroupId={selectedGroupId}
                theme={theme}
                group={group}
                notificationCount={getNotificationCount(group._id)}
                selectGroup={selectGroup}
                groupId={group._id}
                grpMessages={grpMessage}
            />
        ))}
        </div>
    );
    
}



export default GroupList