import React, { useEffect, useState } from "react";
import { Row, Button, UncontrolledTooltip } from "reactstrap";

import List from "./userList";
import BlockUI from "components/Misc/BlockUI";
import CustomSpinner from "components/Misc/CustomSpinner";
import AboutUsCard from "components/Cards/AboutUsCard";

const MeetingTheTeam = ({ ...props }) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [showBlockUI, setShowBlockUI] = useState(false);

  useEffect(() => {
    fetch("/api/accountant/getMeetingTheTeam", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((user) => {
        setUser(user);
        setLoading(false);
        setFilterData(user);
        // console.log(user);
      })
      .catch((e) => {
        let res = [];

        setFilterData(res);
        setUser(res);
        console.log(e);
      });

    return () => {
      setFilterData(filterData);
      setLoading(loading);
      setUser(user);
    };

    //eslint-disable-next-line
  }, []);

  const showTrafficLight = (status, i) => (
    <div>
      <div
        // color={}
        id={!status ? `btn2${i}` : `success${i}`}
        // className="btn-sm mBtn"
        style={{
          height: 15,
          width: 15,
          borderRadius: 7.5,
          backgroundColor: !status ? "#f5365c" : "#2dce89",
        }}
      />
      <UncontrolledTooltip
        target={!status ? `btn2${i}` : `success${i}`}
        placement="top"
      >
        {!status
          ? "This accountant is not visible in the `Meet The Team` page of Accounting App."
          : "This accountant is visible in the `Meet The Team` page of Accounting App."}
      </UncontrolledTooltip>
    </div>
  );

  const displaySubTitle = (UserAccess) => {
    switch (UserAccess) {
      case 1:
        return "Super Admin";
      // break
      case 2:
        return "Accountant Super Admin";
      // break
      case 3:
        return "Accountant Admin";
      // break
      case 4:
        return "Accountant";
      // break
      case 5:
        return "Client Admin";
      // break
      case 6:
        return "Client User";
      // break
      default:
        return;
    }
  };

  const handleUpdate = (url, status) => {
    setShowBlockUI(true);

    fetch(url, {
      method: "PUT",
      body: JSON.stringify({ MeetingTheTeam: status }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          if (response.error) {
            props.warningAlert(response);
            setShowBlockUI(false);
          } else {
            props.successAlert(response);
            // console.log(response)

            for (let i = 0; i < user.length; i++) {
              if (user[i].AccountantID === response.record.AccountantID) {
                user[i].MeetingTheTeam = response.record.MeetingTheTeam;
                break;
              }
            }

            for (let i = 0; i < filterData.length; i++) {
              if (filterData[i].filterData === response.record.AccountantID) {
                filterData[i].MeetingTheTeam = response.record.MeetingTheTeam;
                break;
              }
            }
            setUser(user);
            setFilterData(filterData);
            setLoading(loading);
            setShowBlockUI(false);
          }
        }, 2000);
      });
  };

  const showStatus = (id, status) => {
    return (
      <Button
        style={{ borderRadius: 50 }}
        className="margin-top mBtn"
        color={!status ? "success" : "danger"}
        size="sm"
        onClick={
          !status
            ? (e) => {
                e.preventDefault();
                handleUpdate(
                  `/api/accountant/updateMeetTheTeam?AccountantID=${id}`,
                  true
                );
              }
            : (e) => {
                e.preventDefault();
                handleUpdate(
                  `/api/accountant/updateMeetTheTeam?AccountantID=${id}`,
                  false
                );
              }
        }
      >
        {!status ? "Include" : "Remove"}
      </Button>
    );
  };

  const userList = () => {
    if (filterData.length === 0) {
      if (loading) return <CustomSpinner />;

      return (
        <div className="col-sm-12 text-center">
          <h5>No Advisers found.</h5>
        </div>
      );
    } else {
      return filterData.map((data, i) => {
        return (
          <List
            showTrafficLight={showTrafficLight}
            displaySubTitle={displaySubTitle}
            key={i}
            i={i}
            showStatus={showStatus}
            data={data}
          />
        );
      });
    }
  };

  return (
    <AboutUsCard>
      {showBlockUI ? <BlockUI /> : null}

      <Row style={{ boxSizing: "border-box" }}>{userList()}</Row>
    </AboutUsCard>
  );
};

export default MeetingTheTeam;
