import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Row, Col, Button, Form, Modal, Container, Card } from "reactstrap";
import { MainContext } from "context/mainContext";
import EvaToggleSwitch from "components/EvaToggleSwitch/EvaToggle";
import CustomSpinner from "components/Misc/CustomSpinner";

require("dotenv").config();
const EvaClientList = ({ ...props }) => {
  const { bgColor, textColor, setSelectedClientID, selectedClientID, userDetails } = useContext(MainContext);
  const [isClearable] = useState(true);
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();
  const [clientName, setClientName] = useState(null);
  const [modal, setModal] = useState(false);
  const [canSeeEvaToggle, setCanSeeEvaToggle] = useState(false);
  const [isVisibleToClient, setIsVisibleToClient] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);

  const getClientName = (clientId) => {
    fetch(`/api/client/showDetails/${clientId}`)
    .then((res) => res.json())
    .then((data) => {
      setClientName(data.Name)
      setClientLogo(data.Logo);
    });    
  }


  const generateEVAIframeUrl = async (params) => {
      const details = await fetch("/api/eva/evaParams", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      const data = await details.json();

      if (data.success) {
        const token = data.data;
        const url = `${process.env.REACT_APP_EVA_URL}?token=${token}`;      
        props.setEvaIframeUrl(token);
      } else {
        props.setEvaIframeUrl(null);
      }
  }  

  const checkEvaAccess = (selectedClient) => {
        setIsLoading(true);
        const firmId = userDetails?.User?.AccountingFirm;
       
        const { User: { UserAccess } } = userDetails;

        fetch(`/api/eva/access?businessId=${firmId}&clientId=${selectedClient}`)
          .then((res) => res.json())
          .then(async (data) => { 
            if ([1,2,3,4].includes(UserAccess)) {
              setCanSeeEvaToggle(true);
            } else {
              setCanSeeEvaToggle(false);
              props.setEvaIframeUrl(null);
            }

            if (data.success && !data.data.isAdvisorOnly) {
              setIsVisibleToClient(true); 
            } else {
              setIsVisibleToClient(false); 
              props.setEvaIframeUrl(null);
            }

            if (
              data.success === true &&
              ([1,2,3,4].includes(UserAccess) ||
              data.data.isAdvisorOnly === false)
              ) {
              await generateEVAIframeUrl({
                businessId: firmId.toString(),
                clientId: selectedClient,
                clientName: data.data.clientName,
                userFullName: `${userDetails.User.FirstName} ${userDetails.User.LastName}`,
                userId: userDetails.User.UserID.toString(),
                user: userDetails.User.id.toString(),
              });
              props.setNoEvaAccess(false);
          } else {
            props.setEvaIframeUrl(null);
            props.setNoEvaAccess(true);
          }

            setIsLoading(false);
          }).catch((err) => { console.log(err); setIsLoading(false); });    
  }

  const handleChange = (selected) => {   
    console.log(selected) 
    if (selected?.value) {
      setSelectedClientID(selected?.value);
      getClientName(selected?.value);
      checkEvaAccess(selected?.value);
    }else{
      props.setEvaIframeUrl(null);
    }
  };

  const closeModalDiscliamerHandler  = () => setModal(false);

  const openDisclaimerModalHandler = () => setModal(true);

  useEffect(() => {   
    let optionList = [];
    props.options?.map((opt) => {
      return optionList.push({
        value: opt.ClientID,
        label: opt.Name
      });
    });
    setOptions(optionList);

  }, [props.options]);


  return (
    <> 
      <Container className="mt-5 fadeIn" fluid>
            <Card className="card-profile ">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <img
                      style={{
                        background: "#eee",
                        width: "140px",
                        objectFit: "cover",
                        borderRadius: "0.675rem",
                      }}
                      alt="..."
                      src={ clientLogo ?? require("assets/img/brand/logo-placeholder.png").default }
                    />
                  </div>
                </Col>
              </Row>
              </Card>
      </Container>               
      <Form sm="11" style={{ width: "600px" , "marginBottom": "20px", "marginTop": "40px" }}>
        <Row className="text-center d-flex align-items-center justify-content-center">
          <Col>
            <br />
            <h3 style={{ paddingBottom: "0px", fontSize: "23px" }}>{clientName ?? null}</h3>  
          </Col>
          {canSeeEvaToggle === true ?
          <Col>          
              <EvaToggleSwitch
                canSeeEva={true}
                isVisibleToClient={isVisibleToClient}
                clientId={selectedClientID}
                businessId={userDetails?.User?.AccountingFirm}        
                user={userDetails.User}            
              />
          </Col>
          : null}
          { isLoading ? <CustomSpinner    /> : null }
        </Row>

        <Row className="row-cols-lg-auto g-3 align-items-center">
          <Col lg="11" sm="11" xs="11" style={{ paddingRight: 0 }}>
            <div style={{ width: "99%" }}>
              <Select
                options={options}
                isClearable={isClearable}
                onChange={handleChange}
                placeholder={`Select Client`}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Modal
        className="modal-dialog-top"
        isOpen={modal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Disclaimer
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
The output generated by our Artificial Intelligence (AI) system, (known as EVA), including any advice, is based on data, algorithms and your inputs. It is not generated or checked by human experts, and as a result may contain false, misleading or incorrect responses. Whilst we strive to provide accurate and helpful advice, it may not always be applicable or correct.
</p>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
The advice provided by EVA is intended as a guide only and should not be relied upon without consulting an appropriate professional.
</p>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
By using EVA you agree that, to the extent permitted by law, you will not hold One Place Business Platform Pty Ltd ACN 612 278 180, its related bodies corporate or any of their directors, officers, agents or employees liable for any loss, cost or damage suffered due to your reliance upon EVA's advice. Where liability cannot be excluded or waived, you agree that our liability will (to the extent permitted by law) be limited to, at our election, the cost of providing the service provided by EVA to you, or the resupply of such a service.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalDiscliamerHandler}
          >
            Close
          </Button>
        </div>
      </Modal>      
    </>
  );
};

export default EvaClientList;
