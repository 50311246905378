import React from "react";
//eslint-disable-next-line
import { ClipLoader, PulseLoader } from "react-spinners";
import { Container } from "reactstrap";

const BlockUI = () => {
  return (
    <div
      className="modal fade show d-flex align-items-center justify-content-center"
      role="dialog"
      tabIndex="-1"
      style={{
        display: "block",
        backgroundColor: "#000",
        opacity: "0.8",
        zIndex: "19000000",
      }}
    >
      <Container className="text-center my-5">
        <ClipLoader color="#adb5bd" size={35} margin={3} />
      </Container>
    </div>
  );
};

export default BlockUI;
