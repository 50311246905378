import React, { useEffect, useState } from 'react'
import { evaThemeLighter, reformatEVAColumnValue, styleAlignRight } from '../../../services/EVAResponseService';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { events } from 'variables/general';
import GridDataSubWithChild from './GridDataComponent/GridDataSubWithChild';
import GridEditLayout from './GridDataComponent/GridEditLayout';

function EditGridView({ theme, sqlresult, item, EVAData, gridHeader, reformattedData, isWithChild, 
  isSubWithChild, handleUpdateGridData, handleSaveGridFormula, handleToggleEditGrid }) {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'defaultKey', direction: 'asc' });
    const [totalRow, setTotalRow] = useState([])
    const [selectedCellFormulaValue, setSelectedCellFormulaValue] = useState(null)
    const [isEditFormula, setIsEditFormula] = useState(false)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null)
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(null)
    const [newRowData, setNewRowData] = useState([])

    const [newChildRowList, setNewChildRowList] = useState([])
    const [newSubRowList, setNewSubRowList] = useState([])
    const [newParentRowList, setNewParentRowList] = useState([])
    const [onEditLayout, setOnEditLayout] = useState(true)
    const [newDataSorting, setNewDataSorting] = useState([])

    const [newRowList, setNewRowList] = useState([])
    const [dragAndDropData, setDragAndDropData] = useState([])
    const [cellFormulaList, setCellFormulaList] = useState([])
    const [gridGroupLevel, setGridGroupLevel] = useState(null)
    const [renamedSQLData, setRenamedSQLData] = useState([])
    const [hiddenRow, setHiddenRow] = useState([])
    const [renamedHeaderList, setRenamedHeaderList] = useState([])
    const [addedColumnList, setAddedColumnList] = useState([])

    useEffect(() => {

      const fetchData = async () => {
        if (!sqlresult) return null
        // await addTotalRow(sqlresult);
        setData(reformattedData);
      };
      fetchData();

    }, [sqlresult, reformattedData]);

    useEffect(() => {
      // console.log(data)
    }, [data])

    const handleFormulaInputObjectChange = event => {
      setSelectedCellFormulaValue(event.target.value)
    };
    const addRow = () => {
      const newRow = new Array(gridHeader.length).fill(''); // Create a new row with empty values
      setData([...data, newRow]);
    };

    useState(() => {
    },[isEditFormula])

    const handleUpdateData = (updatedData) => {
      setData(updatedData);
    }
    const handleFormulaInputChange = (value) => {
      setSelectedCellFormulaValue(value)
    }
   
    const handleAddUpdateChildRow = (newChildRowList, newSubRowList, newParentRowList) => {
      setNewChildRowList(newChildRowList)
      setNewSubRowList(newSubRowList)
      setNewParentRowList(newParentRowList)
    }
    const saveChanges = () => {
      handleSaveGridFormula(
        {
          newRowList: newRowList, 
          dragAndDropData: dragAndDropData, 
          cellFormulaList: cellFormulaList,
          gridGroupLevel: gridGroupLevel,
          renamedSQLData: renamedSQLData,
          hiddenRow: hiddenRow,
          renamedHeaderList: renamedHeaderList,
          addedColumnList: addedColumnList
        }
      )
    }
    const handleOnEditLayout = () => {
      setOnEditLayout(!onEditLayout)
    }
    const hanldeEditGridSorting = (newDataSorting) => {
      setNewDataSorting(newDataSorting)
    }
    const handleNewRowList = (newRowList) => {
      setNewRowList(newRowList)
    }
    const handleDragAndDropData = (dragAndDropData) => {
      setDragAndDropData(dragAndDropData)
    }
    const handleAddUpdateCellFormulaList = (formula) => {
      setCellFormulaList(formula)
    }
    const handleGridGroupLevel = (gridGroupLevel) => {
      setGridGroupLevel(gridGroupLevel)
    }
    const handleRenamedSQLData = (renamedSQLData) => {
      setRenamedSQLData(renamedSQLData)
    }
    const handleHiddenRow = (hiddenRow) => {
      setHiddenRow(hiddenRow)
    }
    const handleRenamedColumn = (renamedHeaderList) => {
      setRenamedHeaderList(renamedHeaderList)
    }
    const handleNewAddedColumn = (addedColumnList) => {
      setAddedColumnList(addedColumnList)
    }
    return (
        <>
        {/* <button onClick={saveChanges}>Save Changes</button>
        <button onClick={handleOnEditLayout}>Edit Layout</button> */}
        {!onEditLayout ? 
          <div className='editGridViewContainer' style={{ height: 'calc(100% - 200px)', overflow: 'auto'}}>
            {EVAData && gridHeader && (
                <table>
                    <thead
                    style={evaThemeLighter(100, theme)}
                    >
                    <tr>
                      <th></th>
                      {gridHeader.map((header, index) => (
                      <th
                          key={index}
                          style={evaThemeLighter(100, theme)}
                      >
                          {header}
                      </th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                      {isWithChild ? 
                      <>
                        {isSubWithChild ? 
                          <>
                            <GridDataSubWithChild  
                              sqlresult={sqlresult}
                              gridHeader={gridHeader} 
                              handleUpdateData={handleUpdateData} 
                              data={data} 
                              newDataSorting={newDataSorting}
                              styleAlignRight={styleAlignRight}  
                              handleUpdateGridData={handleUpdateGridData}
                              handleFormulaInputChange={handleFormulaInputChange}
                              handleAddUpdateCellFormulaList={handleAddUpdateCellFormulaList}
                              handleAddUpdateChildRow={handleAddUpdateChildRow}
                            />
                          </>
                        :
                          <>
                          </>
                        }
                      </>
                      :
                      <>
                      </>
                      }
                      
                    </tbody>
                </table>
              )}
          </div>
        :
        <>
          <GridEditLayout 
            isWithChild={isWithChild}
            isSubWithChild={isSubWithChild}
            data={data} 
            item={item}
            sqlresult={sqlresult}
            gridHeader={gridHeader} 
            hanldeEditGridSorting={hanldeEditGridSorting}
            saveChanges={saveChanges}
            handleNewRowList={handleNewRowList}
            handleDragAndDropData={handleDragAndDropData}
            handleAddUpdateCellFormulaList={handleAddUpdateCellFormulaList}
            handleGridGroupLevel={handleGridGroupLevel}
            handleToggleEditGrid={handleToggleEditGrid}
            handleRenamedSQLData={handleRenamedSQLData}
            handleHiddenRow={handleHiddenRow}
            handleRenamedColumn={handleRenamedColumn}
            handleNewAddedColumn={handleNewAddedColumn}
          />
        </>
        }
        </>
    );
}

export default EditGridView

