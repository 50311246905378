import { FormatEVAResponse } from "./EVAResponseService";

const BASE_URL = "https://evamobileservices.azurewebsites.net"
// const BASE_URL = "http://192.168.1.15:3010"
const sendRequest = async (api, method, requestData) => {
  const url = `${BASE_URL}/${api}`

  const requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  };

  try {
    const response = await fetch(url, requestOptions);
    return response
  } catch (error) {
    console.log("MongoDB Connection Error:", error);
  }
}
const sendSQLQuery = async (queries, clientId, userId) => {
  const url = `${BASE_URL}/sendSQLQuery`
  const body = { queries, clientId, userId };
  try {
    const details = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });
    
    const result = await details.json();

    if (result.length > 0 && result[0].fieldCount === 0) {
      return result[result.length - 1]; // Return the last object in the array
    } else {
      return result; // Return the entire result array
    }
    // return result[result.length - 1];
    
  } catch (error) {
    console.log("MongoDB Connection Error:", error);
  }
}

const RegisterToken = async (token) => {
  await sendRequest("registerToken", 'POST', token);
}
const DeleteToken = async (token) => {
  // console.log('DeleteToken: ', token)
  await sendRequest("deleteToken", 'DELETE', token);
}
const UpdateTokenStatus = async (token) => {
  await sendRequest("updateTokenStatus", 'POST', token);
}
const RegisterAvatar = async (avatar) => {
  await sendRequest("registerAvatar", 'POST', avatar);
}
const FetchAvatar = async (clientid) => {
  const url = `${BASE_URL}/fetchAvatar`;

  const requestOptions = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(clientid),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      const result = await response.json();
      return result.avatars; // Return the avatars array
    } else if (response.status === 404) {
      console.log("No avatars found for clientid:", clientid);
      return []; // Return an empty array if no avatars are found
    } else {
      console.error("Error fetching avatars. Status:", response.status);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    console.log("Fetch Avatar Error:", error);
    return null; // Return null in case of fetch errors
  }
}
const sendMessage = async (message) => {
  const res = await sendRequest("saveMessage", 'POST', message);
}

const fetchLastMessagePerGroup = async (param) => {
  const url = `${BASE_URL}/fetchLastMessagePerGroup`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };


  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const result = await response.json();
      return result.messages; 
      return result.avatars; // Return the avatars array
    } else if (response.status === 404) {
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching Messages Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
};

const mongofetchMessages = async (param) => {
  const url = `${BASE_URL}/fetchChatRoomMessages`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };


  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      // const result = await response.json();
      // // console.log("mongofetchMessages: ", result)
      // return result; // Return the messages array

      const result = await response.json();
      if (result && result.messages && Array.isArray(result.messages)) {
        const formattedData = await Promise.all(
          result.messages.map(async (item) => {
            if (item.text.includes("```json") || item.text.includes("get_sql_results") || item.text.includes("sql_result")) {
              item.formatedResponse = await FormatEVAResponse(item);
            }
            return item;
          })
        );
        return formattedData;

      } else {
        return result.messages;
      }
    } else if (response.status === 404) {
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
};
const mongoFetchToken = async (param) => {
  const url = `${BASE_URL}/getClientToken`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  }

  // console.log(param)

  try {
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    return null;
  }
}
const sendEVAPrivateMessage = async (message) => {
  await sendRequest("sendEVAPrivateMessage", 'POST', message);
  await sendRequest("sendEVAPrivateMessageHistory", 'POST', message);
}
const fetchEVAMessagesPerUser = async (param) => {
  const url = `${BASE_URL}/fetchEVAMessagesPerUser`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };
  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      const result = await response.json();
      // console.log("mongofetchMessages: ", result)
      return result; // Return the messages array
    } else if (response.status === 404) {
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching EVA Message Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    // console.log(param)
    return null; // Return null in case of fetch errors
  }
};

const fetchEVAMessages = async (param) => {
  const url = `${BASE_URL}/fetchEVAMessages`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const result = await response.json();
      if (result && result.messages && Array.isArray(result.messages)) {
        const formattedData = await Promise.all(
          result.messages.map(async (item) => {
            if (item.user.name === "EVA") {
              item.formatedResponse = await FormatEVAResponse(item);
              // Add your specific formatting logic here for the "EVA" user.
            }
            return item;
          })
        );
        return formattedData;

      } else {
        return result.messages;
        console.error("Invalid or missing messages in the result:", result);
      }


    } else if (response.status === 404) {
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching EVA Message Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
};


const fetchChatRoomLastMessages = async (param) => {
  const url = `${BASE_URL}/fetchChatRoomLastMessages`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };
  // console.log(param)
  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      const result = await response.json();
      // console.log("mongofetchMessages: ", result)
      return result; // Return the messages array
    } else if (response.status === 404) {
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching Last Messages Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
}
const saveNotification = async (notification) => {
  const convertedData = {
    ClientID: notification.data?.ClientID,
    Name: notification.data?.Name,
    notification: {
      body: notification.notification?.body,
      title: notification.notification?.title,
    },
    tokens: notification.registration_ids,
    type: notification.type
  };
  
  await sendRequest("saveNotification", 'POST', convertedData);
  // await sendRequest("sendNotificationToUser",'POST',convertedData);
}

const fetchNotifications = async (token) => {
  const url = `${BASE_URL}/fetchNotifications`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "token": JSON.parse([token]) }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      const result = await response.json();
      // console.log(result)
      // console.log("mongofetchMessages: ", result)
      // console.log('notification found');
      return result; // Return the messages array
    } else if (response.status === 404) {
      // console.log('No notifications found for the provided token');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching Notifications Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
}

const fetchChatNotificationsPerClient = async (token) => {

  const url = `${BASE_URL}/fetchChatNotificationsPerClient`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const result = await response.json();
      return result; // Return the messages array
    } else if (response.status === 404) {
      // console.log('No notifications found for the provided token');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching Notifications Per Client Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    return null; // Return null in case of fetch errors
  }
}
const fetchAllNotificationsPerClient = async (token) => {

  const url = `${BASE_URL}/fetchChatNotificationsPerClient`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const result = await response.json();
      return result; // Return the messages array
    } else if (response.status === 404) {
      // console.log('No notifications found for the provided token');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching All Notifications Per Client Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    console.log('Fetch notifications Error:', error);
    return null; // Return null in case of fetch errors
  }
}
const fetchAllNotificationsPerToken = async (token) => {

  const url = `${BASE_URL}/fetchAllNotificationsPerToken`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const result = await response.json();
      return result; // Return the messages array
    } else if (response.status === 404) {
      // console.log('No notifications found for the provided token');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching All Notifications Per Token Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    console.log('Fetch notifications Error:', error);
    return null; // Return null in case of fetch errors
  }
}
const fetchAllNotificationsPerBusiness = async (token) => {

  const url = `${BASE_URL}/fetchAllNotificationsPerBusiness`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const result = await response.json();
      return result; // Return the messages array
    } else if (response.status === 404) {
      // console.log('No notifications found for the provided token');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching Notifications Per Business Status: ${response.status}`, errorDetails);
      return response.status; // Return null in case of other errors
    }
  } catch (error) {
    console.log('Fetch notifications Error:', error);
    return null; // Return null in case of fetch errors
  }
}

const updateNotification = async (notification) => {
  await sendRequest("updateNotification", 'POST', { notification });
}
const deleteEVAHistoryItem = async (message) => {
  await sendRequest("deleteEVAHistoryItem", 'POST', message);
}
const deleteAllEVAHistoryItem = async (message) => {
  await sendRequest("deleteAllEVAHistoryItem", 'POST', message);
}
const fetchHelpList = async () => {
  const url = `${BASE_URL}/fetchHelpList`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // You can pass an empty object or omit the body since fetchHelpList doesn't require any parameters
    // body: JSON.stringify({}),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const result = await response.json();
      // console.log(result)
      return result.helpList; // Return the helpList array from the result
    } else if (response.status === 404) {
      console.log('No HelpList items found');
      return []; // Return an empty array if no items are found
    } else {
      const errorDetails = await response.text();
      console.error(`Error fetching HelpList Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    console.log('Fetch HelpList Error:', error);
    return null; // Return null in case of fetch errors
  }
};
const fetchAllEvaIntegrations = async () => {
  const url = `${BASE_URL}/fetchAllEvaIntegrations`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },

  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const result = await response.json();
      return result.evaIntegrations; // Return the evaIntegrations array from the result
    } else if (response.status === 404) {
      console.log('No evaIntegration documents found');
      return []; // Return an empty array if no documents are found
    } else {
      const errorDetails = await response.text();
      console.error(`Error fetching evaIntegrations Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    console.log('Fetch evaIntegrations Error:', error);
    return null; // Return null in case of fetch errors
  }
};
const sendEVAQuery = async (raw) => {
  const url = "https://evamobileservices.azurewebsites.net/evaQuery";
  console.log(raw, url)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(raw),
  };

  try {
    const response = await fetch(url, requestOptions);
    console.log(response)
    if (response.ok) {
      const result = await response.json();
      return result;
    }
  } catch (error) {
    console.log('Fetch evaIntegrations Error:', error);
    return error; // Return null in case of fetch errors
  }
};
const getMessageReact = async (userInfo) => {
  if (!userInfo) {
    return []
  }

  try {
    console.log(userInfo)
    const pythonRes = await fetch('https://evabetaprod.azurewebsites.net/chat_history_list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userInfo: userInfo }),
    });

    if (!pythonRes.ok) {
      // If the Python API responded with an error, log it and continue
      console.error('getMessageReact API error');
    }

    const pythonData = await pythonRes.json();
    // return pythonData as Chat[]
    return pythonData
  } catch (error) {
    console.log("function: saveChatReact error!")
    return []
  }
}
const saveChatReact = async (param) => {
  const url = `${BASE_URL}/saveChatReact`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  };
  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      // If the response status is OK (200), parse the JSON response
      const result = await response.json();
      // console.log("mongofetchMessages: ", result)
      return result; // Return the messages array
    } else if (response.status === 404) {
      console.log('No EVA Message found for clientid');
      return []; // Return an empty array if no messages are found
    } else {
      const errorDetails = await response.text(); // Get more details about the error
      console.error(`Error fetching EVA Message Status: ${response.status}`, errorDetails);
      return null; // Return null in case of other errors
    }
  } catch (error) {
    // console.log(param)
    console.log('Fetch EVA Message Error:', error);
    return null; // Return null in case of fetch errors
  }
}
const registerPersonalDashboard = async (param) => {
  await sendRequest("registerPersonalDashboard", 'POST', param);
}
const updateVisualPersonalDashboard = async (param) => {
  await sendRequest("updateVisualPersonalDashboard", 'POST', param);
}
const updateVisualUserDashboard = async (param) => {
  await sendRequest("updateVisualUserDashboard", 'POST', param);
}
const updatePersonalDashboard = async (param) => {
  await sendRequest("updatePersonalDashboard", 'POST', param);
}
const updateIsFavoritePersonalDashboard = async (param) => {
  const response = await sendRequest("updateIsFavoritePersonalDashboard", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const personalDashboards = async (param) => {
  const response = await sendRequest("personalDashboards", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deletePersonalDashboard = async (param) => {
  await sendRequest("deletePersonalDashboard", 'POST', param);
}
//#region Custom Group
const addGroup = async (param) => {
  await sendRequest("addGroup",'POST',param);
}
const getCustomGroups = async (param) => {
  const response = await sendRequest("groups",'POST',param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Custom Group found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Custom Group Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const updateGroup = async (param) => {
  await sendRequest("updateGroup",'POST',param);
}
const deleteGroup = async (param) => {
  await sendRequest("deleteGroup", 'POST', param);
}
//#endregion

//#region  Personal Dashboard BI
const registerPersonalDashboardBI = async (param) => {
  await sendRequest("registerPersonalDashboardBI", 'POST', param);
}
const personalDashboardsBI = async (param) => {
  const response = await sendRequest("personalDashboardsBI", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deletePersonalDashboardBI = async (param) => {
  await sendRequest("deletePersonalDashboardBI", 'POST', param);
}
const deletePersonalDashboardBIByReport = async (param) => {
  await sendRequest("deletePersonalDashboardBIByReport", 'POST', param);
}
//#endregion

//#region Group Dashboard
const registerGroupDashboard = async (param) => {
  await sendRequest("registerGroupDashboard", 'POST', param);
}
const updateGroupDashboard = async (param) => {
  await sendRequest("updateGroupDashboard", 'POST', param);
}
const updateVisualGroupDashboard = async (param) => {
  await sendRequest("updateVisualGroupDashboard", 'POST', param);
}
const groupDashboards = async (param) => {
  const response = await sendRequest("groupDashboards", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deleteGroupDashboard = async (param) => {
  await sendRequest("deleteGroupDashboard", 'POST', param);
}
//#endregion

//#region Group Dashboard BI
const registerGroupDashboardBI = async (param) => {
  await sendRequest("registerGroupDashboardBI", 'POST', param);
}
const groupDashboardsBI = async (param) => {
  const response = await sendRequest("groupDashboardsBI", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    const res = result.map((item) => ({ ...item, isBI: true }))
    return res; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deleteGroupDashboardBI = async (param) => {
  await sendRequest("deleteGroupDashboardBI", 'POST', param);
}
const updateEVAChatHistoryList = async (param) => {
  await sendRequest("updateEVAChatHistoryList", 'POST', param);
}
const deleteGroupDashboardBIByReport = async (param) => {
  await sendRequest("deleteGroupDashboardBIByReport", 'POST', param);
}
//#endregion

//#region User Dashboard
const registerUserDashboard = async (param) => {
  await sendRequest("registerUserDashboard", 'POST', param);
}
const userDashboard = async (param) => {
  const response = await sendRequest("userDashboard", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deleteUserDashboard = async (param) => {
  await sendRequest("deleteUserDashboard", 'POST', param);
}
const updateUserDashboard = async (param) => {
  await sendRequest("updateUserDashboard", 'POST', param);
}
//#endregion

//#region User Dashboard BI
const registerUserDashboardBI = async (param) => {
  await sendRequest("registerUserDashboardBI", 'POST', param);
}
const userDashboardBI = async (param) => {
  const response = await sendRequest("userDashboardBI", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No Personal Dashboard found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching Personal Dashboard Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deleteUserDashboardBI = async (param) => {
  await sendRequest("deleteUserDashboardBI", 'POST', param);
}
const deleteUserDashboardBIByReport = async (param) => {
  await sendRequest("deleteUserDashboardBIByReport", 'POST', param);
}
//#endregion

//#region Notification
const groupNotifications = async (param) => {
  const response = await sendRequest("groupNotifications", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No group Notifications found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching group Notifications Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const updateGroupNotifications = async (param) => {
  await sendRequest("updateGroupNotifications", 'POST', param);
}
const registerGroupNotification = async (param) => {
  await sendRequest("registerGroupNotification", 'POST', param);
}
//#endregion

//#region ChatNotification
const groupChatNotifications = async (param) => {
  const response = await sendRequest("groupChatNotifications", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No group Chat Notifications found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching group Chat Notifications Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const registerGroupChatNotification = async (param) => {
  const response = await sendRequest("registerGroupChatNotification", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No group Chat Notifications found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching group Chat Notifications Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
const deleteGroupChatNotifications = async (param) => {
  const response = await sendRequest("deleteGroupChatNotifications", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    console.log('No group Chat Notifications found for clientid');
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    console.error(`Error fetching group Chat Notifications Status: ${response.status}`, errorDetails);
    return null; // Return null in case of other errors
  }
}
//#endregion

//#region announcements
const loadAnnouncements = async (param) => {
  const response = await sendRequest("announcements", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const announcementReadStatus = async (param) => {
  const response = await sendRequest("announcementReadStatus", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
//#endregion

//#region Dashboard Template
const dashboardTemplate = async (param) => {
  const response = await sendRequest("dashboardTemplate", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const registerDashboardTemplate = async (param) => {
  await sendRequest("registerDashboardTemplate", 'POST', param);
}
const updateEtaniDashboardTemplates = async (param) => {
  await sendRequest("updateEtaniDashboardTemplates", 'POST', param);
}
const updateDashboardTemplateBusinessId = async (param) => {
  await sendRequest("updateDashboardTemplateBusinessId", 'POST', param);
}
const updateTemplateIsFavorite = async (param) => {
  await sendRequest("updateTemplateIsFavorite", 'POST', param);
}
const updateEVAVisual = async (param) => {
  await sendRequest("updateEVAVisual", 'POST', param);
}
const userDashboardTemplate = async (param) => {
  const response = await sendRequest("userDashboardTemplate", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const etaniDashboardTemplates = async (param) => {
  const response = await sendRequest("etaniDashboardTemplates", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const groupDashboardTemplate = async (param) => {
  const response = await sendRequest("groupDashboardTemplate", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const userDashboardTemplateByUser = async (param) => {
  const response = await sendRequest("userDashboardTemplateByUser", 'POST', param);
  if (response.ok) {
    const result = await response.json();
    return result; // Return the messages array
  } else if (response.status === 404) {
    return []; // Return an empty array if no messages are found
  } else {
    const errorDetails = await response.text(); // Get more details about the error
    return null; // Return null in case of other errors
  }
}
const deleteUserDashboardTemplate = async (param) => {
  await sendRequest("deleteUserDashboardTemplate", 'POST', param);
}
const softDeleteUserDashboardTemplate = async (param) => {
  await sendRequest("softDeleteUserDashboardTemplate", 'POST', param);
}
//#endregion

export { 
  sendSQLQuery,
  RegisterToken, 
  UpdateTokenStatus, 
  DeleteToken,
  RegisterAvatar,
  FetchAvatar,
  sendMessage,
  fetchLastMessagePerGroup,
  mongofetchMessages,
  mongoFetchToken,
  sendEVAPrivateMessage,
  fetchEVAMessagesPerUser,
  updateEVAChatHistoryList,
  fetchEVAMessages,
  fetchChatRoomLastMessages,
  saveNotification,
  fetchNotifications,
  updateNotification,
  fetchChatNotificationsPerClient,
  fetchAllNotificationsPerClient,
  fetchAllNotificationsPerToken,
  fetchAllNotificationsPerBusiness,
  deleteEVAHistoryItem,
  deleteAllEVAHistoryItem,
  fetchHelpList,
  fetchAllEvaIntegrations,
  sendEVAQuery,
  getMessageReact,
  saveChatReact,
  registerPersonalDashboard,
  updateVisualPersonalDashboard,
  updatePersonalDashboard,
  updateIsFavoritePersonalDashboard,
  personalDashboards,
  deletePersonalDashboard,
  addGroup,
  getCustomGroups,
  updateGroup,
  deleteGroup,
  registerPersonalDashboardBI,
  personalDashboardsBI,
  deletePersonalDashboardBI,
  registerGroupDashboard,
  updateGroupDashboard,
  groupDashboards,
  deleteGroupDashboard,
  registerGroupDashboardBI,
  groupDashboardsBI,
  deleteGroupDashboardBI,
  registerUserDashboard,
  userDashboard,
  updateUserDashboard,
  registerUserDashboardBI,
  userDashboardBI,
  deleteUserDashboard,
  deleteUserDashboardBI,
  deletePersonalDashboardBIByReport,
  deleteGroupDashboardBIByReport,
  deleteUserDashboardBIByReport,
  groupNotifications,
  updateGroupNotifications,
  registerGroupNotification,
  registerGroupChatNotification,
  deleteGroupChatNotifications,
  groupChatNotifications,
  loadAnnouncements,
  announcementReadStatus,
  dashboardTemplate,
  registerDashboardTemplate,
  updateEtaniDashboardTemplates,
  updateDashboardTemplateBusinessId,
  updateTemplateIsFavorite,
  userDashboardTemplate,
  etaniDashboardTemplates,
  groupDashboardTemplate,
  userDashboardTemplateByUser,
  deleteUserDashboardTemplate,
  softDeleteUserDashboardTemplate,
  updateEVAVisual,
  updateVisualUserDashboard,
  updateVisualGroupDashboard
};
