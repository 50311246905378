  const modalCustomStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '9999',
    },
    content: {
      border: 'none',
      background: 'none',
      padding: 0,
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
  };

  export  { modalCustomStyles }