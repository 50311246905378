import { MainContext } from 'context/mainContext';
import React, { useContext, useState } from 'react'

const CurrentAndHistoricTabs = ({ ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  // const [tabKey, setKey] = useState('personal');
  const tabList = [
    {
      id: "current-tab",
      name: "current",
      display: "Current"
    },
    {
      id: "historic-tab",
      name: "historic",
      display: "Historic"
    },
  ]

  return (
    <>
      <div className="nav nav-tabs" role="tablist" style={{ border: `unset` }}>
        {tabList.map((item, i) => (
          <a key={i} id={item.id} role="tab"
            style={props.tabKey === item.name ? { backgroundColor: bgColor, color: textColor, cursor: 'pointer' } : { color: bgColor, backgroundColor: textColor, cursor: 'pointer' }}
            className={`nav-item nav-link ${props.tabKey === item.name ? "active" : ""}`}
            onClick={() => props.handleTabSelect(item.name)}>{item.display}</a>
        ))}
      </div>

      <div className="col-sm-12 tabContainer" style={{ borderTop: `3px solid ${bgColor}` }}>
        {props.children}
      </div>
    </>
  )
}
export default CurrentAndHistoricTabs