import { IconExpand } from 'chat-components/ui/icon';
import { IconMinimize } from 'chat-components/ui/icon';
import { IconEdit } from 'chat-components/ui/icon';
import { IconTrash } from 'chat-components/ui/icon';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { evaThemeLighter } from 'services/EVAResponseService';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { registerDashboardTemplate } from 'services/mongoDB';


function DashboardItemHeader({ onEditName, isEnabledDnD, item, editMode, index, handleSetItemIndexToEdit, 
        handleDeleteDashboardTemplateItem, selectedDashboardTemplate, toggleFullScreen, isFullScreen, 
        hanldeFullScreenOnly, onFullScreenOnly, isError, handleNewDashboardNameItemChange, newDashboardItemName}) 
{    
    // const [newDashboardItemName, setNewDashboardItemName] = useState(item.name);
    const [alert, setAlert] = useState(false);

    const { bgColor, textColor, firmDetails } = useContext(MainContext);
    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    // const handleNewDashboardNameItemChange = (event) => {
    //     event.stopPropagation();
    //     setNewDashboardItemName(event.target.value);
    // }
    const onhandleEditItem = (event) => {
        event.stopPropagation();
        // handleNewDashboardNameItemChange(item.name ? capitalize(item.name) : 'Item ' + (index + 1))
        handleSetItemIndexToEdit(index)
        toggleFullScreen(index)
        hanldeFullScreenOnly(false)
    }
    const handleCancelEdit = (event) => {
        event.stopPropagation();
        handleSetItemIndexToEdit(-1)
        toggleFullScreen(-1)
        hanldeFullScreenOnly(false)
    }
    const handleSave = (event) => 
    {
        event.stopPropagation();
        console.log(event)
        item.name = newDashboardItemName
        registerDashboardTemplate(selectedDashboardTemplate)
        const alertMessage = `You have successfully updated the ${item.name}`
        handleSuccessDashboardUpdate(alertMessage)
        handleSetItemIndexToEdit(-1)
        toggleFullScreen(-1)
    };
    const handleSuccessDashboardUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                success
                title="Success"
                onConfirm={async () => {
                    setAlert(null)
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const handleDeleteItem = (event) => {
        event.stopPropagation();
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() =>{ 
                  
                    handleDeleteDashboardTemplateItem(item)
                    const alertMessage = `You have successfully deleted the ${item.name}`
                    handleSuccessDashboardUpdate(alertMessage)

                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                showCancel={true}
                btnSize=""
            >
                <p>
                   {`Are you sure you want to delete this ${item.name}`}?
                </p>
            </ReactBSAlert>
        );
    }
    const handleFullScreen = () => {
        toggleFullScreen(onFullScreenOnly ? -1 : index)
        hanldeFullScreenOnly(!onFullScreenOnly)
    }
    
    return (
        <div className='dashboardItemItemHeader' style={{ color: bgColor }}>
            {/* <div className='dashboardItemItemHeader' style={evaThemeLighter(80, theme)}></div> */}
            {alert}
            <div className='text'>
                {(!onEditName || onFullScreenOnly) ?
                    item.name ? capitalize(item.name) : `Item ${index + 1}` :
                    <div className="editNameContent" style={{ width: '100%'}}>
                        <input
                            type="text"
                            value={newDashboardItemName}
                            onChange={handleNewDashboardNameItemChange}
                            style={{ width: '100%', border: `1px solid ${bgColor}`, borderRadius: 2, padding: 4 }}
                            autoFocus 
                        />
                    </div>
                }
            </div>
            {editMode  ?
                !onEditName &&
                <div className='templateEditItemContainer'>
                    {/* {onEditName &&
                        <div className="itemControlsEdit" onClick={handleSave}>
                            <div className="optBtn">Save</div>
                        </div>
                    } */}
                    <div className='itemControl'>
                        {!isError &&
                        <div className="itemControlsEdit" style={{ background: bgColor }}>
                            {!onEditName && 
                                <>
                                    {!isFullScreen && 
                                        <div onClick={onhandleEditItem}>
                                            <IconEdit />
                                        </div> 
                                    }
                                </>
                            // : 
                            // <div className="optBtnCancel" onClick={handleCancelEdit}>Cancel</div>
                            }
                        </div>}
                        {!onEditName && 
                            <>
                                {!isFullScreen && 
                                    <div className="itemControlsDelete" onClick={handleDeleteItem}>
                                        <IconTrash />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            :
                (isError !== undefined) &&
                <div className={!isFullScreen && "itemControls"} style={{ color: `${bgColor}`, cursor: 'pointer', marginTop: '0px', display: 'flex', alignItems: 'center', marginRight: 10 }} >
                    <div className={isFullScreen ? "minimizeControl": "itemControlsFullScreen"} onClick={handleFullScreen}>{isFullScreen ? "Close" : <IconExpand color={bgColor} />}</div>
                </div>
            }
        </div>
    )
}

export default DashboardItemHeader