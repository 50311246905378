import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Table, Col, } from 'reactstrap';
import Modal from 'react-modal';
import CustomTooltip from '../../../customComponents/customComponents';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Papa from 'papaparse';
import { CsvCustomeHeaderCheck } from '../../../utils/utilityFunctions';
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import ReactPlayer from "react-player";

const CSVJobTaskModal = ({ onClose, closeMainModal, isOpen, setShowBlockUI, jobTaskDb, userDetails, accountingFirm, firmDetails, selectedClient, handleUpdateRecord, ...props }) => {
  const [file, setFile] = useState(null);
  const [csvHeader, setCSVHeader] = useState([])
  const [showTutorial, setShowTutorial] = useState(false);
  const [csvIsEmptyfile, setcsvIsEmptyfile] = useState(true);
  const [jobTaskHistory, setJobTaskHistory] = useState([]);
  const [alert, setAlert] = useState(null);
  const [csvIsValid, setcsvIsValid] = useState(true)
  const [csvErrors, setCsvErrors] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setcsvIsEmptyfile(false)
  };
  const fileRef = useRef(null);

  useEffect(() => {
    setcsvIsValid(true)
    setcsvIsEmptyfile(false)
    loadHistory()
  }, [isOpen])

  const loadHistory = async () => {
    await fetch(`/api/clientService/getCsvUploadHistory?csv=xpm_jobtask_csv&orgId=${jobTaskDb.csID}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((HistoryRecord) => {
        if (HistoryRecord.error) {
          HistoryRecord = {};
          return warningAlert(HistoryRecord);
        }

        setJobTaskHistory(HistoryRecord);
      });
  }

  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message ||
          response.error ||
          response.Details.warning ||
          response}
      </ReactBSAlert>
    );
  };

  const handleSubmit = async () => {
    if (file) {
      const isValid = await fileValidateHeader(file, setCsvErrors);
      setcsvIsValid(isValid);

      if (isValid) {
        handleIncomeTaxUpload(file);
        // console.log("VALID")
      }
    } else {
      setcsvIsEmptyfile(true)
      setcsvIsValid(true);
    }

    fileRef.current.value = '';
    setFile(null)
  }

  function fileValidateHeader(file, setCsvErrors) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: function (result) {
          const firstObject = result.data[0];
          const keys = firstObject;
          // console.log(keys)
          // console.log(validJobCostHeader)

          setCSVHeader(keys);

          const { validCsv, errorsFound } = CsvCustomeHeaderCheck(keys, validJobCostHeader);
          if (validCsv && !errorsFound.length) {
            resolve(true);
          } else {
            setCsvErrors(errorsFound);
            resolve(false);
          }
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
          resolve(false);
        },
      });
    });
  }

  const handleIncomeTaxUpload = async (file) => {
    // console.log(file)
    setShowBlockUI(true)
    setcsvIsEmptyfile(false)
    const uniqueFileId = new Date().getTime();
    const blobFileName = `QUEUE/CSV/${uniqueFileId}-jobtask-csv-upload.csv`;
    const { dbName, csID, dw, tbName, staging } = jobTaskDb
    // console.log(accountingFirm)
    // console.log(blobFileName)
    // console.log({ dbName, csID, dw, tbName, staging })

    await fetch(`/api/clientService/uploadCsv`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ fileName: blobFileName })
    })
      .then((response) => response.json())
      .then(async data => {
        // console.log('handleJobTaskUpload', data)

        await uploadBlob(file, blobFileName, data)

        const QUEUENAME = 'xpm-jobtask-upload-csv-queue';
        let firmCode = firmDetails?.Code.includes("-") ? firmDetails?.Code.replace("-", "") : firmDetails?.Code
        const messageMetadata = {
          organizationId: staging ? `${csID}` : csID,
          // clientId: `BIGOLESHOECOMPANY_CLIENT1518`,
          clientId: staging ? '1' : `${firmCode.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`,
          firmId: accountingFirm.id,
          dbName: dbName,
          tableName: tbName,
          originalFileName: file.name,
          blobFileName,
          uploader: {
            id: userDetails.User.id,
            email: userDetails.User.Email,
            userAccess: userDetails.User.UserAccess,
          },
          dw: dw
        }
        // console.log(messageMetadata)
        await pushToQueue(QUEUENAME, blobFileName, data, messageMetadata)

        const updateClientService = await fetch(`/api/clientService/updateClientServiceAfterCsvUpload`, {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify({
            clientId: selectedClient.id,
            firmId: selectedClient.AccountingFirm,
            serviceId: Number(csID)
          })
        })

        const updatedResult = await updateClientService.json()
        // console.log(updatedResult)

        setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
              setAlert(null)
              onClose()
              handleUpdateRecord(updatedResult.updatedClientService)
            }}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            {"Your file has been successfully uploaded. Please allow approximately 3 minutes for it to be updated in your list."}
          </ReactBSAlert>
        );
      })
      .catch((err) => {
        console.log('UPLOAD JOBTASK CSV ERROR', err);
        props.setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            err
          </ReactBSAlert>
        );
      });
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '930px',
            height: '520px'
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose()
          setFile(null)
        }
        }
        contentLabel="Upload CSV Modal"
      >
        {alert}
        <Row className="lg-12 pl-3">
          <h2>Job Task Upload</h2>
          <CustomTooltip
            i="1"
            className="fas fa-play-circle"
            message="Play Tutorial"
            onClick={() => setShowTutorial(!showTutorial)}
            leftdiv={'text-danger'}
          />
        </Row>
        <div className="file-upload-container">
          <input type="file" accept=".csv" onChange={handleFileChange} ref={fileRef} />
          <div className="button-container">
            <Button onClick={handleSubmit}>Upload</Button>
            <Button onClick={() => {
              onClose()
              setFile(null)
            }}>Cancel</Button>
          </div>
        </div>
        <div className={`csvErr ${csvIsValid ? "hidden" : ""}`}>
          <em>Invalid CSV fiel:</em>
          {csvErrors.length ?
            <ul>{csvErrors.map((error, k) => <li key={k}>{error}</li>)}</ul>
            : null}
          <span className={`csvErr ${csvIsEmptyfile ? "" : "hidden"}`}>File is empty; please choose a valid file.</span>
        </div>
        <div className='CSVList-Container'>
          <Table className='CSVHistoryList'>
            <thead>
              <tr>
                <td>Uploader</td>
                <td>File Name</td>
                <td>Date</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {jobTaskHistory.map((data) => (
                <tr key={data.id}>
                  <td>{data.uploader.email}</td>
                  <td>{data.originalFileName}</td>
                  <td>{dateFormatter(data.updatedAt)}</td>
                  <td>{data.Status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal>
      <Modal
        className="vid-modal"
        size="lg"
        isOpen={showTutorial}
        style={{
          overlay: {
            zIndex: 9999
          },
          content: {
            zIndex: 10000
          }
        }}
      >
        <div className="modal-body ">
          <div className="modal-header px-0">
            <h6 className="modal-title">
              Tutorial
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowTutorial(!showTutorial)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              className="react-player-custom"
              style={{ position: "absolute", top: 0, right: 0 }}
              // url="https://vimeo.com/874701389/1ab29730ee?share=copy"
              controls
              width="100%"
              height="100%"
              light={true}
              stopOnUnmount={false}
              playsinline={true}
              pip={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                vimeo: {
                  playerOptions: {
                    autopause: true,
                    responsive: true,
                    loop: true,
                    keyboard: true,
                    pip: true,
                  },
                },
              }}
            />
          </div>
          <Row
            className="mt-3"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            {/* {videoList()} */}
            {/* <Courses /> */}
            <Col>
              <h3>Description</h3>
              {/* {selectedService?.Details?.TrainingVideoDescriptions
                ? parse(selectedService?.Details?.TrainingVideoDescriptions)
                : ""} */}
              <div className="" style={{ height: '180px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
                <span>XPM Upload Job Task</span>
                <br />
                <br />
                <span>If you have only 1 Custom Field, repeat it 10 times. If you have no Custom Fields do the column before.
                  But you must upload with 10 columns for each Job & Task Custom Fields for it to work.
                  No Date filter so will be big. Ensure using Firefox</span>
                <br />
                <div className="content" style={{ marginBottom: '20px' }}>
                  <ul>
                    <li>[Job No.]</li>
                    <li>[Job Name]</li>
                    <li>[Job Category]</li>
                    <li>[Job Custom Field 1]</li>
                    <li>[Job Custom Field 2]</li>
                    <li>[Job Custom Field 3]</li>
                    <li>[Job Custom Field 4]</li>
                    <li>[Job Custom Field 5]</li>
                    <li>[Job Custom Field 6]</li>
                    <li>[Job Custom Field 7]</li>
                    <li>[Job Custom Field 8]</li>
                    <li>[Job Custom Field 9]</li>
                    <li>[Job Custom Field 10]</li>
                    <li>[Task Name + Label]</li>
                    <li>[Task % Complete]</li>
                    <li>[Task Completed]</li>
                    <li>[Task Date Completed]</li>
                    <li>[Quoted Amount]</li>
                    <li>[Quoted Time]</li>
                    <li>[Cost]</li>
                    <li>[Task Custom Field 1]</li>
                    <li>[Task Custom Field 2]</li>
                    <li>[Task Custom Field 3]</li>
                    <li>[Task Custom Field 4]</li>
                    <li>[Task Custom Field 5]</li>
                    <li>[Task Custom Field 6]</li>
                    <li>[Task Custom Field 7]</li>
                    <li>[Task Custom Field 8]</li>
                    <li>[Task Custom Field 9]</li>
                    <li>[Task Custom Field 10]</li>
                    <li>[Start Date]</li>
                    <li>[Task Billable Amount]</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default CSVJobTaskModal;

const validJobCostHeader = [
  "[Job] Job No.",
  "[Job] Name",
  "[Job] Category",
  "[Job] Custom Field 1",
  "[Job] Custom Field 2",
  "[Job] Custom Field 3",
  "[Job] Custom Field 4",
  "[Job] Custom Field 5",
  "[Job] Custom Field 6",
  "[Job] Custom Field 7",
  "[Job] Custom Field 8",
  "[Job] Custom Field 9",
  "[Job] Custom Field 10",
  "[Task] Name + Label",
  "[Task] % Complete",
  "[Task] Completed",
  "[Task] Date Completed",
  "[Task] Quoted Amount",
  "[Task] Quoted Time",
  "[Task] Cost",
  "[Task] Custom Field 1",
  "[Task] Custom Field 2",
  "[Task] Custom Field 3",
  "[Task] Custom Field 4",
  "[Task] Custom Field 5",
  "[Task] Custom Field 6",
  "[Task] Custom Field 7",
  "[Task] Custom Field 8",
  "[Task] Custom Field 9",
  "[Task] Custom Field 10",
  "[Job] Start Date",
  "[Task] Billable Amount",
]
function dateFormatter(myData) {
  const originalDate = myData;
  const formattedDate = new Date(originalDate).toLocaleString();
  return formattedDate;
}