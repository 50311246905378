import React, { useState, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  CardImg,
  Modal,
} from "reactstrap";

const MBAEmailConfirmSuccess = () => {
  const [transitionStage, setTransistionStage] = useState("fadeFrom");
  const routerLocation = useLocation();
  const [displayLocation, setDisplayLocation] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [bnameValue, setBnameValue] = useState("");
  const [urlOrigin, setUrlOrigin] = useState("");
  const routerHistory = useHistory();

  useEffect(() => {
    if (routerLocation === displayLocation) setTransistionStage("fadeTo");
    let url = window.location.pathname.split("/");
    // console.log(url);
    let urlOrigin = window.location.origin;
    setUrlOrigin(urlOrigin);
    // console.log(window.location.origin);
    let jwt = url[url.length - 1];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("access_token");
    const bname = urlParams.get("bname");
    setTokenValue(token);
    setBnameValue(bname);
    // setTimeout(() => {
    //   window.location.replace(
    //     `${urlOrigin}/api/auth/acceptInvitation?access_token=${token}&bname=${bname}`
    //   );
    // }, 10000);

    // fetch(`/api/auth/acceptInvitation?access_token=${jwt}`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }, [routerLocation, displayLocation]);

  const redirectToPasswordCreation = () => {
    // routerHistory.push(
    //   "/client"
    // );
    window.location.replace(
      `${urlOrigin}/api/auth/acceptInvitation?access_token=${tokenValue}&bname=${bnameValue}&mba=true`
    );
  }

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="8"
              sm="12"
              xs="12"
              className={`${transitionStage} mt-2 px-0 px-lg-3`}
            >
              <Card
                className="bg-transparent p-sm-2 py-3 p-lg-4 mx-3 shadow-none"
                style={{ overflow: "hidden" }}
              >
                <CardImg
                  alt="Business Logo"
                  src={"/api/accountingFirm/getThemeLogo"}
                  style={{ width: "150px", margin: "1rem auto" }}
                  className=" justify-content-center text-center rounded "
                  top
                />
                <CardImg
                  alt="Success"
                  src={require("../../../../assets/img/success-36.svg").default}
                  style={{ width: "50px", margin: "0 auto" }}
                  className="spin justify-content-center text-center rounded my-3"
                  top
                />
                <CardHeader className="bg-transparent text-center border-0 py-2">
                  <h1 className="h1" style={{ color: "#272A65" }}>Success!</h1>
                </CardHeader>
                <CardHeader className="bg-transparent text-center border-0 px-xl-4 px-2  mt--2 text-sm-sm">
                  {/* You have successfully confirmed your email, please wait while
                  are processing your account. You will be redirected to your
                  password creation after this. Thank you. */}
                  You have successfully confirmed your email. We note, you are a Multi Business User, therefore, you will not need to create a password. <br />
                  Please click continue as we move forward setting up your new business on Etani.
                  {/* click
                  <span
                    className="text-primary ml-2 text-underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => (window.location.href = '/auth/login')}
                  >
                    here
                  </span>{' '}
                  to login on your account in the CMS. */}
                </CardHeader>
                <CardBody className="">
                  <div className="text-center">
                    <Button
                      style={{ backgroundColor: "#272A65", borderColor: "#272A65" }}
                      className="mb-2"
                      color="primary"
                      type="submit"
                      onClick={redirectToPasswordCreation}
                    >
                      Continue
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MBAEmailConfirmSuccess;
