import { CustomFeatures } from "customComponents/customPricing";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CustomTooltip from "customComponents/customComponents";

const PricingCard = ({ record, ...props }) => {
  return (
    <>
      <Row>
        <Col>
          {
            record.Code === props.firmDetails?.PricingPlan?.Code ? <p><strong>Current Plan</strong></p>
              : props.firmDetails?.PricingPlan?.Code === 'TIER1' && record.Code === 'TIER2' ? <p><strong>Next Tier Plan</strong></p>
                : props.firmDetails?.PricingPlan?.Code === 'TIER2' && record.Code === 'TIER3' ? <p><strong>Next Tier Plan</strong></p>
                  : props.firmDetails?.PricingPlan?.Code === 'TIER3' && record.Code === 'TIER4' ? <p><strong>Next Tier Plan</strong></p>
                    : props.firmDetails?.PricingPlan?.Code === 'TIER4' && record.Code === 'TIER3' ? <p><strong>Lower Tier Plan</strong></p>
                      : props.firmDetails?.PricingPlan?.Code === 'TIER4' && record.Code === 'TIER3' ? <p><strong>Lower Tier Plan</strong></p>
                        : props.firmDetails?.PricingPlan?.Code === 'TIER3' && record.Code === 'TIER2' ? <p><strong>Lower Tier Plan</strong></p>
                          : props.firmDetails?.PricingPlan?.Code === 'TIER2' && record.Code === 'TIER1' ? <p><strong>Lower Tier Plan</strong></p>
                            : null
          }
        </Col>
      </Row>
      <Card className="card-pricing border-0 mt-3 ribbon-overflow p-2">
        {record.Code === props.firmDetails?.PricingPlan?.Code ? (
          <div className="ribbon">{props.firmDetails?.Details?.Trial ? `Trial` : `Subscribed`}</div>
        ) : null}
        <CardHeader className="h3 rounded-top font-weight-700">
          {/* <div
            style={{
              position: "absolute",
              right: "14px",
              top: "10px",
            }}
          >
            <Row>
              <Col className="p-0">
                <CustomTooltip
                  i={props.i}
                  id={`editPricing${props.i}`}
                  message="Update"
                  className="fas fa-pen fa-xs"
                  // className="fas fa-pen-alt"
                  onClick={() => props.handleUpdatePricing(record)}
                />
              </Col>
              <Col className="pl-1">
                <CustomTooltip
                  i={props.i}
                  id={`deletePricing${props.i}`}
                  message="Delete"
                  className="fas fa-times fa-sm"
                  onClick={() => {
                    props.confirmDelete(
                      `Are you sure you want to delete ${record.Name} Client?`,
                      record
                    );
                  }}
                />
              </Col>
            </Row>
          </div> */}
          {record.Name}
        </CardHeader>
        <CardBody className="px-lg-4 p-sm-3">
          <p
            className="display-4 font-weight-700 d-flex align-items-center"
            style={{
              // color: props.bgColor === '#fff' ? 'rgb(94, 114, 228)' : '',
              color: `${props.bgColor === "#fff" ? "rgb(94, 114, 228)" : props.bgColor
                }`,
            }}
          >
            {
              record.Code === 'TIER1' ?
                <small style={{ fontSize: "14px" }}>min.   </small>
                : null
            }
            {`$${record.Details.Price}`}{" "}
            <small style={{ fontSize: "14px" }}> / month</small>
          </p>
          <div className="mt-3 " style={{ height: '180px' }}>
            <p className="h5 my-2">
              {/* <em>GST EXCLUSIVE</em> */}
              {record.CountryCode === 'au' ? "GST EXCLUSIVE" : record.CountryCode === 'nz' ? "NZD SUBJECT TO FX AUD" : record.CountryCode === 'uk' ? "GBP SUBJECT TO FX AUD" : record.CountryCode === 'us' ? "USD SUBJECT TO FX AUD" : null}
              {/* GST EXCLUSIVE */}
            </p>
            <p className="h5 mb-2">
              {/* <em>NO CC (14 Day Trial)</em> */}
              NO CC (14 Day Trial)
            </p>
            <p className="h5 mb-2">Etani Platform Features</p>
            <p className="h5 mt-3 font-weight-300">
              {record.Details.Descriptions}
            </p>
          </div>
          <Row className="mb-4 mt-2 pt-3 d-flex align-items-start flex-column justify-content-center border-top">
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures name={"Clients"} info={`${record.Details.Clients} ${record.Code === props.firmDetails?.PricingPlan?.Code ? `( ${props.firmDetails?.Client.length} currently used )` : ""}`} />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"Basic Client Data Integration Limits"}
                info={
                  record.Name === "Etani Essentials"
                    ? `$${record.Details.BasicClientDataIntegration} Per Xero | MYOB | QBO`
                    : record.Details.BasicClientDataIntegration +
                    " Xero | MYOB | QBO"
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"Extended Client Data Integrations"}
                info={record.Details.ExtendedClientDataIntegration}
                additionalInfo={
                  <>
                    <span>Custom Pricing Per Integration*</span>
                    <span>
                      <a
                        href="https://www.etani.ai/integrations"
                        target="_blank"
                        rel="noreferrer"
                      >
                        See Integration Library
                      </a>
                    </span>
                  </>
                }
              />
            </Col>
            {
              record.Code === 'TIER1' ?
                <Col xs="12" xl="12" sm="12" md="12">
                  <CustomFeatures
                    name={"Advisor"}
                    info={`${record.Details.Advisors} ${record.Code === props.firmDetails?.PricingPlan?.Code ? `( ${props.firmDetails?.Accountant.length} currently used )` : ""}`}
                    url={
                      <a
                        href="https://www.etani.ai/frequently-asked-questions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        - Learn More
                      </a>
                    }
                  />
                </Col>
                : null
            }
            {
              record.Code === 'TIER1' ?
                <Col xs="12" xl="12" sm="12" md="12">
                  <CustomFeatures
                    name={`${record.Code === 'TIER1' ? 'Client' : ''} Users`}
                    info={`${record.Details.ClientUsers} ${record.Code === props.firmDetails?.PricingPlan?.Code ? `( ${props.firmDetails?.ClientUserCount ? props.firmDetails?.ClientUserCount : 0} currently used )` : ""}`}
                    url={
                      <a
                        href="https://www.etani.ai/frequently-asked-questions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        - Learn More
                      </a>
                    }
                  />
                </Col>
                :
                <Col xs="12" xl="12" sm="12" md="12">
                  <CustomFeatures
                    name={`${record.Code === 'TIER1' ? 'Client' : ''} Users`}
                    info={`${record.Details.ClientUsers} ${record.Code === props.firmDetails?.PricingPlan?.Code ? `( ${props.firmDetails?.ClientUserCount ? `${props.firmDetails?.Accountant.length} Advisers and ${props.firmDetails?.ClientUserCount} Client Users` : `${props.firmDetails?.Accountant.length} Advisers and 0 Client User`} currently used )` : ""}`}
                    url={
                      <a
                        href="https://www.etani.ai/frequently-asked-questions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        - Learn More
                      </a>
                    }
                  />
                </Col>
            }
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"Power BI Embedded"}
                info={record.Details.PowerBIEmbedded ? "Yes" : "No"}
                url={
                  <a
                    href="https://www.etani.ai/frequently-asked-questions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Learn More
                  </a>
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"EVA - Chat GPT - Data Enabled Per Client"}
                info={`${record.Details.Eva} Daily Limit`}
                url={
                  <a
                    href="https://www.etani.ai/frequently-asked-questions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Learn More
                  </a>
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"PDF Email Reports"}
                info={
                  record.Details.PDFReports < 1
                    ? "No"
                    : `${record.Details.PDFReports} Daily`
                }
                url={
                  <a
                    href="https://www.etani.ai/blog/dynamic-custom-automated-power-bi-pdf-emails"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Learn More
                  </a>
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={
                  record.Name === "Etani Essentials" || record.Name === "Etani Business"
                    ? `Etani Mobile App`
                    : `Branded Mobile App`
                }
                info={record.Details.MobileApp ? "Yes" : "No"}
                additionalInfo={
                  <>
                    <span className="mt-1">
                      Feautures: 2 Factor Authentication
                    </span>
                    <span className="mt-1">
                      Power BI Reports{" "}
                      {record.Name === "Etani Essentials" ? "" : ", EVA"}
                    </span>
                  </>
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"Additinal Cost Power BI Pro Account"}
                info={`$${record.Details.PowerBIPro} per advisor per month`}
                url={
                  <a
                    href="https://www.etani.ai/frequently-asked-questions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Learn More
                  </a>
                }
              />
            </Col>
            <Col xs="12" xl="12" sm="12" md="12">
              <CustomFeatures
                name={"Data ‘Refresh Now’ Functionality"}
                info={record.Details.DataRefresh ? "Yes" : "No"}
                url={
                  <a
                    href="https://www.etani.ai/frequently-asked-questions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Learn More
                  </a>
                }
              />
            </Col>
            {
              record.Code !== 'TIER1' ?
                <Col xs="12" xl="12" sm="12" md="12">
                  <CustomFeatures
                    hideCheck={true}
                    name={""}
                    info={``}
                  // url={
                  //   <a
                  //     href="https://www.etani.ai/frequently-asked-questions"
                  //     target="_blank"
                  //     rel="noreferrer"
                  //   >
                  //     - Learn More
                  //   </a>
                  // }
                  />
                </Col>
                : null
            }
            {
              props.firmDetails?.Details?.Trial && record.Code === 'TIER2' ?
                <Col xs="12" className="text-center" style={{ marginTop: '29px', marginBottom: '29px' }}>
                  <div></div>
                </Col>
                :
                <Col xs="12" className="text-center">
                  <div className="mt-3">
                    <Button
                      className="mt-auto"
                      style={{
                        borderRadius: "50px",
                        backgroundColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                          }`,
                        color: `${props.textColor === "#000" ? "#fff" : props.textColor}`,
                        borderColor: `${props.bgColor === "#fff" ? "#5e72e4" : props.bgColor
                          }`,
                      }}
                      disabled={props.userDetails?.User?.UserAccess <= 3 ? false : true}
                      onClick={() => props.firmDetails?.Details?.Trial ? props.subscribe() : props.handleUpgradePlan(record, (props.firmDetails?.PricingPlan?.Code === 'TIER2' && record.Code === 'TIER1') || (props.firmDetails?.PricingPlan?.Code === 'TIER3' && record.Code === 'TIER2') || (props.firmDetails?.PricingPlan?.Code === 'TIER4' && record.Code === 'TIER3') || (props.firmDetails?.PricingPlan?.Code === 'TIER5' && record.Code === 'TIER4') ? 'downgrade'
                        : record.Code === props.firmDetails?.PricingPlan?.Code ? 'cancelSubs' : `upgrade`, record.Code)}
                    >
                      {
                        props.firmDetails?.Details?.Trial ? `Subscribe to Plan`
                          : record.Code === props.firmDetails?.PricingPlan?.Code ? `Cancel Subscription`
                            : (props.firmDetails?.PricingPlan?.Code === 'TIER2' && record.Code === 'TIER1') || (props.firmDetails?.PricingPlan?.Code === 'TIER3' && record.Code === 'TIER2') || (props.firmDetails?.PricingPlan?.Code === 'TIER4' && record.Code === 'TIER3') || (props.firmDetails?.PricingPlan?.Code === 'TIER5' && record.Code === 'TIER4') ? 'Downgrade Plan'
                              : (props.firmDetails?.PricingPlan?.Code === 'TIER1' && record.Code === 'TIER2') || (props.firmDetails?.PricingPlan?.Code === 'TIER2' && record.Code === 'TIER3') || (props.firmDetails?.PricingPlan?.Code === 'TIER3' && record.Code === 'TIER4') || (props.firmDetails?.PricingPlan?.Code === 'TIER4' && record.Code === 'TIER5') ? 'Upgrade Plan'
                                : `Select Plan`
                      }
                    </Button>
                  </div>
                </Col>
            }
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PricingCard;
