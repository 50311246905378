import CustomTooltip from "customComponents/customComponents";
import React from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import avatar from "../../../../assets/img/blank-avatar.png";

const AccountantCard = ({ data, ...props }) => {
  const displaySubtitle = (userAccess) => {
    switch (userAccess) {
      case 1:
        return "Super Admin";
      // break;
      case 2:
        return "Advisor Super Admin";
      // break;
      case 3:
        return "Advisor Admin";
      // break;
      case 4:
        return "Advisor";
      // break;
      case 5:
        return "Client Admin";
      // break;
      case 6:
        return "Client User";
      // break;
      default:
        return;
    }
  };

  const showStatus = (status) => {
    if (status === 1) {
      return (
        <div className="mt-3 text-center">
          <Button
            size="sm"
            className="mt-auto"
            color="success"
            // href="#pablo"
            onClick={(e) => e.preventDefault()}
            style={{ borderRadius: "50px" }}
          >
            Active User
          </Button>
        </div>
      );
    } else if (status === 3) {
      return (
        <div className="mt-3 text-center">
          <Button
            size="sm"
            className="mt-auto"
            color="warning"
            // href="#pablo"
            onClick={(e) => e.preventDefault()}
            style={{ borderRadius: "50px" }}
          >
            Waiting for confirmation
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      {data?.map((acctnt, i) => (
        <Col xl="4" md="6" key={i}>
          <Card style={{ minHeight: "" }} className="zoom">
            <CardBody className="d-flex flex-column">
              {props.userDetails?.User?.UserAccess >= 4 ||
                !props.userDetails?.User?.Details?.Authority
                  ?.Admin ? null : props.isEditable ? (
                    <div
                      style={{ position: "absolute", right: "18px", top: "10px" }}
                    >
                      <Row>
                        {acctnt?.User?.Status === 3 ? (
                          <Col className="px-1">
                            <CustomTooltip
                              i={i}
                              id={`resendEmailInvitation${i}`}
                              message="Resend Email Invitation"
                              className="fas fa-paper-plane fa-xs"
                              onClick={() => props.handleResendEmail(acctnt)}
                            />
                          </Col>
                        ) : null}
                        <Col className="p-0">
                          <CustomTooltip
                            i={i}
                            id={`editAccountant${i}`}
                            message="Update"
                            className="fas fa-pen fa-xs"
                            // className="fas fa-pen-alt"
                            onClick={() => props.handleUpdateAccount(acctnt, i)}
                          />
                        </Col>
                        {props.userDetails?.User?.id === acctnt?.User?.id ? null : (
                          <Col className="pl-1">
                            <CustomTooltip
                              i={i}
                              id={`deleteAccountant${i}`}
                              message="Delete"
                              className="fas fa-times fa-sm"
                              onClick={() => props.confirmDelete(acctnt)}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  ) : null}
              <img
                alt="..."
                className="img-center img-fluid shadow shadow-lg--hover"
                src={acctnt?.User?.Avatar || avatar}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "0.475rem",
                }}
              />
              {/* <a href="#" onClick={(e) => e.preventDefault()}>
              </a> */}
              <div className="pt-4 text-center" style={{ height: "70px" }}>
                <h5 className="h3 title">
                  <span className="d-block mb-1">{`${acctnt?.User?.FirstName} ${acctnt?.User?.LastName}`}</span>
                  <small className="h5 font-weight-light text-muted">
                    {`${acctnt?.Name} / ${displaySubtitle(
                      acctnt?.User?.UserAccess
                    )}`}
                  </small>
                </h5>
              </div>
              <Row>
                <Col sm="12">
                  <hr />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="2">
                  <span className="btn-inner--icon ml-1">
                    <i className="ni ni-email-83" />
                  </span>
                </Col>
                <Col xs="10">
                  <h5>{acctnt?.User?.Email}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs="2">
                  <span className="btn-inner--icon ml-1">
                    <i className="ni ni-single-02" />
                  </span>
                </Col>
                <Col xs="10">
                  <h5>
                    {acctnt.Client &&
                      (acctnt.Client.length || acctnt.Client > 0)
                      ? (acctnt.Client.length || acctnt.Client) + " Clients"
                      : "No Clients"}
                  </h5>
                </Col>
              </Row>
              {showStatus(acctnt?.User?.Status)}
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default AccountantCard;
