import { MainContext } from 'context/mainContext';
import React, { useContext, useState } from 'react'

const ClientPageTabs = ({ ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  // const [tabKey, setKey] = useState('personal');
  const tabList = [
    {
      id: "clients-tab",
      name: "clients",
      display: "Clients"
    },
    {
      id: "users-tab",
      name: "users",
      display: "Users"
    },
    {
      id: "integrations-tab",
      name: "integrations",
      display: "Integrations"
    // },
    // {
    //   id: "integrations-schedule-tab",
    //   name: "integration-schedule",
    //   display: "Integrations Schedule"
    }
  ]

  return (
    <>
      <div className="nav nav-tabs" role="tablist" style={{ border: `unset` }}>
        {tabList.map((item, i) => (
          <a key={i} id={item.id} href="#" role="tab"
            style={props.tabKey === item.name ? { backgroundColor: bgColor, color: textColor } : { color: bgColor, backgroundColor: textColor }}
            className={`nav-item nav-link ${props.tabKey === item.name ? "active" : ""}`}
            onClick={() => props.handleTabSelect(item.name)}>{item.display}</a>
        ))}
      </div>

      <div className="col-sm-12 tabContainer" style={{ borderTop: `3px solid ${bgColor}` }}>
        {props.children}
      </div>
    </>
  )
}
export default ClientPageTabs