import CustomGroup from "components/CustomGroups/CustomGroup";
import React, { useContext, useEffect, useState } from "react";
import CustomHeader from "customComponents/customHeader";
import { Button, Col, Container, Form, Input, Modal, Row } from "reactstrap";
import PanelCard from "components/Cards/PanelCard";
import { MainContext } from "context/mainContext";
import BlockUI from "components/Misc/BlockUI";
import InputModal from "components/CustomGroups/InputModal";
import CustomSpinner from "components/Misc/CustomSpinner";
import { generateUUID } from "services/EVAService";
import { getCustomGroups } from "services/mongoDB";
import Group from "components/CustomGroups/Group";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteGroup } from "services/mongoDB";
import SendExpoNotifications from "services/ExpoNotificationService";
import { mongoFetchToken } from "services/mongoDB";
import SendMultiDeviceNotification from "services/NotificationService";
import { registerGroupNotification } from "services/mongoDB";
// import socket from "services/MainSocket";

const CustomGroups = () => {
  const {
    bgColor,
    textColor,
    selectedClientID,
    userDetails,
    firmDetails,
    getDetails,
  } = useContext(MainContext);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [defaultModal, setdefaultModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [groups, setGroups] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [uuid, setUUID] = useState(null)
  const [isEditGroup, setIsEditGroup] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState([])
  const [alert, setAlert] = useState(false);
  const [newUpdateCount, setNewUpdateCount] = useState(0);
  const [createdBy, setCreateBy]=useState('')

  useEffect(() => {
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    const loadClientDetails = async () => {
      setIsLoading(true);
      setCreateBy(userDetails.User.FirstName + ' ' + userDetails.User.LastName)
      await loadSelectedClientDetails(sessionSelectedClientID ?? selectedClientID)
      await handleLoadCustomGroups(sessionSelectedClientID ?? selectedClientID)
    }
    loadClientDetails();

  }, [selectedClientID, newUpdateCount])
  const handleSetShowBlockUI = (val) => {
    setShowBlockUI(val)
  }
  const handleLoadCustomGroups = async (selectedClientID) => {
    try {
      const res = await getCustomGroups({ ClientId: selectedClientID })
      setGroups(res)
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    };
  }
  const loadSelectedClientDetails = async (selectedClientID) => {
    try {
      fetch(
        `/api/client/showDetails/${selectedClientID}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log(data)
            setSelectedClient(data);
          } else {
            return;
          }

        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const addEditCustomGroup = async (data, isEdit = false) => {

    const uid = await generateUUID()
    const paramId = data ? data._id : uid
    setSelectedGroup(data)
    setIsEditGroup(isEdit)
    setUUID(paramId)
    setShowBlockUI(true);
    setdefaultModal(true);
    setShowBlockUI(false);
  }
  const showBlockUIModal = () => {
    return <BlockUI />;
  };
  const handleCloseModal = () => {
    setNewUpdateCount(prevCount => prevCount + 1);
    setdefaultModal(false);
  };
  const showDeleteConfirmation = (data) => {
    setAlert(
        <ReactBSAlert
            warning
            title="Warning"
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => deleteGroupItem(data)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            showCancel={true}
            btnSize=""
        >
            <p>
                Are you sure you want to delete <strong>{data.GroupName}</strong>?'
            </p>
        </ReactBSAlert>
    );
  };
  const deleteGroupItem = async (data) => {
    try {
        await deleteGroup({ _id: data._id })
        const alertMessage = `You have successfully deleted the ${data.GroupName}`
        handleSuccessUpdate(alertMessage)

        let msg = `deleted the custom group named ${data.GroupName}`
        sendNotificationHandler(data.Users, false, msg, data.GroupName)
    } catch (error) {

    }
  }
  const sendNotificationHandler = async (users, isRemoved, msg, groupName) => {
    sendNotification(users, msg)
    const ClientID = selectedClientID
    const notification = {
      title: `new notification from the "${groupName}" group`,
      body:  `${createdBy} ${msg}`,
      sound: 'default',
      badge: '1',
    };
    
    const clientToken = await mongoFetchToken({ clientid : ClientID, groupId: selectedClientID })
    if(!clientToken) return 
    
    const filteredTokens = clientToken.tokens
    .filter(token => {
        return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
    })
    .map(token => token.token);

    if (filteredTokens.length === 0) return

    const message = {
      tokens: filteredTokens,
      data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'custom group', Id: selectedClient.id},
      notification: notification,
    };

    SendMultiDeviceNotification(message)
    SendExpoNotifications(message)
  };

  const sendNotification = async (selectedUsers, message) => {
    for (const email of selectedUsers) {

      if(email !== userDetails.User.Email)
      {
        const notifications = {
          uid: await generateUUID(),
          id: selectedClientID,
          clientId: selectedClientID,
          message: `${createdBy} ${message}`,
          createdAt: new Date(),
          sender: userDetails.User.Email,
          isReport: true,
          email: email,
          user: email,
          url: '/client/customDashboard',
          groupId: selectedClientID,
        };
        // await socket.emit("newClientNotification", notifications);
        registerGroupNotification(notifications)
      }
    }
  };
  
  const handleSuccessUpdate = (msg, data) => {
    setAlert(
        <ReactBSAlert
            success
            title="Success"
            onConfirm={async () => {
                setAlert(null)
                setNewUpdateCount(prevCount => prevCount + 1);
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
        >
            <>
                <p>
                    {msg}
                </p>
            </>
        </ReactBSAlert>
    );
  }

  return (
    <Container className="mt-6 fadeIn" fluid>
      {alert}
      <div className="nav-wrapper">
        <PanelCard headerName="Custom Groups">
          {!selectedClientID ? <p>Select client first</p> :
            isLoading ? <CustomSpinner /> :
              <>
                <Row>
                  <div className="mb-3">
                    <Button
                      style={{
                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      onClick={() => addEditCustomGroup()}
                      id="addAccountant"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="ni ni-fat-add"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      add custom group
                    </Button>

                    {showBlockUI ? showBlockUIModal() : null}
                    {defaultModal && 
                      <InputModal
                        groupId={uuid}
                        isEditGroup={isEditGroup}
                        selectedGroup={selectedGroup}
                        selectedClientID={selectedClientID}
                        selectedClient={selectedClient}
                        defaultModal={defaultModal}
                        userDetails={userDetails}
                        bgColor={bgColor}
                        handleCloseModal={handleCloseModal}
                        setShowBlockUI={handleSetShowBlockUI} />
                    }
                  </div>
                </Row>
                <Row>
                  {groups.map((group, i) => (

                    <Group
                      data={group}
                      handleSetShowBlockUI={handleSetShowBlockUI}
                      addEditCustomGroup={addEditCustomGroup}
                      showDeleteConfirmation={showDeleteConfirmation}
                    // isEditable={true}
                    // userUpdate={userUpdate}
                    // confirmDelete={confirmDelete}
                    // confirmUpdate={confirmUpdate}
                    // handleResendEmail={handleResendEmail}
                    />
                  ))}
                </Row>
              </>
          }
        </PanelCard>
      </div>
    </Container>
  )
}
export default CustomGroups