import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Input,
  Col,
  Row,
  Form,
  FormGroup,
  InputGroup,
  Modal,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import AddressList from "./AddressList";
import { MainContext } from "context/mainContext";
import { CustomFieldGroup } from "customComponents/Form";
import AboutUsCard from "components/Cards/AboutUsCard";

const Address = () => {
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { bgColor, textColor, userDetails } = useContext(MainContext);
  // const [loading, setLoading] = useState(true)
  const [alert, setAlert] = useState(null);

  const [modal, setModal] = useState({
    title: "Add Address",
    show: false,
    record: {},
    action: "create",
  });

  useEffect(() => {
    // fetch("/api/address/getAllRecords", {
    //   method: "GET",
    //   credentials: "include"
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.error) {
    //       // alert(data.error);
    //       data = [];
    //     }
    //     setData(data);
    //     setFilteredData(data);
    //     // console.log(data);
    //   });
  }, [userDetails]);

  const openModal = (record) => {
    if (record) {
      setModal({
        title: "Update Address",
        show: true,
        record: record,
        actionUrl: "/api/Address/updateRecord?id=" + record.id,
        actionUrlMethod: "PUT",
      });
    } else {
      setModal({
        title: "Add New  Address",
        show: true,
        record: {},
        actionUrl: "/api/Address/createRecord",
        actionUrlMethod: "POST",
      });
    }
  };

  const closeModal = () => {
    setModal({
      title: "Add Address",
      show: false,
      record: {},
      action: "create",
    });
  };

  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = data.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilteredData(filtered);
  };
  // eslint-disable-next-line
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    modal.record[name] = value;

    setModal(modal);
  };

  const addressList = () => {
    if (filteredData.length === 0) {
      return (
        <div className="col-sm-12 text-center">
          <h5>No address found.</h5>
        </div>
      );
    } else {
      return filteredData.map((data, i) => {
        return (
          <AddressList
            data={data}
            i={i}
            openModal={openModal}
            displaySubtitle={displaySubtitle}
          />
        );
      });
    }
  };

  const displaySubtitle = (index) => {
    switch (index) {
      case 1:
        return "Billing Address";
      case 2:
        return "Office Address";
      case 3:
        return "Home Address";
      default:
        return;
    }
  };

  // const handleDelete = (record) => {
  //   fetch('/api/Address/deleteRecord?id=' + record.id, {
  //     method: 'DELETE',
  //     credentials: 'include',
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return alert(response.error)
  //       else {
  //         // alert(response.message);
  //         // confimDelete(response)
  //         successAlert(response)

  //         for (let i = 0; i < filteredData.length; i++) {
  //           if (filteredData[i].id == record.id) {
  //             filteredData.splice(i, 1)
  //             break
  //           }
  //         }
  //       }
  //     })
  // }
  // eslint-disable-next-line
  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message}
      </ReactBSAlert>
    );
  };

  return (
    <AboutUsCard>
      {alert}
      <Row className="">
        <Col className="mt-2">
          <div className="">
            {userDetails?.User?.Details?.Authority?.Admin ? (
              <Button
                size="sm"
                type="button"
                onClick={() => openModal("formModal")}
                style={{
                  backgroundColor: `${
                    bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`,
                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                  border: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                }}
              >
                <i className="ni ni-fat-add" />
                Add New Business Location
              </Button>
            ) : null}
          </div>
        </Col>
        <Col sm="4" className="mt-2">
          <Form>
            <Input
              placeholder="Search"
              type="text"
              bsSize="sm"
              onChange={handleSearch}
            />
          </Form>
        </Col>
      </Row>
      <Row className="mt-5">{addressList()}</Row>

      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={modal.show}
        // toggle={() => closeModal("formModal")}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {modal.title}
          </h6>
        </div>
        <div className="modal-body ">
          <Form role="form" className="mt-2" onSubmit={() => closeModal()}>
            <Row>
              <Col>
                <CustomFieldGroup
                  id="Name"
                  name="Name"
                  type="text"
                  label="Unique Address Name"
                  placeholder="Enter Address Name. (Must be unique, eg: Adelaide Office"
                  // onChange={handleInputChange}
                  // value={modal.record.Name || ''}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Select Physical Address</label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Enter Physical Address"
                      id="Address"
                      type="text"
                      name="Address"
                      required={true}
                      country="au"
                      // onChange={handleInputChange}
                      // defaultValue={modal.record.Address || ''}
                    />

                    <Button
                      style={{
                        color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                      }}
                    >
                      Clear
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id="PostalAddress"
                  name="PostalAddress"
                  type="text"
                  label="Postal Address If Applicable"
                  placeholder="Enter Postal Address"
                  country="au"
                  // onChange={handleInputChange}
                  // value={modal.record.PostalAddress || ''}
                  required={true}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            // color="primary"
            type="submit"
            style={{
              backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              color: `${textColor === "#000" ? "#fff" : textColor}`,
              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => closeModal()}
            color="link"
            type="submit"
            style={{
              color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </AboutUsCard>
  );
};

export default Address;
