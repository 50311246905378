import React, { useContext, useEffect, useMemo, useState } from 'react'
import GridView from './GridView'
import TreeView from './TreeView'
import GraphView from './GraphView'
import { IconEtani } from '../ui/icon'
import XBImage from '../../assets/img/XB.png';
import XPMImage from '../../assets/img/XPM.png';
import DefaultImage from '../../assets/img/etani-ai.png';
import Feedback from 'eva-component/ui/feedback'
import { evaThemeLighter } from 'services/EVAResponseService'
import EVADashboardVisual from 'components/DashboardVisual/EVADashboardVisual'
import DashboardTemplateItemNewVisual from 'views/pages/dashboards/DashboardTemplateItemNewVisual'
import { updateEVAVisual } from 'services/mongoDB'
import { getRandomLightColor } from 'services/EVAService'
import { defaultControl } from 'services/EVAVisualService'
import EVAAnswer from './EVAAnswer'
import { MainContext } from 'context/mainContext'
import { decryptAndDecompress } from 'services/DashboardService'
import { TransformSQLResult } from 'services/EVAEditGridService'
import { PopulateNewRows } from 'services/EVAEditGridService'
import { ProcessRemoveRow } from 'services/EVAEditGridService'
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService'
import { RecalculateWithTotal } from 'services/EVAEditGridService'
import { GetCellIdValue } from 'services/EVAEditGridService'
import { ProcessGroupWithTotal } from 'services/EVAEditGridService'
import { ProcessRenamingSQLData } from 'services/EVAEditGridService'
import { ProcessGrandTotal } from 'services/EVAEditGridService'
import { ProcessGrandParentRow } from 'services/EVAEditGridService'
import { UpdateCellWithFormula } from 'services/EVAEditGridService'
import { ProcessDefaultLinevisual } from 'services/EVAVisualService'
import { Button } from 'reactstrap'
import { fetchAllEvaIntegrations } from 'services/mongoDB'

function EVAResponse({  currentMessage, theme, userData, showAvatar = true, showFeedback = true, showQuery = false, 
    index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate, ViewFullScreen, isWelcomeMessage,
    handleSendEVAStreamMessage
 }) {
    const [viewMore, setViewMore] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [observation, setObservation] = useState(null)
    const [sqlresult, setSqlResult] = useState(null)
    const [originalSqlresult, setOriginalSqlResult] = useState(null)
    const [integration, setIntegration] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [gridOriginalHeader, setOriginalGridHeader] = useState(null)

    const [EVAData, setEVAData] = useState(null)
    const [isError, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [groupLevel, setGroupLevel] = useState(null)
    
    const [isWithChild, setIsWithChild] = useState(false)
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const [haveEVAData, setHaveEVAData] = useState(false)
    const [isShowObserbation, setIsShowObservation] = useState(false)
    const [version, setVersion] = useState(null)
    const [stext, setStext] = useState(null)
    const [toggleAll, setToggleAll] = useState(false)
    const [showEditVisual, setShowEditVisual] = useState(false)
    const [selectedViewOption, setSelectedViewOption] = useState(null)
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const [editedGridCellData, setEditedGridCellData] = useState(null)
    const [newUpdate, setNewUpdate] = useState(0)
    const [isEVAPage, setIsEVAPage] = useState(true)
    const [isFromPnL, setIsFromPnL] = useState(false)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [includesEmail, setIncludesEmail] = useState(false)
    const [integrationImg, setIntegrationImg] = useState(null)
    const { text, query, formatedResponse } = currentMessage;


    const {
        userDetails,
      } = useContext(MainContext);

    useEffect(() => {
        // console.log(currentMessage)
        
    }, [treeViewData])

    useEffect(() => {

        const loadStext = async (stext) => {
            const email = userDetails.User.Email.toLowerCase()
            const specialEmails = ["fuhad@etani.com.au", "admin@etani-bp.com.au"];

            if(stext) {
                const decryptedText = await decryptAndDecompress(stext);
                setIncludesEmail(specialEmails.includes(email))
                if (specialEmails.includes(email))
                    console.log(decryptedText)
            }
        }
        const fetchIntegrationImage = async (integration) => {
            const integrationCode = {
                'XB': 'Xero Blue',
                'XPM': 'XPM',
                'MYOB AR Live': 'MYOB',
                'WFM': 'WFM',
                'WFM2': 'WFM'
            }

            const integrationList = await fetchAllEvaIntegrations()
            const integrationImg = integrationList && integrationList.length > 0 && integrationList.find(i => 
                i.name === integrationCode[integration]
            );

            if(integrationImg)
                setIntegrationImg(integrationImg.image)
        }

        if (formatedResponse !== undefined && formatedResponse.answer !== undefined && formatedResponse.observation !== undefined && formatedResponse.parsedData !== undefined) {
            fetchIntegrationImage(formatedResponse.integration)
            setEVAData(formatedResponse);
            setGridHeader(formatedResponse.headers);
            setOriginalGridHeader(formatedResponse.headers)
            setAnswer(formatedResponse.answer);
            setObservation(formatedResponse.observation);
            const sqlRes = processDataArray(formatedResponse.parsedData)
            setSqlResult(sqlRes);
            setOriginalSqlResult(formatedResponse.parsedData);
            setIntegration(formatedResponse.integration)
            setIsWithChild(formatedResponse.isWithChild)

            setTreeViewData(formatedResponse.newTreeData)
            setIsShowGrid(!formatedResponse.isWithChild)
            setVersion(formatedResponse.version)
            setStext(formatedResponse.stext)
            setHaveEVAData(sqlRes.length > 0)
            setGroupLevel(formatedResponse.groupLevel)
            
            loadStext(formatedResponse.stext)
            
            // const newTreeData = processEditedGridCellData(currentMessage, sqlRes, formatedResponse.headers)

            // if(newTreeData) setTreeViewData(newTreeData)
            
            if(currentMessage.visual)
            {
                setSelectedViewOption(currentMessage.visual.selectedViewOption)
                setTemplateItemVisual(currentMessage.visual)
            }

        } else {
            setError(true)
            setErrorMessage(text)
        }
    }, [formatedResponse, newUpdate]);
    const processGrandTotal = () => {
        const regex = /(pnl|profit\s*(and|&)?\s*loss)/i;

        const withPnL = regex.test(formatedResponse?.answer ? formatedResponse.answer.toLowerCase() : "") || regex.test(query ? query.toLowerCase() : "");

        if(!withPnL)
            setTreeViewData(formatedResponse.newTreeDataWithGrandTotal)
    }
    useEffect(() => {
        if(gridHeader && sqlresult && !templateItemVisual)
        {
            const sqlId = JSON.parse(JSON.stringify(sqlresult));
            const withCumulative =  formatedResponse?.answer && formatedResponse.answer.toLowerCase().includes('cumulative') && query && query.toLowerCase().includes('cumulative')


            if(withCumulative && sqlresult.length > 1)
            {
                const LineObject = ProcessDefaultLinevisual(formatedResponse.parsedData, formatedResponse.headers, groupLevel)
                setTemplateItemVisual(LineObject)
            } else processGrandTotal()
            
            if(sqlresult.length === 1)
            {
                let item = sqlresult[0]

                const cardItem = []
                gridHeader.map((row, index) => {
                    if (typeof sqlresult[0][index] === 'number' && !isNaN(sqlresult[0][index]))
                        cardItem.push({
                            "id": index,
                            "column": gridHeader[index],
                            "columnItem": "",
                            "columnItemList": [item[index]],
                            "columnToSum": gridHeader[index],
                            "sum": sqlresult[0][index],
                            "title": "",
                            "description": ""
                        })
                })

                const headerToMultiSelect = []
                gridHeader.map((item, index) => {
                    if (typeof sqlresult[0][index] === 'number' && !isNaN(sqlresult[0][index]))
                        headerToMultiSelect.push( {
                            "id": index,
                            "name": gridHeader[index]
                        })
                })

                const cardObject = {
                    "selectedViewOption": "Card",
                    "cardItem": cardItem,
                    "headerToMultiSelect": headerToMultiSelect
                }

                setTemplateItemVisual(cardObject)
            }
        }
    },[gridHeader, sqlresult, formatedResponse, newUpdate])


    useEffect(() => {

    },[templateItemVisual])
    
    const processEditedGridCellData = (item, sqlresult, gridHeader) => {
        if(item && item.editedGridCellData)
        {
            const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow, renamedHeaderList, addedColumnList } = item.editedGridCellData
            let header = gridHeader
            let sqlData = sqlresult
            let updatedNewRowList = newRowList

            const processNewHeader = (columnHeader, sqlData, newRowList) => {
                let header = [...columnHeader];
                let updatedData = [...sqlData];
                let updateRowList = JSON.parse(JSON.stringify(newRowList));
            
                if (addedColumnList.length > 0) {
                    addedColumnList.map(item => {
                        header.push(item.name);
                        addZeroToRow(updateRowList);
                    });
                    updatedData = sqlData.map(item => [...item, 0]);
                }
            
                function addZeroToRow(updateRowList) {
                    for (let i = 0; i < updateRowList.length; i++) {
                        const row = updateRowList[i];
                        if (row.value) row.value = [...row.value, 0];
                        if (row.children) addZeroToRow(row.children);
                    }
                }
                setGridHeader(header);
                return { header, updatedData, updateRowList };
            };
            
            if(addedColumnList) {
                const headerData = processNewHeader(gridHeader, sqlData, newRowList);
                header = headerData.header
                sqlData = headerData.updatedData
                updatedNewRowList = headerData.updateRowList
            }

            if(renamedHeaderList) header = ProcessColumnRename(renamedHeaderList, header)
            
            let treeData = []

            treeData = TransformSQLResult(sqlData, header, gridGroupLevel)
           

            treeData = PopulateNewRows(updatedNewRowList, sqlData, header, gridGroupLevel)

            if(dragAndDropData.length > 0) 
            {
                treeData = ProcessDnDTreeviewData(dragAndDropData, treeData)
                const isWithGroup = dragAndDropData.filter(g => g.parentId && g.parentId.includes("-group-"))
                if(isWithGroup.length > 0)
                {
                    treeData = RecalculateWithTotal(treeData)
                }
            }

            let cellValue = GetCellIdValue(treeData)

            treeData = ProcessGroupWithTotal(treeData, cellValue, header, gridGroupLevel, cellFormulaList)
        
            const processRenaming = (treeData) => {
                if (renamedSQLData && renamedSQLData.length > 0) {
                    return ProcessRenamingSQLData(renamedSQLData, treeData);
                }
                return treeData;
            };
     
            treeData = ProcessGrandTotal(treeData, cellValue, updatedNewRowList, header, gridGroupLevel, cellFormulaList)
            cellValue = GetCellIdValue(treeData)
            treeData = ProcessGrandParentRow(updatedNewRowList, treeData)
            treeData = processRenaming(treeData);
            treeData = ProcessRemoveRow(treeData, hiddenRow)
            if(cellFormulaList) {
                cellValue = GetCellIdValue(treeData)
                treeData = UpdateCellWithFormula(treeData, cellValue, cellFormulaList)
            }
            return treeData
        }
        return null
    }
    const ProcessColumnRename = (renamedHeaderList, gridHeader) => {
        if (renamedHeaderList?.length === 0 || !renamedHeaderList)
        { 
            setGridHeader(gridHeader);
            return gridHeader
        }

        const newColumnHeader = [...gridHeader]

        renamedHeaderList && renamedHeaderList.map((item, index) => {
            newColumnHeader[item.colIndex] =  item.newHeader
        })

        setGridHeader(newColumnHeader);
        return newColumnHeader
    }
    const processDataArray = (data) => {
        return data.map(item => {
            return item.map(value => {
                // Check if the value is a string containing a percentage
                if (typeof value === 'string' && value.includes('%')) {
                    value = parseFloat(value.replace('%', '')).toFixed(2) + '%';
                } else if (typeof value === 'number') {
                    // If the value is a number, format it to two decimal places
                    value = Number(value.toFixed(2));
                }
                return value;
            });
        });
    };
    const showGridHandler = () => {
        setIsShowGrid(!isShowGrid)
        setIsShowGrap(false)
    }
    const showGrapHandler = () => {
        setIsShowGrap(!isShowGrap)
    }
    const ShowObserbationHandler = () => {
        setIsShowObservation(!isShowObserbation)
    }
    const toggleAllVisibility = () => {
        setToggleAll(!toggleAll)
    }
    const fileTypeImages = {
        XB: XBImage,
        XPM: XPMImage,
        default: DefaultImage,
    };
    const handleshowEditVisual = () => {
        setShowEditVisual(!showEditVisual)
    }
    const handleSaveEVAVisual = (item) => {
        setTemplateItemVisual(item.visual)
        updateEVAVisual(item)
        setShowEditVisual(false)
        setNewUpdate(prevCount => prevCount + 1);
    }
    const handleSaveEVAGridChanges = (item) => {
        updateEVAVisual(item)
        setNewUpdate(prevCount => prevCount + 1);
    }

    return (
        <div>

            <div className='EVAResponseContainer'>
                {showAvatar &&
                    <div className='EVAAvatar'>
                        <IconEtani />
                    </div>}
                {isError ? (
                    <>
                        <div style={{ paddingTop: 30 }}>
                            {errorMessage}
                            <br></br>
                            <br></br>
                            question: {query}
                        </div>
                    </>
                    
                ) : (
                    <>
                        <div className='EVAMessage'>
                            <>
                                {showEditVisual && 
                                    <EVADashboardVisual
                                        item={currentMessage}     
                                        gridHeader={gridHeader}
                                        gridOriginalHeader={gridOriginalHeader}
                                        originalSqlresult={originalSqlresult}
                                        theme={theme} 
                                        treeViewData={treeViewData}
                                        isWithChild={isWithChild}
                                        isShowGrid={isShowGrid}
                                        toggleAll={toggleAll}
                                        isShowGrap={isShowGrap}
                                        onEditVisual={showEditVisual}
                                        handleshowEditVisual={handleshowEditVisual}
                                        handleSaveEVAVisual={handleSaveEVAVisual}
                                        handleSaveEVAGridChanges={handleSaveEVAGridChanges}
                                        templateItemVisual={templateItemVisual}
                                        groupLevel={groupLevel}
                                    />
                                }
                                
                                <div className='evaText' 
                                    onClick={() => {
                                        if (typeof ViewFullScreen === 'function') {
                                          ViewFullScreen(currentMessage, userData, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate);
                                        } else {
                                          console.error('ViewFullScreen is not a function');
                                        }
                                    }}>
                                        
                                    {((showQuery || includesEmail) && query && query.length > 0) && 
                                        <>
                                            <div className='evaLabel'>Question:</div>
                                            <div>{query}</div>
                                        </>
                                    }
                                    <div className='evaLabel'>Answer:</div>
                                    <div>
                                        {answer && <EVAAnswer answer={answer} query={query} isWelcomeMessage={isWelcomeMessage} handleSendEVAStreamMessage={handleSendEVAStreamMessage} />}
                                        {/* {answer && answer.split("`nl`").map((line, index) => <div key={index}>{line}</div>)} */}
                                    </div>

                                    {haveEVAData &&
                                        <>
                                            {isShowObserbation && <div>
                                                <div className='evaLabel'>Observation:</div>
                                                {observation} {`| EVA Version: ${version}`}
                                            </div>}
                                            {showFeedback &&
                                                <>
                                                  <Button 
                                                        // className='toggleObservation viewOptionBtn'
                                                        // style={evaThemeLighter(100, theme)}
                                                        style={{ width: 147 }}
                                                        color='primary'
                                                        outline
                                                        size='sm'
                                                        onClick={() => ShowObserbationHandler()}>
                                                         {`${isShowObserbation ? 'Observation ▲' : 'Observation ▼'}`}
                                                    </Button>
                                                    <div className='likeChat'>
                                                  
                                                    <Feedback
                                                        messages={currentMessage}
                                                        theme={theme}
                                                        integration={integration}
                                                        userData={userData}
                                                        stext={stext}
                                                        version={version}
                                                        observation={observation}
                                                        handleshowEditVisual={handleshowEditVisual}
                                                        templateItemVisual={templateItemVisual}
                                                        editedGridCellData={editedGridCellData}
                                                        handleNewUpdate={handleNewUpdate}
                                                        selectedDashboardTemplate={selectedDashboardTemplate}
                                                        handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                                                        integrationImg={integrationImg}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </>
                            {haveEVAData &&
                            <div onClick={() => {
                                if (typeof ViewFullScreen === 'function') {
                                  ViewFullScreen(currentMessage, userData, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate);
                                } else {
                                  console.error('ViewFullScreen is not a function');
                                }
                              }}>
                                {templateItemVisual && selectedViewOption !== 'Grid' ?
                                    <>
                                        <DashboardTemplateItemNewVisual
                                            isEVAPage={isEVAPage}
                                            item={currentMessage}
                                            index={index} 
                                            gridHeader={gridHeader}
                                            sqlresult={sqlresult}
                                            templateItemVisual={templateItemVisual}
                                            treeViewData={treeViewData}
                                            isWithChild={isWithChild}
                                            isShowGrid={isShowGrid}
                                            toggleAll={toggleAll}
                                            isShowGrap={isShowGrap}
                                            groupLevel={groupLevel}
                                        />
                                    </>
                                    :
                                    <div className='EVADataContainer'>
                                        <div className='viewOptionBtnContainer'>
                                            {isWithChild &&
                                                <div
                                                    className='viewOptionBtn'
                                                    style={{
                                                        backgroundColor: theme.PrimaryColor,
                                                        color: theme.TextColor
                                                    }}
                                                    onClick={() => showGridHandler()}>
                                                    {isShowGrid ? "Grid view" : "Tree view"}
                                                </div>
                                            }
                                            {/* <div
                                                className='viewOptionBtn'
                                                style={evaThemeLighter(100, theme)}
                                                onClick={() => showGrapHandler()}
                                            >
                                                {isShowGrap ? isShowGrid ? "Back to Grid view" : "Back to Tree view" : "Convert to Graph view"}
                                            </div> */}
                                            {isWithChild &&
                                                <div
                                                    className='viewOptionBtn'
                                                    style={{
                                                        backgroundColor: theme.PrimaryColor,
                                                        color: theme.TextColor
                                                    }}
                                                    onClick={() => toggleAllVisibility()}>
                                                    {toggleAll ? "Expand All" : "Collapse All"}
                                                </div>
                                            }
                                        </div>
                                        
                                        <div className='EVADataContent' style={{ maxHeight: screenHeight - 300 }}>
                                            {/* {isWithChild && !isShowGrid ? */}
                                                <TreeView
                                                    item={currentMessage}
                                                    theme={theme}
                                                    sqlresult={sqlresult}
                                                    gridHeader={gridHeader}
                                                    treeViewData={treeViewData}
                                                    toggleAll={toggleAll}
                                                    isFromPnL={isFromPnL}
                                                    groupLevel={groupLevel}
                                                /> 
                                                {/* : <GridView
                                                    theme={theme}
                                                    sqlresult={sqlresult}
                                                    EVAData={EVAData}
                                                    gridHeader={gridHeader}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    </>
                )}
            </div>
        </div>
    );

}

export default EVAResponse;