/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
import MainFooter from "components/Footers/MainFooter";
import MainSidebar from "components/Sidebar/MainSidebar";
// import base64 from "base-64";

import { MainContext } from "context/mainContext";
import routes from "mainRoutes.js";
import CourseContent from "views/pages/trainingVideo/CourseContents";
import AnnouncementDetails from "views/pages/pushNotification/AnnouncementDetails";
// import MultifactorAuth from "views/pages/login/MultifactorAuth/MultifactorAuth";
import SignClient from "views/pages/clientSignEngagement/SignClient";
import Pricing from "views/pages/pricing/Pricing";
import { getCustomGroups } from "services/mongoDB";
import { groupNotifications } from "services/mongoDB";
import { useSelect } from "react-select-search";
import { registerGroupChatNotification } from "services/mongoDB";
import { generateUUID } from "services/EVAService";
import { deleteGroupChatNotifications } from "services/mongoDB";
import { groupChatNotifications } from "services/mongoDB";
import { loadAnnouncements } from "services/mongoDB";
import { announcementReadStatus } from "services/mongoDB";
import { registerGroupNotification } from "services/mongoDB";
import { fetchNotifications } from "services/mongoDB";
// import socket from "services/MainSocket";


function Admin() {
  // eslint-disable-next-line
  const {
    color,
    bgColor,
    firmDetails,
    userDetails,
    setUnReadAnnouncements,
    unReadAnnouncements,
    selectedClientID,
    setSelectedClientID,
    setSelectedClient,
    selectedClient,
    setNavNotifications,
    setContextClientLists,
    setContextFilteredClientLists,
    setNewMessageReceived,
    newMessageReceived,
    userChatNotification,
    setUserChatNotification,
    setReadAnnouncements,
    readAnnouncements,
    setFilteredAnnouncements,
    selectClientIntegration, 
    setSelectClientIntegration,
    setIsMultiBusinessUser,
    setExistingMySQLAccess,
    setMultiBusinessList
  } = useContext(MainContext);
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [userClients, setUserClients] = React.useState();
  const [selectedUserClient, setSelectedUserClient] = React.useState();
  const [adminSelectedClientId, setAdminSelectedClientId] = useState(null)
  const [oldSelectedClientId, setOldSelectedClientId] = useState(null)
  const [limitFetch, setLimitFetch] = useState(0)
  const history = useHistory();

  useEffect(() => {

    if (Object.keys(userDetails).length === 0) return 
    if (Object.keys(firmDetails).length === 0) return;
    let isMounted = true;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;

    if (isMounted) {
      if (!selectedClientID) {
        if (limitFetch === 0) getUserClientLists();
      } else if (selectedClientID !== oldSelectedClientId) {
        getUserGroup();
        fetchGroupNotification();
        fetchChatNotification();
        // fetchFromSse();
      
      }
    }

    if (firmDetails?.Details?.NeedSetPayment) {
      return history.push("/signup/payment-setup")
    }
  }, [firmDetails, userDetails, selectedClientID]);

  useEffect(() => {
    if (Object.keys(userDetails).length === 0) return 
    fetchMBU();
  }, [userDetails])

  useEffect(() => {
    if(!selectedClient) return

    loadClientIntegration(selectedClient)
  },[selectedClient])

  const fetchMBU = async () => {
    try {
      const ubRecord = await fetch("/api/accountant/getAllUserBusiness")
      const ubRecordResult = await ubRecord.json()
      if (ubRecordResult.data && !ubRecordResult.data.error) {
        const data = ubRecordResult.data
        
        if(data.length > 1) {
          setIsMultiBusinessUser(true)

          const allBusinessPerMBU = await fetch("/api/trialUser/getAllBusinessesPerMBU")
          const allBusinessPerMBUResutlt = await allBusinessPerMBU.json()
          if(allBusinessPerMBUResutlt.record && !allBusinessPerMBUResutlt.error){

            const businesses = allBusinessPerMBUResutlt.record
            const businessList = []
            for (const business of businesses) {
              businessList.push(business.Code)
            }

            setMultiBusinessList(businessList)
          }

          for (const item of data) {
            // console.log(item.Details.Authority.MySQLAccess);
          
            if (item.Details.Authority.MySQLAccess) {
              const access = item.Details.Authority.MySQLAccess[0]
              setExistingMySQLAccess({ username : access._username, password: access._password});
              break; // Stop looping once a value is found
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  // useEffect(() => {
  //   if (Object.keys(userDetails).length === 0) return 
  //   if (Object.keys(firmDetails).length === 0) return;
  //   let isMounted = true;

  //   if (isMounted) {
  //     if (!selectedClientID) {
  //       if (limitFetch === 0) getUserClientLists();
  //     } else if (selectedClientID !== oldSelectedClientId) {
  //       fetchFromSse()
  //     }
  //   }
  // },[])

  // const fetchFromSse = () => {
  //   // console.log('userDetails', userDetails)
  //   // const eventSource = new EventSource(`https://evamobileservices.azurewebsites.net/api/personal-notification?user=${userDetails?.User?.Email}`);
  //   const eventSource = new EventSource(`http://192.168.1.5:4000/api/personal-notification?user=${userDetails?.User?.Email}`);
  //   // eventSource.onopen = (e) => console.log(">>> Connection opened!", e);
  //   // Listen for messages from the server
  //   eventSource.onmessage = (event) => {
  //     // Parse the JSON data from the event
  //     let sseResult = JSON.parse(event.data)
  //     // console.log('personal-notification', sseResult)
  //     if(sseResult.result.length) {
  //       fetchGroupNotification();
  //       // setUnReadAnnouncements({...sseResult.result, ...unReadAnnouncements})
  //     }
  //   };
  
  //   // Handle any errors that occur
  //   eventSource.onerror = (error) => {
  //     console.error('Error with SSE:', error);
  //     // eventSource.close(); // Optionally close the connection on error
  //   };
  
  //   // Cleanup when the component unmounts
  //   return () => {
  //     eventSource.close();
  //   };
  // }

  const loadClientIntegration = () => {
    const serviceMap = {
      "Xero": "XB",
      "Workflow Max Bluerock": "WFM"
    };

    fetch(`/api/clientService/getAllRecords/${selectedClient.id}`)
    .then((res) => res.json())
    .then((data) => {
      if (data && !data.error) {
        const integrations = data
        .filter(item => item.Status != 2)
        .map(item => {
          return {
            integration: serviceMap[item.Service.Name] || item.Service.Name,
            logo: item.Service.Logo,
            createdAt: item.createdAt
          }
        })
      
        setSelectClientIntegration(integrations)
        return integrations
      }else {
        return []
      }

    })
    .catch((err) => {
        console.log(err);
        return []
    });
  }
  const fetchChatNotification = async () => {
    const param = {
      clientId: selectedClientID,
      user: userDetails.User.Email,
    }
    const notificationList = await groupChatNotifications(param)
    setUserChatNotification(notificationList)
  }

  const getUserClientLists = async () => {
    // if (selectedClientID) return
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    setLimitFetch(1)
    await fetch(`/api/client/getAllRecords`)
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          joinClientChannel(data)
          if (!data.length) return history.push(`/client/lists`);
          setUserClients(data);
          setContextClientLists(data)
          const filteredSelectedClient = data.filter(d => d.ClientID === sessionSelectedClientID)

          if (!sessionSelectedClientID || (!filteredSelectedClient || filteredSelectedClient.length === 0)) {
            sessionStorage.setItem("selectedClientID", data[0].ClientID);
            setSelectedClient(data[0])
            setSelectedClientID(data[0].ClientID)
            setOldSelectedClientId(data[0].ClientID)
          } else {
            setSelectedClient(filteredSelectedClient[0])
            setSelectedClientID(filteredSelectedClient[0].ClientID)
            setAdminSelectedClientId(sessionSelectedClientID)
          }

       
        } else {
          setUserClients();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } 

  const joinClientChannel = useCallback(async (data) => {
    if (Object.keys(userDetails).length === 0) return;
   
    // socket.emit("joinCMSActiveUser", userDetails.User.Email);
    data.map((client) => {
      // console.log("joinClientChannel", client.ClientID, client.Name, userDetails.User.Email);
      // socket.emit("joinClientChannel", client.ClientID, client.Name, userDetails.User.Email);
    })
  }, [userDetails])

  useEffect(() => {
    const handleRoomMessage = async (notification) => {
      // console.log("new group chat notification: ")
      // console.log(notification)
      if(notification.data.senderEmail === userDetails.User.Email) return
      if(notification.data.type && notification.data.type === "chat")
      {
        processChatNotification(notification)
      }
    };

    // socket.on("groupChatChannelNotification", handleRoomMessage);
    return () => {
      // socket.off("groupChatChannelNotification", handleRoomMessage);
    };
  }, [userDetails, selectedClientID]);

  useEffect(() => {
    const handleRoomMessage = async (notification) => {
      let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
      console.log(notification)

      if (notification.clientId === sessionSelectedClientID) {

        if (Object.keys(userDetails).length === 0) return;
        if (notification.isReport && notification.email !== userDetails.User.Email) return null

        await fetchGroupNotification()
        console.log('clientChannelNotification this is the trigger')
      }
    };

    // socket.on("clientChannelNotification", handleRoomMessage);
    return () => {
      // socket.off("clientChannelNotification", handleRoomMessage);
    };
  }, [userDetails, selectedClientID]);

  useEffect(() => {
    const handleRoomMessage = async (notification) => {
      if(notification.status === "PUBLISHED")
      {
        await fetchGroupNotification()
      }
    };

    // socket.on("announcementChannelNotification", handleRoomMessage);
    return () => {
      // socket.off("announcementChannelNotification", handleRoomMessage);
    };
  }, [selectedClientID]);

  useEffect(() => {
    const handleRoomMessage = async (notification) => {
        console.log(notification)
        await fetchGroupNotification()
    };

    // socket.on("groupChannelNotification", handleRoomMessage);
    return () => {
      // socket.off("groupChannelNotification", handleRoomMessage);
    };
  }, [selectedClientID]);

  
  const processChatNotification = async (notification) => {
    const notifGroupId = notification.data.groupId
    const currentUrl = window.location.href.split('/');
    const id = currentUrl[currentUrl.length - 1];
    const newNotification = notifGroupId !== id
    setTimeout(async () => {
      if(newNotification)
      {
        console.log("new message from group")
        const param = {
          clientId: notification.data.clientid,
          user: userDetails.User.Email,
          groupId: notification.data.groupId,
        }
        const notificationList = await groupChatNotifications(param)
        setUserChatNotification(notificationList.filter(item => item.groupId !== id))
        // console.log(notificationList.filter(item => item.groupId !== id))
      } else {
        console.log("do nothing!")
        const param = {
          clientId: notification.data.clientid,
          user: userDetails.User.Email,
          groupId: notification.data.groupId,
        }
        const notificationList = await deleteGroupChatNotifications(param)
        setUserChatNotification(notificationList.filter(item => item.groupId !== param.groupId))
        // console.log(notificationList.filter(item => item.groupId !== param.groupId))
      }
    }, 1000);

   
    return
  }

  const fetchGroupNotification = async () => {
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    if (Object.keys(userDetails).length === 0) return;
    const param = {
      'clientId': sessionSelectedClientID,
      'user': userDetails.User.Email
    };

    await getAnnouncements()
    const notification = await groupNotifications(param)
    const unSeenNotification = notification && notification.filter(item => !item.isSeen)
    setNavNotifications(notification)
    // console.log(notification)
    setUnReadAnnouncements(unSeenNotification)
  }

  const setNotification = (notification) => {

    const { clientid, createdAt, text, type } = notification.data
    const { name, _id } = notification.data.user
    const clientName = notification.result[0].name
    const selectedClient = userClients && userClients.filter(client => client.ClientID === clientid)

    const newNotification = {
      clientId: clientid,
      clientName: clientName,
      type: type,
      text: text,
      senderId: _id,
      serderName: name,
      createdAt: createdAt,
      logo: userClients ? selectedClient[0].Logo : ''

    }

    const header = type === "chat" ? `You have new message from ${clientName}` : ''
    const body = type === "chat" ? `${name} sent a message` : ''

    const notificationItem = {
      icon: newNotification.logo,
      type: type,
      header: header,
      body: body,
      isSeen: false,
    }
    setUnReadAnnouncements(prev => {
      // Ensure prev is an array, if not, initialize it as an empty array
      const prevArray = Array.isArray(prev) ? prev : [];
      // Add notificationItem to prevArray
      return [...prevArray, notificationItem];
    });

    // console.log("newNotification: ", notificationItem)
  }



  const getUserGroup = async () => {
    if (Object.keys(userDetails).length === 0) return;

    const param = {
      'ClientId': selectedClientID,
      'email': userDetails.User.Email ? userDetails.User.Email : ''
    };

    const groups = await getCustomGroups(param);

    groups.map((group) => {
      // console.log("joinGroupChannel", group._id, group.GroupName, userDetails.User.Email);
      // socket.emit("joinGroupChannel", group._id, group.GroupName, userDetails.User.Email);
    })


    if (selectedClient) {
      // console.log("joinGroupChannel", selectedClient.ClientID, selectedClient.Name, userDetails.User.Email);
      // socket.emit("joinGroupChannel", selectedClient.ClientID, selectedClient.Name, userDetails.User.Email);
    }
  }

  const getAnnouncements = async () => 
  {
    const announcements = await loadAnnouncements()
    const announementsRead = await announcementReadStatus()
    
    // console.log(announcements)
    // console.log(announementsRead.length > 0)

    // if (announcements.length > 0) setUnReadAnnouncements(announcements)
    // if (announementsRead.length > 0) setReadAnnouncements(announementsRead)

    const SEND_MODES = {
      NEW_BUSINESS: 'newFirms',
      NEW_CLIENTS: 'newClients', 
      NEW_ADVISERS: 'newAccountants',
      NEW_CLIENT_USERS: 'newClientUsers',
      SELECTED_ADVISERS: 'specificAccountants',
      SELECTED_CLIENTS: 'specificClients',
    }
    const clientIds = firmDetails?.Client?.map(client => client.id);

    const generalAnnouncements = announcements.filter(announcement => 
      (announcement.target_audience?.users?.all &&
      announcement.target_audience?.business?.all &&
      announcement.target_audience?.advisors?.all &&
      announcement.target_audience?.clients?.all) &&
      announcement.status ==='PUBLISHED'
    );

    let businessdAnnouncements = [];
    let newBusinessdAnnouncements = [];
    let newAdvisorAnnouncements = [];
    let advisorAnnouncements = [];
    let clientUserAnnouncements = [];
    let allAdviserAnnouncements = [];
    let allClientUserAnnouncements = [];
    let selectedUserAnnouncements = [];
    let selectClientAnnouncements = [];
    let newClientUserAnnouncements = [];
    let unreadAnnouncements = [];
    let mergedAnnouncements = [
      ...generalAnnouncements,
    ];

    const isAdvisorUser = (userAccess) => [1,2,3,4].includes(userAccess);

    const isClientUser = (userAccess) => [5,6].includes(userAccess);

    if (userDetails) {
      const { AccountingFirm, UserAccess, id } = userDetails.User;

      // Filter Announcements by Accounting Firm
      businessdAnnouncements = announcements.filter(announcement => 
        announcement.target_audience?.business?.specific_ids?.includes(AccountingFirm) &&
        announcement.status === 'PUBLISHED'
      );

      // Filter Announcement with target Business and is flag as NewBusiness
      newBusinessdAnnouncements = announcements.filter(announcement => 
        announcement.send_mode === SEND_MODES.NEW_BUSINESS &&
        !announcement.target_audience?.business?.all &&
        announcement.target_audience?.business?.new_business &&
        announcement.target_audience?.business?.specific_ids?.includes(AccountingFirm) &&
        announcement.status ==='PUBLISHED'
      );

      // Filter all Adviser only announcements
      allAdviserAnnouncements = announcements.filter(announcement =>
        announcement.target_audience?.advisors?.all &&
        !announcement.target_audience?.users?.all &&
        isAdvisorUser(UserAccess) &&
        announcement.status ==='PUBLISHED'        
      );

      // Filter Announcements by Selected Advisor Users
      advisorAnnouncements = announcements.filter(announcement =>
        !announcement.target_audience?.advisors?.all &&
        announcement.send_mode === SEND_MODES.SELECTED_ADVISERS &&        
        announcement.target_audience?.advisors?.specific_ids?.includes(id) &&
        isAdvisorUser(UserAccess) &&
        announcement.status ==='PUBLISHED'
      );
      
      // Filter Announcments by New Advisor Users
      newAdvisorAnnouncements = announcements.filter(announcement =>
        announcement.send_mode === SEND_MODES.NEW_ADVISERS &&
        !announcement.target_audience?.advisors?.all &&
        announcement.target_audience?.advisors?.new_advisers &&
        announcement.target_audience?.advisors?.specific_ids?.includes(id) &&
        isAdvisorUser(UserAccess) &&
        announcement.status ==='PUBLISHED'        
      );


      // Filter All Client User Announcements
      allClientUserAnnouncements = announcements.filter(announcement =>
        announcement.target_audience?.clients?.all &&
        !announcement.target_audience?.users?.all &&
        isClientUser(UserAccess) &&
        announcement.status ==='PUBLISHED'
      );      

      // Filter Announcements by Selected Client
      selectClientAnnouncements = announcements.filter(announcement =>
        !announcement.target_audience?.clients?.all &&
        announcement.send_mode === SEND_MODES.SELECTED_CLIENTS &&
        announcement.target_audience?.clients?.specific_ids &&
        announcement.target_audience.clients.specific_ids.some(client => clientIds?.includes(client)) &&
        announcement.status === 'PUBLISHED'
      );


      newClientUserAnnouncements = announcements.filter(announcement =>
        announcement.send_mode === SEND_MODES.NEW_CLIENT_USERS &&
        !announcement.target_audience?.clients?.all &&
        announcement.target_audience?.clients?.new_client_users &&
        announcement.target_audience?.clients?.specific_ids?.includes(id) &&
        isClientUser(UserAccess) &&
        announcement.status ==='PUBLISHED'
      );
    
      // Filter Announcements by Selected Users
      selectedUserAnnouncements = announcements.filter(announcement =>
        announcement.target_audience?.users?.specific_ids?.includes(id) && 
        announcement.status ==='PUBLISHED'
      );

      // All Announcements
      mergedAnnouncements = [
        ...mergedAnnouncements,
        ...businessdAnnouncements,
        ...allAdviserAnnouncements,
        ...allClientUserAnnouncements, 
        ...advisorAnnouncements,
        ...clientUserAnnouncements,
        ...selectedUserAnnouncements,
        ...newBusinessdAnnouncements,
        ...selectClientAnnouncements,
        ...newAdvisorAnnouncements,
        ...newClientUserAnnouncements
      ];

      // Set Unread Announcements
      unreadAnnouncements = mergedAnnouncements.map(announcement => {
          const isRead = readAnnouncements && readAnnouncements.some(readAnnouncement =>
              readAnnouncement.announcement_id.toString() === announcement._id.toString() &&
              readAnnouncement.user_id.toString() === id.toString()
          );

          return {
              ...announcement,
              isRead: isRead
          };
      }).filter(announcement => !announcement.isRead);

      // Add isRead to Announcements
      mergedAnnouncements = mergedAnnouncements.map(announcement => {
          const isRead = readAnnouncements && readAnnouncements.some(readAnnouncement =>
              readAnnouncement.announcement_id.toString() === announcement._id.toString() &&
              readAnnouncement.user_id.toString() === id.toString()
          );

          return {
              ...announcement,
              isRead: isRead
          };
      });

      // Sort by Unread first
      // mergedAnnouncements.sort((a,b) => a.isRead - b.isRead)
    }

        // Remove duplicates after merged
        const uniqueAnnouncements = new Set();
        mergedAnnouncements = mergedAnnouncements.filter(announcement => {
          if (uniqueAnnouncements.has(announcement._id)) {
            return false;
          } else {
            uniqueAnnouncements.add(announcement._id);
            return true;
          }
        });
        const uniqueUnReadAnnouncements = new Set();
        unreadAnnouncements = unreadAnnouncements.filter(announcement => {
          if (uniqueUnReadAnnouncements.has(announcement._id)) {
            return false;
          } else {
            uniqueUnReadAnnouncements.add(announcement._id);
            return true;
          }
        });
    

        // setUnReadAnnouncements(unreadAnnouncements);
        setFilteredAnnouncements(mergedAnnouncements);

        if(unreadAnnouncements.length > 0)
          unreadAnnouncements.map(async (item) => {
            const sender = `${item.posted_by.FirstName} ${item.posted_by.LastName}`
            const notifications = {
              uid: item._id,
              id: selectedClientID,
              clientId: selectedClientID,
              message: `${sender} posted new Announcement`,
              createdAt: item.updatedAt,
              sender: sender,
              isReport: false,
              email: userDetails.User.Email,
              user: userDetails.User.Email,
              url: `/client/announcement/${item._id}`,
              groupId: selectedClientID,
            };
            // console.log(notifications)
            registerGroupNotification(notifications)
          })


        // console.log("unreadAnnouncements: ", unreadAnnouncements.length)
        // console.log(mergedAnnouncements)
    
        // Sort by UpdatedAt
        mergedAnnouncements.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      return unreadAnnouncements.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.path === "/accounting-firm/details" &&
        userDetails?.User?.UserAccess === 4 &&
        userDetails?.User?.Details?.Authority?.PracticeManagerAccess === false
      ) {
        return null;
      }
      if (
        prop.path === "/accounting-firm/details" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (
        prop.path === "/adviser-lists" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (prop.path === "/lists" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (prop.path === "/users" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (prop.path === "/advisers" && userDetails?.User?.UserAccess >= 5) {
        return null;
      }
      if (
        prop.path === "/thirdpartyapplication" &&
        userDetails?.User?.UserAccess >= 6
      ) {
        return null;
      }
      if (
        prop.path === "/traningvideos" &&
        userDetails?.User?.UserAccess >= 5
      ) {
        return null;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/client") {
        return (
          <Route
            path={prop.isActive ? prop.layout + prop.path : "#"}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };
  const LoadingScreen = () => {
    return (
      <div className="loading-container-blk">
        <div className="loading-container-blk-content">
          <img src={require("assets/img/etani-logo-cms.png").default} />
          <p>LOADING</p>
        </div>
      </div>
    );
  };

  return (

    <>
      {/* <MainSidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/client/accounting-firm/details",
          //imgSrc: require("assets/img/brand/XYZ-Icon.jpg").default,
          imgSrc: "/api/accountingFirm/getThemeLogo",
          imgAlt: "...",
        }}
        firmCode={firmDetails.Code}
        userClients={userClients}
        selectedUserClient={selectedUserClient}
      /> */}
      <div className="main-content" ref={mainContentRef}>
        {Object.keys(userDetails).length === 0 && Object.keys(firmDetails).length === 0 ?
          <LoadingScreen /> :
          <>
            <MainNavbar
              theme={getNavbarTheme()}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText(location.pathname)}
              color={color}
              setSelectedUserClient={(data) => setSelectedUserClient(data)}
              logo={{
                innerLink: "/client/accounting-firm/details",
                //imgSrc: require("assets/img/brand/XYZ-Icon.jpg").default,
                imgSrc: "/api/accountingFirm/getThemeLogo",
                imgAlt: "...",
              }}
              firmCode={firmDetails.Code}
              userClients={userClients}
              selectedUserClient={selectedUserClient}
            />
            <Switch>
              {getRoutes(routes)}
              <Route path={`/client/course/:courseID`} component={CourseContent} />
              <Route
                path={`/client/announcement/:announcementID`}
                component={AnnouncementDetails}
              />
              {/* <Route 
                path="/client/auth/multifactor" 
                // render={() => <MultifactorAuth />} 
                component={MultifactorAuth}
              /> */}
              <Route path={`/client/signclient/:clientID`} component={SignClient} />
              <Route path={`/client/plans`} component={Pricing} />
              <Redirect from="*" to="/client/accountant/details" />
            </Switch>
            <MainFooter color={bgColor} />
          </>
        }


      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
