import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomHeader from "customComponents/customHeader";
import { Container } from "reactstrap";
import PanelCard from "components/Cards/PanelCard";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import CustomSpinner from "components/Misc/CustomSpinner";
import moment from "moment";
import { getAnnouncmentId } from "utils/utilityFunctions";
import { useSocket, useSocketEvent, useRoom } from "service/SocketContext";
import { MainContext } from "context/mainContext";
import { useLocation, useHistory } from "react-router-dom";

const AnnouncementDetails = () => {
  const [announcementDetails, setAnnouncementDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { userDetails, filteredAnnouncements } = useContext(MainContext);
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {      
      if(filteredAnnouncements && filteredAnnouncements.length > 0)
      {
        const announcementId = getAnnouncmentId();
        const details = filteredAnnouncements.filter(item => item._id === announcementId)
        setAnnouncementDetails(details[0])
      }

      setIsLoading(false);
    }
    return () => { isMounted = false };
  }, [userDetails, filteredAnnouncements, location]);

  return (
    <>
      <CustomHeader style={null} />
      <Container className="mt-6" fluid>
        <div className="nav-wrapper">
          <PanelCard
            headerName={`${
              announcementDetails ? `${announcementDetails.title}` : ""
            }`}
          >
            {isLoading ? (
              <CustomSpinner />
            ) : (
              announcementDetails &&
              <>
                <div className="announcement-content">
                  {announcementDetails ? parse(announcementDetails.contents) : ""}
                </div>
                <hr />
                <div className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <img
                      alt="..."
                      className="avatar rounded"
                      src={
                        announcementDetails.posted_by?.Avatar
                          ? announcementDetails.posted_by?.Avatar
                          : require("assets/img/etanilogo.png").default
                      }
                    />
                  </div>
                  <div>
                    <h4 className="mb-0 text-sm text-muted">
                      {`Posted By: `}
                      <strong className="text-sm mb-0 text-muted">
                        {announcementDetails.posted_by.FirstName}{" "}
                        {announcementDetails.posted_by.LastName}
                      </strong>
                    </h4>
                    <small className="text-muted">
                      {moment(announcementDetails.updatedAt)
                        .local()
                        .format("LLL")}
                    </small>
                  </div>
                </div>
              </>
            )}
          </PanelCard>
        </div>
      </Container>
    </>
  );
};

export default AnnouncementDetails;
