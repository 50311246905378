import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Chat from './Chat'
import { generateUUID } from 'services/EVAResponseService'
import { jwtDecode } from 'jwt-decode'
import Loading from './ui/loading'
import { evaThemeLighter } from 'services/EVAResponseService'


const EVA = ({ token, onHideHeader, hideHeader }) => {
  const [selectedUUID, setSelectedUUID] = useState('')
  const [isOpenTab, setIsOpenTab] = useState(false)
  const [decodedToken, setDecodedToken] = useState(null);
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState(false);
  const [isEVAPage, setIsEVAPage] = useState(true)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const generateUuidAndDecode = async () => {
      const decoded = jwtDecode(JSON.stringify(token));
      setDecodedToken(decoded);
      setTheme(decoded.business.theme);

      const uuid = await generateUUID();
      setSelectedUUID(uuid);
    };
    
    generateUuidAndDecode();
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectUUID = (uuid) => {
    setSelectedUUID(uuid);
  }
  const handleOpenTab = () => {
    setIsOpenTab(!isOpenTab)
  }
  const handleIsLoading = (val) => {
    setIsLoading(!isLoading)
  }
  const handleSetAlert = (val) => {
    setAlert(val)
  }
  const handleNewUpdate = () => {
  }
  return (
    <>
      <div className='home' style={{ height: screenHeight - 105 }}>
        <div className="evaContainer">
          {alert}
          {/* {isLoading && <Loading />} */}
          {decodedToken && theme && (
            <>
              {isOpenTab && <Sidebar
                onSelectUUID={handleSelectUUID}
                selectedUUID={selectedUUID}
                userData={decodedToken}
                theme={theme}
                isOpenTab={isOpenTab}
                onIsLoading={handleIsLoading}
                onSetAlert={handleSetAlert}
                handleOpenTab={handleOpenTab}
              />}
              <Chat
                handleOpenTab={handleOpenTab}
                onSelectUUID={handleSelectUUID}
                selectedUUID={selectedUUID}
                userData={decodedToken}
                theme={theme}
                onIsLoading={handleIsLoading}
                handleNewUpdate={handleNewUpdate}
                isEVAPage={isEVAPage}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EVA