import CustomSpinner from 'components/Misc/CustomSpinner';
import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { Card, CardImg, CardBody, CardHeader, Container, Row, Col, Button } from "reactstrap";
import LoadingPage from './LoadingPage';
import { useHistory } from "react-router";

const ConfirmingEmail = ({ ...props }) => {
  const [success, setSuccess] = useState(false)
  const routerHistory = useHistory();
  const [message, setMessage] = useState("")
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("access_token");
    const bname = urlParams.get("bname");
    fetch(
      `/api/trialUser/confirmEmail?access_token=${token}&bname=${bname}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        // console.log(data)
        if (data && data.success) {
          setSuccess(true)
          if (data.userExist) {
            return routerHistory.push(
              `/auth/login`
            );
          }
          if (data.multiFirm) {
            return routerHistory.push(
              `/trialUser/confirm-mba-email-success?access_token=${data.token}&bname=${data.bname}`
            );
          } else {
            return routerHistory.push(
              `/trialUser/confirm-email-success?access_token=${data.token}&bname=${data.bname}`
            );
          }
        }
        if (data.error) {
          setHasError(true)
          setMessage(data.error)
        }
      })
  }, [])

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="8"
              sm="12"
              xs="12"
              className={`mt-2 px-0 px-lg-3`}
            >
              <Card className=" bg-transparent shadow-none p-3 mb-0 py-3 px-lg-5 mx-2 ">
                <CardImg
                  alt="Login"
                  src={"/api/accountingFirm/getThemeLogo"}
                  style={{ width: "150px" }}
                  className=" justify-content-center text-center rounded mx-auto"
                  top
                />
                <CardHeader className="px-0 pb-0 bg-transparent">
                  <h1 className="h2 text-center text-normal">
                    Confirming Email
                  </h1>
                </CardHeader>
                <CardBody className="pt-2 p-0">
                  <p>
                    Please wait and don't close or refresh browser while we process your account.
                  </p>
                  <div className='justify-content-center text-center'>
                    {
                      success ? "Account successfully created. Redirecting."
                        : hasError ? message
                          : <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ConfirmingEmail