import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Input,
  Modal
} from "reactstrap";
import ReactPlayer from "react-player";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import { CustomButton } from "components/Button/CustomButton";
import { dataFilterStaffNameOnly } from "utils/utilityFunctions";
import StaffHistoricRatesModal from "components/Modal/StaffHistoricRatesModal";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ClipLoader } from "react-spinners";
import { CustomToggleField } from "customComponents/Form";
import CsvStaffHistoricRatesModal from '../../views/pages/clientThirdPartyApplications/CSVStaffRateModal';
import CustomTooltip from "customComponents/customComponents";
import CustomTaskRateModal from "components/Modal/CustomTaskRateModal";
import HistoricCapacityModal from "components/Modal/HistoricCapacityModal";


const ThirdPartyAppStaffTable = ({
  data,
  warningAlert,
  userDetails,
  dbDetails,
  selectedData,
  ...props
}) => {
  const { setSelectedClientID, bgColor, textColor } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);
  const [editedPaginatedData, setEditedPaginatedData] = useState([]);
  const [sort, setSort] = useState({ field: 'age', direction: 'asc' });
  const [editedRows, setEditedRows] = useState({});
  const [filteredLabel, setFilteredLabel] = useState("Active Only");
  const [dataToFilter, setDataToFilter] = useState([]);
  const [showStaffHistoricRates, setShowStaffHistoricRates] = useState(false)
  const [showCustomTaskRate, setShowCustomTaskRate] = useState(false)
  const [showHistoricCapcity, setShowHistoricCapacity] = useState(false)
  const [selectedCustomTaskRates, setSelectedCustomTaskRates] = useState([])
  const [selectedHistoricCapacity, setSelectedHistoricCapacity] = useState([])
  const [selectedStaffHistoricRates, setSelectedStaffHistoricRates] = useState([])
  const [selectedStaff, setSelectedStaff] = useState([])
  const [staffListCount, setStaffListCount] = useState(null)
  const [showBlockUI, setShowBlockUI] = useState(true);
  const [filter, setFilter] = useState(true);
  const [alert, setalert] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [showStaffRateUpload, setShowStaffRateUpload] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    fetchStaffList();
  }, [currentPage, numberPerPage, filter]);


  const fetchStaffList = async () => {
    if (loadingData) return;
    setShowBlockUI(true);
    setLoadingData(true);
    const { dbName, tbName, orgId, dw, sTbName } = dbDetails;

    // console.log(dbDetails)

    const itemsPerPage = localStorage.getItem("clientListNumberPerPage") ?? 5;
    setNumberPerPage(itemsPerPage);

    let fetchUrl = `/api/clientService/getStaffList?databaseName=${dbName}&dw=${dw}&tableName=${tbName}&sTbName=${sTbName}&orgID=${orgId}&activeOnly=${filter}&page=${currentPage + 1}&itemsPerPage=${itemsPerPage}`;
    if (searchTerm && searchTerm.length) {
      fetchUrl = `/api/clientService/getStaffList?databaseName=${dbName}&dw=${dw}&tableName=${tbName}&sTbName=${sTbName}&orgID=${orgId}&activeOnly=${filter}&page=${currentPage + 1}&itemsPerPage=${itemsPerPage}&search=${searchTerm}`;
    }

    fetch(fetchUrl, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response.error) {
          setPaginatedData([])
          setLoadingData(false);
          return setShowBlockUI(false)
        }

        const myData = response.data

        const editedPaginatedDataMap = new Map(
          editedPaginatedData.map((item) => [item.StaffID, item])
        );

        // Update myData with rows from editedPaginatedData if StaffID matches
        const updatedMyData = myData.map((item) => {
          const staffID = item.StaffID;
          if (editedPaginatedDataMap.has(staffID)) {
            return editedPaginatedDataMap.get(staffID);
          }
          return item; // No match found, keep the original item
        });

        setPaginatedData(updatedMyData);
        setStaffListCount(response.totalRows);
        setShowBlockUI(false);
        setLoadingData(false);
      })
      .catch((err) => {
        errorAlert("Something went wrong. Please try again later.");
        console.log(err);
        setShowBlockUI(false)
        setLoadingData(false);
      });
  }

  const StaffHistoricRatesClickHandler = (StaffHistoricRates) => {
    // console.log(StaffHistoricRates)
    setSelectedStaffHistoricRates(StaffHistoricRates)
    setShowStaffHistoricRates(true)
  }
  const handlesetShowStaffHistoricRates = (val) => {
    setShowStaffHistoricRates(val)
  }
  const selectStaffHistoricRatesClickHandler = (Staff) => {
    // console.log(Staff)
    setSelectedStaff(Staff)
  }

  const CustomTaskRateClickHandler = (customTaskRate) => {
    setShowCustomTaskRate(true)
    // console.log(customTaskRate)
    setSelectedCustomTaskRates(customTaskRate)
  }
  const handlesetShowCustomTaskRate = (val) => {
    setShowCustomTaskRate(val)
  }
  const selectedStaffCutomTaskRate = (Staff) => {
    // console.log(Staff)
    setSelectedStaff(Staff)
  }

  const HistoricCapacityHandler = (historicCapacity) => {
    setShowHistoricCapacity(true)
    setSelectedHistoricCapacity(historicCapacity)
  }

  const handleSetShowHistoricCapacity = (val) => {
    setShowHistoricCapacity(val)
  }

  const selectedStaffHistoricCapacity = (staff) => {
    setSelectedStaff(staff)
  }



  const StaffHistoricRatesLastest = (StaffHistoricRates) => {
    try {
      if (StaffHistoricRates && StaffHistoricRates.length) {
        const rates = JSON.parse(StaffHistoricRates);
        rates.sort((a, b) => new Date(b.date) - new Date(a.date));
        return rates[0].rate;
      } else {
        return '0';
      }
    } catch (error) {
      return 0;
    }
  }
  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const showStaffRateModal = () => setShowStaffRateUpload(!showStaffRateUpload);


  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  const currentPageData = () => {
    if (paginatedData.length) {
      return paginatedData
        .map((data, i) => {
          return (
            <tr key={i} style={{ cursor: "pointer" }}>
              {/* <td className="staffId" >
                {data.id}
              </td>             */}
              <td className="staffId" >
                {data.StaffID ? data.StaffID : data.id}
              </td>
              <td className="staffName">
                {data.StaffName ? data.StaffName : ""}
              </td>
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Mon")
                }}
                value={data.Mon}
                isTime={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Tue")
                }}
                value={data.Tue}
                isTime={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Wed")
                }}
                value={data.Wed}
                isTime={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Thu")
                }}
                value={data.Thu}
                isTime={true}
              />

              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Fri")
                }}
                value={data.Fri}
                isTime={true}
              />
              {/* <td className="admin-email">
                {data.Total}
              </td> */}
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Total")
                }}
                value={data.Total}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Percent")
                }}
                value={data.Percent}
                isPercentage={true}
                isNumberOnly={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Team")
                }}
                value={data.Team}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "BillRate")
                }}
                value={data.BillRate}
                isNumberOnly={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "BillableRate")
                }}
                value={data.BillableRate}
                isNumberOnly={true}
              />
              {/* <td className="admin-email">
                {data.FulltimeEquivPercent}
              </td> */}
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "FulltimeEquivPercent")
                }}
                value={data.FulltimeEquivPercent}
                isPercentage={true}
                isNumberOnly={true}
              />
              <EditableColumn
                onValueChange={(e) => {
                  handleStaffDetailsInputChange(e, data, "Notes")
                }}
                value={data.Notes}
              />

              <td className="admin-email">
                <CustomToggleField
                  onChange={(e) => {
                    handleStaffDetailsInputChange(e, data)
                  }}
                  key={data.StaffID ? data.StaffID : data.id}
                  type="checkbox"
                  id={`Working-${data.StaffID ? data.StaffID : data.id}`}
                  name="Working"
                  defaultChecked={data.Working ? true : false}
                  defaultValue={data.Working ? true : false}
                />
              </td>
              <td className="admin-email">
                <CustomToggleField
                  onChange={(e) => {
                    handleStaffDetailsInputChange(e, data)
                  }}
                  key={data.StaffID ? data.StaffID : data.id}
                  type="checkbox"
                  id={`DirectorPartner-${data.StaffID ? data.StaffID : data.id}`}
                  name="DirectorPartner"
                  defaultChecked={data.DirectorPartner ? true : false}
                  defaultValue={data.DirectorPartner ? true : false}
                />
              </td>
              <td className="admin-email">
                <CustomToggleField
                  onChange={(e) => {
                    handleStaffDetailsInputChange(e, data)
                  }}
                  key={data.StaffID ? data.StaffID : data.id}
                  type="checkbox"
                  id={`Manager-${data.StaffID ? data.StaffID : data.id}`}
                  name="Manager"
                  defaultChecked={data.Manager ? true : false}
                  defaultValue={data.Manager ? true : false}
                />
              </td>
              <td className="admin-email">
                <CustomToggleField
                  onChange={(e) => {
                    handleStaffDetailsInputChange(e, data)
                  }}
                  key={data.StaffID ? data.StaffID : data.id}
                  type="checkbox"
                  id={`FeeEarner-${data.StaffID ? data.StaffID : data.id}`}
                  name="FeeEarner"
                  defaultChecked={data.FeeEarner ? true : false}
                  defaultValue={data.FeeEarner ? true : false}
                />
              </td>
              <td onClick={() => {
                StaffHistoricRatesClickHandler(data.StaffHistoricRates)
                selectStaffHistoricRatesClickHandler(data)
              }}>
                {StaffHistoricRatesLastest(data.StaffHistoricRates)}
              </td>
              {
                selectedData?.Details?.dbDestination === "DW" ?
                  <>
                    <td onClick={() => {
                      CustomTaskRateClickHandler(data.CustomTaskRate)
                      selectedStaffCutomTaskRate(data)
                    }}>
                      Click to view rates
                    </td>
                    <td onClick={() => {
                      HistoricCapacityHandler(data.HistoricCapacity)
                      selectedStaffHistoricCapacity(data)
                    }}>
                      Click to view historic capacity
                    </td>
                  </>
                  : null
              }
              {/* {
                dbDetails?.tbName === `BIGOLESHOECOMPANY_CLIENT1518_XEROPRACTICEMANAGER_CAPACITYDEV` ?
                  : null
              } */}

              {/* <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    size="sm"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={() => props.openStaffDetailsModal(data)}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                    // onClick={() =>
                    //   warningAlert(
                    //     "deleteClient",
                    //     `Are youre you want to delete ${ann.Name} Client?`,
                    //     data
                    //   )
                    // }
                    >
                      Open
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td> */}
            </tr>
          );
        });
    } else {
      return 'No data found here';
    }
  };

  const pageCount = () => {
    return Math.ceil(staffListCount / numberPerPage);
  };

  const changeUrl = (page, clientID) => {
    if (page === "users") {
      setSelectedClientID(clientID);
      return history.push(`/client/users/${clientID}`);
    }
    if (page === "advisers") {
      setSelectedClientID(clientID);
      return history.push(`/client/advisers/${clientID}`);
    }
    if (page === "dashboard") {
      setSelectedClientID(clientID);
      return history.push(`/client/dashboard/${clientID}`);
    }
    if (page === "reports") {
      setSelectedClientID(clientID);
      return history.push(`/client/reports/${clientID}`);
    }
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
    if (page === "signclient") {
      setSelectedClientID(clientID);
      return history.push(`/client/signclient/${clientID}`);
    }
  };

  const sortedData = [...paginatedData].sort((a, b) => {
    if (sort.direction === 'asc') {
      return a[sort.field] > b[sort.field] ? 1 : -1;
    } else {
      return a[sort.field] < b[sort.field] ? 1 : -1;
    }
  });


  function EditableColumn({ value, onValueChange, isPercentage = false, isNumberOnly = false, isTime = false }) {
    const [isEditing, setIsEditing] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const handleInputChange = (event) => {
      let newValue = event.target.value;


      if (isTime) {
        newValue = newValue.replace(/[^0-9:]/g, '');
      } else {


        if (isNumberOnly) {
          newValue = newValue.replace(/[^0-9.]/g, '');
          const [integerPart, decimalPart] = newValue.split('.');
          if (integerPart) {
            newValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }

          if (decimalPart) {
            newValue = `${newValue}.${decimalPart}`;
          }

          newValue = newValue.replace(/[^0-9.]/g, '');
        }

        if (isPercentage && newValue > 100) {
          newValue = '100';
        }
      }


      setLocalValue(newValue);
    };

    const handleSave = () => {
      setIsEditing(false);
      onValueChange(localValue);
    };

    return (
      <td onClick={() => !isEditing && setIsEditing(true)}>
        {isEditing ? (
          <input
            type="text"
            value={localValue}
            onChange={handleInputChange}
            onBlur={handleSave}
          />
        ) : (
          isPercentage ? value + "%" : value
        )}
      </td>
    );
  }


  const saveEdits = () => {
    setShowBlockUI(true);
    // console.log("Saving edited rows:", editedRows);
    // console.log("paginatedData", paginatedData);
    let newPaginatedData = [...paginatedData];
    let editedRowsToBeSaved = []
    newPaginatedData.map((row, i) => {
      if (editedRows[row.StaffID]) {
        console.log(`updatedValues-${i}`, { ...row, ...editedRows[row.StaffID] })
        editedRowsToBeSaved.push({ ...row, ...editedRows[row.StaffID] })

        return newPaginatedData.splice(i, 1, { ...row, ...editedRows[row.StaffID] });
        return { ...row, ...editedRows[row.StaffID] };
      }
      return row;
    });
    // console.log('newPaginatedData', newPaginatedData);
    // console.log('editedRowsToBeSaved', editedRowsToBeSaved);
    setPaginatedData(newPaginatedData)

    // console.log(dbDetails)
    fetch(`/api/clientService/batchUpdateStaffDetails?dw=${dbDetails.dw}`, {
      method: "PUT",
      body: JSON.stringify({
        db: dbDetails,
        data: editedRowsToBeSaved
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        successAlert("Record has been updated successfully");
        setShowBlockUI(false);
        setEditPaginatedDataHandler([])
        props.setwithEditedDataHandler([])
      })
      .catch(e => {
        errorAlert(e.error)
        setShowBlockUI(false);
        props.setwithEditedDataHandler([])
        console.log(e)
      })
    // setPaginatedData(prevData => {
    //   console.log("prevData", prevData);
    //   return prevData.map(row => {
    //     console.log({ ...editedRows[row.StaffID] })
    //     if (editedRows[row.StaffID]) {
    //       console.log('updatedValues', { ...row, ...editedRows[row.StaffID] })
    //       return { ...row, ...editedRows[row.StaffID] };
    //     }
    //     return row;
    //   });
    // });
    // Here, make an API call or any other action to save the edited data.
  };

  const filteredByActiveOrNot = (value) => {
    // console.log('filteredByActiveOrNot', selectedData)
    props.openStaffList(selectedData, value)
    setFilter(value);
    if (value) {
      setFilteredLabel("Active Only")
    } else {
      setFilteredLabel("All")
    }
  }
  const successAlert = (message) => {
    setalert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };
  const errorAlert = (message) => {
    setalert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(0);
      setNumberPerPage(5);
      await fetchStaffList();
    }
  }

  const handleSearchTerm = (e) => {
    let filterString = e.target.value;
    setSearchTerm(filterString);
  }

  const handleStaffDetailsInputChange = (event, data, objName) => {
    const target = event.target
    const value = target ? target.type === 'checkbox' ? target.checked : target.value : event
    const name = target ? target.name : objName

    const updatedRow = { ...data, [name]: value };
    setEditedRows((prev) => ({
      ...prev,
      [data.StaffID]: {
        ...prev[data.StaffID],
        [name]: value
      }
    }));

    setPaginatedData((prev) =>
      prev.map((item) => (item.StaffID === data.StaffID ? updatedRow : item))
    );
    setEditPaginatedDataHandler(updatedRow)
  }

  const setEditPaginatedDataHandler = (updatedRow) => {
    const updatedPaginatedData = [...editedPaginatedData.filter((item) => item.StaffID !== updatedRow.StaffID), updatedRow];
    setEditedPaginatedData(updatedPaginatedData);
    props.setwithEditedDataHandler(updatedPaginatedData)
  };
  return (
    <>
      {alert}
      <Card style={{ minHeight: '590px' }}>
        {showStaffHistoricRates ?
          <StaffHistoricRatesModal
            setShowBlockUI={props.setShowBlockUI}
            handlesetShowStaffHistoricRates={handlesetShowStaffHistoricRates}
            selectedStaff={selectedStaff}
            StaffHistoricRates={selectedStaffHistoricRates}
            dbDetails={dbDetails}
            fetchStaffList={fetchStaffList}
          /> : null}

        {showCustomTaskRate ?
          <CustomTaskRateModal
            handlesetShowCustomTaskRate={handlesetShowCustomTaskRate}
            dbDetails={dbDetails}
            CustomTaskRates={selectedCustomTaskRates}
            bgColor={bgColor}
            textColor={textColor}
            selectedStaff={selectedStaff}
            fetchStaffList={fetchStaffList}
          />
          : null
        }

        {showHistoricCapcity ?
          <HistoricCapacityModal
            handleSetShowHistoricCapacity={handleSetShowHistoricCapacity}
            dbDetails={dbDetails}
            historicCapacity={selectedHistoricCapacity}
            bgColor={bgColor}
            textColor={textColor}
            selectedStaff={selectedStaff}
            fetchStaffList={fetchStaffList}
          />
          : null
        }

        {showStaffRateUpload ?
          <CsvStaffHistoricRatesModal
            showStaffRateModal={showStaffRateModal}
            dbDetails={dbDetails}
            selectedClient={props.selectedClient}
            firmDetails={props.firmDetails}
            userDetails={userDetails}
            setShowStaffRateUpload={setShowStaffRateUpload}
            setShowBlockUI={props.setShowBlockUI}
            loadingData={loadingData}
            selectedData={selectedData}
            closeStaffListModal={props.closeStaffListModal}
            handleUpdateRecord={props.handleUpdateRecord}
          /> : null
        }

        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
          <Row>
            <Col lg="6">
              {/* <button onClick={saveEdits}>Save All Edits</button> */}
              <CustomButton
                onClick={() => saveEdits()}
                type="button"
                outlineStyle={false}
                title="Save All Edits"
                size={'sm'}
                disabled={loadingData === true ? true : false}
              />
            </Col>
            <Col lg="6">
              <Row>
                {/* <Col lg="lg-4 col-lg-4 d-flex justify-content-end" style={{ height: '33px' }}>
                  <CustomButton
                    onClick={() => showStaffRateModal()}
                    type="button"
                    outlineStyle={false}
                    title="StaffRate Upload"
                    size={'sm'}
                    showStaffRateModal={() => showStaffRateModal}
                    disabled={loadingData === true ? true : false}
                  />
                  <CustomTooltip
                    i="1"
                    className="fas fa-play-circle"
                    message="Play Tutorial"
                    onClick={() => setShowTutorial(!showTutorial)}
                    leftdiv={'text-danger'}
                  />
                </Col> */}
                <Col lg="6">
                  <div className="float-right">
                    <UncontrolledDropdown
                      className="me-2"
                      direction="end"
                      disabled={loadingData === true ? true : false}
                    >
                      <DropdownToggle
                        caret
                        size='sm'
                        style={{
                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          color: `${textColor === "#000" ? "#fff" : textColor}`,
                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          paddingTop: '6px', paddingBottom: '6px'
                        }}
                        disabled={loadingData === true ? true : false}
                      >
                        Filtered by: {filteredLabel}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => filteredByActiveOrNot(true)}>
                          Active Only
                        </DropdownItem>
                        <DropdownItem onClick={() => filteredByActiveOrNot(false)}>
                          All
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Input
                      placeholder="Press Enter key to Search"
                      type="text"
                      bsSize="sm"
                      onKeyDown={handleSearch}
                      onChange={handleSearchTerm}
                      disabled={loadingData === true ? true : false}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (

          <div className="table-container">
            <Table className="align-items-center table-flush" responsive>
              {showBlockUI ? <div className="tableLoading">
                <ClipLoader color={bgColor} size={50} margin={2} />
              </div> : null}
              <thead className="thead-light">
                <tr>
                  {/* <th className="" scope="col" style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (sort.field === 'id' && sort.direction === 'asc') {
                        setSort({ field: 'id', direction: 'desc' });
                      } else {
                        setSort({ field: 'id', direction: 'asc' });
                      }
                    }}
                  >
                    ID  {sort.field === 'id' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                  </th>                   */}
                  <th className="tableheader-sticky" scope="col" style={{ left: '0' }}>
                    StaffID
                  </th>
                  <th className="tableheader-sticky" scope="col" style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (sort.field === 'StaffName' && sort.direction === 'asc') {
                        setSort({ field: 'StaffName', direction: 'desc' });
                      } else {
                        setSort({ field: 'StaffName', direction: 'asc' });
                      }
                    }}
                  >
                    STAFF NAME  {sort.field === 'StaffName' ? (sort.direction === 'asc' ? '↑' : '↓') : ''}
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    MON
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    TUE
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    WED
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    THU
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    FRI
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    TOTAL
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    PRODUCTIVITY %
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Team
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Bill Rate
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Billable Rate
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Fulltime Equiv %
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Notes
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Working?
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Director/Partner?
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    Manager?
                  </th>
                  <th className="tableheader-sticky" scope="col">
                    FeeEarner?
                  </th>
                  <th className="tableheader-sticky" scope="col">StaffHistoricRates</th>
                  {
                    selectedData?.Details?.dbDestination === "DW" ?
                      <>
                        <th className="tableheader-sticky" scope="col">Custom Task Rates</th>
                        <th className="tableheader-sticky" scope="col">Historic Capacity</th>
                      </>
                      : null
                  }
                  {/* {
                    dbDetails?.tbName === `BIGOLESHOECOMPANY_CLIENT1518_XEROPRACTICEMANAGER_CAPACITYDEV` ?
                      : null
                  } */}
                </tr>
              </thead>
              <tbody className="list">
                {currentPageData()}
                <tr>
                  <td style={{ backgroundColor: '#fff' }}></td>
                  <td style={{ backgroundColor: '#fff' }}></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {
                    selectedData?.Details?.dbDestination === "DW" ?
                      <>
                        <td></td>
                        <td></td>
                      </>
                      : null
                  }
                  {/* {
                    dbDetails?.tbName === `BIGOLESHOECOMPANY_CLIENT1518_XEROPRACTICEMANAGER_CAPACITYDEV` ?
                      : null
                  } */}
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}
        <CardFooter className="py-4">
          {(!paginatedData.length && !loadingData) ? (
            <p className="text-center mt-3">No data found.</p>
          ) : null}
          {(loadingData) ? (
            <p className="text-center mt-3">Fetching data</p>
          ) : null}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card >
      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={showTutorial}
      >
        <div className="modal-body ">
          <div className="modal-header px-0">
            <h6 className="modal-title">
              Tutorial
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowTutorial(!showTutorial)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              className="react-player-custom"
              style={{ position: "absolute", top: 0, right: 0 }}
              url="https://vimeo.com/874701628/2fcb6bcd3e?share=copy"
              controls
              width="100%"
              height="100%"
              light={true}
              stopOnUnmount={false}
              playsinline={true}
              pip={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                vimeo: {
                  playerOptions: {
                    autopause: true,
                    responsive: true,
                    loop: true,
                    keyboard: true,
                    pip: true,
                  },
                },
              }}
            />
          </div>
          <Row
            className="mt-3"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            {/* {videoList()} */}
            {/* <Courses /> */}
            <Col>
              <h3>Description</h3>
              {/* {selectedService?.Details?.TrainingVideoDescriptions
                ? parse(selectedService?.Details?.TrainingVideoDescriptions)
              : ""} */}
              <div className="" style={{ height: '180px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
                <span>XPM Upload Staff Historic Charge Out Rates</span>
                <br />
                <br />
                <span>Select WIP Ledger Report</span>
                <br />
                <span>NO Filter</span>
                <br />
                <div className="content" style={{ marginBottom: '20px' }}>
                  <ul>
                    <li>[Ledger] Staff</li>
                    <li>[Ledger] Date</li>
                    <li>[Ledger] Standard Rate</li>
                  </ul>
                </div>
                <span>Open CSV in Excel</span>
                <br />
                <span>Sort Order - Staff A/Z - Ledger Date - Newest to Oldest</span>
                <br />
                <span>Remove Duplicates - On Staff and Standard Rate</span>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

    </>
  );
};

export default ThirdPartyAppStaffTable;


