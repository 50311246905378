import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import "./styles.css";
import { Button } from "reactstrap";
import { MainContext } from "../../context/mainContext";

const MFABlock = ({ ...props }) => {
  const routerHistory = useHistory();
  const [userDetails, setUserDetails] = useState("");
  const [mfaSettings, setMfaSettings] = useState("");
  const [mfaConfigured, setMFAConfigured] = useState(false);


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const userDetailsFromProps = props.userDetails.User;
      // console.log(userDetailsFromProps)
      const userMFADetails = userDetailsFromProps?.Details?.MFA;

      if (isMounted) {
        setUserDetails(userDetailsFromProps);

        if (userMFADetails && userDetailsFromProps?.Details?.MFAEnabled && userMFADetails?.enabled) {
          setMfaSettings(userMFADetails.enabled);
          // console.log(userMFADetails)
          // console.log(userMFADetails?.DeviceToken?.length)
          // console.log(userMFADetails?.secretToken?.length)
          if (userMFADetails?.DeviceToken?.length || userMFADetails?.secretToken?.length) {
            setMFAConfigured(true)
          };
        } else {
          setMfaSettings(null);
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [props.userDetails]);

  return (
    <div className="details-row-blk ">
      <div className="details-headline-blk">
        <div>
          <h1>Multi-factor Authentication Settings</h1>
        </div>
        <div>
          <div>
            <div className="toggle-flex-blk">
              {/* <ToggleSwitch mfaSettings={mfaSettings} /> */}
              {/* <Button onClick={props.handleShowMFASettings}>
                Show
              </Button> */}
              <label className="custom-toggle custom-toggle-success mr-1">
                <input
                  checked={mfaSettings}
                  type="checkbox"
                  onClick={props.handleShowMFASettings}
                  id="mfa"
                  name="MFA"
                  disabled={!mfaSettings ? true : false}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="Off"
                  data-label-on="On"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="details-main-blk">
        {mfaConfigured ? (
          <>
            <div>
              {userDetails?.Details?.MFA?.DeviceName ? (
                <>
                  <h2>
                    Connected Device: <span>{userDetails?.Details?.MFA?.DeviceName}</span>
                  </h2>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              {userDetails?.Details?.MFA?.DeviceName ? (
                <>
                  <h2>
                    System Device : <span>{userDetails?.Details?.MFA?.SystemName}</span>
                  </h2>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              <h2>
                Authentication Method :&nbsp;
                <span>
                  {userDetails?.Details?.MFA?.DeviceToken && userDetails?.Details?.MFA?.DeviceToken.length
                    ? "App Verify"
                    : userDetails?.Details?.MFA?.secretToken && userDetails?.Details?.MFA?.secretToken.length ?
                      "Google Authenticator"
                      : ''}

                  {/* {userDetails
                    ? userDetails.deviceToken
                      ? "Push Notification"
                      : userDetails.secretToken && "Google Authenticator"
                    : null}

                    { 
                      userDetails?.deviceToken ? "Push Notification" : userDetails?.
                    } */}
                </span>
              </h2>
              <h2>
                Backup Authentication Method :&nbsp;
                {userDetails?.Details?.MFA?.securityQuestions &&
                  userDetails?.Details?.MFA?.securityQuestions?.length
                  ? "Security Questions"
                  : userDetails?.Details?.MFA?.backupEmail.length ? "Backup Email" : ''}
              </h2>
            </div>
          </>
        ) : (
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => {
              return routerHistory.push(
                "/auth/multi-factor-push-notification-setup",
                props.userDetails.User.Email
              );
            }}
          >
            Setup Multi-factor Authentication
          </Button>
        )}
      </div>
    </div>
  );
};

export default MFABlock;
