import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { jwtDecode } from 'jwt-decode'
import Chats from './Chats'
import { getCustomGroups } from 'services/mongoDB'
import { MainContext } from 'context/mainContext'
// import { useHistory } from "react-router";
import { useLocation, useHistory } from "react-router-dom";

const ChatDashboard = ({ token, selectedGroupId, fromReport=false }) => 
{
  const { bgColor, textColor, selectedClientID, selectedClient, firmDetails, userDetails } = useContext(MainContext);
  const [selectedUUID, setSelectedUUID] = useState('')
  const [isOpenTab, setIsOpenTab] = useState(true)
  const [decodedToken, setDecodedToken] = useState(null);
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [activeGroup, setActiveGroup] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupUsers, setGroupUsers] = useState(null);

  useEffect(() => {
    const generateUuidAndDecode = async () => {
      if (Object.keys(userDetails).length === 0) return;
      if(token)
      {
        const decoded = jwtDecode(JSON.stringify(token));
        setDecodedToken(decoded);
        setTheme(decoded.business.theme);
      }else{
        setTheme({TextColor: textColor, PrimaryColor: bgColor})
        const data = {
          "business": {
              "id": firmDetails.id,
              "uid": firmDetails.AccountingFirmID,
              "name": firmDetails.Name,
              "code": firmDetails.Code
          },
          "client": {
              "id": selectedClient.id,
              "uid": selectedClient.ClientID,
              "name": selectedClient.Name
          },
          "user": {
              "id": userDetails.User.id              ,
              "uid": userDetails.User.UserID,
              "fullName": userDetails.User.FirstName + " " + userDetails.User.LastName
          }
        }

        setDecodedToken(data);
      }

    };

    generateUuidAndDecode();
  }, [token, userDetails]);


  const handleSelectUUID = (uuid) => {
    setSelectedUUID(uuid);
  }
  const handleOpenTab = () => {
    setIsOpenTab(!isOpenTab)
  }
  const handleIsLoading = (val) => {
    // console.log("Setting isLoading to:", val);
    setIsLoading(val)
  }

  const handleSetAlert = (val) => {
    setAlert(val)
  }

//#region group list
const [groupList, setGroupList] = useState(null)
const [selectedgroupId, setSelectedGroupId] = useState(null)

  useEffect(() => {

    
    const loadData = async () => {
      if (Object.keys(userDetails).length === 0) return;

      const param = {
          'ClientId': selectedClientID,
          'email': userDetails.User.Email
      };
      const groups = await getCustomGroups(param);
      if(groups)
      {
        setGroupList(groups);
        if(!selectedgroupId && !fromReport)
        {
          const pathnameParts = location.pathname.split('/');
          const id = pathnameParts[pathnameParts.length - 1];
          if(id === 'chat' || id === selectedClientID)
          {
            setSelectedGroupId(selectedClientID)
            setGroupUsers(null)
            return history.push(`/client/chat/${selectedClientID}`)
          }else{
            const selectedGroup = groups.find(group => group._id === id);
            if(selectedGroup){
              
              if (selectedGroup && selectedGroup.UserList && Array.isArray(selectedGroup.UserList.users)) {
                setGroupUsers(selectedGroup.UserList.users.filter((item) => item !== userDetails.User.Email));
              } else {
                console.warn("selectedGroup or selectedGroup.UserList.users is not defined or not an array");
                setGroupUsers([]); // Set an empty array or handle it as per your requirements
              }
              handleSelectGroup(selectedGroup)
              setSelectedGroupId(id)
            }
          }
        }else{
          if(selectedGroupId)
          {
            const selectedGroup = groups.find(group => group._id === selectedGroupId);
            if (selectedGroup && selectedGroup.UserList && Array.isArray(selectedGroup.UserList.users)) {
              setGroupUsers(selectedGroup.UserList.users.filter((item) => item !== userDetails.User.Email));
            } else {
              console.warn("selectedGroup or selectedGroup.UserList.users is not defined or not an array");
              setGroupUsers([]); // Set an empty array or handle it as per your requirements
            }
            handleSelectGroup(selectedGroup)
            setSelectedGroupId(selectedGroupId)
          }
        }
      }
    };

    loadData()

  }, [selectedClientID, userDetails]);

  const handleSelectGroup = async (group) => {
    let Id = group ? group._id : selectedClientID
    Id = selectedGroupId ? selectedGroupId : Id
    // console.log(Id)
    setTimeout(() => {
      setSelectedGroupId(Id)
      if(!fromReport)
        return history.push(`/client/chat/${Id}`)
    }, 200);
    
  }
//#endregion

//#region group selection
  const handleSelectChange = (val) => {

    if(val === selectedClientID)
    {
      setSelectedGroup(selectedClient.Name);
      setTimeout(() => {
        setSelectedGroupId(selectedClientID)
        setGroupUsers(null)
        return history.push(`/client/chat/${selectedClientID}`)
      }, 200);
    }else{
      const selectedGroupId = val;
      const selectedGroup = groupList && groupList.find(group => group._id === selectedGroupId);
      handleSelectGroup(selectedGroup)
      if (selectedGroup && selectedGroup.UserList && Array.isArray(selectedGroup.UserList.users)) {
        setGroupUsers(selectedGroup.UserList.users.filter((item) => item !== userDetails.User.Email));
      } else {
        console.warn("selectedGroup or selectedGroup.UserList.users is not defined or not an array");
        setGroupUsers([]); // Set an empty array or handle it as per your requirements
      }
      setSelectedGroup(selectedGroup.GroupName);
    }
  };
  const capitalize = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };
//#endregion
  return (
    <>
      <div className='chatForum' style={{ height: '100%'}}>
        <div className="chatForumContainer">
          {alert}
          {/* {isLoading && <Loading />} */}
          {decodedToken && theme && (
            <>
            {fromReport ? 
              <Chats
                selectedGroupId={selectedGroupId !== undefined ? selectedGroupId : selectedClientID  }
                userData={decodedToken}
                theme={theme}
                GroupName={selectedGroup.GroupName}
                token={token}
                groupUsers={groupUsers}
              />
              :
              <>
              {isOpenTab && <Sidebar
                groupList={groupList}
                selectedGroupId={selectedgroupId}
                onSelectGroup={handleSelectChange}
                onSelectUUID={handleSelectUUID}
                selectedUUID={selectedUUID}
                theme={theme}
                isOpenTab={isOpenTab}
                onIsLoading={handleIsLoading}
                onSetAlert={handleSetAlert}
                handleOpenTab={handleOpenTab}
              />}
              {selectedgroupId && 
                <Chats
                    selectedGroupId={selectedgroupId}
                    userData={decodedToken}
                    theme={theme}
                    GroupName={selectedGroup.GroupName}
                    token={token}
                    groupUsers={groupUsers}
                />
              }
              </>
            }
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatDashboard