import React from 'react'

 function Loading() {
    return (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
}

export default Loading