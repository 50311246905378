import { CustomButton } from 'components/Button/CustomButton'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap'
import { addGroup } from 'services/mongoDB';
import { dataFilter } from "utils/utilityFunctions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { MainContext } from 'context/mainContext';
import { updateGroup } from 'services/mongoDB';
import { generateUUID } from 'services/EVAResponseService';
import socket from 'chat-components/utils/socket';
import { registerGroupNotification } from 'services/mongoDB';
import CustomSpinner from 'components/Misc/CustomSpinner';

const InputModal = ({ ...props }) => {
    const [nameValid, setNameValid] = useState(true);
    const [payload, setPayload] = useState({});
    const [clientUsers, setClientUsers] = useState([]);
    const [advisersList, setAdviserList] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [alert, setAlert] = useState(false);
    const [createdBy, setCreateBy]=useState('')
    const [clientDetails, setClientDetails] = useState([])
    const [isEditGroup, setIsEditGroup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [oldUserList, setOldUserList] = useState([])
    const [groupNameValue, setGroupNameValue] = useState(null);
    const [groupOldNameValue, setGroupOldNameValue] = useState(null);
    const [accountants, setAccountants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const {
        bgColor,
        textColor,
        selectedClientID,
        userDetails,
        firmDetails,
        getDetails,
      } = useContext(MainContext);

    useEffect(() => {
        setIsLoading(true)
        const loadClientDetails = async () => {
            
            setClientDetails(props.selectedClient)
            setCreateBy(props.userDetails.User.FirstName + ' ' + props.userDetails.User.LastName)
            clearData()
            await loadClientUser(props.selectedClient.id)
            if(props.isEditGroup) toEdit()
        }
        loadClientDetails()
    },[props.groupId])


    const toEdit = () => {
        setGroupNameValue(props.selectedGroup.GroupName)
        setGroupOldNameValue(props.selectedGroup.GroupName)
        setIsEditGroup(props.isEditGroup)
        setSelectedGroup(props.selectedGroup)
        setSelectedUsers(props.selectedGroup.UserList.users)
        setOldUserList(props.selectedGroup.UserList.users)
        setIsLoading(false)
    }

    const clearData = () => {
        setSelectAll(false)
        setNameValid(true)
        setSelectedUsers([])
        setFilteredData([])
        setClientUsers([])
        setPayload([])
        setIsEditGroup(false)
        setSelectedGroup([])
        setOldUserList([])
        setGroupNameValue("")
        props.setShowBlockUI(false);
    }

    const loadClientUser = async (selectedClientID) => {
        if(!selectedClientID || selectedClientID === undefined) return

        const userList = []
        await fetch(`/api/user/getAllRecords/${selectedClientID}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && !data.error) {
                const users = data.map((user) => {
                    return {
                        Selected: false,
                        UserID: user.UserID,
                        Email: user.Email,
                        FirstName: user.FirstName,
                        LastName: user.LastName
                    };
                });
                userList.push(users)
                setClientUsers(users);
                setFilteredData(users);
                setIsLoading(false)
            } else {
                setIsLoading(false)
                return;
            }
        })
        .catch((err) => {
            console.log(err);
        });

        fetch("/api/accountant/getAllRecords", { credentials: "include" })
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {
                return;
            }
            if (!data.error && data) 
            {
                const users = data.map((user) => {
                    return {
                        Selected: false,
                        UserID: user.User.UserID,
                        Email: user.User.Email,
                        FirstName: user.User.FirstName,
                        LastName: user.User.LastName
                    };
                });
                setAdviserList(users);
                setAdviserList(users);
                // setAccountants(users);
              
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const toggleSelected = (userId) => {
        setSelectedUsers((prevSelectedUsers) => {
            // Use logical OR to ensure prevSelectedUsers is an array
            prevSelectedUsers = prevSelectedUsers || [];
    
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter((id) => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };
    

    const handleInputChange = (e) => { 
        setGroupNameValue(e.target.value)
        const newPayload = { ...payload };
        let target = e.target;
        let val = target.type === "checkbox" ? target.checked : target.value;
        let key = e.target.id;

        if (key === "GroupName") {
            if (!val || !val.match(/\S/g)) {
              setNameValid(false);
            } else {
              setNameValid(true);
            }
        }
        newPayload[key] = val;
        setPayload(newPayload);
    } 
    
    const handleSave = async() => {
        // props.setShowBlockUI(true);
        
        const currentUserEmail = props.userDetails.User.Email
       
        let newPayload = { ...payload }; // Create a shallow copy of payload object
        // newPayload.Users = [...selectedUsers]; // Create a shallow copy of selectedUsers array
        newPayload.ClientId = props.selectedClientID;
        newPayload.CreatedBy = createdBy;
        newPayload.CreatedByEmail = currentUserEmail;
        newPayload.GroupName = groupNameValue;
        newPayload.UserList = { users: [...selectedUsers] };

        advisersList.map((user) => {
            if (!newPayload.UserList.users.includes(user.Email)) 
            newPayload.UserList.users.push(user.Email);
        })
       
        
        if(isEditGroup) newPayload._id = selectedGroup._id


        setPayload(newPayload)
        const newUserList = newPayload.UserList.users.filter(user => !oldUserList?.includes(user));
        const removedUser = oldUserList.filter(user => !newPayload.UserList.users?.includes(user));

        if (!newPayload.GroupName) {
            setNameValid(false);
            props.setShowBlockUI(false);
            return;
        }

        try{
            console.log(newPayload)
            if(isEditGroup)
                await updateGroup(newPayload)
            else
                await addGroup(newPayload)

            clearData()
            handleSuccessUpdate()
            
            let msg = `added you to a custom group named ${groupNameValue}`
            sendNotificationHandler(newUserList, false, msg, groupNameValue)

            if(props.isEditGroup && removedUser.length > 0)
            {
                msg = `removed you to a custom group named ${groupNameValue}`
                sendNotificationHandler(removedUser, true, msg, groupNameValue)
            }

            if(props.isEditGroup && groupOldNameValue !== groupNameValue)
            {
                msg = `renamed custom group from ${groupOldNameValue} to ${groupNameValue}`
                sendNotificationHandler(newUserList.length > 0 ? removedUser : props.selectedGroup.UserList.users, false, msg, groupNameValue)
            }

        }catch(error)
        {
            props.setShowBlockUI(false);
            errorAlert("adding group :", error)
        }
    }

    const handleSuccessUpdate = () => {
        setAlert(
            <ReactBSAlert
              success
              title="Success"
              onConfirm={() => {
                setAlert(null)
                props.handleCloseModal()
              }}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
                <p>
                  You have successfully created a new custom group.
                </p>
            </ReactBSAlert>
        );
    }

    const errorAlert = (message) => {
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => 
                setAlert(null)
            }
            confirmBtnBsStyle="error"
            confirmBtnText="Ok"
            btnSize=""
          >
            {message}
          </ReactBSAlert>
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
          setSelectedUsers([]);
        } else {
          setSelectedUsers(clientUsers.map((user) => user.Email));
        }
        setSelectAll(!selectAll);
    };

    const handleSearch = (e) => {
        let filterString = e.target.value;
        setFilteredData(dataFilter(filterString, clientUsers));
    };

    const sendNotificationHandler = async (users, isRemoved, msg, groupName) => {
        sendNotification(users, msg)
        const ClientID = props.selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body:  `${createdBy} ${msg}`,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : ClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);

        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: clientDetails.Name ,  type: 'custom group', Id: clientDetails.id},
          notification: notification,
        };

        console.log(message);
      
        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };

    const sendNotification = async (selectedUsers, message) => {
        for (const email of selectedUsers) {
    
          if(email !== userDetails.User.Email)
          {
            const notifications = {
              uid: await generateUUID(),
              id: selectedClientID,
              clientId: selectedClientID,
              message: `${createdBy} ${message}`,
              createdAt: new Date(),
              sender: userDetails.User.Email,
              isReport: true,
              email: email,
              user: email,
              url: '',
              groupId: selectedClientID,
            };
            // await socket.emit("newClientNotification", notifications);
            registerGroupNotification(notifications)
          }
        }
    };

    return (
        <>
        {alert}
            <Modal
                className="modal-dialog-top"
                isOpen={props.defaultModal}
                toggle={() => props.setdefaultModal(!props.defaultModal)}
                size="lg"
                backdrop="static"
            >
                <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                    {`Create custom group${props.selectedClient?.Name ? ` to ${props.selectedClient?.Name}` : ""}`}
                </h6>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => props.handleCloseModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                </div>
                <div className="modal-body customGroupModal">
                    {isLoading ? <CustomSpinner />
                    : 
                    <Form>
                        <FormGroup>
                        <label className="form-control-label" htmlFor="GroupName">
                            Group Name
                        </label>
                        <Input
                            placeholder="Group Name"
                            value={groupNameValue}
                            type="text"
                            name="GroupName"
                            required={true}
                            id="GroupName"
                            onChange={handleInputChange}
                            className="getData"
                            style={{
                                border: nameValid ? "1px solid #dee2e6" : "1px solid red",
                            }}
                            // disabled={isUpdate ? true : false}
                        />
                        {nameValid ? (
                            ""
                        ) : (
                            <small className="text-warning">
                            This field is required.
                            </small>
                        )}{" "}
                        </FormGroup>
                        <FormGroup>
                            <div className=''>
                                <Row>
                                    <Col lg="6" sm="12">
                                        <div className="mb-3">
                                            <label className="form-control-label" htmlFor="">
                                                Client Users
                                            </label>   
                                        </div>
                                    </Col>
                                    <Col lg="6" sm="12">
                                        <div className="mb-3">
                                            <Input
                                                placeholder="Search"
                                                type="text"
                                                bsSize="sm"
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='userListModalDetails'>
                                <Table>
                                    <thead>
                                        <tr>
                                        <th>
                                            <Button color="primary" onClick={handleSelectAll} style={{ padding: '5px', fontSize: '10px', background: props.bgColor }}>
                                                {selectAll ? 'Deselect All' : 'Select All'}
                                            </Button>
                                        </th>
                                        <th>Email</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((user, index) => (
                                              <tr key={user.Email}>
                                              <td>
                                                  <FormGroup check>
                                                      <Input
                                                          type="checkbox"
                                                          bsSize="xl"
                                                          checked={selectedUsers && selectedUsers.includes(user.Email)}
                                                          onChange={() => toggleSelected(user.Email)}
                                                      />
                                                  </FormGroup>
                                              </td>
                                              <td  onClick={() => toggleSelected(user.Email)}>{user.Email}</td>
                                              <td>{user.FirstName}</td>
                                              <td>{user.LastName}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </FormGroup>
                    </Form>
                    }
                </div>
                <div className="modal-footer">
                    <CustomButton
                        type="button"
                        onClick={() => handleSave()}
                        outlineStyle={false}
                        title="Save"
                    />
                    <CustomButton
                        onClick={() => props.handleCloseModal()}
                        outlineStyle={true}
                        data-dismiss="modal"
                        className="ml-auto"
                        type="button"
                        color="link"
                        title="Close"
                    /> 
                </div>
            </Modal>
        </>
    )
}

export default InputModal