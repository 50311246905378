import { MainContext } from "context/mainContext";
import React, { useContext } from "react";
import { ClipLoader } from "react-spinners";
import { Container } from "reactstrap";

const CustomSpinner = () => {
  const { bgColor } = useContext(MainContext);
  return (
    <Container className="text-center my-5">
      <ClipLoader
        color={bgColor === "#fff" ? "#5e72e4" : bgColor}
        size={35}
        margin={2}
      />
    </Container>
  );
};

export default CustomSpinner;
