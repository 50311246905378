import React, { useCallback, useContext, useEffect, useState } from 'react'
import { generateUUID } from 'services/EVAResponseService';
import { evaThemeLighter } from 'services/EVAResponseService'
import { personalDashboards } from 'services/mongoDB';
import { registerPersonalDashboard } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getCustomGroups } from 'services/mongoDB';
import { MainContext } from 'context/mainContext';
import { registerPersonalDashboardBI } from 'services/mongoDB';
import { registerGroupDashboard } from 'services/mongoDB';
import { registerGroupDashboardBI } from 'services/mongoDB';
import { groupDashboards } from 'services/mongoDB';
import { Col, Row, FormGroup, Label, Input, Button, CustomInput, Form, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { registerUserDashboard } from 'services/mongoDB';
import { registerUserDashboardBI } from 'services/mongoDB';
import socket from 'chat-components/utils/socket';
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { userDashboardTemplate } from 'services/mongoDB';
import { decryptAndDecompress } from 'services/DashboardService';
import { registerDashboardTemplate } from 'services/mongoDB';
import { userDashboard } from 'services/mongoDB';
import { groupDashboardTemplate } from 'services/mongoDB';

const PinToDashboard = ({ theme, onShowPinModal, userData, messageId, integration, stext, version,observation, isBI = false, dataToPin, templateItemVisual, editedGridCellData, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate = () => {}}) => {
    const [dashboardType, setDashboardType] = useState('new');
    const [pintoDashboard, setPintoDashboard] = useState('personal');
    const [selectedDashboardUID, setSelectedDashboardUID] = useState(null);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedDashboard, setSelectedDashboard] = useState([]);
    const [newDashboardName, setNewDashboardName] = useState(null);
    const [newDashboardItemName, setNewDashboardItemName] = useState(null);
    const [existingDashboards, setExistingDashboards] = useState([])
    const [groupList, setGroupList] = useState([])
    const [alert, setAlert] = useState(false);
    const [groups, setGroups] = useState(null)
    const [userTemplateList, setUserTemplateList] = useState([])
    const [userSelectedTemplate, setUserSelectedTemplate] = useState([])
    const { userDetails, selectedClientID, selectedClient, firmDetails } = useContext(MainContext);
    const [decryptedText, setDecryptedText] = useState(null)
    const [userAllDashboardList , setUserAllDashboardList] = useState([])
    const [filteredUserAllDashboardList , setFilteredUserAllDashboardList] = useState([])
    const [clientUserDashboardList , setClientUserDashboardList] = useState([])
    const [groupDashboardTemplateList, setGroupDashboardTemplateList] = useState([])
    const [isValidGroupId, setIsValidGroupId] = useState(true)
    const [isValidDashboardName, setIsValidDashboardName] = useState(true)
    const [isValidSelectedDashboard, setIsValidSelectedDashboard] = useState(true)

    // const existingDashboards = ['Dashboard 1', 'Dashboard 2', 'Dashboard 3', 'Dashboard 4', 'Dashboard 5', 'Dashboard 6', 'Dashboard 7', 'Dashboard 8', 'Dashboard 9', 'Dashboard 10'];
    // const { business, client, user} = userData

    useEffect(() => {
        let isMounted = true;
    
        const loadData = async () => {
            try {
                if(stext !== undefined)
                {
                    const decryptedText = await decryptAndDecompress(stext);
                    if (isMounted) setDecryptedText(decryptedText);
                }
    
                await fetchPersonalDashboards(selectedGroupId);
                await fetchClientGroups();
                await handleLoadCustomGroups(selectedClientID);
                await fetchUserDashboardTemplate();
                await fetchUserDashboard(selectedClientID, selectedGroupId);
    
                const TemplateParam = { clientId: selectedClientID };
                const templateRes = await groupDashboardTemplate(TemplateParam);
                if (isMounted) {
                    const groupDashboardTemplatesList = templateRes && templateRes.filter(item => !item.isPersonalDashboard && item.isPersonalDashboard !== undefined && !item.isSoftDelete);
                    setGroupDashboardTemplateList(groupDashboardTemplatesList);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error loading data", error);
                }
            }
        };
    
        loadData();
    
        return () => {
            isMounted = false;
        };
    }, [messageId, pintoDashboard, selectedGroupId, selectedClient]);
    
    useEffect(() => {
        if(userTemplateList.length > 0 || existingDashboards.length > 0)
        {
            let combinedArray

            if(pintoDashboard === 'group')
            {
                // console.log(selectedGroupId)
                // console.log(existingDashboards)
                // console.log(groupDashboardTemplateList)

                if(selectedGroupId) {
                    const filteredGroupDashboardTemplates = groupDashboardTemplateList.filter(item => item.selectedGroupId?.groupsId.includes(selectedGroupId))
                    // console.log(filteredGroupDashboardTemplates)

                    combinedArray = existingDashboards.map(item => { return item})
                    .filter(item => item.groupId === selectedGroupId)
                    .concat(filteredGroupDashboardTemplates.map(item => { return item }))
                    // .filter(item => item.selectedGroupId?.groupsId.includes(selectedGroupId))
                }else{
                    combinedArray = existingDashboards.map(item => { return item})
                    .concat(groupDashboardTemplateList.map(item => { return item }))
                }
                
                
            } else if(pintoDashboard === 'userDashboard') {
                combinedArray = clientUserDashboardList
            } else {
        
                combinedArray = userTemplateList.map(item => { return item })
                .concat(existingDashboards.map(item => { return item }))
                .filter(item => item.isPersonalDashboard || item.isPersonalDashboard === undefined);
            }

            const sortedItems = combinedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setUserAllDashboardList(sortedItems)
            setFilteredUserAllDashboardList(sortedItems)
        }
    },[userTemplateList, existingDashboards, selectedGroupId, clientUserDashboardList])

    useEffect(() => {
        // console.log(selectedDashboardTemplate)
        if(selectedDashboardTemplate !== undefined)
        {
            setDashboardType("existing")
            setSelectedDashboard(selectedDashboardTemplate);
            setSelectedDashboardUID(selectedDashboardTemplate._id);
        }
    },[selectedDashboardTemplate])

    const fetchUserDashboard = async (selectedClientID, selectedGroupId) => {
        const param = {
            clientId : selectedClientID, 
            groupId : selectedGroupId
        }
        const res = await userDashboard(param)
        setClientUserDashboardList(res)
    }
    const fetchUserDashboardTemplate = async () => {
        const param = {
            clientId: selectedClientID,
            groupId: selectedClientID,
            user: userDetails.User.Email
        }
        const res = await userDashboardTemplate(param)
        setUserTemplateList(res)
    }
    const fetchPersonalDashboards = async groupId => {
        if(pintoDashboard === 'personal')
        {
            const param = {
                clientId: selectedClientID,
                userId: userDetails.User.UserID
            };
            const res = await personalDashboards(param)
            setExistingDashboards(res);
        }else if(pintoDashboard === 'group')
        {
            const param = {
                clientId: selectedClientID,
                groupId: groupId
            };
            const res = await groupDashboards(param)
           
            setExistingDashboards(res);
        }
    };
    const handleLoadCustomGroups = async (selectedClientID) => {
        try {
          const res = await getCustomGroups({ ClientId: selectedClientID })
          const users = []
          selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
          const groups = [{
              "_id": selectedClient.ClientID,
              "Users": users,
              "GroupName": selectedClient.Name,
              "ClientId": selectedClient.ClientID
          }]
          const newRes = groups.map(item => { return item }).concat(res.map(item => {return item}))
          setGroups(newRes)
        } catch (err) {
          console.log(err);
        };
    }
    const fetchClientGroups = async () => {
        const res = pintoDashboard !== 'personal' 
        ? await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email || '' })
        : [];


        const users = []
        selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
        const groups = [{
            "_id": selectedClient.ClientID,
            "Users": users,
            "GroupName": selectedClient.Name,
            "ClientId": selectedClient.ClientID
        }]
        const newRes = groups.map(item => { return item }).concat(res.map(item => {return item}))
        // console.log(newRes)
        setGroupList(newRes);
    };
    const handleRadioChange = event => {
        setSelectedDashboardUID('');
        setNewDashboardName('');
        setDashboardType(event.target.value);
    };
    const handleSelectPinToDashboard = event => {
        // setDashboardType('new');
        setPintoDashboard(event.target.value);
    };
    const handleSelectExistingDashboardChange = event => {
        const selectedUID = event.target.value;
        // const selectedDashboard = existingDashboards.find(dashboard => dashboard.uid === selectedUID);
        const selectedDashboard = userAllDashboardList.find(dashboard => dashboard._id === selectedUID);
        // console.log(selectedDashboard)
        // console.log(selectedUID)
        setSelectedDashboard(selectedDashboard);
        setSelectedDashboardUID(selectedUID);
    };
    const handleSelectTemplates = event => {
        const selectedId = event.target.value;
        const selectedTemplate = userTemplateList.filter(e => e._id === selectedId)
        setUserSelectedTemplate(selectedTemplate)
        // console.log(selectedTemplate)
    }
    const handleSelectGroupChange = useCallback((event) => {
        const group = groupList.filter((item) => item._id === event.target.value)
        setSelectedGroup(group[0])
        setSelectedGroupId(event.target.value);
        // const filter = userAllDashboardList && userAllDashboardList.filter(item => item.groupId === group[0]._id && item.dashboardTemplate === undefined);
        // setFilteredUserAllDashboardList(filter)
    });
    const handleHidePinModal = () => {
        onShowPinModal(false);
    };
    const handleNewDashboardNameChange = event => {
        setNewDashboardName(event.target.value);
    };
    const handleNewDashboardNameItemChange = event => {
     
        setNewDashboardItemName(event.target.value);
    };
    const handlePintoDashboard = async () => {
        setIsValidGroupId(true)
        setIsValidDashboardName(true)
        setIsValidSelectedDashboard(true)

        if(dashboardType !== 'existing' && !newDashboardName)
        {
            setIsValidDashboardName(false)
            return
        }

        if ((pintoDashboard === 'group' || pintoDashboard === 'userDashboard') && (!selectedGroupId || selectedGroupId === undefined || selectedGroupId === "")) 
        {
            setIsValidGroupId(false)
            return
        }

        if(dashboardType === 'existing' && (selectedDashboardUID === "" || selectedDashboardUID === undefined)) 
        {
            setIsValidSelectedDashboard(false)
            return
        }

        if(selectedDashboard.dashboardTemplate !== undefined && dashboardType === 'existing') {
            try {
                const newData = selectedDashboard;
                const dashboardTemplate = [...selectedDashboard.dashboardTemplate];
                // const decryptedText = await decryptAndDecompress(stext);
                const param = {
                    name: newDashboardItemName ? newDashboardItemName : `Item ${newData.dashboardTemplate.length + 1}`,
                    uid: await generateUUID(),
                    query: decryptedText,
                    isPinToDashboard: true,
                    integration: integration,
                    visual: templateItemVisual !== undefined && templateItemVisual ? templateItemVisual : {}
                }

                const { highestY, highestYHeight } = dashboardTemplate.reduce(
                    (acc, item) => {
                        if (item.visual.y > acc.highestY || (item.visual.y=== 0 && acc.highestY === 0))
                            return { highestY: item.visual.y, highestYHeight: item.visual.height };
                        
                        return acc;
                    },
                    { highestY: 0, highestYHeight: 0 }
                );

                param.visual.x = 0;
                param.visual.y = highestY + highestYHeight;
                param.visual.width = 5;
                param.visual.height = 14;
                param.businessId = firmDetails.AccountingFirmID
                dashboardTemplate.push(param)
                newData.dashboardTemplate = dashboardTemplate

                registerDashboardTemplate(newData)
                handleSuccessUpdate();
            } catch (error) {
                console.log(error)
            }
        }else{
            const createdBy = userDetails.User.FirstName + ' ' + userDetails.User.LastName
      
            const detail = dataToPin;
            const param = {
                uid: dashboardType === 'existing' ? selectedDashboard.uid : selectedDashboardUID || (await generateUUID()),
                name: dashboardType === 'existing' ? selectedDashboard.name : newDashboardName,
                clientId: selectedClientID,
                groupId: selectedGroupId ?? selectedClientID,
                userId: userDetails.User.UserID,
                createdBy: createdBy,
                details: isBI
                    ? {
                        AccountingFirm: detail.AccountingFirm,
                        Client: detail.Client,
                        ReportID: detail.ReportID,
                        Name: detail.Name,
                        Description: detail.Description,
                        Details: detail.Details,
                        Status: detail.Status,
                        Type: detail.Type,
                        id: detail.id,
                    }
                    : [
                        {
                            uid: await generateUUID(),
                            name: newDashboardItemName,
                            query: decryptedText,
                            version,
                            observation,
                            integration: integration,
                            visual: templateItemVisual !== undefined && templateItemVisual ? templateItemVisual : {}
                        },
                    ]
            };
            

            if (!isBI) {
                const newData = selectedDashboard;
                if(dashboardType === 'existing')
                {
                    const dashboardTemplate = [...selectedDashboard.details];
                    const { highestY, highestYHeight } = dashboardTemplate.reduce(
                        (acc, item) => {
                            if (item.visual) {
                                if (item.visual.y > acc.highestY || (item.visual.y=== 0 && acc.highestY === 0))
                                    return { highestY: item.visual.y, highestYHeight: item.visual.height };
                                
                            }
                            return acc;
                        },
                        { highestY: 0, highestYHeight: 0 }
                    );
    
                    const newY = (highestY === 0 && highestYHeight === 0) ? selectedDashboard.details.length : highestY + highestYHeight;
                
                    param.details[0].visual.x = 0;
                    param.details[0].visual.y = newY;
                    param.details[0].visual.width = 5;
                    param.details[0].visual.height = 14;
                    
                    dashboardTemplate.push(param.details[0])
                    newData.details = dashboardTemplate
                }else{
                    param.details[0].visual.x = 0;
                    param.details[0].visual.y = 0;
                    param.details[0].visual.width = 5;
                    param.details[0].visual.height = 14;
                }
                
                param.details[0].editedGridCellData = editedGridCellData
            }

            

            try {
    
                if (selectedGroupId || newDashboardName || selectedDashboardUID) 
                {
                    if (pintoDashboard === 'group') 
                    {
                        const res = groups && groups.filter((item) => item._id === selectedGroupId)
                        const selectedGroup = res[0]

                     

                        if (isBI) await registerGroupDashboardBI(param);
                        else await registerGroupDashboard(param);
    
                        const notifications ={
                            uid: await generateUUID(),
                            id: selectedGroupId,
                            clientId: selectedClientID,
                            message: `${createdBy} pinned new ${isBI ? 'Power BI' : 'EVA'} Report to ${selectedGroup.GroupName} group's dashboard`,
                            createdAt: new Date(),
                            sender: userDetails.User.Email,
                            isDashboard: true,
                            url: '/client/customDashboard'
                        }
    
                        let msg = notifications.message
                        sendNotificationHandler(selectedGroup.Users, false, msg, selectedGroup.GroupName, selectedGroupId)
                        // await socket.emit("newGroupNotification", notifications);
    
                    } else if (pintoDashboard === 'userDashboard')
                    {
                        const res = groups && groups.filter((item) => item._id === selectedGroupId)
                        const selectedGroup = res[0]

                        if (isBI) await registerUserDashboardBI(param);
                        else await registerUserDashboard(param);
    
                        const notifications ={
                            uid: await generateUUID(),
                            id: selectedGroupId,
                            clientId: selectedClientID,
                            message: `${createdBy} pinned new ${isBI ? 'Power BI' : 'EVA'} Report to your personal dashboard`,
                            createdAt: new Date(),
                            sender: userDetails.User.Email,
                            isDashboard: true,
                            url: '/client/customDashboard'
                        }
                        let msg = notifications.message
                        sendNotificationHandler(selectedGroup.Users, false, msg, selectedGroup.GroupName, selectedGroupId)
                        // await socket.emit("newGroupNotification", notifications);
    
                    } else if (selectedDashboardUID || newDashboardName) {
                        param.user = userDetails.User.Email
                        param.dashboardTemplate = param.details

                        if (isBI) await registerPersonalDashboardBI(param);
                        else await registerDashboardTemplate(param);
                    }
                   
                    handleSuccessUpdate();
                   
                }
    
            } catch (error) {
                console.log(error)
            }
        }
    };
    const handlePinDirectToDashboard = async () => {
        try {
            if (selectedDashboardTemplate.dashboardTemplate) {
                const newData = selectedDashboardTemplate;
                const dashboardTemplate = selectedDashboardTemplate.dashboardTemplate;
            
                const param = {
                    name: newDashboardItemName ? newDashboardItemName : `Item ${dashboardTemplate.length + 1}`,
                    uid: await generateUUID(),
                    query: decryptedText,
                    isPinToDashboard: true,
                    integration: integration,
                    visual: templateItemVisual !== undefined ? { ...templateItemVisual } : {}
                };
                // Calculate the highest y value and its height
                const { highestY, highestYHeight } = dashboardTemplate.reduce(
                    (acc, item) => {
                    if (item.visual && item.visual.y > acc.highestY) {
                        return { highestY: item.visual.y, highestYHeight: item.visual.height };
                    }
                    return acc;
                    },
                    { highestY: 0, highestYHeight: 0 }
                );

                // Set visual properties
                param.visual.x = 0;
                param.visual.y = highestY + highestYHeight;
                param.visual.width = 5;
                param.visual.height = 14;

                // Add new item to dashboard template and register the updated template
                dashboardTemplate.push(param);
                newData.dashboardTemplate = dashboardTemplate;
                registerDashboardTemplate(newData);
            } else if (selectedDashboardTemplate.details) {
                const newData = selectedDashboardTemplate;
                const dashboardTemplate = selectedDashboardTemplate.details;
            
                const param = {
                    name: newDashboardItemName ? newDashboardItemName : `Item ${dashboardTemplate.length + 1}`,
                    uid: await generateUUID(),
                    query: decryptedText,
                    isPinToDashboard: true,
                    visual: templateItemVisual !== undefined ? { ...templateItemVisual } : {}
                };
            
                const { highestY, highestYHeight } = dashboardTemplate.reduce(
                    (acc, item) => {
                    if (item.visual && item.visual.y > acc.highestY) {
                        return { highestY: item.visual.y, highestYHeight: item.visual.height };
                    }
                    return acc;
                    },
                    { highestY: 0, highestYHeight: 0 }
                );

                // Set visual properties
                param.visual.x = 0;
                param.visual.y = highestY + highestYHeight;
                param.visual.width = 5;
                param.visual.height = 14;
            
                dashboardTemplate.push(param);
                newData.dashboardTemplate = dashboardTemplate;
                registerPersonalDashboard(newData);
            }
            
            handlePintoDashboardUpdate()
        } catch (error) {
            console.log(error)
        }
        onShowPinModal(false);
        handlePintoDashboardUpdate()
    };
    const sendNotificationHandler = async (users, isRemoved, msg, groupName, selectedGroupId) => {
        const createdBy = userDetails.User.FirstName + ' ' +userDetails.User.LastName
        const ClientID = selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body: msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : selectedClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return Array.isArray(users) && users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);

    
        if (filteredTokens.length === 0) return
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'dashboard', Id: selectedClient.id, groupId: selectedGroupId },
          notification: notification,
        };
        
        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };
    const handleSuccessUpdate = () => {
        setAlert(
        <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
                setAlert(null);
                onShowPinModal(false);
                // if(selectedDashboardTemplate?._id === selectedDashboard._id && dashboardType === 'existing') 
                if(dashboardType === 'existing') 
                    handlePintoDashboardUpdate()
                else 
                if(!isBI)
                    handleNewUpdate();
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
        >
            <p>You have successfully pinned a new item to {newDashboardName} Dashboard.</p>
        </ReactBSAlert>
        );
    };
    const DashboardTypes = !isBI ? 
        pintoDashboard === 'personal' ? 
            [
                {id: 1, value: 'new', label: 'New Dashboard'},  
                {id: 2, value: 'existing', label: 'Existing Dashboard'}
                // {id: 3, value: 'templates', label: 'Dashboard Templates'}
            ]
            :
            [{id: 1, value: 'new', label: 'New Dashboard'},  
            {id: 2, value: 'existing', label: 'Existing Dashboard'}]
    :
    [{id: 1, value: 'new', label: 'New Dashboard'}]
    // : [{id: 1, value: 'new', label: 'New Dashboard'}]
    const [showVisual, setShowVisual] = useState(false)
    const handleShowVisual = () => {
        console.log(decryptedText)
        setShowVisual(!showVisual)
    }
    return ( 
        <>
      
        <Modal isOpen={true}>
        {alert}
            {/* {selectedDashboardTemplate !== undefined ?
            <>
                <ModalBody>
                    <h1>Pin to Dashboard</h1>
                    <h5>Are you sure you want to pin this item to {selectedDashboardTemplate.name}?</h5>
                </ModalBody>
            </>
            : */}
                <ModalBody>
                    <h1>Pin to Dashboard</h1>
                    <h5>Select an existing dashboard or create a new one</h5>
                    <Form>
                        <FormGroup>
                        <Label htmlFor="newDashboardItemName">Visual Name:</Label>
                        <Input
                            type="text"
                            id="newDashboardItemName"
                            placeholder="Enter dashboard item name"
                            value={newDashboardItemName}
                            onChange={handleNewDashboardNameItemChange}
                        />
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend>Where would you like to pin?</legend>
                            <CustomInput
                                type="radio"
                                id="personalDashboard"
                                name="pinTodashboardType"
                                value="personal"
                                label="Personal Dashboard"
                                checked={pintoDashboard === 'personal'}
                                onChange={handleSelectPinToDashboard}
                            />
                            <CustomInput
                                type="radio"
                                id="otherDashboard"
                                name="pinTodashboardType"
                                value="group"
                                label="Group Dashboard"
                                checked={pintoDashboard === 'group'}
                                onChange={handleSelectPinToDashboard}
                            />
                            {userDetails.Status === 1 && 
                                <CustomInput
                                    type="radio"
                                    id="userDashboard"
                                    name="pinTodashboardType"
                                    value="userDashboard"
                                    label="Client Users Personal Dashboard"
                                    checked={pintoDashboard === 'userDashboard'}
                                    onChange={handleSelectPinToDashboard}
                                />
                            }
                        </FormGroup>
                        {(pintoDashboard === 'group' || pintoDashboard === 'userDashboard') && (
                            <FormGroup>
                                <Label htmlFor="selectedGroupId">Select Group</Label>
                                <Input type="select" id="selectedGroupId" value={selectedGroupId} onChange={handleSelectGroupChange}>
                                <option value="">Select Group</option>
                                {groupList.map(group => (
                                    <option key={group._id} value={group._id}>
                                    {group.GroupName}
                                    </option>
                                ))}
                                </Input>
                                {isValidGroupId ? (
                                    ""
                                ) : (
                                    <small className="text-warning">
                                    This field is required.
                                    </small>
                                )}{" "}
                            </FormGroup>
                        )}
                        <FormGroup tag="fieldset">
                        <legend>Dashboard type:</legend>
                        {DashboardTypes.map((item, index) => (
                            <CustomInput
                            type="radio"
                            id={item.id}
                            key={index}
                            name="dashboardType"
                            value={item.value}
                            label={item.label}
                            checked={dashboardType === item.value}
                            onChange={handleRadioChange}
                            />
                        ))}
                        </FormGroup>
                        {dashboardType === 'existing' && (
                        <FormGroup>
                            <Label htmlFor="selectedDashboardUID">Select Dashboard</Label>
                            <Input
                            type="select"
                            id="selectedDashboardUID"
                            value={selectedDashboardUID}
                            onChange={handleSelectExistingDashboardChange}
                            >
                            <option value="">Select Dashboard</option>
                            {/* {existingDashboards.map(dashboard => (
                                <option key={dashboard._id} value={dashboard.uid}>
                                {dashboard.name}
                                </option>
                            ))} */}
                            {filteredUserAllDashboardList.map(dashboard => (
                                <option key={dashboard._id} value={dashboard._id}>
                                    {dashboard.name}
                                </option>
                            ))}
                            </Input>
                            {isValidSelectedDashboard ? (
                                ""
                            ) : (
                                <small className="text-warning">
                                This field is required.
                                </small>
                            )}{" "}
                        </FormGroup>
                        )}
                        {dashboardType === 'new' && (
                        <FormGroup>
                            <Label htmlFor="newDashboardName">Enter new dashboard name</Label>
                            <Input
                                type="text"
                                id="newDashboardName"
                                placeholder="Enter new dashboard name"
                                value={newDashboardName}
                                onChange={handleNewDashboardNameChange}
                            />
                            {isValidDashboardName ? (
                                ""
                            ) : (
                                <small className="text-warning">
                                This field is required.
                                </small>
                            )}{" "}
                        </FormGroup>
                        )}
                        {dashboardType === 'templates' && (
                        <FormGroup>
                            <Label htmlFor="selectedDashboardTemplates">Select Dashboard Templates</Label>
                            <Input
                                type="select"
                                id="selectedDashboardTemplates"
                                value={userSelectedTemplate._id}
                                onChange={handleSelectTemplates}
                            >
                                <option value="">Select Dashboard</option>
                                {userTemplateList.map(templates => (
                                    <option 
                                        key={templates._id} 
                                        value={templates._id}>
                                    {templates.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        )}
                    </Form>
                    {showVisual &&
                        <Form>
                            <FormGroup>
                                
                            </FormGroup>
                        </Form>
                    }
                </ModalBody>
            {/* } */}
            
            <ModalFooter>
                {/* {selectedDashboardTemplate !== undefined ?
                    <Button color="primary" onClick={handlePinDirectToDashboard}>Pin</Button>
                :
                    <Button color="primary" onClick={handlePintoDashboard}>Pin</Button>
                } */}
               
                <Button color="primary" onClick={handlePintoDashboard}>Pin</Button> {' '} 
                <Button color="secondary" onClick={handleHidePinModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default PinToDashboard