import React, { useState, useEffect } from 'react';
import { Button, Modal, Row } from "reactstrap";

const EvaSettingModal = ({ showEvaSettingsModal, isEvaAdvisorOnly, ...props }) => {
  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={showEvaSettingsModal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            {isEvaAdvisorOnly ?
              "Enable EVA to all client users"
              : "Disable EVA to all client users"
            }
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            {
              isEvaAdvisorOnly ?
                "Currently, EVA is only visible to advisors"
                :
                "Currently, EVA is visible to all users"
            }

          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            onClick={props.submitEvaSettings}
          >
            Confirm
          </Button>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={props.closeEvaSettings}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EvaSettingModal;