import React, { createContext, useState, useEffect } from "react";
// import socketIOClient from "socket.io-client";
// import sailsIOClient from "sails.io.js";
import { Route, useHistory } from "react-router-dom";
import { useSocket } from "service/SocketContext";
import { useSocketListener } from "service/SocketListeners";
import { useSocketRooms } from "service/SocketRooms";
import moment from "moment";
import axios from "axios";

export const MainContext = createContext();

const MainContextProvider = (props) => {
  const [bgColor, setBgColor] = useState("#fff");
  const [defaulColor] = useState("#fff");
  const [textColor, setTextColor] = useState("#000");
  const [isLight] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isUserDetailsLoaded, setUserDetailsLoading] = useState(false);
  const [firmDetails, setFirmDetails] = useState({});
  const [progressbarPercentage, setProgressbarPercentage] = useState(0);
  const [latestAnnouncements, setLatestAnnouncements] = useState();
  const [announcements, setAnnouncements] = useState();
  const [unReadAnnouncements, setUnReadAnnouncements] = useState();
  const [newMessageReceived, setNewMessageReceived] = useState(0);
  const [navNotifications, setNavNotifications] = useState();
  const [refresh, setRefresh] = useState(false);
  const [courseListFromContext] = useState();
  const [contextClientLists, setContextClientLists] = useState();
  const [contextFilteredClientLists, setContextFilteredClientLists] = useState();
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [contextFunctionsLoading, setContextFunctionLoading] = useState(false);
  const [announcementsLoading] = useState(false);
  const [filteredAnnouncements, setFilteredAnnouncements] = useState();
  const [readAnnouncements, setReadAnnouncements] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [userChatNotification, setUserChatNotification] = useState([])
  const [isTemplateEdit, setIsTemplateEdit] = useState(false)
  const [selectedTemplatetoEdit, setSelectedTemplatetoEdit] = useState(null)
  const [selectClientIntegration, setSelectClientIntegration] = useState(null)
  const [isAddClient, setIsAddClient] = useState(false)
  const [isEditClient, setIsEditClient] = useState(false)
  const [isMultiBusinessUser, setIsMultiBusinessUser] = useState(false)
  const [existingMySQLAccess, setExistingMySQLAccess] = useState(null)
  const [multiBusinessList, setMultiBusinessList] = useState([])
  const history = useHistory();

  async function fetchTheme() {
    const response = await fetch("/api/accountingFirm/getTheme", {
      credentials: "include",
    });
    const theme = await response.json();
    setBgColor(theme.PrimaryColor);
    setTextColor(theme.TextColor);
  }


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/client") {
        return (
          <Route
            path={prop.isActive ? prop.layout + prop.path : "#"}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getUserDetails = async () => {
    // console.log("getUserDetails")
    let user = {};
    try{
      await fetch("/api/user/invitedUserDetails", { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          user = data;
          
          if (data.UserAccess >= 5) {
            fetch(`/api/user/getClientUserDetails/${data.UserID}`, {
              credentials: "include",
            })
              .then((res) => res.json())
              .then((data) => {
               
                if (
                  data.User &&
                  data.User.Details &&
                  !data.User.Details.Authority
                ) {
                 
                  data.User.Details = JSON.parse(data.User.Details);
                }
                
                let uDetails = {
                  User: {
                    ...data,
                    MFAEnabled: data.Details?.MFAEnabled,
                    MFA: user?.MFA
                  },
                };
                setUserDetails(uDetails);

                setUserDetailsLoading(true);
                setContextFunctionLoading(false);
                // getDetails();
              });
          } else if (data.UserAccess < 5) {
            fetch(`/api/accountant/getAccountantDetails/${data.UserID}`, {
              credentials: "include",
            })
              .then((res) => res.json())
              .then((data) => {
                // console.log(data);
                if (data) {
                  let udata = data[0];
                  if (
                    udata &&
                    udata.User &&
                    udata.User?.Details &&
                    firmDetails?.Details?.MFACheck
                  ) {
                    udata.User.MFAEnabled = udata.User.Details.MFAEnabled;

                    if (!udata?.User?.Details?.Authority) {
                      udata.User.Details = JSON.parse(udata.User.Details);
                    }
                  }
                  if (udata?.User) {
                    setUserDetails(udata);
                  } else {
                    redirectToLogin()
                  }
                }
              });
          }
        }
      });
    }catch(e)
    {
      console.log(e)
    }
    
  };

  const getFavicon = (dynamicFavicon) => {
    let favicon = document.getElementById("favicon");
    let img = new Image();
    img.src = dynamicFavicon;
    let aspectSize = 32;
    img.onload = () => {
      let width, height;
      if (img.width > img.height) {
        width = aspectSize;
        height = Math.floor((aspectSize * img.height) / img.width);
      } else {
        width = Math.floor((aspectSize * img.width) / img.height);
        height = aspectSize;
      }
      let canvas = document.createElement("canvas");
      canvas.width = aspectSize;
      canvas.height = aspectSize;
      let ctx = canvas.getContext("2d");
      let x = (canvas.width - width) / 2;
      let y = (canvas.height - height) / 2;
      ctx.drawImage(img, x, y, width, height);
      let resizedFavicon = canvas.toDataURL();
      favicon.href = resizedFavicon;
    };
  };

  useEffect(() => {
    let isMounted = true;
    try {
      if (isMounted) {
        getUserDetails();
      }

      return () => {
        isMounted = false;
      };
    } catch (error) {
      redirectToLogin();
    }
  }, [firmDetails, history]);

  const redirectToLogin = () => {
    history.push("/auth/login"); // Redirect to login page
  };

  const getDetails = async (userDetails) => {
    // console.log(userDetails);
    await fetch(
      `/api/accountingFirm/getRecord?trialUserFirm=${userDetails?.AccountingFirm}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        // console.log(data);
        let end = moment(data?.Details?.IsTrialExtended ? data?.Details?.ExtendedTrialEndDate : data?.Details?.TrialEnd);
        // setFirmDetails(data);
        if (data?.Details?.Trial && data?.Details?.IsTrialExtended) {
          // console.log('ishowmo')
          setShowPaymentModal(true)
        }
        if (data?.Details?.Trial && end.diff(moment(new Date()), "days") < 0) {
          // console.log(end)
          // console.log(data?.Details?.Trial && end.diff(moment(new Date()), "days") < 0)
          try {
            const isLogout = await axios.get("/api/auth/forceLogout")
            // console.log(isLogout)
            if (isLogout.data.success) {
              return history.push(
                `/auth/login`
              );
            }
          } catch (error) {
            console.log(error)
          }
        }
        // console.log(data);
        setFirmDetails(data);
        if (data) {
          if (data.Logo) {
            let url = window.location.hostname.split(".");
            // console.log(url[0]);
            if (url[0] === "platform") {
              return

            }
            getFavicon(data.Logo);
            // getUserDetails();
          } else {
            return;
          }
        }
        setTimeout(() => {
          if (data.Details.NeedSetPayment) {
            // console.log('data.Details.NeedSetPayment', data.Details.NeedSetPayment)
            return history.push("/signup/payment-setup")
          }
        }, 2000)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProgressBarPercentage = (totalChecked, totalCourseLength) => {
    setProgressbarPercentage(
      Math.round((totalChecked / totalCourseLength) * 100)
    );
  };

  const refreshFunc = (refresh) => { };

  useEffect(() => {
    let isMounted = true;
    // setAnnouncementsLoading(true)

    // Start Edited by :Joef <<<<<<<<

    fetchTheme();

    // getUserDetails()

    // if(localStorage.textColor) {
    //   setTextColor(localStorage.textColor)
    // }
    // if(localStorage.bgColor){
    //   setBgColor(localStorage.bgColor)
    // }
    // >>>>>>> End Edited by :Joef
    if (isMounted) {
      getDetails();
      setFirmDetails(firmDetails);
      // setUserDetails(userDetails);
      setBgColor(bgColor);
      setTextColor(textColor);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const setTheme = (color) => {
    localStorage.bgColor = color;
    setBgColor(color);
    firmDetails.Details.Theme.PrimaryColor = color;
    if (color !== defaulColor) {
      localStorage.textColor = "#fff";
      setTextColor("#fff");
      firmDetails.Details.Theme.TextColor = "#fff";
    } else {
      localStorage.textColor = "#000";
      setTextColor("#000");
      firmDetails.Details.Theme.TextColor = "#000";
    }

    console.log("firmDetails : ")
    firmDetails.Client = []
    firmDetails.ClientUser = []
    firmDetails.Accountant = []
    firmDetails.PricingPlan = {}
    firmDetails.PricingPlan = {}
    firmDetails.EmailForInvoicing = firmDetails.EmailForInvoicing ? firmDetails.EmailForInvoicing : ""

    console.log(firmDetails)
    fetch("/api/accountingFirm/updateOwnRecord", {
      method: "PUT",
      body: JSON.stringify(firmDetails),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.message === "Record has been updated") {
          setFirmDetails(data.record);
        }
      });
  };

  // Socket Rooms
  // const socket = useSocket();
  // const socketRoomToJoin = [
  //   {
  //     roomName: "AnnouncementsRoom",
  //     label: "CMS",
  //   }
  // ];
  // useSocketRooms(socketRoomToJoin);

  // Socket Event Listeners
  // const socketListenerDependencies = {
  //   getReadAnnouncent: {
  //     setReadAnnouncements
  //   },
  //   getAllAnnouncements: {
  //     readAnnouncements,
  //     userDetails,
  //     setFilteredAnnouncements,
  //     setUnReadAnnouncements,
  //     firmDetails,
  //   },
  // }

  // useSocketListener(socketListenerDependencies);

  // Run if dependencies change
  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchTheme();

    if (isMounted && !userDetails?.User) {
      setContextFunctionLoading(true);
      getUserDetails();
      getDetails();
      setContextFunctionLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [userDetails]);

  return (
    <MainContext.Provider
      value={{
        bgColor,
        textColor,
        setTheme,
        isLight,
        userDetails,
        getUserDetails,
        firmDetails,
        setFirmDetails,
        progressbarPercentage,
        getProgressBarPercentage,
        latestAnnouncements,
        announcements,
        setAnnouncements,
        setFilteredAnnouncements,
        filteredAnnouncements,
        unReadAnnouncements,
        setUnReadAnnouncements,
        refresh,
        courseListFromContext,
        contextClientLists,
        setContextClientLists,
        setContextFilteredClientLists,
        contextFilteredClientLists,
        selectedClientID,
        setSelectedClientID,
        getRoutes,
        contextFunctionsLoading,
        setContextFunctionLoading,
        announcementsLoading,
        getDetails,
        showPaymentModal,
        setShowPaymentModal,
        setSelectedClient,
        selectedClient,
        navNotifications,
        setNavNotifications,
        setNewMessageReceived,
        newMessageReceived,
        setUserChatNotification,
        userChatNotification, 
        selectedGroupID, 
        setSelectedGroupID,
        isTemplateEdit, 
        setIsTemplateEdit,
        selectedTemplatetoEdit, 
        setSelectedTemplatetoEdit,
        selectClientIntegration, 
        setSelectClientIntegration,
        isAddClient,
        setIsAddClient,
        isEditClient, 
        setIsEditClient,
        isMultiBusinessUser,
        setIsMultiBusinessUser,
        existingMySQLAccess,
        setExistingMySQLAccess,
        multiBusinessList,
        setMultiBusinessList
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
