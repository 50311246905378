export const videos = [
  {
    title:
      "WELCOME VIDEO - Introduction to Etani Business Platform - MUST WATCH",
    // url:"https://player.vimeo.com/video/579236758?h=1751f8e7a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    url: "https://player.vimeo.com/video/579225240?h=739223e4dc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 1 - Welcome – Introduction - Who I am",
    url: "https://player.vimeo.com/video/579225779?h=9915b74d2a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 2 - Overview of Training",
    url: "https://player.vimeo.com/video/579226197?h=b8bcf0dfdd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title:
      "Lecture 3 - What is Power BI / What Makes Up Power BI /Power BI V Spotlight/Fathom/Futrli",
    url: "https://player.vimeo.com/video/579226227?h=33a55e79ef&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 4 - Where Etani Steps In",
    url: "https://player.vimeo.com/video/579226646?h=289be870e5&badge=0&autopause=0&player_id=0&app_id=58479/embed",
  },
  {
    title: "Lecture 5 - Are You a Data Champion OR a Dashboard Champion?",
    url: "https://player.vimeo.com/video/579234853?h=fe7874a33e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 6 - The 4 Pillars of Microsoft Power BI",
    url: "https://player.vimeo.com/video/579235106?h=35b53ff294&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 7 - Power BI Desktop",
    url: "https://player.vimeo.com/video/579235156?h=13b1803cc8&badge=0&autopause=0&player_id=0&app_id=58479/embed",
  },
  {
    title: "Lecture 8 - The Xero Data Source - Old Man Online Clothing",
    url: "https://player.vimeo.com/video/579235899?h=088b7a7d5f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 9 - 1st Pillar - Loading & Transforming Data - Overview",
    url: "https://player.vimeo.com/video/579236596?h=ce55ececee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 10 - 2nd Pillar - The Data Model - Overview",
    url: "https://player.vimeo.com/video/579236758?h=1751f8e7a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 11 - 3rd Pillar - DAX - Overview",
    url: "https://player.vimeo.com/video/579236889?h=d4725569db&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Lecture 12 - 4th Pillar - Reports & Data Visualisations - Overview",
    url: "https://player.vimeo.com/video/579237349?h=0b5cd7f19e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
];

// Lecture 8 - The Xero Data Source - Old Man Online Clothing
// https://player.vimeo.com/video/579235899?h=088b7a7d5f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 9 - 1st Pillar - Loading & Transforming Data - Overview
// https://player.vimeo.com/video/579236596?h=ce55ececee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 10 - 2nd Pillar - The Data Model - Overview
// https://player.vimeo.com/video/579236758?h=1751f8e7a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 11 - 3rd Pillar - DAX - Overview
// https://player.vimeo.com/video/579236889?h=d4725569db&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 12 - 4th Pillar - Reports & Data Visualisations - Overview
// https://player.vimeo.com/video/579237349?h=0b5cd7f19e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// session 2 - Reports & Data Visualisations

// Lecture 2 - Layouts
// https://player.vimeo.com/video/579275513?h=2a6c30b529&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 3 - Mobile View V Desktop View - How do you handle it?
// https://player.vimeo.com/video/579275727?h=1561c0b370&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 4 - Fields Pane - Power BI Desktop
// https://player.vimeo.com/video/579275837?h=fbc66dceb1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 5 - Formatting Pane - Power BI Desktop
// https://player.vimeo.com/video/579277054?h=8977bb81a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 6 - Analytics Pane - Power BI Desktop
// https://player.vimeo.com/video/579278053?h=565560745c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 7 - Custom Visuals - Power BI Desktop
// https://player.vimeo.com/video/579278177?h=4e3d5f75d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 8 - Colour Schemes - Power BI Desktop
// https://player.vimeo.com/video/579278428?h=aad5d6c8d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 9- Interactions between Tiles - Power BI Desktop
// https://player.vimeo.com/video/583148243?h=c41f8ec317&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 10 - Dynamic Visuals - Power BI Desktop
// https://player.vimeo.com/video/583149164?h=da3500bee9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 11 - Bookmarks - Power BI Desktop
// https://player.vimeo.com/video/586074867?h=0352bc2ce5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 12 - Board Deck - Bookmarks
// https://player.vimeo.com/video/586075100?h=17caec304e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 13 - Bookmarks SAME PAGE - Power BI Desktop
// https://player.vimeo.com/video/586075925?h=79dc8c0c1f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 14 - Power BI Web - Get Insights
// https://player.vimeo.com/video/586076061?h=5714a8f463&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 15 - Power BI Web - Dashboards - Detail
// https://player.vimeo.com/video/586076929?h=ed3f27f4b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 16 - Power BI Web - Publishing Dashboards & Reports
// https://player.vimeo.com/video/586077104?h=a21652bb75&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 17 - Power BI Web - Q & A
// https://player.vimeo.com/video/586077180?h=f7cf4769be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 18 - Power BI Web - Data Alerts
// https://player.vimeo.com/video/586083583?h=4401e53c92&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 19 - Content Packs - Power BI Web
// https://player.vimeo.com/video/587610333?h=7439d22027&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 1 - Introduction - 1st Pillar
// https://player.vimeo.com/video/587619337?h=11a36967ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 2 - Column Transformations - 1st Pillar
// https://player.vimeo.com/video/587619565?h=a138d4031e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 3 - Row Transformations - 1st Pillar
// https://player.vimeo.com/video/587619740?h=05e123801d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 4 - The MAGIC Date Table - 1st Pillar
// https://player.vimeo.com/video/587619884?h=df86b9ba39&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 5 - Advanced Transformations - 1st Pillar
// https://player.vimeo.com/video/587620037?h=5fa12c20f2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 6 - Other ways to Transform - 1st Pillar
// https://player.vimeo.com/video/587620278?h=758cc1e549&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 7 - Session 3 - Fact Tables & Lookup Tables - 2nd Pillar
// https://player.vimeo.com/video/587620633?h=b120590930&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 8 - Multiple Relationships - 2nd Pillar
// https://player.vimeo.com/video/587620890?h=5fb661c894&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 9 - Introduction DAX - 3rd Pillar
// https://player.vimeo.com/video/587620986?h=8de0ffad52&badge=0&autopause=0&player_id=0&app_id=58479/embed

// Lecture 10 - Filter Context - 3rd Pillar
// https://player.vimeo.com/video/587621270?h=091a02f972&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479

// Lecture 11 - Evaluation Context - 3rd Pillar
