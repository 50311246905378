import React, { useState } from "react";
import { Modal, Row, Button, FormGroup, Input, Form, Col } from "reactstrap";

const WmbStaffListModal = ({ ThirdPartyAppWmbStaffTable, ...props }) => {
  const [alert, setAlert] = useState(null);
  return (
    <>
      <Modal
        size="xl"
        className="modal-dialog-centered"
        isOpen={props.isWmbStaffListModalOpen}
        toggle={() => props.closeWmbStaffListModal()}
        style={{ height: '100%', maxWidth: '100%' }}
      >
        <div className="modal-header">
          <h6 className=" modal-title" id="modal-title-default">
            Workflow Max Edit Staff Details
          </h6>
        </div>
        <div className="modal-body p-2">
          {ThirdPartyAppWmbStaffTable}
        </div>
        <div className="modal-footer pt-0">
          <Button color="dark" onClick={() => props.closeWmbStaffListModal()}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default WmbStaffListModal;