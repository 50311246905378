import { IconPie } from 'eva-component/ui/icon'
import { IconBar } from 'eva-component/ui/icon'
import { IconCard } from 'eva-component/ui/icon'
import { IconLine } from 'eva-component/ui/icon'
import { IconGrid } from 'eva-component/ui/icon'
import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

function DashboardTemplateKPI({ index, categories, item, tabKey, selectedTemplateItem, handleSelectItem, sqlExampleResult, category, sub_category, handleSelectCategory }) {
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        // console.log(item)
    },[])

    // const getCategory = (data) => {
    //     const categories = [];
    //     data.forEach(item => {
    //         if(item.category)
    //             if (!categories.includes(item.category)) {
    //                 categories.push(item.category);
    //             }
    //     });
    //     return categories;
    // }
    const getIcon = (visual_type) => {
        if(visual_type === 'Grid')
            return <IconGrid />

        if(visual_type === 'Pie Chart')
            return <IconPie />

        if(visual_type === 'Bar Chart')
            return <IconBar />

        if(visual_type === 'Line Chart')
            return <IconLine />

        if(visual_type === 'Card')
            return <IconCard />

        return <IconGrid />
    }
    return (
        <>
            <div style={{ padding: 10 }}>
                {/* <h3>{category}</h3> */}
                <div className='categoryInput'>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                bsSize="xs"
                                // checked={selectedTemplateItem.some(selectedItem => selectedItem.name === detail.display_name)}
                                onChange={(e) => handleSelectCategory(e.target.checked, item)}
                            />
                            {category}
                        </Label>
                    </FormGroup>
                </div>
                {item.details.map((i, index) => (
                    <React.Fragment key={`subcategory-${index}`}>
                        <div className='subCategory'>{i.sub_category}</div>
                        {i.details.map((detail, detailIndex) => (
                            <div
                                className='templateItem'
                                key={`detail-${detailIndex}`}
                            >
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            bsSize="xl"
                                            checked={selectedTemplateItem.some(selectedItem => selectedItem.name === detail.display_name)}
                                            onChange={() => handleSelectItem(
                                                { 
                                                    name: detail.display_name, 
                                                    query: detail.query, 
                                                    visual_type: detail.visual_type, 
                                                    visual_data: detail.visual_data,
                                                    integration: item.integration
                                                })}
                                        />
                                        {getIcon(detail.visual_type)} {detail.display_name} 
                                    </Label>
                                </FormGroup>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    )
    
}

export default DashboardTemplateKPI