import React from "react";
import { Modal, Row, Button, FormGroup, Input, Form, Col } from "reactstrap";
import ThirdPartyAppStaffTable from "components/Table/ThirdPartyAppStaffTable";


const EditStaffListModal = ({ ...props }) => {
  return (
    <>
      <Modal
        size="xl"
        className="modal-dialog-centered staff-list-modal"
        isOpen={props.staffListModal.show}
        toggle={() => props.closeStaffListModal()}
      >
        <div className="modal-header">
          <h6 className=" modal-title" id="modal-title-default">
            Edit Staff Details
          </h6>
        </div>
        <div className="modal-body p-2">
          <ThirdPartyAppStaffTable
            data={props.staffListModal.data}
            openStaffDetailsModal={props.openStaffDetailsModal}
          />
          {/* <BootstrapTable
            bootstrap4
            keyField="id"
            data={props.staffListModal.data}
            columns={columns}
            pagination={paginationFactory()}
            wrapperClasses="table-responsive"
          ></BootstrapTable> */}
        </div>
        <div className="modal-footer pt-0">
          <Button color="dark" onClick={props.closeStaffListModal}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default EditStaffListModal;