import BlockUI from 'components/Misc/BlockUI';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { useFullScreenHandle } from 'react-full-screen';
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { uploadBlob } from 'service/AzureBlobService';
import { pbixPushToQueue } from 'service/AzureBlobService';
import CustomClientReports from 'customComponents/customClientReports';
import { Col, Container } from 'reactstrap';
import CustomSpinner from 'components/Misc/CustomSpinner';
import PowerBITable from 'components/Table/PowerBITable';

const ReportView = ({ ...props }) => {
  const history = useHistory();
  const handle = useFullScreenHandle();
  const [startDate, setStartDate] = useState(new Date());
  const {
    textColor,
    bgColor,
    selectedClientID,
    setSelectedClientID,
    userDetails,
    firmDetails,
    selectedClient
  } = useContext(MainContext);
  //#region declarations
  const [selectedGroupId, setSelectedGroupId] = useState(null)
  const [subscribed, setsubscribed] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [active, setActive] = useState(false)
  const [clients, setClients] = useState([]);
  const [alert, setAlert] = useState(false);
  const [color, setColor] = useState(false);
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line
  const [access, setAccess] = useState(7);
  // eslint-disable-next-line
  const [width, setWidth] = useState(0);
  const [user, setUser] = useState([]);
  const [dashboardsReportsData, setDashboardsReportsData] = useState([])
  const [filterType, setFilterType] = useState("all")
  const [tabs, setTabs] = useState(1);
  const [showPbixFileUpload, setShowPbixFileUpload] = useState(false);
  const [pbixFileName, setPbixFileName] = useState("");
  const [pbixFile, setPbixFile] = useState(null)
  const fileRef = React.useRef(null);
  const [nameValid, setNameValid] = useState(true)
  const [fileValid, setFileValid] = useState(true)
  const [reportsNameList, setReportsNameList] = useState([])
  // eslint-disable-next-line
  const [mainURL, setMainURL] = useState("");

  const [showBlockUI, setShowBlockUI] = useState(false);

  const [embeddedComponent, setEmbeddedComponent] = useState({});
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [dataToPin, setDataToPin] = useState(null)

  const [pageSelectionModal, setPageSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: [],
  });
  const [reportPageSelectionModal, setReportPageSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: "",
  });
  const [schedulerModal, setSchedulerModal] = useState({
    show: false,
    record: {
      Scheduled: true,
    },
  });
  const [singleUserSelectionModal, setSingleUserSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
  });
  const [reportGenerator, setReportGenarator] = useState({
    show: false,
    record: {},
    type: "View",
    config: {},
  });
  const [settingsModal, setSettingsModal] = useState({
    show: false,
    record: {},
  });
  const [dashboardSettingsModal, setDashboardSettingsModal] = useState({
    show: false,
    record: {},
  });
  const [userSelectionModal, setUserSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: [],
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [exportingPdf, setExportingPdf] = useState(false);
  const [exportingPdfStatus, setExportingPdfStatus] = useState('');
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [reportName, setReportName] = useState("")
  const MAX_SELECTED_PAGE_EXPORT_PDF = 5;
  //#endregion
  //#region  functions
  const showBlockUIModal = () => {
    return <></>
    // return <BlockUI />;
  };
  const openSettingsModal = (record) => {
    // console.log("openSettingsModal", record);
    userSelectionModal.selected =
      record.Details && record.Details.recipient
        ? JSON.parse(JSON.stringify(record.Details.recipient))
        : [];

    // settingsModal.show = true;

    setSettingsModal({
      show: true,
      record: JSON.parse(JSON.stringify(record)),
    });

    setUserSelectionModal(userSelectionModal);
    // console.log(settingsModal.record.id);
    setSelectedUsers(record.Details.recipient);
  };
  const closeReportGenerator = () => {
    localStorage.removeItem('selectedReportID')
    localStorage.removeItem('clientOfSelectedReport')
    setReportGenarator({
      show: false,
      record: reportGenerator.record,
      config: reportGenerator.config,
      type: reportGenerator.type,
    });

  };
  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error || response}
      </ReactBSAlert>
    );
  };
  const warning = (error) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {error.message || error}
      </ReactBSAlert>
    );
  };
  const delay = (ms) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }
  const error = (error) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="Error"
        confirmBtnText="Ok"
        btnSize=""
      >
        {error.message || error}
      </ReactBSAlert>
    );
  };
  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error}
      </ReactBSAlert>
    );
  };
  const openReportPageSelection = (record) => {
    const group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    const report = record.ReportID;

    fetch(`/api/powerbi/getReportPages?group=${group}&report=${report}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response.error) return warningAlert(response);

        reportPageSelectionModal.data.unshift({
          Name: "",
          displayName: "Default",
        });
        setReportPageSelectionModal({
          show: true,
          report: record,
          Name: record.Name,
          data: response.value,
          filteredData: response.value,
        });
        // console.log(reportPageSelectionModal);
      });
  };
  const exportToPDF = (record) => {
    setShowBlockUI(true);
    setExportingPdf(true);
    setExportingPdfStatus('Generating PDF ');

    embeddedComponent.bookmarksManager
      .capture()
      .then(function (capturedBookmark) {
        return fetch(`/api/powerbi/exportToPDF?id=${record.id}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ state: capturedBookmark.state }),
        });
      })
      .then(function (response) {
        return response.json();
      })
      .then(async (response) => {
        const { group, exportObj: { id, reportId, token }, name } = response;
        // check status of PowerBI export file

        const record = { group, reportId, id, token, name };
        checkExportStatus(record).then(async (response) => {
          await downloadPdf(record);
          setShowBlockUI(false);
        });

      });


  };
  const getEmbeddedComponent = (embeddedComponent) => {
    // console.log("TESTING");
    // console.log(embeddedComponent)
    setEmbeddedComponent(embeddedComponent);
  };
  const handleImportPbixFile = async () => {
    // console.log(pbixFile)
    let pbixFileName = ''
    // if (pbixFileName === "") {
    //   setNameValid(false)
    //   return
    // }
    if (!pbixFile) {
      setFileValid(false)
      return
    }
    if (pbixFile) {
      pbixFileName = pbixFile.name.replace(/\..+$/, '')

      if (reportsNameList.includes(pbixFileName)) {
        warning({ message: "Report with the same name already exists. Please use different file name." })
        return
      }
      let getFileType = pbixFile.name.split('.')
      if (getFileType[getFileType.length - 1] !== 'pbix') {
        warning({ message: "File not supported. Please import .pbix file only." })
        return
      }
      if (pbixFile.size > 524288000) {
        warning({ message: "File is too large. Please upload 500mb or lower." })
        return
      }

    }
    setShowBlockUI(true)
    // setPbixFileName(pbixFileName.trim())
    const uniqueFileId = new Date().getTime();
    const blobFileName = `POWERBI/PBIX/${uniqueFileId}-pbix-upload.pbix`;

    await fetch(`/api/customReport/uploadPbixBlob`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ fileName: blobFileName })
    })
      .then(res => res.json())
      .then(async data => {
        // console.log("helloworld", data)

        await uploadBlob(pbixFile, blobFileName, data)

        const QUEUENAME = 'powerbi-pbix-upload-queue';
        const messageMetadata = {
          firmId: selectedClient.AccountingFirm,
          clientId: selectedClient.id,
          originalFileName: pbixFile.name,
          reportName: pbixFileName.trim().split(" ").join("%20"),
          blobFileName,
          uploader: {
            id: userDetails.User.id,
            email: userDetails.User.Email,
            userAccess: userDetails.User.UserAccess
          }
        }
        let queueResult = await pbixPushToQueue(QUEUENAME, blobFileName, data, messageMetadata)
        if (!queueResult.errorCode) {
          successAlert({ message: "Your file has been successfully uploaded. Please allow approximately 3 minutes for it to be updated in your list." })
          setShowPbixFileUpload(false)
          setPbixFile(null)
          setPbixFileName("")
          setShowBlockUI(false)
          setNameValid(true)
          setFileValid(true)
        }
      })
      .catch((err) => {
        console.log(err)
        setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            err
          </ReactBSAlert>
        );
      })
  }
  const handleImportPbixChange = (e) => {
    // console.log(e)
    let target = e.target
    // if (target.type === 'text') {
    //   let value = target.value.trim().split(" ").join("%20")
    //   setNameValid(true)
    //   setPbixFileName(value)
    // }
    if (target.type === 'file') {
      // console.log(target.files[0])
      setFileValid(true)
      setPbixFile(target.files[0])
    }
  }
  const handleClosePbixFileUpload = () => {
    setShowPbixFileUpload(false)
  }
  const handleFilteredBy = (type) => {
    let sorted = []
    if (type === "all") {
      sorted = dashboardsReportsData.sort((a, b) => a.Name.localeCompare(b.Name));
    } else {
      const filterType = type === 'report' ? 1 : 2
      const filtered = user.filter(f => f.Type === filterType)
      // console.log(filtered)
      sorted = filtered.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    setFilterType(type)
    setFilterData(sorted)
  }
  const handleShowPbixFileUpload = () => {
    setShowPbixFileUpload(true)
  }
  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = user.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilterData(filtered);
  };
  const checkExportStatus = (record) => {
    const { reportId, id, token } = record;
    const getExportPdfExportStatusUrl = `https://api.powerbi.com/v1.0/myorg/reports/${reportId}/exports/${id}`;

    return fetch(getExportPdfExportStatusUrl, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(async (response) => {
      // console.log('GET PowerBi Report Status', response);

      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        // console.log(`Exporting to PDF: ${response.percentComplete}%`);
        setExportingPdfStatus('Checking PDF status');
        return delay(5000).then(() => checkExportStatus(record));
      }
    });
  }
  const downloadPdf = async (record) => {
    const { group, reportId, id, token, name } = record;

    const exportPdfUrl = `https://api.powerbi.com/v1.0/myorg/groups/${group}/reports/${reportId}/exports/${id}/file`;
    // Set a timeout duration in milliseconds (e.g., 60 seconds)
    const ABORT_TTL = 60000;
    const controller = new AbortController();
    const signal = controller.signal;
    // Create a timer to abort the request after the timeout
    const timeoutId = setTimeout(() => {
      controller.abort(); // Abort the request after the timeout
    }, ABORT_TTL);

    setExportingPdfStatus('Downloading PDF ');

    return fetch(exportPdfUrl, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      signal
    })
      .then(response => {
        clearTimeout(timeoutId);
        setShowBlockUI(false);
        if (response.status === 504) {
          console.log('Request timed out (504 error)');
          // Handle the 504 error here
        } else {
          setExportingPdfStatus('');
          setExportingPdf(false);
          response.blob().then((pdfFile) => {
            let url = window.URL.createObjectURL(pdfFile);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${record.name}.pdf`;
            a.click();
          });
        }
      })
      .catch(error => {
        clearTimeout(timeoutId);
        console.log('ERROR', error);
        setShowBlockUI(false);
      });
  }
  //#endregion

  useEffect(() => {
    // console.log(selectedClient)
    updateWindowDimensions()
    console.log(props.selectedDashboard ? props.selectedDashboard.groupId : selectedClientID)
    setSelectedGroupId(props.selectedDashboard ? props.selectedDashboard.groupId : selectedClientID)
    openViewReport(props.data, false)
  }, [props.data, selectedClient])

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const openViewReport = (record, isEditReport) => {
    setShowBlockUI(true); //Added by Joef: All api calls should have ui block to avoid multi-press
    let message = "This report is not available in desktop view!";
    if (
      !(
        !record.hasOwnProperty("Details") ||
        !record.Details.hasOwnProperty("Settings") ||
        !record.Details.Settings.hasOwnProperty("Desktop") ||
        record.Details.Settings.Desktop
      )
    )
      return warning(message);

    let group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    let report = record.ReportID;
    let role =
      record.Details &&
        record.Details.Settings &&
        record.Details.Settings.Role &&
        record.Details.Settings.RLS
        ? record.Details.Settings.Role
        : "";
    let dataset = record.Details.datasetId;

    let requestURI = `/api/customReport/generateEmbedToken?group=${group}&report=${report}&dataset=${dataset}&role=${role}`;

    if (userDetails && userDetails.User) requestURI += `&username=${userDetails.User.Email}`;

    fetch(requestURI, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false);
        if (response.error) return warningAlert(response);
        const config = {
          type: "report",
          accessToken: response.accessToken,
          embedUrl: record.Details.embedUrl,
          id: record.ReportID,
          viewMode: isEditReport ? 1 : 0,
          permissions: isEditReport ? 1 : 0,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled:
              record.hasOwnProperty("Details") &&
              record.Details.hasOwnProperty("Settings") &&
              record.Details.Settings.hasOwnProperty("ShowTabs") &&
              record.Details.Settings.ShowTabs,
          },
        };

        if (role) config.tokenType = 1;
        if (record.Details && record.Details.DefaultPage)
          config.pageName = record.Details.DefaultPage;

        const reportGeneratorHolder = {};

        reportGeneratorHolder.show = true;
        reportGeneratorHolder.record = record;
        reportGeneratorHolder.config = config;
        reportGeneratorHolder.type = "View";

        if (user && user.Email) {
          closeSingleUserSelectionModal();
          reportGeneratorHolder.type = "Preview";
        }
        setReportGenarator({
          show: true,
          record,
          config,
          type: reportGeneratorHolder.type,
        });
        localStorage.removeItem('selectedReportID')
        localStorage.removeItem('clientOfSelectedReport')
      })
      .catch((err) => {
        console.log(err);
        setShowBlockUI(false);
      });
  };
  const closeSingleUserSelectionModal = () => {
    setSingleUserSelectionModal({
      show: false,
      data: singleUserSelectionModal.data,
      filteredData: singleUserSelectionModal.filteredData,
      report: singleUserSelectionModal.report,
    });
  };
  let allReportsComponent = (
    <CustomClientReports
      headerName={"Dashboards/Reports"}
      selectedGroupId={selectedGroupId}
      access={access}
      height={height}
      onClick={handle.enter}
      handleSearch={handleSearch}
      cardList={<></>}
      reportGenerator={reportGenerator}
      openSettingsModal={openSettingsModal}
      closeReportGenerator={closeReportGenerator}
      openReportPageSelection={openReportPageSelection}
      exportToPDF={exportToPDF}
      exportingPdf={exportingPdf}
      exportingPdfStatus={exportingPdfStatus}
      getEmbeddedComponent={getEmbeddedComponent}
      userDetails={userDetails}
      handleImportPbixFile={handleImportPbixFile}
      handleShowPbixFileUpload={handleShowPbixFileUpload}
      showPbixFileUpload={showPbixFileUpload}
      setShowPbixFileUpload={setShowPbixFileUpload}
      handleImportPbixChange={handleImportPbixChange}
      handleClosePbixFileUpload={handleClosePbixFileUpload}
      nameValid={nameValid}
      fileValid={fileValid}
      firmDetails={firmDetails}
      selectedClientID={selectedClientID}
      handleFilteredBy={handleFilteredBy}
      filterType={filterType}
      selectedClient={selectedClient}
    />
  );
  return (
    <>
      {showBlockUI ? showBlockUIModal() : null}
      {allReportsComponent}
    </>
  )
}
export default ReportView