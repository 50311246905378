import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Row, Col, FormGroup, Button } from "reactstrap";
import { MainContext } from "context/mainContext";
import WYSIWYGEditor from "components/WYSIWYGEditor/WYSIWYGEditor";
import { uploadBlob } from 'service/AzureBlobService';

import moment from "moment-timezone";

import CustomImageFieldGroup, {
  CustomFieldGroup, CustomImageFieldGroupFreeShape, CustomImageFieldGroupSquare,
} from "../../../../customComponents/Form";
import BlockUI from "components/Misc/BlockUI";
import AboutUsCard from "components/Cards/AboutUsCard";
import { CustomButton } from "components/Button/CustomButton";
import { ChromePicker } from "react-color";
import { checkUrlFormat } from "utils/utilityFunctions";

const Details = ({ ...props }) => {
  const { bgColor, setTheme, userDetails } = useContext(MainContext);
  const [blockUI, setBlockUI] = useState(false);
  const [oldLogo, setOldLogo] = useState("");
  const [oldIcon, setOldIcon] = useState("");
  const [record, setRecord] = useState({});
  const [alert] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [newIcon, setNewIcon] = useState(null);
  const [azureLogo, setAzureLogo] = useState(null);
  const [azureIcon, setAzureIcon] = useState(null);
  const [error, setError] = useState({
    name: "",
    website: "",
    email: "",
    landline: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    youtube: "",
  });

  const [colors, setColors] = useState(bgColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [editorContent, setEditorContent] = useState(null);

  const formattedTerms = ` <p>
                                <strong>1 Use of the App</strong>
                              </p>
                              <p class="ql-indent-1">
                                (a)&nbsp;&nbsp;&nbsp;As part of our services, we
                                may offer you the opportunity to sign-up to and
                                use the App. The App allows the facility for us
                                to build customised management reporting
                                dashboards to you by streaming your live data
                                sources from 100+ 3rd party programs (e.g. Xero,
                                Quickbooks Online, Google Analytics, Salesforce,
                                Stripe, Microsoft Excel) and therefore can be of
                                considerable help to you and us in managing your
                                taxation affairs. You can download the App from
                                the Google Play Store or the Apple App Store.
                                The App is owned and maintained by One Place
                                Business Platform Pty Ltd A.C.N. 621 278 180
                                (Etani) and upon download, you will need to
                                accept the terms and conditions of use for the
                                App before using it. Etani can be contacted via
                                its website. We licence the use of the App from
                                Etani and we look forward to you enjoying all
                                the great benefits and functions that the App
                                can offer.
                              </p>
                              <p class="ql-indent-1">
                                (b)&nbsp;&nbsp;&nbsp;When using the App, you may
                                also connect to various third-party data sources
                                (e.g. Xero, Quickbooks Online, Salesforce or
                                Dropbox) (each a Third-Party Connector) and
                                invite other people to have access to the App in
                                respect of your account (e.g. one of your
                                employees or your bookkeeper) (each, a Client
                                Invitee). Your relationship with any Third-Party
                                Connector is solely between you and the
                                Third-Party Connector and may be governed by
                                other terms of use or agreements that apply. The
                                App functions as a mere portal between the
                                Third-Party Connector, you and us. Likewise, it
                                is your choice whether to grant a Client Invitee
                                access to the App and your account. We, Etani
                                and other third parties involved in providing
                                and maintaining the App (each, a Third-Party
                                Supplier) do not control or assume any liability
                                associated with any access you grant to a Client
                                Invitee or any data that you import from a
                                Third-Party Connector and cannot be responsible
                                for the quality, accuracy, nature, ownership, or
                                any loss or damage in relation thereto.
                              </p>
                              <p>
                                <strong>
                                  2&nbsp;&nbsp;&nbsp;Personal Information, TFN
                                  Information and the App
                                </strong>
                              </p>
                              <p class="ql-indent-1">
                                (a)&nbsp;&nbsp;&nbsp;In order for you to sign-up
                                to and use the App, we need to disclose certain
                                personal information (including sensitive
                                information) as defined in the Privacy Act 1988
                                (Cth) (together, Personal Information) and tax
                                file number information (TFN Information) that
                                we have collected about you to Etani and
                                Third-Party Suppliers. The Third-Party Suppliers
                                include Microsoft Azure who can be contacted via
                                its respective website.
                              </p>
                              <p class="ql-indent-1">
                                (b)&nbsp;&nbsp;&nbsp;By signing-up to and using
                                the App, your Personal Information and TFN
                                Information may also be:
                              </p>
                              <p class="ql-indent-3">
                                (i)&nbsp;&nbsp;&nbsp;collected, held, used and
                                disclosed between us, Etani, Third-Party
                                Suppliers, Third-Party Connectors and Client
                                Invitees;
                              </p>
                              <p class="ql-indent-3">
                                (ii)&nbsp;&nbsp;&nbsp;disclosed to Etani’s
                                employees who may be located overseas in the
                                Philippines, and Google Analytics or other
                                analytics providers who may store this
                                information across a large multiple of countries
                                (to which it is impracticable to name each one);
                                and
                              </p>
                              <p class="ql-indent-3">
                                (iii)&nbsp;&nbsp;&nbsp;disclosed to Third-Party
                                Connectors and Client Invitees who may be
                                located overseas; albeit, it is impracticable to
                                name in which overseas countries they may be
                                located (as this is dependent upon which, if
                                any, Third-Party Connectors you choose to use or
                                Client Invitees you choose to give access to).
                              </p>
                              <p class="ql-indent-1">
                                (c)&nbsp;&nbsp;&nbsp;The collection of your TFN
                                Information is authorised by taxation law,
                                including the Tax Agent Services Act 2009 (Cth),
                                Taxation Administration Act 1953 (Cth), Income
                                Tax Assessment Act 1936 (Cth) and Income Tax
                                Assessment Act 1997 (Cth). It is reasonably
                                necessary and relevant for us, Etani and
                                Third-Party Suppliers to collect, hold, use and
                                disclose your Personal Information and TFN
                                Information in this way in order to provide use
                                of the App to you and to assist in managing your
                                taxation affairs effectively. Declining to quote
                                your TFN is not an offence and it is your choice
                                whether to provide your Personal Information and
                                TFN Information; however, if you don't provide
                                any required Personal Information and TFN
                                Information (or consent to their collection,
                                holding, use and disclosure) it may not be
                                possible for you to use all available functions
                                of the App.
                              </p>
                              <p class="ql-indent-1">
                                (d)&nbsp;&nbsp;&nbsp;Further information on the
                                collection, holding, use and disclosure of your
                                Personal Information in relation to the App can
                                be found in Etani’s privacy policy, a copy of
                                which is available by link on Etani’s website
                                homepage, including how you may access your
                                Personal Information, seek its correction,
                                complain about a breach of the Australian
                                Privacy Principles and how such complaint will
                                be handled.&nbsp;&nbsp;&nbsp;&nbsp;
                              </p>
                              <p>
                                <strong>3&nbsp;&nbsp;&nbsp;Your Consent</strong>
                              </p>
                              <p>
                                By signing this engagement letter, you hereby:
                              </p>
                              <p class="ql-indent-1">
                                (a)&nbsp;&nbsp;&nbsp;agree for us to offer you
                                the opportunity to sign-up to and use the App on
                                these terms and conditions; and&nbsp;
                              </p>
                              <p class="ql-indent-1">
                                (b)&nbsp;&nbsp;&nbsp;consent to your Personal
                                Information and TFN Information being collected,
                                held, used and disclosed in the manner set out
                                above and for the purpose of providing you
                                access to, and use of, the App.
                              </p>`;

  useEffect(() => {
    setColors(bgColor);
    fetch("/api/accountingFirm/getRecord", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setRecord(data);
        setOldLogo(data.Logo);
        setOldIcon(data.Icon);
        setEditorContent(data.ClientClausesConsents);
      })
      .catch((e) => {
        let res = {};

        setRecord(res);
        props.warningAlert(e);
      });

    return () => {
      setRecord(record);
    };
  }, [alert, blockUI, bgColor, userDetails]);

  const handleThemeChange = (color) => {
    setColors(color.hex);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const timeZoneList = () => {
    let auTimeZones = moment.tz.zonesForCountry("AU");
    let nzTimeZones = moment.tz.zonesForCountry("NZ");
    let usTimeZones = moment.tz.zonesForCountry("US");
    let gbTimeZones = moment.tz.zonesForCountry("GB");
    let allTimezones = [
      ...usTimeZones,
      ...auTimeZones,
      ...gbTimeZones,
      ...nzTimeZones,
    ];
    let options = allTimezones.map(function (timeZone) {
      return {
        name: `${timeZone} ${moment.tz(timeZone).format("z")}`,
        value: timeZone,
      };
    });

    return (
      <FormGroup>
        <label className="form-control-label" htmlFor="TimeZone">
          Default Time Zone
        </label>
        <Input
          className="form-control"
          type="select"
          name="TimeZone"
          id="TimeZone"
          onChange={
            record.Details && record.Details.TimeZone
              ? (e) => {
                e.preventDefault();
                const target = e.target;
                const value =
                  target.type === "checkbox" ? target.checked : target.value;
                const name = target.name;

                record.Details[name] = value;
                setRecord(record);
              }
              : handleInputChange
          }
          defaultValue={
            (record.Details && record.Details.TimeZone
              ? record.Details.TimeZone
              : record.TimeZone) || ""
          }
          disabled={
            userDetails?.User?.Details?.Authority?.Admin ||
              userDetails?.User?.Details?.Authority?.PracticeManagerAccess
              ? false
              : true
          }
        >
          <option value="">
            {(record.Details && record.Details.TimeZone
              ? record.Details.TimeZone
              : record.TimeZone) || "Select Time Zone"}
          </option>
          {options.map((obj, i) => {
            return (
              <option key={i} value={obj.value}>
                {obj.name}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  const handleInputChange = (event) => {
    event.preventDefault();

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    record[name] = value;

    setError({
      name: "",
      website: "",
      email: "",
      landline: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      youtube: "",
    });
    setRecord(record);
  };

  const handleSubmit = async (event) => {
    // console.log(event)
    event.preventDefault();
    let err = "";
    let res = {};
    const number = /^[0-9-\b]+$/.test(record.Landline);
    const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      record.Email
    );
    // const website = /^(ftp|http|https):\/\/[^ "]+$/.test(record.WebSite);
    const website = checkUrlFormat(record.WebSite);
    const fb = /^(ftp|http|https):\/\/[^ "]+$/.test(record.FacebookPage);
    const li = /^(ftp|http|https):\/\/[^ "]+$/.test(record.LinkedInPage);
    const yt = /^(ftp|http|https):\/\/[^ "]+$/.test(record.YouTubePage);
    const tw = /^(ftp|http|https):\/\/[^ "]+$/.test(record.TwitterPage);

    // console.log('website', website)
    if (
      // !number ||
      !email ||
      // !fb ||
      // !tw ||
      // !li ||
      // !yt ||
      !website ||
      record.Name === ""
    ) {
      if (record.Name === "") {
        err = "Name is required!";
        error.name = err;
        setError(error);
        props.warningAlert(err);
      }

      // if (!number) {
      //   err =
      //     "Invalid landline number, space and characters are not allowed in this field!";
      //   error.landline = err;
      //   setError(error);
      //   props.warningAlert(err);
      // }
      if (!email) {
        err = "Invalid business email!";
        error.email = err;
        setError(error);
        props.warningAlert(err);
      }
      // if (!fb) {
      //   err = "Invalid Business Facebook url!";
      //   error.facebook = err;
      //   setError(error);
      //   props.warningAlert(err);
      // }
      // if (!tw) {
      //   err = "Invalid Business Twitter url!";
      //   error.twitter = err;
      //   setError(error);
      //   props.warningAlert(err);
      // }
      // if (!li) {
      //   err = "Invalid Business LinkedIn url!";
      //   error.linkedin = err;
      //   setError(error);
      //   props.warningAlert(err);
      // }
      // if (!yt) {
      //   err = "Invalid Business YouTube url!";
      //   error.youtube = err;
      //   setError(error);
      //   props.warningAlert(err);
      // }
      if (!website) {
        err = "Invalid business website url!";
        error.website = err;
        setError(error);
        props.warningAlert(err);
      }
      return;
    }

    setBlockUI(true);

    const htmlContent = editorContent === "" ? "" : editorContent;

    const updatedRecord = {
      ...record,
      Logo: newLogo ?? oldLogo,
      Icon: newIcon ?? oldIcon,
      ClientClausesConsents: htmlContent,
    };

    if(newLogo) {
      updatedRecord.Details.SetupBusinessLogo = false
    }
    if(newIcon) {
      updatedRecord.Details.SetupBusinessIcon = false
    }
    if(updatedRecord.TimeZone) {
      updatedRecord.Details.SetupTimeZone = false
    }

    console.log('updatedRecord', updatedRecord)

    if(azureLogo) {
      const blobFileName = `CMS/firmImages/${record?.Name.split(" ").join("-")}.png`;
      await fetch(`/api/clientService/uploadCsv`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ fileName: blobFileName })
      })
        .then((response) => response.json())
        .then(async data => {

          // Upload to Azure Blob
          await uploadBlob(azureLogo, blobFileName, data);
          updatedRecord.Details = {
            ...updatedRecord.Details,
            azureBlobFirmImageUrl: `https://etanicdn.blob.core.windows.net/etani-blob/${blobFileName}`
          }
          updateFetchCall(updatedRecord)
        })
        .catch((err) => {
          console.log('UPLOAD CLIENT IMAGE', err);
          setBlockUI(false);
        })
    } else {
      updateFetchCall(updatedRecord)
    }
  };

  const updateFetchCall = (updatedRecord) => {
    fetch("/api/accountingFirm/updateOwnRecord", {
      method: "PUT",
      body: JSON.stringify(updatedRecord),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          setBlockUI(false);
          if (response.error) {
            return props.warningAlert(response);
          }
          props.successAlert(response);
          const res = response.record;
          setRecord(res);
          setOldLogo(newLogo);
          setOldIcon(newIcon);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);

        if (error) props.warningAlert(error);
      });
  }
 
  const style = (name) => {
    return name !== "" ? { border: "1px solid #fb6340 " } : { border: "" };
  };

  const errorStyle = (name) => {
    return name !== "" ? (
      <span
        className="text-warning"
        style={{ fontSize: "12px", position: "absolute" }}
      >
        <i className="fas fa-exclamation-circle mr-2 text-warning" />
        {name}
      </span>
    ) : null;
  };

  const handleImgChangeLogo = (img) => {
    setNewLogo(img);
    // if (!newIcon) setNewIcon(oldIcon)
  };
  const handleImgChangeIcon = (img) => {
    setNewIcon(img);
    // if (!newLogo) setNewLogo(oldLogo)
  };

  const handleAzureImgChangeLogo = (file) => {
    // console.log(file)
    setAzureLogo(file)
  }

  const handleAzureImgChangeIcon = (file) => {
    setAzureIcon(file)
  }

  const handleEditorChange = (newContent) => {
    setEditorContent(newContent);
  };

  return (
    <AboutUsCard>
      {alert}
      {blockUI ? <BlockUI /> : null}
      {/* <Row>
        <Col className=" mb-3">
          <p style={{ fontWeight: "700" }}>Business Details</p>
        </Col>
      </Row> */}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm="6">
            <CustomFieldGroup
              id="Name"
              name="Name"
              type="text"
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Name`}
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Name`}
              onChange={handleInputChange}
              defaultValue={record.Name || ""}
              style={style(error.name)}
              error={errorStyle(error.name)}
              disabled
            />
            <CustomFieldGroup
              id="WebSite"
              name="WebSite"
              type="text"
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Website`}
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Website`}
              onChange={handleInputChange}
              defaultValue={record.WebSite || ""}
              style={style(error.website)}
              error={errorStyle(error.website)}
              disabled
            // disabled={
            //   userDetails?.User?.Details?.Authority?.Admin ||
            //     userDetails?.User?.Details?.Authority?.PracticeManagerAccess
            //     ? false
            //     : true
            // }
            />
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Email`}
              id="Email"
              name="Email"
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Email`}
              type="text"
              onChange={handleInputChange}
              defaultValue={record.Email || ""}
              style={style(error.email)}
              error={errorStyle(error.email)}
              disabled
            // disabled={
            //   userDetails?.User?.Details?.Authority?.Admin ||
            //     userDetails?.User?.Details?.Authority?.PracticeManagerAccess
            //     ? false
            //     : true
            // }
            />
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Email For Invoicing`}
              id="EmailForInvoicing"
              name="EmailForInvoicing"
              placeholder="Enter Email For Invoicing"
              type="text"
              onChange={handleInputChange}
              defaultValue={record.EmailForInvoicing || ""}
              style={style(error.email)}
              error={errorStyle(error.email)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Landline`}
              id="Landline"
              name="Landline"
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Landline`}
              type="text"
              defaultValue={record.Landline || ""}
              onChange={handleInputChange}
              style={style(error.landline)}
              error={errorStyle(error.landline)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
          </Col>
          <Col sm="6">
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Facebook Page`}
              id="FacebookPage"
              name="FacebookPage"
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Facebook Page`}
              type="text"
              onChange={handleInputChange}
              defaultValue={record.FacebookPage || ""}
              style={style(error.facebook)}
              error={errorStyle(error.facebook)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />

            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Twitter Page`}
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Twitter Page`}
              id="Twitter"
              name="TwitterPage"
              type="text"
              onChange={handleInputChange}
              defaultValue={record.TwitterPage || ""}
              style={style(error.twitter)}
              error={errorStyle(error.twitter)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  LinkedIn Page`}
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} LinkedIn Page`}
              id="LinkedIn"
              name="LinkedInPage"
              type="text"
              onChange={handleInputChange}
              defaultValue={record.LinkedInPage || ""}
              style={style(error.linkedin)}
              error={errorStyle(error.linkedin)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
            <CustomFieldGroup
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  YouTube Page`}
              placeholder={`Enter ${record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} YouTube Page`}
              id="YouTube"
              name="YouTubePage"
              type="text"
              onChange={handleInputChange}
              defaultValue={record.YouTubePage || ""}
              style={style(error.youtube)}
              error={errorStyle(error.youtube)}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="mt-2">
              <label className="form-control-label">Choose Theme Colour</label>
              <div>
                <Button
                  size="sm"
                  className="ml-auto"
                  type="button"
                  style={{
                    borderColor: `${colors}`,
                    background: `${colors}`,
                    color: `${colors === "#fff" ? "#000" : `#fff`}`,
                  }}
                  onClick={() => handleClick()}
                  disabled={
                    userDetails?.User?.Details?.Authority?.Admin ||
                      userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                      ? false
                      : true
                  }
                >
                  Pick Colour
                </Button>
                {displayColorPicker ? (
                  <div style={{ position: "absolute", zIndex: "99999" }}>
                    <div
                      style={{
                        position: "fixed",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        left: "0px",
                      }}
                      onClick={handleClose}
                    />
                    <ChromePicker color={colors} onChange={handleThemeChange} />
                  </div>
                ) : null}
                <Button
                  size="sm"
                  className="ml-auto"
                  type="button"
                  style={{ borderColor: "#525f7f" }}
                  onClick={() => setTheme(colors)}
                  disabled={
                    userDetails?.User?.Details?.Authority?.Admin ||
                      userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                      ? false
                      : true
                  }
                >
                  Set Theme Colour
                </Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3 mb-4">
          <Col lg="12">
            <CustomImageFieldGroup
              id="Logo"
              name="Logo"
              help="Upload Business Logo"
              onChange={handleInputChange}
              value={oldLogo}
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Logo`}
              alt="Logo"
              record={record}
              oldLogo={oldLogo}
              handleImgChangeLogo={handleImgChangeLogo}
              handleAzureImgChangeLogo={handleAzureImgChangeLogo}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
            {/* <CustomImageFieldGroupFreeShape
              id="Logo"
              name="Logo"
              help="Upload Business Logo"
              onChange={handleInputChange}
              value={oldLogo}
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Logo`}
              alt="Logo"
              record={record}
              handleImgChange={handleImgChange}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            /> */}
          </Col>
          <Col lg="12">
            {/* <CustomImageFieldGroup
              id="Icon"
              name="Icon"
              help="Upload Business Icon"
              onChange={handleInputChange}
              value={oldIcon}
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Icon`}
              alt="Icon"
              record={record}
              handleImgChange={handleImgChange}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            /> */}
            <CustomImageFieldGroupSquare
              id="Icon"
              name="Icon"
              help="Upload Business Icon"
              onChange={handleInputChange}
              value={oldIcon}
              label={`${record?.Details?.Type === "Accounting Firm"
                ? "Accounting Firm"
                : "Business"
                }  Icon`}
              alt="Icon"
              record={record}
              oldIcon={oldIcon}
              handleImgChangeIcon={handleImgChangeIcon}
              handleAzureImgChangeLogo={handleAzureImgChangeIcon}
              disabled={
                userDetails?.User?.Details?.Authority?.Admin ||
                  userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                  ? false
                  : true
              }
            />
            <Row>
              <Col className="mt-4" lg="6">
                {timeZoneList()}
                <CustomFieldGroup
                  label={`${record?.Details?.Type === "Accounting Firm"
                    ? "Accounting Firm"
                    : "Business"
                    }  Description`}
                  name="Description"
                  type="textarea"
                  placeholder="Enter Business Description"
                  style={{ height: 200 }} // Edited by Kenneth
                  onChange={handleInputChange}
                  defaultValue={record.Description || ""}
                  disabled={
                    userDetails?.User?.Details?.Authority?.Admin ||
                      userDetails?.User?.Details?.Authority?.PracticeManagerAccess
                      ? false
                      : true
                  }
                />
              </Col>
              {/* <Col className="mt-4" lg="6">
                <div>
                  <label className="form-control-label">
                    {record?.Details?.Type === "Business" ? "Business" : "Accounting Firm"} Client Clauses and Consents
                  </label>
                  <WYSIWYGEditor
                    value={
                      editorContent && editorContent.includes("<p>")
                        ? editorContent
                        : formattedTerms
                    }
                    onChange={handleEditorChange}
                    readOnly={true}
                  />
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <CustomButton
          type="submit"
          title="Save"
          className="mb--5"
          outlineStyle={false}
          disabled={
            userDetails?.User?.Details?.Authority?.Admin ||
              userDetails?.User?.Details?.Authority?.PracticeManagerAccess
              ? false
              : true
          }
        />
      </Form>
    </AboutUsCard>
  );
};

export default Details;
