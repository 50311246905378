import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardFooter,
  Col,
} from "reactstrap";
import { Badge } from "customizable-react-badges";
import moment from "moment";

const AnnouncementCard = ({ ...props }) => {
  return (
    <Col sm="12" xl="3" lg="4" md="6" style={{ cursor: "pointer" }}>
      <a href={`/client/announcement/${props.id}`}>
        <Card className="bg-transparent shadow--hover">
          {props.data.isRead ? null : (
            <Badge content="New" bgColor="#2dce89" contentColor="#ffffff" />
          )}
          <CardImg
            alt="..."
            src={
              props.data?.cover
                ? props.data?.cover
                : require("assets/img/etani-logo.png").default
            }
            top
          />
          <CardBody>
            <CardTitle className="text-center" style={{ height: "50px" }}>
              <h4>{`${props.Title || "Test Announcement Title"}`}</h4>
            </CardTitle>
            <CardFooter className="px-0">
              {/* {`Posted by: ${props.Text || 'Test User'}`} */}
              <div className="d-flex">
                <div style={{ marginRight: "10px" }}>
                  <img
                    alt="..."
                    className="avatar rounded"
                    src={
                      props.data.posted_by.Avatar
                        ? props.data.posted_by.Avatar
                        : require("assets/img/etanilogo.png").default
                    }
                  />
                </div>
                <div>
                  <h4 className="mb-0 text-sm text-muted">
                    {`Posted By: `}
                    <strong className="text-sm mb-0 text-muted">
                      {props.data.posted_by.FirstName}{" "}
                      {props.data.posted_by.LastName}
                    </strong>
                  </h4>
                  <small className="text-muted">
                    {moment(props.data.updatedAt).format("LLL")}
                  </small>
                </div>
              </div>
            </CardFooter>
          </CardBody>
        </Card>
      </a>
    </Col>
  );
};

export default AnnouncementCard;
