import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { FetchAvatar, deleteGroupChatNotifications, fetchEVAMessages, getMessageReact, mongoFetchToken, mongofetchMessages, registerGroupChatNotification, sendEVAPrivateMessage, sendMessage } from "../services/mongoDB";
import { EVAGroup, EVAPrivate, EVAService, getChatTimeLabel } from "../services/EVAService";
import { CloseTab } from "./ui/icon";
import EVATyping from "./ui/eva-typing";
import EVAResponse from "eva-component/response/EVAResponse";
import { isShowName, setBorderRadius } from "./Helper/chatHelper";
import SendMultiDeviceNotification from "services/NotificationService";
import { evaThemeLighter } from "services/EVAResponseService";
// import socket from "./utils/socket";
import SendExpoNotifications from "services/ExpoNotificationService";
import Loading from "./ui/loading";
import CustomSpinner from "components/Misc/CustomSpinner";
import { MainContext } from "context/mainContext";
import { generateUUID } from "services/EVAResponseService";
import EVAStreamLoading from "eva-component/response/EVAStreamLoading";

const Chats = ({ selectedGroupId, userData, theme, GroupName, token, groupUsers }) => {
  // console.log(userData)

  const { business, client, user} = userData
  const ClientID = client.uid
  const Name = client.name
  // console.log("client ", Name)
  const [messages, setMessages] = useState([]);
  const [isEVATyping, setIsEVATyping] = useState(false)
  const [inputText, setInputText] = useState('');
  const limitIncrement = 30
  const [messageLimit, setMessagesLimit] = useState(limitIncrement)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const [avatars, setAvatars] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [clientUsers, setClientUsers] = useState([]);
  const [streamMessage, setStreamMessage] = useState([])

  const { 
    userChatNotification, 
    selectedClientID, 
    userDetails, 
    setUserChatNotification, 
    selectedClient } = useContext(MainContext);
  const messagesContainerRef = useRef(null);

  useEffect(()=> {
    const getLocalStorage = async () => {
      try {
        setIsLoading(true);
        updateNotification(selectedGroupId);
        setInputText("")
        await loadMessages(selectedGroupId)
        // socket.emit("joinGroupChat", selectedGroupId, Name, userDetails.User.Email);
        scrollDown();
      } catch (error) {
          console.log(error);
      }
    };
    getLocalStorage();
  }, [selectedGroupId])

  useEffect(()=> {
    const getLocalStorage = async () => {
      try {
        if(groupUsers) return
        await loadClientUser(selectedClient)
      } catch (error) {
          console.log(error);
      }
    };
    getLocalStorage();
  }, [selectedClientID])

  const loadClientUser = async (selectedClient) => {
    if(selectedClient === undefined || !selectedClient) return
    const userList = []
    await fetch(`/api/user/getAllRecords/${selectedClient.id}`)
    .then((res) => res.json())
    .then((data) => {
      if (data && !data.error) {
        userList.push(data.map((user) => {
            return user.Email
        }));
      }
    })
    .catch((err) => {
        console.log(err);
    });
    const adviser = []
    await fetch("/api/accountant/getAllRecords", { credentials: "include" })
    .then((res) => res.json())
    .then((data) => {
        if (!data.error && data) 
        {
          adviser.push(data.map((user) => {
              return user.User.Email
          }))
        }
    })
    .catch((err) => {
        console.log(err);
    });

    const combinedArray = userList.concat(adviser);
    setClientUsers(combinedArray.flat())
  }
  
  const updateNotification = async (selectedGroupId) => {
    if (userChatNotification) {
      const notification = userChatNotification.filter((item) => item.groupId === selectedGroupId);
      
     if (notification.length > 0) 
     {
      const param = { user: userDetails.User.Email, clientId: selectedClientID, groupId: selectedGroupId }
      const notification = await deleteGroupChatNotifications(param)
      setUserChatNotification(notification.filter(item => item.groupId !== param.groupId))
     }
    }
  }
  
  const scrollDown = () => {
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  }

  useEffect(() => {
		const handleRoomMessage = async (msg) => {
      const currentUrl = window.location.href.split('/');
      const id = currentUrl[currentUrl.length - 1];

      if(msg.data.groupId === id)
        loadMessages(id)
      // if(msg.data.groupId === selectedGroupId) {
      //   const newMessage =  {
      //     "user": msg.user,
      //     "_id": msg.id,
      //     "clientid": msg.data.clientid,
      //     "groupId": msg.data.groupId,
      //     "text": msg.data.text,
      //     "sender": msg.data.sender,
      //     "createdAt": msg.data.createdAt,
      //     "__v": 0
      //   }

      //   if(msg.data.sender === userDetails.User.Email) return 

      //    setMessages((prevMessages) => [...prevMessages, newMessage]);
      // }
		};
	  
		// socket.on("roomMessage", handleRoomMessage);
		return () => {
		  // socket.off("roomMessage", handleRoomMessage);
		};
	}, [userData]); 

  const scrollToBottom = () => {
      messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current?.scrollHeight);
  };

  const loadMessages = async (selectedGroupId) => {
    const myChatroom = await fetchGroupChatMessages(messageLimit, selectedGroupId);
    if (myChatroom && myChatroom !== undefined)
    {
      setMessages(renderMessages(myChatroom));
      handleLoading(false);
    }else{
      setMessages([])
      handleLoading(false);
    }
  }

  useLayoutEffect(() => {
		// socket.emit("findRoom", ClientID);
		// socket.on("foundRoom", async () => {
    //   // loadMessages(selectedGroupId)
    // });
	}, [userData]);


  const fetchGroupChatMessages = useCallback(async (messageLimit, selectedGroupId) => {
    try {
      const mongoMessages = await mongofetchMessages({ clientid: ClientID, groupId: selectedGroupId , pageSize: messageLimit ? messageLimit : limitIncrement });
      return mongoMessages
    } catch (error) {
      console.error('Error fetching messages:', error);
      handleLoading(false);
      return null
    }
  }, [userData]);

  const renderMessages = useCallback(
    (msgs, userDetails) => {
      const reversedMessages = msgs
        ? msgs.map((msg, index) => ({
            ...msg,
            _id: msg._id,
            // Additional properties based on userDetails
            // avatar: userDetails.avatar,
            // email: userDetails.email,
          }))
        : [];
  
      return reversedMessages.reverse();
    },
    [/* userDetails.avatar, userDetails.email */],
  );

  const handleSendMessage = async (text) => {
    const newMessage = {
      clientid: ClientID,
      groupId: selectedGroupId,
      userid: user.uid,
      _id: Math.floor(Math.random() * 10000000000000),
      text: text,
      sender: user.uid,
      user: mapUser(),
      createdAt: new Date(),
    }

    let newNotification = newMessage
    newNotification.type = 'chat'

    const messageDetails = newMessage.text
    const lowercasedMessage = messageDetails.toLowerCase();
    const targetString = "@eva";
    const isMessageEva = lowercasedMessage.includes(targetString.toLowerCase());
    const splitMessage = lowercasedMessage.replace(targetString, "")

    if (splitMessage.length > 0) {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      await sendMessage(newMessage)

      await sendNotificationHandler(user.fullName,Name,text)

      scrollDown()
      newMessage.groupName = GroupName
      newNotification.senderEmail = userDetails.User.Email
      await sendCMSNotification(newNotification)
      // await socket.emit("newMessage", newMessage);
      // await socket.emit("newNotification", newNotification);
      // console.log(newNotification)
      // await socket.emit("newGroupChatNotification", newNotification);
      if(token)
        if (isMessageEva) {
          // setIsEVATyping(true)
          // await EVAService(splitMessage, "", ClientID, user.uid,0, user.name, business.uid, true)
          // setIsEVATyping(false)
          // await socket.emit("newMessage", newMessage);
          setEVAStream('')
          const raw = {
            "chatId": 0,
            "query": splitMessage,
            "UserInfo": {
              "chatId": 0,
              "UserId": user.uid,
              "Name": user.fullName,
              "ClientId": client.uid,
              "BusinessId": business.uid
            }
          };

          try {
            const response = await fetch('https://evabetaprod.azurewebsites.net/eva_stream', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(raw)
            });
            
            const reader = response.body.getReader();
            let chunks = '';
            const streamMessageArray = []
      
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              chunks += new TextDecoder().decode(value);
              const messagesArray = chunks.split('\n');
              streamMessageArray.push(chunks)
              setStreamMessage([...streamMessageArray])
         
            }
            await saveEVAStream(streamMessageArray[streamMessageArray.length - 1], text)
          } catch (error) {
              // await saveEVAStream(`We are currently encountering an issue while processing your request. 
              //                     We kindly ask that you attempt your request again at a later time. 
              //                     If you continue to receive this message repeatedly, 
              //                     we urge you to contact our dedicated support team for prompt assistance. 
              //                     Thank you!`, text);
              const errorMessage = {
                clientid: ClientID,
                groupId: selectedGroupId,
                userid: 1,
                _id: Math.floor(Math.random() * 10000000000000),
                text: `We are currently encountering an issue while processing your request. 
                      We kindly ask that you attempt your request again at a later time. 
                      If you continue to receive this message repeatedly, 
                      we urge you to contact our dedicated support team for prompt assistance. 
                      Thank you!`,
                sender: 1,
                user: mapEVA(),
                createdAt: new Date(),
              }
              setMessages((prevMessages) => [...prevMessages, errorMessage]);
          }
          setStreamMessage([])
        }
    }
  }
  const setEVAStream = (message) => {
    const newMessage = {
      uuid: 0,
      clientid: client.uid,
      userid: user.uid,
      _id: Math.floor(Math.random() * 10000000000000),
      text: message,
      sender: user.uid,
      user: mapEVA(),
      createdAt: new Date(),
      isStreamMessage: true
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };
  const saveEVAStream = async (message, msg) => {
    const response = await EVAGroup(message, "", client.uid, msg, selectedGroupId);
    await loadMessages(selectedGroupId)
    scrollDown();
  };
  const sendCMSNotification = async (newNotification) => {
    if(selectedClientID === selectedGroupId)
    {
      const param = {
        uid : await generateUUID(),
        id: newNotification._id,
        clientId: newNotification.clientid,
        message: newNotification.text,
        sender: newNotification.sender,
        url: `/client/chat/${newNotification.groupId}`,
        users: clientUsers,
        groupId: newNotification.groupId,
        senderEmail: newNotification.senderEmail,
      }
      await registerGroupChatNotification(param)
    }else{
      const param = {
        uid : await generateUUID(),
        id: newNotification._id,
        clientId: newNotification.clientid,
        message: newNotification.text,
        sender: newNotification.sender,
        url: `/client/chat/${newNotification.groupId}`,
        users: groupUsers,
        groupId: newNotification.groupId,
        senderEmail: newNotification.senderEmail,
      }
      await registerGroupChatNotification(param)
    }
  }

  const sendNotificationHandler = async (name,groupChat,msg) => {
    // const admin = require('firebase-admin');
    const isFile = msg.includes("/documents%2F")
    const isImage = msg.includes("/images%2F")

    const messageType = isImage
    ? 'image'
    : isFile
    ? 'document'
    : 'a message';
    
    const notification = {
      title: `${name} sent ${messageType} to the ${groupChat} group chat.`,
      body:  isFile || isImage ? '' : msg,
      sound: 'default',
      badge: '1',
    };

    // const clientToken = await fetchTokenList(ClientID);
    const clientToken = await mongoFetchToken({ clientid : ClientID, groupId : selectedGroupId })
    if(!clientToken) return 
    const filteredTokens = (clientToken.tokens || []).map(tokenObj => {
      // console.log(tokenObj.token)
      // socket.emit("sendNotification", tokenObj.token)
      return tokenObj.token
    });
    // console.log("filteredTokens", filteredTokens)

    // const renderedClientToken = renderToken(clientToken.token)
    if (filteredTokens.length === 0) return

    const message = {
      tokens: filteredTokens,
      data: { ClientID: ClientID, groupId : selectedGroupId , Name: business.name ,  type: 'chat'},
      notification: notification,
    };
    const payload = {
      notification: {
        title: 'FCM Notification',
        body: 'This is an FCM notification that displays an image!',
        sound: 'default',
        badge: '1',
      },
    };

    SendMultiDeviceNotification(message)
    SendExpoNotifications(message)
   
    // console.log(message)
    // return admin.messaging().Send(tokens, payload);
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  }   

  function mapEVA()
  {
      return {
          _id: Math.floor(Math.random() * 10000000000000),
          sender: Math.floor(Math.random() * 10000000000000),
          name: "EVA" ,
          // avatar: '../../etani.png'
      };
  }

  function mapUser() {
    return {
        _id: user.uid,
        sender: user.uid,
        name: user.fullName,
    };
  }

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchUserAvatar()
      if(response)
      {
        setAvatars(response)
      }
    }
    loadData();
  }, [userData])

  const fetchUserAvatar = useCallback(async() => {
    try{
      const clientid = { clientid : ClientID }
      const avatars = await FetchAvatar(clientid)

      return avatars
    }catch(error)
    {
      console.log("fetchUserAvatar error: ", error)
    }
  },[userData]);

  const getAvatar = (sender) => {
    // console.log(sender)
    // console.log(avatars)
    const matchingAvatar = avatars.find((avatar) => avatar.userid === sender.sender);
    return matchingAvatar ? matchingAvatar.avatar : null;
  }

  const UserAvatar = (sender) => {

    const userAvatar = getAvatar(sender.sender);
    // console.log(sender)

    const getInitials = () => {
      if (sender && sender.sender && sender.sender.name === "EVA") {
        return "EVA"
      }
      if (sender && sender.sender && sender.sender.name) {
        const nameParts = sender.sender.name.split(' ');
        return nameParts.map(part => part[0].toUpperCase()).join('');
      }
      return '';
    };

    return (
      <div className="avatarContainer">
        {userAvatar ? 
           <img src={userAvatar} width={35} height={35} alt="User Avatar" /> :
           <span>{getInitials()}</span>
        }
      </div>
    );
  }

  const handleSetInputText = (text) => {
    // console.log(text)
    setInputText(text)
  }

  const handleScroll = () => {
    // Check if the scroll position is at the top
    if (messagesContainerRef.current.scrollTop === 0) {
      // Call your function when scrolled to the top
      handlePageIncrement()
      handleScrolledToTop();
    }
  };

  const handleScrolledToTop = async () => {
    // Your custom function to run when scrolled to the top
    // console.log('Scrolled to the top!');
    await loadMessages(selectedGroupId)
    // Add your logic here
  };

  const handlePageIncrement = () => {
    const newLimit = messageLimit + limitIncrement
    setMessagesLimit(newLimit)
  }


  return (
    <div className="chatContent" style={{ border: `2px solid ${theme.PrimaryColor}` }}>
      <div className="chatGroupHeader" style={{ background: theme.PrimaryColor, color: theme.TextColor }}>
        <div className="closeTab">
          <CloseTab />
        </div>
      </div>
      <div className="messages" ref={messagesContainerRef} onScroll={handleScroll}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {messages.map((message, index) => (
              <div key={index} style={{ position: message.isStreamMessage ? 'relative' : 'initial' }}>
                {message.isStreamMessage ? (
                  <EVAStreamLoading streamMessage={streamMessage} />
                ) : 
                (message.text.includes("```json") || message.text.includes("get_sql_results")  || message.text.includes("sql_result")) ? (
                  <div className="message leftMessage">
                    <div className="member">
                      {isShowName(index) && message.user.sender !== user.uid && message.user.name !== "EVA" ? (
                        <div className="memberName">forwarded by {message.user.name}</div>
                      ) : message.user.name === "EVA" ? (
                        <div className="memberName">EVA</div>
                      ) : (
                        <div className="memberName">You shared EVA's response</div>
                      )}
                        <EVAResponse currentMessage={message} theme={theme} showQuery showAvatar={false} showFeedback={false} />
                      {message.user.sender !== user.uid && <UserAvatar sender={message.user} />}
                    </div>
                  </div>
                ) : (
                  <div className={`message`}>
                    <div className={message.user.sender === user.uid ? "owner" : "member"}>
                      {isShowName(messages, index) && message.user.sender !== user.uid && (
                        <div className="memberName">{message.user.name}</div>
                      )}
                      <div
                        style={message.user.sender === user.uid ? evaThemeLighter(100, theme) : {}}
                        className={`messageText ${setBorderRadius(messages, index)}`}
                      >
                        {message.text}
                      </div>
                      {setBorderRadius(messages, index).includes('bblr') && (
                        <>
                          <div className="chatTimeLabel">{getChatTimeLabel(message.createdAt)}</div>
                          {message.user.sender !== user.uid && <UserAvatar sender={message.user} />}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <Input onSendMessage={handleSendMessage} theme={theme} token={token} />
    </div>
  );
};

export default Chats;
