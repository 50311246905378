import { IconTrash } from 'chat-components/ui/icon';
import { MainContext } from 'context/mainContext';
import React, { useContext } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';

const GroupDashboardList = ({editMode, groupDashboardList, handleIsActiveGroupDashboard, handleShowDeleteDashboardListConfirmation, handleSelectGroupDashboard}) => {
    const { bgColor, textColor } = useContext(MainContext);
    return (
        groupDashboardList.map((data, index) => (
            <Col lg="4" xl="3" md="4" sm="6" key={index}>
                <Card className="dashboardListCard" style={handleIsActiveGroupDashboard(index)}>
                    <CardBody className="d-flex flex-column p-2">
                        <Row>
                            <Col>
                                <div className='groupItemContainer'>
                                    <div className='groupItemContainerIcon'>
                                        {data.isBI ? 
                                            <img alt="..." className='mr-2' style={{ height: '21px', width: '25px'}} src={require("assets/img/bi.png").default} />
                                            :
                                            <img alt="..." className='mr-2' src={require("assets/img/etanilogo.png").default} />
                                        }
                                    </div>
                                    <div className='groupItemContainerTitle'>
                                        <div className='groupItemName' style={{color: bgColor}}>{data.name}</div>
                                        <div className='groupName'>{data.groupName}</div>
                                    </div>
                                    {editMode &&
                                        <div className='deleteDashboardBtn'>
                                            <IconTrash  onClick={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                handleShowDeleteDashboardListConfirmation(data, index);
                                            }} />
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div 
                                    className="btn-showDetails"
                                    style={{ background: bgColor, color: textColor }}
                                    onClick={() => handleSelectGroupDashboard(data, index)}
                                    >View {!data.isBI ? 'Dashboard  Items' : 'Reports'}</div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        ))
    )
}
export default GroupDashboardList