import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Input, Container } from "reactstrap";
import { MainContext } from "context/mainContext";
import CustomHeader from "customComponents/customHeader";
import PanelCard from "components/Cards/PanelCard";
// import { EditorState } from "draft-js";
import AnnouncementCard from "components/Cards/AnnouncementCard";
import { dataFilterTitleOnly } from "utils/utilityFunctions";
import { useHistory } from "react-router";

const Announcements = ({ ...props }) => {
  const {
    userDetails,
    filteredAnnouncements,
    setFilteredAnnouncements,
    setSelectedClientID,
    firmDetails
  } = useContext(MainContext);
  const history = useHistory();
  const [announcementList , setAnnouncementList] = useState([])

  useEffect(() => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    console.log(filteredAnnouncements)
    setAnnouncementList(filteredAnnouncements)
  }, [filteredAnnouncements])

  const handleSearch = (e) => {
    let filterString = e.target.value;
    if(filterString.length > 0) setAnnouncementList(dataFilterTitleOnly(filterString, filteredAnnouncements));
    else setAnnouncementList(filteredAnnouncements);
  };

  return (
    <>
      <CustomHeader style={null} />
      <Container className="mt-6" fluid>
        <div className="nav-wrapper">
          <PanelCard headerName="Announcements">
            <Row>
              <Col className="my-3"></Col>
              <Col lg="4" sm="4" className="ml-auto mt-3">
                <div className="ml-auto">
                  <Input
                    placeholder="Search"
                    type="text"
                    bsSize="sm"
                    onChange={handleSearch}
                  />
                </div>
              </Col>
            </Row>
            {announcementList && announcementList?.length >= 1 ? (
              <Row className="my-3">
                {announcementList?.map((ann, i) => (
                  <AnnouncementCard
                    key={i}
                    Title={ann.title}
                    Text={`${ann.posted_by.FirstName} ${ann.posted_by.LastName}`}
                    id={ann._id}
                    data={ann}
                  />
                ))}
              </Row>
            ) : (
              <p className="text-center mt-3">No Announcements</p>
            )}
          </PanelCard>
        </div>
      </Container>
    </>
  );
};

export default Announcements;
