import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Container, Col, Row } from "reactstrap";
import CustomHeader from "customComponents/customHeader";
import { MainContext } from "context/mainContext";
import CustomPanelCard from "components/Cards/CustomPanelCard";
import ReactPlayer from "react-player";
import VideoJS from "./VideoJs";
import { videos } from "./videos";
import CustomSpinner from "components/Misc/CustomSpinner";
import CourseSessionListGroup from "./CourseSessionListGroup";
import parse from "html-react-parser";
import { useHistory } from "react-router";

const CourseContent = (props) => {
  const { textColor, bgColor, getProgressBarPercentage, userDetails } =
    useContext(MainContext);
  const history = useHistory();
  // const [userDetails, setUserDetails] = useState({});
  const [defaultModal, setdefaultModal] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoDetails, setVideoUrlDetails] = useState("");
  const [isChecked, setIsChecked] = useState(
    new Array(videos.length).fill(false)
  );
  const [totalChecked, setTotalChecked] = useState(0);
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [courseDestails, setCourseDetails] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [doneLectures, setDoneLectures] = useState();
  const [focusedLecture, setFocusedLecture] = useState();
  const [totalLectureLength, setTotalLectureLength] = useState(0);
  const [totalDoneLecture, setTotalDoneLecture] = useState(0);
  const [stopLecture, setStopLecture] = useState(false);
  const [courseURL, setCourseURL] = useState();

  const fetchAPIs = () => {
    let url = window.location.pathname.split("/");
    setCourseURL(url);
    // console.log(userDetails)
    setIsLoading(true);
    // get user details
    let courseID = null;
    // get course details
    fetch(`/api/course/showDetails/${url[url.length - 1]}`)
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error && data.Status !== 2) {
          // console.log("/api/course/showDetails", data);
          // console.log("progress", data.CourseProgress);
          courseID = data.id;
          let progress = data.CourseProgress;
          setCourseDetails(data);
          let lectureLists = lectures;
          // let isCheckedLists = isChecked
          // get course progress
          // get session per course
          fetch(`/api/courseSession/getRecordPerCourse/${data.id}`)
            .then((res) => res.json())
            .then((data) => {
              // console.log("SESSIONS", data);
              setSessions(data);
              let lec = [];
              data?.map((lecture) => {
                // let isCheckedLists = isChecked
                // get video per course session
                // console.log("after-SESSIONS", lecture);
                lecture.Video.map((vid) => {
                  vid.IsDone = false;
                  lec.push(vid);
                });

                // fetch(
                //   `/api/video/getRecordPerCourseSession/${lecture.Course}/${lecture.id}`
                // )
                //   .then((res) => res.json())
                //   .then((data) => {
                //     // let lectureLists = lectures
                //     // console.log("COURSE-LENGTH", data);
                //     data?.map((lectureResult) => {
                //       // console.log(lectureResult);
                //       lectureResult.IsDone = false;
                //       fetch(
                //         `/api/courseProgress/getProgressPerUserAndCourse/${lectureResult.Course}/${userDetails?.User?.UserID}`
                //       )
                //         .then((res) => res.json())
                //         .then((data) => {
                //           // console.log("PROGRESS", data);
                //           if (data && !data.error) {
                //             setDoneLectures(data);
                //             let doneLecturesLength = 0;
                //             let totalLectureLength = 0;

                //             data?.map((prog) => {
                //               if (
                //                 prog.Video === lectureResult.id &&
                //                 prog.IsDone === "true"
                //               ) {
                //                 lectureResult.IsDone = true;
                //               } else if (lectureResult.IsDone === true) {
                //                 lectureResult.IsDone = true;
                //               } else {
                //                 lectureResult.IsDone = false;
                //               }
                //               // isCheckedLists.push(lectureResult.IsDone)
                //               // console.log("CHECKLISTS", isCheckedLists)
                //               if (prog.IsDone === "true") {
                //                 doneLecturesLength += 1;
                //               }
                //             });
                //           }
                //         })
                //         .catch((err) => {
                //           console.log(err);
                //         });
                //       lectureLists.push(lectureResult);
                //       setLectures(lectureLists);
                //       // console.log(lectureLists);

                //       forceUpdate();
                //       setIsLoading(false);
                //       setVideoUrl(lectures[0].URL);
                //       setVideoUrlDetails(lectures[0]);
                //       handlePlay(
                //         "",
                //         lectures[0].URL,
                //         0,
                //         lectures[0].id,
                //         lectures[0]
                //       );
                //       // console.log("LECTURES", lectures[0])
                //     });
                //   })
                //   .catch((err) => {
                //     console.log(err);
                //   });
              });
              if (!data.error) {
                setIsLoading(false);
              }
              return lec;
            })
            .then((data) => {
              // console.log(data);
              data.map((lec) => {
                progress.map((prog) => {
                  if (prog.Video === lec.id && prog.IsDone === "true") {
                    lec.IsDone = true;
                  } else if (lec.IsDone === true) {
                    lec.IsDone = true;
                  } else {
                    lec.IsDone = false;
                  }
                  return lec;
                });
              });
              // console.log("progress.length", progress.length);
              // console.log("lecture.length", data.length);
              setTotalChecked(progress.length);
              setTotalLectureLength(data.length);
              setLectures(data);
              setVideoUrl(data[0].URL);
              setVideoUrlDetails(data[0]);
              // data.length > 0 && lectureLength > 0
              //   ? setTotalChecked(
              //       Math.round((data.length / lectureLength) * 100)
              //     )
              //   : setTotalChecked(0);
            })
            .catch((err) => {
              console.log(err);
            });
          fetch(`/api/video/getRecordPerCourse/${courseID}`)
            .then((res) => res.json())
            .then((data) => {
              // console.log("TOTAL LECTURES", data.length);
              setTotalLectureLength(data.length);
              let lectureLength = data.length;

              setTotalDoneLecture(progress.length);
              getProgressBarPercentage(progress.length, lectureLength);
              progress.length >= lectureLength
                ? setStopLecture(true)
                : setStopLecture(false);

              // fetch(
              //   `/api/courseProgress/getProgressPerUserAndCourse/${courseID}/${userDetails?.User?.UserID}`
              // )
              //   .then((res) => res.json())
              //   .then((data) => {
              //     console.log("PROGRESS-data", data);
              //     setTotalDoneLecture(data.length);
              //     getProgressBarPercentage(data.length, lectureLength);
              //     data.length >= lectureLength
              //       ? setStopLecture(true)
              //       : setStopLecture(false);
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });
              // getProgressBarPercentage(doneLectures.length, data.length)
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return history.replace("/client/traningvideos");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(userDetails);
    fetchAPIs();
  }, [userDetails, totalChecked]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      player.log("player is waiting");
    });

    player.on("dispose", () => {
      player.log("player will dispose");
    });
  };

  const countTotalChecked = (checkArray) => {
    let checked = [];
    checkArray.map((check) => {
      if (check === true) {
        checked.push(check);
      }
    });
    setTotalChecked(checked.length);
    getProgressBarPercentage(doneLectures, totalLectureLength);
  };

  const handlePlay = (
    modalTitle,
    videoUrl,
    index,
    lectureID,
    lectureDetails
  ) => {
    setVideoUrl(videoUrl);
    setVideoUrlDetails(lectureDetails);
    setFocusedIndex(index);
    setFocusedLecture(lectureID);
  };

  const closeModal = () => {
    setdefaultModal(false);
  };

  const handleCheckChange = (position, lectureID, event, index) => {
    // console.log(lectures);
    const updatedCheckedState = isChecked.map((item, index) =>
      // item === true ? item :
      index === position ? !item : item
    );

    let filteredLecture = lectures.filter(
      (lecture) => lecture.id === lectureID
    );

    if (!event && filteredLecture[0].IsDone === false) {
      filteredLecture[0].IsDone = true;
    }
    if (!event && filteredLecture[0].IsDone === true) {
      filteredLecture[0].IsDone = true;
    }
    if (event && filteredLecture[0].IsDone === true) {
      filteredLecture[0].IsDone = false;
    }
    if (event && filteredLecture[0].IsDone === false) {
      filteredLecture[0].IsDone = true;
    }
    // if (filteredLecture[0].IsDone === true) {
    //     filteredLecture[0].IsDone = false
    // } else {
    //     filteredLecture[0].IsDone = true
    // }

    fetch(`/api/courseProgress/getProgressPerVideo/${filteredLecture[0].id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          // console.log("DATA-EXIST", filteredLecture[0].IsDone)
          // if(index <= lectures.length){
          if (event && filteredLecture[0].IsDone === true) {
            fetch(`/api/courseProgress/deleteOneRecord/${data[0].ProgressID}`, {
              credentials: "include",
              method: "DELETE",
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.message === "Record has been deleted") {
                  filteredLecture[0].IsDone = false;
                  // console.log(data);
                  setTotalDoneLecture(totalDoneLecture - 1);
                  fetch(
                    `/api/course/showDetails/${courseURL[courseURL.length - 1]}`
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      if (data && !data.error && data.Status !== 2) {
                        getProgressBarPercentage(
                          data.CourseProgress.length,
                          totalLectureLength
                        );
                      }
                    });
                  // fetch(
                  //   `/api/courseProgress/getProgressPerUserAndCourse/${courseDestails.id}/${userDetails?.User?.UserID}`
                  // )
                  //   .then((res) => res.json())
                  //   .then((data) => {
                  //     console.log("PROGRESS", data);
                  //     getProgressBarPercentage(data.length, totalLectureLength);
                  //     setDoneLectures(data);
                  //     forceUpdate();
                  //   });
                }
              });
          }
          // else if(!event && filteredLecture[0].IsDone === false || event && filteredLecture[0].IsDone === false){
          //     filteredLecture[0].IsDone = true
          // }
          // }
        } else {
          // console.log("DATE-NOT EXISTS")
          fetch(`/api/courseProgress/createRecord`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
              Course: courseDestails.id,
              User: userDetails?.User?.UserID,
              Video: filteredLecture[0].id,
              IsDone: filteredLecture[0].IsDone,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log(data)
              if (data.message === "Record has been created") {
                // console.log("SUCCESS", data);
                // get course progress
                fetch(
                  `/api/course/showDetails/${courseURL[courseURL.length - 1]}`
                )
                  .then((res) => res.json())
                  .then((data) => {
                    if (data && !data.error && data.Status !== 2) {
                      getProgressBarPercentage(
                        data.CourseProgress.length,
                        totalLectureLength
                      );
                    }
                  });
                // fetch(
                //   `/api/courseProgress/getProgressPerUserAndCourse/${courseDestails.id}/${userDetails?.User?.UserID}`
                // )
                //   .then((res) => res.json())
                //   .then((data) => {
                //     console.log("PROGRESS", data);
                //     getProgressBarPercentage(data.length, totalLectureLength);
                //     setDoneLectures(data);
                //     forceUpdate();
                //   });
                // handleCheckChange("", data.record.Video)
              }
            });
        }
        // console.log('IF_EXISTS', data)
        // handlePlay("", lectures[0].URL, 0, lectures[0].id)
      });
    // console.log("focused", focusedIndex)
  };

  const playNext = (focusedIndex, focusedLecture) => {
    // updateCourseProgress()
    // if(totalDoneLecture > totalLectureLength) {
    // console.log("END", focusedIndex, focusedLecture)
    // } else {

    const nextIndex = focusedIndex + 1;
    if (nextIndex >= lectures.length) {
      setVideoUrl(lectures[nextIndex - 1].URL);
      // setFocusedIndex(0)
      // console.log("Length is equal")
      setFocusedIndex(nextIndex - 1);
      handleCheckChange("", lectures[nextIndex - 1].id, null, nextIndex - 1);
    } else {
      setVideoUrl(lectures[nextIndex].URL);
      setFocusedIndex(nextIndex);
      handleCheckChange("", focusedLecture);
      handlePlay(
        "",
        lectures[nextIndex].URL,
        nextIndex,
        lectures[nextIndex].id,
        lectures[nextIndex]
      );
    }
    // console.log("DONE-LECTURES", totalDoneLecture, totalLectureLength)
    // }
  };

  return (
    <>
      {/* {console.log(lectures)} */}
      <CustomHeader style={null} />
      <Container className="mt-5" fluid>
        <div className="mt-5"></div>
        <CustomPanelCard
          headerName={courseDestails.Name}
          totalChecked={totalChecked}
          totalLectureLength={totalLectureLength}
        >
          {isLoading ? (
            <CustomSpinner />
          ) : lectures && lectures.length <= 0 ? (
            <div className="col-sm-12 mt-3 text-center">
              <h5> No lectures available.</h5>
            </div>
          ) : (
            <Row>
              <Col sm="12" xl="8">
                {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
                <div style={{ position: "sticky", top: 80, zIndex: 1 }}>
                  <h3>{videoDetails?.Name}</h3>

                  <Row
                    style={{
                      position: "relative",
                      paddingTop: "56.25%",
                      background: "#FFFFFF",
                      margin: "auto",
                    }}
                  >
                    <ReactPlayer
                      className="react-player-custom"
                      style={{ position: "absolute", top: 0, right: 0 }}
                      url={videoUrl}
                      playing={stopLecture ? false : true}
                      controls
                      width="100%"
                      height="100%"
                      light={true}
                      stopOnUnmount={false}
                      playsinline={true}
                      pip={true}
                      // onEnded={() => updateCourseProgress(focusedIndex)}
                      onEnded={() =>
                        stopLecture
                          ? handleCheckChange("", focusedLecture)
                          : playNext(focusedIndex, focusedLecture)
                      }
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                        vimeo: {
                          playerOptions: {
                            autoplay: true,
                            autopause: true,
                            responsive: true,
                            loop: false,
                            keyboard: true,
                            pip: true,
                          },
                        },
                      }}
                    />
                  </Row>
                  <Row
                    className="mt-3"
                    style={{ position: "sticky", top: 0, zIndex: 1 }}
                  >
                    {/* {videoList()} */}
                    {/* <Courses /> */}
                    <Col>
                      <h3>About this course</h3>
                      {courseDestails.Description
                        ? parse(courseDestails.Description)
                        : ""}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm="12" xl="4">
                {isLoading ? (
                  <CustomSpinner />
                ) : sessions.length > 0 ? (
                  Object.keys(sessions).map((session, i) => (
                    <CourseSessionListGroup
                      session={sessions[session]}
                      i={i}
                      lectures={lectures}
                      handlePlay={handlePlay}
                      key={i}
                      handleCheckChange={handleCheckChange}
                      videoDetails={videoDetails}
                    />
                  ))
                ) : null}
              </Col>
            </Row>
          )}
        </CustomPanelCard>
      </Container>
    </>
  );
};

export default CourseContent;
