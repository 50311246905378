import CustomSpinner from 'components/Misc/CustomSpinner';
import AllUsersTable from 'components/Table/AllUsersTable';
import IntegrationsTable from 'components/Table/IntegrationsTable';
import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  Form,
  Input,
  FormGroup,
  Card,
  CardBody,
  ButtonGroup,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Media,
  CardFooter,
  UncontrolledDropdown,
} from "reactstrap";
import { dataFilterIntegrationsOnlyWithSelection, dataFilter } from 'utils/utilityFunctions';
import { MainContext } from "context/mainContext";
import moment from "moment";
import avatar from "../../../assets/img/blank-avatar.png";
import ReactPaginate from 'react-paginate';
import BlockUI from 'components/Misc/BlockUI';
import ReactBSAlert from "react-bootstrap-sweetalert";

var momentTZ = require("moment-timezone");


const IntegrationSchedule = ({ isLoadingAllIntegrations, allIntegrations, allIntegrationsFilteredData, setAllIntegrationsFilteredData, ...props }) => {
    const {
        bgColor,
        textColor,
        setContextClientLists,
        setSelectedClientID,
        selectedClientID,
        userDetails,
        firmDetails,
        getDetails,
        selectedClient,
        setSelectedClient,
        contextClientLists,
        setContextFilteredClientLists
    } = useContext(MainContext);
    const [isLoading, setIsLoading] = useState(true);
    // const [allIntegrations, setAllIntegrations] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [filterType, setFilterType] = useState("Integration")
    const [filterBy, setFilterBy] = useState("business")
    const inputRef = useRef(null);
    // const [defaultSelectedGroup, setDefaultSelectedGroup] = useState({ value: 'select-all', label: 'All Client' })
    // const [defaultSelectIntegration, setDefaultSelectIntegration] = useState({ value: 'select-integration-all', label: 'All Integration' })
    // const [defaultSelectIntegrationName, setDefaultSelectIntegrationName] = useState({ value: 'select-integration-name-all', label: 'All Integration' })
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState(null)
    const [defaultSelectIntegration, setDefaultSelectIntegration] = useState(null)
    const [defaultSelectIntegrationName, setDefaultSelectIntegrationName] = useState(null)
    const [clientList, setClientList] = useState([])
    const [integrationList, setIntegrationList] = useState([])
    const [integrationNameList, setIntegrationNameList] = useState([])
    const [integrationDestinationList, setIntegrationDestinationList] = useState([])
    const [selectedClientList, setSelectedClientList] = useState([]);
    const [selectedDestinationList, setSelectedDestination] = useState([]);
    const [selectedIntegrationList, setSelectedIntegrationList] = useState([]);
    const [selectedIntegrationNameList, setSelectedIntegrationNameList] = useState([]);
    const [allIntegrationList, setAllIntegration] = useState(allIntegrationsFilteredData)
    const [firstRefreshTime, setFirstRefreshTime] = useState(moment("00:00", "HH:mm"));
    const [secondRefreshTime, setSecondRefreshTime] = useState(moment("12:00", "HH:mm"));
    const [selectAllIntegration, setSelectAllIntegration] = useState([])
    const [isSelectAllIntegration, setIsSelectAllIntegration] = useState(false)
    const [selectedIntegration, setSetSelectedIntegration] = useState([])
    const [alert, setAlert] = useState(null);
    const [showBlockUI, setShowBlockUI] = useState(false);

    useEffect(async () => {
        console.log(allIntegrationList)
    }, [])

    useEffect(() => {
        const clients = allIntegrationsFilteredData.map(item => ({ value: item.Client.id, label: item.Client.Name }))
        .filter((item, index, self) =>
          index === self.findIndex((t) => t.label === item.label)
        );
        setClientList([{ value: 'select-all', label: 'All Client' }, ...clients]);

        const dbDestination = allIntegrationList.map(item => ({ value: item.id, label: item.Details.dbDestination ?? "Old SQL" }))
        .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setIntegrationDestinationList([{ value: 'select-DB-Destination-all', label: 'All DB Destination' }, ...dbDestination])

        const integration = allIntegrationList.map(item => ({ value: item.id, label: item.Service.Name }))
        .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setIntegrationList([{ value: 'select-integration-all', label: 'All Integration' }, ...integration])

        const integrationName = allIntegrationList.map(item => ({ value: item.id, 
                                                                            label:  item.Details.Organisation ||
                                                                            item.Details.Name ||
                                                                            item.Details.name ||
                                                                            (item.Status === 9 ? "Provisioning" : "Dataset Active") }))

        .filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setIntegrationNameList([{ value: 'select-integration-name-all', label: 'All Integration' }, ...integrationName])
    },[allIntegrationList])

    useEffect(() => {
        const filterIntegrations = () => {
            let filteredData = allIntegrationsFilteredData;
    
            if (selectedClientList.length > 0) {
                filteredData = filteredData.filter(item => selectedClientList.includes(item.Client.id) && !selectedIntegration.includes(item.id));
            }

            if (selectedDestinationList.length > 0) {
                console.log(selectedDestinationList)
                console.log(filteredData)
                filteredData = filteredData.filter(item => selectedDestinationList.includes(item.Details.dbDestination ?? "Old SQL") && !selectedIntegration.includes(item.id));
            }
    
            if (selectedIntegrationList.length > 0) {
                filteredData = filteredData.filter(item => selectedIntegrationList.includes(item.Service.Name) && !selectedIntegration.includes(item.id));
            }
            
            if (selectedIntegrationNameList.length > 0) {
                filteredData = filteredData.filter(item => {
                    const label = item.Details.Organisation ||
                                  item.Details.Name ||
                                  item.Details.name ||
                                  (item.Status === 9 ? "Provisioning" : "Dataset Active");
                    return selectedIntegrationNameList.includes(label) && !selectedIntegration.includes(item.id);
                });
            }

            if (selectedIntegration.length > 0 && (selectedClientList.length > 0 || selectedIntegrationList.length > 0 || selectedIntegrationNameList.length > 0)) {
                const selectedIntegrationFilteredData = allIntegrationsFilteredData.filter(item => selectedIntegration.includes(item.id));
                filteredData = [...selectedIntegrationFilteredData, ...filteredData]
            }
    
            return filteredData;
        };

        const newData = filterIntegrations();   

        setAllIntegration(newData);
    }, [selectedClientList, selectedIntegrationList, selectedIntegrationNameList, selectedDestinationList]);
    
    const handleSelectclient = (value) => {
        if (value.some(option => option.value === 'select-all')) {
          const allOptions = clientList.filter(option => option.value !== 'select-all');
          setSelectedClientList(allOptions.map(option => option.value));
        } else {
          const selectedGroupId = value.map(option => option.value);
          setSelectedClientList(selectedGroupId);
        }
    };
    const handleSelectDestination = (value) => {
        if (value.some(option => option.value === 'select-DB-Destination-all')) {
          const allOptions = clientList.filter(option => option.value !== 'select-DB-Destination-all');
          console.log(allOptions)
          setSelectedDestination(allOptions.map(option => option.label));
        } else {
          const selectedGroupId = value.map(option => option.label);
          setSelectedDestination(selectedGroupId);
        }
    };
    const handleSelectIntegration = (value) => {
        if (value.some(option => option.value === 'select-integration-all')) {
          const allOptions = integrationList.filter(option => option.value !== 'select-integration-all');
          setSelectedIntegrationList(allOptions.map(option => option.label));
        } else {
          const selectedGroupId = value.map(option => option.label);
          setSelectedIntegrationList(selectedGroupId);
        }
    };
    const handleSelectIntegrationName = (value) => {
        if (value.some(option => option.value === 'select-integration-name-all')) {
          const allOptions = integrationNameList.filter(option => option.value !== 'select-integration-name-all');
          setSelectedIntegrationNameList(allOptions.map(option => option.label));
        } else {
          const selectedGroupId = value.map(option => option.label);
          setSelectedIntegrationNameList(selectedGroupId);
        }
    };
    const handleTimeChange = (time) => {
        setFirstRefreshTime(time);
        // console.log("Selected Time:", time.format("HH:mm"));
    };
    const handleSecondTimeChange = (time) => {
        setSecondRefreshTime(time);
    };
    const handleSelectAllIntegration = () => {
        if (isSelectAllIntegration) {
            setSetSelectedIntegration([]);
        } else {
            setSetSelectedIntegration(allIntegrationList.map((item) => item.id));
        }
        setIsSelectAllIntegration(!isSelectAllIntegration);
    }
    const toggleSelected = (id) => {
        setSetSelectedIntegration((prevSelectedIntegration) => {
            prevSelectedIntegration = prevSelectedIntegration || [];
            if (prevSelectedIntegration.includes(id)) {
                return prevSelectedIntegration.filter((itemId) => itemId !== id);
            } else {
                return [...prevSelectedIntegration, id];
            }
        });
    };
    const handleConfirmSave = () => {
        
        if (selectedIntegration.length > 0) {
            if (secondRefreshTime.isBefore(firstRefreshTime)) {
                warningAlert({message: "Second refresh time must be later than the first refresh time."});
                return;
            }

            const minimumInterval = 6; // in hours
            const timeDifference = secondRefreshTime.diff(firstRefreshTime, "hours");

            if (timeDifference < minimumInterval) {
                warningAlert({message: `The interval between the first and second refresh must be at least ${minimumInterval} hours.`});
                return;
            }

            confirmSaving({message: "Are you sure you want to change the schedule?"})
        } else {
            warningAlert({message: "No integrations selected!"});
        }
    };
    const handleSaveChanges = () => {
        try {
            setAlert(null)
            let filteredData = allIntegrationsFilteredData;
            filteredData = allIntegrationsFilteredData.filter(item => selectedIntegration.includes(item.id));
            const updatedData = filteredData.map(item => ({
                ...item,
                ScheduledRefresh: {
                    firstRefreshTime: firstRefreshTime.format("HH:mm"),
                    secondRefreshTime: secondRefreshTime.format("HH:mm")
                }
            }));
    
            console.log(updatedData);
            successAlert({message: "New schedule saved successfully!"})
        } catch (err) {
            errorAlert({message: "Something went wrong while saving your changes, please try again later!"});
        }
    }
    const errorAlert = (response) => {
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
          >
            {response.message ||
              response.error ||
              response.Details.warning ||
              response}
          </ReactBSAlert>
        );
    };
    const confirmSaving = (response) => {
        setAlert(
          <ReactBSAlert
            info
            title="Info"
            onConfirm={() => handleSaveChanges()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Confirm"
            onCancel={() => setAlert(null)}
            showCancel
          >
            {response.message ||
              response.error ||
              response.Details.warning ||
              response}
          </ReactBSAlert>
        );
    };
    const warningAlert = (response) => {
        setAlert(
          <ReactBSAlert
            warning
            title="Warning"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
          >
            {response.message ||
              response.error ||
              response.Details.warning ||
              response}
          </ReactBSAlert>
        );
    };
    const successAlert = (response) => {
        setAlert(
          <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
              setAlert(null);
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            {response.message}
          </ReactBSAlert>
        );
    };

    return (
        <>
        {alert}
        {showBlockUI ? <BlockUI /> : null}
            <Row>
                <Col sm="12">
                <FormGroup>
                    <h2>Filter Integration by:</h2>
                        <Row>
                            <Col xl="4" xs="12">
                                <FormGroup>
                                    <Label 
                                        htmlFor="clientList" 
                                        style={{ 
                                            fontWeight: 'bold', 
                                            fontSize: 14, 
                                            marginBottom: 7
                                        }}
                                    >Client List</Label>
                                    <Select
                                        defaultValue={defaultSelectedGroup}
                                        isMulti
                                        name="colors"
                                        options={clientList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleSelectclient}
                                    />
                                    <Label 
                                        htmlFor="clientList" 
                                        style={{ 
                                            fontWeight: 'bold', 
                                            fontSize: 14, 
                                            marginBottom: 7
                                        }}
                                    >Database Destination</Label>
                                    <Select
                                        defaultValue={defaultSelectedGroup}
                                        isMulti
                                        name="colors"
                                        options={integrationDestinationList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleSelectDestination}
                                    />
                                    <Label 
                                        htmlFor="clientList" 
                                        style={{ 
                                            fontWeight: 'bold', 
                                            fontSize: 14, 
                                            marginBottom: 7
                                        }}
                                    >Integration Type</Label>
                                    <Select
                                        defaultValue={defaultSelectIntegration}
                                        isMulti
                                        name="colors"
                                        options={integrationList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleSelectIntegration}
                                    />
                                    <Label 
                                        htmlFor="clientList" 
                                        style={{ 
                                            fontWeight: 'bold', 
                                            fontSize: 14, 
                                            marginBottom: 7
                                        }}
                                    >Integration Name</Label>
                                    <Select
                                        defaultValue={defaultSelectIntegrationName}
                                        isMulti
                                        name="colors"
                                        options={integrationNameList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleSelectIntegrationName}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl="4" xs="12">
                                <FormGroup>
                                    <Label 
                                            htmlFor="clientList" 
                                            style={{ 
                                                fontWeight: 'bold', 
                                                fontSize: 14, 
                                                marginBottom: 7
                                            }}
                                        >First Refresh Schedule</Label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-time-alarm" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            dateFormat={false}
                                            timeFormat="HH:mm"
                                            inputProps={{ placeholder: "Select Time" }}
                                            value={firstRefreshTime}
                                            onChange={handleTimeChange}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label 
                                        htmlFor="clientList" 
                                        style={{ 
                                            fontWeight: 'bold', 
                                            fontSize: 14, 
                                            marginBottom: 7
                                        }}
                                    >Second Refresh Schedule</Label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-time-alarm" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            dateFormat={false}
                                            timeFormat="HH:mm"
                                            inputProps={{ placeholder: "Select Time" }}
                                            value={secondRefreshTime}
                                            onChange={handleSecondTimeChange}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <Button 
                                            color="primary" 
                                            size="sm"
                                            onClick={handleConfirmSave} 
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Table style={{ marginBottom: 15 }}>
                        <thead className="thead-light integrationTableHead">
                            <tr>
                                <th>
                                    <Button color="primary" onClick={handleSelectAllIntegration} style={{ padding: '5px', fontSize: '10px', background: bgColor }}>
                                        {isSelectAllIntegration ? 'Deselect All' : 'Select All'}
                                    </Button>
                                </th>
                                <th className="">
                                    Integration Logo
                                </th>
                                <th className="">
                                    Integration Type
                                </th>
                                <th className="">
                                    Name
                                </th>
                                <th className="">
                                    DB Destination
                                </th>
                                <th className="">
                                    Client
                                </th>
                                <th className="">
                                    First Refresh Schedule
                                </th>
                                <th className="">
                                    Second Refresh Schedule
                                </th>
                            </tr>
                        </thead>
                        <tbody className='integrationTableBody'>
                            {allIntegrationList.map((item, i) => (
                                <tr key={i} 
                                    onClick={() => toggleSelected(item.id)} 
                                    className={`${selectedIntegration && selectedIntegration.includes(item.id) && 'selectedIntegration'}`}
                                    style={{ maxHeight: 60 }}>
                                    <td className='tdCheckContent'>
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                id={`checkbox-${item.id}`}
                                                className="custom-checkbox"
                                                checked={selectedIntegration && selectedIntegration.includes(item.id)}
                                                onChange={() => toggleSelected(item.id)}
                                            />
                                            <Label
                                                htmlFor={`checkbox-${item.id}`}
                                                className="custom-checkbox-label"
                                            />
                                        </FormGroup>
                                    </td>
                                    <td className="">
                                        <Media className="align-items-center">
                                            <img
                                            // className="rounded mr-3"
                                            className="rounded"
                                            alt="..."
                                            src={
                                                item.Service.Logo
                                                ? item.Service.Logo
                                                : avatar
                                            }
                                            style={{
                                                width: "50px",
                                            }}
                                            />
                                        </Media>
                                    </td>
                                    <td className="">
                                        {item.Service.Name}
                                    </td>
                                    <td className="">
                                        {
                                            item.Details.Organisation ||
                                            item.Details.Name ||
                                            item.Details.name ||
                                            (item.Status === 9 ? "Provisioning" : "Dataset Active")
                                        }
                                    </td>
                                    <td className="">
                                        {item.Details.dbDestination ?? "Old SQL"}
                                    </td>
                                    <td className="">
                                        {item.Client.Name}
                                    </td>
                                    <td style={{ textAlign: 'end'}}>
                                        {item.FirstSchedule ? item.FirstSchedule : moment("00:00", "HH:mm").format("HH:mm")}
                                    </td>
                                    <td style={{ textAlign: 'end'}}>
                                        {item.SecondSchedule ? item.SecondSchedule : moment("12:00", "HH:mm").format("HH:mm")}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <CardFooter className="py-4">
                        {allIntegrationList && allIntegrationList.length >= 1 ? null : (
                            <p className="text-center mt-3">No Integrations found.</p>
                        )}
                        {/* <Row>
                            <Col>
                            <UncontrolledDropdown direction="down" size="sm">
                                <DropdownToggle
                                caret
                                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                                >
                                {`${numberPerPage}`}
                                </DropdownToggle>
                                <DropdownMenu>
                                <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                                    {`5 `}
                                </DropdownItem>
                                <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                                    {`15 `}
                                </DropdownItem>
                                <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                                    {`30 `}
                                </DropdownItem>
                                <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                                    {`50 `}
                                </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </Col>
                            <Col>
                            <ReactPaginate
                                previousLabel={<i className="fas fa-angle-left" />}
                                nextLabel={<i className="fas fa-angle-right" />}
                                pageCount={pageCount()}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                            </Col>
                        </Row> */}
                        </CardFooter>
                </Col>
            </Row>
        </>
    )
}

export default IntegrationSchedule;