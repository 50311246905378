import CustomTooltip from "customComponents/customComponents";
import React, { useState } from "react";
import {
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Modal,
  Row,
} from "reactstrap";

const ServiceList = ({ data, ...props }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const onOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <Col sm="12" lg="4" key={props.i}>
      <Card className="p-3 zoom">
        <CardBody className="text-justify">
          <div style={{ position: "absolute", right: "30px", top: "10px" }}>
            <Row>
              <Col className="px-1">
                <CustomTooltip
                  i={props.i}
                  id={`add${props.i}`}
                  message="Update"
                  className="fas fa-pen fa-xs"
                  // className="fas fa-pen-alt"
                  onClick={() => props.openModal(data)}
                />
                {/* <i
                  className="ni ni-fat-add"
                  id={`add${props.i}`}
                  onClick={() => props.openModal(data)}
                ></i> */}
                {/* <a
                  href="#"
                  id={`add${props.i}`}
                  onClick={() => {
                    props.openModal(data);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                  </svg>
                </a> */}
                {/* <UncontrolledTooltip
                  placement="top"
                  style={{ fontSize: "smaller", padding: "10px" }}
                  delay={0}
                  target={`add${props.i}`}
                >
                  Update
                </UncontrolledTooltip> */}
              </Col>
              <Col className="p-0">
                <CustomTooltip
                  i={props.i}
                  id={`delete${props.i}`}
                  message="Delete"
                  className="fas fa-times fa-sm"
                  onClick={() => props.confirmDelete(data)}
                />
              </Col>
            </Row>
          </div>
          <div>
            <CardTitle
              className="border-bottom p-2 text-center"
              style={{ overFlow: "hidden" }}
            >
              {data.Name}
            </CardTitle>
            <CardText
              style={{
                height: "150px",
                display: "grid",
                overFlow: "hidden",
                alignItems: "center",
                position: "relative",
              }}
              className="text-center"
            >
              <span
                style={
                  props.isReadMore
                    ? { position: "absolute", background: "#fff" }
                    : { position: "relative" }
                }
              >
                {data.Description.slice(0, 90)}
                {data.Description.length > 90 ? (
                  <span
                    onClick={() => onOpenDetails()}
                    style={{ cursor: "pointer", fontSize: ".7rem" }}
                    className="text-muted mt--3 ml-2 text-underline font-weight-500"
                  >
                    ...read more
                  </span>
                ) : null}
              </span>
            </CardText>
          </div>
        </CardBody>
      </Card>
      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={openDetails}
        key={data.id}
        toggle={() => onOpenDetails()}
      >
        <div className="modal-body pb-0">
          <i
            onClick={() => onOpenDetails()}
            className="fas fa-times fa-sm"
            style={{ position: "absolute", top: 20, right: 20 }}
          />
          <div className="modal-title">{data.Name}</div>
          <p className="text-justify mt-3">{data.Description}</p>
        </div>
      </Modal>
    </Col>
  );
};

export default ServiceList;
