import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Row, Col, Button, Form } from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";

const DropdownSelectClient = ({ ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  const [isClearable] = useState(true);
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();
  const history = useHistory();

  useEffect(() => {
    let optionList = [];
    props.options?.map((opt) => {
      return optionList.push({
        value: opt.ClientID,
        label: opt.Name || opt.FirstName,
      });
    });
    setOptions(optionList);
  }, [props.options]);

  const handleSearch = () => {
    if (selected) {
      return history.push(`/client/${props.page}/${selected.value}`);
    }
    return;
  };

  const handleChange = (selected) => {
    setSelected(selected);
  };

  return (
    <>
      <Form>
        <Row className="row-cols-lg-auto g-3 align-items-center">
          <Col lg="11" sm="11" xs="11" style={{ paddingRight: 0 }}>
            <div style={{ width: "99%" }}>
              <Select
                options={options}
                isClearable={isClearable}
                onChange={handleChange}
                placeholder={`Select Client`}
              />
            </div>
          </Col>
          <Col lg="1" sm="1" xs="1" style={{ paddingLeft: 0 }} className="">
            <Button
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                padding: "8px",
              }}
              size="sm"
              type="button"
              onClick={() => handleSearch()}
              id="selectClient"
              href={`/client/${
                props.page === "users"
                  ? "users"
                  : props.page === "advisers"
                  ? "advisers"
                  : props.page
              }/${selected?.value}`}
              disabled={selected ? false : true}
            >
              Go
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DropdownSelectClient;
