import { IconEdit } from 'chat-components/ui/icon';
import { IconTrash } from 'chat-components/ui/icon';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import { Shield } from 'eva-component/ui/icon';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { evaThemeLighter } from 'services/EVAResponseService';
import DashboardTemplateAccessSetting from './DashboardTemplateAccessSetting';

function GroupDashboardTemplateList({ editMode, loading, groupDashboardTemplatesList, handleActiveDasboard, showDeleteTemplateConfirmation, 
    handleSelectTemplate, handleTemplateEditModal, handleAddtoFavorite, dashboardUpdate, groupList }) {
    const [showAccessModal, setShowAccessModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    const onHandleSetFavorite = (index) => {
        const param = { index, groupDashboardTemplatesList, from: 'personal' }
        handleAddtoFavorite(param)
    }
    const showAccessSettingModal = (data, index) => {
        
        setSelectedTemplate(data)
        setShowAccessModal(!showAccessModal)
    }
    useEffect(() => {
        console.log(groupDashboardTemplatesList)
    },[dashboardUpdate, groupDashboardTemplatesList])
    const getGroupName = (id) => {
        const name = groupList.filter(item => item._id === id)
        if(name.length > 0)
            return name[0].GroupName
        else 
            return ""
    }
    return (
        !selectedClientID ? 
            <Row className="text-center d-flex align-items-center justify-content-center">
                Please choose a client first.
            </Row>
            :
            <>
                {showAccessModal && 
                    <DashboardTemplateAccessSetting 
                        selectedTemplate={selectedTemplate}
                        showAccessModal={showAccessModal}
                        showAccessSettingModal={showAccessSettingModal}
                        firmDetails={firmDetails}
                        selectedClient={selectedClient} />
                }

                {loading ? <CustomSpinner />
                :
                <div className='editDashboardContainer'>
                    {groupDashboardTemplatesList && groupDashboardTemplatesList.map((data, index) => (
                        // data.dashboardTemplate && data.dashboardTemplate !== undefined && 
                        // data.dashboardTemplate.length > 0 &&
                        <div className='editDashboardItems' key={index}>
                            <div className="dashboardListCard" style={handleActiveDasboard(index)}>
                                <div className='dashboardFavorite'>
                                    <div></div>
                                    {editMode &&
                                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                                            {data.dashboardTemplate !== undefined &&
                                            <div  
                                                className='templateBtn' 
                                                style={{ marginTop: -5, cursor: 'pointer', height: 25, marginTop: 1 }} 
                                                onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleTemplateEditModal(data, index);
                                                    }} >
                                                <IconEdit />
                                            </div>}
                                            <div style={{ cursor: 'pointer'}}>
                                                <IconTrash 
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        showDeleteTemplateConfirmation(data, index, true);
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <div className='groupItemContainer'>
                                        <div className='groupItemContainerIcon'>
                                            {data.isBI ? 
                                                <img alt="..." className='mr-2' style={{ height: '21px', width: '25px'}} src={require("assets/img/bi.png").default} />
                                                :
                                                <img alt="..." className='mr-2' src={require("assets/img/etanilogo.png").default} />
                                            }
                                        </div>
                                        <div className='groupItemContainerTitle'>
                                            <div className='groupItemName' style={{color: bgColor}}>{data.name ? data.name : data.groupName}</div>
                                            {data.dashboardTemplate !== undefined ?
                                                <div className='groupName'>Group Template</div>
                                            :
                                                <div className='groupName'>{getGroupName(data.groupId)}</div>
                                            }
                                        </div>
                                    </div>
                                    <div 
                                        className="btn-showDetails"
                                        style={{ ...evaThemeLighter(100, theme), color: textColor }}
                                        onClick={() => handleSelectTemplate(data, index)}
                                        // >View Template Reports</div>
                                        >View
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                }
            </>
        )
}

export default GroupDashboardTemplateList