import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import { CustomToggleField } from "customComponents/Form";
import avatar from "../../assets/img/blank-avatar.png";

const AllUsersTable = ({
  data,
  warningAlert,
  handleEditClient,
  userDetails,
  handleShowEnablePCEModal,
  ...props
}) => {
  const { setSelectedClientID, setSelectedClient, contextClientLists } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    setPaginatedData(data);
    // Get clientListNumberPerPage
    localStorage.getItem("clientListNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("clientListNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage, paginatedData, data]);

  const userFullName = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].FirstName} ${clientUserAdmin[0].LastName}`;
    }
    return "N/A";
  };

  const userEmail = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].Email}`;
    }
    return "N/A";
  };

  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  const currentPageData = () => {
    return paginatedData
      .slice(offset, Number(offset) + Number(numberPerPage))
      .map((user, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }} className="all-users-row" onClick={() => changeUrl("thirdpartyapplication", user)}>
            <td className="logo">
              <Media className="align-items-center">
                <img
                  className="rounded mr-3" //Edited by Joef: removed avatar
                  alt="..."
                  src={
                    user.Avatar
                      ? user.Avatar
                      : avatar
                  }
                  style={{
                    //Added by Joef: instead of avatar to retain dimension
                    width: "60px",
                  }}
                />
              </Media>
            </td>
            <td className="name">
              {`${user.FirstName} ${user.LastName}`}
            </td>
            <td className="pbi">
              {user.UserAccess === 1
                ? "System Super Admin"
                : user.UserAccess === 2
                  ? "System Admin"
                  : user.UserAccess === 3
                    ? "Super Admin"
                    : user.UserAccess === 4
                      ? "Admin"
                      : user.UserAccess === 5
                        ? "Super User"
                        : user.UserAccess === 6
                          ? "User"
                          : null}
            </td>
            <td className="eva">
              {user.Email}
            </td>
            <td className="chat">
              {user.Status === 1
                ? "Active"
                : user.Status === 3
                  ? "Waiting for confirmation"
                  : ""}
            </td>
            <td className="eva">
              {
                user.Client.length && user.Client.map(cl => (
                  <span>{`${cl.Name}${user.Client.length > 1 ? ', ' : ""}`}</span>
                ))
              }
            </td>
          </tr>
        );
      });
  };

  const pageCount = () => {
    return Math.ceil(data.length / numberPerPage);
  };

  const getUserClients = (clients) => {
    for (let i = 0; i < clients.length; i++) {
      return clients[i].Name
    }
  }

  const changeUrl = (page, user) => {
    // console.log(user)
    // sessionStorage.setItem("selectedClientID", clientID);
    // let newSelectedClient = contextClientLists.filter(c => c.ClientID === clientID)
    // // console.log('newSelectedClient', newSelectedClient)
    // setSelectedClient(newSelectedClient[0])
    // if (page === "thirdpartyapplication") {
    //   setSelectedClientID(clientID);
    //   return history.push(`/client/thirdpartyapplication/${clientID}`);
    // }
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th className="">
                  Avatar
                </th>
                <th className="">
                  Name
                </th>
                <th className="">
                  User Access
                </th>
                <th className="">
                  Email
                </th>
                <th className="">
                  Status
                </th>
                <th className="">
                  {`Client(s)`}
                </th>
                {/* <th className="">
                  Admin Email
                </th>
                <th scope="col">Actions</th> */}
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        <CardFooter className="py-4">
          {data && data.length >= 1 ? null : (
            <p className="text-center mt-3">No Users found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default AllUsersTable;
