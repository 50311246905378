import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

function EVAAnswer({ answer }) {
    const [showMore, setShowMore] = useState(false);
    useEffect(() => {
        // const  strAnswer = answer.split("`nl`").map((line, index) => <div key={index}>{line.replace(/\[/g, "<b>").replace(/\]/g, "</b>")}</div>)
        // console.log(strAnswer)
    }, [answer]);

    const ReformatLine = ({ line }) => {
        let formattedLine = line;

        if (line.includes("####")) {
            formattedLine = `<h3>${line.replace("####", '').trim()}</h3>`;
        } else if (line.includes("###")) {
            formattedLine = `<h2>${line.replace("###", '').trim()}</h2>`;
        } else if (line.includes("- **")) {
            formattedLine = line.replace(/- *\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        } else if (line.includes("**")) {
            formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        }

        return <span dangerouslySetInnerHTML={{ __html: formattedLine }} />;
    };

    const lines = answer ? answer.split("`nl`") : [];
    const displayedLines = showMore ? lines : lines.slice(0, 2);

    return (
        <div>
             {displayedLines.map((line, index) => (
                <div key={index}>
                    <ReformatLine line={line} />
                </div>
            ))}

            {lines.length > 2 && (
                <div style={{ display: 'flex', padding: '10px 0px' }}>
                    <Button color="primary" size="sm" outline onClick={() => setShowMore(!showMore)}>
                        {showMore ? "See less" : "See more"}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default EVAAnswer;
