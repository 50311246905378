import { CustomModalButton } from 'components/Button/CustomButton'
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Modal, Row } from 'reactstrap'

function DashboardUserWithAccess({ dashboardUserWithAccess, bgColor, textColor }) {
    const [showUserList, setShowUserList] = useState(false)
    const userAccess = [
        'Super Admin',
        'Super Admin',
        'Advisor Admin',
        'Advisor',
        'Client User Admin',
        'Client User'
    ]
    const toggleShowUserWithAccess = () => {
        setShowUserList(!showUserList)
    }
    const GetUserAccess = (val) => {
        return userAccess[val-1]
    }
    return (
        <>
            {showUserList ? 
                <>
                    {/* <div
                        style={{
                            position: "fixed",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                            zIndex: 9998
                        }}
                        onClick={toggleShowUserWithAccess}
                    /> */}
                     
                    {/* <div className='dashboardGroupUsersContainer' onClick={toggleShowUserWithAccess}>
                        <div className='dashboardGroupUsersheader'>Users with access to this dashboard</div>
                        <div className='dashboardGroupUsersContent'>
                            {dashboardUserWithAccess && dashboardUserWithAccess.map((user) => (
                                <div className='dashboardGroupUsersItem'>
                                    <div  className='dashboardGroupUsersImg'> 
                                        <img
                                            alt={`${user.FirstName} ${user.LastName}`}
                                            className={`navbar-brand-img default-logo-size`}
                                            src={user.Avatar ? user.Avatar : `https://ui-avatars.com/api/?background=random&&name=${user.FirstName}`}
                                            />
                                    </div>
                                    <div className='dashboardGroupUsersDetails'>
                                        <div  className='dashboardGroupUsersName'>{`${user.FirstName} ${user.LastName}`}</div>
                                        <div className='dashboardGroupUsersAccess'>{GetUserAccess(user.userAccess)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                    <Modal
                        size="xl"
                        className="modal-dialog"
                        isOpen={showUserList}
                    // toggle={props.toggle}
                    >
                        <div className="modal-body">
                        <h5 className="modal-title mb-3">Users with access to this dashboard</h5>
                        <Row className="">
                            {dashboardUserWithAccess && dashboardUserWithAccess.map((user, i) => {
                            return (
                                <Col md={4} key={i} className="px-1">
                                <Card
                                    className="mb-2"
                                    style={{ cursor: "pointer" }}
                                >
                                    <CardBody>
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                        <div
                                            className="avatar  rounded-circle"
                                        // onClick={(e) => e.preventDefault()}
                                        >
                                             <img
                                                alt={`${user.FirstName} ${user.LastName}`}
                                                className={`navbar-brand-img default-logo-size`}
                                                src={user.Avatar ? user.Avatar : `https://ui-avatars.com/api/?background=random&&name=${user.FirstName}`}
                                            />
                                        </div>
                                        </Col>
                                        <div className="col ml--2">
                                        <h4 className="mb-0">
                                            {`${user.FirstName} ${user.LastName}`}
                                        </h4>
                                        <span>
                                            {`${user.UserAccess <= 4 ? "Advisor" : "Client"
                                            }`}
                                        </span>
                                        </div>
                                    </Row>
                                    </CardBody>
                                </Card>
                                </Col>
                            );
                            })}
                        </Row>
                            <CustomModalButton
                                submitButton={false}
                                closeButton={() => {
                                    toggleShowUserWithAccess();
                                }}
                                cancelText="Close"
                            />
                        </div>
                    </Modal>
                </>
            :
                <>
                    <div
                        className='showUserList'
                        style={{ background: bgColor}}
                        onClick={toggleShowUserWithAccess}
                    >
                        <i className={`fas fa-user-alt`} style={{ color: textColor}} />
                    </div>
                </>
            }
        </>
    )
}

export default DashboardUserWithAccess