import React, { useState } from "react";
import Resizer from "react-image-file-resizer";

function ImageResizerComponent({ ...props }) {
  const [resizedImageSrc, setResizedImageSrc] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    props.handleAzureImgChangeLogo(file)
    if (file) {
      Resizer.imageFileResizer(
        file,
        500, // maxWidth
        500, // maxHeight
        "PNG", // compressFormat
        90, // quality
        0, // rotation
        (uri) => {
          setResizedImageSrc(uri);
          props.setImage(uri);
        },
        "base64" // outputType
      );
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageUpload} className="custom-file-input" />
      <img src={resizedImageSrc ? resizedImageSrc : props.oldImage ? props.oldImage : require("assets/img/blank-avatar.png").default} alt="Resized preview"
        style={{
          width: 'auto',
          height: '75px',
          borderRadius: '0.375rem'
        }} />
    </div>
  );
}

export default ImageResizerComponent;
