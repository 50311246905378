import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { Spinner, Modal, Button, } from "reactstrap";
import "./styles.css";
import { MainContext } from "context/mainContext";

const MultiFactorPage = () => {
  const { firmDetails } = useContext(MainContext)
  const routerHistory = useHistory();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState("");
  const [updatedData, setUpdateData] = useState("");
  const [passCode, setPassCode] = useState("");
  const [mfaTextbox, setMfaTextbox] = useState("");
  const [message, setMessage] = useState("");
  const [accountingLogo, setAccountingLogo] = useState("");
  const [defaultScreen, setDefaultScreen] = useState(true);
  const [backEmailScreen, setBackEmailScreen] = useState(false);
  const [securityQuestionsScreen, setSecurityQuestionsScreen] = useState(false);
  const [authCodeBackupEmail, setAuthCodeBackupEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageBackupEmailConfirm, setMessageBackupEmailConfirm] =
    useState("");
  const [modalSecurityQuestion, setModalSecurityQuestion] = useState(false);
  const [messageSecurityQuestion, setMessageSecurityQuestion] = useState("");
  const [btnLoading, setBtnLoading] = useState(false)
  const [resetCooldown, setResetCooldown] = useState(30)
  const [emailResetCooldown, setEmailResetCooldown] = useState(30)
  const [isAns1Valid, setIsAns1Valid] = useState(true)
  const [isAns2Valid, setIsAns2Valid] = useState(true)

  const answerSecQuestionOne = useRef(null);
  const answerSecQuestionTwo = useRef(null);

  // Check if Login
  useEffect(() => {
    if (!location.state) {
      return routerHistory.push("/auth/login");
    }
    fetchFirmLogo();
  }, []);

  // Send push notification
  useEffect(() => {
    // console.log(location.state)
    const sendNotification = async () => {
      const details = await fetch("/api/auth/mfaPushNotification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pushToken: location.state.deviceToken,
          email: location.state.email
        }),
      });
      const data = await details.json();
      console.log(data)
      setPassCode(data);
    };
    sendNotification();

    const timer = setInterval(() => {
      setResetCooldown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    const timer2 = setInterval(() => {
      setEmailResetCooldown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    }
  }, []);

  // Check MFA Method
  useEffect(() => {
    const checkUserDetail = async () => {
      try {
        const userData = await fetch("/api/auth/checkMFAMethod", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.email,
          }),
        });
        const data = await userData.json();
        if (data.success) {
          // console.log(data)
          setUserDetails(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkUserDetail();
  }, []);

  // Get Firm Logo
  const fetchFirmLogo = () => {
    const firmLogo = async () => {
      try {
        const data = await fetch("/api/accountingFirm/getLogo", {
          method: "GET",
        });
        const logo = await data.json();
        setAccountingLogo(logo);
      } catch (error) {
        console.log(error);
      }
    };
    firmLogo();
  };

  const handleResendMobileCode = () => {
    setBtnLoading(true)
    setResetCooldown(30)
    const sendNotification = async () => {
      const details = await fetch("/api/auth/mfaPushNotification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pushToken: location.state.deviceToken,
          email: location.state.email
        }),
      });
      const data = await details.json();
      console.log(data)
      setPassCode(data);
      setMessage("Code sent successfully.");

      const timer = setInterval(() => {
        setResetCooldown((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Clear the interval when the component is unmounted
      clearInterval(timer);
      return setTimeout(() => {
        setMessage("");
        setBtnLoading(false);
      }, 2000);
    };
    sendNotification();
  }

  const handleResendEmailCode = () => {
    // setBtnLoading(true)
    setEmailResetCooldown(30)
    sendMFABackEmailCode();
    // const sendNotification = async () => {
    //   const details = await fetch("/api/auth/mfaPushNotification", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       pushToken: location.state.deviceToken,
    //       email: location.state.email
    //     }),
    //   });
    //   const data = await details.json();
    //   console.log(data)
    //   setPassCode(data);
    //   setMessage("Code sent successfully.");

    //   const timer = setInterval(() => {
    //     setEmailResetCooldown((prevSeconds) => prevSeconds - 1);
    //   }, 1000);

    //   // Clear the interval when the component is unmounted
    //   clearInterval(timer);
    //   return setTimeout(() => {
    //     setMessage("");
    //     setBtnLoading(false);
    //   }, 2000);
    // };
    // sendNotification();
  }

  // Send Back Email Code
  const sendMFABackEmailCode = async () => {
    // console.log("sendMFABackEmailCode")
    try {
      const sendEmailBackEmailCode = await fetch(
        "/api/auth/sendVerifyEmailBackUp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.email,
            accountingFirmLogo: accountingLogo.logo,
            firmCountry: firmDetails?.CountryCode,
            ubID: userDetails.ubID
          }),
        }
      );
      const data = await sendEmailBackEmailCode.json();
      if (data.success) {
        const userData = await fetch("/api/auth/checkMFAMethod", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.email,
          }),
        });
        const data = await userData.json();
        setUpdateData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mfaOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMfaTextbox(value);
  };

  const onVerifyHandler = async () => {
    setBtnLoading(true)
    if (mfaTextbox === "") {
      setMessage("Text field is empty. Try again.");
      return setTimeout(() => {
        setMessage("");
        setBtnLoading(false);
      }, 2000);
    }

    try {
      const verifyMobileCode = await fetch(`/api/auth/verifyMFAMobileCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: mfaTextbox,
          email: location.state.email,
          uid: userDetails.id,
          ubID: userDetails.ubID
        }),
      });

      const verified = await verifyMobileCode.json();
      console.log(verified)

      if (!verified.success) {
        setMessage("Invalid 4-digit pin. Try again.");
        return setTimeout(() => {
          setMessage("");
          setBtnLoading(false);
        }, 2000);
      }

      if (verified.success) {
        const userLogin = await fetch("/api/auth/mfaPushNotificationLogin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.email,
            password: location.state.password,
          }),
        });
        const data = await userLogin.json();
        setMessage("You are successfully authenticated.");
        setBtnLoading(false)
        if (data.success) {
          setTimeout(() => {
            return routerHistory.push("/");
          }, 2000);
        }
      }
    } catch (error) {
      setBtnLoading(false)
      console.log(error);
    }
  };

  const defaultScreenShow = () => {
    setDefaultScreen(true);
    setBackEmailScreen(false);
    setSecurityQuestionsScreen(false);
  };

  const onClickBackupEmail = () => {
    setBackEmailScreen(true);
    sendMFABackEmailCode();
    setDefaultScreen(false);
    setSecurityQuestionsScreen(false);
    setEmailResetCooldown(30)
  };

  const onClickSecurityQuestions = () => {
    setSecurityQuestionsScreen(true);
    setBackEmailScreen(false);
    setDefaultScreen(false);
  };

  const authCodeBackupEmailOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAuthCodeBackupEmail(value);
  };

  const verifyEmailCodeOnClickHandler = async () => {
    // setLoading(true);
    setBtnLoading(true)
    if (authCodeBackupEmail === "") {
      setBtnLoading(true)
      setMessageBackupEmailConfirm("This field is required");
      return setTimeout(() => {
        setMessageBackupEmailConfirm("");
      }, 2000);
    }
    // if (updatedData.length === 0) {
    //   setLoading(false);
    //   return console.log("Still fetching data...");
    // }
    // if (updatedData.data.BackupEmailCode.toString() !== authCodeBackupEmail) {
    //   setLoading(false);
    //   setMessageBackupEmailConfirm(
    //     "Invalide code. Check your secondary email to get the code"
    //   );
    //   return setTimeout(() => {
    //     setMessageBackupEmailConfirm("");
    //   }, 2000);
    // }
    try {
      const response = await fetch('/api/auth/verifyEmailCode', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          code: authCodeBackupEmail,
          email: location.state.email,
          ubID: userDetails.ubID
        }),
      });

      const emailValidation = await response.json();

      console.log(emailValidation)

      if (!emailValidation.success) {
        setBtnLoading(false)
        setMessageBackupEmailConfirm(
          "Invalide code. Check your secondary email to get the code"
        );
        return setTimeout(() => {
          setMessageBackupEmailConfirm("");
        }, 2000);
      }

      if (emailValidation.success) {
        const userLoginBackupEmail = await fetch(
          "/api/auth/mfaEnabledBackupEmailLogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: location.state.email,
              password: location.state.password,
            }),
          }
        );
        const data = await userLoginBackupEmail.json();
        if (!data.success) {
          setBtnLoading(false)
          setMessageBackupEmailConfirm(
            "Invalide code. Please check your secondary email to get the code"
          );
          return setTimeout(() => {
            setMessageBackupEmailConfirm("");
          }, 1500);
        }
        setBtnLoading(false)
        setMessageBackupEmailConfirm("Your login authentication was successful!");
        return setTimeout(() => {
          routerHistory.push("/");
        }, 1500);
      }

    } catch (error) {
      setBtnLoading(false)
      console.log(error);
    }

  };

  const securityQuestionVerifyOnClick = async () => {
    // console.log(answerSecQuestionOne.current.value)
    // console.log(answerSecQuestionTwo.current.value)
    // console.log(userDetails)
    // setLoading(true);
    setBtnLoading(true)
    if (answerSecQuestionOne.current.value === "") {
      setBtnLoading(false)
      setIsAns1Valid(false)
      setMessageSecurityQuestion("Please type in your answers.");
      return setTimeout(() => {
        setIsAns1Valid(true)
        setMessageSecurityQuestion("")
      }, 2000);
    }
    if (answerSecQuestionTwo.current.value === "") {
      setBtnLoading(false)
      setIsAns2Valid(false)
      setMessageSecurityQuestion("Please type in your answers.");
      return setTimeout(() => {
        setIsAns2Valid(true)
        setMessageSecurityQuestion("")
      }, 2000);
    }

    try {
      const checkSecAns = await fetch(
        "/api/auth/checkSecurityQuestionAnswer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.email,
            q1: userDetails.data.securityQuestions[0].question,
            a1: answerSecQuestionOne.current.value.toLowerCase(),
            q2: userDetails.data.securityQuestions[1].question,
            a2: answerSecQuestionTwo.current.value.toLowerCase()
          }),
        }
      );
      const data = await checkSecAns.json();
      if (data.success) {
        const dataSend = await fetch(
          "/api/auth/mfaEnabledSecurityQuestionLogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: location.state.email,
              password: location.state.password,
            }),
          }
        );
        const data = await dataSend.json();
        if (data.success) {
          setLoading(false);
          setMessageSecurityQuestion("Security question validated.");
          return setTimeout(() => {
            routerHistory.push("/");
          }, 2000);
        }
      }
      if (!data.success) {
        setBtnLoading(false)
        setMessageSecurityQuestion("Incorrect answers. Please try again.");
        return setTimeout(() => {
          setMessageSecurityQuestion("")
        }, 3000);
      }
      if (data.error) {
        setBtnLoading(false)
        setMessageSecurityQuestion(data.error)
        return setTimeout(() => {
          setMessageSecurityQuestion("")
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false)
      setMessageSecurityQuestion(error)
      return setTimeout(() => {
        setMessageSecurityQuestion("")
      }, 2000);
    }

    // try {
    //   const dataSend = await fetch(
    //     "/api/auth/mfaEnabledSecurityQuestionLogin",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         email: location.state.email,
    //         password: location.state.password,
    //       }),
    //     }
    //   );
    //   const data = await dataSend.json();
    //   setLoading(false);
    //   if (data.success) {
    //     setMessageSecurityQuestion("Security question validated.");
    //     return setTimeout(() => {
    //       routerHistory.push("/client/accountant/details");
    //     }, 2000);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const closeModalHandlerSecurityQuestions = () => {
    setModalSecurityQuestion(false);
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modalSecurityQuestion}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Security Question Answer
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            It appears that the answer you provided for the security question is
            incorrect. <br />
            Please double-check your answer and try again.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerSecurityQuestions}
          >
            Close
          </Button>
        </div>
      </Modal>
      {defaultScreen && (
        <div className="center-blk-container mfa-container-blk">
          <div></div>
          <h1>Authenticate Your Account</h1>
          <p>
            Your account is protected with Multi-factor authentication.
            <br />
            Please confirm your account by entering the 4-digit pin sent to your
            device.
          </p>
          <div className="form-blk-mfa">
            <input
              type="text"
              placeholder="Enter your code here..."
              maxLength={4}
              value={mfaTextbox}
              onChange={mfaOnChangeHandler}
              disabled={btnLoading ? true : false}
            />
            {
              btnLoading ?
                <button onClick={onVerifyHandler} disabled={btnLoading ? true : false} style={{ paddingLeft: "42px", paddingRight: "42px" }}>
                  <Spinner style={{ height: "1rem", width: "1rem" }} />
                </button>
                :
                <button onClick={onVerifyHandler}>
                  <div>
                    VERIFY
                    <span className="btn-inner--icon"></span>
                  </div>
                </button>
            }
            <div className="text-center btn-link pt-1" >
              <span className={`text-small h5 ${resetCooldown > 0 ? "text-muted" : ""}`} style={{ cursor: `${resetCooldown > 0 ? "" : "pointer"}` }} onClick={() => resetCooldown > 0 || btnLoading ? null : handleResendMobileCode()} >
                Resend code {resetCooldown <= 0 ? "" : `in ${resetCooldown} seconds`}
              </span>
            </div>
            {message ? (
              message === "You are successfully authenticated." || message === "Code sent successfully." ? (
                <div className="message-blk-validation-success">{message}</div>
              ) : (
                <div className="message-blk-validation-error">{message}</div>
              )
            ) : (
              ""
            )}
            {userDetails?.data?.backupEmail &&
              userDetails.data.backupEmail !== "" && (
                <div style={{ marginTop: 20 }}>
                  <button
                    className="buttonLinkClass"
                    onClick={() => btnLoading ? null : onClickBackupEmail()}
                  >
                    Authenticate using backup email instead?
                  </button>
                </div>
              )}
            {userDetails?.data?.securityQuestions &&
              userDetails.data.securityQuestions !== "" &&
              userDetails.data.securityQuestions.length !== 0 && (
                <div style={{ marginTop: 20 }}>
                  <button
                    className="buttonLinkClass"
                    onClick={() => btnLoading ? null : onClickSecurityQuestions()}
                  >
                    Authenticate using security questions instead?
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
      {backEmailScreen && (
        <div className="center-blk-container mfa-container-blk">
          <div></div>
          <h1>We have sent you an email</h1>
          <p>
            To authenticate your login, retrieve the 6 digit code that was sent
            to your back up email and copy it into the appropriate field.
          </p>

          <div className="form-blk-mfa">
            <input
              type="text"
              placeholder="Enter your code here..."
              maxLength={6}
              value={authCodeBackupEmail}
              onChange={authCodeBackupEmailOnChangeHandler}
              disabled={btnLoading ? true : false}
            />
            {
              btnLoading ?
                <button onClick={verifyEmailCodeOnClickHandler} disabled={btnLoading ? true : false} style={{ paddingLeft: "42px", paddingRight: "42px" }}>
                  <Spinner style={{ height: "1rem", width: "1rem" }} />
                </button>
                :
                <button onClick={verifyEmailCodeOnClickHandler} disabled={btnLoading ? true : false}>VERIFY</button>
            }
            <div className="text-center btn-link pt-1" >
              <span className={`text-small h5 ${emailResetCooldown > 0 ? "text-muted" : ""}`} style={{ cursor: `${emailResetCooldown > 0 ? "" : "pointer"}` }} onClick={() => emailResetCooldown > 0 || btnLoading ? null : handleResendEmailCode()} >
                Resend code {emailResetCooldown <= 0 ? "" : `in ${emailResetCooldown} seconds`}
              </span>
            </div>
          </div>
          {loading ? (
            <Spinner style={{ display: "block", margin: "30px auto" }} />
          ) : (
            <div
              style={{ margin: "0 auto" }}
              className={
                messageBackupEmailConfirm === ""
                  ? ""
                  : messageBackupEmailConfirm ===
                    "Your login authentication was successful!"
                    ? "message-blk-validation-success"
                    : "message-blk-validation-error"
              }
            >
              {messageBackupEmailConfirm}
            </div>
          )}
          <div style={{ marginTop: 20 }}>
            <button className="buttonLinkClass" onClick={() => btnLoading ? null : defaultScreenShow()}>
              Use App Verify instead?
            </button>
          </div>
        </div>
      )}
      {securityQuestionsScreen && (
        <div
          className="center-blk-container mfa-container-blk form-security-question-answer"
          style={{ top: "38%" }}
        >
          <div></div>
          <h1>
            <b>Security Question</b>
          </h1>
          <p>
            To verify your login, you will need to provide an answer to your
            security question.
          </p>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="form-blk-mfa" style={{ width: "700px" }}>
            {
              userDetails.data.securityQuestions.map((sec, i) => (
                i === 2 ? null
                  :
                  <React.Fragment key={i}>
                    <p style={{ textAlign: "left" }}>
                      <b>Question {i + 1}:</b>{" "}
                      {sec.question}
                    </p>
                    <input
                      type="text"
                      placeholder="Answer..."
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        borderColor: `${i === 0 && isAns1Valid ? "rgb(217, 217, 217)" : i === 1 && isAns2Valid ? "rgb(217, 217, 217)" : 'red'}`
                      }}
                      ref={i === 0 ? answerSecQuestionOne : answerSecQuestionTwo}
                      disabled={btnLoading ? true : false}
                    />
                  </React.Fragment>
              ))
            }
            {/* <p style={{ textAlign: "left" }}>
              <b>Question 1:</b>{" "}
              {userDetails.data.securityQuestions[0].question}
            </p>
            <input
              type="text"
              placeholder="Answer..."
              style={{
                width: "100%",
                marginBottom: "20px",
                borderRadius: "5px",
              }}
              ref={answerSecQuestionOne}
            />
            <p style={{ textAlign: "left" }}>
              <b>Question 2:</b>{" "}
              {userDetails.data.securityQuestions[1].question}
            </p>
            <input
              type="text"
              placeholder="Answer..."
              style={{
                width: "100%",
                marginBottom: "10px",
                borderRadius: "5px",
              }}
              ref={answerSecQuestionTwo}
            /> */}
            <button
              style={{
                borderRadius: "5px",
                width: "100%",
                marginTop: "10px",
              }}
              onClick={securityQuestionVerifyOnClick}
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} /> : `VERIFY YOUR ANSWER`}
            </button>

            {loading ? (
              <Spinner style={{ display: "block", margin: "30px auto" }} />
            ) : (
              <div
                className={
                  messageSecurityQuestion === ""
                    ? ""
                    : messageSecurityQuestion === "Security question validated."
                      ? "message-blk-validation-success"
                      : "message-blk-validation-error"
                }
                style={{
                  width: "100%",
                }}
              >
                {messageSecurityQuestion}
              </div>
            )}
          </div>
          <div>
            <button className="buttonLinkClass" onClick={() => btnLoading ? null : defaultScreenShow()}>
              Use App Verify instead?
            </button>
          </div>
        </div>
      )}
      <div className="footer-blk">
        <p>
          &copy; 2023 - <span>Etani</span>
        </p>
      </div>
    </>
  );
};

export default MultiFactorPage;
