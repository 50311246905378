import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button } from "reactstrap";
import moment from "moment";
const AdminHistoryTable = ({ detailsType, data, isCurrent, ...props }) => {
  // console.log(data)
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/* <th scope="col">
              UserID
            </th> */}
            <th scope="col">
              Name
            </th>
            <th scope="col">Email</th>
            <th scope="col">Is Superadmin?</th>
            <th scope="col">{isCurrent ? "Price" : "Action"}</th>
            <th scope="col">Initiator</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.length >= 1 ? data?.map((d, i) => (
              <tr key={i}>
                {/* <th scope="row">{d.Details.User.UserID}</th> */}
                <td>{isCurrent ? `${d.User.FirstName} ${d.User.LastName}` : `${d.Details.User.FirstName} ${d.Details.User.LastName}`}</td>
                <td>{isCurrent ? d.User.Email : d.Details.User.Email}</td>
                <td>{isCurrent ? d.UserAccess === 3 ? "Yes" : "No" : d.Details.IsAdmin ? "Yes" : "No"}</td>
                <td>{isCurrent ? "A$30" : d.Action}</td>
                <td>{isCurrent ? d.LastAddedBy.id <= 2 ? "System" : d.LastAddedBy.Email : d.Details?.Initiator.id <= 4 ? 'System' : `${d.Details?.Initiator.Email}` }</td>
                <td>{moment(isCurrent ? d.createdAt : d.createdAt).format("DD-MM-YYYY LT")}</td>
              </tr>
            ))
              : null
          }
        </tbody>
      </Table>
      {
        data?.length < 1 ?
          <Row>
            <Col>
              <p className="text-center text-sm mt-3">No data found.</p>
            </Col>
          </Row>
          : null
      }
    </>
  )
}
export default AdminHistoryTable;