import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { MainContext } from "context/mainContext";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CustomPanelCard = ({ ...props }) => {
  const { bgColor, textColor, progressbarPercentage } = useContext(MainContext);
  const [progress, setProgress] = useState();
  // console.log(props.totalChecked);
  useEffect(() => {
    // console.log("props.totalChecked", props.totalChecked);
    // console.log("props.totalLectureLength", props.totalLectureLength);
    props.totalChecked && props.totalLectureLength
      ? setProgress(
          Math.round((props.totalChecked / props.totalLectureLength) * 100)
        )
      : setProgress(0);
  }, [progress, props.totalChecked, props.totalLectureLength]);
  return (
    <>
      {/* {console.log(progress)} */}
      <Card className="fadeIn">
        <CardHeader
          style={{
            backgroundColor: `${bgColor}`,
            transition: ".3s ease-in-out",
          }}
        >
          <h5 className="h3 mb-0" style={{ color: `${textColor}` }}>
            <span
              className="text-left float-left p"
              style={{ color: `${textColor}` }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ margin: "12px" }}>
                  <span>{props.headerName}</span>
                </div>
              </div>
            </span>
            <span
              className="text-right float-right p"
              style={{ color: `${textColor}` }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ width: 45, height: 45 }}>
                  <CircularProgressbar
                    // value={progress ? progress : 0}
                    // text={`${progress ? progress : 0}%`}
                    value={progressbarPercentage ? progressbarPercentage : 0}
                    text={`${
                      progressbarPercentage ? progressbarPercentage : 0
                    }%`}
                    background
                    backgroundPadding={6}
                    styles={buildStyles({
                      backgroundColor: `${textColor}`,
                      textColor: `${bgColor}`,
                      pathColor: `${bgColor}`,
                      trailColor: "transparent",
                    })}
                  />
                </div>
                <div className="ml-2" style={{ margin: "auto" }}>
                  <span>Your Progress</span>
                </div>
              </div>
            </span>
          </h5>
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    </>
  );
};

export default CustomPanelCard;
