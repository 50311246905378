import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Chat from './Chat'
import { generateUUID } from 'services/EVAResponseService'
import { jwtDecode } from 'jwt-decode'
import Loading from './ui/loading'
import { evaThemeLighter } from 'services/EVAResponseService'
import { Col, Row } from 'reactstrap'
import { useRef } from 'react'
import ChatDashboard from 'chat-components/ChatDashboard';


const EVADashboard = ({ token, onHideHeader, hideHeader, handleNewUpdate, showChat, noEvaAccess, selectedClientID, selectedDashboardTemplate, handlePintoDashboardUpdate, handleEVAChatWidth }) => {
  const [selectedUUID, setSelectedUUID] = useState('')
  const [isOpenTab, setIsOpenTab] = useState(false)
  const [decodedToken, setDecodedToken] = useState(null);
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [resizeWidth, setResizeWidth] = useState((window.innerWidth / 2))
  const [isEVAPage, setIsEVAPage] = useState(false)
  useEffect(() => {
    const generateUuidAndDecode = async () => {
      const decoded = jwtDecode(JSON.stringify(token));
      setDecodedToken(decoded);
      setTheme(decoded.business.theme);

      const uuid = await generateUUID();
      setSelectedUUID(uuid);
    };
    
    generateUuidAndDecode();
  }, [token]);

  useEffect(() => {
    handleEVAChatWidth(resizeWidth)
  },[resizeWidth])

  const handleSelectUUID = (uuid) => {
    setSelectedUUID(uuid);
  }
  const handleOpenTab = () => {
    setIsOpenTab(!isOpenTab)
  }
  const handleIsLoading = (val) => {
    setIsLoading(!isLoading)
  }
  const handleSetAlert = (val) => {
    setAlert(val)
  }

  const ref = useRef(null);
  const refLeft = useRef(null);

  useEffect(() => {
    const resizeableEle = ref.current;
    if (!resizeableEle) return;

    const styles = window.getComputedStyle(resizeableEle);
    let width = parseInt(styles.width, 10);
    let height = parseInt(styles.height, 10);
    let x = 0;

    // resizeableEle.style.top = '50px';
    // resizeableEle.style.left = '50px';

    // Left resize
    const onMouseMoveLeftResize = (event) => {
      const dx = event.clientX - x;
      x = event.clientX;
      width = width - dx;
      resizeableEle.style.width = `${width}px`;
      setResizeWidth(width)
    };

    const onMouseUpLeftResize = () => {
      document.removeEventListener('mousemove', onMouseMoveLeftResize);
    };

    const onMouseDownLeftResize = (event) => {
      x = event.clientX;
      resizeableEle.style.right = styles.right;
      resizeableEle.style.left = null;
      document.addEventListener('mousemove', onMouseMoveLeftResize);
      document.addEventListener('mouseup', onMouseUpLeftResize);
    };

    // Add mouse down event listener
    const resizerLeft = refLeft.current;
    if (resizerLeft) {
      resizerLeft.addEventListener('mousedown', onMouseDownLeftResize);
    }

    return () => {
      if (resizerLeft) {
        resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize);
      }
    };
  }, []);
  
  return (
    <>
      <div ref={ref} className="resizeable" style={{ width : screenWidth / 2, minWidth: 500 }}>
        <div ref={refLeft} className="resizer resizer-l"></div>
        {showChat ? (
          <div className='resizeable-container' style={{ height: screenHeight - 130, width: resizeWidth - 20, position: 'fixed', top: 126, marginLeft: 3, minWidth: 480, transform: 'translate(0px, 0px)'}}>
           {/* <div style={{ height: screenHeight - 130, width: resizeWidth - 20, position: 'fixed', top: 126, marginLeft: 3 }}> */}
            <div className="home" style={{ height: '100%'}}>
              <ChatDashboard token={token} fromReport={true} selectedGroupId={selectedClientID} />
            </div>
          {/* </div> */}
          </div>
        ) : token ? (
          <div className='resizeable-container' style={{ height: screenHeight - 130, width: resizeWidth - 20, position: 'fixed', top: 126, marginLeft: 3, minWidth: 480, transform: 'translate(0px, 0px)'}}>
            <div className="home" style={{ height: '100%'}}>
              <div className="evaContainer">
                {alert}
                {/* {isLoading && <Loading />} */}
                {decodedToken && theme && (
                  <>
                    {isOpenTab && (
                      <Sidebar
                        onSelectUUID={handleSelectUUID}
                        selectedUUID={selectedUUID}
                        userData={decodedToken}
                        theme={theme}
                        isOpenTab={isOpenTab}
                        onIsLoading={handleIsLoading}
                        onSetAlert={handleSetAlert}
                        handleOpenTab={handleOpenTab}
                      />
                    )}
                    <Chat
                      handleOpenTab={handleOpenTab}
                      onSelectUUID={handleSelectUUID}
                      selectedUUID={selectedUUID}
                      userData={decodedToken}
                      theme={theme}
                      onIsLoading={handleIsLoading}
                      handleNewUpdate={handleNewUpdate}
                      isEVAPage={isEVAPage}
                      selectedDashboardTemplate={selectedDashboardTemplate}
                      handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        ) : noEvaAccess ? (
          <Row className="text-center d-flex align-items-center justify-content-center">
            Selected client is not allowed to access EVA.<br />
            Please select another client to view EVA.
          </Row>
        ) : null}
      </div>
    </>
  );
}

export default EVADashboard