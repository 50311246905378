/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  Form,
  Input,
  FormGroup,
  Card,
  CardBody,
  ButtonGroup,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";
import ClientCard from "components/Cards/ClientCard";
import { dataFilter, dataFilterNameOnly } from "utils/utilityFunctions";
import PanelCard from "components/Cards/PanelCard";

import { MainContext } from "context/mainContext";
import BlockUI from "components/Misc/BlockUI";
import CustomImageFieldGroup from "customComponents/Form";
import CustomHeader from "customComponents/customHeader";
import CustomSpinner from "components/Misc/CustomSpinner";
import { CustomButton } from "components/Button/CustomButton";
import ClientTable from "components/Table/ClientTable";
import { uploadBlob } from 'service/AzureBlobService';
import EnablePCE from "./EnablePCE";
import ClientPageTabs from "components/ClientPageCustomComponents/ClientPageTabs";
import AllUsers from "./AllUsers";
import AllIntegrations from "./AllIntegrations";
import EnablePBI from "./EnablePBI";
import IntegrationSchedule from "./IntegrationSchedule";

const Lists = ({ ...props }) => {
  const {
    bgColor,
    textColor,
    setContextClientLists,
    setSelectedClientID,
    selectedClientID,
    userDetails,
    firmDetails,
    setFirmDetails,
    getDetails,
    selectedClient,
    setSelectedClient,
    contextClientLists,
    setContextFilteredClientLists,
    isAddClient,
    setIsAddClient,
    isEditClient,
    setIsEditClient
  } = useContext(MainContext);
  const [logo, setLogo] = useState(null);
  // eslint-disable-next-line
  const [, setName] = useState("");
  const [clients, setClients] = useState([]);
  const [color, setColor] = useState(false);
  // eslint-disable-next-line
  const [, setWebsite] = useState("");
  const [alert, setalert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [payload, setPayload] = useState({ Logo: "" });
  const [accountants, setAccountants] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isWebSiteValid, setIsWebSiteValid] = useState(true);

  const [defaultModal1, setdefaultModal1] = React.useState(false);
  const [filteredAccountants, setFilteredAccountants] = useState([]);
  const [selectedAccountants, setSelectedAccountants] = useState([]);
  const [removedAccountants, setRemovedAccountants] = useState([]);
  const [accountantListModal, setAccountantListModal] = useState(false);
  const [accountantsFilterString, setAccountantsFilterString] = useState("");
  const [selectedUpdatedAccountants, setSelectedUpdatedAccountants] = useState(
    []
  );
  const [selectedClientToEdit, setSelectedClientToEdit] = useState([]);
  const [isClientEdit, setIsClientEdit] = useState(false);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const history = useHistory();
  // const [, setTabs] = useState(1);
  const [viewType, setViewType] = useState("table");
  const [newLogo, setNewLogo] = useState(null);
  const [newIcon, setNewIcon] = useState(null);
  const [oldIcon, setOldIcon] = useState("");
  const [azureImage, setAzureImage] = useState(null)
  const [showPCEModal, setShowPCEModal] = useState(false)
  const [pceModalData, setPCEModalData] = useState({})
  const [tabKey, setTabKey] = useState('clients');
  const [isLoadingAllUsers, setIsLoadingAllUsers] = useState(true)
  const [allUsers, setAllUsers] = useState([])
  const [allUsersFilteredData, setAllUsersFilteredData] = useState([])
  const [isLoadingAllIntegrations, setIsLoadingAllIntegrations] = useState(true)
  const [allIntegrations, setAllIntegrations] = useState([])
  const [allIntegrationsFilteredData, setAllIntegrationsFilteredData] = useState([])
  const [showPBIModal, setShowPBIModal] = useState(false)
  const [filterType, setFilterType] = useState("Client Name")
  const [hasRefreshingIntegration, setHasRefreshingIntegration] = useState(false)

  const fetchAPIs = async () => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    setIsLoading(true);
    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {
      fetch(`/api/client/getAll`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data && !data.error) {
            setClients(data);
            setContextClientLists(data);
            setFilteredData(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetch(`/api/client/getAllRecords`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log('dito', data)
            setClients(data);
            setContextClientLists(data);
            setFilteredData(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetch("/api/user/getRecipients")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          setAccountants(data.accountant);
          setFilteredAccountants(data.accountant);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    fetch("/api/user/getAccess")
      .then((res) => res.json())
      .then((data) => { })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(firmDetails)
    // Get clientListViewType
    localStorage.getItem("clientListViewType") !== null
      ? setViewType(localStorage.getItem("clientListViewType"))
      : setViewType("table");
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    setSelectedClientID(sessionSelectedClientID ? sessionSelectedClientID : selectedClientID);
    fetchAPIs();

    localStorage.getItem("showAddClientModal") !== null && localStorage.getItem("showAddClientModal") === "true" ? openModal()
      : setdefaultModal1(false)

    return () => {
      setFilteredAccountants(filteredAccountants);
      setAccountants(accountants);

    };
  }, [userDetails]);

  useEffect(async () => {
    try {
      const getAllUsers = await fetch("/api/user/getUsersByBusiness")
      const result = await getAllUsers.json()
      // console.log(result)
      if (result && !result.error) {
        setIsLoadingAllUsers(false)
        setAllUsers(result)
        setAllUsersFilteredData(result)
      }

      const getAllServices = await fetch("/api/clientService/getAllRecordsByBusiness")
      const resultServices = await getAllServices.json()
      if (resultServices && !resultServices.error) {
        // console.log(resultServices)
        setAllIntegrations(resultServices)
        setAllIntegrationsFilteredData(resultServices)
        setIsLoadingAllIntegrations(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoadingAllUsers(false)
      setIsLoadingAllIntegrations(false)
    }
    // try {
    //   const getAllServices = await fetch("/api/clientService/getAllRecordsByBusiness")
    //   const resultServices = await getAllServices.json()
    //   console.log(resultServices)
    //   if (resultServices && !resultServices.error) {
    //     setAllIntegrations(resultServices)
    //     setAllIntegrationsFilteredData(resultServices)
    //     setIsLoadingAllIntegrations(false)
    //   }
    // } catch (error) {
    //   console.log(error)
    //   setIsLoadingAllIntegrations(false)
    // }
  }, [])
  useEffect(() => {
    if (isAddClient) {
      setdefaultModal1(isAddClient)
    }

    if (isEditClient) {
      handleEditClient(selectedClient)
    }
  }, [isAddClient, isEditClient])

  const warningAlert = (type, message, obj) => {
    setalert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() =>
          type === "deleteClient" ? handleDelete(obj) : type === 'planSubscribe' ? redirectToAboutUsPlanTab() : redirectToPricing()
        }
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        {message}
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setalert(
      <ReactBSAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title="Error"
        onConfirm={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Close"
        btnSize=""
        showCancel={false}
      >
        {message}
      </ReactBSAlert>
    );
  };

  const successAlert = (message) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const openModal = () => {
    // console.log(firmDetails?.Details?.Trial);
    // console.log(clients.length);
    // console.log(firmDetails?.Details?.Plan?.Details?.Clients);
    // console.log(firmDetails?.PricingPlan?.Details?.Clients)
    setShowBlockUI(true);
    fetch("/api/accountingFirm/getRecord")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          setShowBlockUI(false);
          if (firmDetails?.PricingPlan?.Code === 'TIER6') {
            setdefaultModal1(true);
          } else
            if (firmDetails?.Details?.Trial && data?.Client.length >= 1) {
              warningAlert(
                "planSubscribe",
                `You are not yet subscribed to a plan and currently in trial. Please click continue to review the subscribe benefits.`,
                null
              )
            } else if (data?.Client.length >= firmDetails?.PricingPlan?.Details?.Clients) {
              warningAlert(
                "planUpgrade",
                `The maximum number of Client(s) has been reached. Do you want to upgrade your plan?`,
                null
              )
            } else {
              setdefaultModal1(true);
            }
        } else {
          setShowBlockUI(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowBlockUI(false);
      });
    // firmDetails?.Details?.Trial ||
    //   clients.length === firmDetails?.Details?.Plan?.Details?.Clients
    //   ? warningAlert(
    //     "planUpgrade",
    //     `The maximum number of Client has been reached. Do you want to upgrade your plan?`,
    //     null
    //   )
    //   : setdefaultModal1(true);
  };

  const closeMainModal = () => {
    setIsAddClient(false)
    setIsEditClient(false)
    setdefaultModal1(false);
    setSelectedAccountants([]);
    setIsClientEdit(false);
    setSelectedClientToEdit([]);
    setPayload({});
    setLogo(null);
    setIsNameValid(true);
    setIsWebSiteValid(true);
    setAzureImage(null);
    localStorage.removeItem('showAddClientModal');
  };

  const closeSelectedAccountantModal = () => {
    setAccountantListModal(false);
    if (isClientEdit) {
      setSelectedAccountants(selectedUpdatedAccountants);
    } else {
      setSelectedAccountants([]);
    }
  };

  const handleSearch = (e) => {
    let filterString = e.target.value;
    setFilteredData(dataFilterNameOnly(filterString, clients));
  };

  const handleSearchAccountant = (e) => {
    let filterString = e.target.value;
    setAccountantsFilterString(filterString);
    setFilteredAccountants(
      dataFilter(filterString || accountantsFilterString, accountants)
    );
  };

  const handleInputChange = (e) => {
    let target = e.target;
    let val = target.type === "checkbox" ? target.checked : target.value;
    let key = e.target.id;

    if (target.type === "checkbox") {
      val = target.checked;
    } else if (target.type === "file") {
      const reader = new FileReader();
      const file = target.files[0];

      reader.onload = function (upload) {
        setLogo(upload.target.result);

        let inputElement = target.nextSibling;
        inputElement.value = upload.target.result;
        inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      };
      reader.readAsDataURL(file);
    } else {
      val = target.value.trim();
    }
    if (key === "Name") {
      if (!val || !val.match(/\S/g)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }
      setName(val);
    }
    if (key === "WebSite") {
      // let re = new RegExp(
      //   /^((?:(?:(?:\w[-+.]?)*)\w)+)((?:(?:(?:\w[-+.]?){0,62})\w)+)\.(\w{2,6})$/
      // );
      let re = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,63}|' + // domain name with TLD
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d{1,5})?' + // port
        '(\\/[-a-zA-Z\\d%_.~+]*)*' + // path
        '([^\\/.]{1,}[^\\/.]|\\/)?' + // path should not end with dot or slash followed by dot
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z\\d_]*)?$', 'i');
      let match = val.match(re);
      if (!val || !val.match(/\S/g) || !match) {
        return setIsWebSiteValid(false);
      } else {
        setIsWebSiteValid(true);
      }
      setWebsite(val);
    }
    const newPayload = { ...payload };
    newPayload[key] = val;
    setPayload(newPayload);
  };

  const handleImgChange = (img) => {
    setLogo(img);
  };

  const handleImgChangeLogo = (img) => {
    // console.log(img)
    // setNewLogo(img);
    setLogo(img)
    // if (!newIcon) setNewIcon(oldIcon)
    // if (!newIcon) setLogo(oldIcon)
  };

  const handleAzureImgChangeLogo = (file) => {
    // console.log(file)
    setAzureImage(file)
  }

  const redirectToPricing = () => {
    setalert(null);
    setdefaultModal1(false);
    return history.replace("/client/plans");
  };

  const redirectToAboutUsPlanTab = () => {
    setalert(null);
    setdefaultModal1(false);
    sessionStorage.setItem("aboutUsTabView", 2);
    return history.replace("/client/business/details");
  }

  const selectAccountant = (data) => {
    let selected = selectedAccountants;
    let removed = removedAccountants;
    const index = selected.indexOf(data.id);

    if (index > -1) {
      removed.push(data.id);
      selected.splice(index, 1);
      setColor(!color);
    } else {
      selected.push(data.id);
      removed = removed.filter((e) => e !== data.id);
      setColor(!color);
    }
    setSelectedAccountants(selected);
    setRemovedAccountants(removed);
  };

  const selectAllAccountants = () => {
    const accountantsList = accountants;
    const selected = [];
    for (let i = 0; i < accountantsList.length; i++) {
      selected.push(accountantsList[i].id);
    }

    setSelectedAccountants(selected);
    setRemovedAccountants([]);
  };

  const unselectAllAccountants = () => {
    const removed = selectedAccountants;
    setSelectedAccountants([]);
    setRemovedAccountants(removed);
  };

  const listAccountants = () => {
    const data = filteredAccountants;
    // console.log('listAccountants', data)
    if (data && data.length === 0) {
      return (
        <div className="col-sm-12 text-center">
          <h5>No users found.</h5>
        </div>
      );
    } else {
      return (
        data &&
        data.filter(d => d.id > 4).map((record, i) => {
          const index = selectedAccountants.indexOf(record.id);
          return (
            <Col md={4} key={i}>
              <Card onClick={() => selectAccountant(record)}>
                <CardBody>
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <div className="avatar  rounded-circle">
                        <img
                          alt="..."
                          src={
                            record && record.Avatar
                              ? record.Avatar
                              : require("assets/img/blank-avatar.png").default
                          }
                          style={{ height: "48px", width: "48px" }}
                        />
                      </div>
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        {`${record.FirstName} ${record.LastName}`}
                      </h4>
                    </div>
                    <Col className="col-auto">
                      <Button
                        size="sm"
                        type="button"
                        style={{
                          borderRadius: "50%",
                          backgroundColor: index > -1 ? "#2dce89" : "gray",
                          borderColor: index > -1 ? "#2dce89" : "gray",
                          color: "#fff",
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-check-bold" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          );
        })
      );
    }
  };

  const showBlockUIModal = () => {
    return <BlockUI />;
  };

  const handleEditClient = (record) => {
    console.log(record)
    let selected = [];
    setShowBlockUI(true);
    fetch(`/api/client/getClientAccountant/${record.ClientID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          data.map((val) => {
            if (val && val.User) {
              return selected.push(val.User.id);
            }
            return null;
          });
        }
      })
      .then(() => {
        setShowBlockUI(false);
        setdefaultModal1(true);
        setSelectedClientToEdit(record);
        setIsClientEdit(true);
        setLogo(record.Logo);
        setOldIcon(record.Logo)
        setPayload({
          Name: record.Name,
          Description: record.Description,
          WebSite: record.WebSite,
          Accountants: selected,
        });
        setSelectedAccountants(selected);
        setSelectedUpdatedAccountants(selected);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setShowBlockUI(true);
    if (!payload.Name || !isNameValid) {
      setIsLoading(false);
      setShowBlockUI(false);
      setIsNameValid(false);
      return;
    }
    // if (!payload.WebSite || !isWebSiteValid) {
    //   setIsLoading(false);
    //   setShowBlockUI(false);
    //   setIsWebSiteValid(false);
    //   return;
    // }

    delete payload.Logo;

    let newPayload = payload;
    newPayload.Logo = logo;
    newPayload.Accountants = selectedAccountants || [];
    setIsLoading(true);
    if (isClientEdit) {
      // console.log(selectedClient)
      // console.log(selectedAccountants)

      if (azureImage) {
        const blobFileName = `CMS/clientImages/${selectedClient.id}-${selectedClient?.Name.split(" ").join("-")}.png`;
        // This will only get azure blob token that is used for the uploadBlob function
        await fetch(`/api/clientService/uploadCsv`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ fileName: blobFileName })
        })
          .then((response) => response.json())
          .then(async data => {

            // Upload to Azure Blob
            await uploadBlob(azureImage, blobFileName, data);
            newPayload.Details = {
              ...selectedClient.Details,
              azureBlobClientImageUrl: `https://etanicdn.blob.core.windows.net/etani-blob/${blobFileName}`
            }
            editFetchCall(newPayload)
          })
          .catch((err) => {
            console.log('UPLOAD CLIENT IMAGE', err);
            setShowBlockUI(false);
            setIsLoading(false)
          })
      } else {
        // console.log(newPayload)
        editFetchCall(newPayload)
      }
    } else {
      let newSelectedAccountants = selectedAccountants;
      newSelectedAccountants.push(userDetails?.User?.id);
      if (
        clients.find(
          (client) =>
            client.Name.toLowerCase() === payload.Name.toLowerCase().trim()
        )
      ) {
        setShowBlockUI(false);
        setIsLoading(false);
        errorAlert(
          `Client name already exists. Please choose different client name.`
        );
        return;
      }
      if (payload.Name.toLowerCase() === "test") {
        setShowBlockUI(false);
        setIsLoading(false);
        errorAlert(`Please choose unique client name.`);
        return;
      }
      fetch("/api/client/createRecord", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(newPayload),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("/api/client/createRecord", data);
          const createdClient = data.record
          if (data.message === "Record has been created") {
            setdefaultModal1(false);
            setShowBlockUI(false);
            successAlert("Record has been successfully added.");
            getDetails();
            fetchAPIs();
          }
          if (newSelectedAccountants.length >= 1) {
            // console.log('newSelectedAccountants', newSelectedAccountants)
            fetch(
              `/api/customReport/subscribeToWorkspaceByClient/${data.record.ClientID}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  selectedAdvisor: newSelectedAccountants,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.message === "Success") {
                  setSelectedClientToEdit([]);
                }
              });

            fetch(`/api/dwAccess/addUserToDwViaClient`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                selectedAdvisors: newSelectedAccountants,
                client: createdClient.id,
                business: firmDetails.id,
                businessCode: firmDetails.Code
              }),
            })
              .then(res => res.json())
              .then(data => {
                // console.log('/dwAccess/addUserToDwViaClient', data)
                // setShowBlockUI(false)
              })
          }
          if (data.error) {
            setdefaultModal1(false);
            setShowBlockUI(false);
            errorAlert(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editFetchCall = (newPayload) => {
    fetch(`/api/client/updateRecordExternal/${selectedClientToEdit.ClientID}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(newPayload),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('updateRecordExternal', data)
        if (data && data.message === "Record has been updated") {
          fetchAPIs();
          setShowBlockUI(false);
          setdefaultModal1(false);
          setPayload({});
          successAlert(data.message);
          if (selectedAccountants.length >= 1) {
            fetch(
              `/api/customReport/subscribeToWorkspaceByClient/${selectedClientToEdit.ClientID}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  selectedAdvisor: selectedAccountants,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.message === "Success") {
                  setSelectedClientToEdit([]);
                }
                // console.log(data)
                // successAlert(data.message)
              });

            fetch(`/api/dwAccess/addUserToDwViaClient`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                selectedAdvisors: selectedAccountants,
                client: selectedClientToEdit.id,
                business: firmDetails.id,
                businessCode: firmDetails.Code
              }),
            })
              .then(res => res.json())
              .then(data => {
                // console.log('/dwAccess/addUserToDwViaClient', data)
                // setShowBlockUI(false)
              })
          }

          if (removedAccountants.length >= 1) {
            fetch(
              `/api/customReport/unsubscribeToWorkspaceByClient/${selectedClientToEdit.ClientID}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  removedAdvisor: removedAccountants,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.message === "Success") {
                  setSelectedClientToEdit([]);
                }
              });

            fetch(`/api/dwAccess/removeUserToDwViaClient`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                removedAdvisors: removedAccountants,
                client: selectedClientToEdit.id,
                business: firmDetails.id,
                businessCode: firmDetails.Code
              }),
            })
              .then(res => res.json())
              .then(data => {
                // console.log('/dwAccess/removeUserToDwViaClient', data)
                // setShowBlockUI(false)
              })
          }
        }
        if (data.error) {
          setShowBlockUI(false);
          setdefaultModal1(false);
          setPayload({});
          errorAlert(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleDelete = (obj) => {
    // console.log(obj)
    let newSelectedClient = contextClientLists.filter(c => c.ClientID !== obj.ClientID)
    // console.log(newSelectedClient)
    setIsLoading(true);
    setShowBlockUI(true);
    fetch(`/api/client/deleteRecord/${obj.ClientID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          setShowBlockUI(false);
          errorAlert(data.error);
        }
        if (data.message === "Record has been deleted") {
          if (obj.ClientID === selectedClient.ClientID) {
            // console.log("same as selected")
            setSelectedClient(newSelectedClient[0])
            sessionStorage.setItem("selectedClientID", newSelectedClient[0].ClientID);
          }
          setContextClientLists(newSelectedClient)
          setContextFilteredClientLists(newSelectedClient)
          setShowBlockUI(false);
          setIsLoading(false);
          getDetails();
          fetchAPIs();
          successAlert("Record has been successfully deleted.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectView = (view) => {
    setViewType(view);
    // Set clientListViewType
    localStorage.setItem("clientListViewType", view);
  };

  const handleEnablePCE = () => {

  }

  const handleShowPBI = async (origin) => {
    try {
      setShowBlockUI(true)
      const checkRefreshingIntagration = await fetch("/api/clientService/checkForOngoingIntegrationRefresh")
      const refreshingIntegrationResult = await checkRefreshingIntagration.json()
      setShowBlockUI(false)
      setHasRefreshingIntegration(refreshingIntegrationResult)
      if(refreshingIntegrationResult && firmDetails.Database === "DB") {
        setalert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            onConfirm={() => setalert(null) }
            // onCancel={() => setalert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Close"
            btnSize=""
            showCancel={false}
          >
            {`Sorry, there are still integrations refreshing data. You may proceed after all integrations have finished refreshing. Thank you.`}
          </ReactBSAlert>
        );
      } else {
        setShowPBIModal(!showPBIModal)
      }
    } catch (error) {
      console.log(error) 
      setShowBlockUI(false)
    }
  }

  const handleClosePBIModal = () => {
    setShowPBIModal(!showPBIModal)
  }
  const handleSavePBI = async (val) => {
    // console.log('firmDetails', firmDetails)
    let payload = firmDetails.Details
    payload.PbiEnabled = val === "Enable" ? true : false
    // console.log("payload", payload)
    setShowBlockUI(true)
    setShowPBIModal(false)
    try {
      const updateFirm = await fetch("/api/accountingFirm/enableDisablePBIFeature", {
        method: "PUT",
        body: JSON.stringify({
          Details: payload
        }),
        credentials: "include",
      })
      const data = await updateFirm.json()
      // console.log(data)
      setShowBlockUI(false)
      if (data.error) {
        errorAlert(data.error)
        return;
      }
      if (data.message === "Record has been updated") {
        let updateFirmDetails = firmDetails
        updateFirmDetails.Details = data.record.Details
        setFirmDetails(updateFirmDetails)
        successAlert(
          val === "Enable" ? "Microsoft Power BI Etani Platform is now enabled. You may now enable Power BI page on your selected clients."
            : "Microsoft Power BI Etani Platform is now disabled."
        )
        fetchAPIs()
      }
    } catch (error) {
      setShowBlockUI(false)
      errorAlert(error)
    }
  }

  const handleShowEnablePCEModal = async (event, data, type) => {
    // console.log(data)
    // console.log('firmDetails', firmDetails)
    // console.log(type)
    try {
      setShowBlockUI(true)
      const checkRefreshingIntagration = await fetch("/api/clientService/checkForOngoingIntegrationRefresh")
      const refreshingIntegrationResult = await checkRefreshingIntagration.json()
      setShowBlockUI(false)
      if(type !== 'chat' && refreshingIntegrationResult && firmDetails.Database === "DB") {
        setalert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            onConfirm={() => setalert(null) }
            // onCancel={() => setalert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Close"
            btnSize=""
            showCancel={false}
          >
            {`Sorry, there are still integrations refreshing data. You may proceed after all integrations have finished refreshing. Thank you.`}
          </ReactBSAlert>
        );
      } else {
        if (firmDetails?.PricingPlan.id >= 20 && !firmDetails?.Details?.PbiEnabled && type === 'pbi') {
          return handleShowPBI()
        }
        let targetValue = event.target.checked
        setShowPCEModal(true)
        setPCEModalData({
          data,
          type,
          value: targetValue,
          businessOnDW: firmDetails?.Database === "DW" ? true : false
        })
      }
    } catch (error) {
      setShowBlockUI(false)
      errorAlert(error)
    }
    // data.Details.ShowPowerBIPage && data.Details.ShowPowerBIPage === true ? true : data.Details.ShowPowerBIPage && data.Details.ShowPowerBIPage === false ? false : true
  }

  const handleClosePCEModal = () => {
    setShowPCEModal(false)
    setPCEModalData({})
  }

  const handleTabSelect = (value) => {
    setTabKey(value)
  }

  const handleSavePCE = async (type) => {
    // console.log(pceModalData)
    setShowBlockUI(true)
    let payload = {}
    if (pceModalData.type === 'pbi') {
      payload.ShowPowerBIPage = pceModalData.value
    }
    if (pceModalData.type === 'chat') {
      payload.ShowChatPage = pceModalData.value
    }
    if (pceModalData.type === 'eva') {
      payload.ShowEvaPage = pceModalData.value
    }
    let oldIndex;
    let oldData;
    filteredData.forEach((client, index) => {
      if (client.id === pceModalData.data.id) {
        oldIndex = index;
        oldData = client;
      }
    });
    // console.log(payload)
    try {
      const updateClient = await fetch(`/api/client/updateShowPCEPages/${pceModalData.data.ClientID}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(payload),
      })
      const updateResult = await updateClient.json()
      setShowBlockUI(false)
      // console.log(updateResult)
      if (updateResult.error) {
        errorAlert(updateResult.error);
      } else {
        setShowPCEModal(false)
        let newDetails = updateResult.data.Details
        oldData.Details = newDetails
        let newData = oldData
        let newFilteredData = [...filteredData]
        // console.log('newData', newData)
        // console.log('old-selectedClient', selectedClient)
        // console.log('updateResult', updateResult)
        if (sessionStorage.getItem('selectedClientID') && updateResult.data.ClientID === sessionStorage.getItem('selectedClientID')) {
          setSelectedClient(updateResult.data)
          setSelectedClientID(updateResult.data.ClientID)
        }
        // sessionStorage.setItem("selectedClientID", updateResult.data.ClientID);
        newFilteredData.splice(oldIndex, 1, newData)
        setFilteredData(newFilteredData)
        setClients(newFilteredData);
        setContextClientLists(newFilteredData);
        successAlert("Record has been updated");
        if (pceModalData.type === 'eva' && pceModalData.value === true) {
          try {
            const details = await fetch("/api/eva/toggle", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                businessId: userDetails?.User?.AccountingFirm,
                clientId: updateResult.data.ClientID,
                isAdvisorOnly: true,
              }),
            });

            const data = await details.json();

            if (data.success) {
              console.log(data)
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      setShowBlockUI(false)
      errorAlert(error);
    }
  }

  return (
    <>
      {alert}
      <CustomHeader style={null} />
      <Container className="mt-6 fadeIn" fluid>
        <div className="">
          <div className="nav-wrapper">
            <Card>
              <ClientPageTabs tabKey={tabKey} handleTabSelect={handleTabSelect}>
                {tabKey === "clients" &&
                  <>
                    <Row className="pt-3">
                      <Col lg="6" sm="4">
                        <div className="mb-3">
                          {userDetails?.User?.Details?.Authority?.Admin ||
                            userDetails?.User?.Details?.TrialUser ? (
                            <Button
                              style={{
                                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                  }`,
                                color: `${textColor === "#000" ? "#fff" : textColor}`,
                                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                  }`,
                              }}
                              size="sm"
                              type="button"
                              onClick={() => openModal()}
                              id="addAccountant"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i
                                  className="ni ni-fat-add"
                                  style={{ fontSize: "1rem" }}
                                />
                              </span>
                              Add Client
                            </Button>
                          ) : null}
                          {showBlockUI ? showBlockUIModal() : null}
                          <Modal
                            className="modal-dialog-top"
                            isOpen={defaultModal1}
                            toggle={() => closeMainModal()}
                            size="lg"
                            backdrop="static"
                          >
                            <div className="modal-header">
                              <h6 className="modal-title">
                                {isClientEdit ? "Update Client" : "Create New Client"}
                              </h6>
                              <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => closeMainModal()}
                              >
                                <span aria-hidden={true}>×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Form>
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="Name">
                                    Name <span className="text-warning">*</span>
                                  </label>
                                  <Input
                                    placeholder="Name"
                                    type="text"
                                    name="Name"
                                    required={true}
                                    id="Name"
                                    onChange={handleInputChange}
                                    defaultValue={
                                      selectedClientToEdit && selectedClientToEdit.Name
                                        ? selectedClientToEdit.Name
                                        : ""
                                    }
                                    className="getData"
                                    style={{
                                      border: isNameValid
                                        ? "1px solid #dee2e6"
                                        : "1px solid red",
                                    }}
                                  />
                                  {isNameValid ? (
                                    ""
                                  ) : (
                                    <small className="text-warning">
                                      This field is required.
                                    </small>
                                  )}
                                </FormGroup>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="Description"
                                  >
                                    Description
                                  </label>
                                  <Input
                                    placeholder="Description"
                                    type="textarea"
                                    name="Description"
                                    required={true}
                                    id="Description"
                                    rows={4}
                                    onChange={handleInputChange}
                                    defaultValue={
                                      selectedClientToEdit && selectedClientToEdit.Description
                                        ? selectedClientToEdit.Description
                                        : ""
                                    }
                                    className="getData"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="WebSite"
                                  >
                                    Website
                                  </label>
                                  <Input
                                    placeholder="Website"
                                    type="text"
                                    name="WebSite"
                                    required={true}
                                    id="WebSite"
                                    onChange={handleInputChange}
                                    defaultValue={
                                      selectedClientToEdit && selectedClientToEdit.WebSite
                                        ? selectedClientToEdit.WebSite
                                        : ""
                                    }
                                    className="getData"
                                    style={{
                                      border: isWebSiteValid
                                        ? "1px solid #dee2e6"
                                        : "1px solid red",
                                    }}
                                  />
                                  {isWebSiteValid ? (
                                    ""
                                  ) : (
                                    <small className="text-warning">
                                      Website is not valid.
                                    </small>
                                  )}
                                </FormGroup>
                                <CustomImageFieldGroup
                                  id="Logo"
                                  name="Logo"
                                  help="Avatar"
                                  onChange={props.handleInputChange}
                                  // value={newIcon}
                                  value={selectedClientToEdit.Logo}
                                  label="Avatar"
                                  alt="Logo"
                                  lang="en"
                                  record={payload}
                                  oldLogo={selectedClientToEdit.Logo}
                                  handleImgChange={handleImgChange}
                                  handleImgChangeLogo={handleImgChangeLogo}
                                  handleAzureImgChangeLogo={handleAzureImgChangeLogo}
                                  withResetImage={true}
                                />
                                <FormGroup>
                                  <CustomButton
                                    onClick={() => setAccountantListModal(true)}
                                    type="button"
                                    size="sm"
                                    outlineStyle={false}
                                    title=" Select Advisers"
                                  />

                                  {`  ${selectedAccountants.length
                                    ? selectedAccountants.length
                                    : "No"
                                    } accountants selected.`}
                                </FormGroup>
                              </Form>
                            </div>
                            <div className="modal-footer">
                              <CustomButton
                                type="button"
                                onClick={handleSave}
                                outlineStyle={false}
                                title={isClientEdit ? "Save Changes" : "Save"}
                              />
                              <CustomButton
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => closeMainModal()}
                                outlineStyle={true}
                                title="Close"
                              />
                            </div>
                          </Modal>
                          <Modal
                            className="modal-dialog-top"
                            isOpen={accountantListModal}
                            toggle={() => setAccountantListModal(!accountantListModal)}
                            size="xl"
                            backdrop="static"
                          >
                            <div className="modal-header">
                              <h6 className="modal-title">Select Advisers</h6>
                              <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => setAccountantListModal(false)}
                              >
                                <span aria-hidden={true}>×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Row className="card-wrapper">
                                <Col lg="8">
                                  <div className="mb-5">
                                    <ButtonGroup>
                                      <Button
                                        style={{
                                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                            }`,
                                          color: `${textColor === "#000" ? "#fff" : textColor
                                            }`,
                                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                            }`,
                                        }}
                                        size="sm"
                                        type="button"
                                        onClick={() => selectAllAccountants()}
                                      >
                                        <span className="btn-inner--icon mr-1">
                                          <i
                                            className="ni ni-fat-add"
                                            style={{ fontSize: "1rem" }}
                                          />
                                        </span>
                                        Select All
                                      </Button>
                                      <Button
                                        style={{
                                          color: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                            }`,
                                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                            }`,
                                        }}
                                        size="sm"
                                        type="button"
                                        onClick={() => unselectAllAccountants()}
                                      >
                                        <span className="btn-inner--icon mr-1">
                                          <i
                                            className="ni ni-fat-delete"
                                            style={{ fontSize: "1rem" }}
                                          />
                                        </span>
                                        Unselect All
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-5">
                                    <Input
                                      placeholder="Search"
                                      type="text"
                                      bsSize="sm"
                                      onChange={handleSearchAccountant}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>{listAccountants()}</Row>
                            </div>
                            <div className="modal-footer">
                              <CustomButton
                                onClick={() => setAccountantListModal(false)}
                                type="button"
                                outlineStyle={false}
                                title="Save"
                              />
                              <CustomButton
                                onClick={() => closeSelectedAccountantModal()}
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                outlineStyle={true}
                                title="Cancel"
                              />
                            </div>
                          </Modal>
                        </div>
                      </Col>
                      <Col lg="6" sm="12">
                        <Row>
                          {/* <Col lg="2" md="2" sm="12">
                            <Button
                              size="sm"
                              style={{
                                backgroundColor: `${viewType === "table" && bgColor === "#fff"
                                  ? "#5e72e4"
                                  : viewType === "table"
                                    ? bgColor
                                    : "#fff"
                                  }`,
                                color: `${viewType === "table" && textColor === "#000"
                                  ? "#fff"
                                  : viewType === "table"
                                    ? textColor
                                    : "#525f7f"
                                  }`,
                                border: `1px ${viewType === "table" && bgColor === "#fff"
                                  ? "#5e72e4"
                                  : viewType === "table"
                                    ? bgColor
                                    : "#fff"
                                  }`
                              }}
                              onClick={() => handleShowPBI()}
                              id={`pbisettings`}
                            >
                              <span className="btn-inner--icon mr-1">
                                <img
                                  alt="..."
                                  src={
                                    require("assets/img/pbi-logo.png")
                                      .default
                                  }
                                  style={{ height: "1.438rem" }}
                                />
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target={`pbisettings`}
                            >
                              {`Power BI Settings`}
                            </UncontrolledTooltip>
                          </Col> */}
                          {/* <Col lg="2">

                          </Col> */}
                          <Col lg="12" md="12" sm="12">
                            <Row>
                              <Col className="pr-0">
                                <div className="float-right">
                                  <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                      caret
                                      size='sm'
                                      style={{
                                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                          }`,
                                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                          }`,
                                        paddingTop: '6px', paddingBottom: '6px'
                                      }}
                                    >
                                    Filter by: {filterType}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>Client Name</DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </div>
                              <div className="float-right mr-3">
                                {
                                  firmDetails?.PricingPlan?.Code === 'TIER6' ?
                                    <>
                                      <Button
                                        size="sm"
                                        style={{
                                          backgroundColor: `${viewType === "table" && bgColor === "#fff"
                                            ? "#5e72e4"
                                            : viewType === "table"
                                              ? bgColor
                                              : "#fff"
                                            }`,
                                          color: `${viewType === "table" && textColor === "#000"
                                            ? "#fff"
                                            : viewType === "table"
                                              ? textColor
                                              : "#525f7f"
                                            }`,
                                          border: `1px ${viewType === "table" && bgColor === "#fff"
                                            ? "#5e72e4"
                                            : viewType === "table"
                                              ? bgColor
                                              : "#fff"
                                            }`
                                        }}
                                        onClick={() => handleShowPBI()}
                                        id={`pbisettings`}
                                      >
                                        {/* <i className="fas fa-chart-bar" /> */}
                                        {/* <i class="fa-solid fa-chart-simple"></i> */}
                                        <span className="btn-inner--icon mr-1">
                                          <img
                                            alt="..."
                                            src={
                                              require("assets/img/pbi-logo.png")
                                                .default
                                            }
                                            style={{ height: "1.5rem" }}
                                          />
                                        </span>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target={`pbisettings`}
                                      >
                                        {`Power BI Settings`}
                                      </UncontrolledTooltip>
                                    </>
                                    : null
                                }
                              </div>
                            </Col>
                              <Col>
                                <div className="mb-3">
                                  <Input
                                    placeholder="Search by Entity"
                                    type="text"
                                    bsSize="sm"
                                    onChange={handleSearch}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {isLoading ? (
                        <CustomSpinner />
                      ) : clients.length > 0 ? (
                        viewType === "card" ? (
                          filteredData.map((data, i) => (
                            <Col lg="4" md="4" sm="12" key={i}>
                              <ClientCard
                                data={data}
                                // handleDelete={handleDelete}
                                warningAlert={warningAlert}
                                handleEditClient={handleEditClient}
                                userDetails={userDetails}
                              />
                            </Col>
                          ))
                        ) : viewType === "table" ? (
                          <Col lg="12">
                            <EnablePCE
                              handleClosePCEModal={handleClosePCEModal}
                              handleSavePCE={handleSavePCE}
                              showPCEModal={showPCEModal}
                              type={pceModalData.type}
                              data={pceModalData.data}
                              value={pceModalData.value}
                              businessOnDW={pceModalData.businessOnDW}
                            />
                            <EnablePBI
                              handleClosePBIModal={handleClosePBIModal}
                              showPBIModal={showPBIModal}
                              handleSavePBI={handleSavePBI}
                              firmDetails={firmDetails}
                              hasRefreshingIntegration={hasRefreshingIntegration}
                            />
                            <ClientTable
                              data={filteredData}
                              warningAlert={warningAlert}
                              handleEditClient={handleEditClient}
                              userDetails={userDetails}
                              handleShowEnablePCEModal={handleShowEnablePCEModal}
                            />
                          </Col>
                        ) : null
                      ) : (
                        <div className="col-sm-12 mt-3 text-center">
                          <h5> No Clients found.</h5>
                        </div>
                      )}
                    </Row>
                  </>
                }
                {tabKey === "users" &&
                  <div className="pt-3">
                    <AllUsers
                      isLoadingAllUsers={isLoadingAllUsers}
                      allUsers={allUsers}
                      allUsersFilteredData={allUsersFilteredData}
                      setAllUsersFilteredData={setAllUsersFilteredData}
                      bgColor={bgColor}
                      textColor={textColor}
                    />
                  </div>
                }
                {tabKey === "integrations" &&
                  <div className="pt-3">
                    <AllIntegrations
                      isLoadingAllIntegrations={isLoadingAllIntegrations}
                      allIntegrations={allIntegrations}
                      allIntegrationsFilteredData={allIntegrationsFilteredData}
                      setAllIntegrationsFilteredData={setAllIntegrationsFilteredData}
                      bgColor={bgColor}
                      textColor={textColor}
                    />
                  </div>
                }
                {tabKey === "integration-schedule" && 
                  <>
                    {!isLoadingAllIntegrations ?
                      <div className="pt-3">
                        <IntegrationSchedule
                          isLoadingAllIntegrations={isLoadingAllIntegrations}
                          allIntegrations={allIntegrations}
                          allIntegrationsFilteredData={allIntegrations}
                          setAllIntegrationsFilteredData={setAllIntegrationsFilteredData}
                          bgColor={bgColor}
                          textColor={textColor}
                        />
                      </div>
                      :
                      <CustomSpinner />
                    }
                  </>
                }
              </ClientPageTabs>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Lists;
