import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useEffect, useState } from 'react'
import EditGridView from './EditGridView'
import { NewEVAResponseTreeviewData } from 'services/EVAResponseService'

function EditGridComponent({ gridHeader, sqlresult, theme, item, treeViewData, isWithChild, isShowGrid, toggleAll, handleToggleEditGrid, handleSaveGridFormula }) 
{
    const [newUpdateCount, setNewUpdateCount] = useState(0)
    const [GridData, setGridData] = useState([])
    const [reformattedData, setReformattedData] = useState([])
    const [isSubWithChild, setIsSubWithChild] = useState(false)

    useEffect(() => {
        setGridData(sqlresult)
    },[sqlresult])

    useEffect(() => {

        if(!GridData) return 
        
        const res = NewEVAResponseTreeviewData(GridData)
        setReformattedData(res.newData)
        setIsSubWithChild(res.isSubWithChild)

    },[GridData, newUpdateCount])

    useEffect(() => { 
        // console.log(isSubWithChild) 
    },[isSubWithChild])

    const handleUpdateGridData = (newData) => {
        setNewUpdateCount(prev => prev + 1)
        setGridData(newData)
    }
    const getDuplicate = (arr) => {
        const seen = {};
        const result = [];
    
        arr.forEach(item => {
            const parent = item.parent;
            const sub = item.sub;
            if (!seen[parent]) {
                seen[parent] = new Set();
            }
            const isDuplicate = seen[parent].has(sub);

            result.push({
                parent: parent,
                sub: sub,
                isDuplicate: isDuplicate
            });
            seen[parent].add(sub);
        });
    
        return seen;
    }
    return (
        <div className='EditGridContainer'>
            {/* <div onClick={handleToggleEditGrid}> Exit</div> */}
        {
            <EditGridView
                theme={theme} 
                sqlresult={sqlresult} 
                EVAData={sqlresult}
                item={item}
                gridHeader={gridHeader}
                reformattedData={reformattedData}
                isWithChild={isWithChild}
                isSubWithChild={isSubWithChild}
                handleUpdateGridData={handleUpdateGridData}
                handleSaveGridFormula={handleSaveGridFormula}
                handleToggleEditGrid={handleToggleEditGrid}
            />
        }
        </div>
    )
}

export default EditGridComponent