import { indexOf } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IsLastCharOperator } from 'services/EVAEditGridService'
import { ReplaceCellIdsWithValues } from 'services/EVAEditGridService'
import { EvaluateFormula } from 'services/EVAEditGridService'
import { NewParseFormula } from 'services/EVAEditGridService'
import { CalculateCellBaseValue } from 'services/EVAEditGridService'
import { RemoveCellChildFormula } from 'services/EVAEditGridService'
import { generateUUID } from 'services/EVAResponseService'
// import { CalculateCellBaseValue } from 'services/EVAEditGridService'


function GridDataSubWithChild({ sqlresult, gridHeader, handleUpdateData, data, newDataSorting, styleAlignRight, handleUpdateGridData, handleFormulaInputChange, handleAddUpdateCellFormulaList, handleAddUpdateChildRow}) 
{
    const [selectedParentRowIndex, setSelectedParentRowIndex] = useState(null)
    const [selectChildRowIndex, setSelectChildRowIndex] = useState(null)
    const [selectedColChildIndex, setSelectChildColumnIndex] = useState(null)
    const [selectedCellValue, setSelectedCellValue] = useState(null)
    const [selectedCellID, setSelectedCellID] = useState(null)
    const [isEditingCell, setIsEditingCell] = useState(null);
    const [cellFormulaList, setCellFormulaList] = useState([])
    const [cellExistingFormula, setCellExistingFormula] = useState(null)
    const [newChildRowList, setNewChildRowList] = useState([])
    const [newSubRowList, setNewSubRowList] = useState([])
    const [newParentRowList, setNewParentRowList] = useState([])
    const [cellFormulaTDOnChangeValue, setCellFormulaTDOnChangeValue] = useState(null)
    const [childListCellValue, setChildListCellValue] = useState([])
    const [newRowCount, setNewRowCount] = useState(1)

    useEffect(() => {
        const cellIdValue = []
        data && data.map((item => {
            item && item.child.details.map((child, index) => {
                for(let i=0; i < gridHeader.length; i++)
                {
                    if(child[i-2] !== undefined)
                    {
                        let childHead = child[0]
                        if(child[0].length === 0) {
                            childHead = index
                            // console.log(`[${item.parent}.${item.sub}.${childHead}.${gridHeader[i]}]`)
                        }
                        const cellId = `[${item.parent}.${item.sub}.${childHead}.${gridHeader[i]}]`
                        const cellVal = typeof child[i-2] === 'string' ? 0 : child[i-2]
                        cellIdValue.push({ cellId, cellVal})
                    }
                }
            })
        }))
        // console.log(newDataSorting)
        // console.log(data)
        setChildListCellValue(cellIdValue)
    },[data])
    useEffect(() => {
        console.log(childListCellValue)
    },[childListCellValue])
    useEffect(() => {
        // if(isEditingCell)
            // console.log(isEditingCell)
    },[isEditingCell])
    useEffect(() => {
        if(cellFormulaTDOnChangeValue)
        handleFormulaInputChange(cellFormulaTDOnChangeValue)
    },[cellFormulaTDOnChangeValue])
    useEffect(() => {
        // console.log(cellFormulaList)
        handleAddUpdateCellFormulaList(cellFormulaList)
    },[cellFormulaList])
    useEffect(() => {
        
        if(cellExistingFormula) handleFormulaInputChange(cellExistingFormula.formula)
        else handleFormulaInputChange('')
    }, [cellExistingFormula])
    useEffect(() => {
        handleProcessAddedChildRow(newChildRowList, newSubRowList, newParentRowList)
        handleAddUpdateChildRow(newChildRowList, newSubRowList, newParentRowList)
    },[newChildRowList, newSubRowList, newParentRowList])

    const reformatEVAColumnValue = (value) => {
        return isNaN(value) ? value : Number(value).toLocaleString()
    }
    const handleProcessAddedChildRow = (newChildRowList, newSubRowList, newParentRowList) => {
        const newData = [...sqlresult]

        const orderedParentRowData = newParentRowList.sort((a, b) => {
            if (a.parent < b.parent) return -1;
            if (a.parent > b.parent) return 1;

            if (a.sub < b.sub) return -1;
            if (a.sub > b.sub) return 1;

            return 0;
        });

        let parentGroupIndex = 0
        orderedParentRowData.length > 0 && orderedParentRowData.map((item, index) => {
            let lastIndex = -1;
            if(index > 0 && orderedParentRowData[index].parent === orderedParentRowData[index - 1].parent)
            {
                parentGroupIndex++
            }else {
                parentGroupIndex=0
            }

            for (let i = 0; i < newData.length; i++) {
                if (newData[i][0] === item.parent) {
                    lastIndex = i + parentGroupIndex;
                }
            }
            newData.splice(lastIndex + 1, 0, item.rowData);
        })


        const orderedData = newSubRowList.sort((a, b) => {
            if (a.parent < b.parent) return -1;
            if (a.parent > b.parent) return 1;
            
            if (a.sub < b.sub) return -1;
            if (a.sub > b.sub) return 1;
            
            return 0;
        });

        let groupIndex = 0
        orderedData.length > 0 && orderedData.map((item, index) => {
            let lastIndex = -1;
            if(index > 0 && orderedData[index].parent === orderedData[index - 1].parent && orderedData[index].sub === orderedData[index - 1].sub)
            {
                groupIndex++
            }else {
                groupIndex=0
            }

            for (let i = 0; i < newData.length; i++) {
                
                if (newData[i][0] === item.parent && newData[i][1] === item.sub) {

                    lastIndex = i + groupIndex;
                }
            }

            newData.splice(lastIndex + 1, 0, item.rowData);
        })
 
        newChildRowList.map((row) => {
            newData.push(row)
        })

        handleUpdateGridData(newData)
    }
    const handleAddChildRow = async (e, parent, sub, colIndex) => {
        const newData = [...sqlresult]
        const newRow = new Array(newData[0].length).fill('');
        newRow[0] = parent
        newRow[1] = sub
        const rowId = data.find(i => i.parent === parent && i.sub === sub) 
        newRow[2] = `child-row-${newRowCount}`
        setNewRowCount(prev => prev + 1)
        setNewChildRowList(prev => [...prev, newRow])
    }
    const addSubParentRow = (parent, sub) => {

        const newData = [...sqlresult];
        const newRow = new Array(newData[0].length).fill('');
        // const rowId = data.filter(i => i.parent === parent) 
        // console.log({parent, sub})
        // console.log(newSubRowList.filter(i => i.rowData[1] === sub))
        const parentSub = newSubRowList.filter(i => i.rowData[1] === sub)
        newRow[0] = parent;
        newRow[1] = `sub-row-${newRowCount}`
        newRow[2] = `child-row-0`
        const newRowData = {
            parent: parent,
            sub: parentSub.length > 0 ? parentSub[0].sub : sub,
            rowData: newRow
        }
        setNewRowCount(prev => prev + 1)
        setNewSubRowList(prev => [...prev, newRowData])
    }
    const removeSubParentRow = (parent, sub) => {
        
        // console.log({parent, sub})
        // console.log("sub row list")
        // console.log(newSubRowList)
        const updatednewSubRowList = newSubRowList.filter(s => s.parent === parent && s.rowData[1] !== sub)
        // console.log("child row list")
        // console.log(newChildRowList)
        // console.log(newChildRowList.filter(s => s[0] !== parent || s[1] !== sub))
        const updatednewChildRowList = newChildRowList.filter(s => s[0] !== parent || s[1] !== sub)

        // console.log("Formula list")
        // console.log(cellFormulaList)
        // console.log(cellFormulaList.filter(s => !s.cellID.includes(`=[${parent}.${sub}`)))
        const updatedcellFormulaList = cellFormulaList.filter(s => !s.cellID.includes(`=[${parent}.${sub}`))


        // console.log("sub row list changed? ", newSubRowList.length !== updatednewSubRowList.length)
        // console.log("child row list changed? ", newChildRowList.length !== updatednewChildRowList.length)
        // console.log("child row list changed? ", cellFormulaList.length !== updatedcellFormulaList.length)

        // console.log(updatednewSubRowList)
        // console.log(updatednewChildRowList)
        // console.log(updatedcellFormulaList)
        
        
        if(cellFormulaList.length !== updatedcellFormulaList.length) setCellFormulaList(updatedcellFormulaList)
        if(newSubRowList.length !== updatednewSubRowList.length) setNewSubRowList(updatednewSubRowList)
        if(newChildRowList.length !== updatednewChildRowList.length) setNewChildRowList(updatednewChildRowList)
        // console.log(newSubRowList.filter(s => s.parent === parent && s.rowData[1] !== sub))
        // const newRow = new Array(newData[0].length).fill('');

        // let lastIndex = -1;
        // for (let i = 0; i < newData.length; i++) {
        //     if (newData[i][0] === parent && newData[i][1] === sub) {
        //         lastIndex = i;
        //     }
        // }
        
        
        // const rowId = data.filter(i => i.parent === parent) 
        // newRow[0] = parent;
        // newRow[1] = `sub-row-${rowId.length}`
        // newRow[2] = `child-row-0`
        // const newRowData = {
        //     parent: parent,
        //     sub: sub,
        //     rowData: newRow
        // }
        // setNewSubRowList(prev => [...prev, newRowData])
    }
    const handleRemoveChildRow = (e, parent, sub, colIndex, addedRow) => {
     
        // console.log({parent, sub, colIndex})
        const cellID = `${parent}.${sub}.${addedRow}`
        console.log(cellID)
        console.log("cellFormulaList: ", cellFormulaList)
        console.log("newChildRowList: ", newChildRowList)

        console.log("-----------------------------------")

        const newCellFormulaList = cellFormulaList.filter(i => !i.cellID.includes(cellID))
        const newChildRowListData = newChildRowList.filter(i => 
            i[0] !== parent || 
            i[1] !== sub || 
            i[2] !== addedRow
        )

        console.log(newCellFormulaList)
        console.log(newChildRowListData)

        setCellFormulaList(newCellFormulaList)
        setNewChildRowList(newChildRowListData)
    }
    const addParentRow = (parent) => 
    {
        const newData = [...sqlresult];
        const newRow = new Array(newData[0].length).fill('');
        // const rowId = data.filter(i => i.parent === parent) 
        newRow[0] = `parent-row-${newRowCount}`;
        newRow[1] = `sub-row-${newRowCount}`
        newRow[2] = `child-row-0`
        const newRowData = {
            parent: parent,
            sub: `sub-row-${newRowCount}`,
            rowData: newRow
        }
        setNewRowCount(prev => prev + 1)
        setNewParentRowList(prev => [...prev, newRowData])
    } 
    const removeParentRow = (parent) => 
    {
        const updatednewParentRowList = newParentRowList.filter(s => s.rowData[0] !== parent)
        const updatednewSubRowList = newSubRowList.filter(s => s.parent === parent)
        const updatednewChildRowList = newChildRowList.filter(s => s[0] !== parent)
        const updatedcellFormulaList = cellFormulaList.filter(s => !s.cellID.includes(`=[${parent}.`))

        setNewParentRowList(updatednewParentRowList)
        if(cellFormulaList.length !== updatedcellFormulaList.length) setCellFormulaList(updatedcellFormulaList)
        if(newChildRowList.length !== updatednewChildRowList.length) setNewChildRowList(updatednewChildRowList)
        if(newSubRowList.length !== updatednewSubRowList.length) setNewSubRowList(updatednewSubRowList)
    } 
    const handleDoubleClick = (parentRowIndex, childRowIndex, colChildIndex, value) => {
        setTempCellFormulaTDOnChangeValue(null)
        setIsEditingCell({ parentRowIndex, childRowIndex, colChildIndex });

        setSelectedParentRowIndex(parentRowIndex)
        setSelectChildRowIndex(childRowIndex)
        setSelectChildColumnIndex(colChildIndex)
        setSelectedCellValue(value)

        

        const parent = data[parentRowIndex].parent
        const sub = data[parentRowIndex].sub
        let childHead = data[parentRowIndex].child.details[childRowIndex][0]
        const col = gridHeader[colChildIndex+2]

        if(childHead.length === 0) childHead = childRowIndex

        const selectedCellID = `=[${parent}.${sub}.${childHead}.${col}]`

        const existingFormula = cellFormulaList.filter(f => f.cellID === selectedCellID)

        setSelectedCellID(selectedCellID)
        if(existingFormula.length > 0)
        {
            setCellExistingFormula(existingFormula[0])
            setCellFormulaTDOnChangeValue(existingFormula[0].formula)
        } else {
            setCellExistingFormula({formula : selectedCellID})
            setCellFormulaTDOnChangeValue('')
        }
    };
    const isEditing = (rowIndex, colIndex, childIndex) => {
        return (
          isEditingCell &&
          isEditingCell.parentRowIndex === rowIndex &&
          isEditingCell.childRowIndex === colIndex &&
          isEditingCell.colChildIndex === childIndex
        );
    };
    const formulaBuilder = () => {

    }
    const handleKeyDown = (rowIndex, colIndex, childIndex, e) => {
        if (e.key === 'Enter') {
            processFormula(rowIndex, colIndex, childIndex, e)
            clearCellFormula()
        } else if (e.key === 'Escape') {
            clearCellFormula()
        }
    };
    const clearCellFormula = () => {
        setSelectedCellID(null)
        setIsEditingCell(null);
        setCellExistingFormula(null)
        setCellFormulaTDOnChangeValue(null)
    }
    // Step 1: Parse the formula
    const NewParseFormula = (formula) => {
        if (!formula.startsWith('=')) {
            const numberMatch = formula.match(/=(\d+\.?\d*)/); // Match numbers after '='
          
            if (numberMatch) {
                return { number: parseFloat(numberMatch[1]) };
            } else {
                // Handle letter references or other cases
                return { text: formula.trim() };
            }
        }

        const sumMatch = formula.match(/=sum\((.*)\)/i);
        const avgMatch = formula.match(/=avg\((.*)\)/i);

        if (sumMatch) {
            const [_, parent, subParent] = sumMatch[1].match(/(.*?)\.(.*)/);
            return { function: 'sum', parent: parent.trim(), subParent: subParent.trim() };
        } else if (avgMatch) {
            const [_, parent, subParent] = avgMatch[1].match(/(.*?)\.(.*)/);
            return { function: 'avg', parent: parent.trim(), subParent: subParent.trim() };
        }

        const regex = /(\[.*?\])|([\+\-\*\/])|(-?\d+(\.\d+)?)/g;
        return formula.match(regex);
    };
    // Step 2: Replace cell IDs with their corresponding values
    const ReplaceCellIdsWithValues = (parsedFormula, cellIdValue, selectedCellID) => {
        return parsedFormula.map(token => {
            const newToken = token.trim()
            if (/[\+\-\*\/]/.test(newToken) && newToken.length === 1) {
            
                return newToken; // If the token is an operator or a number, return it as is
            } else {
                const cellFormula = cellFormulaList && cellFormulaList.filter(f => f.cellID === `=${token}`)
           
                if(cellFormula.length > 0 && selectedCellID !== `=${token}`)
                {
                    return cellFormula[0].newVal
                }else
                {
                    const cell = cellIdValue.find(cell => cell.cellId === token);
                    if (cell) {
                        return cell.cellVal; // Replace cell ID with its value
                    } else {
                        return token
                        // throw new Error(`Cell ID ${token} not found in cellIdValue`);
                    }
                }
            }
        });
    };
    // Step 3: Evaluate the formula
    const EvaluateFormula = (replacedFormula) => {
        let isPercentage = false
        const convertedFormula = replacedFormula.map(item => {
            if (typeof item === 'string' && item.includes('%')) {
                isPercentage = true
                return parseFloat(item.replace('%', '').trim())
            }
            return item;
        });
        const expression = convertedFormula.join('');

        let result;
        try {
            result = eval(expression);
        } catch (error) {
            console.error('Error evaluating formula:', error);
            return null;
        }

        // Convert the result back to a percentage string
        if(isPercentage) 
            return `${result.toFixed(2)} %`
        else
            return result;
    };
    const processFormula = (rowIndex, colIndex, childIndex, e) => {
        try{
            if(e.target.innerText === selectedCellID || e.target.innerText === '' || e.target.innerText === '=') {
                const newData = RemoveCellChildFormula(selectedCellID, cellExistingFormula.formula, cellFormulaList)
                setCellFormulaList(newData)
                return
            }
           
            if(e.target.innerText === cellExistingFormula.formula) return
            const parsedFormula = NewParseFormula(e.target.innerText);
           
            let finalResult
            if (parsedFormula.number !== undefined) finalResult = isNaN(parsedFormula.text) ? parsedFormula.text : parseFloat(parsedFormula.text);
            else if (parsedFormula.text !== undefined) finalResult = isNaN(parsedFormula.text) ? parsedFormula.text : parseFloat(parsedFormula.text);
            else if (parsedFormula.function !== undefined) {
                finalResult = processFormulaFunction(parsedFormula, gridHeader[childIndex+2], selectedCellID)
            } else {
                const replacedFormula = ReplaceCellIdsWithValues(parsedFormula, childListCellValue, selectedCellID);
                finalResult = EvaluateFormula(replacedFormula);
            }

            const cell = childListCellValue.find(cell => cell.cellId === selectedCellID.replace('=', ''));
            const param = {
                cellID: selectedCellID,
                formula: e.target.innerText,
                newVal: finalResult,
                originalVal: cell !== undefined ? cell.cellVal : null
            }
            setCellFormulaList(prevParams => {
                const existingIndex = prevParams.findIndex(p =>
                    p.cellID === param.cellID
                );
        
                if (existingIndex !== -1) {
                    const updatedParams = [...prevParams];
                    updatedParams[existingIndex] = param;
                    return updatedParams;
                } else {
                    return [...prevParams, param];
                }
            });

        } catch (error) {
            console.error(`Error processing formula: ${error.message}`);
        }
    }
    const processFormulaFunction = (parsedFormula,column, selectedCellID) => {
        const filteredCells = childListCellValue.filter(cell =>
            cell.cellId.includes(parsedFormula.parent) &&
            cell.cellId.includes(parsedFormula.subParent) &&
            cell.cellId.includes(column) &&
            cell.cellId !== selectedCellID.replace('=', '')
        );
       
        const cellValues = filteredCells.map((cell) => {
            const c = cellFormulaList.find(i => i.cellID === `=${cell.cellId}`)
            if(c !== undefined) 
                return  isNaN(c.newVal) || c.newVal === '' ? 0 : parseFloat(c.newVal)
            else
                return  isNaN(cell.cellVal) || cell.cellVal === '' ? 0 : parseFloat(cell.cellVal)
        });

        let total = 0
        if (parsedFormula.function === 'sum') {
            total = cellValues.reduce((acc, val) => acc + val, 0);
        } else if (parsedFormula.function === 'avg') {
            total = cellValues.reduce((acc, val) => acc + val, 0) / cellValues.length;
        }
        return total
    }
    const processFormulaResult = (formula, selectedCellID, column) => {
        const parsedFormula = NewParseFormula(formula);

        if (parsedFormula.number !== undefined) {
            return parsedFormula.number;
        } else if (parsedFormula.text !== undefined) {
            return parsedFormula.text;
        } else if (parsedFormula.function !== undefined) {
            return processFormulaFunction(parsedFormula, column, selectedCellID)
        } else {
            const replacedFormula = ReplaceCellIdsWithValues(parsedFormula, childListCellValue, selectedCellID);
            if(replacedFormula.length === 1) return replacedFormula[0]
            const finalResult = EvaluateFormula(replacedFormula);
            return finalResult
        }
    }
    const _ReplaceCellIdsWithValues = (parsedFormula, cellIdValue, selectedCellID) => {
        return parsedFormula.map(token => {
            const newToken = token.trim()
            if ((/[\+\-\*\/]/.test(newToken) || /^-?\d+(\.\d+)?$/.test(newToken)) && newToken.length === 1) {
                return newToken; // If the newToken is an operator or a number, return it as is
            } else {
                const cellFormula = cellFormulaList && cellFormulaList.filter(f => f.cellID === `=${newToken}`)
                if(cellFormula.length > 0 && selectedCellID !== `=${newToken}`)
                {
                    return cellFormula[0].newVal
                }else{
                    const cell = cellIdValue.find(cell => cell.cellId === newToken);
                    if (cell) {
                        return cell.cellVal; // Replace cell ID with its value
                    } else {
                        const cellFormula = cellFormulaList && cellFormulaList.filter(f => f.cellID === `=${newToken}` )
                        throw new Error(`Cell ID ${newToken} not found in cellIdValue`);
                    }
                }
                
            }
        });
    };
    const handleGetCellWithFormula = (parentRowIndex, childRowIndex, colChildIndex, child) => {
        const parent = data[parentRowIndex].parent
        const sub = data[parentRowIndex].sub
        let childHead = data[parentRowIndex].child.details[childRowIndex][0]
        const col = gridHeader[colChildIndex+2]

        if(childHead.length === 0) childHead = childRowIndex

        const selectedCellID = `=[${parent}.${sub}.${childHead}.${col}]`
        

        const existingFormula = cellFormulaList.length > 0 && cellFormulaList.filter(f => f.cellID === selectedCellID)
        
        if(existingFormula.length > 0)
        {
            const result = processFormulaResult(existingFormula[0].formula, selectedCellID, col)
            return result
        }

        return child
    }
    const handleIsCellWithFormula = (parentRowIndex, childRowIndex, colChildIndex) => {
        const parent = data[parentRowIndex].parent
        const sub = data[parentRowIndex].sub
        let childHead = data[parentRowIndex].child.details[childRowIndex][0]
        const col = gridHeader[colChildIndex+2]

        if(childHead.length === 0) childHead = childRowIndex

        const selectedCellID = `=[${parent}.${sub}.${childHead}.${col}]`

        const existingFormula = cellFormulaList.length > 0 && cellFormulaList.filter(f => f.cellID === selectedCellID)

        // if(data[parentRowIndex].child.details[childRowIndex][0].includes("child-row")) return true
        return existingFormula.length > 0;
    };
    const handleTDOnchange = (e) => {
        // handleFormulaInputChange(e.target.innerText)
        if(IsLastCharOperator(e.target.innerText))
            setTempCellFormulaTDOnChangeValue(null)

        setCellFormulaTDOnChangeValue(e.target.innerText)
    }
    const [tempCellFormulaTDOnChangeValue, setTempCellFormulaTDOnChangeValue] = useState(null)
    const handleSelectTD = (parentRowIndex, childRowIndex, colChildIndex) => {
        if(!selectedCellID) return 
        const parent = data[parentRowIndex].parent
        const sub = data[parentRowIndex].sub
        let childHead = data[parentRowIndex].child.details[childRowIndex][0]
        const col = gridHeader[colChildIndex+2]

        if(childHead.length === 0) childHead = childRowIndex

        const _selectedCellID = `=[${parent}.${sub}.${childHead}.${col}]`

        if(_selectedCellID === selectedCellID) return

        const isOperator = IsLastCharOperator(cellFormulaTDOnChangeValue.trim())
        const newFormula = `${cellFormulaTDOnChangeValue}${_selectedCellID.replace('=', '')}` 


        if(isOperator)
        {
            setTempCellFormulaTDOnChangeValue(newFormula)
            // setCellFormulaTDOnChangeValue(newFormula)
            // setTempCellFormulaTDOnChangeValue(null)
        }else{
            // 
        }
   
    }
    const handleSelectSubParent = (parent,subParent) => {
        if(!selectedCellID) return 
        const newFormula = `${cellFormulaTDOnChangeValue}${parent}.${subParent}` 
        setCellFormulaTDOnChangeValue(newFormula)
    }
    const EditableCell = ({
        col,
        rowIndex,
        colIndex,
        childIndex,
        child,
        isEditing,
        isEditingCell,
        handleDoubleClick,
        // handleBlur,
        handleKeyDown,
        styleAlignRight,
        cellExistingFormula,
        handleGetCellWithFormula,
        handleIsCellWithFormula,
        handleTDOnchange,
        handleSelectTD
    }) => {
        const tdRef = useRef(null);

        useEffect(() => {
            if (isEditing(rowIndex, colIndex, childIndex) && tdRef.current) {
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(tdRef.current);
                range.collapse(false); // Move caret to the end
                selection.removeAllRanges();
                selection.addRange(range);
                tdRef.current.focus();
            }
        }, [isEditing, rowIndex, colIndex, childIndex]);

        const isCellEditing = isEditingCell && 
            isEditingCell.parentRowIndex === rowIndex &&
            isEditingCell.childRowIndex === colIndex &&
            isEditingCell.colChildIndex === childIndex;
        const cellValue =  handleGetCellWithFormula(rowIndex, colIndex, childIndex, child)
        const isWithFormula = handleIsCellWithFormula(rowIndex, colIndex, childIndex, child)

        let isAddedRow = false;

        if (typeof col[0] === 'string') 
        {
            isAddedRow = col[0].includes("child-row")
        }
        // if(isAddedRow) console.log(col)
        return (
            <td
                ref={tdRef}
                contentEditable={isEditing(rowIndex, colIndex, childIndex)}
                suppressContentEditableWarning
                onDoubleClick={() => {
                    handleDoubleClick(rowIndex, colIndex, childIndex, child);
                    if (tdRef.current) {
                        const range = document.createRange();
                        const selection = window.getSelection();
                        range.selectNodeContents(tdRef.current);
                        range.collapse(false); // Move caret to the end
                        selection.removeAllRanges();
                        selection.addRange(range);
                        tdRef.current.focus(); // Focus on double-click
                    }
                }}
                onKeyDown={(e) => handleKeyDown(rowIndex, colIndex, childIndex, e)}
                style={isCellEditing ? {} : styleAlignRight(isCellEditing ? tempCellFormulaTDOnChangeValue ? tempCellFormulaTDOnChangeValue : cellFormulaTDOnChangeValue : isWithFormula ? cellValue : child)}
                className={`${isWithFormula ? 'with-formula-td' : ''} ${isAddedRow ? 'addedSubRow' : ''}`}
                onInput={(e) => handleTDOnchange(e)}
                onClick={e => handleSelectTD(rowIndex, colIndex, childIndex, child)}
            >
                {isCellEditing ? (
                    <>
                        {tempCellFormulaTDOnChangeValue ? tempCellFormulaTDOnChangeValue : cellFormulaTDOnChangeValue}
                    </>
                ) : (
                    isWithFormula ? 
                    <>
                        <div className='with-formula-div'>
                            {cellValue}
                        </div>
                    </>
                    :
                    <>
                        {/* {child} */}
                       {isAddedRow ? childIndex === 0 ? 'New ' + `${gridHeader[2]} ${colIndex}`: '' : child}
                    </>
                )}
            </td>
        );
    };
    const EmptyTD = ({
        item,
        parent,
        sub,
        col,
        colIndex,
        handleAddChildRow,
        handleRemoveChildRow
    }) => {
    const addedRow = col[0]

    return (
        <>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right'}} >
                {item.child.details.length - 1 === colIndex && (
                    <span className='addSubChildRow' onClick={(e) => handleAddChildRow(e, parent, sub, colIndex)}>
                        <i className='ni ni-fat-add' />
                    </span>
                )}
                {addedRow.includes("child-row") && addedRow !== "child-row-0" &&
                    <span className='removeSubChildRow' onClick={(e) => handleRemoveChildRow(e, parent, sub, colIndex, addedRow)}>
                        <i className='ni ni-fat-delete' />
                    </span>
                }
            </td>
        </>
    )}
    const RenderParentSub = ({
            item,
            rowIndex,
            addParentRow,
            addSubParentRow,
            removeSubParentRow,
            handleSelectSubParent,
            reformatEVAColumnValue
        }) => {
        if(item.parent.includes("parent-row")) console.log(item.parent !== data[rowIndex - 1].parent)

        return (
            <>
                
                {rowIndex === 0 ?
                    <tr>
                        {!item.parent.includes("parent-row")  &&
                            <td style={{ textAlign: 'right'}}>
                                <span className='addSubChildRow' onClick={(e) => addParentRow(item.parent)}>
                                    <i className='ni ni-fat-add' />
                                </span>
                            </td>
                        }
                        <td colSpan={gridHeader ? gridHeader.length : 1}>{item.parent}</td>
                    </tr>
                :
                    item.parent !== data[rowIndex - 1].parent && 
                    <tr>
                        <td style={{ textAlign: 'right'}} >
                            {!item.parent.includes("parent-row")  &&
                                <span className='addSubChildRow' onClick={(e) => addParentRow(item.parent)}>
                                    <i className='ni ni-fat-add' />
                                </span>
                            }
                            {item.parent.includes("parent-row")  &&
                                <span className='removeSubChildRow' onClick={(e) => removeParentRow(item.parent, item.sub)}>
                                    <i className='ni ni-fat-delete' />
                                </span>
                            }
                        </td>
                        <td 
                            colSpan={gridHeader ? gridHeader.length : 1}
                            className={`${item.parent.includes("parent-row") && 'addedSubRow'}`}
                        >{`${item.parent.includes("parent-row") ? 'New ' + `${gridHeader[0]} ${rowIndex}` : item.parent}`}</td>
                    </tr>
                }

                <tr>
                    <td></td>
                    <td style={{ textAlign: 'right'}}>
                        <span className='addSubChildRow' onClick={(e) => addSubParentRow(item.parent, item.sub)}>
                            <i className='ni ni-fat-add' />
                        </span>

                        {item.sub.includes("sub-row") && rowIndex > 0 && data[rowIndex - 1]?.parent === item.parent &&
                            <span className='removeSubChildRow' onClick={(e) => removeSubParentRow(item.parent, item.sub)}>
                                <i className='ni ni-fat-delete' />
                            </span>
                        }
                    </td>
                    
                    <td 
                        colSpan={gridHeader ? gridHeader.length - 1 : 1} 
                        onClick={(e) => handleSelectSubParent(item.parent, item.sub)}
                        className={item.sub.includes("sub-row") ? 'addedSubRow' : ''}
                    >
                        {item.sub.includes("sub-row") ? 'New '+ `${gridHeader[1]} ${rowIndex}` : reformatEVAColumnValue(item.sub)}
                    </td>
                </tr>

            </>
        )
    }
    return (
        <>
        {data && data.length > 0 && data.map((item, rowIndex) => (
            <React.Fragment key={rowIndex}>
                <>
                <RenderParentSub 
                    item={item}
                    rowIndex={rowIndex}
                    addParentRow={addParentRow}
                    addSubParentRow={addSubParentRow}
                    removeSubParentRow={removeSubParentRow}
                    handleSelectSubParent={handleSelectSubParent}
                    reformatEVAColumnValue={reformatEVAColumnValue}
                />
                </>
                {item.child.details.map((col, colIndex) => (
                <tr key={`row-${rowIndex}-col-${colIndex}`}>
                    <EmptyTD 
                        item={item}
                        col={col}
                        parent={item.parent}
                        sub={item.sub}
                        colIndex={colIndex}
                        handleAddChildRow={handleAddChildRow}
                        handleRemoveChildRow={handleRemoveChildRow}
                    />
                    {col.map((child, childIndex) => (
                        <EditableCell
                            key={`row-${rowIndex}-col-${colIndex}-child-${childIndex}`}
                            rowIndex={rowIndex}
                            colIndex={colIndex}
                            col={col}
                            childIndex={childIndex}
                            child={child}
                            isEditing={isEditing}
                            isEditingCell={isEditingCell}
                            handleDoubleClick={handleDoubleClick}
                            // handleBlur={handleBlur}
                            handleKeyDown={handleKeyDown}
                            styleAlignRight={styleAlignRight}
                            cellExistingFormula={cellExistingFormula}
                            handleGetCellWithFormula={handleGetCellWithFormula}
                            handleIsCellWithFormula={handleIsCellWithFormula}
                            handleTDOnchange={handleTDOnchange}
                            handleSelectTD={handleSelectTD}
                        />
                    ))}
                </tr>
                ))}
            </React.Fragment>
        ))}
        </>
    )
}

export default GridDataSubWithChild