import React, { useContext } from "react";
import { Button, Row } from "reactstrap";
import { MainContext } from "context/mainContext";

export const CustomButton = ({ title, outlineStyle, icon, size, ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);

  let style = { ...props.buttonstyle };

  outlineStyle === false
    ? (style = {
      backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
      color: `${textColor === "#000" ? "#fff" : textColor}`,
      borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
      ...style
    })
    : (style = {
      color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
      borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
      ...style
    });
  return (
    <Button
      style={style}
      {...props}
      disabled={props.disabled ? props.disabled : false}
      size={size}
    >
      {icon ? <i className={icon} /> : null}
      {icon ? " " : null}
      {title}
    </Button>
  );
};

export const CustomModalButton = ({ ...props }) => {
  return (
    <Row className="modal-footer py-0">
      {props.submitButton != false ? ( //Added by Joef: Added option to hide submit Button
        <CustomButton
          data-dismiss="modal"
          type={props.submitType}
          onClick={props.submitButton}
          title={props.submitText}
          outlineStyle={false}
        />
      ) : null}
      <CustomButton
        outline
        type="button"
        color="secondary"
        data-dismiss="modal"
        onClick={props.closeButton}
        title={props.cancelText}
        outlineStyle={true}
      />
    </Row>
  );
};
