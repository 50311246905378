/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react"; //Edited by Joef: Needed for Private Route
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "./assets/css/custom-style.css";


//import AccountantLogin from "views/pages/examples/AccountantLogin/AccountantLogin";
import ClientLogin from "views/pages/login/clientLogin/ClientLogin";
import AcceptInvitaion from "views/pages/login/acceptInvitation/AcceptInvitation";

// import AdminLayout from 'layouts/Admin-copy.js'
// import RTLLayout from 'layouts/RTL.js'
// import AuthLayout from 'layouts/Auth.js'
// import IndexView from 'views/Index.js'
import MainLayout from "layouts/Admin";
// import Client from 'views/pages/client/Client'
import MainContextProvider from "context/mainContext";
import SecurityWrapper from "components/Security/SecurityWrapper";
// import MultifactorAuth from "views/pages/login/MultifactorAuth/MultifactorAuth";
import ResetPassword from "views/pages/login/resetPassword/ResetPassword";
import InviteUsed from "views/pages/login/acceptInvitation/InviteUsed";
import PageNotFound from "views/pages/login/acceptInvitation/PageNotFound";
// import AccountantLogin from "views/pages/login/accountantLogin/AccountantLogin";
// import ClientLogin from "views/pages/login/clientLogin/ClientLogin";

// Paul Code Starts Here -------------------->>>
import MultiFactorPage from "views/pages/login/MultifactorAuth/MultiFactorPage";
import MFAPushNotificationPage from "views/pages/login/MultifactorAuth/MFAPushNotificationPage";
import MFAGoogleAuthenticatorPage from "views/pages/login/MultifactorAuth/MFAGoogleAuthenticatorPage";
import GoogleAuthenticatorLoginPage from "views/pages/login/MultifactorAuth/GoogleAuthenticatorLoginPage";
import MFAScreenGoogleAuthenticator from "views/pages/login/MultifactorAuth/MFAScreenGoogleAuthenticator";
import InvitationLinkExpired from "views/pages/login/acceptInvitation/InvitationLinkExpired";
import LoginPopup from "components/Security/LoginPopup";
import EmailConfirmSuccess from "views/pages/login/trialUserPages/EmailConfirmSuccess";
import CancellationSuccess from "views/pages/cancellation/CancellationSuccess";
import MBAEmailConfirmSuccess from "views/pages/login/trialUserPages/MBAEmailConfirmSuccess";
// <<<-------------------- Paul Code Ends Here

import Modal from 'react-modal';
import { SocketProvider } from "service/SocketContext";
import AcceptClientUserInvitation from "views/pages/login/acceptInvitation/AcceptClientUserInvitation";
import PaymentSetup from "views/pages/login/paymentSetup/paymentSetup";
import ConfirmingEmail from "views/pages/login/trialUserPages/ConfirmingEmail";
import SharedPaymentSetup from "views/pages/login/paymentSetup/sharedPaymentSetup";
import SharedPageNotFound from "views/pages/login/paymentSetup/sharedPageNotFound";
import "./style.scss";

// Set the app element for react-modal
const rootElement = document.getElementById('root');
Modal.setAppElement(rootElement);

require('dotenv').config();

// Start Edited By Joef: Added Authentication, remove unnecessary routes <<<<<<<<<<<<<<<<<<<<

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const [auth, setAuth] = useState(false);
  // const [isTokenValidated, setIsTokenValidated] = useState(false);

  // async function checkLogin() {
  //   // console.log("Testing");
  //   const response = await fetch("/api/auth/checkLogin", {
  //     credentials: "include",
  //   });
  //   const parsedResult = await response.json();

  //   // console.log(parsedResult);
  //   return parsedResult.authenticated;
  // }

  // useEffect(() => {
  //   // send jwt to API to see if it's valid

  //   checkLogin()
  //     .then((result) => {
  //       setAuth(result);
  //     })
  //     .catch((err) => {
  //       setAuth(false);
  //     })
  //     .then(() => setIsTokenValidated(true));
  //     console.log(`Index [authorized: ${auth} - tokenValid:${isTokenValidated}]`);
  // }, []);

  // if (!isTokenValidated) return <div />; // or some kind of loading animation

  return (
    <Route
      {...rest}
      // render={(props) => {
      //   return auth ? (
      //     <MainContextProvider>
      //       <Component {...props} />
      //     </MainContextProvider>
      //   ) : (
      //     <Redirect to="/auth/login" />
      //   );
      // }}
      render={(props) => (
        <SecurityWrapper>
          <MainContextProvider>
            {/* <LoginPopup/> */}
            <Component {...props} />
          </MainContextProvider>
        </SecurityWrapper>
      )}
    />
  );
};

ReactDOM.render(
  <BrowserRouter>
    <SocketProvider>
      <Switch>
        <PrivateRoute path="/client" component={MainLayout} />
        {/* <Route
        path="/main"
        render={(props) => this.state.authenticated?(
          <MainContextProvider>
            <MainLayout {...props} />
          </MainContextProvider>
        ): <Redirect to="/auth/login" />}
      /> */}
        {/* <Route
        path="/client"
        render={(props) => (
          <MainContextProvider>
            <Client {...props} />
          </MainContextProvider>
        )}
      /> */}
        {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/" render={(props) => <IndexView {...props} />} /> */}
        <Route
          path="/auth/login"
          render={() => (
            <MainContextProvider>
              <ClientLogin />
            </MainContextProvider>
          )}
        />
        <Route path="/auth/setup-new-password" render={() => <ResetPassword />} />
        <Route
          path="/auth/create-password"
          render={() => (
            <AcceptInvitaion />
            // <MainContextProvider>
            // </MainContextProvider>
          )}
        />
        <Route
          path="/auth/client-user-create-password"
          render={() => (
            <AcceptClientUserInvitation />
            // <MainContextProvider>
            // </MainContextProvider>
          )}
        />
        <Route
          path="/auth/invite-used"
          render={() => (
            <MainContextProvider>
              <InviteUsed />
            </MainContextProvider>
          )}
        />
        <Route
          path="/auth/invite-link-expired"
          render={() => (
            <MainContextProvider>
              <InvitationLinkExpired />
            </MainContextProvider>
          )}
        />
        <Route
          path="/auth/page-not-found"
          render={() => (
            <MainContextProvider>
              <PageNotFound />
            </MainContextProvider>
          )}
        />
        {/* <Route
        path="/auth/multifactor"
        render={() => (
          <MainContextProvider>
            <MultifactorAuth />
          </MainContextProvider>
        )}
      /> */}
        <Route
          path="/auth/multi-factor"
          render={() => (
            <MainContextProvider>
              <MultiFactorPage />
            </MainContextProvider>
          )}
        />
        <Route
          path="/auth/multi-factor-push-notification-setup"
          render={() => (
            <MainContextProvider>
              <MFAPushNotificationPage />
            </MainContextProvider>
          )}
        />

        <Route
          path="/auth/multi-factor-google-authenticator"
          render={() => (
            <MainContextProvider>
              <MFAGoogleAuthenticatorPage />
            </MainContextProvider>
          )}
        />
        <Route
          path="/auth/mfa-login"
          render={() => (
            <MainContextProvider>
              <MFAScreenGoogleAuthenticator />
            </MainContextProvider>
          )}
        />
        <Route
          path="/auth/google-auth-screen"
          render={() => (
            <MainContextProvider>
              <GoogleAuthenticatorLoginPage />
            </MainContextProvider>
          )}
        />
        <Route
          path="/trialUser/confirm-email-success"
          render={() => <EmailConfirmSuccess />}
        />
        <Route
          path="/trialUser/confirming-email"
          render={() => <ConfirmingEmail />}
        />
        <Route
          path="/trialUser/confirm-mba-email-success"
          render={() => <MBAEmailConfirmSuccess />}
        />
        <Route
          path="/subscription/success-cancellation"
          render={() => <CancellationSuccess />}
        />
        <Route
          path="/trialUser/confirm-mba-email-success"
          render={() => <MBAEmailConfirmSuccess />}
        />
        <Route
          path="/trialUser/sharedPaymentLink"
          render={() => <SharedPaymentSetup />}
        />
        <Route
          path="/trialUser/shared-page-error"
          render={() => (
            <SharedPageNotFound />
          )}
        />
        <Route
          path="/signup/payment-setup"
          render={() => (
            <SecurityWrapper>
              <MainContextProvider>
                <PaymentSetup />
              </MainContextProvider>
            </SecurityWrapper>
          )}
        />
        <Redirect from="*" to="/client/customDashboard" />
      </Switch>
    </SocketProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
