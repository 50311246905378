import CustomSpinner from 'components/Misc/CustomSpinner';
import AllUsersTable from 'components/Table/AllUsersTable';
import IntegrationsTable from 'components/Table/IntegrationsTable';
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  Form,
  Input,
  FormGroup,
  Card,
  CardBody,
  ButtonGroup,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { dataFilterIntegrationsOnlyWithSelection, dataFilter } from 'utils/utilityFunctions';
import { MainContext } from "context/mainContext";

const AllIntegrations = ({ isLoadingAllIntegrations, allIntegrations, allIntegrationsFilteredData, setAllIntegrationsFilteredData, ...props }) => {
  const {
    bgColor,
    textColor,
    setContextClientLists,
    setSelectedClientID,
    selectedClientID,
    userDetails,
    firmDetails,
    getDetails,
    selectedClient,
    setSelectedClient,
    contextClientLists,
    setContextFilteredClientLists
  } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(true);
  // const [allIntegrations, setAllIntegrations] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterType, setFilterType] = useState("Integration")

  const inputRef = useRef(null);

  useEffect(async () => {
    // try {
    //   const getAllServices = await fetch("/api/clientService/getAllRecordsByBusiness")
    //   const resultServices = await getAllServices.json()
    //   console.log(resultServices)
    //   if (resultServices && !resultServices.error) {
    //     setAllIntegrations(resultServices)
    //     setFilteredData(resultServices)
    //     setIsLoading(false)
    //   }
    // } catch (error) {
    //   console.log(error)
    //   setIsLoading(false)
    // }
  }, [])

  const handleSearch = (e) => {
    let filterString = e.target.value;
    setAllIntegrationsFilteredData(dataFilterIntegrationsOnlyWithSelection(filterString, allIntegrations, filterType))
  }

  const handleChangeFilterType = (val) => {
    setFilterType(val)
    setAllIntegrationsFilteredData(allIntegrations)
    inputRef.current.value = '';
  }

  return (
    <>
      <Row>
        <Col lg="6" sm="4">
        </Col>
        <Col lg="6" sm="12">
          <Row>
            <Col lg="2" md="12" sm="12">
            </Col>
            {/* <Col lg="2"></Col> */}
            <Col lg="10" md="12" sm="12">
              <Row>
                <Col className="pr-0">
                  <div className="float-right">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        size='sm'
                        style={{
                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          color: `${textColor === "#000" ? "#fff" : textColor}`,
                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          paddingTop: '6px', paddingBottom: '6px'
                        }}
                      >
                        Filter by: {filterType}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => handleChangeFilterType("Integration")}>Integration</DropdownItem>
                        <DropdownItem onClick={() => handleChangeFilterType("Client")}>Client</DropdownItem>
                        <DropdownItem onClick={() => handleChangeFilterType("Name")}>Name</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    {/* <Input
                      placeholder="Search by Name"
                      type="text"
                      bsSize="sm"
                      onChange={handleSearch}
                      ref={inputRef}
                    /> */}
                    <input ref={inputRef} onChange={handleSearch} placeholder="Search by Name" type="text" className="form-control-sm form-control" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {
          isLoadingAllIntegrations ? <CustomSpinner />
            :
            allIntegrationsFilteredData.length >= 1 ?
              <Col lg="12">
                {/* <AllUsersTable
                  data={filteredData}
                /> */}
                <IntegrationsTable
                  data={allIntegrationsFilteredData}
                  accountingFirm={firmDetails}
                />
              </Col>
              :
              <Col lg="12">
                <div className='text-center my-5'>
                  <h5 className='align-middle'>No Integrations found.</h5>
                </div>
              </Col>
        }
      </Row>
    </>
  )
}

export default AllIntegrations;