import React from "react";
import { Container } from "reactstrap";

const CustomHeader = ({ style }) => {
  return (
    <div
      className="header header-dark content__title content__title--calendar"
      style={style}
    >
      <Container fluid>
        <div className="header-body"></div>
      </Container>
    </div>
  );
};

export default CustomHeader;
