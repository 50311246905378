import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import { Button, Col, FormGroup, Input, Modal } from "reactstrap";
import _ from "lodash";
import "react-image-crop/dist/ReactCrop.css";
import "./index.css";
import { MainContext } from "context/mainContext";

const CustomThumbnailField = ({ ...props }) => {
  let photo = props.value;
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "%", width: 100 });

  const { textColor, bgColor } = useContext(MainContext);
  const [onView, setOnView] = useState(false);
  const [srcImg, setSrcImg] = useState(null);
  const [result, setResult] = useState(null);
  const [image, setImage] = useState(photo);
  // eslint-disable-next-line
  const [oldImage, setOldImage] = useState(props.value);

  useEffect(() => {
    // setOldImage(photo) //fixed for resetting image
  }, [props.value]);

  const handleImageChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const reader = new FileReader();
    const file = target.files[0];
    // eslint-disable-next-line
    const value = target.value;

    reader.onload = function (upload) {
      setOnView(true);
      setImage(upload.target.result);

      let inputElement = target.nextSibling;
      inputElement.value = upload.target.result;
      inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      // console.log(target.name);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setOnView(false);
      return;
    }
  };

  const getCroppedImg = async (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;

    try {
      const canvas = document.createElement("canvas");
      const scaleX = srcImg.naturalWidth / srcImg.width;
      const scaleY = srcImg.naturalHeight / srcImg.height;

      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        srcImg,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/png", 1);

      setResult(base64Image);
      setImage(base64Image);
      setOnView(false);
      props.record[name] = base64Image;
      props.handleImgChange(base64Image);
      // console.log("FROMONLOAD",props.record.Logo || props.record.Avatar)
    } catch (e) {
      console.log("error", e);
    }
  };

  const resetImage = () => {
    setResult(oldImage);
    props.handleImgChange(oldImage);

    // const resetImage = (e) => {

    //   e.preventDefault()
    //   const target = e.target
    //   const name = target.name

    //   // console.log(name)
    //   setResult(oldImage)
    //   props.record[name] = oldImage //>>>>>> fixed for resetting image
    //   props.handleImgChange(oldImage)
  };

  return (
    <FormGroup>
      <label className="form-control-label">{props.label}</label>
      <div className="custom-file">
        <input
          className="custom-file-input"
          id={props.id}
          name={props.name}
          help={props.help}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          onClick={(e) => (e.target.value = null)}
          disabled={props.disabled ? props.disabled : false}
        />
        <input
          style={{ display: "none" }}
          name={props.name}
          onChange={props.onChange}
        />
        {props.withResetImage ? (
          <Button
            className="btn-icon btn-2"
            size="sm"
            color="secondary"
            type="button"
            name={props.name} //>>>>>> fixed for resetting image
            onClick={resetImage}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-redo"></i>
            </span>
          </Button>
        ) : null}
        <img
          alt={props.alt}
          src={
            !result
              ? props.value
                ? props.value
                : require("assets/img/blank-avatar.png").default
              : result
          }
          style={{
            width: "75px",
            objectFit: "cover",
            height: "75px",
            borderRadius: "0.375rem",
          }}
        />

        <Modal className="modal-dialog" isOpen={onView}>
          <div
            className="modal-body text-center py-sm-5 rounded"
            style={{ backgroundColor: "#f6f9fc", position: "relative" }}
          >
            <ReactCrop
              crop={crop}
              src={image}
              onChange={(e) => setCrop(e)}
              onImageLoaded={setSrcImg}
            />
          </div>

          <div
            className="modal-footer"
            style={{ justifyContent: "space-between" }}
          >
            <i
              onClick={() => setOnView(false)}
              className="ni ni-fat-remove text-lg"
              style={{ position: "absolute", right: 10, top: 10 }}
            />
            <div>
              <span className="">
                <strong>Note:</strong> Crop image before saving
              </span>
            </div>
            <Button
              size="md"
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              }}
              name={props.name}
              className="cropButton m-0"
              onClick={getCroppedImg}
            >
              Save
            </Button>
          </div>
        </Modal>
      </div>
    </FormGroup>
  );
};

export default CustomThumbnailField;

export const CustomFieldGroup = ({
  error,
  id,
  label,
  help,
  type,
  placeholder,
  ...props
}) => {
  switch (type) {
    case "time":
      return (
        <FormGroup id={id} className="mt-2">
          <label className="form-control-label">{label}</label>
          <Input type="select" {...props}>
            <option value="">{placeholder}</option>
            {_.times(24, (i) => {
              return (
                <option key={i} value={moment(i + 1, "H").format("HH")}>
                  {moment(i + 1, "H").format("ha")}
                </option>
              );
            })}
            /
          </Input>
          {/* {help && <HelpBlock>{help}</HelpBlock>} */}
        </FormGroup>
      );
    default:
      return (
        <FormGroup id={id}>
          <label className="form-control-label">{label}</label>
          <Input type={type} placeholder={placeholder} {...props} />
          {/* {help && <HelpBlock>{help}</HelpBlock>} */}
          {error}
        </FormGroup>
      );
  }
};

export const CustomToggleField = ({ ...props }) => {
  return (
    <Col className="d-flex mt-3" style={{ flexDirection: "column" }}>
      <label>{props.label}</label>
      <label className="custom-toggle custom-toggle-success mr-1">
        <input
          type={props.type}
          name={props.name}
          id={`${props.id}${props.i ? props.i : ""}`}
          defaultChecked={props.defaultChecked}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
        <span
          className="custom-toggle-slider rounded-circle"
          data-label-off="No"
          data-label-on="Yes"
        />
      </label>
    </Col>
  );
};

export const ExternalLink = (display, uri, target) => {
  return (
    <a
      href={
        uri ? (typeof uri === "string" ? uri : uri.url || display) : display
      }
      target={target || "_blank"}
    >
      {display}
    </a>
  );
};
