import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import { CustomToggleField } from "customComponents/Form";

const ClientTable = ({
  data,
  warningAlert,
  handleEditClient,
  userDetails,
  handleShowEnablePCEModal,
  ...props
}) => {
  const { setSelectedClientID, setSelectedClient, contextClientLists, firmDetails, bgColor, textColor } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    // console.log(firmDetails)
    setPaginatedData(data);
    // Get clientListNumberPerPage
    localStorage.getItem("clientListNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("clientListNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage, paginatedData, data]);

  const userFullName = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].FirstName} ${clientUserAdmin[0].LastName}`;
    }
    return "N/A";
  };

  const userEmail = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].Email}`;
    }
    return "N/A";
  };

  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  const currentPageData = () => {
    return paginatedData
      .slice(offset, Number(offset) + Number(numberPerPage))
      .map((ann, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }} className="all-clients-row">
            <td className="logo" onClick={(e) => tableRowClick(ann)}>
              <Media className="align-items-center">
                <img
                  className="rounded mr-3" //Edited by Joef: removed avatar
                  alt="..."
                  src={
                    ann.Logo
                      ? ann.Logo
                      : require(`assets/img/brand/logo-placeholder.png`).default
                  }
                  style={{
                    //Added by Joef: instead of avatar to retain dimension
                    width: "60px",
                  }}
                />
                {/* <a
                className="avatar rounded mr-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
              </a> */}
              </Media>
            </td>
            <td className="name" onClick={(e) => tableRowClick(ann)}>
              {ann.Name}
            </td>
            {
              firmDetails?.PricingPlan.Code === 'TIER6' ?
                !firmDetails?.Details?.PbiEnabled ?
                  null :
                  <td className="pbi">
                    <Col className="d-flex mt-3" style={{ flexDirection: "column" }}>
                      <label className="custom-toggle custom-toggle-success mr-1">
                        <input
                          type={"checkbox"}
                          name={"PBI"}
                          id={`PBI-${ann.id}`}
                          checked={ann.Details?.ShowPowerBIPage ? true : false}
                          onChange={(e) => handleShowEnablePCEModal(e, ann, 'pbi')}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Yes"
                        />
                      </label>
                    </Col>
                  </td>
                :
                <td className="pbi">
                  <Col className="d-flex mt-3" style={{ flexDirection: "column" }}>
                    <label className="custom-toggle custom-toggle-success mr-1">
                      <input
                        type={"checkbox"}
                        name={"PBI"}
                        id={`PBI-${ann.id}`}
                        checked={ann.Details?.ShowPowerBIPage ? true : false}
                        onChange={(e) => handleShowEnablePCEModal(e, ann, 'pbi')}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </Col>
                </td>
            }
            <td className="chat">
              <Col className="d-flex mt-3" style={{ flexDirection: "column" }}>
                <label className="custom-toggle custom-toggle-success mr-1">
                  <input
                    type={"checkbox"}
                    name={"CHAT"}
                    id={`CHAT-${ann.id}`}
                    checked={ann.Details?.ShowChatPage ? true : false}
                    onChange={(e) => handleShowEnablePCEModal(e, ann, 'chat')}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </Col>
            </td>
            <td className="eva">
              <Col className="d-flex mt-3" style={{ flexDirection: "column" }}>
                <label className="custom-toggle custom-toggle-success mr-1">
                  <input
                    type={"checkbox"}
                    name={"EVA"}
                    id={`EVA-${ann.id}`}
                    checked={ann.Details?.ShowEvaPage ? true : false}
                    onChange={(e) => handleShowEnablePCEModal(e, ann, 'eva')}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </Col>
            </td>
            {/* <td className="admin" onClick={(e) => tableRowClick(ann)}>
              {userFullName(ann.User)}
            </td>
            <td className="admin-email" onClick={(e) => tableRowClick(ann)}>
              {userEmail(ann.User)}
            </td> */}
            <td className="text-right">
              <div className="float-right mr-3">
                <Button
                  size="sm"
                  // color="warning"
                  style={{
                    backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                      }`,
                    color: `${textColor === "#000" ? "#fff" : textColor}`,
                    borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                      }`,
                  }}
                  onClick={() => warningAlert(
                    "deleteClient",
                    `Are youre you want to delete ${ann.Name} Client?`,
                    ann
                  )}
                  id={`deleteClient${ann.id}`}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-trash" />
                  </span>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target={`deleteClient${ann.id}`}
                >
                  {`Delete`}
                </UncontrolledTooltip>
              </div>
              <div className="float-right mr-3">
                <Button
                  size="sm"
                  // color="warning"
                  style={{
                    backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                      }`,
                    color: `${textColor === "#000" ? "#fff" : textColor}`,
                    borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                      }`,
                  }}
                  onClick={() => handleEditClient(ann)}
                  id={`editClient${ann.id}`}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-pen" />
                  </span>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target={`editClient${ann.id}`}
                >
                  {`Update`}
                </UncontrolledTooltip>
              </div>
              {/* <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  color=""
                  size="sm"
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => handleEditClient(ann)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      warningAlert(
                        "deleteClient",
                        `Are youre you want to delete ${ann.Name} Client?`,
                        ann
                      )
                    }
                  >
                    Delete
                  </DropdownItem>
                  <DropdownItem>
                    <UncontrolledDropdown
                      direction="left"
                      className="d-inline-block submenu"
                      onMouseOver={onMouseEnter2}
                      onMouseLeave={onMouseLeave2}
                      isOpen={dropdownOpen2}
                      toggle={toggle2}
                    >
                      <DropdownToggle
                        caret
                        tag="span"
                        onClick={toggle2}
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen2}
                      >
                        <div className="submenu-item"> View</div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => changeUrl("users", ann.ClientID)}
                        >
                          Users
                        </DropdownItem>
                        {
                          firmDetails?.Details?.PbiEnabled ?
                            <DropdownItem
                              onClick={() => changeUrl("reports", ann.ClientID)}
                            >
                              Power BI
                            </DropdownItem>
                            : null
                        }
                        <DropdownItem
                          onClick={() =>
                            changeUrl("thirdpartyapplication", ann.ClientID)
                          }
                        >
                          Integrations
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            changeUrl("chat", ann.ClientID)
                          }
                        >
                          Chat
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            changeUrl("eva", ann.ClientID)
                          }
                        >
                          EVA
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </td>
          </tr>
        );
      });
  };

  const pageCount = () => {
    return Math.ceil(data.length / numberPerPage);
  };

  const changeUrl = (page, clientID) => {
    sessionStorage.setItem("selectedClientID", clientID);
    let newSelectedClient = contextClientLists.filter(c => c.ClientID === clientID)
    // console.log('newSelectedClient', newSelectedClient)
    setSelectedClient(newSelectedClient[0])
    if (page === "users") {
      setSelectedClientID(clientID);
      return history.push(`/client/users/${clientID}`);
    }
    if (page === "advisers") {
      setSelectedClientID(clientID);
      return history.push(`/client/advisers/${clientID}`);
    }
    // if (page === "dashboard") {
    //   setSelectedClientID(clientID);
    //   return history.push(`/client/dashboard/${clientID}`);
    // }
    if (page === "reports") {
      setSelectedClientID(clientID);
      return history.push(`/client/reports/${clientID}`);
    }
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
    if (page === "signclient") {
      setSelectedClientID(clientID);
      return history.push(`/client/signclient/${clientID}`);
    }
    if (page === "chat") {
      setSelectedClientID(clientID);
      return history.push(`/client/chat`);
    }
    if (page === "eva") {
      setSelectedClientID(clientID);
      return history.push(`/client/eva`);
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th className="">
                  Client Logo
                </th>
                <th className="">
                  Client Name
                </th>
                {
                  firmDetails?.PricingPlan.Code === 'TIER6' ?
                    !firmDetails?.Details?.PbiEnabled ?
                      null :
                      <th className="">
                        Power BI Enabled?
                      </th>
                    : <th className="">
                      Power BI Enabled?
                    </th>
                }
                <th className="">
                  Chat Enabled?
                </th>
                <th className="">
                  EVA Enabled?
                </th>
                {/* <th className="">
                  Admin
                </th>
                <th className="">
                  Admin Email
                </th> */}
                {/* <th className="sort" data-sort="engagement" scope="col">Signed Engagement</th> */}
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                {
                  firmDetails?.PricingPlan.Code === 'TIER6' ?
                    !firmDetails?.Details?.PbiEnabled ?
                      null :
                      <td></td>
                    : <td></td>
                }
                <td></td>
                <td></td>
                {/* <td></td>
                <td></td> */}
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        <CardFooter className="py-4">
          {data && data.length >= 1 ? null : (
            <p className="text-center mt-3">No Clients found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              {/* <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={`${currentPage <= 0 ? 'disabled' : ''}`}>
                    <PaginationLink
                      onClick={e => handleChangePage(e, currentPage - 1)}
                      previous
                      href="#"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {showPages()}
                  <PaginationItem className={`${currentPage === (pageCount - 1) ? 'disabled' : ''}`}>
                    <PaginationLink
                      onClick={e => handleChangePage(e, currentPage + 1)}
                      previous
                      href="#"
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav> */}
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default ClientTable;
