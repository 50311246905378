import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();

// Hook to use the socket context
export const useSocket = () => {
  return useContext(SocketContext);
};

// Hook to listen to specific socket events
export const useSocketEvent = (eventName, callback) => {
  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.on(eventName, callback);

    // console.log("Listening to event ", eventName);

    return () => {
      socket.off(eventName, callback);
    };
  }, [eventName, callback, socket]);
};

// Hook for room management
export const useRoom = (roomName, platform = '') => {
  const socket = useSocket();

  useEffect(() => {
    if (!socket || !roomName) return;

    socket.emit('joinRoom', { roomName, platform  });
    // console.log(`Joined room ${roomName} on platform ${platform}`);

    return () => {
      socket.emit('leaveRoom', { roomName });
    };
  }, [roomName, socket]);
};

// The main provider
require('dotenv').config();
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // const socketInstance = io(websocketUrl, {
    //   withCredentials: true,
    //   transports: ['websocket', 'polling'],
    //   reconnection: true,                  // whether to reconnect automatically
    //   reconnectionAttempts: 10,            // number of reconnection attempts before giving up
    //   reconnectionDelay: 2000,             // initial delay (ms) before the first reconnection attempt
    //   reconnectionDelayMax: 10000,         // maximum delay (ms) between reconnection attempts
    //   randomizationFactor: 0.5
    // });

    // setSocket(socketInstance);

    // socketInstance.on('connect', () => {
    //   console.log('Connected to WebSocket', socketInstance.id);
    // });

    // socketInstance.on('disconnect', () => {
    //   console.log('Disconnected from WebSocket', socketInstance.id);
    // });

    // socketInstance.on('error', (error) => {
    //   console.log('Error from WebSocket', error);
    // });

    // // Cleanup effect to disconnect when the component unmounting the provider is no longer in use
    // return () => {
    //   socketInstance.disconnect();
    // };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
