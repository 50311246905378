const RunDataConversionService = async (sqlResult, header) => {
    const body = { 
        sqlresult: sqlResult,
        header: header
    }

    try {
        const newTreeData = await fetch("/api/eva/PopulateNewEVAResponseFormat", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
     
        const data = await newTreeData.json();
        // console.log("RunDataConversionService: ", data)
        return data.result
    } catch (error) {
        console.log(error);
    }
}
const ProcessEditedExcelGrid = async (editedGridCellData, sqlResult, header) => {
    const body = { 
        editedGridCellData: editedGridCellData,
        sqlresult: sqlResult,
        gridHeader: header
    }
    
    try {
        const newTreeData = await fetch("/api/eva/ProcessEditedExcelGrid", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        const data = await newTreeData.json();
        // console.log("ProcessEditedExcelGrid: ", data.result)
        return data.result
    } catch (error) {
        console.log(error);
    }
}

export { RunDataConversionService, ProcessEditedExcelGrid }