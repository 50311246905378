import React from "react";
import {
  Button,
  CardImg,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import QRcode from "qrcode.react";

const CustomTooltip = ({ message, id, i, className, onClick, ...props }) => {
  return (
    <>
      <div id={`${id}${i}`} onClick={onClick} className={props.leftdiv}>
        <span className="btn-inner--icon ml-1" style={{ cursor: "pointer" }}>
          <i className={className} {...props} />
        </span>
      </div>
      <UncontrolledTooltip
        delay={0}
        placement="top"
        target={`${id}${i}`}
        className="custom-tooltip"
      >
        {message}
      </UncontrolledTooltip>
    </>
  );
};

export default CustomTooltip;

export const PasswordLabel = ({ ...props }) => {
  return (
    <Row className="mt-2">
      {props.validType ? (
        <Col xs="1" style={{ fontSize: "12px", marginTop: "5px" }}>
          <i className="fas fa-times mr-2 text-warning " />
        </Col>
      ) : (props.password === props.repassword &&
          props.password !== "" &&
          props.testType) ||
        props.testType ? (
        <Col xs="1" style={{ fontSize: "12px", marginTop: "5px" }}>
          <i className="fas fa-check mr-2 text-success " />
        </Col>
      ) : null}
      <Col lg="10" xs="10">
        <span
          style={{ fontSize: "12px", transition: "all ease-in .3s" }}
          className={`font-weight-600  ${
            props.validType === true
              ? `text-warning`
              : (props.password === props.repassword &&
                  props.password !== "" &&
                  props.testType) ||
                props.testType
              ? `text-success`
              : `text-dark text-muted`
          }`}
        >
          {props.label}
        </span>
      </Col>
    </Row>
  );
};

export const CustomInputFields = ({ ...props }) => {
  return (
    <FormGroup className="mt-3 mb-1">
      <label htmlFor={props.id} className="mb-2 form-control-label">
        {props.label}
      </label>
      <InputGroup
        className="input-group-merge input-group-alternative"
        style={props.style}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            className={`${
              props.error !== "" || props.alert === true ? "text-warning" : ""
            }`}
          >
            <i className={props.className} />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          autoComplete="true"
          placeholder={props.placeholder}
          onChange={props.onChange}
          required={props.required}
          value={props.value}
          type={props.type}
          name={props.name}
          id={props.id}
        />
        {props.showPasswordIcon === true ? (
          <InputGroupAddon addonType="prepend" onClick={props.onClick}>
            <InputGroupText className="bg-transparent">
              <i
                className={
                  !props.showPassword
                    ? "far fa-eye "
                    : "far fa-eye-slash text-dark"
                }
              />
            </InputGroupText>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
    </FormGroup>
  );
};

export const CustomQRCode = ({ ...props }) => (
  <Col
    xs="6"
    md="6"
    sm="6"
    className={`text-center mt-3 ${props.padding === true ? "pl-0" : "pr-0"}`}
  >
    <a
      className="d-flex justify-content-center"
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      <CardImg
        style={props.cardStyle}
        className="appIcon"
        src={props.srcImg}
        alt={props.alt}
      />
    </a>
    {props.isDescription === true ? (
      <>
        {/* <p className="text-muted h5 mt-3 mb-2">Or</p> */}
        <p className="mt-3 mb-2 h5 text-center  ">
          Get QR code for{" "}
          <strong
            style={{ cursor: "pointer" }}
            className="text-primary text-underline"
            id={props.alt}
            onClick={props.onClick}
          >
            {props.qrDescription}
          </strong>
        </p>
      </>
    ) : null}
  </Col>
);

export const ScanQRPage = ({ ...props }) => {
  return (
    <Row>
      <Col xs="12" xl="6" lg="6" md="6" sm="12" className="mx-auto d-flex">
        <QRcode
          id={props.isOpen.android ? "https://play.google.com/store/apps/details?id=com.etani.etanimobileapp&hl=en&pli=1" : "https://apps.apple.com/us/app/etani-connect/id6449495913?platform=iphone"}
          value={props.isOpen.android ? "https://play.google.com/store/apps/details?id=com.etani.etanimobileapp&hl=en&pli=1" : "https://apps.apple.com/us/app/etani-connect/id6449495913?platform=iphone"}
          size={250}
          // includeMargin={true}
          className="border my-5 p-3 mx-auto flex-column"
        />
      </Col>
      <Col
        xs="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        style={{ positon: "relative" }}
        className="d-flex justify-content-center flex-column "
      >
        <h1 className="head font-weight-800 mt-0 text-md-left text-center">
          Download Accounting App for your
          <strong className="text-primary">
            {props.showQR
              ? null
              : props.isOpen.android
              ? " Android"
              : props.isOpen.ios
              ? " IOS"
              : null}{" "}
            Device
          </strong>
        </h1>
        <p className="font-weight-400 text-md-left text-center">
          Please scan the QR Code to download the app from{" "}
          <strong className="text-primary">
            {props.showQR
              ? null
              : props.isOpen.android
              ? "Google Playstore"
              : props.isOpen.ios
              ? "Apple Store"
              : null}
          </strong>
          , you may also setup multifactor authentication in your mobile app
          after installation to better secure your account.
        </p>
        <div
          className={`d-flex justify-content-sm-start justify-content-center`}
        >
          <Button
            color="primary"
            className=" buttonWrap "
            outline
            onClick={props.toNewRoute}
          >
            Go Back
          </Button>
        </div>
      </Col>
    </Row>
  );
};
