import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Input, Col, Row, Form, Modal } from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";

import BlockUI from "components/Misc/BlockUI";
import { MainContext } from "context/mainContext";
import PanelCard from "components/Cards/PanelCard";
import CustomHeader from "customComponents/customHeader";
import { CustomFieldGroup } from "customComponents/Form";
import CustomSpinner from "components/Misc/CustomSpinner";
import { CustomModalButton } from "components/Button/CustomButton";
import Courses from "./Courses";
import { useHistory } from "react-router";

const TrainingVideo = () => {
  const { bgColor, refresh, setSelectedClientID, selectedClientID, userDetails, firmDetails } =
    useContext(MainContext);
  const [user, setUser] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const history = useHistory();
  const [error, setError] = useState({
    name: "",
    url: "",
  });
  const [modal, setModal] = useState({
    title: "Add Video",
    show: false,
    record: {},
    action: "create",
  });

  let modalRecord = modal.record;

  const fetchAPIs = async () => {
    await fetch("/api/course/getAllRecord", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setAlert(data.error);
          data = [];
          setFilterData(data);
        }
        // console.log("COURSE", data)
        setCourses(data);
        setLoading(false);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    let isMounted = true;
    if (isMounted) {
      let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
      setSelectedClientID(sessionSelectedClientID ? sessionSelectedClientID : selectedClientID);
      fetchAPIs();
    }
    return () => {
      isMounted = false;
    }
  }, [userDetails]);



  const handleDelete = (record) => {
    setShowBlockUI(true);
    fetch("/api/video/deleteRecord?id=" + record.id, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);

        // console.log(response);
        // setAlert(alert);

        for (let i = 0; i < filterData?.length; i++) {
          if (filterData[i]?.id === record.id) {
            filterData?.splice(i, 1);
            // break
          }
          setShowBlockUI(false);
          setFilterData(filterData);
          setUser(user);
          success(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeModal = () => {
    setModal({
      title: "",
      show: false,
      record: modal.record,
      action: "",
    });
    setError({ name: "", url: "" });
    // console.log(modal)
  };

  const openModal = (record) => {
    if (record) {
      setModal({
        title: "Update Video",
        show: true,
        record: record,
        actionUrl: "/api/video/updateRecord?id=" + record.id,
        actionUrlMethod: "PUT",
      });
    } else {
      setModal({
        title: "Add New Video",
        show: true,
        record: {},
        actionUrl: "/api/video/createRecord",
        actionUrlMethod: "POST",
      });
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    modal.record[name] = value;
    setError({ name: "", url: "" });
    setModal(modal);
  };

  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = courses.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilterData(filtered);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const validURL = /^(ftp|http|https):\/\/[^ "]+$/.test(modalRecord.URL);

    if (
      modalRecord.Name === undefined ||
      modalRecord.URL === undefined ||
      modalRecord.Name === undefined ||
      modalRecord.Name === "" ||
      !validURL
    ) {
      if (modalRecord.Name === undefined && modalRecord.URL === undefined)
        return setError({
          name: "This field is required!",
          url: "This field is required!",
        });
      if (modalRecord.Name === undefined || modalRecord.Name === "")
        return setError({ name: "Name is required", url: "" });

      if (!validURL)
        return setError({ name: "", url: "Please input a valid URL!" });
    }

    setShowBlockUI(true);
    fetch(modal.actionUrl, {
      method: modal.actionUrlMethod,
      body: JSON.stringify(modal.record),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          setShowBlockUI(false);

          if (response.error) {
            return warningAlert(response);
          }

          modal.show = false;

          if (modal.actionUrlMethod === "POST") {
            response.record.id = filterData?.length + 1;
            filterData?.push(response.record);
          }

          // console.log(data);
          setFilterData(user);
          setModal(modal);
          setUser(user);
          success(response);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDelete = (record) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => handleDelete(record)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        showCancel
        confirmBtnText="Ok"
        btnSize=""
      >
        Are you sure you want to remove this video?
      </ReactBSAlert>
    );
  };
  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.error}
      </ReactBSAlert>
    );
  };

  const success = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message}
      </ReactBSAlert>
    );
  };

  const style = (name) => {
    return name !== "" ? { border: "1px solid #fb6340 " } : { border: "" };
  };

  const errorStyle = (name) => {
    return name !== "" ? (
      <span className="text-warning" style={{ fontSize: "12px" }}>
        <i className="fas fa-exclamation-circle mr-2 text-warning" />
        {name}
      </span>
    ) : null;
  };

  const CoursesList = ({ filterData }) => {
    const courses = filterData?.map((data, i) => {
      return (
        <Courses
          key={i}
          id={i}
          courseData={data}
          bgColor={bgColor}
          userDetails={userDetails}
        />
      );
    });

    return courses;
  };

  return (
    <>
      {alert}
      {/* {refresh === false ? fetchAPIs : null} */}
      <CustomHeader style={null} />
      <Container className="mt-6" fluid>
        <div className="mt-6"></div>
        <div className="nav-wrapper">
          <PanelCard headerName="Courses">
            <Row>
              <Col className="mb-3 mt-0"></Col>
              <Col sm="4" className="ml-auto mb-3 mt-0">
                <Input
                  bsSize="sm"
                  placeholder="Search"
                  type="text"
                  onChange={handleSearch}
                />
              </Col>
            </Row>
            <Row>
              {filterData?.length === 0 ? loading ? (<CustomSpinner />) :
                (
                  <div className="col-sm-12 mt-4 text-center">
                    <h5>No Training Video found.</h5>
                  </div>
                )
                :
                (
                  <CoursesList filterData={filterData} />
                )
              }
            </Row>
          </PanelCard>
        </div>
      </Container>

      <Modal
        className="modal-dialog"
        size="lg"
        isOpen={modal.show}
      // toggle={() => closeModal()}
      >
        <div className="modal-body ">
          <h6 className="modal-title">{modal.title}</h6>
          <Form role="form" onSubmit={handleSubmit}>
            <Row className="mt-4">
              <Col>
                <CustomFieldGroup
                  id="Name"
                  name="Name"
                  type="text"
                  label="Video Name"
                  placeholder="Enter Service Name"
                  onChange={handleInputChange}
                  defaultValue={modalRecord.Name || ""}
                  // required={true}
                  style={style(error.name)}
                  error={errorStyle(error.name)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  placeholder="Enter Description"
                  label="Description"
                  name="Description"
                  id="Description"
                  type="textarea"
                  rows={5}
                  onChange={handleInputChange}
                  defaultValue={modalRecord.Description || ""}
                // required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomFieldGroup
                  id="URL"
                  name="URL"
                  type="text"
                  label="URL"
                  placeholder="Enter URL"
                  onChange={handleInputChange}
                  defaultValue={modalRecord.URL || ""}
                  // required={true}
                  style={style(error.url)}
                  error={errorStyle(error.url)}
                />
              </Col>
            </Row>
            <CustomModalButton
              submitButton={null}
              closeButton={() => closeModal()}
              submitText="Save"
              cancelText="Cancel"
              submitType="submit"
              disabled={
                modalRecord.Name === "" || modalRecord.URL === "" ? true : false
              }
            />
          </Form>
        </div>
      </Modal>
      {
        // console.log(showBlockUI)
        showBlockUI ? <BlockUI /> : null
      }
    </>
  );
};

export default TrainingVideo;
