import React, { useContext } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { MainContext } from "context/mainContext";

const PanelCard = ({ ...props }) => {
  const { bgColor, textColor } = useContext(MainContext);
  return (
    <>
      {
        //Added by Joef: Added style in case style is passed in panel
      }
      <Card className="fadeIn" style={props.style || {}}>
        <CardHeader
          style={{
            backgroundColor: `${bgColor}`,
            transition: ".3s ease-in-out",
          }}
        >
          <h5 className="h3 mb-0" style={{ color: `${textColor}` }}>
            {props.headerName}
          </h5>
          {props.subHeader ? (
            <span style={{ color: `${textColor}`, fontSize: "0.7rem" }}>
              {props.subHeader}
            </span>
          ) : null}
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    </>
  );
};

export default PanelCard;
